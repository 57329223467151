import React, { useState, useEffect } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
} from 'recharts'
import { toast } from 'react-toastify'

function AdminDashboardTruckingLanePriorities({ navigation }) {
  const axios = useAxios()
  const [truckingData, setTruckingData] = useState(null)
  const [loadingAnalysis, setLoadingAnalysis] = useState(true)

  useEffect(() => {
    async function updateLaneAnalysis() {
      setLoadingAnalysis(true)
      let res = await axios.get(`/main/admin-trucking-lanes/priority/`)
      if (res.status < 400) {
        let lines = res.data.results
        setTruckingData(lines)
      } else {
        toast.error('Error analyzing lane')
      }
      setLoadingAnalysis(false)
    }
    updateLaneAnalysis()

    return () => {
      setTruckingData(null)
    }
  }, [])

  return (
    <Segment style={{ minHeight: '40vh' }}>
      <Header as="h2">Trucking Lane Priorities</Header>

      <Segment basic loading={loadingAnalysis}>
        {!loadingAnalysis && (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={truckingData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="priority" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="total_trucking_lanes"
                name="Trucking Lanes"
                stackId="a"
                fill="#4daf7c"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Segment>
    </Segment>
  )
}

export default AdminDashboardTruckingLanePriorities
