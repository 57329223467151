import { Table } from 'semantic-ui-react'
import { useState } from 'react'
import MickeyTable from '../MickeyTable/MickeyTable'
import { Toggle } from 'rsuite'
import './Permissions.css'
import env from '../../env'

const PERMISSIONS = {
  Notification: ['Create', 'Read', 'Update', 'Delete'],
  Order: ['Create', 'Read', 'Update', 'Delete'],
  Location: ['Create', 'Read', 'Update', 'Delete'],
  User: ['Create', 'Read', 'Update', 'Delete'],
  Document: ['Create', 'Read', 'Update', 'Delete'],
  Business: ['Create', 'Read', 'Update', 'Delete'],
  'Activity Log': ['Create', 'Read', 'Update', 'Delete'],
  'Price History': ['Read', 'Update', 'Delete'],
  'Sales Log': ['Create', 'Read', 'Update', 'Delete'],
  'Sales Order': ['Create', 'Read', 'Update', 'Delete'],
  'Purchase Order': ['Create', 'Read', 'Update', 'Delete'],
  'Take Rate': ['Read', 'Update'],
  Exchange: ['Create', 'Read', 'Update', 'Delete'],
  Calendar: ['Create', 'Read', 'Update', 'Delete'],
  Report: ['Create', 'Read', 'Update', 'Delete'],
  Settings: ['Create', 'Read', 'Update', 'Delete'],
  'Material List': ['Create', 'Read', 'Update', 'Delete'],
  Fulfillment: ['Create', 'Read', 'Update', 'Delete'],
  'Buyer Request': ['Create', 'Read', 'Update', 'Delete'],
  Payment: ['Create', 'Read', 'Update', 'Delete'],
}

function GroupFormPermissionsTable(props) {
  const {
    fieldName,
    value,
    handleInputChange,
    labelSuccessField,
    instanceLabel,
  } = props

  const [clickedToggleId, setClickedToggleId] = useState(null)

  const handleToggleClick = (addId, id) => {
    setClickedToggleId(id)
    let currentValue = value ? [...value] : []
    if (addId) {
      currentValue = [...currentValue, id]
    } else {
      let cv = currentValue?.filter((permission_id) => permission_id !== id)
      currentValue = cv ? cv : []
    }
    let d = {
      name: fieldName,
      value: currentValue,
    }
    handleInputChange('', d)
  }
  const ToggleJsx = (id) => {
    return (
      <>
        <Toggle
          style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
          size="lg"
          name={fieldName}
          checked={
            value && value?.find((permission_id) => permission_id === id)
              ? true
              : false
          }
          onChange={(value) => handleToggleClick(value, id)}
        />
        <label
          className={
            labelSuccessField && clickedToggleId === id
              ? 'saved-label saving'
              : 'saved-label'
          }
        >
          {' '}
          Saved!
        </label>
      </>
    )
  }

  return (
    <div>
      <MickeyTable
        headerRow={['', 'Read', 'Create', 'Update', 'Delete']}
        basicSegment
        unselectable
        url={'/main/permission-group/'}
        urlParams={{ permissions_dict: true }}
        hideFooter={true}
        renderBodyRow={(data, index) => {
          const permissions = PERMISSIONS[data.name]
          return (
            <Table.Row>
              <Table.Cell>{data?.name}</Table.Cell>
              <Table.Cell>
                {permissions.includes('Read') && (
                  <>{ToggleJsx(data?.permissions_dict?.Read?.id)}</>
                )}
              </Table.Cell>
              <Table.Cell>
                {permissions.includes('Create') && (
                  <>{ToggleJsx(data?.permissions_dict?.Create?.id)}</>
                )}
              </Table.Cell>
              <Table.Cell>
                {permissions.includes('Update') &&
                  (instanceLabel !== 'Admin' ||
                    (instanceLabel === 'Admin' &&
                      data.name !== 'Settings')) && (
                    <>{ToggleJsx(data?.permissions_dict?.Update?.id)}</>
                  )}
              </Table.Cell>
              <Table.Cell>
                {permissions.includes('Delete') && (
                  <>{ToggleJsx(data?.permissions_dict?.Delete?.id)}</>
                )}
              </Table.Cell>
            </Table.Row>
          )
        }}
        emptyMessage={'No Permission Groups'}
      />
    </div>
  )
}

export default GroupFormPermissionsTable
