export const AdminGlobalTakeAdjustmentForm = {
  model: 'GlobalSetting',
  format: [
    {
      widths: 'equal',
      fields: {
        default_marketplace_buyer_take_rate: {
          type: 'number',
          step: '0.1',
          min: 0,
          max: 100,
          round: 2,
          required: true,
          validator: (input) => {
            let error
            let float
            if (input) {
              if (isNaN(input)) {
                error = 'Number between 0 and 100 required'
              } else {
                float = parseFloat(input)
                if (float < 0 || float > 100) {
                  error = 'Number between 0 and 100 required'
                }
              }
            } else {
              error = 'Number between 0 and 100 required'
            }
            return error
          },
          label: 'Global Take Rate- Buyer',
          icon: { name: 'percent', circular: false, link: false },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        default_marketplace_supplier_take_rate: {
          type: 'number',
          step: '0.1',
          min: 0,
          max: 100,
          round: 2,
          required: true,
          validator: (input) => {
            let error
            let float
            if (input) {
              if (isNaN(input)) {
                error = 'Number between 0 and 100 required'
              } else {
                float = parseFloat(input)
                if (float < 0 || float > 100) {
                  error = 'Number between 0 and 100 required'
                }
              }
            } else {
              error = 'Number between 0 and 100 required'
            }
            return error
          },
          label: 'Global Take Rate- Supplier',
          icon: { name: 'percent', circular: false, link: false },
        },
      },
    },
  ],
}
