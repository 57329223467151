import React, { useEffect, useLayoutEffect, useState } from 'react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminChangeableOrderRow from './AdminChangeableOrderRow'
import AdminChangeOrderRow from './AdminChangeOrderRow'
import {
  Modal,
  Button,
  Divider,
  Loader,
  Header,
  Dropdown,
  Input,
  TextArea,
  Grid,
  Form,
} from 'semantic-ui-react'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import InventoryTable from '../../supplier/Inventory/InventoryTable'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import _ from 'underscore'
import { toast } from 'react-toastify'
import { formatTotalPrice } from '../../../lib/utils/utils'
import CustomAttribute from '../../mx/mx_shared/MXMain/CustomAttributes/CustomAttribute'
import { useSelector } from 'react-redux'
import AdminChangeOrderRequestRow from './AdminChangeOrderRequestRow'
import AutoDropdown from '../../../components/AutoDropdown/AutoDropdown'

const AdminChangeOrders = (props) => {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  // const globalSettings = useSelector((state) => state.globals.globalSettings);
  const {
    changeOrderModalOpen,
    setChangeOrderModalOpen,
    order,
    orderItems,
    setOrderItems,
    reload,
    setReload,
  } = props
  // MODALS
  const [addLineItemsModalOpen, setAddLineItemsModalOpen] = useState(false)
  const [editLineItemsModalOpen, setEditLineItemsModalOpen] = useState(false)
  const [loadingNextModal, setLoadingNextModal] = useState(false)
  const [openAttributesModal, setOpenAttributesModal] = useState(false)
  const [openQuantityModal, setOpenQuantityModal] = useState(false)
  const [reviewAddModal, setReviewAddModal] = useState(false)
  const [cancelOrderItemModal, setCancelOrderItemModal] = useState(false)
  const [editOrderItemModal, setEditOrderItemModal] = useState(false)

  // STATE

  const [selectedProductId, setSelectedProductId] = useState(null)
  const [addLineItemSelectedUom, setAddLineItemSelectedUom] = useState(null)
  const [uomOptions, setUomOptions] = useState([])
  const [selectedProductLoading, setSelectedProductLoading] = useState(false)
  const [attributesForSelectedProduct, setAttributesForSelectedProduct] =
    useState([])
  const [product, setProduct] = useState(null)
  const [attributeSelectionsFromDropdown, setAttributeSelectionsFromDropdown] =
    useState([])
  const [customAttributesSelected, setCustomAttributesSelected] = useState([])
  const [useCustomTally, setUseCustomTally] = useState(false)
  const [changeOrderItemsToSubmit, setChangeOrderItemsToSubmit] = useState([])
  const [changeOrders, setChangeOrders] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pieceCount, setPieceCount] = useState(null)
  const [customNotes, setCustomNotes] = useState('')

  const [globalSettings, setGlobalSettings] = useState()
  const getGlobalSettings = async () => {
    const resp = await axios.get('main/global-setting/')
    if (resp?.data?.results) {
      setGlobalSettings(resp?.data?.results[0])
    }
  }
  useEffect(() => {
    getGlobalSettings()
  }, [])

  const changeOrderRequestHeader = [
    'Product',
    'Attributes',
    'Previous Quanity',
    'New Quantity',
    '',
  ]

  const changeableOrderItemsHeader = [
    'Product',
    'Supplier',
    'Estimated Total Quantity',
    'Paid for Quantity',
    'Shipped Quantity',
    'Available to Edit Quantity',
    'Actions',
  ]

  // ADD FUNCTIONS
  const addLineItems = () => {
    setAddLineItemsModalOpen(true)
    setChangeOrderModalOpen(false)
  }
  const backFromSelectProduct = () => {
    setAddLineItemsModalOpen(false)
    setChangeOrderModalOpen(true)
  }
  const backFromChooseAttributes = () => {
    setOpenAttributesModal(false)
    setAddLineItemsModalOpen(true)
  }
  const backFromQuantity = () => {
    setOpenAttributesModal(true)
    setOpenQuantityModal(false)
  }
  const backFromReviewAdd = () => {
    setOpenQuantityModal(true)
    setReviewAddModal(false)
  }
  const handleOpenReviewModal = () => {
    setLoadingNextModal(true)
    setReviewAddModal(true)
    setOpenQuantityModal(false)
    setLoadingNextModal(false)
  }

  const createLineItem = (
    order_item_id,
    unit_of_measure,
    attribute_selections,
    new_quantity,
    is_new_line_item,
    is_cancel_line_item,
    is_edit_line_item,
    product_variant,
    price,
    piece_count
  ) => {
    let newLineItem = {
      order_item_id: order_item_id,
      unit_of_measure: unit_of_measure,
      attributes: attribute_selections,
      new_quantity: new_quantity,
      is_new_line_item: is_new_line_item,
      is_cancel_line_item: is_cancel_line_item,
      is_edit_line_item: is_edit_line_item,
      product_variant: product_variant,
      price: price,
      piece_count: piece_count,
    }
    setChangeOrderItemsToSubmit((changeOrderItems) => [
      ...changeOrderItems,
      newLineItem,
    ])
    setReviewAddModal(false)
    setChangeOrderModalOpen(true)
    setAttributeSelectionsFromDropdown({})
    setSelectedProductId(null)
    setProduct(null)
    props.setInputQuantity(null)
    setUseCustomTally(false)
    setCustomAttributesSelected([])
    setCancelOrderItemModal(false)
    setEditOrderItemModal(false)
    setEditLineItemsModalOpen(false)
    setChangeOrderModalOpen(true)
  }

  const removeLineItem = (index) => {
    let itemsToSubmit = [...changeOrderItemsToSubmit]
    itemsToSubmit.splice(index, 1)
    setChangeOrderItemsToSubmit(itemsToSubmit)
  }

  useEffect(() => {
    const getUoms = async () => {
      let res = await axios.get(`/main/unit-of-measures/`, {
        params: {
          product_variant_id: props.productVariant?.id,
        },
      })
      if (res.data.results) {
        setUomOptions(res.data.results)
      }
    }
    getUoms()
  }, [props.productVariant?.id])

  // EDIT FUNCTIONS

  const editLineItems = () => {
    setEditLineItemsModalOpen(true)
    setChangeOrderModalOpen(false)
  }
  const backFromEdit = () => {
    setEditLineItemsModalOpen(false)
    setChangeOrderModalOpen(true)
  }

  const handlePriceInput = (price) => {
    if (price < 0) {
      toast.error('Price must be greater than 0')
      props.setInputPrice(null)
    } else if (globalSettings?.round_exchange_order_prices && price % 1 != 0) {
      toast.error('Price must be a whole number')
      props.setInputPrice(null)
    } else if (price !== '') {
      props.setInputPrice(parseFloat(price))
    } else {
      props.setInputPrice(null)
    }
  }

  useEffect(() => {
    let orderItemIds = []
    orderItems?.map((orderItem) => {
      if (!orderItem['is_buyer_marketplace_fee']) {
        orderItemIds.push(orderItem.id)
      }
    })
    async function loadOrderItemStatus() {
      let res = await axios.get(
        `/main/cargo-transportation/order-item-location/`,
        {
          params: {
            order_item_ids: orderItemIds,
          },
        }
      )
      if (res?.status < 300 && res?.data?.results) {
        let orderItemsWithSupplQuantities = []
        orderItems.map((orderItem) => {
          if (!orderItem['is_buyer_marketplace_fee']) {
            res.data.results.map((trucking_val) => {
              if (orderItem.id === parseInt(trucking_val.order_item_id)) {
                orderItem.supplier_quantity_values =
                  trucking_val.quantity_values
                orderItem.buyer_quantity_values =
                  trucking_val.buyer_quantity_values
                orderItemsWithSupplQuantities.push(orderItem)
              }
            })
          }
        })
        setOrderItems(orderItemsWithSupplQuantities)
      }
    }
    loadOrderItemStatus()
  }, [orderItems.length])

  const handleClose = () => {
    // CLOSE MODALS
    setChangeOrderModalOpen(false)
    setAddLineItemsModalOpen(false)
    setEditLineItemsModalOpen(false)
    setLoadingNextModal(false)
    setOpenAttributesModal(false)
    setOpenQuantityModal(false)
    setReviewAddModal(false)

    //STATE
    setAttributeSelectionsFromDropdown({})
    setSelectedProductId(null)
    setProduct(null)
    props.setInputQuantity(null)
    setUseCustomTally(false)
    setCustomAttributesSelected([])
    setChangeOrderItemsToSubmit([])
  }
  const handleQuantityInput = (quantity) => {
    if (quantity < 0) {
      toast.error('Quantity must be greater than 0')
      props.setInputQuantity(null)
    } else if (quantity % 1 != 0) {
      toast.error('Quantity must be a whole number')
      props.setInputQuantity(null)
    } else {
      props.setInputQuantity(quantity)
    }
  }
  const checkNull = (attribute) => {
    if (attributeSelectionsFromDropdown.length === undefined) {
      return false
    }
    if (attributeSelectionsFromDropdown.length > 1) {
      let value = Object.keys(attributeSelectionsFromDropdown)[attribute?.name][
        'selectionId'
      ]
      if (value === undefined || value === null || value.length < 1) {
        return false
      }
    }
  }
  const getProductVariant = async () => {
    setLoadingNextModal(true)
    let attribute_selections = Object.values(
      attributeSelectionsFromDropdown
    ).map((attribute) => {
      return {
        selection_id: attribute.selectionId,
        selection_value: attribute.selectionValue,
        attribute_name: attribute.attributeName,
      }
    })
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        attribute_selections: attribute_selections,
        product_id: selectedProductId,
      },
    })
    if (res?.data) {
      props.setProductVariant(res.data)
      setOpenAttributesModal(false)
      setOpenQuantityModal(true)
    }
    setLoadingNextModal(false)
  }
  const getAttributes = async () => {
    setLoadingNextModal(true)
    let res = await axios.get(`/main/product-filter/`, {
      params: {
        product_id: selectedProductId,
      },
    })

    if (res?.data) {
      setAttributesForSelectedProduct(res.data.attribute_selections)
      setProduct(res.data.product)
      setAddLineItemsModalOpen(false)
      setOpenAttributesModal(true)
    }
    setLoadingNextModal(false)
  }

  const handleUpdateDropdownSelections = (
    attribute,
    selection,
    attributeName
  ) => {
    if (selection.id === 'custom' && customAttributesSelected.length < 1) {
      setUseCustomTally(true)
      setCustomAttributesSelected([attribute.name])
    }
    if (
      selection.id === 'custom' &&
      !customAttributesSelected.includes(attribute.name) &&
      customAttributesSelected.length >= 1
    ) {
      setCustomAttributesSelected((tally) => [...tally, attribute.name])
    }
    if (
      attribute.id !== 'custom' &&
      customAttributesSelected.includes(attribute.name) &&
      customAttributesSelected.length >= 1
    ) {
      let customAttributesSelectedCopy = [...customAttributesSelected]
      let customIndex = customAttributesSelectedCopy.indexOf(attribute.name)
      customAttributesSelectedCopy.splice(customIndex)
      setCustomAttributesSelected(customAttributesSelectedCopy)
    }
    setAttributeSelectionsFromDropdown({
      ...attributeSelectionsFromDropdown,
      [attribute.name]: {
        attributeName: attribute.name,
        selectionId: selection.id,
        selectionValue: selection.value,
        selectionValueDisplay: selection.value_display,
      },
    })
  }
  const handleUpdateCustomValues = (attributeName, customValues) => {
    let attributeSelectionsFromDropdownCopy = attributeSelectionsFromDropdown
    if (attributeSelectionsFromDropdownCopy[attributeName]) {
      attributeSelectionsFromDropdownCopy[attributeName].selectionValue =
        customValues
      setAttributeSelectionsFromDropdown(attributeSelectionsFromDropdownCopy)
    }
  }

  const createAndSendChangeOrder = async () => {
    let res = await axios.post(`/main/change-orders/`, {
      user_id: user?.id,
      order_id: order?.id,
      change_order_items: changeOrderItemsToSubmit,
      notes: customNotes,
      send_email: true,
    })
    handleClose()
    setReload(true)
    if (res.data.success) {
      toast.success(
        `Change order generation started! Please check ${globalSettings?.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
      )
    } else {
      toast.error('Error sending change order')
    }
  }

  const createChangeOrder = async () => {
    let res = await axios.post(`/main/change-orders/`, {
      user_id: user?.id,
      order_id: order?.id,
      change_order_items: changeOrderItemsToSubmit,
      send_email: false,
    })
    handleClose()
    setReload(true)
    if (res.data.success) {
      toast.success(
        `Change order generation started! Please check ${globalSettings?.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
      )
    } else {
      toast.error('Error sending change order')
    }
  }
  useEffect(() => {
    setLoading(true)
    const getChangeOrders = async () => {
      let res = await axios.get(`/main/change-orders/`, {
        params: {
          order_id: order?.id,
        },
      })
      if (res?.data?.results) {
        setChangeOrders(res.data.results)
      }
    }
    getChangeOrders()
    setLoading(false)
  }, [reload])

  function containsValue(data) {
    for (let i = 0; i < changeOrderItemsToSubmit?.length; i++) {
      if (changeOrderItemsToSubmit[i]?.product_variant?.id === data) {
        return true // Found a matching object
      }
    }
    return false // Value not found in any object
  }

  // // Check if the array contains an object with the target value
  // if (containsValue(arrayOfObjects, targetValue)) {
  //     console.log(`The array contains an object with the value "${targetValue}".`);
  // } else {
  //     console.log(`The array does not contain an object with the value "${targetValue}".`);
  // }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <MickeyTable
            style={{ marginTop: '5px', margin: '10px', textAlign: 'center' }}
            basicSegment
            reload={reload}
            setReload={setReload}
            data={changeOrders}
            emptyIcon={'redo'}
            emptyMessage={'Change order requests will populate here'}
            renderBodyRow={(data, index) => {
              return (
                <AdminChangeOrderRow
                  index={index}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  changeOrder={data}
                  addLineItemSelectedUom={addLineItemSelectedUom}
                />
              )
            }}
          />

          {/* ADD OR EDIT */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setChangeOrderModalOpen(true)}
            open={changeOrderModalOpen}
            size={'medium'}
            closeOnDimmerClick={false}
            closeIcon={true}
          >
            <Modal.Header>
              {'Change request for Order ' + order?.order_number}
            </Modal.Header>
            <Modal.Content>
              <Button onClick={() => addLineItems()}>Add Line Items</Button>
              <Divider horizontal>Or</Divider>
              <Button onClick={() => editLineItems()}>
                Edit Current Line Items
              </Button>
              {changeOrderItemsToSubmit.length > 0 ? (
                <>
                  <Divider />
                  <MickeyTable
                    headerRow={changeOrderRequestHeader}
                    style={{
                      marginTop: '5px',
                      margin: '10px',
                      textAlign: 'center',
                    }}
                    basicSegment
                    reload={reload}
                    setReload={setReload}
                    data={changeOrderItemsToSubmit}
                    emptyIcon={'redo'}
                    emptyMessage={'Change order requests will populate here'}
                    renderBodyRow={(data, index) => {
                      return (
                        <AdminChangeOrderRequestRow
                          changeOrder={data}
                          index={index}
                          removeLineItem={removeLineItem}
                          addLineItemSelectedUom={addLineItemSelectedUom}
                        />
                      )
                    }}
                  />
                </>
              ) : (
                ''
              )}
              <Form
                style={{
                  marginTop: '1rem',
                }}
              >
                <TextArea
                  style={{
                    width: '100%',
                  }}
                  placeholder="Notes"
                  rows={8}
                  onChange={(e, d) => setCustomNotes(d.value)}
                />
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button color="green" onClick={() => createChangeOrder()}>
                Create
              </Button>
              <Button color="green" onClick={() => createAndSendChangeOrder()}>
                Create & Send
              </Button>
            </Modal.Actions>
          </Modal>
          {/* ADD - SELECT PRODUCT MODAL */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setAddLineItemsModalOpen(true)}
            open={addLineItemsModalOpen}
            size={'small'}
            closeOnDimmerClick={false}
            closeIcon={true}
          >
            <Modal.Header>Select New Product</Modal.Header>
            <Modal.Content>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 65%',
                  justifyContent: 'center',
                  overflow: 'visible',
                  width: '100%',
                }}
              >
                <label>Select Product</label>
                <MickeyDropdown
                  url="/main/products/"
                  placeholder={'Select Product'}
                  style={{
                    zIndex: '1003',
                    margin: '15px 0 20px 0',
                  }}
                  params={{
                    page_size: 25,
                  }}
                  titleFields={['material.name']}
                  descriptionFields={['commodity_form.name', 'commodity.name']}
                  hideDescriptionFieldParenthesis
                  onChange={(e, d) => {
                    setSelectedProductId(d.value)
                  }}
                  autoFocus
                  loading={selectedProductLoading}
                  setLoading={setSelectedProductLoading}
                />
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => backFromSelectProduct()}>
                Back
              </Button>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                color="primary"
                onClick={() => getAttributes()}
                disabled={!selectedProductId}
              >
                {loadingNextModal && <Loader size="tiny" inline="centered" />}
                {!loadingNextModal && 'Next'}
              </Button>
            </Modal.Actions>
          </Modal>
          {/* ADD - CHOOSE ATTRIBUTES */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setOpenAttributesModal(true)}
            open={openAttributesModal}
            size={'small'}
            closeOnDimmerClick={false}
            closeIcon={true}
          >
            <Modal.Header>
              Select Attributes for {product && product?.material.name}{' '}
              {product && product.commodity_form.name}
            </Modal.Header>

            <Modal.Content>
              {attributesForSelectedProduct?.map((attribute) => {
                return (
                  <>
                    <label style={{ margin: '5px' }}>{attribute.name}</label>
                    <Dropdown
                      label={attribute.name}
                      style={{
                        marginBottom: '1rem',
                      }}
                      placeholder={`Select ${attribute.name}`}
                      selection
                      fluid
                      text={
                        attributeSelectionsFromDropdown[attribute.name]
                          ? attributeSelectionsFromDropdown[attribute.name]
                              .selectionValueDisplay
                            ? attributeSelectionsFromDropdown[attribute.name]
                                .selectionValueDisplay
                            : 'Unspecified'
                          : ''
                      }
                      options={_.sortBy(
                        attribute?.attribute_selections,
                        'order'
                      ).map((selection) => {
                        return (
                          <Dropdown.Item
                            value={selection.value}
                            onClick={() =>
                              handleUpdateDropdownSelections(
                                attribute,
                                selection,
                                attribute.name
                              )
                            }
                          >
                            {selection.value_display
                              ? selection.value_display
                              : 'Unspecified'}
                          </Dropdown.Item>
                        )
                      })}
                    />
                    {useCustomTally &&
                      customAttributesSelected.includes(attribute.name) && (
                        <CustomAttribute
                          attributeName={attribute.name}
                          product={product}
                          handleUpdateCustomValues={handleUpdateCustomValues}
                        />
                      )}
                  </>
                )
              })}
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => backFromChooseAttributes()}>
                Back
              </Button>

              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                color="primary"
                onClick={async () => await getProductVariant()}
                disabled={
                  Object.keys(attributeSelectionsFromDropdown).length !==
                    attributesForSelectedProduct?.length &&
                  attributesForSelectedProduct.map((attribute) =>
                    checkNull(attribute)
                  )
                }
                clearable
              >
                {loadingNextModal && <Loader size="tiny" inline="centered" />}
                {!loadingNextModal && 'Next'}
              </Button>
            </Modal.Actions>
          </Modal>
          {/* ADD - CHOOSE QUANTITY */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setOpenQuantityModal(true)}
            open={openQuantityModal}
            size={'small'}
            closeOnDimmerClick={false}
            closeIcon={true}
          >
            <Modal.Header>Select Price & Quantity</Modal.Header>
            <Modal.Content>
              <Header as={'h4'} textAlign="center">
                Unit of Measure
              </Header>
              <Dropdown
                selection
                style={{ width: '100%' }}
                placeholder={props.productVariant?.unit_name}
                options={[
                  ...uomOptions.map((uom) => {
                    return {
                      key: uom.id,
                      text: uom.short_name,
                      value: {
                        id: uom.id,
                        unit_name: uom.short_name,
                      },
                    }
                  }),
                ]}
                onChange={(e, d) => setAddLineItemSelectedUom(d.value)}
              />

              <Divider />
              <Header as={'h4'} textAlign="center">
                Price
              </Header>
              <div style={{ position: 'relative', display: 'flex' }}>
                <Input
                  placeholder="$"
                  className="price-modal-input"
                  iconPosition={'left'}
                  type={'number'}
                  onChange={(e, d) => {
                    handlePriceInput(d.value)
                  }}
                  step={1}
                />
              </div>

              <Divider />
              <Header as={'h4'} textAlign="center">
                Quantity
              </Header>
              <div style={{ position: 'relative', display: 'flex' }}>
                <Input
                  placeholder={
                    addLineItemSelectedUom
                      ? addLineItemSelectedUom.unit_name
                      : props.productVariant?.unit_name
                  }
                  defaultValue={props.inputQuantity}
                  iconPosition={'left'}
                  className="price-modal-input"
                  type={'number'}
                  onChange={(e, d) => {
                    handleQuantityInput(d.value)
                  }}
                  step={1}
                />
              </div>
              {/* <Divider /> */}
              {/* <Header as={"h4"} textAlign="center">
                                Piece Count
                            </Header> */}
              {/* <div style={{ position: "relative", display: "flex" }}>
                                <Input
                                    placeholder="#"
                                    iconPosition={"left"}
                                    className="price-modal-input"
                                    type={"number"}
                                    onChange={(e, d) => {
                                        setPieceCount(d?.value)
                                    }}
                                    step={1}
                                />
                            </div> */}
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => backFromQuantity()}>
                Back
              </Button>

              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                color="primary"
                onClick={() => {
                  handleOpenReviewModal()
                }}
                disabled={!props.inputQuantity}
                clearable
              >
                {loadingNextModal && <Loader size="tiny" inline="centered" />}
                {!loadingNextModal && 'Next'}
              </Button>
            </Modal.Actions>
          </Modal>
          {/* ADD - REVIEW ADD LINE ITEM */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setReviewAddModal(true)}
            open={reviewAddModal}
            size={'small'}
          >
            <Modal.Header>
              {'Review Change Order Item for Order ' + order?.order_number}
            </Modal.Header>
            <Modal.Content>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 1 65%',
                  width: '100%',
                  overflow: 'scroll',
                  justifyContent: 'flex-start',
                }}
              >
                <Grid>
                  <Grid.Column>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '1rem',
                      }}
                    >
                      <p
                        style={{ fontSize: '15px', margin: '0', width: '35%' }}
                      >
                        <strong>Item:</strong>
                      </p>
                      <p
                        style={{ fontSize: '15px', margin: '0', width: '65%' }}
                      >
                        {props.productVariant &&
                          props.productVariant?.product?.material?.name}
                        {', '}
                        {props.productVariant &&
                          props.productVariant.attribute_selections.map(
                            (attribute, index) => {
                              if (
                                index !==
                                props.productVariant.attribute_selections
                                  .length -
                                  1
                              ) {
                                return (
                                  attribute.attribute_selection_value + ', '
                                )
                              }
                              if (
                                index ===
                                props.productVariant.attribute_selections
                                  .length -
                                  1
                              ) {
                                return attribute.attribute_selection_value
                              }
                            }
                          )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '1rem',
                      }}
                    >
                      <p
                        style={{ fontSize: '15px', margin: '0', width: '35%' }}
                      >
                        <strong>Quantity:</strong>
                      </p>
                      <p
                        style={{ fontSize: '15px', margin: '0', width: '65%' }}
                      >
                        {props.inputQuantity} /
                        {addLineItemSelectedUom
                          ? addLineItemSelectedUom.unit_name
                          : props.productVariant?.unit_name}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'baseline',
                        marginBottom: '1rem',
                      }}
                    >
                      {/* <p style={{ fontSize: "15px", margin: "0", width: "35%" }}>
                                                <strong>Piece Count:</strong>
                                            </p>
                                            <p style={{ fontSize: "15px", margin: "0", width: "65%" }}>
                                                {pieceCount}
                                            </p> */}
                    </div>
                    <Divider />
                  </Grid.Column>
                </Grid>
              </div>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => backFromReviewAdd()}>
                Back
              </Button>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                color="primary"
                onClick={() =>
                  createLineItem(
                    null,
                    addLineItemSelectedUom
                      ? addLineItemSelectedUom.id
                      : props.productVariant.uom_id,
                    props.productVariant.attribute_selections,
                    props.inputQuantity,
                    true,
                    false,
                    false,
                    props.productVariant,
                    props.inputPrice,
                    parseInt(pieceCount)
                  )
                }
                clearable
              >
                {loadingNextModal && <Loader size="tiny" inline="centered" />}
                {!loadingNextModal && 'Submit Change Order Item'}
              </Button>
            </Modal.Actions>
          </Modal>
          {/* EDIT - EDITABLE LINE ITEMS */}
          <Modal
            onClose={() => handleClose()}
            onOpen={() => setEditLineItemsModalOpen(true)}
            open={editLineItemsModalOpen}
            size={'large'}
          >
            <Modal.Header>
              {'Change request for Order ' + order?.order_number}
            </Modal.Header>
            <Modal.Content>
              {changeOrderItemsToSubmit.length === orderItems.length ? (
                <p>No Order Items to Change</p>
              ) : (
                <MickeyTable
                  headerRow={changeableOrderItemsHeader}
                  style={{ marginTop: '5px', margin: '10px', clear: 'right' }}
                  data={orderItems}
                  reload={reload}
                  basicSegment
                  setReload={setReload}
                  renderBodyRow={(data, index) => {
                    if (
                      !data?.is_buyer_marketplace_fee &&
                      !containsValue(data?.product_variant?.id) &&
                      !data?.buyer_quantity_values[
                        'unbooked_origin_location_quantity'
                      ] <= 0
                    ) {
                      return (
                        <AdminChangeableOrderRow
                          orderItem={data}
                          createLineItem={createLineItem}
                          changeOrderItemsToSubmit={changeOrderItemsToSubmit}
                          setChangeOrderItemsToSubmit={
                            setChangeOrderItemsToSubmit
                          }
                          setChangeOrderModalOpen={setChangeOrderModalOpen}
                          setEditLineItemsModalOpen={setEditLineItemsModalOpen}
                          editOrderItemModal={editOrderItemModal}
                          setEditOrderItemModal={setEditOrderItemModal}
                          setCancelOrderItemModal={setCancelOrderItemModal}
                          cancelOrderItemModal={cancelOrderItemModal}
                        />
                      )
                    }
                  }}
                  urlParams={{
                    order_id: order?.id,
                  }}
                  emptyIcon={'redo'}
                  emptyMessage={'Changeable order items will populate here'}
                />
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" onClick={() => backFromEdit()}>
                Back
              </Button>
              <Button color="green">Submit Change Order Request</Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </>
  )
}

export default AdminChangeOrders
