import {
  requiredEmailValidator,
  phoneNumberValidator,
  requiredOnlyDigtsNotAllowedValidator,
  onlyDigtsNotAllowedValidator,
} from '../components/Forms/validators'

export const InviteNewUserForm = {
  model: 'CustomUser',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          type: 'text',
          required: true,
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(input, 'first name')
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        last_name: {
          type: 'text',
          validator: (input) => {
            return onlyDigtsNotAllowedValidator(input, 'last name')
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          type: 'text',
          required: true,
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        phone: {
          type: 'phone',
          label: 'Phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
      },
    },
  ],
}
