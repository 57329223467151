import moment from 'moment'
import React, { useState } from 'react'
import { Icon, Modal, Table } from 'semantic-ui-react'
import Handlebars from 'handlebars'
import './AdminEmailRow.css'

function AdminEmailRow(props) {
  const email = props.data
  const [openEmailHtml, setOpenEmailHtml] = useState(false)

  function getHtmlTemplate(html, data) {
    const template = Handlebars.compile(html)
    return template(data)
  }

  return (
    <>
      <Table.Row
        selectable
        onClick={() => {
          setOpenEmailHtml(true)
        }}
      >
        <Table.Cell>
          {moment(email?.created_at).format('YYYY-MM-DD')}
        </Table.Cell>
        <Table.Cell>{email?.to}</Table.Cell>
        <Table.Cell>{email?.subject}</Table.Cell>
      </Table.Row>

      <Modal
        onClose={() => setOpenEmailHtml(false)}
        onOpen={() => setOpenEmailHtml(true)}
        open={openEmailHtml}
        size={'large'}
      >
        <Modal.Header className={'email-modal-header'}>
          <span> {email?.subject} </span>
          <Icon
            link
            name={'close'}
            onClick={() => {
              setOpenEmailHtml(false)
            }}
          />
        </Modal.Header>
        <Modal.Content className={'email-modal-body'}>
          <div>
            <div>
              <span style={{ fontWeight: 'bold' }}>To: </span>
              {email?.to}
            </div>
            {email?.cc?.length > 0 && (
              <div>
                <span style={{ fontWeight: 'bold' }}>CC: </span>
                {email?.cc?.map((cc_email, index) => {
                  return (index !== 0 ? ', ' : '') + cc_email
                })}
              </div>
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: getHtmlTemplate(email?.html_data, email?.dynamic_context),
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}
export default AdminEmailRow
