import React, { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ProductIcon from '../../../../components/ProductIcon/ProductIcon'
import { useCallback } from 'react'
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Panel,
} from 'reactflow'
import useAxios from '../../../../lib/auth/useAxios'
import 'reactflow/dist/style.css'
import AdminOrderBookAskNode from './AdminOrderBookAskNode'
import AdminOrderBookBidNode from './AdminOrderBookBidNode'
import AdminOrderBookEdge from './AdminOrderBookEdge'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { useWebsocket } from '../../../../hooks/useWebsocket'
import { toast } from 'react-toastify'

function AdminOrderBook(props) {
  const { lastWsMessage } = useWebsocket()
  const { orderBookId } = useParams()
  const axios = useAxios()
  const [activeNodeId, setActiveNodeId] = useState()
  const [reload, setReload] = useState(false)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  )
  const nodeTypes = useMemo(
    () => ({
      adminOrderBookAskNode: (node) => (
        <AdminOrderBookAskNode
          data={node.data}
          activeNodeId={activeNodeId}
          node={node}
        />
      ),
      adminOrderBookBidNode: (node) => (
        <AdminOrderBookBidNode
          data={node.data}
          activeNodeId={activeNodeId}
          node={node}
        />
      ),
    }),
    [activeNodeId]
  )
  const edgeTypes = useMemo(() => ({ custom: AdminOrderBookEdge }), [])

  let ledgerChart = {
    chart: {
      type: 'bar',
      height: '120px',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: [''],
    },
    yAxis: {
      min: -1,
      max: 5,
      title: {
        text: 'Mickey Take',
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
      },
    },
    series: [
      {
        name: '4-5%',
        data: [1],
        color: 'red',
      },
      {
        name: '3-4%',
        data: [1],
        color: '#FEAB12',
      },
      {
        name: '2-3%',
        data: [1],
        color: '#BEAB12',
      },
      {
        name: '1-2%',
        data: [1],
        color: 'lightgreen',
      },
      {
        name: '0-1%',
        data: [1],
        color: 'skyblue',
      },
      {
        name: '<0%',
        data: [-1],
        color: 'blue',
      },
    ],
  }
  useEffect(() => {
    let incrementAsk = 100
    let incrementBid = 100
    async function getOrderBook() {
      let res = await axios.get(`/main/admin-order-books/${orderBookId}/`, {
        params: {
          tenant_aware: false,
        },
      })
      if (res?.data?.id) {
        let bidOrderNodes = res?.data?.bid_orders?.map((order, idx) => {
          if (idx === 0 && !activeNodeId) {
            setActiveNodeId(order?.id.toString())
          }
          if (incrementBid === 0) {
            incrementBid += 100
          } else {
            incrementBid -= 100
          }
          return {
            id: order?.id.toString(),
            type: 'adminOrderBookBidNode',
            targetPosition: 'right',
            hidden: false,
            position: { x: 100 + incrementBid, y: 160 + idx * 150 },
            data: { order: order },
          }
        })
        let askOrderNodes = res?.data?.ask_orders?.map((order, idx) => {
          if (incrementAsk === 0) {
            incrementAsk += 100
          } else {
            incrementAsk -= 100
          }
          return {
            id: order?.id.toString(),
            type: 'adminOrderBookAskNode',
            sourcePosition: 'left',
            hidden: false,
            position: { x: 1100 - incrementAsk, y: 160 + idx * 150 },
            data: { order: order },
          }
        })
        let edg = []
        res?.data?.ask_orders?.forEach((ask, idx) => {
          res?.data?.bid_orders?.forEach((bid, idx2) => {
            if (
              ask.user?.id !== bid.user?.id &&
              ask.cargo_unit?.id === bid?.cargo_unit?.id
            ) {
              edg.push({
                id: `e${ask.id}-${bid.id}`,
                target: ask.id.toString(),
                source: bid.id.toString(),
                animated: true,
                type: 'custom',
                data: {
                  ask: ask,
                  eo: res.data,
                  bid: bid,
                  edges: res.data.edges,
                },
              })
            }
          })
        })
        setNodes(
          askOrderNodes.concat(bidOrderNodes).concat([
            {
              id: 'z',
              hidden: false,
              position: { x: 750, y: 10 },
              style: {
                background: '#EEE',
                color: 'black',
              },
              data: {
                label: (
                  <>
                    <ProductIcon
                      productName={res?.data?.product_material_name}
                    />
                    <br></br>
                    <b>{res?.data?.product_material_name}</b>
                    <br />
                    {res.data.product_variant?.attribute_selections?.map(
                      (pvs) => {
                        return (
                          <>
                            <b>{pvs?.attribute_name}</b>
                            {': ' + pvs?.attribute_selection_value}
                            <br></br>
                          </>
                        )
                      }
                    )}
                    <div></div>
                  </>
                ),
              },
            },
            {
              id: 'a',
              hidden: false,
              position: { x: 250, y: 100 },
              data: { label: 'Bids' },
              style: {
                background: '#4daf7c',
                color: 'white',
              },
            },
            {
              id: 'b',
              hidden: false,
              position: { x: 1150, y: 100 },
              data: { label: 'Asks' },
              style: {
                background: '#e49929',
                color: 'white',
              },
            },
          ])
        )
        setEdges(edg)
      }
    }
    getOrderBook()
  }, [orderBookId, reload])

  useEffect(() => {
    if (lastWsMessage) {
      let message = lastWsMessage
      if (
        'trade' in message &&
        (message?.trade?.side === 'ask' || message?.trade?.side === 'bid')
      ) {
        let toastMessage = `${message['trade']['quantity']} ${message['trade']['cargo_unit']['plural_name']} ${message['trade']['product_variant']['product_material_name']}`
        if (message['trade']['side'] === 'ask') {
          toastMessage = `🎉  Order to Sell ${toastMessage} Matched!`
        } else {
          toastMessage = `🎉  Order to Buy ${toastMessage} Matched!`
        }
        toast.success(toastMessage)
        setReload(!reload)
      }
    }
  }, [lastWsMessage])

  const filteredEdges = edges.filter(
    (edge) => edge.source === activeNodeId || edge.target === activeNodeId
  )

  return (
    <div style={{ padding: '80px 10px 10px 10px', height: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={filteredEdges}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={(event, node) => setActiveNodeId(node.id)}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
      >
        <MiniMap />
        <Controls />
        <Background />
        <Panel position={'bottom-left'}>
          <>
            <HighchartsReact highcharts={Highcharts} options={ledgerChart} />
          </>
        </Panel>
      </ReactFlow>
    </div>
  )
}

export default AdminOrderBook
