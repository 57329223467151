import {
  requiredEmailValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const AdminCustomUserForm = {
  model: 'CustomUser',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          required: true,
          type: 'text',
        },
        last_name: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          required: true,
          type: 'text',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
        phone: {
          type: 'phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
        receive_sms_messages: {
          type: 'checkbox',
          label: 'Receive SMS Messages',
        },
        type: {
          type: 'text',
          label: 'Position',
          disabled: true,
          disclude_key: 'type',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        groups: {
          required: true,
          url: '/main/group/',
          type: 'multiple dropdown model',
          label: 'Group',
          option_text_field: 'name',
          singleResult: true,
          disclude_options: [
            {
              field_name: 'name',
              field_value: 'Development',
            },
          ],
        },
      },
    },
    {
      header: 'Other Information',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        dead_weight_threshold: {
          type: 'text',
          label: 'Dead Weight Threshold',
        },
        dead_weight_unit_of_measure: {
          label: 'Dead Weight Unit of Measure',
          type: 'dropdown search',
          url: '/main/unit-of-measures/',
          title_fields: ['plural_name'],
          description_fields: ['short_name'],
        },
      },
    },
  ],
}
