import React, { useState, useEffect } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import './AdminReports.css'
import PageHeader from '../../../components/PageHeader/PageHeader'
import {
  Grid,
  Header,
  Modal,
  Form,
  Button,
  Card,
  Menu,
} from 'semantic-ui-react'
import env from '../../../env'
import { Link } from 'phosphor-react'

const AdminReports = () => {
  const [reports, setReports] = useState(null)
  const [iFrameUrl, setIFrameUrl] = useState(null)
  const [categories, setCategories] = useState(null)
  const [activeCategory, setActiveCategory] = useState(null)
  const [activeItem, setActiveItem] = useState('reports')
  const axios = useAxios()

  useEffect(() => {
    async function getReports() {
      let reports = await axios.get('/main/admin-reports/', {})
      setReports(reports.data.reports)
      let tempCategories = []
      reports.data.reports.forEach((report) => {
        if (!tempCategories.includes(report.category)) {
          tempCategories.push(report.category)
        }
      })
      setCategories(tempCategories)
      setActiveCategory(tempCategories[0])
      setIFrameUrl(reports.data.iframe_url)
    }
    getReports()
  }, [])

  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              icon={'newspaper'}
              header={'Reports'}
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          style={{ marginLeft: '10px' }}
          className={'no-top-margin no-top-padding'}
        >
          <Grid.Column width={16}>
            {categories?.map((category) => {
              return (
                <Header
                  style={{
                    marginRight: '20px',
                    display: 'inline-block',
                    textDecoration:
                      category === activeCategory ? 'underline' : '',
                    cursor: 'pointer',
                    color:
                      category == activeCategory
                        ? env.REACT_APP_PRIMARY_COLOR
                        : '',
                  }}
                  onClick={() => {
                    setActiveCategory(category)
                  }}
                >
                  {category}
                </Header>
              )
            })}
          </Grid.Column>
          {activeItem === 'reports' && (
            <>
              <div className={'reports-group'}>
                <Card.Group>
                  {reports
                    ?.filter((r) => {
                      return r.category === activeCategory
                    })
                    .map((report) => {
                      return (
                        <Card
                          size={'large'}
                          stackable
                          style={{ marginRight: '20px' }}
                        >
                          <Card.Content
                            header={report?.header}
                            description={report?.meta}
                          />
                          <Card.Content>
                            <Button
                              href={report?.href}
                              color={'green'}
                              content={'View'}
                              style={{ width: '100%', textAlign: 'center' }}
                            />
                          </Card.Content>
                        </Card>
                      )
                    })}
                </Card.Group>
              </div>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}></Grid.Column>
                </Grid.Row>
              </Grid>
            </>
          )}
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AdminReports
