import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useAxios from '../../../lib/auth/useAxios'

const AdminTeamMemberRow = (props) => {
  const axios = useAxios()
  const [openEditTeamMember, setOpenEditTeamMember] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const handleClose = () => {
    setOpenEditTeamMember(false)
    props.setReload(true)
  }

  const deleteTeamMember = async () => {
    await axios.delete(`/main/team-members/${props.teamMember.id}/`)
    props.setReload(true)
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell width={2}>{props?.teamMember?.last_name}</Table.Cell>
        <Table.Cell width={2}>{props?.teamMember?.first_name}</Table.Cell>
        <Table.Cell width={2}>{props?.teamMember?.position}</Table.Cell>
        <Table.Cell width={2}>
          <Button
            color="grey"
            icon="edit"
            onClick={() => setOpenEditTeamMember(true)}
          />
          <Button
            color="red"
            icon="x"
            onClick={() => setConfirmationModal(true)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => handleClose()}
        onOpen={() => setOpenEditTeamMember(true)}
        open={openEditTeamMember}
      >
        <Modal.Header>Edit Team Member</Modal.Header>
        <Modal.Content>
          <MickeyForm
            instanceId={props.teamMember.id}
            formName={'TeamMemberForm'}
            tenantAware={false}
            model={'TeamMember'}
            buttonFloatedRight={true}
            successMessage={'Team Member Added!'}
            failureMessage={'Error adding team member.'}
            url={'/main/team-members/'}
          />
        </Modal.Content>
      </Modal>
      <ConfirmationModal
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        yesFunction={deleteTeamMember}
        noFunction={() => setConfirmationModal(false)}
        archiveItem={'this team member'}
      />
    </>
  )
}

export default AdminTeamMemberRow
