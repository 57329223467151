import { Table, Button } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { DownloadSimple, FileText, TrashSimple } from 'phosphor-react'

const DocumentTableRow = (props) => {
  const { document, setReload, canDelete } = props
  const axios = useAxios()
  async function downloadData(document_id, document_name, document_extension) {
    let res = await axios.get('/main/download-document/', {
      responseType: 'blob',
      params: {
        document_id: document_id,
        document_field_name: 'file',
        tenant_aware: false,
      },
    })
    fileDownload(res.data, document_name + document_extension)
  }

  async function removeDocument(document_id) {
    let res = await axios.delete(`/main/documents/${document_id}/`)
    if (!res?.data?.error) toast.success('Document removed')
    if (setReload) setReload(true)
  }

  return (
    <>
      <Table.Row selectable>
        <Table.Cell width={1}>
          <p>{document?.order?.order_number}</p>
        </Table.Cell>
        <Table.Cell width={1}>
          <p>
            {document?.document_type
              ? document?.document_type
              : document?.document_name}
          </p>
        </Table.Cell>
        <Table.Cell width={1}>
          <p>{document?.created_at}</p>
        </Table.Cell>
        <Table.Cell width={1}>
          {document.file && (
            <p id="document-action-button">
              <Button
                basic
                color={'black'}
                onClick={() => {
                  window.open(document.file, '_blank')
                }}
                style={{
                  margin: '5px',
                }}
              >
                <FileText size={20} weight={'bold'} />
                <span>View</span>
              </Button>
              <Button
                basic
                color={'black'}
                onClick={() => {
                  downloadData(
                    document.id,
                    document.document_name,
                    document.document_extension
                  )
                }}
                style={{
                  margin: '5px',
                }}
              >
                <DownloadSimple size={20} weight={'bold'} />
                <span>Download</span>
              </Button>
              {document.order &&
                ![
                  'Contractor Direct Terms And Conditions Template',
                  'Purchase Order Terms And Conditions Template',
                  'Order Acknowledgment Terms And Conditions Template',
                ].includes(document.document_name) &&
                canDelete && (
                  <Button
                    basic
                    color={'primary'}
                    content={'Remove'}
                    onClick={() => {
                      removeDocument(document.id)
                    }}
                    style={{
                      margin: '5px',
                      '--primaryColor': '#f1592a',
                      '--secondaryButtonColor': '#f1592a',
                    }}
                  >
                    <TrashSimple size={20} weight={'bold'} />
                    <span>Remove</span>
                  </Button>
                )}
            </p>
          )}
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default DocumentTableRow
