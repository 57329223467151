import { Sidebar, Header, Menu } from 'semantic-ui-react'
import env from '../../env'
import './MickeySideBar.css'
import { useMemo } from 'react'

const MickeySideBar = (props) => {
  const { id, sidebarContent, visible, activeButton, setActiveButton, style } =
    props
  const rgbColors = useMemo(() => {
    let hex = env.REACT_APP_PRIMARY_COLOR
    hex = hex.replace(/^#/, '')
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255
    return { r, g, b }
  })

  return (
    <Sidebar
      as={Menu}
      animation="Uncover"
      icon="labeled"
      vertical
      visible={visible}
      width="thin"
      id={id ? id : 'mickey-sidebar'}
      style={style}
    >
      <div class="header-item item">
        <div class="sidebar-header">
          {sidebarContent?.header?.icon}
          <Header
            as={'h3'}
            style={{
              marginTop: 0,
              marginLeft: '10px',
              paddingTop: '5px',
              display: 'inline',
              fontSize: '1em',
            }}
          >
            {sidebarContent?.header?.header}
            <div class="sub header">{sidebarContent?.header?.sub_header}</div>
          </Header>
        </div>
      </div>
      {Object.keys(sidebarContent?.sections)?.map((section) => {
        if (
          !('permission' in sidebarContent?.sections[section]) ||
          ('permission' in sidebarContent?.sections[section] &&
            sidebarContent?.sections[section]?.permission)
        ) {
          return (
            <>
              <>
                <div class="item">
                  <h4
                    class="ui header left aligned content"
                    style={{ marginBottom: 0 }}
                  >
                    {section}
                  </h4>
                </div>
                <div class="item" style={{ paddingTop: 0, marginTop: 0 }}>
                  <div class="menu">
                    {sidebarContent?.sections[section]?.items?.map((item) => {
                      return (
                        <>
                          {(!('permission' in item) ||
                            ('permission' in item && item?.permission)) && (
                            <div
                              style={{
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                '--backgroundColorr': rgbColors?.r,
                                '--backgroundColorg': rgbColors?.g,
                                '--backgroundColorb': rgbColors?.b,
                              }}
                              className={
                                activeButton ===
                                (item?.activeButtonText
                                  ? item?.activeButtonText
                                  : item?.text)
                                  ? 'mickey-sidebar-option item selected'
                                  : 'mickey-sidebar-option item'
                              }
                              onClick={(e, d) => {
                                setActiveButton(
                                  item?.activeButtonText
                                    ? item?.activeButtonText
                                    : item?.text
                                )
                              }}
                            >
                              {item?.icon}
                              {item?.text}
                              {item?.html}
                            </div>
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </>
            </>
          )
        }
      })}
    </Sidebar>
  )
}

export default MickeySideBar
