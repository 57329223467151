import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loaderPercentage: 0,
  tenant: {},
  orderInvoices: {},
}
export const globalSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState
    },
    setLoaderPercentage: (state, data) => {
      state.loaderPercentage = data.payload
    },
    addTenant: (state, data) => {
      state.tenant = data.payload
    },
    removeTenant: (state) => {
      state.tenant = {}
    },
    removeOrderInvoices: (state) => {
      state.orderInvoices = {}
    },
    addOrderInvoices: (state, data) => {
      if (state.orderInvoices.hasOwnProperty(data.payload['orderId'])) {
        state.orderInvoices[data.payload['orderId']].push({
          invoiceId: data.payload['invoiceId'],
          invoiceNumber: data.payload['invoiceNumber'],
        })
      } else {
        state.orderInvoices[data.payload['orderId']] = [
          {
            invoiceId: data.payload['invoiceId'],
            invoiceNumber: data.payload['invoiceNumber'],
          },
        ]
      }
    },
    addGlobalSettings: (state, data) => {
      state.globalSettings = data.payload
    },
    removeGlobalSettings: (state) => {
      state.globalSettings = {}
    },
    addOnboardedBuyerEmail: (state, data) => {
      state.onboarded_buyer_email = data.payload
    },
    removeOnboardedBuyerEmail: (state, formId) => {
      state.onboarded_buyer_email = undefined
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  reset,
  setLoaderPercentage,
  addTenant,
  addOrderInvoices,
  removeOrderInvoices,
  removeTenant,
  addOnboardedBuyerEmail,
  removeOnboardedBuyerEmail,
  addGlobalSettings,
  removeGlobalSettings,
} = globalSlice.actions

export default globalSlice.reducer
