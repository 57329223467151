import { ContactForm } from './ContactForm'
import { BusinessApplicationForm } from './BusinessApplicationForm'
import { DashboardItemForm } from './DashboardItemForm'
import { ProductForm } from './ProductForm'
import { CustomUserForm } from './CustomUserForm'
import { ProductListingForm } from './ProductListingForm'
import { LocationForm } from './LocationForm'
import { LocationUpdateForm } from './LocationUpdateForm'
import { SupplierContactForm } from './SupplierContactForm'
import { BuyerForm } from './BuyerForm'
import { MarkOrderShippedForm } from './MarkOrderShippedForm'
import { ResaleCertificateForm } from './ResaleCertificateForm'
import { SupplierOrderForm } from './SupplierOrderForm'
import { OrderItemForm } from './OrderItemForm'
import { ExtraLineOrderItemForm } from './ExtraLineOrderItemForm'
import { ManualTruckingForm } from './ManualTruckingForm'
import { OrderItemDiscountForm } from './OrderItemDiscountForm'
import { PublicOrderBusinessForm } from './PublicOrderBusinessForm'
import { ShopSettingsForm } from './ShopSettingsForm'
import { ShopSettingsLogoForm } from './ShopSettingsLogoForm'
import { AdminOrderForm } from './AdminOrderForm'
import { AdminOrderSupplierForm } from './AdminOrderSupplierForm'
import { AdminOrderBuyerForm } from './AdminOrderBuyerForm'
import { SendSupplierPaymentForm } from './SendSupplierPaymentForm'
import { LocationFormNotRequired } from './LocationFormNotRequired'
import { TruckingLaneForm } from './TruckingLaneForm'
import { ShopSettingsHomepageBannerForm } from './ShopSettingsHomepageBannerForm'
import { AdminBusinessApplicationForm } from './AdminBusinessApplicationForm'
import { InviteNewUserForm } from './InviteNewUserForm'
import { ContactUsForm } from './ContactUsForm'
import { AddNewExchangeListingForm } from './AddNewExchangeListingForm'
import { AddNewsArticleForm } from './AddNewsArticleForm'
import { AddEventForm } from './AddEventForm'
import { NewsForm } from './NewsForm'
import { PressReleaseForm } from './PressReleaseForm'
import { TeamMemberForm } from './TeamMemberForm'
import { TickerFieldForm } from './TickerFieldForm'
import { DocumentUploadForm } from './DocumentUploadForm'
import { AdminBusinessDocumentUploadForm } from './AdminBusinessDocumentUploadForm'
import { AdminSupplierTakeAdjustmentForm } from './AdminSupplierTakeAdjustmentForm'
import { AdminBuyerTakeAdjustmentForm } from './AdminBuyerTakeAdjustmentForm'
import { SalesLogForm } from './SalesLogForm'
import { AdminGlobalTakeAdjustmentForm } from './AdminGlobalTakeAdjustmentForm'
import { AdminCustomUserForm } from './AdminCustomUserForm'
import { BoxItemESignTemplateForm } from './BoxItemESignTemplateForm'
import { MarkOrderDeliveredForm } from './MarkOrderDeliveredForm'
import { BuyerRequestForm } from './BuyerRequestForm'
import { TruckingCompanyForm } from './TruckingCompanyForm'
import { AddTaskForm } from './AddTaskForm'
import { GroupForm } from './GroupForm'
import { PermissionForm } from './PermissionForm'
import { ExchangeOrderHistoryForm } from './ExchangeOrderHistoryForm'
import { ExchangeOrderHistorySecondaryForm } from './ExchangeOrderHistorySecondaryForm'
import { AdminDevelopmentCustomUserForm } from './AdminDevelopmentCustomUserForm'
import { SettlementStatementForm } from './SettlementStatementForm'
import { TruckingUserForm } from './TruckingUserForm'

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

let forms = {
  AdminDevelopmentCustomUserForm: AdminDevelopmentCustomUserForm,
  ExchangeOrderHistorySecondaryForm: ExchangeOrderHistorySecondaryForm,
  ExchangeOrderHistoryForm: ExchangeOrderHistoryForm,
  PermissionForm: PermissionForm,
  GroupForm: GroupForm,
  AdminOrderBuyerForm: AdminOrderBuyerForm,
  AdminOrderSupplierForm: AdminOrderSupplierForm,
  ContactForm: ContactForm,
  TruckingLaneForm: TruckingLaneForm,
  DashboardItemForm: DashboardItemForm,
  BusinessApplicationForm: BusinessApplicationForm,
  AdminBusinessApplicationForm: AdminBusinessApplicationForm,
  MarkOrderDeliveredForm: MarkOrderDeliveredForm,
  AdminSupplierTakeAdjustmentForm: AdminSupplierTakeAdjustmentForm,
  AdminBuyerTakeAdjustmentForm: AdminBuyerTakeAdjustmentForm,
  AdminGlobalTakeAdjustmentForm: AdminGlobalTakeAdjustmentForm,
  BuyerForm: BuyerForm,
  DocumentUploadForm: DocumentUploadForm,
  AdminBusinessDocumentUploadForm: AdminBusinessDocumentUploadForm,
  CustomUserForm: CustomUserForm,
  SupplierContactForm: SupplierContactForm,
  ProductForm: ProductForm,
  ProductListingForm: ProductListingForm,
  LocationForm: LocationForm,
  BoxItemESignTemplateForm: BoxItemESignTemplateForm,
  LocationUpdateForm: LocationUpdateForm,
  LocationFormNotRequired: LocationFormNotRequired,
  MarkOrderShippedForm: MarkOrderShippedForm,
  ResaleCertificateForm: ResaleCertificateForm,
  SupplierOrderForm: SupplierOrderForm,
  OrderItemForm: OrderItemForm,
  ManualTruckingForm: ManualTruckingForm,
  ExtraLineOrderItemForm: ExtraLineOrderItemForm,
  OrderItemDiscountForm: OrderItemDiscountForm,
  PublicOrderBusinessForm: PublicOrderBusinessForm,
  ShopSettingsForm: ShopSettingsForm,
  ShopSettingsLogoForm: ShopSettingsLogoForm,
  AdminOrderForm: AdminOrderForm,
  SendSupplierPaymentForm: SendSupplierPaymentForm,
  ShopSettingsHomepageBannerForm: ShopSettingsHomepageBannerForm,
  InviteNewUserForm: InviteNewUserForm,
  ContactUsForm: ContactUsForm,
  TickerFieldForm: TickerFieldForm,
  AddNewExchangeListingForm: AddNewExchangeListingForm,
  AddNewsArticleForm: AddNewsArticleForm,
  AddEventForm: AddEventForm,
  NewsForm: NewsForm,
  PressReleaseForm: PressReleaseForm,
  TeamMemberForm: TeamMemberForm,
  SalesLogForm: SalesLogForm,
  AdminCustomUserForm: AdminCustomUserForm,
  BuyerRequestForm: BuyerRequestForm,
  TruckingCompanyForm: TruckingCompanyForm,
  AddTaskForm: AddTaskForm,
  SettlementStatementForm: SettlementStatementForm,
  TruckingUserForm: TruckingUserForm,
}

function get_label_from_field_name(key, field) {
  if ('label' in field) {
    return field['label']
  }
  key = key.replace('_', ' ')
  return toTitleCase(key)
}

function values(format) {
  let values = {}
  format.forEach((group) => {
    for (const [key, field] of Object.entries(group['fields'])) {
      if (field['type'] === 'checkbox') {
        values[key] = false
      } else {
        values[key] = null
      }
    }
  })

  return values
}

function groups(format) {
  format.forEach((group) => {
    for (const [key, field] of Object.entries(group['fields'])) {
      field['label'] = get_label_from_field_name(key, field)
    }
  })
  return format
}

export default function getForm(formName, model) {
  let form
  if (formName in forms) {
    form = forms[formName]
  }

  return {
    success: true,
    groups: groups(form['format']),
    values: values(form['format']),
    dependencies: form['dependencies'],
  }
}
