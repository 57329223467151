import React, { useEffect, useState } from 'react'
import {
  Table,
  Button,
  Grid,
  Icon,
  Modal,
  Header,
  Divider,
  Form,
} from 'semantic-ui-react'
import { PieChart, Pie, Legend } from 'recharts'
import ImageGallery from 'react-image-gallery'

import { formatTotalPrice } from '../../../lib/utils/utils'
import AutoField from '../../../components/AutoField/AutoField'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { toast } from 'react-toastify'
import useAxios from '../../../lib/auth/useAxios'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import AutoDropdown from '../../../components/AutoDropdown/AutoDropdown'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import Checkbox from '@mui/material/Checkbox'
import _ from 'underscore'
import { hasPermission, hasPricing } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import './AdminOrder.css'
import { CaretRight, CaretDown } from 'phosphor-react'
import OrderItemPriceOffers from '../../../components/OrderItemPriceOffer/OrderItemPriceOffers'

const AdminOrderItemsRow = (props) => {
  const { setPurchaseOrder, setAddPurchaseOrderModal } = props
  const axios = useAxios()
  const [displayPriceNegotiation, setDisplayPriceNegotiation] = useState(false)
  const [displayImages, setDisplayImages] = useState(false)
  const user = useSelector((state) => state.user.user)
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const primaryPricing = hasPricing(user, 'primary')
  const [supplierPayout, setSupplierPayout] = useState(
    props.orderItem.supplier_payout
  )
  const canUpdate = hasPermission(user, 'Update Material List')
  const orderItem = props.orderItem
  const setOrderItem = props.setOrderItem
  const [reload, setReload] = useState(false)
  const [resetvalue, setResetvalue] = useState(false)
  const [openDiscountModal, setOpenDiscountModal] = useState(false)
  const [totalDeliveredPrice, setTotalDeliveredPrice] = useState()
  const [secondaryTotalDeliveredPrice, setSecondaryTotalDeliveredPrice] =
    useState()

  const [notShippedStartAngle, setNotShippedStartAngle] = useState(0)
  const [notShippedEndAngle, setNotShippedEndAngle] = useState(180)
  const [bookedTruckStartAngle, setBookedTruckStartAngle] = useState(180)
  const [bookedTruckEndAngle, setBookedTruckEndAngle] = useState(180)
  const [inTransitStartAngle, setInTransitStartAngle] = useState(180)
  const [inTransitEndAngle, setInTransitEndAngle] = useState(180)
  const [deliveryStartAngle, setDeliveryStartAngle] = useState(180)

  const [deliveryEndAngle, setDeliveryEndAngle] = useState(360)

  const [buyerUnitParentOptions, setBuyerUnitParentOptions] = useState([])
  const [supplierUnitParentOptions, setSupplierUnitParentOptions] = useState([])
  const [currentUpdatingField, setCurrentUpdatingField] = useState()

  const [updatingLocked, setUpdatingLocked] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)

  const notShippedPercentage = 25
  const bookedTruckPercentage = 25
  const inTransitPercentage = 5
  const deliveredPercentage = 45

  const handleActiveIndex = () => {
    if (props.index !== activeIndex) {
      setActiveIndex(props.index)
    }
    if (props.index === activeIndex) {
      setActiveIndex(null)
    }
  }

  const [totalPrice, setTotalPrice] = useState(
    props.orderItem?.supplier_quotes?.length > 0
      ? props.orderItem?.supplier_quotes[0]?.supplier_price *
          props.orderItem.price
      : props.orderItem.quantity * props.orderItem.price
  )

  async function updateSupplier(supplierId) {
    let res = await axios.patch(
      `/main/admin-order-items/${orderItem.string_token}/`,
      {
        supplier_id: supplierId,
      }
    )
    if (res?.data?.supplier?.id === supplierId || !supplierId) {
      props.setOrderItem({
        ...orderItem,
        supplier: res.data.supplier,
      })
      toast.success('Updated Supplier')
    } else {
      toast.error('Error updating supplier.')
    }
  }

  async function updatePurchaseOrder(poiId = null, uomId = null, price = null) {
    const res = await axios.patch(
      `/main/admin-order-items/${orderItem.string_token}/`,
      {
        purchase_order_item_id: poiId,
        price: price,
        uom_id: uomId,
      }
    )
    if (res.status === 200) {
      props.setOrderItem(res?.data)
      setResetvalue(true)
    }
    if (res?.data?.purchase_order_item?.id === poiId) {
      toast.success('Purchase order updated')
    } else if (!poiId) {
      toast.success('Purchase order removed')
    } else {
      toast.error('Error updating purchase order')
    }
  }

  async function updateSalesOrder(soiId = null, uomId = null, price = null) {
    const res = await axios.patch(
      `/main/admin-order-items/${orderItem.string_token}/`,
      {
        sales_order_item_id: soiId,
        delivered_price: price,
        buyer_uom_id: uomId,
      }
    )
    if (res.status === 200) {
      props.setOrderItem(res?.data)
      setResetvalue(true)
    }
    if (res?.data?.sales_order_item?.id === soiId) {
      toast.success('Sales order updated')
    } else if (!soiId) {
      toast.success('Sales order removed')
    } else {
      toast.error('Error updating sales order')
    }
  }

  useEffect(() => {
    // make oi api call here and return oi data and then set everything
    async function loadOrderItem() {
      let res = await axios.get(`/main/order-items/`, {
        params: {
          tenant_aware: false,
          order_item_id: orderItem.id,
        },
      })
      let results = res?.data?.results
      if (results?.length > 0) {
        if (
          orderItem.unit_count !== results[0].unit_count ||
          orderItem.piece_count !== results[0].piece_count
        ) {
          setResetvalue(true)
        }
        setOrderItem(res.data.results[0])
      }
      setReload(false)
      setUpdatingLocked(false)
    }
    if (reload) {
      // Load the order item
      loadOrderItem()
    }
    if (orderItem?.is_buyer_marketplace_fee) {
      setTotalDeliveredPrice(
        (orderItem?.buyer_marketplace_fee
          ? parseFloat(orderItem?.buyer_marketplace_fee)
          : 0) +
          (orderItem?.buyer_marketplace_fee_tax_amount
            ? parseFloat(orderItem?.buyer_marketplace_fee_tax_amount)
            : 0)
      )
    } else if (orderItem.price && orderItem.quantity) {
      if (primaryPricing) {
        setTotalPrice(
          parseFloat(orderItem?.price) * parseFloat(orderItem?.quantity)
        )
        setTotalDeliveredPrice(
          parseFloat(orderItem?.delivered_price) *
            parseFloat(orderItem?.buyer_quantity) +
            parseFloat(orderItem?.tax_amount)
        )
        setSecondaryTotalDeliveredPrice(
          parseFloat(orderItem?.secondary_delivered_price) *
            parseFloat(orderItem?.buyer_quantity) +
            parseFloat(orderItem?.tax_amount)
        )
      } else {
        setTotalPrice(
          parseFloat(orderItem?.price) * parseFloat(orderItem?.quantity)
        )
        setTotalDeliveredPrice(
          parseFloat(orderItem?.secondary_delivered_price) *
            parseFloat(orderItem?.buyer_quantity) +
            parseFloat(orderItem?.tax_amount)
        )
      }
    }
  }, [
    orderItem.price,
    orderItem.quantity,
    orderItem.trucking_price,
    orderItem.discount,
    orderItem.is_credit_line_item,
    orderItem.is_buyer_marketplace_fee,
    orderItem.mickey_take,
    orderItem.tax_amount,
    orderItem.delivered_price,
    orderItem.buyer_quantity,
    orderItem.unit_count,
    orderItem.piece_count,
  ])

  useEffect(() => {
    async function loadOrderItemStatus() {
      let res = await axios.get(
        `/main/cargo-transportation/order-item-location/${orderItem?.id}/`,
        {}
      )
      if (res?.status < 300) {
        let totalNotShipped =
          res?.data?.results?.unbooked_origin_location_quantity
        let totalBooked = res?.data?.results?.booked_quantity
        let totalInTransit = res?.data?.results?.in_transit_quantity
        let totalDelivered = res?.data?.results?.destination_location_quantity
        let totalOfAll =
          totalNotShipped + totalBooked + totalInTransit + totalDelivered
        let totalNotShippedCircle = totalOfAll
          ? (totalNotShipped / totalOfAll) * 360
          : 0
        let totalBookedCircle = totalOfAll
          ? (totalBooked / totalOfAll) * 360
          : 0
        let totalInTransitCircle = totalOfAll
          ? (totalInTransit / totalOfAll) * 360
          : 0
        let totalDeliveredCircle = totalOfAll
          ? (totalDelivered / totalOfAll) * 360
          : 0
        setNotShippedStartAngle(0)
        setNotShippedEndAngle(totalNotShippedCircle)
        setBookedTruckStartAngle(totalNotShippedCircle)
        setBookedTruckEndAngle(totalNotShippedCircle + totalBookedCircle)
        setInTransitStartAngle(totalNotShippedCircle + totalBookedCircle)
        setInTransitEndAngle(
          totalNotShippedCircle + totalBookedCircle + totalInTransitCircle
        )
        setDeliveryStartAngle(
          totalNotShippedCircle + totalBookedCircle + totalInTransitCircle
        )
        setDeliveryEndAngle(
          totalNotShippedCircle +
            totalBookedCircle +
            totalInTransitCircle +
            totalDeliveredCircle
        )
      }
    }
    if (orderItem?.id && !orderItem?.is_buyer_marketplace_fee) {
      loadOrderItemStatus()
    }
  }, [])

  function isSelectedRow() {
    return (
      props.selectedOrderItem &&
      orderItem &&
      props.selectedOrderItem?.id === orderItem?.id &&
      !orderItem?.is_buyer_marketplace_fee
    )
  }
  useEffect(() => {
    props.setOrderItem(orderItem)
  }, [totalPrice, totalDeliveredPrice])

  const getPurchaseOrder = async (poId) => {
    let res = await axios.get(`/main/admin-purchase-order/${poId}/`)
    if (res.status === 200) {
      let poiId
      let uomId
      let price
      res.data.purchase_order_items.forEach((poi) => {
        if (
          !poiId &&
          poi.product_variant.id === props.orderItem.product_variant.id
        ) {
          poiId = poi.id
          uomId = poi.uom.id
          price = poi.price
        }
      })
      if (poiId) {
        updatePurchaseOrder(poiId, uomId, price)
      } else {
        toast.error(
          `Purchase order does not contain ${props.orderItem.product_variant?.product?.material?.name}(${props.orderItem.product_variant.product?.commodity_form?.name})`
        )
      }
    } else {
      toast.error('Error updating purchase order')
    }
  }

  const getSalesOrder = async (poId) => {
    let res = await axios.get(`/main/admin-sales-order/${poId}/`)
    if (res.status === 200) {
      let soiId
      let buyerUomId
      let deliveredPrice
      res.data.sales_order_items.forEach((soi) => {
        if (
          !soiId &&
          soi.product_variant.id === props.orderItem.product_variant.id
        ) {
          soiId = soi.id
          buyerUomId = soi.uom.id
          deliveredPrice = soi.price
        }
      })
      if (soiId) {
        updateSalesOrder(soiId, buyerUomId, deliveredPrice)
      } else {
        toast.error(
          `Sales order does not contain ${props.orderItem.product_variant?.product?.material?.name}(${props.orderItem.product_variant.product?.commodity_form?.name})`
        )
      }
    } else {
      toast.error('Error updating sales order')
    }
  }
  console.log(orderItem)
  console.log(orderItem.price)
  return (
    <>
      <Table.Row
        selectable
        compact
        onClick={() => handleActiveIndex()}
        className={`material-row ${isSelectedRow() ? 'selection' : ''}`}
      >
        {/* Row Selection */}
        <Table.Cell width={1}>
          {!orderItem.is_buyer_marketplace_fee && canUpdate && (
            <>
              <span onClick={() => props.onClick()}>
                <CaretRight size={20} />
              </span>
              <Checkbox
                color={'primary'}
                checked={props.selectedMaterials.includes(orderItem?.id)}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                onClick={(e, d) => {
                  let sm = [...props.selectedMaterials]
                  let sois = [...props.selectedOrderItems]
                  if (sm.includes(orderItem?.id)) {
                    const index = sm.indexOf(orderItem?.id)
                    const oiIndex = sois.indexOf(orderItem)
                    if (index > -1) {
                      // only splice array when item is found
                      sm.splice(index, 1)
                      sois.splice(index, 1)
                      props.setSelectedMaterials(sm)
                      props.setSelectedOrderItems(sois)
                    }
                  } else {
                    sm.push(orderItem.id)
                    sois.push(orderItem)
                    props.setSelectedMaterials(sm)
                    props.setSelectedOrderItems(sois)
                  }
                }}
              />
            </>
          )}
        </Table.Cell>

        {/* Product Details */}
        <Table.Cell
          width={5}
          onClick={() => {
            props.onClick()
          }}
        >
          <div className={'product-variant-container'}>
            <div>
              {!orderItem?.is_buyer_marketplace_fee && (
                <OrderItemDetailTwo orderItem={orderItem} />
              )}
              {orderItem?.is_buyer_marketplace_fee && <>Marketplace Fee</>}
            </div>
          </div>
        </Table.Cell>

        {/* Supplier */}
        <Table.Cell
          width={2}
          onClick={() => {
            props.onClick()
          }}
        >
          {!orderItem?.is_buyer_marketplace_fee && (
            <>{orderItem?.supplier?.business?.name}</>
          )}
        </Table.Cell>

        {/* Supplier Price */}
        <Table.Cell
          width={1}
          onClick={() => {
            props.onClick()
          }}
        >
          {!orderItem?.is_buyer_marketplace_fee && (
            <>{formatTotalPrice(orderItem?.price)}</>
          )}
        </Table.Cell>

        {/* Supplier Quantity */}
        <Table.Cell
          width={1}
          onClick={() => {
            props.onClick()
          }}
        >
          {!orderItem?.is_buyer_marketplace_fee &&
            ((globalSettings?.id && !globalSettings?.disabled_quantity) ||
            (orderItem?.quantity && orderItem?.quantity > 0) ? (
              <>
                {' '}
                1 LBS
              </>
            ) : (
              '100 LBS'
            ))}
        </Table.Cell>

        {/* Buyer Price */}
        <Table.Cell width={1}>
          {!orderItem?.is_buyer_marketplace_fee && (
            <>
              {primaryPricing && (
                <>
                  Primary {formatTotalPrice(orderItem?.delivered_price)}
                  <br />
                  Secondary{' '}
                  {formatTotalPrice(orderItem?.secondary_delivered_price)}
                </>
              )}
              {!primaryPricing && (
                <>{formatTotalPrice(orderItem?.secondary_delivered_price)}</>
              )}
            </>
          )}
        </Table.Cell>

        {/* Buyer Quantity */}
        <Table.Cell
          width={1}
          onClick={() => {
            props.onClick()
          }}
        >
          {!orderItem?.is_buyer_marketplace_fee &&
            ((globalSettings?.id && !globalSettings?.disabled_quantity) ||
            (orderItem?.buyer_quantity && orderItem?.buyer_quantity > 0) ? (
              <>
                {' '}
                {orderItem?.buyer_quantity} {orderItem?.buyer_unit?.short_name}{' '}
              </>
            ) : (
              '100 LBS'
            ))}
        </Table.Cell>

        {/* Supplier Total */}
        <Table.Cell
          width={1}
          onClick={() => {
            props.onClick()
          }}
        >
          {formatTotalPrice(totalPrice)}
        </Table.Cell>
        {/* Total Margin */}
        <Table.Cell
          width={1}
          onClick={() => {
            props.onClick()
          }}
        >
          {!props.orderItem?.is_credit_line_item &&
            !orderItem?.is_buyer_marketplace_fee && (
              <>
                {primaryPricing && (
                  <>
                    <span style={{ fontSize: '11.9px' }}>
                      Primary {formatTotalPrice(orderItem?.mickey_take)}
                    </span>
                    <br />
                    <span style={{ fontSize: '11.9px' }}>
                      Secondary{' '}
                      {formatTotalPrice(orderItem?.secondary_mickey_take)}
                    </span>
                  </>
                )}
                {!primaryPricing && (
                  <>{formatTotalPrice(orderItem?.secondary_mickey_take)}</>
                )}
              </>
            )}
          {orderItem?.is_buyer_marketplace_fee && (
            <>
              {props.canEdit && canUpdate ? (
                <>
                  <AutoField
                    icon={'dollar'}
                    iconPosition="left"
                    model={'Order'}
                    field={'buyer_marketplace_fee'}
                    url={'/main/orders/'}
                    currentUpdatingField={currentUpdatingField}
                    setCurrentUpdatingField={setCurrentUpdatingField}
                    resetvalue={resetvalue}
                    setResetvalue={setResetvalue}
                    disabled={orderItem?.locked || updatingLocked}
                    instanceId={orderItem?.id}
                    value={parseFloat(orderItem?.buyer_marketplace_fee)}
                    placeholder="Enter Marketplace Fee"
                    type="number"
                    step="0.01"
                    size={'small'}
                    style={{
                      width: '100px',
                      display: 'inline-block',
                    }}
                    onChange={(e, d) => {
                      setReload(true)
                      setOrderItem({
                        ...orderItem,
                        buyer_marketplace_fee: d?.value
                          ? parseFloat(d?.value).toFixed(2)
                          : 0,
                      })
                    }}
                    textarea={false}
                  />
                </>
              ) : (
                formatTotalPrice(orderItem?.buyer_marketplace_fee)
              )}
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          {!props.orderItem?.is_buyer_marketplace_fee && (
            <>{formatTotalPrice(orderItem?.tax_amount)}</>
          )}
          {props.orderItem?.is_buyer_marketplace_fee && (
            <>{formatTotalPrice(orderItem?.buyer_marketplace_fee_tax_amount)}</>
          )}
        </Table.Cell>
        <Table.Cell>
          {!props.orderItem?.is_buyer_marketplace_fee && (
            <b>
              {primaryPricing && (
                <>
                  <span style={{ fontSize: '11.9px' }}>
                    Primary {formatTotalPrice(totalDeliveredPrice)}
                  </span>
                  <br />
                  <span style={{ fontSize: '11.9px' }}>
                    Secondary {formatTotalPrice(secondaryTotalDeliveredPrice)}
                  </span>
                </>
              )}
              {!primaryPricing && (
                <>{formatTotalPrice(orderItem?.secondary_mickey_take)}</>
              )}
            </b>
          )}
          {props.orderItem?.is_buyer_marketplace_fee && (
            <b>
              {formatTotalPrice(
                parseFloat(orderItem?.buyer_marketplace_fee) +
                  parseFloat(orderItem?.buyer_marketplace_fee_tax_amount)
              )}
            </b>
          )}
          <br></br>
        </Table.Cell>
        <Table.Cell textAlign="center">
          {!props.orderItem?.is_buyer_marketplace_fee && (
            <PieChart style={{ cursor: 'pointer' }} width={60} height={40}>
              <Pie
                data={[{ name: 'Unfilled', value: 20 }]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={notShippedStartAngle}
                endAngle={notShippedEndAngle}
                innerRadius={10}
                outerRadius={20}
                fill="rgb(200,200,200)"
              />
              <Pie
                data={[{ name: 'Booked Truck', value: 20 }]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={bookedTruckStartAngle}
                endAngle={bookedTruckEndAngle}
                innerRadius={10}
                outerRadius={20}
                fill="rgb(242, 222, 91)"
              />
              <Pie
                data={[{ name: 'In Transit', value: 20 }]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={inTransitStartAngle}
                endAngle={inTransitEndAngle}
                innerRadius={10}
                outerRadius={20}
                fill="rgb(242, 22, 91)"
              />
              <Pie
                data={[{ name: 'Delivered', value: 20 }]}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                startAngle={deliveryStartAngle}
                endAngle={deliveryEndAngle}
                innerRadius={10}
                outerRadius={20}
                fill="rgb(103, 168, 131)"
              />
            </PieChart>
          )}
        </Table.Cell>
      </Table.Row>
      {isSelectedRow() && (
        <>
          <Table.Row selectable compact className="material-row selected">
            <Table.Cell
              colSpan={16}
              style={{
                backgroundColor: '#F8F8F8',
              }}
            >
              <Grid stackable columns={4} style={{ padding: '25px' }}>
                <Grid.Column>
                  <Header as={'h3'}>Supplier</Header>
                  <Form>
                    {!orderItem?.is_buyer_marketplace_fee && (
                      <>
                        <MickeyDropdown
                          url={'/main/suppliers/'}
                          defaultValue={orderItem?.supplier?.id}
                          disabled={!props.canEdit}
                          autoComplete="new-password"
                          allowEmpty
                          isForm={true}
                          label={'Supplier'}
                          tenantAware={false}
                          params={{
                            order_id: props.order?.id,
                          }}
                          filterField={'q'}
                          placeholder={'Select Supplier'}
                          titleFields={['business.name']}
                          descriptionFields={[]}
                          descriptionFieldSeparator={' '}
                          hideDescriptionFieldParenthesis
                          description
                          onChange={(e, d) => {
                            updateSupplier(d.value)
                          }}
                        />
                        {hasPermission(user, 'Read Purchase Order') && (
                          <MickeyDropdown
                            url={'/main/admin-purchase-order/'}
                            allowEmpty
                            defaultValue={
                              props.orderItem?.purchase_order_item?.id
                            }
                            disabled={!props.orderItem?.supplier?.business?.id}
                            isForm={true}
                            label={'Purchase Order #'}
                            tenantAware={false}
                            params={{
                              business_id:
                                props.orderItem?.supplier?.business?.id,
                              product_variant_id:
                                props.orderItem?.product_variant?.id,
                            }}
                            placeholder={
                              'Select Purchase Order Associated to Product'
                            }
                            titleFields={['purchase_order_number']}
                            descriptionFields={[]}
                            descriptionFieldSeparator={' '}
                            hideDescriptionFieldParenthesis
                            description
                            onChange={(e, d) => {
                              if (d.value) {
                                getPurchaseOrder(d.value)
                              } else {
                                updatePurchaseOrder()
                              }
                            }}
                          />
                        )}
                        <AutoDropdown
                          optionsUrl={'/main/unit-of-measures/'}
                          setParentOptions={setSupplierUnitParentOptions}
                          optionTitleField={'plural_name'}
                          disabled={
                            orderItem?.locked ||
                            !props.canEdit ||
                            updatingLocked ||
                            !canUpdate
                          }
                          label="Supplier Unit of Measure"
                          model={'OrderItem'}
                          field={'unit'}
                          updating_fields={['quantity', 'price']}
                          serializer={'OrderItemSerializer'}
                          instanceId={orderItem?.id}
                          value={orderItem?.unit?.id}
                          placeholder={'Select Unit'}
                          titleFields={['plural_name']}
                          onUpdate={(res) => {
                            if (res?.data?.instance) {
                              setOrderItem(res.data.instance)
                              setResetvalue(true)
                            }
                          }}
                          descriptionFields={[]}
                          style={{
                            width: '95px',
                          }}
                          onChange={(e, d) => {
                            setOrderItem({
                              ...orderItem,
                              unit: _.find(
                                supplierUnitParentOptions,
                                function (x) {
                                  return x.id == d?.value
                                }
                              ),
                            })
                            setReload(true)
                          }}
                        />
                      </>
                    )}
                    {!orderItem?.is_buyer_marketplace_fee && (
                      <>
                        {props.canEdit ? (
                          <AutoField
                            icon={
                              <Icon
                                circular
                                className={'auto-field-icon-extra'}
                              >
                                <b>{'$/' + orderItem?.unit?.short_name}</b>
                              </Icon>
                            }
                            iconPosition={'right'}
                            label={'Supplier Price'}
                            disabled={
                              orderItem?.locked ||
                              !props.canEdit ||
                              (updatingLocked && updatingLocked !== 'price') ||
                              !canUpdate
                            }
                            serializer={'OrderItemSerializer'}
                            model={'OrderItem'}
                            field={'price'}
                            updating_fields={[
                              'mickey_take',
                              'mickey_unit_take',
                              'take_rate',
                            ]}
                            url={'/main/order-items/'}
                            instanceId={orderItem?.id}
                            value={orderItem?.price}
                            canNotTakeNeagativeValue={true}
                            placeholder="Enter Price"
                            type="number"
                            step="0.01"
                            size={'small'}
                            currentUpdatingField={currentUpdatingField}
                            setCurrentUpdatingField={setCurrentUpdatingField}
                            resetvalue={resetvalue}
                            setResetvalue={setResetvalue}
                            onUpdate={(res) => {
                              if (res.data.instance) {
                                setOrderItem(res.data.instance)
                              }
                              setUpdatingLocked(false)
                            }}
                            onChange={(e, d) => {
                              if (d.value < 0) {
                                toast.error('Please Enter Valid Supplier Price')
                              }
                            }}
                            onStopTyping={(newVal) => {
                              setUpdatingLocked('price')
                            }}
                            textarea={false}
                          />
                        ) : (
                          <>
                            {primaryPricing && (
                              <>
                                Primary {formatTotalPrice(orderItem?.price)}
                                <br />
                                Secondary{' '}
                                {formatTotalPrice(orderItem?.secondary_price)}
                              </>
                            )}
                            {!primaryPricing && (
                              <>
                                {formatTotalPrice(orderItem?.secondary_price)}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form>
                  {orderItem.purchase_order && (
                    <Button
                      size={'mini'}
                      className={'primary sales-tax-btn'}
                      style={{ '--primaryColor': '#46AF46' }}
                      content={'Open Purchase Order'}
                      onClick={() => {
                        setPurchaseOrder(orderItem.purchase_order)
                        setAddPurchaseOrderModal(true)
                      }}
                    />
                  )}
                </Grid.Column>
                <Grid.Column>
                  <Header as={'h3'}>Buyer</Header>
                  <Form>
                    {!orderItem?.is_buyer_marketplace_fee && (
                      <>
                        <div style={{ marginTop: '10px' }}>
                          {hasPermission(user, 'Read Sales Order') && (
                            <MickeyDropdown
                              url={'/main/admin-sales-order/'}
                              allowEmpty
                              defaultValue={
                                props.orderItem?.sales_order_item?.id
                              }
                              isForm={true}
                              label={'Sales Order #'}
                              tenantAware={false}
                              params={{
                                business_id:
                                  props.orderItem?.order?.buyer?.business?.id,
                                product_variant_id:
                                  props.orderItem?.product_variant?.id,
                              }}
                              placeholder={
                                'Select Sales Order Associated to Product'
                              }
                              titleFields={['sales_order_number']}
                              descriptionFields={[]}
                              descriptionFieldSeparator={' '}
                              hideDescriptionFieldParenthesis
                              description
                              onChange={(e, d) => {
                                if (d.value) {
                                  getSalesOrder(d.value)
                                } else {
                                  updateSalesOrder()
                                }
                              }}
                            />
                          )}
                          {props.canEdit ? (
                            <AutoDropdown
                              optionsUrl={'/main/unit-of-measures/'}
                              optionsParams={{
                                product_variant_id:
                                  orderItem?.product_variant_id,
                              }}
                              setParentOptions={setBuyerUnitParentOptions}
                              optionTitleField={'plural_name'}
                              disabled={
                                orderItem?.locked ||
                                !props.canEdit ||
                                updatingLocked ||
                                !canUpdate
                              }
                              label="Buyer Unit of Measure"
                              model={'OrderItem'}
                              field={'buyer_unit'}
                              updating_fields={[
                                'buyer_quantity',
                                'delivered_price',
                              ]}
                              serializer={'OrderItemSerializer'}
                              instanceId={orderItem?.id}
                              value={orderItem?.buyer_unit?.id}
                              placeholder={'Unit'}
                              titleFields={['plural_name']}
                              descriptionFields={[]}
                              style={{
                                width: '95px',
                              }}
                              onUpdate={(res) => {
                                if (res?.data?.instance) {
                                  setOrderItem(res.data.instance)
                                  setResetvalue(true)
                                }
                              }}
                              onChange={async (e, d) => {
                                setOrderItem({
                                  ...orderItem,
                                  buyer_unit: _.find(
                                    buyerUnitParentOptions,
                                    function (x) {
                                      return x.id == d?.value
                                    }
                                  ),
                                })
                                setReload(true)
                              }}
                            />
                          ) : (
                            orderItem?.buyer_unit?.short_name
                          )}
                        </div>
                      </>
                    )}
                    <div style={{ height: '15px' }}></div>
                    {!orderItem?.is_buyer_marketplace_fee && (
                      <>
                        {props.canEdit ? (
                          <>
                            <AutoField
                              icon={
                                <Icon
                                  circular
                                  className={'auto-field-icon-extra'}
                                >
                                  <b>
                                    {'$/' + orderItem?.buyer_unit?.short_name}
                                  </b>
                                </Icon>
                              }
                              iconPosition={'right'}
                              disabled={
                                orderItem?.locked ||
                                !props.canEdit ||
                                (updatingLocked &&
                                  (primaryPricing
                                    ? updatingLocked !== 'delivered_price'
                                    : updatingLocked !==
                                      'secondary_delivered_price')) ||
                                !canUpdate
                              }
                              serializer={'OrderItemSerializer'}
                              model={'OrderItem'}
                              field={
                                primaryPricing
                                  ? 'delivered_price'
                                  : 'secondary_delivered_price'
                              }
                              label={
                                primaryPricing
                                  ? 'Buyer Primary Price'
                                  : 'Buyer Price'
                              }
                              updating_fields={[
                                'mickey_take',
                                'mickey_unit_take',
                                'take_rate',
                              ]}
                              url={'/main/order-items/'}
                              instanceId={orderItem?.id}
                              value={
                                primaryPricing
                                  ? orderItem?.delivered_price
                                  : orderItem?.secondary_delivered_price
                              }
                              placeholder="Enter Delivered Price"
                              type="number"
                              onStopTyping={(newVal) => {
                                if (primaryPricing) {
                                  setUpdatingLocked('delivered_price')
                                } else {
                                  setUpdatingLocked('secondary_delivered_price')
                                }
                              }}
                              step="0.01"
                              size={'small'}
                              style={{}}
                              canNotTakeNeagativeValue={true}
                              currentUpdatingField={currentUpdatingField}
                              setCurrentUpdatingField={setCurrentUpdatingField}
                              resetvalue={resetvalue}
                              setResetvalue={setResetvalue}
                              onUpdate={(res) => {
                                if (res.data.instance) {
                                  setOrderItem(res.data.instance)
                                }
                                setUpdatingLocked(false)
                              }}
                              onChange={(e, d) => {
                                setReload(true)
                                if (d.value < 0) {
                                  toast.error(
                                    'Please Enter Valid Delivered Price'
                                  )
                                }
                              }}
                              textarea={false}
                            />
                          </>
                        ) : (
                          <>
                            {primaryPricing && (
                              <>
                                Primary{' '}
                                {formatTotalPrice(orderItem?.delivered_price)}
                                <br />
                                Secondary{' '}
                                {formatTotalPrice(
                                  orderItem?.secondary_delivered_price
                                )}
                              </>
                            )}
                            {!primaryPricing && (
                              <>
                                {formatTotalPrice(
                                  orderItem?.secondary_delivered_price
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {primaryPricing && (
                      <AutoField
                        icon={
                          <Icon circular className={'auto-field-icon-extra'}>
                            <b>{'$/' + orderItem?.buyer_unit?.short_name}</b>
                          </Icon>
                        }
                        iconPosition={'right'}
                        disabled={
                          orderItem?.locked ||
                          (updatingLocked &&
                            updatingLocked !== 'delivered_price') ||
                          !canUpdate
                        }
                        serializer={'OrderItemSerializer'}
                        model={'OrderItem'}
                        field={'secondary_delivered_price'}
                        label={'Buyer Secondary Price'}
                        updating_fields={[
                          'mickey_take',
                          'mickey_unit_take',
                          'take_rate',
                        ]}
                        url={'/main/order-items/'}
                        instanceId={orderItem?.id}
                        value={orderItem?.secondary_delivered_price}
                        placeholder="Enter Delivered Price"
                        type="number"
                        onStopTyping={(newVal) => {
                          setUpdatingLocked('secondary_delivered_price')
                        }}
                        step="0.01"
                        size={'small'}
                        style={{}}
                        canNotTakeNeagativeValue={true}
                        currentUpdatingField={currentUpdatingField}
                        setCurrentUpdatingField={setCurrentUpdatingField}
                        resetvalue={resetvalue}
                        setResetvalue={setResetvalue}
                        onUpdate={(res) => {
                          if (res.data.instance) {
                            setOrderItem(res.data.instance)
                          }
                          setUpdatingLocked(false)
                        }}
                        onChange={(e, d) => {
                          setReload(true)
                          if (d.value < 0) {
                            toast.error('Please Enter Valid Delivered Price')
                          }
                        }}
                        textarea={false}
                      />
                    )}
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  {!orderItem?.is_buyer_marketplace_fee && (
                    <div>
                      <Header as={'h3'}>Fulfillment Status</Header>
                      <PieChart width={420} height={230}>
                        <Legend
                          verticalAlign="top"
                          align={'left'}
                          height={26}
                        />
                        <Pie
                          data={[
                            { name: 'Unfilled', value: notShippedPercentage },
                          ]}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          startAngle={notShippedStartAngle}
                          endAngle={notShippedEndAngle}
                          innerRadius={50}
                          outerRadius={100}
                          fill="rgb(200,200,200)"
                        />
                        <Pie
                          data={[
                            {
                              name: 'Booked Truck',
                              value: bookedTruckPercentage,
                            },
                          ]}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          startAngle={bookedTruckStartAngle}
                          endAngle={bookedTruckEndAngle}
                          innerRadius={50}
                          outerRadius={100}
                          fill="rgb(242, 222, 91)"
                        />
                        <Pie
                          data={[
                            { name: 'In Transit', value: inTransitPercentage },
                          ]}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          startAngle={inTransitStartAngle}
                          endAngle={inTransitEndAngle}
                          innerRadius={50}
                          outerRadius={100}
                          fill="rgb(242, 22, 91)"
                        />
                        <Pie
                          data={[
                            { name: 'Delivered', value: deliveredPercentage },
                          ]}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          startAngle={deliveryStartAngle}
                          endAngle={deliveryEndAngle}
                          innerRadius={50}
                          outerRadius={100}
                          fill="rgb(103, 168, 131)"
                        />
                      </PieChart>
                    </div>
                  )}
                </Grid.Column>
              </Grid>
            </Table.Cell>
          </Table.Row>
          <Table.Row selectable compact className="material-row selected">
            <Table.Cell
              colSpan={16}
              style={{
                backgroundColor: '#F8F8F8',
              }}
            >
              <Grid stackable columns={4} style={{ padding: '25px' }}>
                <Grid.Column>
                  <Header as={'h3'}>
                    {displayPriceNegotiation && (
                      <CaretDown
                        size={20}
                        onClick={() =>
                          setDisplayPriceNegotiation(!displayPriceNegotiation)
                        }
                      />
                    )}
                    {!displayPriceNegotiation && (
                      <CaretRight
                        size={20}
                        onClick={() =>
                          setDisplayPriceNegotiation(!displayPriceNegotiation)
                        }
                      />
                    )}
                    <span style={{ marginTop: '10px' }}>
                      Supplier Price Negotiation
                    </span>
                  </Header>
                </Grid.Column>
              </Grid>
              {displayPriceNegotiation && (
                <OrderItemPriceOffers orderItemId={orderItem?.id} />
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row selectable compact className="material-row selected">
            <Table.Cell
              colSpan={16}
              style={{
                backgroundColor: '#F8F8F8',
              }}
            >
              <Grid stackable columns={4} style={{ padding: '25px' }}>
                <Grid.Column>
                  <Header as={'h3'}>
                    {displayImages && (
                      <CaretDown
                        size={20}
                        onClick={() => setDisplayImages(false)}
                      />
                    )}
                    {!displayImages && (
                      <CaretRight
                        size={20}
                        onClick={() => setDisplayImages(true)}
                      />
                    )}
                    <span style={{ marginTop: '10px' }}>Scrap Images</span>
                  </Header>
                </Grid.Column>
              </Grid>
              {displayImages && (
                <>
                  {orderItem.order_item_images.length > 0 && (
                    <ImageGallery
                      items={orderItem.order_item_images.map((image) => ({
                        original: image.default_image,
                        thumbnail: image.thumbnail_image,
                      }))}
                    />
                  )}
                  {orderItem.order_item_images.length === 0 && (
                    <Grid
                      stackable
                      columns={4}
                      style={{
                        padding: '25px',
                        marginTop: '-45px',
                        marginLeft: '20px',
                      }}
                    >
                      <Grid.Column>
                        <h3>No Images</h3>
                      </Grid.Column>
                    </Grid>
                  )}
                </>
              )}
            </Table.Cell>
          </Table.Row>
        </>
      )}
      <Modal
        onClose={() => setOpenDiscountModal(false)}
        onOpen={() => setOpenDiscountModal(true)}
        open={openDiscountModal}
      >
        <Modal.Header>
          Edit Discount to Order Item {orderItem?.string_token}
        </Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'OrderItemDiscountForm'}
            model={'OrderItem'}
            instanceId={orderItem?.string_token}
            instanceIdOverwrite={orderItem?.id}
            url={'/main/admin-order-items/'}
            onUpdate={(fields) => {
              setOrderItem({
                ...orderItem,
                discount: fields['discount'],
              })
              props.setOrderItem({
                ...orderItem,
                discount: fields['discount'],
              })
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default AdminOrderItemsRow
