import React, { useState, useEffect, useCallback } from 'react'
import useAxios from '../../lib/auth/useAxios'
import PhoneInput from 'react-phone-input-2'
import lookup from 'country-code-lookup'
import {
  Input,
  Dropdown,
  Form,
  Checkbox,
  Button,
  TextArea,
  Modal,
  Loader,
  Flag,
} from 'semantic-ui-react'
import _ from 'underscore'
import MickeySearch from '../Search/MickeySearch'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import './MickeyForm.css'
import MickeyDropdown from '../MickeyDropdown/MickeyDropdown'
import 'react-phone-input-2/lib/style.css'
import { objFull, arraysEqual } from '../../lib/utils/utils'
import MickeyForm from './MickeyForm'
import env from '../../env'
import { toast } from 'react-toastify'

function MickeyFormField(props) {
  const {
    disabledDefaultFields,
    instanceLabel,
    instanceId,
    handleInputChangeComplete,
    setHandleInputChangeComplete,
  } = props
  const [dependencies, setDependencies] = useState(props.dependencies)
  const [multiModelFetchLoading, setMultiModelFetchLoading] = useState(false)
  const { field, checkDependencies, HandleDropdownOption } = props
  const [key, setKey] = useState(props.keyValue)
  const [options, setOptions] = useState(null)
  const [labelContext, setLabelContexts] = useState([])
  const [reloadOptions, setReloadOptions] = useState(true)
  const [dropdownSearchParams, setDropdownSearchParams] = useState({})
  const [hasAllSearchParams, setHasAllSearchParams] = useState(false)
  const [hasAllAllowAddRelatedParams, setHasAllAllowAddRelatedParams] =
    useState(false)
  const [addModelUrl, setAddModelUrl] = useState(false)
  const [addModelForm, setAddModelForm] = useState(false)
  const [addModelModel, setAddModelModel] = useState(false)
  const [addModelModalOpen, setAddModelModalOpen] = useState(false)
  const [allowAddParams, setAllowAddParams] = useState({})
  const [allowAddOptions, setAllowAddOptions] = useState([])
  const [lastSendedKeys, setLastSendedKeys] = useState([])
  const [hasRequiredUrlKeys, sethasRequiredUrlKeys] = useState(true)
  const [reload, setReload] = useState(false)
  const axios = useAxios()
  const keyCurrentValue = props.values[key]

  useEffect(() => {
    setKey(props.keyValue)
    setDependencies(props.dependencies)
  }, [])

  const countryOptions = () => {
    const options = lookup.countries.map((country) => {
      return {
        key: country.iso2,
        value: country.iso2,
        flag: country.iso2.toLowerCase(),
        text: country.country,
      }
    })
    return options
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      props.updateDatabaseValue(key, field, field?.modelOverride)
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [keyCurrentValue])

  useEffect(() => {
    if (field.renderLabelContexts && props.values && key) {
      field.renderLabelContexts(
        instanceId,
        props.values[key],
        setLabelContexts,
        axios
      )
      if (handleInputChangeComplete) {
        setHandleInputChangeComplete(false)
      }
    }
  }, [field.renderLabelContexts, props.values, key, handleInputChangeComplete])

  useEffect(async () => {
    if (field?.require_url_keys) {
      let deps = []
      field?.require_url_keys?.map((key) => {
        if (key in props?.values && props?.values[key])
          deps.push(props?.values[key])
      })
      if (!arraysEqual(deps, lastSendedKeys)) {
        setLastSendedKeys(deps)
      }
    }
    let relatedParams = {}
    if (field.related_params) {
      // get value of related_param
      for (const [k, value] of Object.entries(field.related_params)) {
        let val = props.values[k]
        val = typeof val === 'object' ? val?.id : val
        relatedParams[value] = val
      }
    }
    if (field.params) {
      relatedParams = { ...relatedParams, ...field.params }
    }
    if (field.url_params) {
      if (field.url_params.includes('business_id')) {
        relatedParams = { ...relatedParams, business_id: props?.business?.id }
      }
    }
    if (
      objFull(relatedParams) &&
      JSON.stringify(dropdownSearchParams) !== JSON.stringify(relatedParams)
    ) {
      setHasAllSearchParams(false)
      setReloadOptions(true)
      setDropdownSearchParams(relatedParams)
    }
    let allowAddRelatedParams = {}
    if (field.allow_add_related_params) {
      setHasAllAllowAddRelatedParams(false)
      // get value of allow_add_related_params
      for (const [k, value] of Object.entries(field.allow_add_related_params)) {
        let val = props.values[k]
        val = typeof val === 'object' ? val?.id : val
        allowAddRelatedParams[value] = val
      }
    }
    if (field.allow_add_params) {
      allowAddRelatedParams = {
        ...allowAddRelatedParams,
        ...field.allow_add_params,
      }
    }
    if (
      field.allow_add_url_params &&
      field.url_params.includes('business_id')
    ) {
      allowAddRelatedParams = {
        ...allowAddRelatedParams,
        business_id: props?.business?.id,
      }
    }
    if (objFull(allowAddRelatedParams)) {
      setAllowAddParams(allowAddRelatedParams)
    }
  }, [field, props.values])

  useEffect(() => {
    setHasAllSearchParams(hasAllRelatedParams())
  }, [JSON.stringify(dropdownSearchParams)])

  useEffect(() => {
    setHasAllAllowAddRelatedParams(hasAllAllowAddParams())
  }, [allowAddParams])

  function addModel(field) {
    setAddModelModel(field.model)
    setAddModelForm(field.model_form)
    setAddModelUrl(field.url)
    setAddModelModalOpen(true)
  }

  const getFieldChoices = useCallback(async () => {
    let fetchArgs = [axios]
    if (field?.require_url_keys) {
      if (field?.require_url_keys?.length !== lastSendedKeys.length) {
        sethasRequiredUrlKeys(false)
        return
      }
      sethasRequiredUrlKeys(true)
      fetchArgs.push(lastSendedKeys)
    }
    if (props.choicesFetchArgs?.hasOwnProperty(key)) {
      fetchArgs = [...fetchArgs, ...props.choicesFetchArgs[key]]
    }
    let results = await field.fetch(...fetchArgs)
    setOptions([...results, ...allowAddOptions])
  }, [lastSendedKeys])

  useEffect(() => {
    async function getChoices() {
      if (
        (field.type === 'dropdown' || field.type == 'multiple dropdown') &&
        reloadOptions
      ) {
        if (field.fetch) {
          getFieldChoices()
        } else {
          let res = await axios.get(
            `/main/choices/?model=${props.model}&field=${key}`,
            {
              params: {
                page_size: 'infinite',
              },
            }
          )
          if (res?.data?.choices)
            setOptions([...res?.data?.choices, ...allowAddOptions])
        }
      } else if (field.type === 'multiple dropdown model') {
        setMultiModelFetchLoading(true)
        const multiChoiceModels = await axios.get(field.url, {
          params: {
            page_size: 'infinite',
          },
        })
        const options_ = []
        multiChoiceModels.data.results.forEach((result) => {
          let text
          let disclude = false
          if (field.disclude_options) {
            field.disclude_options.forEach((discludeOption) => {
              if (
                !disclude &&
                result[discludeOption['field_name']] ===
                  discludeOption['field_value']
              ) {
                disclude = true
              }
            })
          }
          if (!disclude) {
            if ('option_text_field' in field) {
              text = result[field.option_text_field]
            } else if ('option_text_fields' in field) {
              field.option_text_fields.forEach((textField) => {
                if (text) {
                  text = `${text} ${result[textField]}`
                } else {
                  text = result[textField]
                }
              })
            }
            options_.push({
              key: result.id,
              text: text,
              value: result.id,
            })
          }
        })
        setOptions(options_)
        setMultiModelFetchLoading(false)
      }
    }

    if (field.show_allow_add_as_option && field.allow_add_option_text) {
      setAllowAddOptions([
        {
          text: field.allow_add_option_text,
          value: field.allow_add_option_text,
          key: field.allow_add_option_text,
          content: (
            <b style={{ color: env.REACT_APP_PRIMARY_COLOR }}>
              {field.allow_add_option_text}
            </b>
          ),
          onClick: () => addModel(field),
        },
      ])
    }
    getChoices()
  }, [getFieldChoices])

  useEffect(() => {
    if (field?.fetch) {
      getFieldChoices()
    }
  }, [lastSendedKeys, getFieldChoices])

  function hasAllRelatedParams() {
    if (field.url_params) {
      if (
        field.url_params.includes('business_id') &&
        !dropdownSearchParams['business_id']
      ) {
        return false
      }
    }

    if (props.field.related_params) {
      for (const value of Object.values(props.field.related_params)) {
        if (!dropdownSearchParams[value]) {
          return false
        }
      }
    }
    return true
  }

  function hasAllAllowAddParams() {
    if (props.field.allow_add_related_params) {
      for (const value of Object.values(props.field.allow_add_related_params)) {
        if (!allowAddParams[value]) {
          return false
        }
      }
    }
    return true
  }

  function handleAddModalForm(status, data) {
    if (status === 'success') {
      setAddModelModalOpen(false)
      if (field.allow_add_related_params) {
        for (const [k, value] of Object.entries(
          field.allow_add_related_params
        )) {
          props.setValues({
            ...props.values,
            [k]: allowAddParams[value],
          })
        }
      }
      setReloadOptions(true)
    }
  }

  const roundNumber = (value, round) => {
    let rounded = value
    if (value) {
      try {
        rounded = Math.round(parseFloat(value) * 100) / Math.pow(10, round)
      } catch {}
    }
    return rounded
  }
  let disabledDefaultField
  let disabledDefaultOptions
  if (
    disabledDefaultFields &&
    field.disabled_default_field &&
    disabledDefaultFields.hasOwnProperty(key)
  ) {
    const defaultField = disabledDefaultFields[key]
    disabledDefaultField = defaultField.value
    if (defaultField.options_value) {
      disabledDefaultOptions = [
        {
          key: disabledDefaultField,
          text: defaultField.options_value,
          value: disabledDefaultField,
        },
      ]
    }
    const data = {
      name: key,
    }
    if (defaultField.type === 'checkbox') {
      data.checked = defaultField.value
    } else {
      data.value = defaultField.value
    }
    props.handleInputChange(null, data)
  }

  const defaultRenderItemContent = (item) => {
    const { text } = item
    return {
      content: <>{text}</>,
    }
  }

  if (props.hiddenFields && props.hiddenFields.includes(key)) return <></>
  return (
    <>
      {key && checkDependencies(key, 'hidden') && props.values && (
        <Form.Field
          key={key}
          required={field.required}
          className={
            'mickey-form-field ' + field?.fieldClassName
              ? field?.fieldClassName
              : ''
          }
        >
          {field && !field?.hide_label && (
            <div>
              <label
              // className={props.labelSuccessField === key ? 'pulse-green-text' : ''}
              >
                {field.label}
                {field.required && (
                  <span style={{ color: 'indianred' }}> *</span>
                )}
                {props.labelSuccessField && (
                  <label
                    className={
                      props.labelSuccessField === key
                        ? 'saved-label saving'
                        : 'saved-label'
                    }
                  >
                    {' '}
                    Saved!
                  </label>
                )}
                {field.allow_add && !field.show_allow_add_as_option ? (
                  <Button
                    disabled={
                      field.require_params && !hasAllAllowAddRelatedParams
                    }
                    size={'mini'}
                    type={'button'}
                    basic
                    circular
                    icon={'plus'}
                    onClick={() => {
                      addModel(field)
                    }}
                    className={'grey-basic-hover'}
                    color={'grey'}
                    style={{ 'margin-bottom': '5px' }}
                    floated={'right'}
                  />
                ) : (
                  ''
                )}
              </label>
            </div>
          )}
          {field.type === 'dropdown search' && (
            <MickeyDropdown
              disabled={
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                (field.disabled_default_field && disabledDefaultFields)
              }
              hasRequiredParams={field.require_params && !hasAllSearchParams}
              url={field.url}
              openOnFocus={false}
              name={key}
              field={field}
              filterField={field.filter_field}
              tenantAware={props.tenantAware}
              params={dropdownSearchParams}
              allowAddOptions={allowAddOptions}
              reloadOptions={reloadOptions}
              setReloadOptions={setReloadOptions}
              results={disabledDefaultOptions ? disabledDefaultOptions : null}
              setValues={props.setValues}
              defaultValue={
                disabledDefaultField
                  ? disabledDefaultField
                  : typeof props.values[key] === 'object'
                  ? props.values[key]?.id
                  : props.values[key]
              }
              autoComplete="new-password"
              placeholder={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              titleFields={field.title_fields}
              descriptionFields={field.description_fields}
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'dropdown' && (
            <Dropdown
              placeholder={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              fluid
              search
              renderLabel={
                field.renderLabel
                  ? (item) =>
                      field.renderLabel(
                        item,
                        labelContext,
                        instanceId,
                        props.values[key] ? props.values[key] : [],
                        setLabelContexts,
                        field.renderLabelContexts,
                        axios
                      )
                  : defaultRenderItemContent
              }
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled') ||
                !hasRequiredUrlKeys
              }
              autoComplete="new-password"
              selection
              label={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              name={key}
              key={key}
              value={
                disabledDefaultField ? disabledDefaultField : props.values[key]
              }
              options={
                disabledDefaultOptions ? disabledDefaultOptions : options
              }
              onChange={(e, d) => {
                props.handleInputChange(e, d)
                if (HandleDropdownOption && key in HandleDropdownOption) {
                  let customOnClickExist = HandleDropdownOption[key]?.find(
                    (option) =>
                      'customOnClick' in option && option?.value === d?.value
                  )
                  if (customOnClickExist)
                    customOnClickExist.customOnClick(lastSendedKeys)
                }
              }}
            />
          )}

          {'multiple dropdown' === field.type && (
            <Dropdown
              id="multiple-dropdown"
              placeholder={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              fluid
              search
              multiple
              renderLabel={
                field.renderLabel
                  ? (item) =>
                      field.renderLabel(
                        item,
                        labelContext,
                        instanceId,
                        props.values[key] ? props.values[key] : [],
                        setLabelContexts,
                        field.renderLabelContexts,
                        axios
                      )
                  : defaultRenderItemContent
              }
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              autoComplete="new-password"
              selection
              label={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              name={key}
              key={key}
              value={
                disabledDefaultField
                  ? disabledDefaultField
                  : props.values[key]
                  ? props.values[key]
                  : []
              }
              options={
                disabledDefaultOptions ? disabledDefaultOptions : options
              }
              style={{ ...field?.fieldStyle }}
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {'multiple dropdown model' === field.type && (
            <>
              {multiModelFetchLoading && (
                <>
                  <Loader active inline="centered">
                    Loading{' '}
                    {field.label ? (
                      <>{field.label}...</>
                    ) : (
                      <>{key.charAt(0).toUpperCase() + key.slice(1)}...</>
                    )}
                  </Loader>
                </>
              )}
              {!multiModelFetchLoading && (
                <Dropdown
                  id="multiple-dropdown"
                  placeholder={
                    field.label
                      ? field.label
                      : key.charAt(0).toUpperCase() + key.slice(1)
                  }
                  fluid
                  search
                  multiple={field.singleResult ? false : true}
                  type={field.type}
                  disabled={
                    field.disabled ||
                    props.disabled ||
                    (props.disabledFields && props.disabledFields[key]) ||
                    !checkDependencies(key, 'disabled')
                  }
                  autoComplete="new-password"
                  selection
                  label={
                    field.label
                      ? field.label
                      : key.charAt(0).toUpperCase() + key.slice(1)
                  }
                  name={key}
                  renderLabel={
                    field.renderLabel
                      ? (item) =>
                          field.renderLabel(
                            item,
                            labelContext,
                            instanceId,
                            props.values[key] ? props.values[key] : [],
                            setLabelContexts,
                            field.renderLabelContexts,
                            axios
                          )
                      : defaultRenderItemContent
                  }
                  key={key}
                  value={props.values[key] ? props.values[key] : []}
                  options={options}
                  style={{ ...field?.fieldStyle }}
                  onChange={(e, d) => {
                    props.handleInputChange(e, d)
                  }}
                />
              )}
            </>
          )}
          {field.type === 'checkbox' && (
            <Checkbox
              name={key}
              value={
                disabledDefaultField
                  ? disabledDefaultField
                  : props.values[key]
                  ? props.values[key]
                  : ''
              }
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              checked={props.values[key]}
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'search' && (
            <MickeySearch
              url={field.url}
              name={key}
              value={
                disabledDefaultField ? disabledDefaultField : props.values[key]
              }
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              autoComplete="new-password"
              tenantAware={props.tenantAware}
              urlParams={field?.params}
              filter={field.filter}
              results={disabledDefaultOptions ? disabledDefaultOptions : null}
              placeholder={
                'Search ' + field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              titleFields={field.title_fields ? field.title_fields : ['id']}
              descriptionFields={field.description_fields}
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
              defaultResults={
                props.defaultSearchResults[key]
                  ? props.defaultSearchResults[key]
                  : []
              }
            />
          )}
          {field.type === 'text' && (
            <Input
              placeholder={
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
                  ? ''
                  : field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              name={key}
              type={'text'}
              icon={field.icon}
              autoComplete="new-password"
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              defaultValue={
                disabledDefaultField
                  ? disabledDefaultField
                  : props.values[key]
                  ? props.values[key]
                  : ''
              }
              onChange={(e, d) => {
                props.handleInputChange(e, d)
                if (
                  field?.max_length &&
                  d?.value?.length === field?.max_length
                ) {
                  toast.warning(
                    `Maximum character limit has been reached. You cannot enter any new characters from now.`
                  )
                }
              }}
              maxLength={field?.max_length}
            />
          )}
          {field.type === 'text area' && (
            <TextArea
              placeholder={
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
                  ? ''
                  : field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              rows={field?.rows ? field?.rows : 3}
              name={key}
              type={'text'}
              autoComplete="new-password"
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              value={
                disabledDefaultField ? disabledDefaultField : props.values[key]
              }
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'number' && (
            <Input
              placeholder={field.label}
              name={key}
              min={field.min}
              icon={field.icon}
              max={field.max}
              type={'number'}
              step={field.step ? field.step : '1'}
              label={field.inputLabel ? field.inputLabel : null}
              labelPosition={field.labelPosition ? field.labelPosition : null}
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              defaultValue={
                disabledDefaultField
                  ? disabledDefaultField
                  : field.round
                  ? roundNumber(props.values[key], field.round)
                  : props.values[key]
              }
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'date' && (
            <div className="customDatePickerWidth">
              <SemanticDatepicker
                onChange={(e, d) => {
                  props.handleInputChange(e, d)
                }}
                filterDate={field.filterDate}
                clearable={true}
                autoComplete="nope"
                disabled={
                  (field.disabled_default_field && disabledDefaultFields) ||
                  field.disabled ||
                  props.disabled ||
                  (props.disabledFields && props.disabledFields[key]) ||
                  !checkDependencies(key, 'disabled')
                }
                name={key}
                value={
                  disabledDefaultField
                    ? disabledDefaultField
                    : props.values[key]
                }
                format={'MMM D, YYYY'}
              />
            </div>
          )}
          {field.type === 'file' && (
            <Input
              placeholder={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              name={key}
              type={'file'}
              disabled={
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'phone' && (
            <PhoneInput
              containerClass={`ui input ${
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
                  ? 'disabled'
                  : ''
              }`}
              country={'us'}
              disableDropdown={true}
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                field.disabled ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              countryCodeEditable={false}
              placeholder={field.label}
              value={
                disabledDefaultField ? disabledDefaultField : props.values[key]
              }
              onChange={(value, country, e) => {
                const d = { name: key, type: field.type, value }
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'country' && (
            <Dropdown
              placeholder={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              fluid
              search
              multiple={false}
              disabled={
                (field.disabled_default_field && disabledDefaultFields) ||
                props.disabled ||
                (props.disabledFields && props.disabledFields[key]) ||
                !checkDependencies(key, 'disabled')
              }
              autoComplete="new-password"
              selection
              label={
                field.label
                  ? field.label
                  : key.charAt(0).toUpperCase() + key.slice(1)
              }
              name={key}
              type={'country'}
              defaultValue={
                disabledDefaultField ? disabledDefaultField : props.values[key]
              }
              options={countryOptions()}
              onChange={(e, d) => {
                props.handleInputChange(e, d)
              }}
            />
          )}
          {field.type === 'custom field' &&
            field?.custom_field_components(
              key,
              props.values[key],
              props.handleInputChange,
              props.labelSuccessField === key,
              instanceLabel,
              props.values,
              field.error
            )}
          {!field?.hide_error && field.error && (
            <p style={{ color: 'red' }}>
              <small>{field.error}</small>
            </p>
          )}
        </Form.Field>
      )}
      <Modal
        onClose={() => setAddModelModalOpen(false)}
        onOpen={() => setAddModelModalOpen(true)}
        open={addModelModalOpen}
      >
        <Modal.Header>
          Add {field?.model_header_name ? field?.model_header_name : ''}
        </Modal.Header>
        <Modal.Content>
          <MickeyForm
            currentPortal={props.currentPortal}
            formName={addModelForm}
            model={addModelModel}
            reload={reload}
            setReload={setReload}
            values={allowAddParams}
            successMessage={'Added!'}
            failureMessage={'Error adding, please try again.'}
            url={addModelUrl}
            tenantAware={props.tenantAware}
            handleForm={handleAddModalForm}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default MickeyFormField
