import { useEffect, useState } from 'react'
import useAxios from '../../lib/auth/useAxios'
import { Dropdown, Loader } from 'semantic-ui-react'
import './PriceHistoryTable.css'

function ProductAttributeFormFields(props) {
  const {
    fieldName,
    value,
    handleInputChange,
    labelSuccessField,
    instanceLabel,
    values,
    error,
  } = props
  const axios = useAxios()
  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)
  const [attributeSelectionsFromDropdown, setAttributeSelectionsFromDropdown] =
    useState({})

  useEffect(() => {
    async function getAttributes() {
      setLoading(true)
      let productId =
        typeof values?.product === 'object'
          ? values?.product?.id
          : values?.product
      let res = await axios.get(`/main/product-filter/`, {
        params: {
          product_id: productId,
        },
      })
      if (res?.data) {
        if (typeof values?.product === 'object') {
          let attributeSelections = {}
          values?.product_variant?.attribute_selections?.map((as) => {
            attributeSelections[as?.attribute_name] = as?.attribute_selection_id
          })
          setAttributeSelectionsFromDropdown(attributeSelections)
        } else {
          let val = res.data?.attribute_selections?.map((a) => {
            return 0
          })
          let d = {
            name: fieldName,
            value: val,
          }
          handleInputChange('', d)
        }
        setAttributes(res.data?.attribute_selections)
      }
      setLoading(false)
    }

    if (values?.product) {
      getAttributes()
    }
  }, [values?.product])

  if (!values?.product) return <></>

  const handleClick = (id, index) => {
    let val = value
      ? value
      : attributes.map((a) => {
          return 0
        })
    val.splice(index, 1, id)
    let d = {
      name: fieldName,
      value: val,
    }
    handleInputChange('', d)
  }

  return (
    <div className="pv-attribute-field">
      {loading && (
        <Loader active inline="centered">
          Loading Attributes...
        </Loader>
      )}
      {attributes &&
        attributes?.map((attribute, idx) => {
          return (
            <>
              <label>
                {attribute.name}
                <span> *</span>
              </label>
              <Dropdown
                label={attribute.name}
                fluid
                search
                selection
                disabled={typeof values?.product === 'object'}
                value={attributeSelectionsFromDropdown[attribute.name]}
                style={{
                  marginBottom: attributes?.length !== idx + 1 ? '1em' : '',
                }}
                placeholder={`Select ${attribute.name}`}
                onChange={(e, d) => {
                  handleClick(d.value, idx)
                  setAttributeSelectionsFromDropdown({
                    ...attributeSelectionsFromDropdown,
                    [attribute.name]: d.value,
                  })
                }}
                options={attribute?.attribute_selections?.map((selection) => {
                  return {
                    key: selection.id,
                    value: selection.id,
                    text: selection.value,
                  }
                })}
              />
              {error && value[idx] === 0 && (
                <p style={{ color: 'red' }}>
                  <small>This field is required</small>
                </p>
              )}
            </>
          )
        })}
    </div>
  )
}

export default ProductAttributeFormFields
