import { Modal, Button, Grid } from 'semantic-ui-react'
import MickeyForm from '../Forms/MickeyForm'
import useAxios from '../../lib/auth/useAxios'
import env from '../../env'
import './Permissions.css'

const GroupModal = (props) => {
  const { group, showModal, toggleModal, setReload } = props
  const axios = useAxios()

  if (!showModal) return <></>

  const formButton = [
    {
      content: 'Cancel',
      style: {
        width: '45%',
        textAlign: !group ? 'end' : 'center',
        '--secondaryColor': 'white',
        '--primaryColor': 'darkgray',
      },
      onClick: () => {
        toggleModal()
        if (group) setReload(true)
      },
    },
  ]

  if (!group) {
    formButton.push({
      content: 'Apply',
      type: 'submit',
      disabled: true,
      style: {
        width: '55%',
        position: 'relative',
        '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
      },
    })
  }

  return (
    <>
      <Modal
        onClose={() => {
          toggleModal()
          if (group) setReload(true)
        }}
        open={showModal}
        closeIcon
        className={'group-modal-form'}
        size={'medium'}
      >
        <Modal.Header>
          {group && <>Update {group.name} Group</>}
          {!group && <>Add New Group</>}
        </Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'GroupForm'}
            model={'Group'}
            tenantAware={false}
            handleForm={() => {
              if (!group) {
                setReload(true)
                toggleModal()
              }
            }}
            instanceId={group?.id}
            instanceLabel={group?.name}
            url={'/main/group/'}
            buttons={formButton}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default GroupModal
