export const MarkOrderDeliveredForm = {
  model: 'CargoUnitInstance',
  format: [
    {
      widths: 'equal',
      fields: {
        proof_of_delivery: {
          label: 'Proof of Delivery',
          type: 'file',
        },
      },
    },
  ],
}
