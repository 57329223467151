import React, { useState, useEffect } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Dropdown } from 'semantic-ui-react'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import './Profile.css'

const SelectProductListingTriggers = (props) => {
  const axios = useAxios()
  const [options, setOptions] = useState([])
  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getData() {
      setLoading(true)
      let res = await axios.get('/main/products/')
      let response = res?.data?.results?.map((choice) => {
        return {
          key: choice?.id,
          value: choice?.id,
          text: `${choice?.material.name} (${choice?.commodity_form?.name} - ${choice?.commodity?.name})`,
        }
      })

      setOptions(response)
      setLoading(false)
    }
    getData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      props.setProductAttributes({})
      let res = await axios.get(`/main/product-filter/`, {
        params: {
          product_id: props.product,
        },
      })
      let attributes_pos = Object.values(res.data.attribute_selections).map(
        (value) => {
          return value?.name
        }
      )
      setAttributes(res.data.attribute_selections)
      props.setProductAttributesPos(attributes_pos)
      setLoading(false)
    }
    if (props?.product) {
      fetchData()
    }
  }, [props?.product])

  return (
    <div style={{ width: '90%', margin: '0 auto 5rem auto' }}>
      <>
        <label>Location</label>
        <br />
        <MickeyDropdown
          url={'/main/locations/'}
          value={props.selectedLocation}
          autoComplete="new-password"
          tenantAware={false}
          params={{
            business_id: props.business?.id,
            page_size: 25,
          }}
          placeholder={'Select Location'}
          titleFields={['name', 'address_1']}
          descriptionFields={['city', 'state', 'zip_code']}
          onChange={(e, d) => {
            props.setSelectedLocation(d.value)
          }}
        />
      </>
      <>
        <label>Product</label>
        <br />
        <Dropdown
          fluid
          selection
          className={'auto-dropdown'}
          icon={props.icon}
          style={{
            marginBottom: '1rem',
          }}
          autoComplete="new-password"
          label="Product"
          placeholder="Select Product"
          value={props.triggerType}
          basic={props.basic}
          disabled={props.disabled}
          onChange={(event, data) => {
            props.setProduct(data?.value)
          }}
          loading={loading}
          options={options}
        />
      </>
      <>
        {attributes &&
          attributes?.map((attribute, idx) => {
            return (
              <div key={idx}>
                <label style={{ margin: '5px' }}>{attribute.name}</label>
                <Dropdown
                  label={attribute.name}
                  fluid
                  multiple
                  search
                  selection
                  value={
                    props.attributeValues[attribute.name]
                      ? props.attributeValues[attribute.name]
                      : []
                  }
                  style={{
                    marginBottom: '1rem',
                  }}
                  placeholder={`Select ${attribute.name}`}
                  onChange={(e, d) => {
                    let avs = { ...props.attributeValues }
                    avs[attribute.name] = d.value
                    props.setAttributeValues(avs)
                  }}
                  options={attribute?.attribute_selections?.map((selection) => {
                    return {
                      key: selection.id,
                      value: selection.value,
                      text: selection.value,
                    }
                  })}
                />
              </div>
            )
          })}
      </>
    </div>
  )
}

export default SelectProductListingTriggers
