export const AddEventForm = {
  model: 'Event',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'text',
          required: true,
          label: 'Event name',
        },
        event_link: {
          type: 'text',
          required: true,
          label: 'Event URL',
        },
        event_date: {
          type: 'date',
          required: true,
          label: 'Event Date',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        description: {
          type: 'text area',
          required: true,
          label: 'Event Description',
        },
      },
    },
  ],
}
