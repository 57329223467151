import {
  requiredEmailValidator,
  requiredPhoneNumberValidator,
  requiredCountryValidator,
} from '../components/Forms/validators'

export const PublicOrderBusinessForm = {
  model: 'Business',
  format: [
    {
      header: 'Business Information',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
          label: 'Legal Business Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_first_name: {
          required: true,
          type: 'text',
          label: 'Contact First Name',
        },
        contact_last_name: {
          required: true,
          type: 'text',
          label: 'Contact Last Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_email: {
          required: true,
          type: 'text',
          label: 'Email',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
        contact_phone: {
          type: 'phone',
          label: 'Phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
        business_tax_id: {
          type: 'text',
          label: 'EIN / Tax ID',
        },
      },
    },
    {
      header: 'Delivery Location',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        billing_address_line_1: {
          required: true,
          type: 'text',
          label: 'Address Line One',
        },
        billing_address_line_2: {
          type: 'text',
          label: 'Address Line Two',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        billing_address_country_code: {
          label: 'Country',
          required: true,
          type: 'country',
          validator: (input) => {
            return requiredCountryValidator(input)
          },
        },
        billing_address_state: {
          label: 'State',
          required: true,
          type: 'dropdown search',
          url: '/main/states/',
          require_params: true,
          related_params: {
            billing_address_country_code: 'country',
          },
          title_fields: ['name'],
          description_fields: [''],
        },
        billing_address_city: {
          required: true,
          type: 'text',
          label: 'City',
        },
        billing_address_zip_code: {
          required: true,
          type: 'text',
          label: 'Zip Code',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        on_site_contact_name: {
          required: true,
          type: 'text',
          label: 'On-Site Contact Name',
        },
        email: {
          required: true,
          type: 'text',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
        phone: {
          required: true,
          type: 'phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
      },
    },
  ],
}
