import { useState, useEffect, useCallback } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import env from '../../../env'
import { useAuth } from '../../../lib/auth/useAuth'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
  Button,
  Header,
  Menu,
  Popup,
  Modal,
  Grid,
  PopupContent,
  Divider,
  Dimmer,
} from 'semantic-ui-react'
import MXExchange from '../../mx/mx_shared/MXExchange/MXExchange'
import { CaretDown, Funnel, CheckCircle, Circle } from 'phosphor-react'
import MXWatchlist from '../../mx/mx_shared/MXMain/MXWatchlist'
import MXYourBids from '../../mx/mx_shared/MXMain/MXYourBids'
import MXNewListing from '../../mx/mx_shared/MXMain/MXNewListing'
import { MXNewLocation } from '../../mx/mx_shared/MXMain/MXHeaderPopups'
import './AdminBusinessExchange.css'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function AdminBusinessExchange(props) {
  const reduxUser = useSelector((state) => state.user.user)
  const { businessId } = useParams()
  const [reloadExchangeTable, setReloadExchangeTable] = useState(false)
  const axios = useAxios()
  const [openStartListingModal, setStartListingModal] = useState(false)
  const [openSetAttributesModal, setOpenAttributesModal] = useState(false)
  const [openPricingModal, setOpenPricingModal] = useState(false)
  const [openReviewListingModal, setReviewListingModal] = useState(false)
  const [openTimeframeModal, setTimeframeModal] = useState(false)
  const wd = useWindowDimensions()
  const [productVariant, setProductVariant] = useState()
  const [inputPrice, setInputPrice] = useState()
  const [inputSecondaryPrice, setInputSecondaryPrice] = useState()
  const [inputQuantity, setInputQuantity] = useState()
  const [inputExpiration, setInputExpiration] = useState('Good Till Cancelled')
  const [openExchangeFilter, setOpenExchangeFilter] = useState(false)
  const [currentTab, setCurrentTab] = useState('exchange')
  const [commodities, setCommodities] = useState([])
  const [commodityForms, setCommodityForms] = useState([])
  const [reloadHeaderStats, setReloadHeaderStats] = useState(false)
  const [selectedCommodityForm, setSelectedCommodityForm] = useState(null)
  const [equipmentType, setEquipmentType] = useState(null)
  const [selectedCommodity, setSelectedCommodity] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [
    updateSelectedLocationButtonLoading,
    setUpdateSelectedLocationButtonLoading,
  ] = useState(false)
  const [showTakeRateModal, setShowTakeRateModal] = useState(null)
  const auth = useAuth()
  const [value, setValue] = useState(null)
  const [searchValue, setSearchValue] = useState(false)
  const [showLocationForm, setShowLocationForm] = useState(false)
  const [editLocation, setEditLocation] = useState(false)
  const [globalSetting, setGlobalSetting] = useState()
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState(null)
  const [openLocationPopup, setOpenLocationPopup] = useState(false)
  const [openDropDownPopup, setOpenDropDownPopup] = useState(null)
  const [locations, setLocations] = useState([])

  const state = props.state
  const getUser = props.getUser
  const loadData = props.loadData
  const user = props.user
  const setUser = props.setUser
  const users = props.users
  const business = props.business
  const currentlySelectedLocation = props.currentlySelectedLocation
  console.log(currentlySelectedLocation)
  const setCurrentlySelectedLocation = props.setCurrentlySelectedLocation
  const currentExchangeTable = props.currentExchangeTable
  const setCurrentExchangeTable = props.setCurrentExchangeTable
  const currentExchangeTab = props.currentExchangeTab
  const setCurrentExchangeTab = props.setCurrentExchangeTab
  const loading = false

  const reAuth = async () => {
    // When a supplier switched from their supplier portal to the mickey buyer portal, redux state is cleared
    // so we need to reauthenticate to set state
    await auth.isLoggedIn()
  }

  useEffect(() => {
    document.body.style.zoom = '96%'
    if (state?.side) {
      setCurrentExchangeTable(state.side === 'ask' ? 'supplier' : 'buyer')
      setCurrentExchangeTab('my-bids')
    } else if (business?.primary_business) {
      setCurrentExchangeTable(
        business?.primary_business === 'Supplier' ? 'supplier' : 'buyer'
      )
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (user?.id && !user?.current_location?.id) {
        if (
          currentlySelectedLocation &&
          currentlySelectedLocation === locations[0]?.id
        ) {
          updateSelectedLocation()
        } else {
          setCurrentlySelectedLocation(locations[0]?.id)
        }
      } else {
        console.log('hihihih')
        setCurrentlySelectedLocation(user.current_location?.id)
      }
    }
  }, [user, currentExchangeTable])

  const getLocations = async () => {
    let res = await axios.get(`/main/locations/`, {
      params: {
        business_id: business?.id,
        tenant_aware: false,
        page_size: 'infinite',
      },
    })
    if (res?.data?.results) {
      setLocations(res.data.results)
    }
  }

  useEffect(async () => {
    if (business) {
      await getLocations()
    }
  }, [business?.id])

  useEffect(() => {
    if (currentExchangeTab === 'my-bids') {
      setCurrentSelectedLocation({ id: 0 })
    } else if (user?.id) {
      setCurrentSelectedLocation(user?.current_location)
    }
  }, [currentExchangeTab])

  const refreshUser = async () => {
    let res = await axios.get(`/main/custom-users/${user.id}/`)
    if (res.data.id) {
      setUser(res?.data)
    }
  }

  useEffect(() => {
    getGlobalSetting()
  }, [businessId])

  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    if (event.shiftKey === true && event.ctrlKey === true) {
      if (event.key === '+') {
        setStartListingModal(true)
      }
    }
  }, [])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  async function updateSelectedLocation() {
    setUpdateSelectedLocationButtonLoading(true)
    let res = await axios.patch(`/main/custom-users/${user.id}/`, {
      current_location_id: currentlySelectedLocation,
      business_id: business?.id,
    })
    setUpdateSelectedLocationButtonLoading(false)
    if (res?.data?.id) {
      toast.success('Location Updated')
      setUser(res?.data)
    }
  }

  function onStartListing() {
    setStartListingModal(true)
    console.log('hi')
    setCurrentlySelectedLocation(null)
  }
  console.log(currentlySelectedLocation)
  useEffect(() => {
    const getCommodities = async () => {
      let res = await axios.get(`/main/commodities/`)
      if (res?.data?.results?.length > 0) {
        let results = res.data.results.reverse()
        if (results.length > 0) {
          setCommodities(results)
          setCommodityForms(results[0]?.commodity_forms)
          setSelectedCommodity(results[0])
        }
      }
    }
    if (currentExchangeTab === 'exchange' && !commodities) {
      getCommodities()
    }
  }, [currentExchangeTab])

  useEffect(() => {
    if (
      user?.id &&
      currentlySelectedLocation &&
      currentlySelectedLocation !== user?.current_location?.id
    ) {
      updateSelectedLocation()
    }
  }, [currentlySelectedLocation])

  useEffect(() => {
    if (value !== null && value !== '') {
      setShowLocationForm(true)
    }
  }, [value])

  useEffect(() => {
    if (props.reload) {
      setReloadExchangeTable(false)
      setReloadExchangeTable(true)
    }
  }, [props.reload])

  const getGlobalSetting = async () => {
    const res = await axios.get('/main/global-setting/')
    if (res?.data?.results) {
      setGlobalSetting(res.data.results[0])
    }
  }

  let globalBuyerTakeRate
  let globalSupplierTakeRate
  let buyerTakeAdjustment
  let buyerTakeRate
  let supplierTakeAdjustment
  let supplierTakeRate
  if (globalSetting) {
    globalBuyerTakeRate =
      Math.round(
        parseFloat(globalSetting.default_marketplace_buyer_take_rate) * 100
      ) / 100
    globalSupplierTakeRate =
      Math.round(
        parseFloat(globalSetting.default_marketplace_supplier_take_rate) * 100
      ) / 100
    if (business?.buyer) {
      buyerTakeAdjustment =
        Math.round(parseFloat(business.buyer?.buyer_take_adjustment) * 100) /
        100
      buyerTakeRate =
        Math.round((globalBuyerTakeRate + buyerTakeAdjustment) * 100) / 100
    }
    if (business?.supplier) {
      supplierTakeAdjustment =
        Math.round(
          parseFloat(business.supplier?.supplier_take_adjustment) * 100
        ) / 100
      supplierTakeRate =
        Math.round((globalSupplierTakeRate + supplierTakeAdjustment) * 100) /
        100
    }
  }

  const viewDict = {
    exchange: 'Exchange',
    'my-bids': 'my-bids',
    watchlist: 'Watchlist',
  }
  const businessDict = {}
  if (business?.supplier?.id) {
    businessDict['supplier'] = 'Supplier'
  }
  if (business?.buyer?.id) {
    businessDict['buyer'] = 'Buyer'
  }

  return (
    <>
      <div id="admin-business-exchange">
        <Menu secondary className="exchange-main-header" widths={6}>
          {hasPermission(reduxUser, 'Create Exchange') && (
            <Menu.Item>
              <div className="popup-triger">
                <div
                  id="product-filter-button-admin"
                  as={Button}
                  onClick={() => setOpenExchangeFilter(!openExchangeFilter)}
                >
                  <Funnel
                    color={'grey'}
                    style={{
                      display: ['exchange', 'my-bids', 'watchlist'].includes(
                        currentExchangeTab
                      )
                        ? 'inherit'
                        : 'none',
                    }}
                    weight={openExchangeFilter ? 'fill' : 'regular'}
                    size={23}
                  />
                </div>
                <Button
                  color={
                    currentExchangeTable === 'supplier'
                      ? 'primary'
                      : currentExchangeTable === 'buyer'
                      ? 'green'
                      : ''
                  }
                  loading={loading}
                  content={
                    currentExchangeTable === 'supplier'
                      ? 'Add Listing'
                      : currentExchangeTable === 'buyer'
                      ? 'Add Buy'
                      : ''
                  }
                  hidden={!currentExchangeTable}
                  style={{
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  }}
                  onClick={() => {
                    onStartListing()
                  }}
                />
              </div>
            </Menu.Item>
          )}
          <Menu.Item>
            <Popup
              on={'click'}
              position={'bottom right'}
              offset={[0, 0]}
              flowing
              trigger={
                <div className="popup-triger">
                  <p> COMMODITY TYPE </p>
                  <div
                    style={{
                      '--rotate':
                        openDropDownPopup === 'commodity'
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                    }}
                  >
                    <p> {selectedCommodity?.name} </p>
                    <CaretDown weight={'bold'} size={15} />
                  </div>
                </div>
              }
              style={{ width: '250px' }}
              mountNode={document.getElementsByClassName(`popup-triger`)[1]}
              onOpen={() => setOpenDropDownPopup('commodity')}
              onClose={() => setOpenDropDownPopup(null)}
            >
              <PopupContent className="popup-content">
                <Header as="h4">Select Commodity Type</Header>
                <div>
                  {[
                    ...(currentExchangeTab !== 'exchange'
                      ? [{ id: 0, name: 'All' }]
                      : []),
                    ...commodities,
                  ]?.map((commodity) => {
                    let style = {
                      opacity:
                        commodity?.id === selectedCommodity?.id ? '1' : '0.4',
                    }
                    if (commodity?.id === selectedCommodity?.id) {
                      style.color = env.REACT_APP_PRIMARY_COLOR
                    }
                    return (
                      <div
                        style={style}
                        onClick={async () => {
                          setSelectedCommodity(commodity)
                          setCommodityForms(commodity?.commodity_forms)
                        }}
                      >
                        {commodity?.id === selectedCommodity?.id ? (
                          <CheckCircle size={15} weight="bold" />
                        ) : (
                          <Circle size={15} weight="bold" />
                        )}
                        <div>
                          <p> {commodity?.name} </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </PopupContent>
            </Popup>
          </Menu.Item>
          <Menu.Item>
            <Popup
              on={'click'}
              position={'bottom right'}
              offset={[0, 0]}
              flowing
              trigger={
                <div className="popup-triger">
                  <p> VIEW </p>
                  <div
                    style={{
                      '--rotate':
                        openDropDownPopup === 'view' ? 'rotate(180deg)' : '',
                    }}
                  >
                    <p>
                      {currentExchangeTab === 'my-bids'
                        ? `User's ${
                            currentExchangeTable === 'supplier'
                              ? 'Sells'
                              : 'Buys'
                          }`
                        : viewDict[currentExchangeTab]}
                    </p>
                    <CaretDown weight={'bold'} size={15} />
                  </div>
                </div>
              }
              style={{ width: '250px' }}
              mountNode={document.getElementsByClassName(`popup-triger`)[2]}
              onOpen={() => setOpenDropDownPopup('view')}
              onClose={() => setOpenDropDownPopup(null)}
            >
              <PopupContent className="popup-content">
                <Header as="h4">Select View</Header>
                <div>
                  {Object.keys(viewDict).map((view) => {
                    return (
                      <div
                        style={{
                          opacity: view === currentExchangeTab ? '' : '0.4',
                          color:
                            view === currentExchangeTab &&
                            env.REACT_APP_PRIMARY_COLOR,
                        }}
                        onClick={async () => {
                          setCurrentExchangeTab(view)
                          if (view === 'exchange' && commodities?.length) {
                            setSelectedCommodity(commodities[0])
                            setCommodityForms(commodities[0]?.commodity_forms)
                          } else {
                            setSelectedCommodity({ id: 0, name: 'All' })
                          }
                        }}
                      >
                        {view === currentExchangeTab ? (
                          <CheckCircle size={15} weight="bold" />
                        ) : (
                          <Circle size={15} weight="bold" />
                        )}
                        <div>
                          <p>
                            {view === 'my-bids'
                              ? `User's ${
                                  currentExchangeTable === 'supplier'
                                    ? 'Sells'
                                    : 'Buys'
                                }`
                              : viewDict[view]}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </PopupContent>
            </Popup>
          </Menu.Item>
          <Menu.Item>
            <Popup
              on={'click'}
              position={'bottom right'}
              offset={[0, 0]}
              flowing
              trigger={
                <div className="popup-triger">
                  <p> BUSINESS TYPE </p>
                  <div
                    style={{
                      '--rotate':
                        openDropDownPopup === 'business'
                          ? 'rotate(180deg)'
                          : '',
                    }}
                  >
                    <p>{businessDict[currentExchangeTable]}</p>
                    <CaretDown weight={'bold'} size={15} />
                  </div>
                </div>
              }
              style={{ width: '250px' }}
              mountNode={document.getElementsByClassName(`popup-triger`)[3]}
              onOpen={() => setOpenDropDownPopup('business')}
              onClose={() => setOpenDropDownPopup(null)}
            >
              <PopupContent className="popup-content">
                <Header as="h4">Select Business Type</Header>
                <div>
                  {Object.keys(businessDict).map((business_type) => {
                    return (
                      <div
                        style={{
                          opacity:
                            business_type === currentExchangeTable ? '' : '0.4',
                          color:
                            business_type === currentExchangeTable &&
                            env.REACT_APP_PRIMARY_COLOR,
                        }}
                        onClick={async () => {
                          setCurrentExchangeTable(business_type)
                        }}
                      >
                        {business_type === currentExchangeTable ? (
                          <CheckCircle size={15} weight="bold" />
                        ) : (
                          <Circle size={15} weight="bold" />
                        )}
                        <div>
                          <p> {businessDict[business_type]} </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </PopupContent>
            </Popup>
          </Menu.Item>
          <Menu.Item>
            <Popup
              on={'click'}
              position={'bottom right'}
              offset={[0, 0]}
              flowing
              trigger={
                <div className="popup-triger">
                  <p> USER </p>
                  <div
                    style={{
                      '--rotate':
                        openDropDownPopup === 'user' ? 'rotate(180deg)' : '',
                    }}
                  >
                    <p>
                      {user?.id ? `${user?.first_name} ${user?.last_name}` : ''}
                    </p>
                    <CaretDown weight={'bold'} size={15} />
                  </div>
                </div>
              }
              style={{ width: '250px' }}
              mountNode={document.getElementsByClassName(`popup-triger`)[4]}
              onOpen={() => setOpenDropDownPopup('user')}
              onClose={() => setOpenDropDownPopup(null)}
            >
              <PopupContent className="popup-content">
                <Header as="h4">Select User</Header>
                <div>
                  {users?.map((business_user) => {
                    return (
                      <div
                        style={{
                          opacity: business_user?.id === user?.id ? '' : '0.4',
                          color:
                            business_user?.id === user?.id &&
                            env.REACT_APP_PRIMARY_COLOR,
                        }}
                        onClick={async () => {
                          await getUser(business_user?.id)
                          console.log('hajskdjsakdjlksa')
                          setCurrentExchangeTab('exchange')
                        }}
                      >
                        {business_user?.id === user?.id ? (
                          <CheckCircle size={15} weight="bold" />
                        ) : (
                          <Circle size={15} weight="bold" />
                        )}
                        <div>
                          <p>
                            {' '}
                            {`${business_user?.first_name} ${business_user.last_name}`}{' '}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </PopupContent>
            </Popup>
          </Menu.Item>
          <Menu.Item>
            <Dimmer
              page
              active={openLocationPopup}
              style={{ backgroundColor: '#d3d3d380' }}
            />
            <Popup
              on={'click'}
              position={'bottom right'}
              offset={[0, 0]}
              flowing
              trigger={
                <div className="popup-triger">
                  <p>
                    {currentExchangeTable === 'supplier'
                      ? 'DELIVERING FROM'
                      : 'DELIVERING TO'}
                  </p>
                  <div
                    style={{
                      '--rotate': openLocationPopup ? 'rotate(180deg)' : '',
                    }}
                  >
                    <p style={{ padding: '0 0 0 0.5rem' }}>
                      {currentSelectedLocation &&
                      currentSelectedLocation?.id === 0
                        ? 'All Locations'
                        : currentSelectedLocation
                        ? `${currentSelectedLocation?.address_1}
                            ${
                              currentSelectedLocation?.address_2
                                ? ' ' + currentSelectedLocation?.address_2
                                : ''
                            }
                            , ${currentSelectedLocation?.city}, ${
                              currentSelectedLocation?.state_short_code
                            }`
                        : ' '}
                    </p>
                    <p style={{ padding: '0 1rem 0 0', opacity: '.3' }}>
                      {currentSelectedLocation &&
                      currentSelectedLocation?.id === 0
                        ? ''
                        : currentSelectedLocation?.zip_code}
                    </p>
                    <CaretDown weight={'bold'} size={15} />
                  </div>
                </div>
              }
              style={{ marginRight: '2rem' }}
              onClose={() => {
                setShowLocationForm(false)
                setSearchValue(false)
                setEditLocation(false)
                setOpenLocationPopup(false)
              }}
              onOpen={() => setOpenLocationPopup(true)}
              open={openLocationPopup}
            >
              <MXNewLocation
                myAsk={currentExchangeTab === 'my-bids'}
                currentSelectedLocation={currentSelectedLocation}
                setCurrentSelectedLocation={setCurrentSelectedLocation}
                showLocationForm={showLocationForm}
                editLocation={editLocation}
                setSearchValue={setSearchValue}
                business={business}
                value={value}
                setValue={setValue}
                user={user}
                setEditLocation={setEditLocation}
                searchValue={searchValue}
                locations={locations}
                setCurrentlySelectedLocation={setCurrentlySelectedLocation}
                setOpenLocationPopup={setOpenLocationPopup}
                setShowLocationForm={setShowLocationForm}
                getLocations={getLocations}
                supplier={
                  currentExchangeTable === 'supplier'
                    ? business?.supplier
                    : null
                }
                buyer={
                  currentExchangeTable === 'buyer' ? business?.buyer : null
                }
                loading={updateSelectedLocationButtonLoading}
                setLoading={setUpdateSelectedLocationButtonLoading}
              />
            </Popup>
          </Menu.Item>
        </Menu>
        <div style={{ marginTop: '0px' }}>
          {currentExchangeTable && (
            <>
              {currentExchangeTab === 'my-bids' && (
                <>
                  <MXYourBids
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    is_admin={true}
                    business={business}
                    reAuth={reAuth}
                    buyer={
                      currentExchangeTable === 'buyer' ? business?.buyer : null
                    }
                    supplier={
                      currentExchangeTable === 'supplier'
                        ? business?.supplier
                        : null
                    }
                    user={user}
                    setAdminUser={setUser}
                    loadData={loadData}
                    yourBids={true}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    currentPortal={
                      currentExchangeTable === 'supplier' ? 'supplier' : 'buyer'
                    }
                    currentSelectedLocation={currentSelectedLocation}
                    setCurrentSelectedLocation={setCurrentSelectedLocation}
                    reload={reloadExchangeTable}
                    setReload={setReloadExchangeTable}
                    openExchangeFilter={openExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    reloadHeaderStats={reloadHeaderStats}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setStartListingModal={setStartListingModal}
                    selectedCommodity={selectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    setInputPrice={setInputPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodity={setSelectedCommodity}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    currentlySelectedTimeframe={
                      props.adminCurrentlySelectedTimeframe
                    }
                    setCurrentlySelectedTimeframe={
                      props.setAdminCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      props.adminCurrentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      props.setAdminCurrentlySelectedTruckingType
                    }
                  />
                </>
              )}
              {currentExchangeTab === 'watchlist' && (
                <>
                  <MXWatchlist
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    reload={reloadExchangeTable}
                    setReload={setReloadExchangeTable}
                    is_admin={true}
                    business={business}
                    reAuth={reAuth}
                    buyer={
                      currentExchangeTable === 'buyer' ? business.buyer : null
                    }
                    supplier={
                      currentExchangeTable === 'supplier'
                        ? business.supplier
                        : null
                    }
                    user={user}
                    setAdminUser={setUser}
                    loadData={loadData}
                    yourBids={currentExchangeTab === 'my-bids'}
                    setSelectedCommodity={setSelectedCommodity}
                    watchlist={currentExchangeTab === 'watchlist'}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    currentPortal={
                      currentExchangeTable === 'supplier' ? 'supplier' : 'buyer'
                    }
                    currentSelectedLocation={currentSelectedLocation}
                    setCurrentSelectedLocation={setCurrentSelectedLocation}
                    openExchangeFilter={openExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    reloadHeaderStats={reloadHeaderStats}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setStartListingModal={setStartListingModal}
                    selectedCommodity={selectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    setInputPrice={setInputPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    currentlySelectedTimeframe={
                      props.adminCurrentlySelectedTimeframe
                    }
                    setCurrentlySelectedTimeframe={
                      props.setAdminCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      props.adminCurrentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      props.setAdminCurrentlySelectedTruckingType
                    }
                  />
                </>
              )}
              {currentExchangeTab === 'exchange' && commodityForms && (
                <>
                  <MXExchange
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    is_admin={true}
                    business={business}
                    reAuth={reAuth}
                    buyer={
                      currentExchangeTable === 'buyer' ? business?.buyer : null
                    }
                    supplier={
                      currentExchangeTable === 'supplier'
                        ? business?.supplier
                        : null
                    }
                    user={user}
                    setAdminUser={setUser}
                    loadData={loadData}
                    yourBids={currentExchangeTab === 'my-bids'}
                    watchlist={currentExchangeTab === 'watchlist'}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    currentPortal={
                      currentExchangeTable === 'supplier' ? 'supplier' : 'buyer'
                    }
                    currentSelectedLocation={currentSelectedLocation}
                    setCurrentSelectedLocation={setCurrentSelectedLocation}
                    reload={reloadExchangeTable}
                    setReload={setReloadExchangeTable}
                    openExchangeFilter={openExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    reloadHeaderStats={reloadHeaderStats}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setStartListingModal={setStartListingModal}
                    selectedCommodity={selectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    setInputPrice={setInputPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    currentlySelectedTimeframe={
                      props.adminCurrentlySelectedTimeframe
                    }
                    setCurrentlySelectedTimeframe={
                      props.setAdminCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      props.adminCurrentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      props.setAdminCurrentlySelectedTruckingType
                    }
                  />
                  <Divider />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <MXNewListing
        user={user}
        setUser={setUser}
        business={business}
        loadData={loadData}
        refreshUser={refreshUser}
        is_admin={true}
        currentPortal={
          currentExchangeTable === 'supplier' ? 'supplier' : 'buyer'
        }
        openExchangeFilter={openExchangeFilter}
        setOpenExchangeFilter={setOpenExchangeFilter}
        openStartListingModal={openStartListingModal}
        selectedCommodity={selectedCommodity}
        setSelectedCommodity={setSelectedCommodity}
        reload={reloadExchangeTable}
        setReload={setReloadExchangeTable}
        setStartListingModal={setStartListingModal}
        openSetAttributesModal={openSetAttributesModal}
        setOpenAttributesModal={setOpenAttributesModal}
        openPricingModal={openPricingModal}
        setOpenPricingModal={setOpenPricingModal}
        openReviewListingModal={openReviewListingModal}
        setReviewListingModal={setReviewListingModal}
        openTimeframeModal={openTimeframeModal}
        setTimeframeModal={setTimeframeModal}
        currentSelectedLocation={currentSelectedLocation}
        setCurrentSelectedLocation={setCurrentSelectedLocation}
        productVariant={productVariant}
        setProductVariant={setProductVariant}
        setInputPrice={setInputPrice}
        inputSecondaryPrice={inputSecondaryPrice}
        setInputSecondaryPrice={setInputSecondaryPrice}
        inputPrice={inputPrice}
        setInputQuantity={setInputQuantity}
        reloadHeaderStats={reloadHeaderStats}
        setReloadHeaderStats={setReloadHeaderStats}
        inputQuantity={inputQuantity}
        notifications={notifications}
        setNotifications={setNotifications}
        inputExpiration={inputExpiration}
        setInputExpiration={setInputExpiration}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        buyer={business?.buyer}
        supplier={business?.supplier}
        reAuth={reAuth}
        equipmentType={equipmentType}
        setEquipmentType={setEquipmentType}
        commodities={commodities}
        setCommodities={setCommodities}
        commodityForms={commodityForms}
        setCommodityForms={setCommodityForms}
        selectedCommodityForm={selectedCommodityForm}
        setSelectedCommodityForm={setSelectedCommodityForm}
        currentlySelectedLocation={props.currentlySelectedLocation}
        currentlySelectedTimeframe={props.adminCurrentlySelectedTimeframe}
        setCurrentlySelectedTimeframe={props.setAdminCurrentlySelectedTimeframe}
        currentlySelectedTruckingType={props.adminCurrentlySelectedTruckingType}
        setCurrentlySelectedTruckingType={
          props.setAdminCurrentlySelectedTruckingType
        }
      />
      <Modal
        closeIcon
        onOpen={() => setShowTakeRateModal(true)}
        onClose={() => setShowTakeRateModal(false)}
        open={showTakeRateModal}
        style={{
          minHeight: '340px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '50px' }}
              textAlign="center"
            >
              {currentExchangeTable === 'buyer' ? <>Buyer</> : <>Supplier</>}{' '}
              Take Rate Adjustment
            </Header>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {currentExchangeTable === 'buyer' && (
              <>
                <MickeyForm
                  formName={'AdminBuyerTakeAdjustmentForm'}
                  model={'Buyer'}
                  tenantAware={false}
                  instanceId={business?.buyer?.id}
                  url={'/main/buyers/'}
                  values={{
                    business_id: business?.id,
                  }}
                  postSubmit={() => {
                    setTimeout(() => {
                      loadData()
                      setReloadExchangeTable(true)
                    }, 2000)
                  }}
                  loadingHeight={'100px'}
                  style={{ border: 'none', boxShadow: 'none' }}
                />
                <div
                  style={{
                    marginTop: '30px',
                    color: '#2FB000',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  + {globalBuyerTakeRate}% Global Take Rate
                </div>
                <div
                  style={{
                    marginTop: '30px',
                    color: '#2FB000',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  = {buyerTakeRate}% Adjusted Take Rate
                </div>
              </>
            )}
            {currentExchangeTable === 'supplier' && (
              <>
                <MickeyForm
                  formName={'AdminSupplierTakeAdjustmentForm'}
                  model={'Supplier'}
                  tenantAware={false}
                  instanceId={business?.supplier?.id}
                  url={'/main/suppliers/'}
                  values={{
                    business_id: business?.id,
                  }}
                  postSubmit={() => {
                    setTimeout(() => {
                      loadData()
                      setReloadExchangeTable(true)
                    }, 2000)
                  }}
                  loadingHeight={'100px'}
                  style={{ border: 'none', boxShadow: 'none' }}
                />
                <div
                  style={{
                    marginTop: '30px',
                    color: '#2FB000',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  + {globalSupplierTakeRate}% Global Take Rate
                </div>
                <div
                  style={{
                    marginTop: '30px',
                    color: '#2FB000',
                    fontSize: '20px',
                    textAlign: 'center',
                  }}
                >
                  = {supplierTakeRate}% Adjusted Take Rate
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AdminBusinessExchange
