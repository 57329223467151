import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { format_phone_number } from '../../../lib/utils/utils'
import { formatAddress } from '../../../helpers/helpers'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { hasPermission, inGroup } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function AdminManageLocationsRow(props) {
  const axios = useAxios()
  const reduxUser = useSelector((state) => state.user.user)

  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [
    archiveLocationConfirmationModel,
    setArchiveLocationConfirmationModel,
  ] = useState(false)

  const deleteLocation = async () => {
    setArchiveLocationConfirmationModel(false)
    await axios
      .delete(
        `/main/admin-locations/${props.location?.id}/?tenant_aware=false`,
        {}
      )
      .then(() => {
        toast.success('Successfully deleted location')
      })
    props.setReload(true)
  }

  return (
    <>
      <Table.Row
        selectable={'true'}
        className={'admin-manage-users-row'}
        onClick={(e) => {
          if (e.target.tagName !== 'TD') return
          if (
            hasPermission(reduxUser, 'Update User') &&
            (inGroup(reduxUser, 'Admin') || inGroup(reduxUser, 'Development'))
          ) {
            setOpenEditUserModal(true)
          }
        }}
      >
        <Table.Cell width={2}>{props.location?.name}</Table.Cell>
        <Table.Cell width={3}>{formatAddress(props.location)}</Table.Cell>
        <Table.Cell width={2}>{props.location?.type}</Table.Cell>
        <Table.Cell width={2}>
          {props.location?.on_site_contact_name}
        </Table.Cell>
        <Table.Cell width={2}>{props.location?.email}</Table.Cell>
        <Table.Cell width={2}>
          {format_phone_number(props.location?.phone)}
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            color={'red'}
            icon={'x'}
            circular
            onClick={() => {
              setArchiveLocationConfirmationModel(true)
            }}
          />
          <ConfirmationModal
            message={'Are you sure you want to archive this location?'}
            confirmationModal={archiveLocationConfirmationModel}
            setConfirmationModal={setArchiveLocationConfirmationModel}
            yesFunction={() => deleteLocation()}
            noFunction={() => setArchiveLocationConfirmationModel(false)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => {
          setOpenEditUserModal(false)
          props.setReload(true)
        }}
        onOpen={() => setOpenEditUserModal(true)}
        open={openEditUserModal}
      >
        <Modal.Header>Edit {props.location?.name}</Modal.Header>
        <p
          style={{
            padding: '1.25rem 1.5rem',
            margin: '0',
          }}
        >
          Changes will update automatically. When you're finished click the
          Close button below.
        </p>
        <Modal.Content>
          <MickeyForm
            formName={'LocationForm'}
            model={'Location'}
            url={'/main/admin-locations/'}
            instanceId={props?.location?.id}
            tenantAware={false}
            urlParams={{
              business_id: props.business?.id,
            }}
          />
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button
            onClick={() => {
              props.setReload(true)
              setOpenEditUserModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminManageLocationsRow
