import { Button, Header, Loader, Modal } from 'semantic-ui-react'
import { track } from '@amplitude/analytics-browser'
import './ExchangeTable.css'
import env from '../../../../../env'
import MickeyDropdown from '../../../../../components/MickeyDropdown/MickeyDropdown'
import useAxios from '../../../../../lib/auth/useAxios'
import { useState } from 'react'

const WatchlistModal = (props) => {
  const {
    handleClose,
    user,
    business,
    updateUser,
    setStartWatchlistModal,
    startWatchlistModal,
    setSelectedProductId,
    selectedProductLoading,
    setSelectedProductLoading,
    selectedProductId,
    loadingNextModal,
    setLoadingNextModal,
    setAttributesForSelectedProduct,
    setProductForWatchlistModal,
    setOpenAttributesModal,
  } = props
  const axios = useAxios()
  const [currentlySelectedLocation, setCurrentlySelectedLocation] =
    useState(null)

  async function updateSelectedLocation() {
    let res = await axios.patch(`/main/custom-users/${user.id}/`, {
      current_location_id: currentlySelectedLocation,
      business_id: business?.id,
    })
    if (res?.data?.id) {
      updateUser(res.data)
    }
  }

  const getAttributes = async () => {
    setLoadingNextModal(true)
    updateSelectedLocation()
    let res = await axios.get(`/main/product-filter/`, {
      params: {
        product_id: selectedProductId,
      },
    })

    if (res?.data) {
      setAttributesForSelectedProduct(res.data.attribute_selections)
      setProductForWatchlistModal(res.data.product)
      setStartWatchlistModal(false)
      setOpenAttributesModal(true)
      setLoadingNextModal(false)
    }
  }

  return (
    <>
      <Modal
        id="start-watchlist-modal"
        onOpen={() => {
          setStartWatchlistModal(true)
          track('Add Exchange Order Page 1', {
            'User ID': user?.id,
            'Business Name': business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={startWatchlistModal}
        size="mini"
        style={{
          minHeight: '500px',
          padding: '2rem',
          display: 'flex !important',
        }}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Start Your Watchlist
            </Header>
            <Header.Subheader style={{ textAlign: 'center' }}>
              Search for a product below to get started
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              justifyContent: 'center',
              overflow: 'visible',
              width: '100%',
            }}
          >
            <label>Select Location</label>
            <MickeyDropdown
              url={'/main/locations/'}
              name={'locations'}
              autoComplete="new-password"
              label={'Select Location'}
              params={{
                business_id: business?.id,
                page_size: 'infinite',
              }}
              tenantAware={false}
              placeholder={'Select Address'}
              titleFields={['address_1']}
              descriptionFields={['city', 'state', 'zip_code']}
              style={{
                margin: '15px 0 20px 0',
                zIndex: '1003',
              }}
              onChange={(e, d) => {
                setCurrentlySelectedLocation(d.value)
              }}
            />
            <label>Select Product</label>
            <MickeyDropdown
              url="/main/products/"
              placeholder={'Select Product'}
              style={{
                zIndex: '1000',
                margin: '15px 0 0px 0',
              }}
              titleFields={['material.name']}
              descriptionFields={['commodity_form.name', 'commodity.name']}
              onChange={(e, d) => {
                setSelectedProductId(d.value)
              }}
              autoFocus
              loading={selectedProductLoading}
              setLoading={setSelectedProductLoading}
            />
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              color="primary"
              onClick={() => getAttributes()}
              disabled={!selectedProductId}
            >
              {loadingNextModal && <Loader size="tiny" inline="centered" />}
              {!loadingNextModal && 'Next'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default WatchlistModal
