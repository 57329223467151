import { useEffect, useState } from 'react'
import { Segment } from 'semantic-ui-react'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import MXPaymentsRow from './MXPaymentsRow'
import MickeyTable from '../../../../components/MickeyTable/MickeyTable'

function MXPayments(props) {
  const { businessId, currentPortal, hideHeader, style } = props

  let headerRow = [
    'Order Number',
    'Business Paying',
    'Business Receiving Payment',
    'Balance ID',
    'Payment Method',
    'Payment Date/Time',
    'Payment Status',
    'Payment Type',
    'Amount',
  ]

  const params = {}
  if (businessId) {
    params.business_id = businessId
  }
  if (currentPortal) {
    params.portal = currentPortal
  }

  return (
    <div className={'main-container'}>
      {!hideHeader && (
        <PageHeader
          icon={'money'}
          header={'Accounting'}
          subheader={
            'Manage and keep track of your receipts and disbursements.'
          }
        />
      )}
      <Segment style={style}>
        <MickeyTable
          headerRow={headerRow}
          url={'/main/payments/'}
          urlParams={params}
          basicSegment
          renderBodyRow={(data, index) => {
            return (
              <MXPaymentsRow
                row={data}
                index={index}
                headerRow={headerRow}
                key={data ? data.id : null}
              />
            )
          }}
          emptyIcon={'payment'}
          emptyMessage={'Payments will populate here'}
        />
      </Segment>
    </div>
  )
}

export default MXPayments
