import React, { useState, useEffect } from 'react'
import { Header, Segment, Label } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import _ from 'underscore'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { ArrowRight } from 'phosphor-react'

function AdminDashboardTruckingStatus({ navigation }) {
  const axios = useAxios()
  const [outagesLoading, setOutagesLoading] = useState(true)
  const range = [0, 10000]
  const [echoData, setEchoData] = useState([])
  const [jbHuntData, setJbHuntData] = useState([])
  const [coyoteData, setCoyoteData] = useState([])
  const [loadsmartData, setLoadsmartData] = useState([])

  const parseDomain = () => [0, 10000]
  const domain = parseDomain()

  const CustomTooltip = (props) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div
          className={'nice-box-shadow'}
          style={{
            backgroundColor: 'white',
            border: '1px solid lightgrey',
            padding: '10px',
            borderRadius: '10px',
          }}
        >
          <Label>
            {props.payload[0].value}
            <Label.Detail>
              {props.payload[2].payload['cargo_unit']['singular_name']}
            </Label.Detail>
          </Label>
          <p style={{ textAlign: 'center', padding: '10px' }}>
            {props.payload[2].payload['pickup_zip_code']}{' '}
            <ArrowRight style={{ paddingTop: '5px' }} />{' '}
            {props.payload[2].payload['delivery_zip_code']}
          </p>
          <Segment
            color={'green'}
            style={{ textAlign: 'center', marginTop: '0' }}
          >
            <p>
              <b>{formatTotalPrice(props.payload[2].payload['value'])}</b>
            </p>
          </Segment>
        </div>
      )
    }

    return null
  }

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        let date = new Date()
        let res = await axios.get('/main/admin-trucking-quotes/', {
          params: {
            start_date: new Date(
              date.setHours(date.getHours() - 3)
            ).toLocaleString(),
            // created_at__gte: date.setHours(date.getHours() - 3),
            is_test: true,
          },
        })
        if (res?.status < 400) {
          let ed = _.map(
            _.filter(res?.data?.results, (result) => {
              return result.trucking_company.name === 'Echo'
            }),
            (result) => {
              return {
                datetime: new Date(result.created_at + ' UTC').toLocaleString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }
                ),
                index: 1,
                value: parseFloat(result.rate),
                pickup_zip_code: result.pickup_appointment.location.zip_code,
                delivery_zip_code:
                  result.delivery_appointment.location.zip_code,
                cargo_unit: result.cargo_unit,
              }
            }
          )
          if (ed.length > 10) {
            ed = ed.slice(0, 10)
          }
          setEchoData(ed.reverse())
          let coyote = _.map(
            _.filter(res.data.results, (result) => {
              return result.trucking_company.name === 'Coyote'
            }),
            (result) => {
              return {
                datetime: new Date(result.created_at + ' UTC').toLocaleString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }
                ),
                index: 1,
                value: parseFloat(result.rate),
                pickup_zip_code: result.pickup_appointment.location.zip_code,
                delivery_zip_code:
                  result.delivery_appointment.location.zip_code,
                cargo_unit: result.cargo_unit,
              }
            }
          )
          if (coyote.length > 10) {
            coyote = coyote.slice(0, 10)
          }
          setCoyoteData(coyote.reverse())
          let jbhunt = _.map(
            _.filter(res.data.results, (result) => {
              return result.trucking_company.name === 'JB Hunt'
            }),
            (result) => {
              return {
                datetime: new Date(result.created_at + ' UTC').toLocaleString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }
                ),
                index: 1,
                value: parseFloat(result.rate),
                pickup_zip_code: result.pickup_appointment.location.zip_code,
                delivery_zip_code:
                  result.delivery_appointment.location.zip_code,
                cargo_unit: result.cargo_unit,
              }
            }
          )
          if (jbhunt.length > 10) {
            jbhunt = jbhunt.slice(0, 10)
          }
          setJbHuntData(jbhunt.reverse())
          let loadsmart = _.map(
            _.filter(res.data.results, (result) => {
              return result.trucking_company.name === 'Loadsmart'
            }),
            (result) => {
              return {
                datetime: new Date(result.created_at + ' UTC').toLocaleString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }
                ),
                index: 1,
                value: parseFloat(result.rate),
                pickup_zip_code: result.pickup_appointment.location.zip_code,
                delivery_zip_code:
                  result.delivery_appointment.location.zip_code,
                cargo_unit: result.cargo_unit,
              }
            }
          )
          if (loadsmart.length > 10) {
            loadsmart = loadsmart.slice(0, 10)
          }
          setLoadsmartData(loadsmart.reverse())
        }
        setOutagesLoading(false)
      }
      fetchData()
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Segment style={{ minHeight: '40vh' }} loading={outagesLoading}>
      <Header as="h2">Echo</Header>
      <ResponsiveContainer width="100%" height={60}>
        <ScatterChart
          width={800}
          height={60}
          margin={{
            width: '100%',
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            type="category"
            dataKey="datetime"
            name="datetime"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            height={10}
            width={0}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <ZAxis type="number" name="value" dataKey="value" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={<CustomTooltip />}
          />
          <Scatter
            stroke="forrestgreen"
            data={echoData}
            fill="green"
            domain={domain}
            range={range}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <Header as="h2">JB Hunt</Header>
      <ResponsiveContainer width="100%" height={60}>
        <ScatterChart
          width={800}
          height={60}
          margin={{
            width: '100%',
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            type="category"
            dataKey="datetime"
            name="datetime"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            height={10}
            width={0}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <ZAxis type="number" name="value" dataKey="value" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={<CustomTooltip />}
          />
          <Scatter
            stroke="forrestgreen"
            data={jbHuntData}
            fill="green"
            domain={domain}
            range={range}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <Header as="h2">Coyote</Header>
      <ResponsiveContainer width="100%" height={60}>
        <ScatterChart
          width={800}
          height={60}
          margin={{
            width: '100%',
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            type="category"
            dataKey="datetime"
            name="datetime"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            height={10}
            width={0}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <ZAxis type="number" name="value" dataKey="value" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={<CustomTooltip />}
          />
          <Scatter
            stroke="forrestgreen"
            strokeWidth={2}
            fillOpacity={1}
            data={coyoteData}
            fill="green"
            domain={domain}
            range={range}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <Header as="h2">Loadsmart</Header>
      <ResponsiveContainer width="100%" height={60}>
        <ScatterChart
          width={800}
          height={60}
          margin={{
            width: '100%',
            top: 10,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <XAxis
            type="category"
            dataKey="datetime"
            name="datetime"
            interval={0}
            tickLine={{ transform: 'translate(0, -6)' }}
          />
          <YAxis
            type="number"
            dataKey="index"
            height={10}
            width={0}
            tick={false}
            tickLine={false}
            axisLine={false}
          />
          <ZAxis type="number" name="value" dataKey="value" />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            wrapperStyle={{ zIndex: 100 }}
            content={<CustomTooltip />}
          />
          <Scatter
            stroke="forrestgreen"
            strokeWidth={2}
            fillOpacity={1}
            data={loadsmartData}
            fill="green"
            domain={domain}
            range={range}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <p>*All times are displayed in UTC</p>
    </Segment>
  )
}

export default AdminDashboardTruckingStatus
