import { Button, Header, Table, Divider, Popup } from 'semantic-ui-react'
import './ExchangeTable.css'
import env from '../../../../../env'
import { formatTotalPrice } from '../../../../../lib/utils/utils'
import { Star } from 'phosphor-react'
import ProductIcon from '../../../../../components/ProductIcon/ProductIcon'
import { toast } from 'react-toastify'
import { reduce } from 'underscore'

const ExchangeTableMobileRow = (props) => {
  const {
    userBid,
    userAsk,
    lowestAskOrder,
    highestBidOrder,
    setCurrentlySelectedTimeframe,
    setCurrentlySelectedTruckingType,
    hasCurrentSideOrder,
    hasActiveOrder,
  } = props

  const buyTimeframe =
    props.currentPortal === 'buyer'
      ? hasActiveOrder &&
        hasCurrentSideOrder &&
        (props.currentPortal === 'supplier'
          ? userAsk
            ? userAsk?.timeframe
            : ''
          : userBid
          ? userBid?.timeframe
          : '')
      : (props.currentPortal === 'supplier' &&
          props.exchangeOrderBook?.bid_orders?.length > 0) ||
        (props.currentPortal === 'buyer' &&
          props.exchangeOrderBook?.ask_orders?.length > 0)
      ? props.currentPortal === 'supplier'
        ? highestBidOrder?.timeframe
        : lowestAskOrder?.timeframe
      : ''

  const sellTimeframe =
    props.currentPortal === 'supplier' && userAsk
      ? userAsk.timeframe
      : props.currentPortal === 'buyer' && lowestAskOrder
      ? lowestAskOrder.timeframe
      : ''

  const showCancelBid =
    (props.currentPortal === 'supplier' &&
      props.activeAskIds?.includes(props.exchangeOrderBook?.id) &&
      props.exchangeOrderBook?.ask_orders?.length > 0) ||
    (props.currentPortal === 'buyer' &&
      props.activeBidIds?.includes(props.exchangeOrderBook?.id) &&
      props.exchangeOrderBook?.bid_orders?.length > 0)

  return (
    <>
      <Table.Row as={'div'} style={{ marginBottom: '1.5rem' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '.75rem' }}>
            <ProductIcon
              width={'25px'}
              height={'25px'}
              productName={props.product?.material?.name}
            />
          </div>
          <div style={{ display: 'flex', width: '100%', paddingTop: '.5rem' }}>
            <div
              style={{
                display: 'flex',
                flex: '0 1 64%',
                flexDirection: 'column',
              }}
            >
              <Header as={'h5'}>{props.product?.material?.name}</Header>
              <p
                style={{
                  marginBottom: '1rem',
                  opacity: '.5',
                }}
              >
                {props.productVariant?.attribute_selections?.map(
                  (attribute, index) => {
                    if (
                      index + 1 ===
                      props.productVariant?.attribute_selections.length
                    ) {
                      return attribute?.attribute_selection_value
                    } else {
                      return attribute?.attribute_selection_value + ', '
                    }
                  }
                )}
              </p>
              <div style={{ display: 'flex' }}>
                {(showCancelBid ||
                  (props.currentPortal === 'supplier' &&
                    props.activeAskIds?.includes(
                      props.exchangeOrderBook?.id
                    )) ||
                  (props.currentPortal === 'buyer' &&
                    props.activeBidIds?.includes(
                      props.exchangeOrderBook?.id
                    ))) && (
                  <>
                    <Button
                      style={{
                        borderRadius: '3px',
                        padding: '10px 9px 10px 9px',
                        marginRight: '1rem',
                        // display: "flex",
                        // alignSelf: "flex-start",
                        // cursor: "pointer",
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        border: `1px solid ${env.REACT_APP_PRIMARY_COLOR}`,
                      }}
                      color="primary"
                      loading={props.cancelBidLoading}
                      onClick={() => {
                        let orderId = showCancelBid
                          ? props.currentPortal === 'supplier'
                            ? props.exchangeOrderBook?.ask_orders?.length > 0
                              ? lowestAskOrder?.id
                              : ''
                            : props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.id
                            : ''
                          : props.currentPortal === 'supplier'
                          ? props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.id
                            : ''
                          : props.exchangeOrderBook?.ask_orders?.length > 0
                          ? lowestAskOrder?.id
                          : ''

                        let locationId = showCancelBid
                          ? props.currentPortal === 'supplier'
                            ? props.exchangeOrderBook?.ask_orders?.length > 0
                              ? lowestAskOrder?.location_id
                              : ''
                            : props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.location_id
                            : ''
                          : props.currentPortal === 'supplier'
                          ? props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.location_id
                            : ''
                          : props.exchangeOrderBook?.ask_orders?.length > 0
                          ? lowestAskOrder?.location_id
                          : ''
                        props.cancelBid(
                          props.exchangeOrderBook?.product_variant?.id,
                          orderId,
                          locationId
                        )
                      }}
                    >
                      <span>
                        Cancel{' '}
                        {props.currentPortal === 'supplier' ? 'Offer' : 'Bid'}
                      </span>
                    </Button>
                  </>
                )}
                {showCancelBid && (
                  <Button
                    style={{
                      borderRadius: '3px',
                      padding: '10px',
                      marginRight: '.75rem',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                    color="grey"
                    onClick={() => {
                      props.setOpenEditBidAskModal(true)
                      props.setEditingOrder(
                        props.currentPortal === 'supplier' ? userAsk : userBid
                      )
                      props.setEditingExchangeOrderBook(props.exchangeOrderBook)
                    }}
                  >
                    Edit
                  </Button>
                )}
                {!showCancelBid &&
                ((props.currentPortal === 'supplier' &&
                  !props.activeAskIds?.includes(props.exchangeOrderBook?.id)) ||
                  (props.currentPortal === 'buyer' &&
                    !props.activeBidIds?.includes(
                      props.exchangeOrderBook?.id
                    ))) ? (
                  <Button
                    basic
                    style={{
                      borderRadius: '3px',
                      display: 'flex',
                      alignSelf: 'flex-start',
                      cursor: 'pointer',
                      marginRight: '.75rem',
                    }}
                    color="green"
                    className={'view-listing-button'}
                    onClick={() => {
                      if (setCurrentlySelectedTimeframe) {
                        setCurrentlySelectedTimeframe(
                          props.currentPortal === 'buyer'
                            ? sellTimeframe
                            : buyTimeframe
                        )
                      }
                      if (setCurrentlySelectedTruckingType) {
                        setCurrentlySelectedTruckingType(
                          props.currentPortal === 'buyer'
                            ? lowestAskOrder?.cargo_unit
                            : highestBidOrder?.cargo_unit
                        )
                      }
                      props.setOpenPricingModal(true)
                      props.setProductVariant(
                        props.exchangeOrderBook?.product_variant
                      )
                    }}
                  >
                    {props.currentPortal === 'supplier' ? 'Ask' : 'Bid'}
                  </Button>
                ) : (
                  ''
                )}
                {!showCancelBid &&
                  ((props.currentPortal === 'supplier' &&
                    !props.activeAskIds?.includes(
                      props.exchangeOrderBook?.id
                    )) ||
                    (props.currentPortal === 'buyer' &&
                      !props.activeBidIds?.includes(
                        props.exchangeOrderBook?.id
                      ))) && (
                    <Button
                      style={{
                        borderRadius: '3px',
                        display: 'flex',
                        alignSelf: 'flex-start',
                        cursor: 'pointer',
                        marginRight: '1rem',
                      }}
                      color="green"
                      onClick={() => {
                        if (setCurrentlySelectedTimeframe) {
                          setCurrentlySelectedTimeframe(
                            props.currentPortal === 'buyer'
                              ? sellTimeframe
                              : buyTimeframe
                          )
                        }
                        if (setCurrentlySelectedTruckingType) {
                          setCurrentlySelectedTruckingType(
                            props.currentPortal === 'buyer'
                              ? lowestAskOrder?.cargo_unit
                              : highestBidOrder?.cargo_unit
                          )
                        }
                        props.setOpenPricingModal(true)
                        props.setProductVariant(
                          props.exchangeOrderBook?.product_variant
                        )
                        props.setInputQuantity(
                          props.currentPortal === 'supplier'
                            ? highestBidOrder.quantity
                            : lowestAskOrder.quantity
                        )
                        props.setInputPrice(
                          props.currentPortal === 'supplier'
                            ? Math.max(
                                ...props.exchangeOrderBook?.bid_orders?.map(
                                  (item) => item.total_price
                                )
                              )
                            : Math.min(
                                ...props.exchangeOrderBook?.ask_orders?.map(
                                  (item) => item.total_price
                                )
                              )
                        )
                      }}
                    >
                      {props.currentPortal === 'supplier'
                        ? 'Sell Now'
                        : 'Buy Now'}
                    </Button>
                  )}

                <Star
                  style={{ cursor: 'pointer' }}
                  className={'start-watchlist'}
                  size={32}
                  color={
                    props.user?.product_variant_watchlist &&
                    props.user?.product_variant_watchlist.find(
                      (product_variant) =>
                        product_variant['pv_id'] ===
                          props.exchangeOrderBook?.product_variant?.id &&
                        product_variant['location_id'] ===
                          props.user?.current_location?.id
                    )
                      ? 'orange'
                      : 'grey'
                  }
                  weight={
                    props.user?.product_variant_watchlist &&
                    props.user?.product_variant_watchlist.find(
                      (product_variant) =>
                        product_variant['pv_id'] ===
                          props.exchangeOrderBook?.product_variant?.id &&
                        product_variant['location_id'] ===
                          props.user?.current_location?.id
                    )
                      ? 'duotone'
                      : 'regular'
                  }
                  onClick={() => {
                    if (props.publicExchange) {
                      toast.error('Login to access Watchlist')
                    } else {
                      props.updateWatchlist(
                        props.exchangeOrderBook?.product_variant?.id,
                        true
                      )
                    }
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flex: '0 1 36%',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <div style={{ width: 'fit-content' }}>
                <div
                  style={{
                    marginLeft: showCancelBid && '20px',
                  }}
                >
                  <label>
                    {' '}
                    {props.currentPortal === 'supplier' ? 'Buy' : 'Sell'} Price
                  </label>
                  <p
                    style={{
                      margin: '0',
                      fontSize: '1em',
                      marginTop: '5px',
                    }}
                  >
                    {(props.currentPortal === 'supplier' && highestBidOrder) ||
                    (props.currentPortal === 'buyer' && lowestAskOrder) ? (
                      <Popup
                        content={
                          <div
                            style={{
                              width: '250px',
                              overflow: 'scroll',
                              paddingTop: '10px',
                            }}
                          >
                            <Header as={'h2'} textAlign={'center'}>
                              {' '}
                              {props.currentPortal === 'supplier'
                                ? 'Bids'
                                : 'Asks'}
                            </Header>
                            <p
                              style={{
                                fontSize: '12px',
                                margin: '0',
                                textAlign: 'center',
                              }}
                            >
                              {props.productVariant &&
                                props.productVariant.product.material.name}
                              {', '}
                              {props.productVariant &&
                                props.productVariant?.attribute_selections?.map(
                                  (attribute, index) => {
                                    return attribute.attribute_selection_value +
                                      index !==
                                      props.productVariant.attribute_selections
                                        .length -
                                        1
                                      ? ', '
                                      : ''
                                  }
                                )}
                            </p>
                            <Table basic compact unstackable>
                              <Table.Header>
                                <Table.HeaderCell textAlign={'center'}>
                                  $/
                                  {
                                    props.productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Number of Trucks</>
                                </Table.HeaderCell>
                                {props?.is_admin && (
                                  <>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Business</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Location</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>FOB Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Lowest Freight Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Provider</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Divisor</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>TWD</>
                                    </Table.HeaderCell>
                                  </>
                                )}
                              </Table.Header>
                              <Table.Body>
                                {props.currentPortal === 'buyer' &&
                                  props.groupedAsks
                                    ?.sort((a, b) => {
                                      let aPrice = a.total_price
                                      let bPrice = b.total_price
                                      if (aPrice < bPrice) return -1
                                      if (bPrice >= aPrice) return -1
                                    })
                                    .map((asks) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell textAlign={'center'}>
                                            {asks[0]?.total_price > 0
                                              ? formatTotalPrice(
                                                  asks[0]?.total_price
                                                )
                                              : '--'}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {reduce(
                                              asks.map((ask) => {
                                                return ask.quantity
                                              }),
                                              (memo, num) => {
                                                return memo + num
                                              },
                                              0
                                            )}
                                          </Table.Cell>
                                          {props?.is_admin && (
                                            <>
                                              <Table.Cell
                                                textAlign={'center'}
                                                width={2}
                                              >
                                                {asks?.map((ask) => {
                                                  return ask?.business_name
                                                    ? ask?.business_name
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.formatted_address
                                                    ? ask?.formatted_address
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.price
                                                    ? formatTotalPrice(
                                                        ask?.price
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.rate > 0
                                                    ? formatTotalPrice(
                                                        ask?.trucking?.rate
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking
                                                    ?.trucking_company
                                                    ? ask?.trucking
                                                        ?.trucking_company
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.divisor
                                                    ? ask?.trucking?.divisor
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.rate &&
                                                    ask?.trucking?.divisor &&
                                                    ask?.trucking?.rate /
                                                      ask?.trucking?.divisor >
                                                      0
                                                    ? formatTotalPrice(
                                                        ask?.trucking?.rate /
                                                          ask?.trucking?.divisor
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                            </>
                                          )}
                                        </Table.Row>
                                      )
                                    })}
                                {props.currentPortal === 'supplier' &&
                                  props.groupedBids
                                    ?.sort((a, b) => {
                                      let aPrice = a.total_price
                                      let bPrice = b.total_price
                                      if (aPrice > bPrice) return -1
                                      if (bPrice <= aPrice) return -1
                                    })
                                    .map((bids) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell textAlign={'center'}>
                                            {bids[0]?.total_price > 0
                                              ? formatTotalPrice(
                                                  bids[0]?.total_price
                                                )
                                              : '--'}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {reduce(
                                              bids.map((bid) => {
                                                return bid.quantity
                                              }),
                                              (memo, num) => {
                                                return memo + num
                                              },
                                              0
                                            )}
                                          </Table.Cell>
                                          {props?.is_admin && (
                                            <>
                                              <Table.Cell
                                                textAlign={'center'}
                                                width={2}
                                              >
                                                {bids?.map((bid) => {
                                                  return bid?.business_name
                                                    ? bid?.business_name
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.formatted_address
                                                    ? bid?.formatted_address
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.price
                                                    ? formatTotalPrice(
                                                        bid?.price
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.rate > 0
                                                    ? formatTotalPrice(
                                                        bid?.trucking?.rate
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking
                                                    ?.trucking_company
                                                    ? bid?.trucking
                                                        ?.trucking_company
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.divisor
                                                    ? bid?.trucking?.divisor
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.rate &&
                                                    bid?.trucking?.divisor &&
                                                    bid?.trucking?.rate /
                                                      bid?.trucking?.divisor >
                                                      0
                                                    ? formatTotalPrice(
                                                        bid?.trucking?.rate /
                                                          bid?.trucking?.divisor
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                            </>
                                          )}
                                        </Table.Row>
                                      )
                                    })}
                              </Table.Body>
                            </Table>
                          </div>
                        }
                        on={'click'}
                        offset={props?.is_admin ? [-60, 40] : [-10, 30]}
                        trigger={
                          <b className="hover-price-underline">
                            {props.currentPortal === 'supplier'
                              ? highestBidOrder
                                ? formatTotalPrice(
                                    highestBidOrder?.total_price
                                  ) +
                                  '/' +
                                  props.productVariant?.product
                                    ?.default_unit_of_measure?.short_name
                                : '--'
                              : lowestAskOrder
                              ? formatTotalPrice(lowestAskOrder?.total_price) +
                                '/' +
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              : '--'}
                          </b>
                        }
                      />
                    ) : (
                      '--'
                    )}
                  </p>
                  <label>
                    {props.currentPortal === 'supplier'
                      ? (props.exchangeOrderBook?.bid_orders?.length > 0
                          ? highestBidOrder?.quantity
                          : '') +
                        ' ' +
                        (props.exchangeOrderBook?.bid_orders?.length > 0
                          ? highestBidOrder?.cargo_unit?.plural_name
                          : '')
                      : (props.exchangeOrderBook?.ask_orders?.length > 0
                          ? lowestAskOrder?.quantity
                          : '') +
                        ' ' +
                        (props.exchangeOrderBook?.ask_orders?.length > 0
                          ? lowestAskOrder?.cargo_unit?.plural_name
                          : '')}
                  </label>
                  <p
                    style={{
                      fontSize: '0.7em',
                      marginBottom: '14px',
                    }}
                    className={
                      props.hoverRow === props.index
                        ? 'attribute-hover visible'
                        : 'attribute-hover'
                    }
                  >
                    {showCancelBid &&
                      (props.currentPortal === 'supplier'
                        ? (props.exchangeOrderBook?.ask_orders?.length > 0
                            ? lowestAskOrder?.quantity
                            : '') +
                          ' ' +
                          (props.exchangeOrderBook?.ask_orders?.length > 0
                            ? lowestAskOrder?.cargo_unit?.plural_name
                            : '')
                        : (props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.quantity
                            : '') +
                          ' ' +
                          (props.exchangeOrderBook?.bid_orders?.length > 0
                            ? highestBidOrder?.cargo_unit?.plural_name
                            : ''))}
                    {!showCancelBid &&
                      (props.currentPortal === 'supplier'
                        ? 'Current Bid'
                        : 'Current Ask')}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginTop: '1.25rem' }}>
                    {showCancelBid &&
                      showCancelBid &&
                      props.currentPortal === 'supplier' &&
                      props.exchangeOrderBook?.ask_orders?.length &&
                      lowestAskOrder?.status === 'open' && (
                        <div className="ring-container">
                          <div className="ringring"></div>
                          <div className="circle"></div>
                        </div>
                      )}
                    {showCancelBid &&
                      showCancelBid &&
                      props.currentPortal === 'buyer' &&
                      props.exchangeOrderBook?.bid_orders?.length &&
                      highestBidOrder?.status === 'open' && (
                        <div className="ring-container">
                          <div className="ringring"></div>
                          <div className="circle"></div>
                        </div>
                      )}
                    {showCancelBid &&
                      showCancelBid &&
                      props.currentPortal === 'supplier' &&
                      props.exchangeOrderBook?.ask_orders?.length &&
                      lowestAskOrder?.status === 'disabled' && (
                        <div className="disabled-ring-container">
                          <div className="disabled-circle"></div>
                        </div>
                      )}
                    {showCancelBid &&
                      showCancelBid &&
                      props.currentPortal === 'buyer' &&
                      props.exchangeOrderBook?.bid_orders?.length &&
                      highestBidOrder?.status === 'disabled' && (
                        <div className="disabled-ring-container">
                          <div className="disabled-circle"></div>
                        </div>
                      )}
                  </div>
                  <div>
                    <label>
                      {' '}
                      Your {props.currentPortal === 'supplier' ? 'Sell' : 'Buy'}
                    </label>
                    <p
                      style={{
                        margin: '0',
                        fontSize: '1em',
                        marginTop: '5px',
                      }}
                    >
                      <b>
                        {props.currentPortal === 'supplier' ? (
                          showCancelBid ? (
                            // TODO: this should make sure the 0th ask or bid is the user's
                            <b>
                              {formatTotalPrice(
                                props.currentPortal === 'supplier'
                                  ? lowestAskOrder?.price
                                  : highestBidOrder?.price
                              )}
                              /
                              {
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              }
                            </b>
                          ) : (
                            <b>--</b>
                          )
                        ) : showCancelBid ? (
                          <b>
                            {formatTotalPrice(
                              props.currentPortal === 'supplier'
                                ? lowestAskOrder?.price
                                : highestBidOrder?.price
                            )}
                            /
                            {
                              props.productVariant?.product
                                ?.default_unit_of_measure?.short_name
                            }
                          </b>
                        ) : (
                          <b>--</b>
                        )}
                      </b>
                    </p>
                    <label>
                      {props.currentPortal === 'supplier'
                        ? (props.exchangeOrderBook?.ask_orders?.length
                            ? lowestAskOrder?.quantity
                            : '') +
                          ' ' +
                          (props.exchangeOrderBook?.ask_orders?.length
                            ? lowestAskOrder?.cargo_unit?.plural_name
                            : '')
                        : (props.exchangeOrderBook?.bid_orders?.length
                            ? highestBidOrder?.quantity
                            : '') +
                          ' ' +
                          (props.exchangeOrderBook?.bid_orders?.length
                            ? highestBidOrder?.cargo_unit?.plural_name
                            : '')}
                    </label>
                  </div>
                </div>
                <p
                  style={{
                    fontSize: '0.7em',
                    marginBottom: '14px',
                  }}
                  className={
                    props.hoverRow === props.index
                      ? 'attribute-hover visible'
                      : 'attribute-hover'
                  }
                >
                  {showCancelBid &&
                    (props.currentPortal === 'supplier'
                      ? (props.exchangeOrderBook?.ask_orders?.length
                          ? lowestAskOrder?.quantity
                          : '') +
                        ' ' +
                        (props.exchangeOrderBook?.ask_orders?.length
                          ? lowestAskOrder?.cargo_unit?.plural_name
                          : '')
                      : (props.exchangeOrderBook?.bid_orders?.length
                          ? highestBidOrder?.quantity
                          : '') +
                        ' ' +
                        (props.exchangeOrderBook?.bid_orders?.length
                          ? highestBidOrder?.cargo_unit?.plural_name
                          : ''))}
                  {!showCancelBid &&
                    (props.currentPortal === 'supplier'
                      ? 'Current Bid'
                      : 'Current Ask')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </Table.Row>
    </>
  )
}
export default ExchangeTableMobileRow
