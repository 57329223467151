import React from 'react'
import { Modal, Grid } from 'semantic-ui-react'
import MickeyForm from '../Forms/MickeyForm'
import { hasPricing } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'

const PriceHistoryFormModal = (props) => {
  const {
    priceHistory,
    showModal,
    toggleModal,
    setReload,
    businessId,
    businessName,
  } = props
  const user = useSelector((state) => state.user.user)

  return (
    <>
      <Modal
        onClose={() => toggleModal(false)}
        onOpen={() => toggleModal(true)}
        open={showModal}
        size={'large'}
        closeIcon
      >
        <Modal.Header>
          <Grid.Row>
            <Grid.Column>
              {priceHistory ? 'Edit Price History' : 'Create Price History'}
            </Grid.Column>
          </Grid.Row>
        </Modal.Header>
        <Modal.Content>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <MickeyForm
                  handleForm={(status, response) => {
                    toggleModal()
                    setReload(false)
                    setReload(true)
                  }}
                  postSubmit={() => {
                    setReload(false)
                    setReload(true)
                  }}
                  formName={
                    hasPricing(user, 'primary')
                      ? 'ExchangeOrderHistoryForm'
                      : 'ExchangeOrderHistorySecondaryForm'
                  }
                  model={'ExchangeOrderHistory'}
                  disabledDefaultFields={
                    businessId
                      ? {
                          business: {
                            value: businessId,
                            request_params: 'business_id',
                            type: 'dropdown search',
                            options_value: businessName,
                          },
                        }
                      : null
                  }
                  disabledFields={priceHistory?.id ? { product: true } : null}
                  tenantAware={false}
                  instanceId={priceHistory?.id}
                  url={'/main/exchange-order-price-history/'}
                  style={{ border: 'none', boxShadow: 'none' }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default PriceHistoryFormModal
