import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useAxios from '../../../../lib/auth/useAxios'
import MXExchange from '../MXExchange/MXExchange'
import MickeyPlaceholderLoading from '../../../../components/PlaceholderLoading/PlaceholderLoading'

const MXProduct = (props) => {
  const { hideFilter, setHideFilter } = props
  const [product, setProduct] = useState()
  const [reload, setReload] = useState(true)
  const axios = useAxios()
  const location = useLocation()
  const { productSlug } = useParams()

  useEffect(() => {
    if (location.pathname.includes(`${productSlug}`)) {
      setHideFilter(true)
      props.setOpenExchangeFilter(false)
    }
    return () => {
      setHideFilter(false)
      props.setOpenExchangeFilter(true)
    }
  }, [])

  useEffect(() => {
    const getProduct = async () => {
      let res = await axios.get('/main/products/', {
        params: {
          slug: productSlug,
        },
      })
      if (res?.data?.results) {
        setProduct(res.data.results[0])
        props.setSelectedCommodity(res.data.results[0].commodity)
      }
    }
    getProduct()
  }, [productSlug])

  return !product ? (
    <MickeyPlaceholderLoading style={{ margin: '3rem' }} />
  ) : (
    <MXExchange
      reload={reload}
      setReload={setReload}
      product={product}
      user={props.user}
      setUser={props.setUser}
      openExchangeFilter={props.openExchangeFilter}
      currentPortal={props.currentPortal}
      setCurrentPortal={props.setCurrentPortal}
      selectedCommodity={props.selectedCommodity}
      setSelectedCommodity={props.setSelectedCommodity}
      inputExpiration={props.inputExpiration}
      setInputExpiration={props.setInputExpiration}
      currentSelectedLocation={props.currentSelectedLocation}
      setCurrentSelectedLocation={props.setCurrentSelectedLocation}
      openStartListingModal={props.openStartListingModal}
      setStartListingModal={props.setStartListingModal}
      openSetAttributesModal={props.openSetAttributesModal}
      setOpenAttributesModal={props.setOpenAttributesModal}
      openPricingModal={props.openPricingModal}
      setOpenPricingModal={props.setOpenPricingModal}
      openReviewListingModal={props.openReviewListingModal}
      setReviewListingModal={props.setReviewListingModal}
      productVariant={props.productVariant}
      setProductVariant={props.setProductVariant}
      setInputPrice={props.setInputPrice}
      inputPrice={props.inputPrice}
      setInputSecondaryPrice={props.setInputSecondaryPrice}
      inputSecondaryPrice={props.inputSecondaryPrice}
      setReloadHeaderStats={props.setReloadHeaderStats}
      setInputQuantity={props.setInputQuantity}
      inputQuantity={props.inputQuantity}
      timeframe={props.timeframe}
      setCurrentTab={props.setCurrentTab}
      currentTab={props.currentTab}
      equipmentType={props.equipmentType}
      setEquipmentType={props.setEquipmentType}
      setSelectedCommodityForm={props.setSelectedCommodityForm}
      publicExchange={props.publicExchange}
      hideFilter={hideFilter}
      setOpenExchangeFilter={props.setOpenExchangeFilter}
    />
  )
}

export default MXProduct
