import React, { useState, useEffect } from 'react'
import './MXTicker.css'
import Ticker from 'react-ticker'
import useAxios from '../../../../lib/auth/useAxios'
import WebFont from 'webfontloader'

const MXTicker = (props) => {
  const axios = useAxios()
  const tickers = props.tickers
  const setTickers = props.setTickers

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Brown'],
        urls: ['/index.css'],
      },
    })
  }, [])

  useEffect(() => {
    const getTickers = async () => {
      const res = await axios.get(`/main/tickers/`, {
        params: {
          is_hompage: true,
        },
      })
      const data = res?.data?.results
      let tickersList = []
      if (data?.length > 0) {
        const res2 = await axios.get(`/main/ticker-fields/`, {
          params: {
            ticker_id: data[0]?.id,
          },
        })
        if (res2.data?.results?.length > 0) {
          res2.data?.results?.forEach((ticker, index) => {
            tickersList.push(
              <p className="ticker-item-detail" key={index}>
                {ticker.name}{' '}
                <span style={{ color: '#6D6E70' }}>
                  {ticker.description !== '' ? ticker.description : ''}
                </span>
                {ticker.url && (
                  <a
                    href={ticker.url}
                    style={{
                      marginLeft: '5px',
                      color: '#f1592a',
                      textDecoration: 'underline',
                    }}
                  >
                    {ticker.link_text}
                  </a>
                )}
              </p>
            )
          })
          setTickers(tickers.length > 0 ? [] : tickersList)
        }
      }
    }
    getTickers()
  }, [])

  return tickers.length > 0 ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>{tickers}</div>
  ) : (
    <p style={{ visibility: 'hidden' }}>Placeholder</p>
  )
}

function StockTicker() {
  const [tickers, setTickers] = useState([])
  return (
    <div id="mx-ticker">
      <Ticker offset={'run-in'} speed={5}>
        {({ index }) => (
          <>
            <MXTicker tickers={tickers} setTickers={setTickers} />
          </>
        )}
      </Ticker>
    </div>
  )
}

export default StockTicker
