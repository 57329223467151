import React, { useState, useEffect } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import './AdminResources.css'
import { Grid, Header, Modal, Form, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import env from '../../../env'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminNewsRow from './AdminNewsRow'
import PageHeader from '../../../components/PageHeader/PageHeader'
import AdminEventsRow from './AdminEventsRow'
import AdminPressRow from './AdminPressRow'
import AdminTeamMemberRow from './AdminTeamMemberRow'
import AdminTickerRow from './AdminTickerRow'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Plus } from 'phosphor-react'

const AdminResources = (props) => {
  const { openAddModal, setOpenAddModal, selectedTab, setSelectedTab } = props
  const [reload, setReload] = useState(false)
  const [openAddNewsModal, setOpenAddNewsModal] = useState(false)
  const [openAddEventModal, setOpenAddEventModal] = useState(false)
  const [openAddPressReleaseModal, setOpenAddPressReleaseModal] =
    useState(false)
  const [openAddTeamMemberModal, setOpenAddTeamMemberModal] = useState(false)
  const [openAddTickerModal, setOpenAddTickerModal] = useState(false)
  const [pressReleaseTitle, setPressReleaseTitle] = useState(null)
  const [pressReleaseSubheader, setPressReleaseSubheader] = useState(null)
  const [pressReleasePubDate, setPressReleasePubDate] = useState(null)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const [ticker, setTicker] = useState(null)
  const [showError, setShowError] = useState(false)
  const axios = useAxios()

  const handleTitle = (title) => {
    setPressReleaseTitle(title)
  }
  const handleSubheader = (subheader) => {
    setPressReleaseSubheader(subheader)
  }

  const handlePub = (pub) => {
    setPressReleasePubDate(pub)
  }

  useEffect(() => {
    async function getHomepageTicker() {
      let res = await axios.get(`/main/tickers`, {
        params: {
          is_homepage: true,
        },
      })
      if (res?.data?.results?.length > 0) {
        setTicker(res.data.results[0])
      }
    }
    getHomepageTicker()
  }, [])

  const addPressRelease = async () => {
    if (
      !pressReleaseTitle ||
      !pressReleaseTitle.length > 0 ||
      !pressReleaseSubheader ||
      !pressReleaseSubheader.length > 0 ||
      !pressReleasePubDate ||
      !editorState?.getCurrentContent()?.hasText()
    ) {
      setShowError(true)
    } else {
      setOpenAddPressReleaseModal(false)
      await axios.post(`/main/press-releases/`, {
        title: pressReleaseTitle,
        subheader: pressReleaseSubheader,
        publish_date: pressReleasePubDate.toLocaleDateString('en-US'),
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      })
      setReload(true)
      setShowError(false)
      setOpenAddModal(false)
    }
  }

  const addNewPressRelease = () => {
    setOpenAddPressReleaseModal(true)
    setPressReleasePubDate(null)
    setPressReleaseSubheader(null)
    setPressReleaseTitle(null)
    setEditorState(() => EditorState.createEmpty())
  }

  const clearValues = () => {
    setOpenAddPressReleaseModal(false)
    setPressReleasePubDate(null)
    setPressReleaseSubheader(null)
    setPressReleaseTitle(null)
    setEditorState(() => EditorState.createEmpty())
    setShowError(false)
    setOpenAddModal(false)
  }

  const pressHeaderRow = [
    'Tite',
    'Created At',
    'Publish Date',
    'Content',
    'Actions',
  ]
  const newsHeaderRow = [
    'Title',
    'Publish Date',
    'Created At',
    'Link',
    'Description',
    'Logo',
    'Actions',
  ]
  const eventHeaderRow = [
    'Event Date',
    'Name',
    'Link',
    'Description',
    'Actions',
  ]
  const teamMemberHeaderRow = ['Last Name', 'First Name', 'Position', 'Actions']
  const tickerHeaderRow = ['Name', 'Description', 'Actions']

  useEffect(() => {
    if (selectedTab === 'press' && openAddModal === true) {
      addNewPressRelease()
    } else {
      setOpenAddPressReleaseModal(false)
      setShowError(false)
    }
    if (selectedTab === 'news') {
      setOpenAddNewsModal(openAddModal)
    }
    if (selectedTab === 'events') {
      setOpenAddEventModal(openAddModal)
    }
    if (selectedTab === 'team-members') {
      setOpenAddTeamMemberModal(openAddModal)
    }
    if (selectedTab === 'tickers') {
      setOpenAddTickerModal(openAddModal)
    }
  }, [openAddModal])

  return (
    <div className="main-container">
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} style={{ paddingLeft: '25px' }}>
            <div className="exchange-table-heading">
              <div className="commodities-wrapper">
                <Header
                  as={'h2'}
                  className={
                    selectedTab === 'press'
                      ? 'commodity-header selected'
                      : 'commodity-header'
                  }
                  color={selectedTab === 'press' ? 'primary' : 'black'}
                  onClick={() => setSelectedTab('press')}
                  style={{
                    margin: '0 1rem 0rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  Press
                </Header>
                <Header
                  as={'h2'}
                  className={
                    selectedTab === 'news'
                      ? 'commodity-header selected'
                      : 'commodity-header'
                  }
                  color={selectedTab === 'news' ? 'primary' : 'black'}
                  onClick={() => setSelectedTab('news')}
                  style={{
                    margin: '0 1rem 0rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  News
                </Header>

                <Header
                  as={'h2'}
                  className={
                    selectedTab === 'events'
                      ? 'commodity-header selected'
                      : 'commodity-header'
                  }
                  color={selectedTab === 'events' ? 'primary' : 'black'}
                  onClick={() => setSelectedTab('events')}
                  style={{
                    margin: '0 1rem 0rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  Events
                </Header>
                <Header
                  as={'h2'}
                  className={
                    selectedTab === 'team-members'
                      ? 'commodity-header selected'
                      : 'commodity-header'
                  }
                  color={selectedTab === 'team-members' ? 'primary' : 'black'}
                  onClick={() => setSelectedTab('team-members')}
                  style={{
                    margin: '0 1rem 0rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  Team Members
                </Header>
                <Header
                  as={'h2'}
                  className={
                    selectedTab === 'tickers'
                      ? 'commodity-header selected'
                      : 'commodity-header'
                  }
                  color={selectedTab === 'tickers' ? 'primary' : 'black'}
                  onClick={() => setSelectedTab('tickers')}
                  style={{
                    margin: '0 1rem 0rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  Tickers
                </Header>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {selectedTab === 'press' && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={pressHeaderRow}
                  url={'/main/press-releases/'}
                  reload={reload}
                  setReload={setReload}
                  basicSegment
                  renderBodyRow={(data, index) => {
                    return (
                      <AdminPressRow
                        setPressReleasePubDate={setPressReleasePubDate}
                        pressReleasePubDate={pressReleasePubDate}
                        setPressReleaseSubheader={setPressReleaseSubheader}
                        pressReleaseSubheader={pressReleaseSubheader}
                        pressReleaseTitle={pressReleaseTitle}
                        setPressReleaseTitle={setPressReleaseTitle}
                        setEditorState={setEditorState}
                        editorState={editorState}
                        handlePub={handlePub}
                        handleSubheader={handleSubheader}
                        handleTitle={handleTitle}
                        pressRelease={data}
                        index={index}
                        setReload={setReload}
                        key={index}
                        setOpenAddPressReleaseModal={
                          setOpenAddPressReleaseModal
                        }
                      />
                    )
                  }}
                  emptyIcon={'wordpress forms'}
                  emptyMessage={'Press Releases will populate here'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {selectedTab === 'news' && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={newsHeaderRow}
                  url={'/main/news/'}
                  reload={reload}
                  setReload={setReload}
                  basicSegment
                  renderBodyRow={(data, index) => {
                    return (
                      <AdminNewsRow
                        news={data}
                        index={index}
                        setReload={setReload}
                        key={index}
                      />
                    )
                  }}
                  emptyIcon={'newspaper'}
                  emptyMessage={'News articles will populate here'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {selectedTab === 'events' && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={eventHeaderRow}
                  url={'/main/events/'}
                  reload={reload}
                  setReload={setReload}
                  basicSegment
                  renderBodyRow={(data, index) => {
                    return (
                      <AdminEventsRow
                        events={data}
                        index={index}
                        setReload={setReload}
                        key={index}
                      />
                    )
                  }}
                  emptyIcon={'globe'}
                  emptyMessage={'Events will populate here'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {selectedTab === 'team-members' && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={teamMemberHeaderRow}
                  url={'/main/team-members/'}
                  reload={reload}
                  setReload={setReload}
                  basicSegment
                  renderBodyRow={(data, index) => {
                    return (
                      <AdminTeamMemberRow
                        teamMember={data}
                        index={index}
                        setReload={setReload}
                        key={index}
                      />
                    )
                  }}
                  emptyIcon={'user'}
                  emptyMessage={'Team members will populate here'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {selectedTab === 'tickers' && (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={tickerHeaderRow}
                  url={'/main/ticker-fields/'}
                  reload={reload}
                  setReload={setReload}
                  basicSegment
                  renderBodyRow={(data, index) => {
                    return (
                      <AdminTickerRow
                        ticker={data}
                        index={index}
                        setReload={setReload}
                        key={index}
                      />
                    )
                  }}
                  emptyIcon={'user'}
                  emptyMessage={'Ticker Fields will populate here'}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
      <Modal
        onClose={() => {
          setOpenAddPressReleaseModal(false)
          setShowError(false)
          setOpenAddModal(false)
        }}
        onOpen={() => setOpenAddPressReleaseModal(true)}
        open={openAddPressReleaseModal}
      >
        <Header>Add Press Release</Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                placeholder="Title"
                label="Title"
                onChange={(e, d) => handleTitle(d?.value)}
              />
              {(!pressReleaseTitle || !pressReleaseTitle?.length > 0) &&
                showError && (
                  <p style={{ color: 'red' }}>
                    <small>This field is required</small>
                  </p>
                )}
            </Form.Field>
            <Form.Field>
              <Form.Input
                placeholder="Subheader"
                label="Subheader"
                onChange={(e, d) => handleSubheader(d?.value)}
              />
              {(!pressReleaseSubheader || !pressReleaseSubheader?.length > 0) &&
                showError && (
                  <p style={{ color: 'red' }}>
                    <small>This field is required</small>
                  </p>
                )}
            </Form.Field>
            <label
              style={{
                display: 'block',
                margin: '0em 0em 0.28571429rem 0em',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.92857143em',
              }}
            >
              Publish Date
            </label>
            <div
              className="customDatePickerWidth"
              style={{ marginBottom: '1rem' }}
            >
              <SemanticDatepicker
                onChange={(e, d) => handlePub(d?.value)}
                format={'MMM D, YYYY'}
                clearable={true}
              />
              {!pressReleasePubDate && showError && (
                <p style={{ color: 'red' }}>
                  <small>This field is required</small>
                </p>
              )}
            </div>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'history',
                ],
              }}
              editorState={editorState}
              editorClassName="editor-wrapper"
              onEditorStateChange={setEditorState}
            />
            {!editorState?.getCurrentContent()?.hasText() && showError && (
              <p style={{ color: 'red' }}>
                <small>This field is required</small>
              </p>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              clearValues()
              setOpenAddModal(false)
            }}
            color="red"
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={addPressRelease}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Add Press Release
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setOpenAddNewsModal(false)
          setOpenAddModal(false)
        }}
        onOpen={() => setOpenAddNewsModal(true)}
        open={openAddNewsModal}
      >
        <Modal.Header>Add News Article</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'NewsForm'}
            tenantAware={false}
            model={'News'}
            buttonFloatedRight={true}
            successMessage={'News Article Added!'}
            failureMessage={'Error adding news.'}
            url={'/main/news/'}
            handleForm={() => {
              setReload(true)
              setOpenAddNewsModal(false)
              setOpenAddModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => {
          setOpenAddEventModal(false)
          setOpenAddModal(false)
        }}
        onOpen={() => setOpenAddEventModal(true)}
        open={openAddEventModal}
      >
        <Modal.Header>Add Event</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AddEventForm'}
            tenantAware={false}
            model={'Event'}
            buttonFloatedRight={true}
            successMessage={'Event Added!'}
            failureMessage={'Error adding event.'}
            url={'/main/events/'}
            handleForm={() => {
              setReload(true)
              setOpenAddEventModal(false)
              setOpenAddModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => {
          setOpenAddTeamMemberModal(false)
          setOpenAddModal(false)
        }}
        onOpen={() => setOpenAddTeamMemberModal(true)}
        open={openAddTeamMemberModal}
      >
        <Modal.Header>Add Team Member</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'TeamMemberForm'}
            tenantAware={false}
            model={'TeamMember'}
            buttonFloatedRight={true}
            successMessage={'Team Member Added!'}
            failureMessage={'Error adding team member.'}
            url={'/main/team-members/'}
            handleForm={() => {
              setReload(true)
              setOpenAddTeamMemberModal(false)
              setOpenAddModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => {
          setOpenAddTickerModal(false)
          setOpenAddModal(false)
        }}
        onOpen={() => setOpenAddTickerModal(true)}
        open={openAddTickerModal}
      >
        <Modal.Header>Add Ticker</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'TickerFieldForm'}
            tenantAware={false}
            model={'TickerField'}
            buttonFloatedRight={true}
            successMessage={'Ticker Added!'}
            failureMessage={'Error adding ticker.'}
            url={'/main/ticker-fields/'}
            values={{
              ticker_id: ticker?.id,
            }}
            handleForm={() => {
              setReload(true)
              setOpenAddTickerModal(false)
              setOpenAddModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminResources
