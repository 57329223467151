import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import AdminEmailRow from './AdminEmailRow'

function AdminEmailsTable() {
  return (
    <div className={'main-container'}>
      <PageHeader icon={'file'} header={'Emails'} />
      <MickeyTable
        headerRow={['Date', 'To', 'Subject']}
        basicSegment
        url={'/main/email/'}
        renderBodyRow={(data, index) => {
          return (
            <AdminEmailRow
              data={data}
              index={index}
              key={data ? data.id : null}
            />
          )
        }}
        emptyIcon={'mail'}
        emptyMessage={"Email's will populate here"}
      />
    </div>
  )
}
export default AdminEmailsTable
