import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Segment,
  Header,
  Icon,
  Sidebar,
  Modal,
  Message,
  Form,
  Input,
  Menu,
} from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import { useDispatch } from 'react-redux'
import PageHeader from '../../../components/PageHeader/PageHeader'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { Steps } from 'intro.js-react'
import { addSupplier } from '../../../features/user/userSlice'
import Notifications from './Notifications'
import 'rc-time-picker/assets/index.css'
import 'rsuite/dist/rsuite.min.css'
import './Profile.css'
import { toast } from 'react-toastify'
import ManageLocationsRow from './ManageLocationsRow'
import ManageUsersRow from './ManageUsersRow'
import env from '../../../env'
import { Sling } from 'hamburger-react'
import ShopSettings from './ShopSettings'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import ActivityRow from './ActivityRow'
import SalesLogRow from './SalesLogRow'
import { useLocation } from 'react-router-dom'
import AdminBusinessTakeRate from '../../admin/AdminDashboard/AdminBusinessTakeRate'
import AdminBusinessExchange from '../../admin/AdminBusinesses/AdminBusinessExchange'
import MXPayments from '../../mx/mx_shared/MXPayments/MXPayments'
import AdminBusinessDocuments from '../../admin/AdminBusinesses/AdminBusinessDocuments'
import ProfilePriceHistory from './ProfilePriceHistory'
import AdminOrders from '../../admin/AdminOrders/AdminOrders'
import { hasPermission } from '../../../lib/utils/utils'
import NotesTable from './NotesTable'
import {
  X,
  Activity,
  AddressBook,
  Bell,
  ChatDots,
  CurrencyDollar,
  Files,
  House,
  Info,
  Money,
  Package,
  Paperclip,
  Percent,
  Recycle,
  Scales,
  Users,
  SquaresFour,
} from 'phosphor-react'
import ProfileSalesOrders from './ProfileSalesOrders'
import ProfilePurchaseOrders from './ProfilePurchaseOrders'
import { useSelector } from 'react-redux'
import MickeySideBar from '../../../components/MickeySideBar/MickeySideBar'
import ProfileDashboard from './ProfileDashboard'

function Profile(props) {
  // Props
  let {
    user,
    buyer,
    supplier,
    business,
    tenantAware,
    currentlySelectedLocation,
  } = props
  // State
  if (tenantAware != false) {
    tenantAware = true
  }
  console.log(currentlySelectedLocation)
  const reduxUser = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(reduxUser, 'Update Business')
  const [openCreateLocationsModal, setOpenCreateLocationsModal] =
    useState(false)
  const [openInviteNewUserModal, setOpenInviteNewUserModal] = useState(false)
  const [openSalesLogModal, setOpenSalesLogModal] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [locations, setLocations] = useState([])
  const [loaded, setLoaded] = useState(true)
  const [runTour, setRunTour] = useState(business?.completed_profile_tour)
  const [reload, setReload] = useState(false)
  const wd = useWindowDimensions()
  const [selectedMenuItem, setSelectedMenuItem] = useState('Dashboard')
  const dispatch = useDispatch()
  const [openFirstLocationModal, setOpenFirstLocationModal] = useState(false)
  const [balanceIdUpdated, setBalanceIdUpdated] = useState(null)
  const [supplierBalanceId, setSupplierBalanceId] = useState(
    business?.supplier?.balance_supplier_id
  )
  const [buyerBalanceId, setBuyerBalanceId] = useState(
    business?.buyer?.balance_buyer_id
  )
  const [editUserId, setEditUserId] = useState(null)
  // Hooks
  const axios = useAxios()
  const location = useLocation()
  // Vars
  const conditions = [
    {
      label: 'Primary Business',
      checks: Object?.keys(supplier ? supplier : {})?.length > 0 ? true : false,
    },
  ]

  if (tenantAware !== false) {
    tenantAware = true
  }

  useEffect(() => {
    if (selectedMenuItem !== 'Exchange') {
      props.setCurrentExchangeTable(null)
      if (props.setHideHeader) props.setHideHeader(true)
    } else {
      props.setCurrentExchangeTable(supplier ? 'supplier' : 'buyer')
      if (props.setHideHeader) props.setHideHeader(false)
    }
  }, [selectedMenuItem])

  const [
    openUploadResaleCertificateModal,
    setOpenUploadResaleCertificateModal,
  ] = useState(false)

  useEffect(() => {
    async function getData() {
      setLoaded(false)
      let res = await axios.get(`/main/locations/`, {
        params: {
          business_id: business?.id,
          tenant_aware: false,
        },
      })
      setLocations(res?.data.results)
      setReload(false)
      setLoaded(true)
    }
    if (business) {
      getData()
    }
  }, [reload, runTour])

  useEffect(() => {
    if (business?.id && !props.admin) {
      setOpenFirstLocationModal(!business.completed_profile)
    }
  }, [business?.completed_profile])

  // useEffect(() => {
  //   if (business?.status !== 'Approved') {
  //     setSelectedMenuItem("Account Details")
  //   }
  // }, [business?.id, business?.status])

  function handleAddLocationForm(status, data) {
    if (status === 'success') {
      setReload(true)
      setOpenFirstLocationModal(false)
      setOpenCreateLocationsModal(false)
    }
  }

  function handleInviteNewUser(status) {
    if (status === 'success') {
      setReload(true)
      setOpenInviteNewUserModal(false)
    }
  }

  function handleFirstLocation() {
    setOpenFirstLocationModal(false)
    setOpenCreateLocationsModal(false)
    setReload(true)
    setTimeout(() => {
      setRunTour(true)
    }, 800)
  }

  function handleUploadResaleCertificate(status, data) {
    if (data.success) {
      setOpenUploadResaleCertificateModal(false)
      dispatch(addSupplier(data.supplier))
    }
  }

  const tourStepsBuyer = [
    {
      element: '.buyer-step-1',
      title: 'Add Locations',
      intro: 'Have more locations? Add them by clicking here.',
    },
    {
      element: '.buyer-step-2',
      title: 'Edit Locations',
      intro: 'You can edit your locations here',
    },
    {
      element: '.buyer-step-3',
      title: 'General Info',
      intro: 'Double check your business information! Happy Trading!',
    },
  ]
  const tourStepsSupplier = [
    {
      element: '.supplier-step-1',
      title: 'Add Locations',
      intro: 'Have more locations? Add them by clicking here.',
    },
    {
      element: '.supplier-step-2',
      title: 'Edit Locations',
      intro: 'You can edit your locations here',
    },
    {
      element: '.supplier-step-3',
      title: 'General Info',
      intro: 'Double check your business information here',
    },
    {
      element: '.supplier-step-4',
      title: 'Inventory Page',
      intro:
        'Double check that your business information and location information are accruate, then navigate to the inventory tab in the menu.',
    },
  ]

  const updateFinishedPageTour = async () => {
    await axios.patch(`/main/businesses/${business?.id}/?tenant_aware=false`, {
      completed_profile_tour: true,
    })
    setRunTour(false)
    window.location.reload()
  }

  const locationsHeaderRow = ['Name', 'Address', 'Phone', 'Date Added']
  if (hasPermission(reduxUser, 'Delete Location')) {
    locationsHeaderRow.push('Actions')
  }
  const usersHeaderRow = ['Name', 'Email', 'Phone', 'Date Added', 'Contact']
  if (
    hasPermission(reduxUser, 'Update User') ||
    hasPermission(reduxUser, 'Delete User')
  ) {
    usersHeaderRow.splice(3, 0, 'Actions')
  }

  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab('settings')
    }
  }, [])

  useEffect(() => {
    setOpenCreateLocationsModal(props.openCreateLocationsModal)
  }, [props.openCreateLocationsModal])

  useEffect(() => {
    setOpenInviteNewUserModal(props.openInviteNewUserModal)
  }, [props.openInviteNewUserModal])

  useEffect(() => {
    setOpenSalesLogModal(props.openSalesLogModal)
  }, [props.openSalesLogModal])

  useEffect(() => {
    if (props.setProfileActiveTab) {
      props.setProfileActiveTab(selectedMenuItem)
    }
  }, [selectedMenuItem])

  useEffect(() => {
    if (props.setOpenCreateLocationsModal) {
      props.setOpenCreateLocationsModal(openCreateLocationsModal)
    }
  }, [openCreateLocationsModal])

  useEffect(() => {
    if (props.setOpenInviteNewUserModal) {
      props.setOpenInviteNewUserModal(openInviteNewUserModal)
    }
  }, [openInviteNewUserModal])

  useEffect(() => {
    if (props.setOpenSalesLogModal) {
      props.setOpenSalesLogModal(openSalesLogModal)
    }
  }, [openSalesLogModal])

  async function updateBalaceId(value, modelName) {
    if (props.admin) {
      await axios.post(`/main/auto-update/`, {
        model: modelName,
        field:
          modelName === 'Supplier' ? 'balance_supplier_id' : 'balance_buyer_id',
        value: value,
        instance_id:
          modelName === 'Supplier'
            ? business?.supplier?.id
            : business?.buyer?.id,
      })

      setBalanceIdUpdated(modelName)
      setTimeout(() => {
        setBalanceIdUpdated(null)
      }, 2000)
    }
  }
  return (
    <div
      className={'main-profile-container'}
      style={{
        ...props.style,
        padding: selectedMenuItem === 'Exchange' && '0 0 15px 0',
      }}
      id={props.admin ? '' : 'profile-container'}
    >
      <MickeySideBar
        as={Menu}
        animation="Uncover"
        icon="labeled"
        vertical
        visible={true}
        width="thin"
        activeButton={selectedMenuItem}
        setActiveButton={setSelectedMenuItem}
        sidebarContent={{
          header: {
            icon: (
              <AddressBook
                weight="fill"
                size={30}
                style={{ marginBottom: '0px', paddingBottom: '0px' }}
              />
            ),
            header: `${business?.name}`,
            // sub_header: `${business?.billing_address_city}, ${business?.billing_address_state}`,
          },
          sections: {
            BUSINESS: {
              permission: props.admin,
              items: [
                {
                  icon: <SquaresFour size={20} />,
                  text: 'Dashboard',
                  permission: hasPermission(reduxUser, 'Read Order'),
                },
                {
                  icon: <Info size={20} />,
                  text: 'Account Details',
                  permission: hasPermission(reduxUser, 'Read Business'),
                },
                {
                  icon: <House size={20} />,
                  text: 'Manage Locations',
                  permission: hasPermission(reduxUser, 'Read Location'),
                  activeButtonText: 'Manage Addresses',
                },
                {
                  icon: <Users size={20} />,
                  text: 'Manage Users',
                  permission: hasPermission(reduxUser, 'Read User'),
                },
                {
                  icon: <Bell size={20} />,
                  text: 'Notifications',
                  permissions: hasPermission(reduxUser, 'Read Notification'),
                },
                {
                  icon: <Package size={20} />,
                  text: 'Deals',
                  permission:
                    hasPermission(reduxUser, 'Read Order') && props.admin,
                },
              ],
            },
            SALES: {
              permission: props.admin,
              items: [
                {
                  icon: <Activity size={20} />,
                  text: 'Activity Logs',
                  permission: hasPermission(reduxUser, 'Read Activity Log'),
                },
                {
                  icon: <Recycle size={20} />,
                  text: 'Exchange',
                  permission: hasPermission(reduxUser, 'Read Exchange'),
                },
                {
                  icon: <CurrencyDollar size={20} />,
                  text: 'Price History',
                  permission: hasPermission(reduxUser, 'Read Price History'),
                },
                {
                  icon: <ChatDots size={20} />,
                  text: 'Notes',
                  permission: hasPermission(reduxUser, 'Read Sales Log'),
                  activeButtonText: 'Sales Logs',
                },
                {
                  icon: <Percent size={20} />,
                  text: 'Take Rates',
                  permission: hasPermission(reduxUser, 'Read Take Rate'),
                  activeButtonText: 'Take Rate',
                },
              ],
            },
            ACCOUNTING: {
              permission:
                props.admin && hasPermission(reduxUser, 'Read Payment'),
              items: [
                {
                  icon: <Money size={20} />,
                  text: 'Payments',
                },
                {
                  icon: <Package size={20} />,
                  text: 'Sales Orders',
                  permission: business?.buyer?.id,
                },
                {
                  icon: <Package size={20} />,
                  text: 'Purchase Orders',
                  permission: business?.supplier?.id,
                },
              ],
            },
            DOCUMENTS: {
              permission: hasPermission(reduxUser, 'Read Document'),
              items: [
                {
                  icon: <Files size={20} />,
                  text: 'Documents',
                },
              ],
            },
          },
        }}
      />
      <Segment
        style={{
          border: 'none',
          boxShadow: 'none',
          padding: 0,
          margin: selectedMenuItem === 'Exchange' ? '0' : '0', // need to set a value not null or the build will break
        }}
      >
        {runTour && !openFirstLocationModal && loaded && (
          <Steps
            enabled={runTour}
            steps={business.is_buyer ? tourStepsBuyer : tourStepsSupplier}
            initialStep={0}
            onComplete={() => updateFinishedPageTour()}
            onExit={() => setRunTour(false)}
            options={{
              exitOnEsc: false,
              exitOnOverlayClick: false,
              showStepNumbers: true,
              showBullets: false,
              showProgress: true,
            }}
          />
        )}
        <div>
          <div>
            {wd?.width < 1001 && (
              <Sling
                className="profile-hamburger"
                toggled={mobileMenuOpen}
                toggle={setMobileMenuOpen}
              />
            )}
            {selectedMenuItem === 'Price History' && (
              <ProfilePriceHistory business={business} />
            )}
            {selectedMenuItem === 'Sales Orders' &&
              props.admin &&
              business?.buyer?.id && (
                <ProfileSalesOrders
                  business={business}
                  openAddSalesOrderModel={props.openAddSalesOrderModel}
                  setOpenAddSalesOrderModel={props.setOpenAddSalesOrderModel}
                />
              )}
            {selectedMenuItem === 'Purchase Orders' &&
              props.admin &&
              business?.supplier?.id && (
                <ProfilePurchaseOrders
                  business={business}
                  openAddPurchaseOrderModal={props.openAddPurchaseOrderModal}
                  setOpenAddPurchaseOrderModal={
                    props.setOpenAddPurchaseOrderModal
                  }
                />
              )}
            {selectedMenuItem === 'Payments' && (
              <MXPayments
                businessId={business?.id}
                hideHeader={true}
                style={{ boxShadow: 'none', border: 'none' }}
              />
            )}
            {selectedMenuItem === 'Take Rate' && (
              <div style={{ padding: '0px 2rem' }}>
                <div
                  style={{ margin: '3rem 0 0 0' }}
                  className="supplier-step-3 buyer-step-3"
                >
                  <AdminBusinessTakeRate business={business} />
                </div>
              </div>
            )}
            {selectedMenuItem === 'Notifications' && (
              <div style={{ padding: '0px 2rem' }}>
                <div
                  style={{ margin: '3rem 0 0 0' }}
                  className="supplier-step-3 buyer-step-3"
                >
                  <Notifications
                    userId={user?.id}
                    business={business}
                    showAddNotificationsModal={props.showAddNotificationsModal}
                    setShowAddNotificationsModal={
                      props.setShowAddNotificationsModal
                    }
                    admin={props.admin}
                    showHeaders={props.showHeaders}
                    userReceiveSMSMessages={user?.receive_sms_messages}
                  />
                </div>
              </div>
            )}
            {selectedMenuItem === 'Profile' && supplier && (
              <div style={{ padding: '0px 2rem' }}>
                <div
                  style={{ margin: '3rem 0 0 0' }}
                  className="supplier-step-3 buyer-step-3"
                >
                  <ShopSettings supplier={supplier} />
                </div>
              </div>
            )}
            {props.admin && selectedMenuItem === 'Activity Logs' && (
              <div style={{ padding: '0rem' }}>
                <div style={{ marginTop: '0px' }}>
                  <MickeyTable
                    headerRow={[
                      'Date',
                      'User',
                      'Created For',
                      'Updated Object',
                      'Object ID',
                      'Updated Field',
                      'Old Value',
                      'New Value',
                    ]}
                    url={'/main/activities/'}
                    reload={reload}
                    setReload={setReload}
                    basicSegment
                    emptyIcon={'user secret'}
                    emptyMessage={'No activity.'}
                    renderBodyRow={(data, index) => {
                      return (
                        <ActivityRow
                          activity={data}
                          index={index}
                          admin={props.admin}
                          supplier={props.supplier}
                          buyer={props.buyer}
                          key={index}
                          setReload={setReload}
                        />
                      )
                    }}
                    urlParams={{
                      tenant_aware: false,
                      business_id: business?.id,
                    }}
                  />
                </div>
              </div>
            )}
            {props.admin && selectedMenuItem === 'Documents' && (
              <div style={{ padding: '0px 2rem' }}>
                <div
                  style={{ margin: '3rem 0 0 0' }}
                  className="supplier-step-3 buyer-step-3"
                >
                  <AdminBusinessDocuments business={business} />
                </div>
              </div>
            )}
            {selectedMenuItem === 'Account Details' && (
              <>
                {/* {props.supplier && canUpdate && */}
                {/*   supplier && */}
                {/*   (!supplier?.balance_supplier_id || */}
                {/*     !supplier?.business?.resale_certificate) && ( */}
                {/*     <Message color={"orange"} style={{ marginTop: '10px', display: 'inline-block' }}> */}
                {/*       {!supplier.balance_supplier_id && ( */}
                {/*         <> */}
                {/*           <Message.Header>Upload Bank Information</Message.Header> */}
                {/*           <p> */}
                {/*             Please add your banking information to finish */}
                {/*             setting up your profile. */}
                {/*           </p> */}
                {/*           <Button */}
                {/*             color={"orange"} */}
                {/*             href={supplier?.balance_onboarding_link} */}
                {/*           > */}
                {/*             Setup Banking */}
                {/*           </Button> */}
                {/*         </> */}
                {/*       )} */}
                {/*       {!supplier?.business?.resale_certificate && ( */}
                {/*         <> */}
                {/*           <Message.Header style={{ marginTop: '10px' }}>Warning</Message.Header> */}
                {/*           <p>Upload your resale certificate to avoid sales tax!</p> */}
                {/*           <Button */}
                {/*             color={"orange"} */}
                {/*             onClick={() => { */}
                {/*               setOpenUploadResaleCertificateModal(true); */}
                {/*             }} */}
                {/*           > */}
                {/*             Upload Resale Certificate */}
                {/*           </Button> */}
                {/*         </> */}
                {/*       )} */}
                {/*     </Message> */}
                {/*   )} */}
                <div
                  className="supplier-step-3 buyer-step-3"
                  style={{ marginTop: '30px', padding: '0 1rem' }}
                >
                  <Grid stackable>
                    <Grid.Column className="account-details-column">
                      <MickeyForm
                        formName={
                          props.admin
                            ? 'AdminBusinessApplicationForm'
                            : 'BusinessApplicationForm'
                        }
                        model={'Business'}
                        loadingHeight={'150px'}
                        instanceId={business?.id}
                        disabled={!canUpdate}
                        tenantAware={false}
                        url={'/main/businesses/'}
                        disabledFields={{ contact_email: true }}
                        urlParams={props.admin ? { admin: true } : {}}
                        currentPortal={props.admin && 'admin'}
                        conditions={conditions}
                        currentUpdatedField={(field, value) => {
                          if (field === 'verified_customer') {
                            props.setBusiness((prevState) => ({
                              ...prevState,
                              verified_customer: value,
                            }))
                          }
                        }}
                      />
                      {props.admin && (
                        <Form.Group
                          columns={'equal'}
                          style={{ paddingBottom: '20px', display: 'flex' }}
                        >
                          {props.supplier && (
                            <Form.Field
                              style={{ paddingRight: '7px', width: '50%' }}
                            >
                              <div>
                                <label>
                                  Supplier Balance ID
                                  <label
                                    className={
                                      balanceIdUpdated === 'Supplier'
                                        ? 'saved-label saving'
                                        : 'saved-label'
                                    }
                                  >
                                    {' '}
                                    Saved!
                                  </label>
                                </label>
                              </div>
                              <Input
                                type={'text'}
                                disabled={!canUpdate}
                                placeholder={'Supplier Balance ID'}
                                style={{ height: '49px', width: '100%' }}
                                value={supplierBalanceId}
                                onChange={(e, d) => {
                                  setSupplierBalanceId(d.value)
                                  updateBalaceId(d.value, 'Supplier')
                                }}
                              />
                            </Form.Field>
                          )}
                          <Form.Field
                            style={{
                              paddingLeft: props.supplier ? '7px' : '0px',
                              paddingRight: props.supplier ? '0px' : '7px',
                              width: '50%',
                            }}
                          >
                            <div>
                              <label>
                                {'Buyer Balance ID'}
                                <label
                                  className={
                                    balanceIdUpdated === 'Buyer'
                                      ? 'saved-label saving'
                                      : 'saved-label'
                                  }
                                >
                                  {' '}
                                  Saved!
                                </label>
                              </label>
                            </div>
                            <Input
                              type={'text'}
                              disabled={!canUpdate}
                              placeholder={'Buyer Balance ID'}
                              style={{ height: '49px', width: '100%' }}
                              value={buyerBalanceId ? buyerBalanceId : ''}
                              onChange={(e, d) => {
                                setBuyerBalanceId(d.value)
                                updateBalaceId(d.value, 'Buyer')
                              }}
                            />
                          </Form.Field>
                        </Form.Group>
                      )}
                    </Grid.Column>
                  </Grid>
                </div>
              </>
            )}
            {selectedMenuItem === 'Manage Addresses' && (
              <Grid.Column width={16}>
                <div
                  className={`supplier-step-2 buyer-step-2`}
                  style={{
                    marginTop: `${props.supplier ? '0px' : '0px'}`,
                    padding: '0px 0rem',
                  }}
                >
                  {props.showHeaders && (
                    <PageHeader
                      header={`Manage Addresses`}
                      subheader={`Manage your business addresses here.`}
                      buttons={
                        hasPermission(reduxUser, 'Create Location')
                          ? [
                              {
                                color: 'primary',
                                className: 'supplier-step-2 buyer-step-2',
                                content: 'Add Location',
                                onClick: () => {
                                  setOpenCreateLocationsModal(true)
                                },
                                style: {
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                  '--secondaryButtonColor':
                                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                },
                              },
                            ]
                          : []
                      }
                    />
                  )}
                </div>
                <div style={{ marginTop: '0px' }}>
                  <MickeyTable
                    headerRow={locationsHeaderRow}
                    url={'/main/locations/'}
                    reload={reload}
                    setReload={setReload}
                    emptyIcon={'building'}
                    emptyMessage={'No locations'}
                    basicSegment
                    renderBodyRow={(data, index) => {
                      return (
                        <ManageLocationsRow
                          location={data}
                          index={index}
                          admin={props.admin}
                          supplier={props.supplier}
                          buyer={props.buyer}
                          key={index}
                          setReload={setReload}
                        />
                      )
                    }}
                    urlParams={{
                      tenant_aware: false,
                      supplier_id: supplier?.id,
                      buyer_id: buyer?.id,
                    }}
                  />
                </div>
              </Grid.Column>
            )}
            {selectedMenuItem === 'Sales Logs' && (
              <Grid.Column width={5}>
                <Segment basic>
                  {props.showheaders &&
                    hasPermission(user, 'Create Sales Log') && (
                      <div
                        className={`supplier-step-2 buyer-step-2`}
                        style={{
                          marginTop: `${props.supplier ? '40px' : '0px'}`,
                          padding: '0px 1rem',
                        }}
                      >
                        <PageHeader
                          header={`Notes`}
                          subheader={`Keep track of Notes.`}
                          buttons={[
                            {
                              color: 'primary',
                              className: 'supplier-step-2 buyer-step-2',
                              content: 'Add Sales Log',
                              onClick: () => {
                                setOpenSalesLogModal(true)
                              },
                              style: {
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  <div style={{ marginTop: '0px' }}>
                    <NotesTable
                      businessId={business?.id}
                      business={business}
                      reload={reload}
                      setReload={setReload}
                    />
                  </div>
                </Segment>
              </Grid.Column>
            )}
            {selectedMenuItem === 'Manage Users' && (
              <Grid.Column width={5}>
                <Segment basic>
                  {props.showHeaders && (
                    <div
                      className={`supplier-step-2 buyer-step-2`}
                      style={{
                        marginTop: `${props.supplier ? '40px' : '0px'}`,
                        padding: '0px 1rem',
                      }}
                    >
                      <PageHeader
                        header={`Manage Users`}
                        subheader={`Manage your users here.`}
                        buttons={
                          hasPermission('Create User')
                            ? [
                                {
                                  color: 'primary',
                                  className: 'supplier-step-2 buyer-step-2',
                                  content: 'Add User',
                                  onClick: () => {
                                    setOpenInviteNewUserModal(true)
                                  },
                                  style: {
                                    '--primaryColor':
                                      env.REACT_APP_PRIMARY_COLOR,
                                    '--secondaryButtonColor':
                                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                  },
                                },
                              ]
                            : []
                        }
                      />
                    </div>
                  )}
                  <div style={{ marginTop: '0px' }}>
                    <MickeyTable
                      headerRow={usersHeaderRow}
                      url={'/main/custom-users/'}
                      reload={reload}
                      setReload={setReload}
                      basicSegment
                      emptyIcon={'users'}
                      emptyMessage={'No users added'}
                      renderBodyRow={(data, index) => {
                        return (
                          <ManageUsersRow
                            user={data}
                            index={index}
                            business={business}
                            key={index}
                            setReload={setReload}
                            editUserId={editUserId}
                            setEditUserId={setEditUserId}
                          />
                        )
                      }}
                      urlParams={{
                        tenant_aware: false,
                        business_id: business?.id,
                      }}
                    />
                  </div>
                </Segment>
              </Grid.Column>
            )}
            {selectedMenuItem === 'Exchange' && (
              <Grid.Column width={5}>
                <div style={{ marginTop: '0px' }}>
                  <AdminBusinessExchange
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    is_admin={true}
                    business={business}
                    setBusiness={props.setBusiness}
                    buyer={business?.buyer}
                    supplier={business?.supplier}
                    user={user}
                    setUser={props.setUser}
                    users={props.users}
                    setUsers={props.setUsers}
                    state={props.state}
                    loadData={props.loadData}
                    getUser={props.getUser}
                    currentlySelectedLocation={props.currentlySelectedLocation}
                    setCurrentlySelectedLocation={
                      props.setCurrentlySelectedLocation
                    }
                    currentlySelectedTimeframe={
                      props.currentlySelectedTimeframe
                    }
                    setCurrentlySelectedTimeframe={
                      props.setCurrentlySelectedTimeframe
                    }
                    yourBids={true}
                    reload={props.reloadExchange}
                    setReload={props.setReloadExchange}
                    reAuth={props.reAuth}
                    currentTab={props.currentTab}
                    setCurrentTab={props.setCurrentTab}
                    currentPortal={props.currentPortal}
                    setCurrentPortal={props.setCurrentPortal}
                    currentExchangeTable={props.currentExchangeTable}
                    setCurrentExchangeTable={props.setCurrentExchangeTable}
                    currentExchangeTab={props.currentExchangeTab}
                    setCurrentExchangeTab={props.setCurrentExchangeTab}
                    openExchangeFilter={props.openExchangeFilter}
                    openStartListingModal={props.openStartListingModal}
                    setReloadHeaderStats={props.setReloadHeaderStats}
                    setStartListingModal={props.setStartListingModal}
                    selectedCommodity={props.selectedCommodity}
                    openSetAttributesModal={props.openSetAttributesModal}
                    setOpenAttributesModal={props.setOpenAttributesModal}
                    openPricingModal={props.openPricingModal}
                    setOpenPricingModal={props.setOpenPricingModal}
                    openReviewListingModal={props.openReviewListingModal}
                    setReviewListingModal={props.setReviewListingModal}
                    productVariant={props.productVariant}
                    setProductVariant={props.setProductVariant}
                    inputExpiration={props.inputExpiration}
                    setInputExpiration={props.setInputExpiration}
                    setInputPrice={props.setInputPrice}
                    setInputSecondaryPrice={props.setInputSecondaryPrice}
                    inputSecondaryPrice={props.inputSecondaryPrice}
                    setSelectedCommodity={props.setSelectedCommodity}
                    inputPrice={props.inputPrice}
                    setInputQuantity={props.setInputQuantity}
                    inputQuantity={props.inputQuantity}
                    notifications={props.notifications}
                    setNotifications={props.setNotifications}
                    timeframe={props.timeframe}
                    setTimeframe={props.setTimeframe}
                    equipmentType={props.equipmentType}
                    setEquipmentType={props.setEquipmentType}
                    commodities={props.commodities}
                    setCommodities={props.setCommodities}
                    commodityForms={props.commodityForms}
                    setCommodityForms={props.setCommodityForms}
                    selectedCommodityForm={props.selectedCommodityForm}
                    setSelectedCommodityForm={props.setSelectedCommodityForm}
                    locations={locations}
                    setLocations={setLocations}
                    equipmentTypes={props.equipmentTypes}
                    setEquipmentTypes={props.setEquipmentTypes}
                    adminCurrentlySelectedTimeframe={
                      props.adminCurrentlySelectedTimeframe
                    }
                    setAdminCurrentlySelectedTimeframe={
                      props.setAdminCurrentlySelectedTimeframe
                    }
                    adminCurrentlySelectedTruckingType={
                      props.adminCurrentlySelectedTruckingType
                    }
                    setAdminCurrentlySelectedTruckingType={
                      props.setAdminCurrentlySelectedTruckingType
                    }
                  />
                </div>
              </Grid.Column>
            )}
            {props.admin && selectedMenuItem === 'Deals' && (
              <Grid.Column width={5}>
                <AdminOrders business={business} />
              </Grid.Column>
            )}
            {props.admin && selectedMenuItem === 'Dashboard' && (
              <Grid.Column width={5}>
                <ProfileDashboard business={business} />
                <AdminOrders business={business} />
              </Grid.Column>
            )}
          </div>
          <Modal
            onClose={() => setOpenCreateLocationsModal(false)}
            onOpen={() => setOpenCreateLocationsModal(true)}
            open={openCreateLocationsModal}
          >
            <Modal.Header>Add Location</Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'LocationForm'}
                model={'Location'}
                successMessage={'Added location successfully!'}
                failureMessage={'Error adding location, please try again.'}
                values={{
                  business_id: business?.id,
                }}
                url={'/main/locations/'}
                handleForm={handleAddLocationForm}
              />
            </Modal.Content>
          </Modal>

          <Modal
            onClose={() => setOpenInviteNewUserModal(false)}
            onOpen={() => setOpenInviteNewUserModal(true)}
            open={openInviteNewUserModal}
          >
            <Modal.Header>Invite a New User to {business.name}</Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'InviteNewUserForm'}
                model={'CustomUser'}
                url={'/main/custom-users/'}
                tenantAware={false}
                values={{
                  business_id: business?.id,
                }}
                handleForm={handleInviteNewUser}
                buttonLabel={'Send Invitation'}
              />
            </Modal.Content>
          </Modal>

          <Modal
            onClose={() => setOpenUploadResaleCertificateModal(false)}
            onOpen={() => setOpenUploadResaleCertificateModal(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            open={openUploadResaleCertificateModal}
          >
            <Modal.Header style={{ padding: '1.5rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Header as={'h2'} style={{ margin: '0' }}>
                    Upload Resale Certificate
                  </Header>
                  <Header.Subheader
                    as={'h5'}
                    style={{ color: 'gray', marginTop: '.5rem' }}
                  >
                    Before moving on please add your resale certificate.
                  </Header.Subheader>
                </div>
                <Icon
                  name="x"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenUploadResaleCertificateModal(false)}
                />
              </div>
            </Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'ResaleCertificateForm'}
                successMessage={'Resale certificate uploaded!'}
                failureMessage={'Please add a resale certificate to continue.'}
                url={'/main/upload-resale-certificate/'}
                handleForm={handleUploadResaleCertificate}
                buttonLabel={'Upload Resale Certificate'}
                values={{
                  supplier_id: supplier?.id,
                }}
              />
            </Modal.Content>
          </Modal>
          <Modal
            onClose={() => setOpenFirstLocationModal(false)}
            onOpen={() => setOpenFirstLocationModal(true)}
            closeOnDimmerClick={false}
            closeOnEscape={false}
            open={openFirstLocationModal}
            closeIcon={true}
          >
            <Modal.Header>
              Add Your First Location
              <Header.Subheader as={'h5'} style={{ color: 'gray' }}>
                Before moving on please add your first SHIPPING location
              </Header.Subheader>
            </Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'LocationForm'}
                model={'Location'}
                successMessage={'Added location successfully!'}
                failureMessage={'Error adding location, please try again.'}
                values={{
                  business_id: business?.id,
                }}
                url={'/main/locations/'}
                handleForm={handleFirstLocation}
                disableButton={true}
                setReload={setReload}
              />
            </Modal.Content>
          </Modal>
          <Modal
            id={'sales-log-modal'}
            onClose={() => setOpenSalesLogModal(false)}
            onOpen={() => setOpenSalesLogModal(true)}
            open={openSalesLogModal}
            style={{
              width: '38%',
              padding: '2rem',
              height: '90%',
              borderRadius: '1rem',
            }}
          >
            <X
              size={35}
              style={{
                margin: '0',
                color: 'black',
                float: 'right',
                cursor: 'pointer',
              }}
              onClick={() => setOpenSalesLogModal(false)}
            />
            <Modal.Header
              style={{
                fontSize: '25px',
                textAlign: 'center',
                borderBottom: 'none',
              }}
            >
              Add Sales Log
            </Modal.Header>
            <Modal.Content style={{ height: '90%' }}>
              <MickeyForm
                formName={'SalesLogForm'}
                model={'SalesLog'}
                business={business}
                successMessage={'Added sales log successfully!'}
                failureMessage={'Error adding sales log, please try again.'}
                url={'/main/admin-sales-logs/'}
                values={{
                  business_id: business?.id,
                }}
                setReload={setReload}
                handleForm={() => {
                  setOpenSalesLogModal(false)
                  setReload(true)
                }}
                style={{ height: '100%' }}
                HandleDropdownOption={{
                  communication_type: [
                    {
                      value: 'Add User Phone Number',
                      customOnClick: (value) => {
                        setOpenSalesLogModal(false)
                        setSelectedMenuItem('Manage Users')
                        setEditUserId(value[0])
                      },
                    },
                  ],
                }}
                buttons={[
                  {
                    content: 'Add Sales Log',
                    type: 'submit',
                    disabled: true,
                    style: {
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    },
                    innerStyle: {
                      width: '70%',
                      height: '50px',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      position: 'relative',
                    },
                  },
                ]}
              />
            </Modal.Content>
          </Modal>
          {/* </Grid> */}
        </div>
      </Segment>
    </div>
  )
}

export default Profile
