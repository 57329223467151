import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import env from '../../env'
import { formatTotalPrice } from '../../lib/utils/utils'
import useAxios from '../../lib/auth/useAxios'
import {
  Button,
  Form,
  Grid,
  Input,
  Modal,
  Table,
  Loader,
} from 'semantic-ui-react'
import OrderItemDetailTwo from '../OrderItemDetail/OrderItemDetailTwo'
import MickeyDropdown from '../MickeyDropdown/MickeyDropdown'
import ConfirmationModal from '../ConfirmationModal'
import GetProductVariant from '../ProductVariant.js/GetProductVariant'
import { Plus, TrashSimple, X } from 'phosphor-react'
import MickeyTable from '../MickeyTable/MickeyTable'
import ESignRequestsTable from '../Documents/ESignRequestsTable'

import './PurchaseOrders.css'

function AddPurchaseOrder(props) {
  const axios = useAxios()
  const {
    supplier,
    supplier_id,
    setOuterPurchaseOrder,
    purchaseOrder_,
    purchaseOrderId,
    reload,
    setReload,
    update,
    setUpdate,
    hideBusiness,
    orderItems,
    order,
    openAddPurchaseOrderModal,
    setOpenAddPurchaseOrderModal,
  } = props
  let lineItems_ = []
  if (orderItems) {
    lineItems_ = orderItems?.map((orderItem) => {
      return {
        product_variant: orderItem?.product_variant,
        quantity: orderItem?.quantity,
        uom_id: orderItem?.unit?.id,
        price: orderItem?.price,
      }
    })
  }
  const [supplierId, setSupplierId] = useState(supplier_id)
  const [purchaseOrder, setPurchaseOrder] = useState(purchaseOrder_)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState()
  const [openProductVariantModal, setOpenProductVariantModal] = useState(false)
  const [openArchiveLineItemModal, setOpenArchiveLineItemModal] =
    useState(false)
  const [archiveLineItems, setArchiveLineItems] = useState([])
  const [productVariant, setProductVariant] = useState()
  const [lineItems, setLineItems] = useState(lineItems_)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [dropItemIdx, setDropItemIdx] = useState()
  const [dropItemId, setDropItemId] = useState()

  useEffect(() => {
    const getPurchaseOrder = async () => {
      let res = await axios.get(
        `/main/admin-purchase-order/${purchaseOrderId}/`
      )
      setPurchaseOrder(res.data)
    }
    if (purchaseOrderId) {
      getPurchaseOrder()
    }
  }, [purchaseOrderId])

  useEffect(() => {
    if (productVariant?.id) {
      setLineItems([
        ...lineItems,
        {
          product_variant: productVariant,
          quantity: 0,
          uom_id: null,
          price: null,
        },
      ])
    }
  }, [productVariant])

  useEffect(() => {
    if (supplier) {
      setSupplierId(supplier.id)
    }
  }, [supplier])

  useEffect(() => {
    if (purchaseOrder?.id) {
      setSupplierId(purchaseOrder?.supplier?.id)
      setPurchaseOrderNumber(purchaseOrder?.purchase_order_number)
      let li = purchaseOrder?.purchase_order_items?.map((lineItem) => {
        return {
          id: lineItem?.id,
          product_variant: lineItem?.product_variant,
          quantity: lineItem?.quantity,
          uom_id: lineItem?.uom?.id,
          price: lineItem?.price,
        }
      })
      setLineItems(li)
    }
  }, [purchaseOrder])

  const handleCancel = () => {
    setOpenAddPurchaseOrderModal(false)
    if (setUpdate) {
      setUpdate(false)
    }
    setError({})
    if (setOuterPurchaseOrder) setOuterPurchaseOrder()
    if (!supplier_id) setSupplierId()
    setArchiveLineItems([])
    setLineItems([])
    setLoading(false)
    setProductVariant()
    setPurchaseOrderNumber()
  }

  const ValidateForm = () => {
    setError({})
    let Validate = true
    if (!purchaseOrderNumber || purchaseOrderNumber === '') {
      setError((prev) => {
        return { ...prev, purchase_order_number: 'This field is required' }
      })
      Validate = false
    }
    if (!hideBusiness && !supplierId) {
      setError((prev) => {
        return { ...prev, suplier: 'This field is required' }
      })
      Validate = false
    }
    lineItems?.map((lineItem, index) => {
      if (!lineItem?.price || lineItem?.price <= 0) {
        setError((prev) => {
          return {
            ...prev,
            [index]: { ...prev[index], price: 'This field is required' },
          }
        })
        Validate = false
      }
      if (!lineItem?.uom_id) {
        setError((prev) => {
          return {
            ...prev,
            [index]: { ...prev[index], uom_id: 'This field is required' },
          }
        })
        Validate = false
      }
    })
    if (!lineItems || lineItems?.length === 0) {
      toast.error('Add line Items.')
      Validate = false
    }
    return Validate
  }

  const totalPrice = () => {
    return lineItems
      ?.map((lineItem) => {
        return lineItem?.price
          ? lineItem.price * (lineItem?.quantity ? lineItem.quantity : 0)
          : 0
      })
      .reduce(
        (accumulator, currentValue) =>
          parseFloat(accumulator) + parseFloat(currentValue),
        0
      )
  }

  async function createPurchaseOrder(sendToCustomer) {
    if (!ValidateForm()) {
      return
    }
    setLoading(true)
    let copyLineItems = lineItems?.map((lineItem) => {
      return {
        product_variant_id: lineItem?.product_variant?.id,
        quantity: parseFloat(lineItem?.quantity).toFixed(3),
        uom_id: lineItem?.uom_id,
        price: parseFloat(lineItem?.price).toFixed(3),
      }
    })
    toast.success('Please wait while the document generation is queued')

    let res = await axios.post('/main/admin-purchase-order/', {
      purchase_order: {
        purchase_order_number: purchaseOrderNumber,
        total_amount: totalPrice().toFixed(2),
        supplier_id: supplierId,
      },
      order_item_ids: orderItems && orderItems.map((orderItem) => orderItem.id),
      send_to_customer: sendToCustomer,
      purchase_order_items: copyLineItems,
    })
    if (res?.status < 300 && res?.data) {
      toast.success('Purchase order added!')
      handleCancel()
      setReload(true)
    } else {
      toast.error('Error adding purchase order')
    }
    setLoading(false)
  }

  const getDiffrance = () => {
    let updatedItems = purchaseOrder?.purchase_order_items?.map((lineItem) => {
      let updatedLineItem = lineItems?.find((li) => li.id === lineItem.id)
      if (updatedLineItem) {
        let updateli = {}
        if (lineItem?.quantity !== updatedLineItem?.quantity) {
          updateli = {
            ...updateli,
            quantity: parseFloat(updatedLineItem?.quantity).toFixed(3),
          }
        }
        if (lineItem?.uom?.id !== updatedLineItem?.uom_id) {
          updateli = { ...updateli, uom_id: updatedLineItem?.uom_id }
        }
        if (lineItem?.price !== updatedLineItem?.price) {
          updateli = {
            ...updateli,
            price: parseFloat(updatedLineItem?.price).toFixed(3),
          }
        }
        if (Object.keys(updateli).length) {
          return { ...updateli, id: lineItem?.id }
        }
      }
    })
    updatedItems = updatedItems?.filter((ui) => ui !== undefined)
    let newLineItems = []
    lineItems?.map((li) => {
      if (!li?.id) {
        newLineItems = [
          ...newLineItems,
          {
            product_variant_id: li?.product_variant?.id,
            quantity: li?.quantity,
            uom_id: li?.uom_id,
            price: li?.price,
          },
        ]
      }
    })
    let po = {}
    if (purchaseOrder?.purchase_order_number !== purchaseOrderNumber) {
      po = { ...po, purchase_order_number: purchaseOrderNumber }
    }
    if (!hideBusiness && purchaseOrder?.suplier?.id !== supplierId) {
      po = { ...po, supplier_id: supplierId }
    }
    if (purchaseOrder?.total_amount !== totalPrice()) {
      po = { ...po, total_amount: totalPrice().toFixed(2) }
    }
    let updatedData = {
      purchase_order: po,
      delete_items_list: archiveLineItems,
      line_items_update: updatedItems,
      add_line_Items: newLineItems,
    }
    return updatedData
  }

  async function updatePurchaseOrder() {
    if (!ValidateForm()) {
      return
    }
    setLoading(true)
    let differentData = getDiffrance()
    toast.success('Please wait while the document generation is queued')
    let res = await axios.patch(
      `/main/admin-purchase-order/${purchaseOrder?.id}/`,
      {
        ...differentData,
      }
    )
    if (res && res?.status < 300) {
      toast.success('Purchase order updated')
      handleCancel()
      setReload(true)
    } else {
    }
    setLoading(false)
  }

  const handleLineItemChange = (index, field, value) => {
    let lineItemsCopy = [...lineItems]
    lineItemsCopy[index] = {
      ...lineItemsCopy[index],
      [field]: value,
    }
    setLineItems(lineItemsCopy)
  }

  const handleDropItemClick = (idx, id) => {
    setDropItemIdx(idx)
    if (update) setDropItemId(id)
    setOpenArchiveLineItemModal(true)
  }

  return (
    <>
      <Modal
        className="add-purchase-order"
        size={update ? 'fullscreen' : 'large'}
        open={openAddPurchaseOrderModal}
        onOpen={() => setOpenAddPurchaseOrderModal(true)}
        onClose={handleCancel}
      >
        <Modal.Header>
          <div>
            <span>
              {update ? 'Update' : 'Add'}{' '}
              {supplier && <>{supplier.business.name} </>}Purchase Order{' '}
            </span>
            <X size={20} weight="bold" onClick={handleCancel} />
          </div>
        </Modal.Header>
        <Modal.Content>
          {loading && (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 10000,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10000,
                }}
              >
                <Loader active inline="centered" />
                <div
                  style={{
                    marginTop: '10px',
                    color: 'white',
                    fontSize: '16px',
                  }}
                >
                  Queueing Document Generation...
                </div>
              </div>
            </>
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group>
                    <Form.Field>
                      <label>
                        Purchase Order Number{' '}
                        <span style={{ color: 'indianred' }}> *</span>
                      </label>
                      <Form.Input
                        placeholder={'Purchase Order Number'}
                        name={'purchase_order_number'}
                        type={'number'}
                        value={purchaseOrderNumber}
                        onChange={(e, d) => {
                          setPurchaseOrderNumber(d.value)
                        }}
                      />
                      {error && error?.purchase_order_number && (
                        <p style={{ color: 'red' }}>
                          <small>{error?.purchase_order_number}</small>
                        </p>
                      )}
                    </Form.Field>
                    {!hideBusiness && !supplier && (
                      <Form.Field width={4}>
                        <label>
                          Supplier{' '}
                          <span style={{ color: 'indianred' }}> *</span>
                        </label>
                        <MickeyDropdown
                          placeholder={'Supplier'}
                          url={'/main/suppliers/'}
                          name={'supplier'}
                          value={supplierId}
                          defaultValue={purchaseOrder?.supplier?.id}
                          params={{
                            page_size: 25,
                          }}
                          tenantAware={false}
                          titleFields={['business.name']}
                          descriptionFields={[]}
                          onChange={(e, d) => setSupplierId(d.value)}
                        />
                        {error && error?.supplier && (
                          <p style={{ color: 'red' }}>
                            <small>{error?.supplier}</small>
                          </p>
                        )}
                      </Form.Field>
                    )}
                    {!supplier && (
                      <Form.Field>
                        <Button
                          content={'Add Line Item'}
                          color="primary"
                          icon={
                            <Plus
                              size={20}
                              weight="bold"
                              style={{ marginRight: '5px' }}
                            />
                          }
                          style={{
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                          }}
                          onClick={() => setOpenProductVariantModal(true)}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <MickeyTable
            headerRow={
              supplier
                ? [
                    'Product',
                    'Quantity',
                    'Unit of Measure',
                    'Price',
                    'Total Price',
                  ]
                : [
                    'Product',
                    'Quantity',
                    'Unit of Measure',
                    'Price',
                    'Total Price',
                    'Actions',
                  ]
            }
            basicSegment
            showHeaderNoData={true}
            data={lineItems}
            renderBodyRow={(lineItem, index) => {
              return (
                <Table.Row>
                  <Table.Cell width={5}>
                    <OrderItemDetailTwo orderItem={lineItem} />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      placeholder={'Quantity'}
                      type={'number'}
                      step={0.01}
                      min={0}
                      disabled={supplier}
                      value={lineItem?.quantity}
                      defaultValue={lineItem?.quantity}
                      style={{ maxWidth: '150px' }}
                      onChange={(e, d) => {
                        if (d.value >= 0) {
                          handleLineItemChange(index, 'quantity', d.value)
                        }
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <MickeyDropdown
                      url={'/main/unit-of-measures/'}
                      name={'unit_of_measure'}
                      value={lineItem?.uom_id}
                      discludeValueUrl
                      disabled={supplier}
                      defaultValue={lineItem?.uom_id}
                      params={{
                        page_size: 'infinite',
                        product_id: lineItem?.product_variant?.product_id,
                      }}
                      autoComplete="new-password"
                      placeholder={'Select UOM'}
                      titleFields={['plural_name']}
                      descriptionFields={[]}
                      onChange={(e, d) => {
                        handleLineItemChange(index, 'uom_id', d.value)
                      }}
                    />
                    {error && error[index]?.uom_id && (
                      <p style={{ color: 'red' }}>
                        <small>{error[index]?.uom_id}</small>
                      </p>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      placeholder={'Price'}
                      type={'number'}
                      step={0.01}
                      min={0}
                      disabled={supplier}
                      value={lineItem?.price}
                      defaultValue={lineItem?.price}
                      style={{ maxWidth: '150px' }}
                      onChange={(e, d) => {
                        if (d.value >= 0) {
                          handleLineItemChange(index, 'price', d.value)
                        }
                      }}
                    />
                    {error && error[index]?.price && (
                      <p style={{ color: 'red' }}>
                        <small>{error[index]?.price}</small>
                      </p>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatTotalPrice(
                      lineItem?.price
                        ? lineItem.price *
                            (lineItem?.quantity ? lineItem?.quantity : 0)
                        : 0
                    )}
                  </Table.Cell>
                  {!supplier && (
                    <Table.Cell>
                      <Button
                        color="red"
                        icon={
                          <TrashSimple
                            size={20}
                            weight="bold"
                            style={{ marginRight: '5px' }}
                          />
                        }
                        content={'Delete'}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => handleDropItemClick(index, lineItem?.id)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            }}
            footerRow={
              <Table.Row>
                <Table.HeaderCell colSpan="4" />
                <Table.HeaderCell colSpan="2">
                  <b>{formatTotalPrice(totalPrice())}</b>
                </Table.HeaderCell>
              </Table.Row>
            }
            emptyIcon={'file'}
            emptyMessage={'Line items will populate here'}
          />
          {update && purchaseOrder && (
            <Grid>
              <ESignRequestsTable
                purchaseOrderId={purchaseOrder.id}
                documentName="Price Confirmation"
                setReload={setReload}
                reload={reload}
                actions
                discludeOrder
                generationDescription
                hideHeader
                regenerateArgs={{
                  file_type: 'price_confirmation',
                  purchase_order_item_ids:
                    purchaseOrder.purchase_order_items.map((poi) => poi.id),
                  delay: false,
                }}
              />
            </Grid>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={handleCancel}
            color={'gray'}
            style={{
              width: '8.5em',
              marginRight: '2em',
            }}
            content={'Cancel'}
          />
          <Button
            loading={loading}
            disabled={!supplierId || !purchaseOrderNumber || !lineItems?.length}
            onClick={() => {
              if (update) {
                updatePurchaseOrder(false)
              } else {
                createPurchaseOrder(false)
              }
            }}
            color="primary"
            style={{
              width: '16.5em',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
            }}
            content={update ? 'Update' : 'Create'}
          />
          <Button
            loading={loading}
            disabled={!supplierId || !purchaseOrderNumber || !lineItems?.length}
            onClick={() => {
              if (update) {
                updatePurchaseOrder(true)
              } else {
                createPurchaseOrder(true)
              }
            }}
            color="primary"
            style={{
              width: '16.5em',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
            }}
            content={
              update
                ? 'Update and Send to Customer'
                : 'Create and Send to Customer'
            }
          />
        </Modal.Actions>
      </Modal>
      <GetProductVariant
        productVariant={productVariant}
        setProductVariant={setProductVariant}
        openProductVariantModal={openProductVariantModal}
        setOpenProductVariantModal={setOpenProductVariantModal}
      />
      <ConfirmationModal
        message={'Are you sure you want to archive this line item?'}
        confirmationModal={openArchiveLineItemModal}
        setConfirmationModal={setOpenArchiveLineItemModal}
        yesFunction={() => {
          if (update) {
            setArchiveLineItems([...archiveLineItems, dropItemId])
          }
          let li = [...lineItems]
          li.splice(dropItemIdx, 1)
          setLineItems(li)
          setOpenArchiveLineItemModal(false)
          setDropItemId()
          setDropItemIdx()
        }}
        noFunction={() => {
          setDropItemId()
          setDropItemIdx()
          setOpenArchiveLineItemModal(false)
        }}
      />
    </>
  )
}

export default AddPurchaseOrder
