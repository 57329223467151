import {
  Header,
  Segment,
  Button,
  Divider,
  Popup,
  Label,
  Image,
} from 'semantic-ui-react'
import './PageHeader.css'
import { X } from 'phosphor-react'
import MickeyButton from '../MickeyButton/MickeyButton'
import { useNavigate } from 'react-router-dom'
import BreadCrumb from './BreadCrumb/BreadCrumb'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import bluecheck from '../../img/verticals/BLUECHECK.png'

function PageHeader(props) {
  const navigate = useNavigate()
  const wd = useWindowDimensions()

  let buttonList = props.buttons
    ? props.buttons.map((item, index) => {
        if (item.popup) {
          return (
            <Popup
              content={item.popupContent}
              on="click"
              pinned
              offset={item.popupOffset}
              className={item.popupClass}
              position={item.popupPosition}
              popperDependencies={[!!props.reloadPopup]}
              onOpen={item?.popupOnOpen}
              onClose={item?.popupOnClose}
              open={item?.popupOpen}
              key={index}
              trigger={
                <MickeyButton
                  id={item?.id}
                  className={item.buttonClass}
                  color={item.color === 'primary' ? 'primary' : item.color}
                  disabled={item.disabled}
                  size={'mini'}
                  icon={item.icon}
                  circular={item.circular}
                  labelPosition={
                    item.labelPosition
                      ? item.labelPosition
                      : item.icon && !item.hideContent
                      ? 'left'
                      : null
                  }
                  primary={item.color === 'primary'}
                  secondary={item.color === 'secondary'}
                  loading={item.loading}
                  basic={item.basic}
                  content={item.content}
                  onClick={item.onClick}
                  style={{
                    ...item.style,
                    display: item.hidden ? 'none' : 'block',
                  }}
                />
              }
            />
          )
        } else if (item) {
          return item.hidden ? (
            ''
          ) : (
            <MickeyButton
              className={item.buttonClass}
              id={item?.id}
              color={item.color === 'primary' ? 'primary' : item.color}
              primary={item.color === 'primary'}
              secondary={item.color === 'secondary'}
              labelPosition={
                item.labelPosition
                  ? item.labelPosition
                  : item.icon
                  ? 'left'
                  : null
              }
              disabled={item.disabled}
              innerStyle={item.innerStyle}
              size={'mini'}
              circular={item.circular}
              loading={item.loading}
              icon={item.icon}
              basic={item.basic}
              content={item.content}
              onClick={item.onClick}
              key={index}
              style={{
                ...item.style,
                display: item.hidden ? 'none' : 'block',
              }}
            />
          )
        }
        return null
      })
    : ''
  return (
    <div
      id={'page-header'}
      className={`page-header-wrapper ${
        props.className ? props.className : ''
      }`}
    >
      <Segment
        className="page-header-inner-wrapper"
        basic
        clearing
        style={{
          display: 'flex',
          margin: '0',
          padding: '0',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            // display: "flex",
            alignItems: 'start',
            flex: '0 1 50%',
            justifyContent: 'flex-start',
          }}
        >
          {props.breadcrumb?.length > 0 && (
            <BreadCrumb
              header={
                props.breadcrumbHeader ? props.breadcrumbHeader : props.header
              }
              breadcrumb={props.breadcrumb}
              style={{ 'padding-bottom': '10px' }}
            />
          )}
          {props.breadcrumb?.length > 0 && <div style={{ height: '10px' }} />}
          <Header as="h2">
            {props.backButton && (
              <X
                size={wd.width < 768 ? 20 : 28}
                weight="bold"
                style={{
                  margin: wd.width < 768 ? '3.8px 0 0 0' : 'auto',
                  cursor: 'pointer',
                }}
                className={'page-header-back'}
                onClick={() => navigate(-1)}
              />
            )}
            {props.blueCheck && (
              <Image
                src={bluecheck}
                alt="bluecheck"
                style={{ margin: '0 0 15px 0 ', width: '25px' }}
              />
            )}
            <Header.Content
              style={{
                marginBottom: '.75rem',
                fontSize: wd.width < 768 && '18px',
              }}
            >
              {props.header}
              {props.label && (
                <Label color={props.labelColor} content={props.label} />
              )}
            </Header.Content>
            {props.subheader && (
              <Header.Subheader style={{ fontSize: '0.55em' }}>
                {props.subheader}
              </Header.Subheader>
            )}
          </Header>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            flex: '0 1 50%',
            justifyContent: 'flex-end',
          }}
        >
          {props.otherComponent}
          <Header className="header-button-wrapper">
            <Button.Group
              className="header-button-group"
              size={'mini'}
              style={{ marginBottom: '10px' }}
            >
              {buttonList}
            </Button.Group>
          </Header>
        </div>
      </Segment>
      {props.showDivider && <Divider className={props.dividerClassName} />}
    </div>
  )
}

export default PageHeader
