import React, { useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import ContactsRow from './ContactsRow'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { Modal, Segment, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import Profile from '../../shared/Profile/Profile'
import './Contacts.css'
import MickeySearch from '../../../components/Search/MickeySearch'
import env from '../../../env'

function Contacts(props) {
  // Hooks
  const axios = useAxios()
  // State
  const [reload, setReload] = useState(false)
  const [selectedBusiness, setSelectedBusiness] = useState()
  const [activeBusiness, setActiveBusiness] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openContactModal, setOpenContactModal] = useState(false)
  const [openNewContactModal, setOpenNewContactModal] = useState(false)
  const [openEditContactModal, setOpenEditContactModal] = useState(false)
  // Vars
  const loading = false
  let headerRow = [
    'Company',
    'First Name',
    'Last Name',
    'Email',
    'Phone',
    'Archive',
  ]
  function handleContactForm(status) {
    if (status === 'success') {
      setOpenContactModal(false)
      setReload(true)
    } else if (status === 'failure') {
    } else if (status === 'unvalidated') {
    }
  }

  function editBusiness(business) {
    setOpenEditContactModal(true)
    setActiveBusiness(business)
  }

  const archiveContactInstance = async (id) => {
    await axios.delete(`/main/businesses/${id}/`, {
      params: {
        business_id: id,
        supplier_id: props?.supplier?.id,
      },
    })
    setReload(true)
    toast.success('Contact Archived', {
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  const supplierAddExistingContact = async (business) => {
    await axios.patch(`/main/businesses/${business.id}/`, {
      business_id: business?.id,
      supplier_id: props?.supplier?.id,
    })
    await setOpenConfirmModal(false)
    await setOpenContactModal(false)
    setReload(true)
  }

  return (
    <div style={{ padding: '20px' }}>
      <Segment className={'main-segment-container'}>
        <PageHeader
          icon={'address book'}
          header={'Contacts'}
          subheader={'View and add contacts.'}
          buttons={[
            {
              color: 'primary',
              icon: 'plus',
              content: 'Contact',
              onClick: () => {
                setOpenContactModal(true)
              },
              style: {
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              },
            },
          ]}
        />
        <MickeyTable
          className="contacts-table"
          headerRow={headerRow}
          url={'/main/businesses/'}
          reload={reload}
          basicSegment
          style={{ margin: '20px 5px 10px 5px' }}
          setReload={setReload}
          renderBodyRow={(data, index) => {
            return (
              <ContactsRow
                archiveContactInstance={archiveContactInstance}
                contact={data}
                index={index}
                onClick={() => {
                  editBusiness(data)
                }}
                key={data ? data.id : null}
              />
            )
          }}
          urlParams={{
            supplier_id: props.supplier?.id,
            only_show_supplier_businesses: true,
            tenant_aware: false,
          }}
          emptyIcon={'address book'}
          emptyMessage={'No contacts yet, add a new one!'}
        />
        <Modal
          onClose={() => setOpenNewContactModal(false)}
          onOpen={() => setOpenNewContactModal(true)}
          open={openNewContactModal}
        >
          <Modal.Header>Add Business</Modal.Header>
          <Modal.Content>
            <MickeyForm
              formName={'SupplierContactForm'}
              values={{
                supplier_id: props.supplier?.id,
                only_show_supplier_businesses: true,
              }}
              model={'Business'}
              successMessage={'Added contact successfully!'}
              failureMessage={'Error adding buyer, please try again.'}
              url={'/main/businesses/'}
              handleForm={handleContactForm}
            />
          </Modal.Content>
        </Modal>

        <Modal
          onClose={() => setOpenConfirmModal(false)}
          onOpen={() => setOpenConfirmModal(true)}
          open={openConfirmModal}
        >
          <Modal.Header>
            Are you sure you'd like to add {selectedBusiness?.title} as a
            contact?
          </Modal.Header>
          <Modal.Actions>
            <Button color="red" onClick={() => setOpenConfirmModal(false)}>
              No
            </Button>
            <Button
              color="green"
              onClick={() => {
                setOpenConfirmModal(false)
                supplierAddExistingContact(selectedBusiness)
              }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => setOpenContactModal(false)}
          onOpen={() => setOpenContactModal(true)}
          open={openContactModal}
        >
          <Modal.Header>Add Business</Modal.Header>
          <Modal.Content>
            <MickeySearch
              placeholder={'Search Businesses'}
              url="/main/businesses/"
              name={'global-search'}
              titleFields={['name']}
              descriptionFields={['contact_first_name', 'contact_last_name']}
              onChange={(e, d) => {
                setSelectedBusiness(d?.result)
                setOpenConfirmModal(true)
              }}
              urlParams={{
                tenant_aware: false,
                all_businesses: true,
              }}
              loading={loading}
            />
            Don't see who you're looking for?
            <button
              style={{
                background: 'none',
                color: 'blue',
                border: '0',
                padding: '0',
                font: 'inherit',
                cursor: 'pointer',
                outline: 'inherit',
              }}
              onClick={() => {
                setOpenContactModal(false)
                setOpenNewContactModal(true)
              }}
            >
              Create a new contact here.
            </button>
          </Modal.Content>
        </Modal>
        <Modal
          onClose={() => setOpenEditContactModal(false)}
          onOpen={() => setOpenEditContactModal(true)}
          open={openEditContactModal}
          size={'fullscreen'}
          closeIcon
          style={{ borderRadius: '40px' }}
        >
          {/* <Modal.Header>Edit Business</Modal.Header> */}
          <Modal.Content style={{ borderRadius: '40px' }}>
            {activeBusiness && (
              <Profile business={activeBusiness} admin hideToggle={true} />
            )}
          </Modal.Content>
        </Modal>
      </Segment>
    </div>
  )
}

export default Contacts
