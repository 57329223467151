import AdminBusinesses from '../../AdminBusinesses/AdminBusinesses'
import PageHeader from '../../../../components/PageHeader/PageHeader'

const AdminSalesMyBusinesses = (props) => {
  const { salespersonId } = props
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/orders' },
    { name: 'Sales', href: '/portal/admin/sales' },
  ]
  return (
    <>
      <PageHeader
        header="My Businesses"
        breadcrumbHeader="My Businesses"
        breadcrumb={breadcrumbData}
      />
      <AdminBusinesses
        salespersonId={salespersonId}
        hideItems
        hideHeader={true}
      />
    </>
  )
}

export default AdminSalesMyBusinesses
