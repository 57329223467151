import React, { useRef, useState } from 'react'
import { Table, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import EditESignTemplateModal from './EditESignTemplateModal'

const ESignTemplateRow = (props) => {
  const { boxItem } = props
  const linkRef = useRef(null)
  const [showEditModal, setShowEditModal] = useState(false)
  let displayName = boxItem.display_name
  if (displayName.includes('One Page Template')) {
    displayName = displayName.replace('One Page Template', '').trim()
  } else {
    displayName = displayName.replace('Template', '').trim()
  }
  return (
    <>
      <Table.Row selectable="true">
        <Table.Cell width={1} onClick={() => setShowEditModal(true)}>
          <p>{displayName}</p>
        </Table.Cell>
        <Table.Cell width={1} onClick={() => setShowEditModal(true)}>
          <p>{boxItem.e_sign_email_subject}</p>
        </Table.Cell>
        <Table.Cell width={1} onClick={() => setShowEditModal(true)}>
          <p>{boxItem.e_sign_document_visibility}</p>
        </Table.Cell>
        <Table.Cell width={1}>
          <Button
            onClick={() => {
              fetch(boxItem.document.file)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(blob)
                  const link = linkRef.current
                  link.href = url
                  link.download = boxItem.display_name
                  link.click()
                  window.URL.revokeObjectURL(url)
                  toast.success('Document Downloaded')
                })
                .catch((e) => console.error(e))
            }}
          >
            Download
          </Button>
        </Table.Cell>
      </Table.Row>
      <EditESignTemplateModal
        boxItem={boxItem}
        showModal={showEditModal}
        toggleModal={setShowEditModal}
      />
      <a style={{ display: 'none' }} ref={linkRef} />
    </>
  )
}

export default ESignTemplateRow
