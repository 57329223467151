export const AdminBusinessDocumentUploadForm = {
  model: 'Document',
  format: [
    {
      widths: 'equal',
      fields: {
        file: {
          required: true,
          type: 'file',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        order: {
          required: false,
          fetch: async (axios, businessId) => {
            const res = await await axios.get(
              `/main/orders/?business_id=${businessId}&tenant_aware=false`
            )
            const choices = []
            res?.data?.results?.forEach((order) => {
              const description = `Order ${order.id}`
              choices.push({
                text: description,
                value: {
                  order_id: order.id,
                  supplier_id: order.supplier?.id,
                  buyer_id: order.buyer?.id,
                },
                key: description,
              })
            })
            return choices
          },
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        document_type: {
          required: true,
          type: 'dropdown',
        },
        visiblity: {
          type: 'dropdown',
          visibleto: ['admin'],
        },
      },
    },
  ],
}
