import React from 'react'
import { Table } from 'semantic-ui-react'

const BoxDocumentTagRow = (props) => {
  const { documentTag, hideDataType } = props

  return (
    <>
      <Table.Row>
        <Table.Cell width={1}>
          <p>{documentTag.document_tag_id}</p>
        </Table.Cell>
        <Table.Cell width={1}>
          <p>{documentTag.description}</p>
        </Table.Cell>
        {!hideDataType && (
          <Table.Cell width={1}>
            <p>
              {documentTag.data_type.charAt(0).toUpperCase() +
                documentTag.data_type.slice(1)}
            </p>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  )
}

export default BoxDocumentTagRow
