import React from 'react'

const ContractorDirectTAC = () => {
  return (
    <div
      style={{
        padding: '6rem 10% 6rem 10%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <object
        data="/mickey-contractor-direct-terms-and-conditions.pdf"
        type="application/pdf"
        width="100%"
        height="1000"
      ></object>
    </div>
  )
}

export default ContractorDirectTAC
