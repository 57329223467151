import SettlementStatementFormCuiTable from '../components/SettlementStatement/SettlementStatementFormCuiTable'

export const SettlementStatementForm = {
  model: 'SettlementStatement',
  format: [
    {
      widths: 'equal',
      fields: {
        start_date: {
          required: true,
          type: 'date',
          label: 'Start Date',
        },
        end_date: {
          required: true,
          type: 'date',
          label: 'End Date',
        },
        supplier_id: {
          label: 'Supplier',
          type: 'dropdown search',
          url: '/main/suppliers/',
          required: true,
          title_fields: ['business.name'],
          params: {
            tenant_aware: false,
          },
          filterField: 'q',
          description_fields: [],
        },
        send_email: {
          type: 'checkbox',
          label: 'Send Email',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        cui_ids: {
          type: 'custom field',
          required: true,
          hide_label: true,
          hide_error: true,
          custom_field_components: (
            key,
            value,
            handleInputChange,
            labelSuccessField,
            instanceLabel,
            values,
            error
          ) => {
            return (
              <SettlementStatementFormCuiTable
                fieldName={key}
                value={value}
                handleInputChange={handleInputChange}
                labelSuccessField={labelSuccessField}
                instanceLabel={instanceLabel}
                values={values}
                error={error}
              />
            )
          },
          handleFormDataAppend: (key, value, formData) => {
            try {
              value?.forEach((item) => {
                formData.append(key + '[]', item)
              })
              return formData
            } catch {
              return formData
            }
          },
          validator: (input) => {
            if (!input?.length) {
              return 'Please select items.'
            } else {
              return false
            }
          },
        },
      },
    },
  ],
}
