import { useEffect, useState } from 'react'
import useAxios from '../../lib/auth/useAxios'

const MickeyInfiniteScroll = (props) => {
  const axios = useAxios()
  const {
    next,
    setNext,
    loading,
    setLoading,
    results,
    setResults,
    getResults,
  } = props
  const [lastItemVisible, setLastItemVisible] = useState(false)

  const divElement = document.querySelector('#last-element')
  if (divElement) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && next && !loading && !lastItemVisible)
            setLastItemVisible(true)
        })
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }
    )
    observer.observe(divElement)
  }

  async function getPages() {
    if (!next) return
    setLoading(true)
    let response = await axios.get(next)
    let res_data =
      response?.data &&
      typeof response?.data === 'object' &&
      'results' in response?.data
        ? response?.data?.results
        : response?.data
    if (res_data) {
      setNext(response?.data?.links?.next)
      if (getResults) {
        let result = await getResults(res_data)
        let existing_results = [...results]
        if (existing_results?.length) {
          let exist_index = results.findIndex(
            (obj) => obj?.content?.props?.id === 'last-element'
          )
          if (exist_index >= 0) {
            let existing_result = existing_results[exist_index]
            let content = { ...existing_result?.content }
            content.props = { children: content?.props?.children }
            existing_results[exist_index] = {
              text: existing_result.text,
              value: existing_result.key,
              key: existing_result.key,
              content: content,
            }
          }
          let existing_results_keys = existing_results.map(
            (ex_obj) => ex_obj.key
          )
          let filtered_results = result.filter(
            (obj) => !existing_results_keys.includes(obj.key)
          )
          setResults([...existing_results, ...filtered_results])
        }
      } else {
        setResults((prevState) => [prevState, ...res_data])
      }
    }
    setLastItemVisible(false)
    setLoading(false)
  }

  useEffect(() => {
    if (lastItemVisible) getPages()
  }, [lastItemVisible])

  return <></>
}

export default MickeyInfiniteScroll
