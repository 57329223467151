import { Grid, Modal } from 'semantic-ui-react'
import env from '../../env'
import PageHeader from '../PageHeader/PageHeader'
import { useState } from 'react'
import PurchaseOrdersRow from './PurchaseOrdersRow'
import MickeyTable from '../MickeyTable/MickeyTable'
import AddPurchaseOrder from './AddPurchaseOrder'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { Plus } from 'phosphor-react'

function PurchaseOrders(props) {
  const {
    hideHeader,
    hideBusiness,
    business,
    openAddPurchaseOrderModal,
    setOpenAddPurchaseOrderModal,
  } = props
  const [reload, setReload] = useState(false)
  const [purchaseOrder, setPurchaseOrder] = useState()
  const [update, setUpdate] = useState(false)
  const user = useSelector((state) => state.user.user)

  const headerRow = [
    'Purchase Order Number',
    'Products',
    'Quantity',
    'UOM',
    'Price',
    'Total Amount',
  ]
  const urlParams = business ? { business_id: business?.id } : {}
  const headerSearch = [
    {
      header: 'Purchase Order Number',
      type: 'search',
      input_type: 'number',
      search_param: 'purchase_order_number',
    },
    {
      header: 'Total Amount',
      type: 'number_range',
      search_param_start: 'total_amount_start',
      search_param_end: 'total_amount_end',
    },
  ]
  const headerSort = [
    {
      header: 'Purchase Order Number',
      sort_by: 'purchase_order_number',
    },
    {
      header: 'Purchase Order Items',
      sort_by: '',
    },
    {
      header: 'Total Amount',
      sort_by: 'total_amount',
    },
  ]

  if (!hideBusiness) {
    headerRow.splice(5, 0, 'Supplier')
    headerSort.splice(5, 0, {
      header: 'Supplier',
      sort_by: 'supplier__business__name',
    })
    headerSearch.splice(5, 0, {
      header: 'Supplier',
      type: 'dropdown',
      options_url: 'businesses/',
      options_params: {
        tenant_aware: false,
        extra_light_serializer: 'true',
        all_supplier_businesses: 'true',
        all_businesses: 'true',
      },
      options_result: 'name',
      search_param: 'business_id',
    })
  }

  return (
    <div className={'main-container'}>
      <Grid stackable>
        {!hideHeader && (
          <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
            <Grid.Column
              width={16}
              className={'no-bottom-margin no-bottom-padding'}
            >
              <PageHeader
                header={'Purchase Orders'}
                subheader={
                  'Add, archive, or edit purchase orders at your company.'
                }
                className={
                  'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
                }
                dividerClassName={'no-bottom-margin no-bottom-padding'}
                buttons={[
                  {
                    color: 'primary',
                    content: 'Add Purchase Order',
                    icon: <Plus weight="bold" />,
                    hidden: !hasPermission(user, 'Create Purchase Order'),
                    onClick: () => {
                      setOpenAddPurchaseOrderModal(true)
                    },
                    style: {
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    },
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={headerRow}
              headerSearch={headerSearch}
              headerSort={headerSort}
              urlParams={urlParams}
              basicSegment
              showHeaderNoData={true}
              url={'/main/admin-purchase-order/'}
              hideFooter={false}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <PurchaseOrdersRow
                    index={index}
                    purchaseOrder={data}
                    setPurchaseOrder={setPurchaseOrder}
                    setUpdate={setUpdate}
                    hideBusiness={hideBusiness}
                    setOpenAddPurchaseOrderModal={setOpenAddPurchaseOrderModal}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Purchase Orders will populate here'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AddPurchaseOrder
        supplier_id={business?.supplier?.id}
        purchaseOrder={purchaseOrder}
        setPurchaseOrder={setPurchaseOrder}
        reload={reload}
        setReload={setReload}
        update={update}
        setUpdate={setUpdate}
        hideBusiness={hideBusiness}
        openAddPurchaseOrderModal={openAddPurchaseOrderModal}
        setOpenAddPurchaseOrderModal={setOpenAddPurchaseOrderModal}
      />
    </div>
  )
}

export default PurchaseOrders
