import React, { useState, useEffect } from 'react'
import { Star } from 'phosphor-react'
import { Card, Header, Table, Popup, Button } from 'semantic-ui-react'
import env from '../../../../../env'
import ProductIcon from '../../../../../components/ProductIcon/ProductIcon'
import { formatTotalPrice } from '../../../../../lib/utils/utils'
import { reduce } from 'underscore'
import './ExchangeTableCard.css'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'

const ExchangeTableCard = (props) => {
  const navigate = useNavigate()
  const windowDimensions = useWindowDimensions()
  const [mountNode, setMountNode] = useState()
  const {
    product,
    attributeSelections,
    exchangeOrderBook,
    user,
    setSelectedOrder,
    currentPortal,
    cancelBid,
    productVariant,
    highestBidOrder,
    lowestAskOrder,
    hasActiveOrder,
    hasCurrentSideOrder,
    selectedOrder,
    updateWatchlist,
    openFilter,
    hasOpposingSideOrder,
    userAsk,
    userBid,
    publicExchange,
    setCurrentlySelectedTimeframe,
    setCurrentlySelectedTruckingType,
  } = props

  useEffect(() => {
    setMountNode(document.getElementById(`price-${props.index}`))
  }, [])

  if (!exchangeOrderBook?.product_variant) {
    return
  }

  const handleCancelOrderClick = () => {
    setSelectedOrder(exchangeOrderBook)
    let orderId = currentPortal === 'supplier' ? userAsk?.id : userBid?.id
    let locationId =
      currentPortal === 'supplier'
        ? userBid?.location_id
        : highestBidOrder?.location_id

    cancelBid(props.exchangeOrderBook?.product_variant?.id, orderId, locationId)
  }

  const handleNavigateToProductPages = (to) => {
    let navigation_url = `${
      (publicExchange && '/exchange/') ||
      (!publicExchange && '/portal/' + props?.currentPortal + '/')
    }${product?.commodity?.slug}/${product?.commodity_form?.slug}/${
      product?.slug
    }`
    if (to !== 'Product') {
      navigation_url = navigation_url + `/${productVariant?.slug}/`
      if (!publicExchange) {
        navigation_url = navigation_url + `${props.yourBids ? true : false}/`
      }
    }
    return navigate(navigation_url)
  }

  const buyTimeframe =
    props.currentPortal === 'buyer'
      ? hasActiveOrder &&
        hasCurrentSideOrder &&
        (props.currentPortal === 'supplier'
          ? userAsk
            ? userAsk?.timeframe
            : ''
          : userBid
          ? userBid?.timeframe
          : '')
      : (props.currentPortal === 'supplier' &&
          props.exchangeOrderBook?.bid_orders?.length > 0) ||
        (props.currentPortal === 'buyer' &&
          props.exchangeOrderBook?.ask_orders?.length > 0)
      ? props.currentPortal === 'supplier'
        ? highestBidOrder?.timeframe
        : lowestAskOrder?.timeframe
      : ''

  const sellTimeframe =
    props.currentPortal === 'supplier' && userAsk
      ? userAsk.timeframe
      : props.currentPortal === 'buyer' && lowestAskOrder
      ? lowestAskOrder.timeframe
      : ''

  return (
    <>
      <Card id="listing-card" data-status={openFilter}>
        <Star
          style={{ cursor: 'pointer' }}
          className={'start-watchlist'}
          size={25}
          color={
            user?.product_variant_watchlist &&
            user?.product_variant_watchlist.find(
              (product_variant) =>
                product_variant['pv_id'] ===
                  props.exchangeOrderBook?.product_variant?.id &&
                product_variant['location_id'] ===
                  props.user?.current_location?.id
            )
              ? 'orange'
              : '#4f555b'
          }
          weight={
            user?.product_variant_watchlist &&
            user?.product_variant_watchlist.find(
              (product_variant) =>
                product_variant['pv_id'] ===
                  props.exchangeOrderBook?.product_variant?.id &&
                product_variant['location_id'] ===
                  props.user?.current_location?.id
            )
              ? 'duotone'
              : 'regular'
          }
          onClick={() => {
            if (props.publicExchange) {
              toast.error('Login to access Watchlist')
            } else {
              updateWatchlist(exchangeOrderBook?.product_variant?.id, true)
            }
          }}
        />
        <div className="listing-product-photo">
          <img
            className="card-image"
            src={
              env.REACT_APP_ENVIRONMENT === 'local'
                ? `http://marketplace.localhost:8002/media/${product?.material?.material_display_image}`
                : `${env.REACT_APP_STATIC_BASE_URL}${product?.material?.material_display_image}`
            }
            alt=""
          />
        </div>
        <div className="listing-info">
          <div className="listing-info-inner-wrapper">
            <div className="product-info-top">
              <div className="icon-product-name">
                <ProductIcon
                  productName={product?.material?.name}
                  width={'25px'}
                  height={'25px'}
                  margin={'0 .5rem 0 0'}
                />

                <span onClick={() => handleNavigateToProductPages('Product')}>
                  <Header className="material-name" as={'h3'}>
                    {product?.material?.name}
                  </Header>
                </span>
              </div>
              <div className="product-attributes">
                <p
                  className="attributes"
                  onClick={() => handleNavigateToProductPages('ProductVariant')}
                >
                  {attributeSelections &&
                    attributeSelections?.find(
                      (attribute) => attribute?.attribute_name === 'Size'
                    )?.attribute_selection_value}
                </p>
                <p
                  className="attributes"
                  onClick={() => handleNavigateToProductPages('ProductVariant')}
                >
                  {attributeSelections &&
                    attributeSelections
                      .filter(
                        (attribute) => attribute?.attribute_name !== 'Size'
                      )
                      .map((attr, index) => {
                        if (index !== attributeSelections.length - 2) {
                          return attr.attribute_selection_value + ', '
                        } else {
                          return attr.attribute_selection_value
                        }
                      })}
                </p>

                <div className="product-actions-mobile">
                  {((currentPortal === 'supplier' && userAsk) ||
                    (currentPortal === 'buyer' && userBid)) && (
                    <>
                      <Button
                        style={{
                          borderRadius: '3px',
                          padding: '10px',
                          margin: '0 auto',
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          marginRight: '.5rem',
                          height: '40px',
                          whiteSpace: 'nowrap',
                        }}
                        color="primary"
                        loading={
                          props.cancelBidLoading &&
                          selectedOrder?.id === props?.exchangeOrderBook?.id
                        }
                        onClick={handleCancelOrderClick}
                      >
                        Cancel{' '}
                        {props.currentPortal === 'supplier' ? 'Offer' : 'Buy'}
                      </Button>
                    </>
                  )}
                  {((props.currentPortal === 'supplier' && !userAsk) ||
                    (props.currentPortal === 'buyer' && !userBid)) && (
                    <Button
                      style={{
                        borderRadius: '3px',
                        marginRight: '.5rem',
                        height: '40px',
                        whiteSpace: 'nowrap',
                      }}
                      color="green"
                      onClick={() => {
                        if (setCurrentlySelectedTimeframe) {
                          setCurrentlySelectedTimeframe(
                            props.currentPortal === 'buyer'
                              ? sellTimeframe
                              : buyTimeframe
                          )
                        }
                        if (setCurrentlySelectedTruckingType) {
                          setCurrentlySelectedTruckingType(
                            props.currentPortal === 'buyer'
                              ? lowestAskOrder?.cargo_unit
                              : highestBidOrder?.cargo_unit
                          )
                        }
                        props.setOpenPricingModal(true)
                        props.setProductVariant(
                          props.exchangeOrderBook?.product_variant
                        )
                        props.setInputQuantity(
                          props.currentPortal === 'supplier'
                            ? highestBidOrder?.quantity
                            : lowestAskOrder?.quantity
                        )
                        props.setInputPrice(
                          props.currentPortal === 'supplier'
                            ? Math.ceil(highestBidOrder?.total_price * 100) /
                                100
                            : Math.ceil(lowestAskOrder?.total_price * 100) / 100
                        )
                      }}
                    >
                      {props.currentPortal === 'supplier'
                        ? 'Sell Now'
                        : 'Buy Now'}
                    </Button>
                  )}

                  {((currentPortal === 'supplier' && userAsk) ||
                    (currentPortal === 'buyer' && userBid)) && (
                    <Button
                      style={{
                        borderRadius: '3px',
                        padding: '10px',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        marginRight: '.5rem',
                        width: '65px',
                        height: '40px',
                        whiteSpace: 'nowrap',
                      }}
                      color="grey"
                      onClick={() => {
                        props.setOpenEditBidAskModal(true)
                        props.setEditingOrder(
                          currentPortal === 'supplier' ? userAsk : userBid
                        )
                        props.setEditingExchangeOrderBook(exchangeOrderBook)
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  {((currentPortal === 'supplier' && !userAsk) ||
                    (currentPortal === 'buyer' && !userBid)) && (
                    <Button
                      basic
                      style={{
                        borderRadius: '3px',
                        marginRight: '.5rem',
                      }}
                      color="green"
                      className={'view-listing-button'}
                      onClick={() => {
                        if (setCurrentlySelectedTimeframe) {
                          setCurrentlySelectedTimeframe(
                            props.currentPortal === 'buyer'
                              ? sellTimeframe
                              : buyTimeframe
                          )
                        }
                        if (setCurrentlySelectedTruckingType) {
                          setCurrentlySelectedTruckingType(
                            props.currentPortal === 'buyer'
                              ? lowestAskOrder?.cargo_unit
                              : highestBidOrder?.cargo_unit
                          )
                        }
                        props.setOpenPricingModal(true)
                        props.setProductVariant(
                          exchangeOrderBook?.product_variant
                        )
                      }}
                    >
                      {currentPortal === 'supplier' ? 'Ask' : 'Bid'}
                    </Button>
                  )}

                  <Star
                    style={{ cursor: 'pointer', marginLeft: '.5rem' }}
                    className={'start-watchlist-mobile'}
                    size={32}
                    color={
                      user?.product_variant_watchlist &&
                      user?.product_variant_watchlist.find(
                        (product_variant) =>
                          product_variant['pv_id'] ===
                            props.exchangeOrderBook?.product_variant?.id &&
                          product_variant['location_id'] ===
                            props.user?.current_location?.id
                      )
                        ? 'orange'
                        : '#4f555b'
                    }
                    weight={
                      user?.product_variant_watchlist &&
                      user?.product_variant_watchlist.find(
                        (product_variant) =>
                          product_variant['pv_id'] ===
                            props.exchangeOrderBook?.product_variant?.id &&
                          product_variant['location_id'] ===
                            props.user?.current_location?.id
                      )
                        ? 'duotone'
                        : 'regular'
                    }
                    onClick={() => {
                      if (props.publicExchange) {
                        toast.error('Login to access Watchlist')
                      } else {
                        updateWatchlist(
                          exchangeOrderBook?.product_variant?.id,
                          true
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="product-info-bottom">
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'self-end',
                }}
              >
                <div>
                  <p
                    className="price-detail"
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  >
                    {currentPortal === 'buyer' ? 'Your Buy' : 'Buy Price'}
                  </p>
                  <Header as={'h3'} style={{ margin: '0' }}>
                    {props.currentPortal === 'supplier' &&
                      exchangeOrderBook?.bid_orders?.length > 0 && (
                        <>
                          <Popup
                            mountNode={mountNode}
                            content={
                              <div
                                style={{
                                  width:
                                    windowDimensions.width > 768 &&
                                    props.is_admin
                                      ? '65em'
                                      : '250px',
                                  overflow: 'scroll',
                                  paddingTop: '10px',
                                }}
                              >
                                <Header as={'h2'} textAlign={'center'}>
                                  {' '}
                                  {props.currentPortal === 'supplier'
                                    ? 'Bids'
                                    : 'Asks'}
                                </Header>
                                <p
                                  style={{
                                    fontSize: '12px',
                                    margin: '0',
                                    textAlign: 'center',
                                  }}
                                >
                                  {props.productVariant &&
                                    props.productVariant.product.material.name}
                                  {', '}
                                  {props.productVariant &&
                                    props.productVariant?.attribute_selections?.map(
                                      (attribute, index) => {
                                        let check =
                                          index !==
                                          props.productVariant
                                            .attribute_selections.length -
                                            1
                                        return attribute.attribute_selection_value +
                                          check
                                          ? ', '
                                          : ''
                                      }
                                    )}
                                </p>
                                <Table basic compact unstackable>
                                  <Table.Header>
                                    <Table.HeaderCell textAlign={'center'}>
                                      $/
                                      {
                                        props.productVariant?.product
                                          ?.default_unit_of_measure?.short_name
                                      }
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Number of Trucks</>
                                    </Table.HeaderCell>
                                    {props?.is_admin && (
                                      <>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>Business</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>Location</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>FOB Price</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>Lowest Freight Price</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>Provider</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>Divisor</>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell textAlign={'center'}>
                                          <>TWD</>
                                        </Table.HeaderCell>
                                      </>
                                    )}
                                  </Table.Header>
                                  <Table.Body>
                                    {props.currentPortal === 'supplier' &&
                                      props.groupedBids
                                        ?.sort((a, b) => {
                                          let aPrice = a.total_price
                                          let bPrice = b.total_price
                                          if (aPrice > bPrice) return -1
                                          if (bPrice <= aPrice) return -1
                                        })
                                        .map((bids) => {
                                          return (
                                            <Table.Row>
                                              <Table.Cell textAlign={'center'}>
                                                {bids[0]?.total_price > 0
                                                  ? formatTotalPrice(
                                                      bids[0]?.total_price
                                                    )
                                                  : '----'}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {reduce(
                                                  bids.map((bid) => {
                                                    return bid.quantity
                                                  }),
                                                  (memo, num) => {
                                                    return memo + num
                                                  },
                                                  0
                                                )}
                                              </Table.Cell>
                                              {props?.is_admin && (
                                                <>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                    width={2}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.business_name
                                                        ? bid?.business_name
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.formatted_address
                                                        ? bid?.formatted_address
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.price
                                                        ? formatTotalPrice(
                                                            bid?.price
                                                          )
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.trucking
                                                        ?.rate > 0
                                                        ? formatTotalPrice(
                                                            bid?.trucking?.rate
                                                          )
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.trucking
                                                        ?.trucking_company
                                                        ? bid?.trucking
                                                            ?.trucking_company
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.trucking
                                                        ?.divisor
                                                        ? bid?.trucking?.divisor
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    textAlign={'center'}
                                                  >
                                                    {bids?.map((bid) => {
                                                      return bid?.trucking
                                                        ?.rate &&
                                                        bid?.trucking
                                                          ?.divisor &&
                                                        bid?.trucking?.rate /
                                                          bid?.trucking
                                                            ?.divisor >
                                                          0
                                                        ? formatTotalPrice(
                                                            bid?.trucking
                                                              ?.rate /
                                                              bid?.trucking
                                                                ?.divisor
                                                          )
                                                        : '----'
                                                    })}
                                                  </Table.Cell>
                                                </>
                                              )}
                                            </Table.Row>
                                          )
                                        })}
                                  </Table.Body>
                                </Table>
                              </div>
                            }
                            on={'click'}
                            offset={props?.is_admin ? [-60, 40] : [-10, 30]}
                            trigger={
                              <b
                                className={'hover-price-underline'}
                                id={`price-${props.index}`}
                              >
                                <span className="price">
                                  {formatTotalPrice(
                                    highestBidOrder.total_price
                                  )}
                                </span>
                                <span className="unit">
                                  /
                                  {
                                    productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                </span>
                              </b>
                            }
                          />
                          <p
                            className="attributes"
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Brown Light',
                              opacity: '.5',
                            }}
                          >
                            {props.currentPortal === 'supplier'
                              ? (props.exchangeOrderBook?.bid_orders?.length > 0
                                  ? highestBidOrder?.quantity
                                  : '') +
                                ' ' +
                                (props.exchangeOrderBook?.bid_orders?.length > 0
                                  ? highestBidOrder?.cargo_unit?.plural_name
                                  : '')
                              : (props.exchangeOrderBook?.ask_orders?.length > 0
                                  ? lowestAskOrder?.quantity
                                  : '') +
                                ' ' +
                                (props.exchangeOrderBook?.ask_orders?.length > 0
                                  ? lowestAskOrder?.cargo_unit?.plural_name
                                  : '')}
                          </p>
                        </>
                      )}
                    {currentPortal === 'supplier' &&
                      !exchangeOrderBook?.bid_orders?.length > 0 && <b>--</b>}
                    {currentPortal === 'buyer' && userBid && (
                      <>
                        <b>
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'buyer' &&
                            userBid?.status === 'open' && (
                              <div
                                className="ring-container"
                                style={{ paddingTop: '.5rem' }}
                              >
                                <div
                                  className="ringring"
                                  style={{ top: 'calc(.5rem - 5px)' }}
                                ></div>
                                <div className="circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'buyer' &&
                            userBid?.status === 'disabled' && (
                              <div className="disabled-ring-container">
                                <div className="disabled-circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'supplier' &&
                            userAsk?.status === 'open' && (
                              <div
                                className="ring-container"
                                style={{ paddingTop: '.5rem' }}
                              >
                                <div
                                  className="ringring"
                                  style={{ top: 'calc(.5rem - 5px)' }}
                                ></div>
                                <div className="circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'supplier' &&
                            userAsk?.status === 'disabled' && (
                              <div className="disabled-ring-container">
                                <div className="disabled-circle"></div>
                              </div>
                            )}
                          <span className="price">
                            {formatTotalPrice(userBid.price)}
                          </span>
                          <span className="unit">
                            /
                            {
                              productVariant?.product?.default_unit_of_measure
                                ?.short_name
                            }
                          </span>
                        </b>
                        <p
                          className="attributes"
                          style={{
                            fontSize: '12px',
                            fontFamily: 'Brown Light',
                            opacity: '.5',
                          }}
                        >
                          {props.currentPortal === 'buyer'
                            ? ((props.currentPortal === 'supplier' &&
                                userAsk) ||
                                (props.currentPortal === 'buyer' && userBid)) &&
                              (props.currentPortal === 'supplier'
                                ? userAsk?.quantity +
                                  ' ' +
                                  userAsk?.cargo_unit?.plural_name
                                : userBid?.quantity +
                                  ' ' +
                                  userBid?.cargo_unit?.plural_name)
                            : props.currentPortal === 'supplier'
                            ? (props.exchangeOrderBook?.ask_orders?.length
                                ? lowestAskOrder?.quantity
                                : '') +
                              ' ' +
                              (props.exchangeOrderBook?.ask_orders?.length
                                ? lowestAskOrder?.cargo_unit?.plural_name
                                : '')
                            : (props.exchangeOrderBook?.bid_orders?.length
                                ? highestBidOrder?.quantity
                                : '') +
                              ' ' +
                              (props.exchangeOrderBook?.bid_orders?.length
                                ? highestBidOrder?.cargo_unit?.plural_name
                                : '')}
                        </p>
                      </>
                    )}
                    {currentPortal === 'buyer' && !userBid > 0 && <b>--</b>}
                  </Header>
                  {buyTimeframe ? (
                    <label
                      style={{
                        backgroundColor:
                          buyTimeframe === 'Prompt' ? '#d0f3cc' : '#c7eaec',
                        color:
                          buyTimeframe === 'Prompt' ? '#0D5740' : '#225061',
                        padding: '0 5px',
                        margin: '0px 0px 15px 9px',
                        borderRadius: '3px',
                        fontSize: '0.83em',
                        height: '20px',
                        width: '70px',
                      }}
                    >
                      {buyTimeframe}
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                {((currentPortal === 'supplier' && userAsk) ||
                  (currentPortal === 'buyer' && userBid)) && (
                  <Button
                    style={{
                      borderRadius: '3px',
                      padding: '10px',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      marginRight: '.5rem',
                      width: '65px',
                      height: '40px',
                      whiteSpace: 'nowrap',
                    }}
                    color="grey"
                    onClick={() => {
                      props.setOpenEditBidAskModal(true)
                      props.setEditingOrder(
                        currentPortal === 'supplier' ? userAsk : userBid
                      )
                      props.setEditingExchangeOrderBook(exchangeOrderBook)
                    }}
                  >
                    Edit
                  </Button>
                )}
                {((currentPortal === 'supplier' && !userAsk) ||
                  (currentPortal === 'buyer' && !userBid)) && (
                  <Button
                    basic
                    style={{
                      borderRadius: '3px',
                      marginRight: '.5rem',
                    }}
                    color="green"
                    className={'view-listing-button'}
                    onClick={() => {
                      if (setCurrentlySelectedTimeframe) {
                        setCurrentlySelectedTimeframe(
                          props.currentPortal === 'buyer'
                            ? sellTimeframe
                            : buyTimeframe
                        )
                      }
                      if (setCurrentlySelectedTruckingType) {
                        setCurrentlySelectedTruckingType(
                          props.currentPortal === 'buyer'
                            ? lowestAskOrder?.cargo_unit
                            : highestBidOrder?.cargo_unit
                        )
                      }
                      props.setOpenPricingModal(true)
                      props.setProductVariant(
                        exchangeOrderBook?.product_variant
                      )
                    }}
                  >
                    {currentPortal === 'supplier' ? 'Ask' : 'Bid'}
                  </Button>
                )}
              </div>
              <div
                style={{
                  marginBottom: '1rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'self-end',
                }}
              >
                <div>
                  <p
                    className="price-detail"
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  >
                    {currentPortal === 'buyer' ? 'Sell Price' : 'Your Sells'}
                  </p>

                  <Header as={'h3'} style={{ margin: '0' }} className="price">
                    {props.currentPortal === 'supplier' ? (
                      hasActiveOrder && hasCurrentSideOrder ? (
                        // TODO: this should make sure the 0th ask or bid is the user's
                        <b>
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'buyer' &&
                            userBid?.status === 'open' && (
                              <div
                                className="ring-container"
                                style={{ paddingTop: '.5rem' }}
                              >
                                <div
                                  className="ringring"
                                  style={{ top: 'calc(.5rem - 5px)' }}
                                ></div>
                                <div className="circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'buyer' &&
                            userBid?.status === 'disabled' && (
                              <div className="disabled-ring-container">
                                <div className="disabled-circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'supplier' &&
                            userAsk?.status === 'open' && (
                              <div
                                className="ring-container"
                                style={{ paddingTop: '.5rem' }}
                              >
                                <div
                                  className="ringring"
                                  style={{ top: 'calc(.5rem - 5px)' }}
                                ></div>
                                <div className="circle"></div>
                              </div>
                            )}
                          {hasActiveOrder &&
                            hasCurrentSideOrder &&
                            props.currentPortal === 'supplier' &&
                            userAsk?.status === 'disabled' && (
                              <div className="disabled-ring-container">
                                <div className="disabled-circle"></div>
                              </div>
                            )}
                          <span className="price">
                            {formatTotalPrice(
                              props.currentPortal === 'supplier'
                                ? userAsk?.price
                                : userBid?.price
                            )}
                          </span>
                          <span className="unit">
                            /
                            {
                              props.productVariant?.product
                                ?.default_unit_of_measure?.short_name
                            }
                          </span>
                        </b>
                      ) : (
                        <b>--</b>
                      )
                    ) : (props.currentPortal === 'supplier' &&
                        highestBidOrder) ||
                      (props.currentPortal === 'buyer' && lowestAskOrder) ? (
                      <Popup
                        mountNode={mountNode}
                        content={
                          <div
                            style={{
                              width:
                                windowDimensions.width > 768 && props.is_admin
                                  ? '65em'
                                  : '250px',
                              overflow: 'scroll',
                              paddingTop: '10px',
                            }}
                          >
                            <Header as={'h2'} textAlign={'center'}>
                              {' '}
                              {props.currentPortal === 'supplier'
                                ? 'Bids'
                                : 'Asks'}
                            </Header>
                            <p
                              style={{
                                fontSize: '12px',
                                margin: '0',
                                textAlign: 'center',
                              }}
                            >
                              {props.productVariant &&
                                props.productVariant.product.material.name}
                              {', '}
                              {props.productVariant &&
                                props.productVariant?.attribute_selections?.map(
                                  (attribute, index) => {
                                    let check =
                                      index !==
                                      props.productVariant.attribute_selections
                                        .length -
                                        1
                                    return attribute.attribute_selection_value +
                                      check
                                      ? ', '
                                      : ''
                                  }
                                )}
                            </p>
                            <Table basic compact unstackable>
                              <Table.Header>
                                <Table.HeaderCell textAlign={'center'}>
                                  $/
                                  {
                                    props.productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Number of Trucks</>
                                </Table.HeaderCell>
                                {props?.is_admin && (
                                  <>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Business</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Location</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>FOB Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Lowest Freight Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Provider</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Divisor</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>TWD</>
                                    </Table.HeaderCell>
                                  </>
                                )}
                              </Table.Header>
                              <Table.Body>
                                {props.currentPortal === 'buyer' &&
                                  props.groupedAsks
                                    ?.sort((a, b) => {
                                      let aPrice = a.total_price
                                      let bPrice = b.total_price
                                      if (aPrice < bPrice) return -1
                                      if (bPrice >= aPrice) return -1
                                    })
                                    .map((asks) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell textAlign={'center'}>
                                            {asks[0]?.total_price > 0
                                              ? formatTotalPrice(
                                                  asks[0]?.total_price
                                                )
                                              : '----'}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {reduce(
                                              asks.map((ask) => {
                                                return ask.quantity
                                              }),
                                              (memo, num) => {
                                                return memo + num
                                              },
                                              0
                                            )}
                                          </Table.Cell>
                                          {props?.is_admin && (
                                            <>
                                              <Table.Cell
                                                textAlign={'center'}
                                                width={2}
                                              >
                                                {asks?.map((ask) => {
                                                  return ask?.business_name
                                                    ? ask?.business_name
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.formatted_address
                                                    ? ask?.formatted_address
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.price
                                                    ? formatTotalPrice(
                                                        ask?.price
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.rate > 0
                                                    ? formatTotalPrice(
                                                        ask?.trucking?.rate
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking
                                                    ?.trucking_company
                                                    ? ask?.trucking
                                                        ?.trucking_company
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.divisor
                                                    ? ask?.trucking?.divisor
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {asks?.map((ask) => {
                                                  return ask?.trucking?.rate &&
                                                    ask?.trucking?.divisor &&
                                                    ask?.trucking?.rate /
                                                      ask?.trucking?.divisor >
                                                      0
                                                    ? formatTotalPrice(
                                                        ask?.trucking?.rate /
                                                          ask?.trucking?.divisor
                                                      )
                                                    : '--'
                                                })}
                                              </Table.Cell>
                                            </>
                                          )}
                                        </Table.Row>
                                      )
                                    })}
                              </Table.Body>
                            </Table>
                          </div>
                        }
                        on={'click'}
                        offset={[-10, 25]}
                        trigger={
                          <b
                            className="hover-price-underline"
                            id={`price-${props.index}`}
                          >
                            <span className="price">
                              {formatTotalPrice(
                                props.currentPortal === 'supplier'
                                  ? highestBidOrder?.total_price
                                  : lowestAskOrder?.total_price
                              )}
                            </span>
                            <span className="unit">
                              /
                              {
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              }
                            </span>
                          </b>
                        }
                      />
                    ) : (
                      <b>--</b>
                    )}
                    <p
                      className="attributes"
                      style={{
                        fontSize: '12px',
                        fontFamily: 'Brown Light',
                        opacity: '.5',
                      }}
                    >
                      {props.currentPortal === 'supplier'
                        ? hasActiveOrder &&
                          hasCurrentSideOrder &&
                          (props.currentPortal === 'supplier'
                            ? userAsk?.quantity +
                              ' ' +
                              userAsk?.cargo_unit?.plural_name
                            : userBid?.quantity +
                              ' ' +
                              userBid?.cargo_unit?.plural_name)
                        : hasOpposingSideOrder
                        ? props.currentPortal === 'supplier'
                          ? highestBidOrder?.quantity +
                            ' ' +
                            highestBidOrder?.cargo_unit?.plural_name
                          : lowestAskOrder?.quantity +
                            ' ' +
                            lowestAskOrder?.cargo_unit?.plural_name
                        : ''}
                    </p>
                  </Header>
                  {sellTimeframe ? (
                    <label
                      style={{
                        backgroundColor:
                          sellTimeframe === 'Prompt' ? '#d0f3cc' : '#c7eaec',
                        color:
                          sellTimeframe === 'Prompt' ? '#0D5740' : '#225061',
                        padding: '0 5px',
                        margin: '0px 0px 15px 9px',
                        borderRadius: '3px',
                        fontSize: '0.83em',
                        height: '20px',
                        width: '70px',
                      }}
                    >
                      {sellTimeframe}
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                {((currentPortal === 'supplier' && userAsk) ||
                  (currentPortal === 'buyer' && userBid)) && (
                  <>
                    <Button
                      style={{
                        borderRadius: '3px',
                        padding: '10px',
                        margin: '0 auto',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        marginRight: '.5rem',
                        height: '40px',
                        whiteSpace: 'nowrap',
                      }}
                      color="primary"
                      loading={
                        props.cancelBidLoading &&
                        selectedOrder?.id === props?.exchangeOrderBook?.id
                      }
                      onClick={handleCancelOrderClick}
                    >
                      Cancel{' '}
                      {props.currentPortal === 'supplier' ? 'Offer' : 'Buy'}
                    </Button>
                  </>
                )}
                {((props.currentPortal === 'supplier' && !userAsk) ||
                  (props.currentPortal === 'buyer' && !userBid)) && (
                  <Button
                    style={{
                      borderRadius: '3px',
                      marginRight: '.5rem',
                      height: '40px',
                      whiteSpace: 'nowrap',
                    }}
                    color="green"
                    onClick={() => {
                      if (setCurrentlySelectedTimeframe) {
                        setCurrentlySelectedTimeframe(
                          props.currentPortal === 'buyer'
                            ? sellTimeframe
                            : buyTimeframe
                        )
                      }
                      if (setCurrentlySelectedTruckingType) {
                        setCurrentlySelectedTruckingType(
                          props.currentPortal === 'buyer'
                            ? lowestAskOrder?.cargo_unit
                            : highestBidOrder?.cargo_unit
                        )
                      }
                      props.setOpenPricingModal(true)
                      props.setProductVariant(
                        props.exchangeOrderBook?.product_variant
                      )
                      props.setInputQuantity(
                        props.currentPortal === 'supplier'
                          ? highestBidOrder?.quantity
                          : lowestAskOrder?.quantity
                      )
                      props.setInputPrice(
                        props.currentPortal === 'supplier'
                          ? Math.ceil(highestBidOrder?.total_price * 100) / 100
                          : Math.ceil(lowestAskOrder?.total_price * 100) / 100
                      )
                    }}
                  >
                    {props.currentPortal === 'supplier'
                      ? 'Sell Now'
                      : 'Buy Now'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default ExchangeTableCard
