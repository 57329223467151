import React, { useState, useMemo } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import ESignTemplatesTable from '../../../components/Documents/ESignTemplatesTable'
import DocumentTable from '../../../components/Documents/DocumentTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import env from '../../../env'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const AdminDocuments = () => {
  const user = useSelector((state) => state.user.user)
  const canDelete = hasPermission(user, 'Delete Document')
  const [activeItem, setActiveItem] = useState('E-Sign Templates')
  const items = useMemo(() => {
    return ['E-Sign Templates', 'E-Sign Requests', 'Documents']
  })
  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={'Documents'}
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} style={{ paddingLeft: '25px' }}>
            <div className="exchange-table-heading">
              <div className="commodities-wrapper">
                {items?.map((item) => {
                  return (
                    <Header
                      as={'h2'}
                      className={
                        activeItem === item
                          ? 'commodity-header selected'
                          : 'commodity-header'
                      }
                      color={activeItem === item ? 'primary' : 'black'}
                      onClick={() => setActiveItem(item)}
                      style={{
                        margin: '0 1rem 1rem 0',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      }}
                    >
                      {item}
                    </Header>
                  )
                })}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        {activeItem === 'E-Sign Templates' && (
          <ESignTemplatesTable hideHeader />
        )}
        {activeItem === 'E-Sign Requests' && (
          <ESignRequestsTable generationDescription hideHeader />
        )}
        {activeItem === 'Documents' && (
          <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
            <Grid.Column width={16}>
              <DocumentTable hideHeader canDelete={canDelete} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  )
}

export default AdminDocuments
