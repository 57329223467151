import { useState } from 'react'
import SalesOrders from '../../../components/SalesOrders/SalesOrders'

function AdminSalesOrders(props) {
  const [openAddSalesOrderModel, setOpenAddSalesOrderModel] = useState(false)

  return (
    <div style={{ margin: '25px' }}>
      <SalesOrders
        openAddSalesOrderModel={openAddSalesOrderModel}
        setOpenAddSalesOrderModel={setOpenAddSalesOrderModel}
      />
    </div>
  )
}

export default AdminSalesOrders
