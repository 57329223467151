import React from 'react'
import { Placeholder } from 'semantic-ui-react'

const MickeyPlaceholderLoading = (props) => {
  return (
    <Placeholder style={props.style} fluid className={'mickey-table-loading'}>
      <Placeholder.Line />
      <Placeholder.Line />
      {props.size !== 'small' && props.size !== 'tiny' && (
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      )}
      {props.size !== 'small' && (
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      )}
      {props.size === 'large' && (
        <>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </>
      )}
    </Placeholder>
  )
}

export default MickeyPlaceholderLoading
