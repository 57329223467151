import React, { useState, useEffect } from 'react'
import './Settings.css'
import { Tab, Grid, Segment, Modal } from 'semantic-ui-react'
import MickeyForm from '../../components/Forms/MickeyForm'
import env from '../../env'
import { useSelector } from 'react-redux'
import PageHeader from '../../components/PageHeader/PageHeader'
import SMSNotificationSettings from '../shared/Profile/SMSNotificationSettings'
import NotificationTriggers from '../shared/Profile/NotificationTriggers'
import AddNotificationTriggers from '../shared/Profile/AddNotificationTriggers'
import MickeyTable from '../../components/MickeyTable/MickeyTable'
import ManageLocationsRow from '../shared/Profile/ManageLocationsRow'
import ManageUsersRow from '../shared/Profile/ManageUsersRow'
import MXPayments from './mx_shared/MXPayments/MXPayments'
import { hasPermission } from '../../lib/utils/utils'
import { Plus } from 'phosphor-react'

const Settings = (props) => {
  const user = useSelector((state) => state.user.user)
  const [openInviteNewUserModal, setOpenInviteNewUserModal] = useState(false)
  const currentPortal = props.currentPortal
  const business = useSelector((state) => state.user.business)
  const buyer = business.buyer
  const supplier = business.supplier
  const [reloadTriggersTable, setReloadTriggersTable] = useState(false)
  const [showAddNotificationsModal, setShowAddNotificationsModal] =
    useState(false)
  const [reload, setReload] = useState(false)
  const [openCreateLocationsModal, setOpenCreateLocationsModal] =
    useState(false)

  const conditions = [
    {
      label: 'Primary Business',
      checks: Object?.keys(supplier ? supplier : {})?.length > 0 ? true : false,
    },
  ]

  function handleInviteNewUser(status) {
    if (status === 'success') {
      setReload(true)
      setOpenInviteNewUserModal(false)
    }
  }

  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab('settings')
    }
  }, [])

  function handleAddLocationForm(status, data) {
    if (status === 'success') {
      setReload(true)
      setOpenCreateLocationsModal(false)
    }
  }

  const locationsHeaderRow = [
    'Name',
    'Address',
    'Phone',
    'Date Added',
    'Actions',
  ]
  const usersHeaderRow = [
    'Name',
    'Email',
    'Phone',
    'Date Added',
    'Actions',
    'Contact',
  ]

  const data = []

  if (hasPermission(user, 'Read Business')) {
    data.splice(0, 0, {
      menuItem: 'Account Details',
      render: (props) => (
        <Tab.Pane attached={false}>
          {props.business?.id && (
            <MickeyForm
              formName={'BusinessApplicationForm'}
              model={'Business'}
              loadingHeight={'150px'}
              instanceId={business?.id}
              disabled={!hasPermission(user, 'Update Business')}
              tenantAware={false}
              url={'/main/businesses/'}
              currentPortal={currentPortal}
              conditions={conditions}
            />
          )}
        </Tab.Pane>
      ),
    })
  }
  if (hasPermission(user, 'Read User')) {
    data.push({
      menuItem: 'Profile',
      render: (props) => (
        <Tab.Pane attached={false}>
          {props.user?.id && (
            <MickeyForm
              formName={'CustomUserForm'}
              model={'CustomUser'}
              instanceId={user?.id}
              disabled={!hasPermission(user, 'Update User')}
              url={'/main/custom-users/'}
              tenantAware={false}
              urlParams={{
                business_id: props.business?.id,
              }}
            />
          )}
        </Tab.Pane>
      ),
    })
  }
  if (hasPermission(user, 'Read User')) {
    data.push({
      menuItem: 'Manage Users',
      render: (props) => (
        <Tab.Pane attached={false}>
          <PageHeader
            buttons={
              hasPermission(user, 'Create User')
                ? [
                    {
                      color: 'primary',
                      className: 'supplier-step-2 buyer-step-2',
                      content: 'Add User',
                      onClick: () => {
                        setOpenInviteNewUserModal(true)
                      },
                      style: {
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      },
                    },
                  ]
                : []
            }
          />
          <MickeyTable
            headerRow={usersHeaderRow}
            url={'/main/custom-users/'}
            reload={reload}
            setReload={setReload}
            basicSegment
            renderBodyRow={(data, index) => {
              return (
                <ManageUsersRow
                  user={data}
                  index={index}
                  business={business}
                  key={index}
                  setReload={setReload}
                />
              )
            }}
            urlParams={{
              tenant_aware: false,
              business_id: business?.id,
            }}
          />
          <Modal
            onClose={() => setOpenInviteNewUserModal(false)}
            onOpen={() => setOpenInviteNewUserModal(true)}
            open={openInviteNewUserModal}
          >
            <Modal.Header>Invite a new user to {business.name}</Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'InviteNewUserForm'}
                model={'CustomUser'}
                url={'/main/custom-users/'}
                tenantAware={false}
                values={{
                  business_id: business?.id,
                }}
                handleForm={handleInviteNewUser}
                buttonLabel={'Send Invitation'}
              />
            </Modal.Content>
          </Modal>
        </Tab.Pane>
      ),
    })
  }
  if (hasPermission(user, 'Read Notification')) {
    data.push({
      menuItem: 'Notifications',
      render: (props) => (
        <Tab.Pane id="notifications" attached={false}>
          <PageHeader
            buttons={
              hasPermission(user, 'Create Notification')
                ? [
                    {
                      color: 'primary',
                      className: 'supplier-step-2 buyer-step-2',
                      icon: <Plus weight="bold" />,
                      content: 'Add Notification Trigger',
                      onClick: () => {
                        props.setShowAddNotificationsModal(true)
                      },
                      style: {
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      },
                    },
                  ]
                : []
            }
          />

          <SMSNotificationSettings
            userId={props.user.id}
            userReceiveSMSMessages={props.user?.receive_sms_message}
            currentPortal={currentPortal}
          />
          <AddNotificationTriggers
            business={props.business}
            userId={props.user.id}
            setReloadTriggersTable={props.setReloadTriggersTable}
            showModal={props.showAddNotificationsModal}
            currentPortal={currentPortal}
            setShowModal={props.setShowAddNotificationsModal}
          />
          <NotificationTriggers
            reload={props.reloadTriggersTable}
            currentPortal={currentPortal}
            setReload={props.setReloadTriggersTable}
            userId={props.user.id}
            admin={props.admin}
          />
        </Tab.Pane>
      ),
    })
  }
  if (hasPermission(user, 'Read Location')) {
    data.push({
      menuItem: 'Manage Addresses',
      render: (props) => (
        <Tab.Pane attached={false}>
          <PageHeader
            buttons={
              hasPermission(user, 'Create Location')
                ? [
                    {
                      color: 'primary',
                      className: 'supplier-step-2 buyer-step-2',
                      content: 'Add Location',
                      icon: <Plus weight="bold" />,
                      onClick: () => {
                        props.setOpenCreateLocationsModal(true)
                      },
                      style: {
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      },
                    },
                  ]
                : []
            }
          />
          <MickeyTable
            headerRow={props.locationsHeaderRow}
            url={'/main/locations/'}
            reload={props.reload}
            setReload={props.setReload}
            basicSegment
            renderBodyRow={(data, index) => {
              return (
                <ManageLocationsRow
                  location={data}
                  index={index}
                  key={index}
                  setReload={props.setReload}
                  buyer={props.buyer}
                  supplier={props.supplier}
                  business={props.business}
                />
              )
            }}
            urlParams={{
              tenant_aware: false,
              supplier_id: props.supplier?.id,
              buyer_id: props.buyer?.id,
              business_id: props.business?.id,
            }}
          />
          <Modal
            onClose={() => props.setOpenCreateLocationsModal(false)}
            onOpen={() => props.setOpenCreateLocationsModal(true)}
            open={props.openCreateLocationsModal}
          >
            <Modal.Header>Add Location</Modal.Header>
            <Modal.Content>
              <MickeyForm
                formName={'LocationForm'}
                model={'Location'}
                successMessage={'Added location successfully!'}
                failureMessage={'Error adding location, please try again.'}
                values={{
                  business_id: props.business?.id,
                }}
                url={'/main/locations/'}
                handleForm={props.handleAddLocationForm}
              />
            </Modal.Content>
          </Modal>
        </Tab.Pane>
      ),
    })
  }
  if (hasPermission(user, 'Read Payment')) {
    data.push({
      menuItem: 'Payments',
      render: (props) => (
        <Tab.Pane attached={false}>
          <MXPayments
            businessId={props.business?.id}
            hideHeader={true}
            style={{ boxShadow: 'none', border: 'none' }}
            currentPortal={currentPortal}
          />
        </Tab.Pane>
      ),
    })
  }

  return (
    <>
      <Grid
        id="settings-wrapper"
        className="settings-tabs"
        style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
      >
        <Grid.Column>
          <PageHeader icon={'file'} header={'Settings'} />
          <Tab
            menu={{ text: true }}
            panes={data}
            user={user}
            buyer={buyer}
            supplier={supplier}
            business={business}
            showAddNotificationsModal={showAddNotificationsModal}
            setShowAddNotificationsModal={setShowAddNotificationsModal}
            reloadTriggersTable={reloadTriggersTable}
            setReloadTriggersTable={setReloadTriggersTable}
            reload={reload}
            setReload={setReload}
            locationsHeaderRow={locationsHeaderRow}
            setOpenCreateLocationsModal={setOpenCreateLocationsModal}
            openCreateLocationsModal={openCreateLocationsModal}
            handleAddLocationForm={handleAddLocationForm}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default Settings
