import { Header, Segment } from 'semantic-ui-react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminDashboardExploreMissingCacheRow from './AdminDashboardExploreMissingCacheRow'

function AdminDashboardExploreMissingCache({ navigation }) {
  return (
    <Segment style={{ minHeight: '40vh', margin: '90px 20px 0px 20px' }}>
      <Header as="h2">Explore Missing Trucking Quotes</Header>
      <MickeyTable
        headerRow={[
          'Cargo Unit',
          'Pickup Zip',
          'Delivery Zip',
          "Shipper Can't Ships",
        ]}
        basicSegment
        url={'/main/trucking-lane-cargo-units/missing_from_cache/'}
        renderBodyRow={(data, index) => {
          return (
            <AdminDashboardExploreMissingCacheRow
              tlcu={data}
              index={index}
              onClick={() => {}}
              key={data ? data.id : null}
            />
          )
        }}
        emptyIcon={'truck'}
        emptyMessage={"TLCU's will populate here"}
      />
    </Segment>
  )
}

export default AdminDashboardExploreMissingCache
