import { Table } from 'semantic-ui-react'
import OrderItemDetailTwo from '../OrderItemDetail/OrderItemDetailTwo'

function SalesOrdersRow(props) {
  const {
    salesOrder,
    setSalesOrder,
    hideBusiness,
    setUpdate,
    setOpenAddSalesOrderModel,
  } = props

  return (
    <Table.Row
      onClick={(e, d) => {
        setSalesOrder(salesOrder)
        setUpdate(true)
        setOpenAddSalesOrderModel(true)
      }}
    >
      <Table.Cell>{salesOrder?.sales_order_number}</Table.Cell>
      <Table.Cell>
        {salesOrder?.sales_order_items?.map((lineItem) => {
          return <OrderItemDetailTwo orderItem={lineItem} />
        })}
      </Table.Cell>
      <Table.Cell>
        {salesOrder?.sales_order_items?.map((lineItem) => {
          return <p>{lineItem?.quantity}</p>
        })}
      </Table.Cell>
      <Table.Cell>
        {salesOrder?.sales_order_items?.map((lineItem) => {
          return <p>{lineItem?.uom?.plural_name}</p>
        })}
      </Table.Cell>
      <Table.Cell>
        {salesOrder?.sales_order_items?.map((lineItem) => {
          return <p>{lineItem?.price}</p>
        })}
      </Table.Cell>
      {!hideBusiness && (
        <Table.Cell>{salesOrder?.buyer?.business?.name}</Table.Cell>
      )}
      <Table.Cell>{salesOrder?.total_amount}</Table.Cell>
    </Table.Row>
  )
}

export default SalesOrdersRow
