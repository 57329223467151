import _ from 'underscore'
import React, { useState, useEffect } from 'react'
import { Search, Icon } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import './MickeySearch.css'

function MickeySearch(props) {
  const axios = useAxios()
  const [value, setValue] = useState(
    props.defaultResults && props.defaultResults.length > 0
      ? props.defaultResults[0]?.title
      : props.value || ''
  )
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(
    props.defaultResults ? props.defaultResults : []
  )
  const locationString = props.locationString

  useEffect(() => {
    let isSubscribed = true
    async function handleSearchChange() {
      setLoading(true)
      let pathName = ''
      if (locationString?.includes('admin/businesses')) {
        pathName = 'business'
      }
      if (locationString?.includes('admin/orders')) {
        pathName = 'orders'
      }
      axios
        .get(props.url, {
          params: {
            ...props.urlParams,
            q: value,
            filter: props.filter,
            pathname: pathName,
            tenant_aware: props.tenantAware,
          },
        })
        .then((response) => {
          let res_data =
            response?.data &&
            typeof response?.data === 'object' &&
            'results' in response?.data
              ? response?.data?.results
              : response?.data

          let results = _.map(res_data, (d) => {
            function index(obj, i) {
              return obj ? obj[i] : null
            }

            let title = ''
            props.titleFields.forEach((field) => {
              let val = field.split('.').reduce(index, d)
              if (val) {
                if (title.length > 0) {
                  title += ' '
                }
                title += val
              }
            })
            let description
            if (props.descriptionFields.length > 0) {
              description = ''
              props.descriptionFields.forEach((field) => {
                let val = field.split('.').reduce(index, d)
                if (val) {
                  if (description.length > 0) {
                    description += ' - '
                  }
                  description += val
                }
              })
            } else {
              description = ''
            }
            let url = d?.url
            let extraFields = {}
            if (props.extraFields?.length > 0) {
              props.extraFields.forEach((field) => {
                if (field in d) {
                  extraFields[field] = d[field]
                }
              })
            }
            return {
              title: title,
              description: description,
              url: url,
              id: d['id'],
              extraFields: extraFields,
              dataObject: d,
            }
          })
          if (isSubscribed) {
            if (props?.handleResponse) {
              props.handleResponse(results, value)
            } else {
              setResults(results)
            }
            setLoading(false)
          }
        })
    }
    const delayDebounceFn = setTimeout(() => {
      handleSearchChange()
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn)
      isSubscribed = false
    }
  }, [value])

  const clearSearch = () => {
    if (props.setSelection) {
      props.setSelection(undefined)
    }
    setValue('')
  }

  useEffect(() => {
    if (props?.results) {
      setResults(props?.results)
    }
  }, [props?.results])

  return (
    <Search
      id="mickey-search"
      loading={loading}
      label={props.label}
      fluid
      disabled={props.disabled ? props.disabled : false}
      onSearchChange={(e, d) => {
        setValue(d?.value)
        if (props.onSearchChange) {
          props.onSearchChange(e, d)
        }
      }}
      icon={
        props.hideIcons
          ? null
          : (props.icon && props.icon) ||
            (value && (
              <Icon name="delete" link onClick={(e, d) => clearSearch()} />
            )) ||
            (value === '' && <Icon name="search" />)
      }
      input={{
        iconPosition: props.iconPosition,
      }}
      clearable={'true'}
      name={props.name}
      placeholder={props.placeholder}
      onSelectionChange={(e, d) => {
        setValue(null)
        if (props.setSelection) {
          props.setSelection(null)
        }
        if (props.onChange) {
          props.onChange(e, d)
        }
      }}
      onResultSelect={(e, data) => {
        _.debounce(() => {
          setValue(data.result.title)
          if (props?.emptyFieldAfterRedirect) {
            setValue('')
          }
          if (props.setSelection) {
            props.setSelection(data.result.id)
          }
          if (props.onChange) {
            props.onChange(e, data)
          }
          if (props.onResultSelect) {
            props.onResultSelect()
          }
          if (props.setSelectionDataObject) {
            props.setSelectionDataObject(data?.result?.dataObject)
          }
        }, 500)()
      }}
      minCharacters={2}
      results={results}
      autoFocus={props.autoFocus}
      onClick={props.onClick}
      value={value}
      className={props.className}
      style={props.style}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    />
  )
}

export default MickeySearch
