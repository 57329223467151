import AdminOrders from '../../AdminOrders/AdminOrders'
import PageHeader from '../../../../components/PageHeader/PageHeader'

const AdminSalesCurrentOrders = (props) => {
  const { salespersonId } = props
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/deals' },
    { name: 'Sales', href: '/portal/admin/sales' },
  ]
  return (
    <>
      <PageHeader
        header="Current Deals"
        breadcrumbHeader="Current Deals"
        breadcrumb={breadcrumbData}
      />
      <AdminOrders salespersonId={salespersonId} hideItems hideHeader={true} />
    </>
  )
}

export default AdminSalesCurrentOrders
