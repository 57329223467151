import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../../lib/auth/useAuth'
import { useEffect, useState } from 'react'
import MickeyPlaceholderLoading from '../PlaceholderLoading/PlaceholderLoading'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute({ children }) {
  let auth = useAuth()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let isLoggedInLocal = localStorage.getItem('is_logged_in')
    async function isLoggedInFun() {
      let res = await auth.loggedInVerify()
      if (res?.data?.user?.id) {
        setIsLoggedIn(true)
      }
      setLoaded(true)
    }
    if (isLoggedInLocal) {
      isLoggedInFun()
    } else {
      setIsLoggedIn(false)
      setLoaded(true)
    }
  }, [])

  return (
    <div>
      {loaded && <>{isLoggedIn ? <Outlet /> : <Navigate to="/" />}</>}
      {!loaded && (
        <MickeyPlaceholderLoading size={'large'} style={{ margin: '20px' }} />
      )}
    </div>
  )
}
