import React, { useState } from 'react'
import { Modal, Grid, Dropdown, Button, Form } from 'semantic-ui-react'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const AdminOrderDocumentGenerationModal = (props) => {
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const { business, showModal, toggleModal } = props
  const [document, setDocument] = useState()
  const axios = useAxios()
  const sendBoxSignRequest = async (email) => {
    let errors = false
    if (email && !business.contact) {
      toast.error(
        `${business.contact.name} requires a contact user to send files for signature`
      )
      errors = true
    }
    if (!errors) {
      const res = await axios.post(`/main/box-sign-requests/`, {
        file_type: document,
        business_id: business.id,
        send_to_customer: email,
      })
      if (res.data.success) {
        toast.success(
          `Commercial credit application generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
        )
      } else {
        toast.error('Error generating document')
      }
    }
  }

  return (
    <>
      <Modal
        onClose={() => {
          toggleModal()
          setDocument()
        }}
        open={showModal}
        size={'small'}
      >
        <Modal.Header>
          <Grid.Row>
            <Grid.Column>Document Generation</Grid.Column>
          </Grid.Row>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} style={{ marginRight: '20px' }}>
                  <label>Select Document</label>
                  <Dropdown
                    placeholder={'Select Document'}
                    fluid
                    selection
                    value={document}
                    options={[
                      {
                        text: 'Commercial Credit Application',
                        value: 'commercial_credit_application',
                        key: 'commercial_credit_application',
                        content: <>Commercial Credit Application</>,
                      },
                    ]}
                    onChange={(_, d) => {
                      setDocument(d.value)
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              toggleModal()
              setDocument()
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={!document}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            onClick={() => sendBoxSignRequest(false)}
          >
            Create
          </Button>
          <Button
            type="submit"
            color="primary"
            icon="paper plane"
            disabled={!document}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            onClick={() => sendBoxSignRequest(true)}
            content={'Create & Send'}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminOrderDocumentGenerationModal
