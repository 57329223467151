import {
  requiredEmailValidator,
  requiredPhoneNumberValidator,
  requiredCountryValidator,
  emailValidator,
  requiredOnlyDigtsNotAllowedValidator,
} from '../components/Forms/validators'
import { Star } from 'phosphor-react'

export const AdminBusinessApplicationForm = {
  model: 'Business',
  format: [
    {
      header: 'Business Information',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
          label: 'Legal Business Name',
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(input, 'business name')
          },
        },
        referral: {
          type: 'text',
          label: 'Referral',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_first_name: {
          required: true,
          type: 'text',
          label: 'Contact First Name',
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(
              input,
              'contact first name'
            )
          },
        },
        contact_last_name: {
          required: true,
          type: 'text',
          label: 'Contact Last Name',
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(
              input,
              'contact last name'
            )
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_email: {
          required: true,
          type: 'text',
          label: 'Email',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
        finance_contact_email: {
          type: 'text',
          label: 'Finance Contact Email',
          validator: (input) => {
            return emailValidator(input)
          },
        },
        contact_phone: {
          required: true,
          type: 'phone',
          label: 'Phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        business_url: {
          type: 'text',
          label: 'Business URL',
        },
        business_tax_id: {
          type: 'text',
          label: 'Tax ID (EIN or Equivalent)',
        },
        hear_about_us: {
          type: 'text',
          label: 'Where Did You Hear About Us?',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        salespersons: {
          label: 'Salespeople',
          type: 'multiple dropdown model',
          url: '/main/admin-users/?light_serializer=true',
          option_text_fields: ['full_name', 'email'],
          renderLabelContexts: async (
            instanceId,
            values,
            setLabelContexts,
            axios
          ) => {
            const res = await axios.get(`/main/business-salespeople/`, {
              params: {
                user_ids: values,
                business_id: instanceId,
              },
            })
            if (res?.status === 200) {
              setLabelContexts(res.data.results)
            }
          },
          renderLabel: (
            item,
            labelContexts,
            instanceId,
            values,
            setLabelContexts,
            renderLabelContexts,
            axios
          ) => {
            const { text, value } = item
            const labelContext = labelContexts.find(
              (ctx) => ctx.custom_user.id === value
            )
            const setPrimary = async () => {
              const res = await axios.patch(
                `/main/business-salespeople/${labelContext.id}/`,
                {
                  primary: true,
                }
              )
              if (res?.status === 200) {
                await renderLabelContexts(
                  instanceId,
                  values,
                  setLabelContexts,
                  axios
                )
              }
            }
            return {
              content: (
                <>
                  <Star
                    style={{
                      cursor: 'pointer',
                      verticalAlign: 'middle',
                      marginRight: '7px',
                    }}
                    className={'start-watchlist'}
                    size={15}
                    color={labelContext?.primary ? 'orange' : 'grey'}
                    weight={labelContext?.primary ? 'duotone' : 'regular'}
                    onClick={() => {
                      if (labelContext && !labelContext.primary) {
                        setPrimary()
                      }
                    }}
                  />
                  {text}
                </>
              ),
            }
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        sales_tax_exempt: {
          type: 'checkbox',
          label: 'Sales Tax Exempt',
        },
        verified_customer: {
          type: 'checkbox',
          label: 'Verified Customer',
        },
      },
    },
    {
      header: 'Billing Address',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        billing_address_line_1: {
          required: true,
          type: 'text',
          label: 'Address 1',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        billing_address_line_2: {
          type: 'text',
          label: 'Address Line Two',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        billing_address_country_code: {
          label: 'Country',
          required: true,
          type: 'country',
          validator: (input) => {
            return requiredCountryValidator(input)
          },
        },
        billing_address_state: {
          label: 'State',
          required: true,
          type: 'dropdown search',
          url: '/main/states/',
          require_params: true,
          related_params: {
            billing_address_country_code: 'country',
          },
          title_fields: ['name'],
          description_fields: [''],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        billing_address_city: {
          required: true,
          type: 'text',
          label: 'City',
        },
        billing_address_zip_code: {
          required: true,
          type: 'text',
          label: 'Zip Code',
        },
      },
    },
    {
      header: 'Other Information',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        dead_weight_threshold: {
          type: 'text',
          label: 'Dead Weight Threshold',
        },
        dead_weight_unit_of_measure: {
          label: 'Dead Weight Unit of Measure',
          type: 'dropdown search',
          url: '/main/unit-of-measures/',
          title_fields: ['plural_name'],
          description_fields: ['short_name'],
        },
      },
    },
  ],
}
