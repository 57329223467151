import React from 'react'
import { Label } from 'semantic-ui-react'
import './PillIcon.css'

const PillIcon = ({ style, color, text }) => {
  return (
    <Label circular color={color} className="pill-icon" style={style && style}>
      {text}
    </Label>
  )
}

export default PillIcon
