export default function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatAddress(location) {
  if (!location?.address_1 && !location?.city) return <>--</>
  return (
    <>
      {location?.address_1 +
        (location?.address_2 ? ' ' + location?.address_2 : '')}
      <br />
      {location?.city + ', ' + location?.state + ' ' + location?.zip_code}
    </>
  )
}

export function formatAddressOneLine(location) {
  if (!location?.address_1 && !location?.city) return <>--</>
  return (
    <>
      {location?.address_1 +
        (location?.address_2 ? ' ' + location?.address_2 : '')}
      , {location?.city + ', ' + location?.state + ' ' + location?.zip_code}
    </>
  )
}

export const us_timezones = {
  Alabama: 'US/Central',
  Alaska: 'US/Alaska',
  Arizona: 'US/Arizona',
  Arkansas: 'US/Central',
  California: 'US/Pacific',
  Colorado: 'US/Mountain',
  Connecticut: 'US/Eastern',
  Delaware: 'US/Eastern',
  Florida: 'US/Eastern',
  Georgia: 'US/Eastern',
  Hawaii: 'US/Hawaii',
  Idaho: 'US/Mountain',
  Illinois: 'US/Central',
  Indiana: 'US/Eastern',
  Iowa: 'US/Central',
  Kansas: 'US/Central',
  Kentucky: 'US/Eastern',
  Louisiana: 'US/Central',
  Maine: 'US/Eastern',
  Maryland: 'US/Eastern',
  Massachusetts: 'US/Eastern',
  Michigan: 'US/Eastern',
  Minnesota: 'US/Central',
  Mississippi: 'US/Central',
  Missouri: 'US/Central',
  Montana: 'US/Mountain',
  Nebraska: 'US/Central',
  Nevada: 'US/Pacific',
  'New Hampshire': 'US/Eastern',
  'New Jersey': 'US/Eastern',
  'New Mexico': 'US/Mountain',
  'New York': 'US/Eastern',
  'North Carolina': 'US/Eastern',
  'North Dakota': 'US/Central',
  Ohio: 'US/Eastern',
  Oklahoma: 'US/Central',
  Oregon: 'US/Pacific',
  Pennsylvania: 'US/Eastern',
  'Rhode Island': 'US/Eastern',
  'South Carolina': 'US/Eastern',
  'South Dakota': 'US/Central',
  Tennessee: 'US/Central',
  Texas: 'US/Central',
  Utah: 'US/Mountain',
  Vermont: 'US/Eastern',
  Virginia: 'US/Eastern',
  Washington: 'US/Pacific',
  'West Virginia': 'US/Eastern',
  Wisconsin: 'US/Central',
  Wyoming: 'US/Mountain',
  'Washington DC': 'US/Eastern',
}
