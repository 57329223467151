import React, { useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Icon,
} from 'semantic-ui-react'
import './ResetPassword.css'
import useAxios from '../../../../lib/auth/useAxios'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import env from '../../../../env'

function ResetPasswordComplete(props) {
  const { buid64, token } = useParams()
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const axios = useAxios()
  const navigate = useNavigate()

  const submitPassword = () => {
    if (password1 !== password2) {
      toast.error('Passwords do not match', {
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      })
    } else {
      async function postPassword() {
        const res = await axios.post('/main/reset-password-complete/', {
          password1: password1,
          password2: password2,
          buid64: buid64,
          token: token,
        })
        if (res.data.reset_password) {
          toast.success('Password Reset Successful!', {
            position: 'top-right',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          })
          navigate('/login')
        }
      }
      postPassword()
    }
  }
  return (
    <Grid
      id="reset-password-page"
      style={{
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
      }}
    >
      <Grid.Column
        style={{
          maxWidth: '500px',
        }}
      >
        <Segment
          className="main-background-texture"
          style={{
            padding: '10px 44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          <Image
            centered
            src={env.REACT_APP_LOGO}
            alt="Logo"
            style={{ marginTop: '20px', maxWidth: '200px', height: 'auto' }}
          />
          <Header
            textAlign="center"
            style={{
              color: '#000',
              fontSize: '24px',
              margin: '35px auto 20px auto',
              letterSpacing: '1px',
            }}
          >
            Reset your password
          </Header>
          <p className="submit-text" style={{ color: 'grey' }}>
            Make sure both passwords match in order to continue
          </p>
          <Form size="large" style={{ minWidth: '100%' }}>
            <Form.Input
              inverted
              fluid
              type={visible1 ? 'text' : 'password'}
              color={'white'}
              iconPosition="left"
              placeholder="Password"
              name="password1"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              style={{ margin: '20px auto' }}
              icon={
                password1 ? (
                  <Icon
                    name={visible1 ? 'eye' : 'eye slash'}
                    link
                    onClick={(e, d) => {
                      setVisible1(!visible1)
                    }}
                  />
                ) : (
                  ''
                )
              }
            />
            <Form.Input
              inverted
              fluid
              type={visible2 ? 'text' : 'password'}
              color={'white'}
              iconPosition="left"
              placeholder="Confirm Password"
              name="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              style={{ margin: '20px auto' }}
              icon={
                password2 ? (
                  <Icon
                    name={visible2 ? 'eye' : 'eye slash'}
                    link
                    onClick={(e, d) => {
                      setVisible2(!visible2)
                    }}
                  />
                ) : (
                  ''
                )
              }
            />
            <Button
              className={'reset-btn'}
              color={'green'}
              fluid
              size="large"
              type="submit"
              style={{ border: 'none' }}
              onClick={submitPassword}
              disabled={
                (password1 === '' && password2 === '') ||
                password1.length !== password2.length
              }
            >
              Submit
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default ResetPasswordComplete
