import React, { useState } from 'react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { Table, Checkbox } from 'semantic-ui-react'
import { format_phone_number } from '../../../lib/utils/utils'
import './AddNotificationTrigger.css'

const SelectNotifiedUsers = (props) => {
  const headers = ['Select', 'Name', 'Email', 'Phone Number', 'Receive SMS']
  const [reload, setReload] = useState(false)
  const [users, setUsers] = useState({})

  const handleResponse = (data) => {
    setUsers(data)
    return data
  }

  return (
    <>
      <div id="member-checkbox">
        <Checkbox
          style={{ marginRight: '10px' }}
          checked={props.selectedUsers.length === users.length}
          onClick={() => {
            if (props.selectedUsers.length === users.length) {
              props.setSelectedUsers([])
            } else {
              let ids = users.map((user) => user?.id)
              props.setSelectedUsers(ids)
            }
          }}
        />{' '}
        Send to all team members
      </div>
      <MickeyTable
        id="notification-users-table"
        headerRow={headers}
        url={'/main/custom-users/'}
        reload={reload}
        setReload={setReload}
        handleResponse={handleResponse}
        renderBodyRow={(data, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>
                <Checkbox
                  style={{ marginRight: '10px' }}
                  checked={props.selectedUsers.includes(data?.id)}
                  onClick={() => {
                    if (props.selectedUsers.includes(data?.id)) {
                      props.setSelectedUsers(
                        props.selectedUsers.filter((eo) => eo !== data?.id)
                      )
                    } else {
                      props.setSelectedUsers([...props.selectedUsers, data?.id])
                    }
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                {data?.first_name + ' ' + data?.last_name}
              </Table.Cell>
              <Table.Cell>{data?.email}</Table.Cell>
              <Table.Cell>{format_phone_number(data?.phone)}</Table.Cell>
              <Table.Cell>
                {data?.receive_sms_messages ? <>Yes</> : <>No</>}
              </Table.Cell>
            </Table.Row>
          )
        }}
        urlParams={{
          tenant_aware: false,
          business_id: props?.business?.id,
        }}
        style={{
          border: 'none !important',
        }}
      />
    </>
  )
}

export default SelectNotifiedUsers
