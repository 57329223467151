const env = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  REACT_APP_BALANCE_URL: process.env.REACT_APP_BALANCE_URL,
  REACT_APP_YODA_WEBSOCKET: process.env.REACT_APP_YODA_WEBSOCKET,
  REACT_APP_SECONDARY_COLOR: process.env.REACT_APP_SECONDARY_COLOR,
  REACT_APP_PRIMARY_COLOR: process.env.REACT_APP_PRIMARY_COLOR,
  REACT_APP_STATIC_BASE_URL: process.env.REACT_APP_STATIC_BASE_URL,
  REACT_APP_LOGO: process.env.REACT_APP_LOGO,
  REACT_APP_SECONDARY_BUTTON_COLOR:
    process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
  REACT_APP_FACEBOOK_PIXEL_ID: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
  REACT_APP_RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_LINKEDIN_PIXEL_ID: process.env.REACT_APP_LINKEDIN_PIXEL_ID,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_REDIRECT: process.env.REACT_APP_REDIRECT,
  REACT_APP_BUSINESS_NAME: process.env.REACT_APP_BUSINESS_NAME,
  REACT_APP_BUSINESS_CONTACT_EMAIL:
    process.env.REACT_APP_BUSINESS_CONTACT_EMAIL,
  REACT_APP_BUSINESS_CONTACT_PHONE:
    process.env.REACT_APP_BUSINESS_CONTACT_PHONE,
  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_MINI_LOGO: process.env.REACT_APP_MINI_LOGO,
  REACT_APP_MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  REACT_APP_INSTAGRAM: process.env.REACT_APP_INSTAGRAM,
  REACT_APP_TWITTER: process.env.REACT_APP_TWITTER,
  REACT_APP_LINKEDIN: process.env.REACT_APP_LINKEDIN,
  REACT_APP_FACEBOOK: process.env.REACT_APP_FACEBOOK,
  REACT_APP_INDUSTRY: process.env.REACT_APP_INDUSTRY,

  REACT_APP_HOMEPAGE_TITLE: process.env.REACT_APP_HOMEPAGE_TITLE,
  REACT_APP_HOMEPAGE_SUBTITLE: process.env.REACT_APP_HOMEPAGE_SUBTITLE,
  REACT_APP_HOMEPAGE_TITLE_IMAGE: process.env.REACT_APP_HOMEPAGE_TITLE_IMAGE,
  REACT_APP_HOMEPAGE_IMAGE: process.env.REACT_APP_HOMEPAGE_IMAGE,
  REACT_APP_HOMEPAGE_WHAT_IS_DESCRIPTION: process.env.REACT_APP_HOMEPAGE_WHAT_IS_DESCRIPTION,
  REACT_APP_HOMEPAGE_BOTTOM_IMAGE: process.env.REACT_APP_HOMEPAGE_BOTTOM_IMAGE, 

  REACT_APP_ABOUT_PAGE_TITLE: process.env.REACT_APP_ABOUT_PAGE_TITLE,
  REACT_APP_ABOUT_PAGE_SUBTITLE: process.env.REACT_APP_ABOUT_PAGE_SUBTITLE,
  REACT_APP_ABOUT_PAGE_HEADER1: process.env.REACT_APP_ABOUT_PAGE_HEADER1,
  REACT_APP_ABOUT_PAGE_SUBHEADER1: process.env.REACT_APP_ABOUT_PAGE_SUBHEADER1,
  REACT_APP_ABOUT_PAGE_HEADER2: process.env.REACT_APP_ABOUT_PAGE_HEADER2,
  REACT_APP_ABOUT_PAGE_SUBHEADER2: process.env.REACT_APP_ABOUT_PAGE_SUBHEADER2,
  REACT_APP_ABOUT_PAGE_HEADER3: process.env.REACT_APP_ABOUT_PAGE_HEADER3,
  REACT_APP_ABOUT_PAGE_SUBHEADER3: process.env.REACT_APP_ABOUT_PAGE_SUBHEADER3,


}

export default env
