import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Table, Label, Button, Modal } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import './AdminFulfillment.css'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { toast } from 'react-toastify'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import env from '../../../env'
function AdminFulfillmentUsers(props) {
  const [reload, setReload] = useState(false)
  const [truckingUsers, setTruckingUsers] = useState([])
  const [loadingUserButton, setLoadingUserButton] = useState(null)
  const [addTruckingUserModalOpen, setAddTruckingUserModalOpen] =
    useState(false)
  const user = useSelector((state) => state.user.user)
  const business = useSelector((state) => state.user.business)
  const axios = useAxios()

  let headerRow = ['Name', 'Email', 'Trucking Company', 'Status']
  const headerSearch = [
    {
      header: 'Name',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'CustomUser',
        query_dict: { first_name__isnull: false, last_name__isnull: false },
        filter_field_value_list: ['first_name', 'last_name'],
      },
      options_titleFields: ['first_name', 'last_name'],
      options_valueFields: [],
      search_param: 'first_name',
    },
    {
      header: 'Trucking Company',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'CargoUnitInstance',
        query_dict: { trucking_company__isnull: false },
        filter_field_value_list: ['trucking_company__name'],
      },
      options_titleFields: ['trucking_company__name'],
      options_valueFields: ['trucking_company__name'],
      search_param: 'trucking_company',
    },
  ]
  const headerSort = [
    {
      header: 'Name',
      sort_by: 'first_name',
    },
    {
      header: 'Email',
      sort_by: 'email',
    },
    {
      header: 'Trucking Company',
      sort_by: 'trucking_company__name',
    },
    {
      header: 'Status',
    },
  ]

  async function approveUser(user) {
    setLoadingUserButton(user?.id)
    let res = await axios.patch(
      `/main/admin-users/${user?.id}/?trucking_users=true`,
      {
        approved: true,
      }
    )
    setLoadingUserButton(null)
    if (res.data.approved) {
      toast.success('Approved user!')
      setReload(true)
    } else {
      toast.error('Error approving user.')
    }
  }

  const addToSubmit = () => {
    setAddTruckingUserModalOpen(false)
    setReload(true)
  }

  return (
    <div style={{ padding: 0, marginTop: '0px' }}>
      <PageHeader
        icon={'user'}
        header={'Fulfillment Users'}
        buttons={
          hasPermission(user, 'Create User')
            ? [
                {
                  color: 'primary',
                  content: 'Add Fulfillment User',
                  onClick: () => {
                    setAddTruckingUserModalOpen(true)
                  },
                  style: {
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  },
                },
              ]
            : []
        }
      />
      <MickeyTable
        headerRow={headerRow}
        headerSearch={headerSearch}
        headerSort={headerSort}
        url={'/main/admin-users/'}
        urlParams={{
          trucking_users: true,
        }}
        reload={reload}
        setReload={setReload}
        setParentRow={setTruckingUsers}
        dependencies={[reload]}
        basicSegment
        renderBodyRow={(data, index) => {
          return (
            <Table.Row>
              <Table.Cell>
                {data?.first_name} {data?.last_name}
              </Table.Cell>
              <Table.Cell>{data?.email}</Table.Cell>
              <Table.Cell>{data?.trucking_company?.name}</Table.Cell>
              <Table.Cell>
                {data?.approved && (
                  <Label color={'green'} content={'Approved'} />
                )}
                {!data?.approved && (
                  <Button
                    loading={data?.id === loadingUserButton}
                    content={'Approve User'}
                    onClick={() => {
                      approveUser(data)
                    }}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          )
        }}
        emptyIcon={'users'}
        emptyMessage={'Fulfillment users will populate here'}
      />
      <Modal
        onClose={() => setAddTruckingUserModalOpen(false)}
        onOpen={() => setAddTruckingUserModalOpen(true)}
        open={addTruckingUserModalOpen}
      >
        <Modal.Header>Add Fulfillment User</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'TruckingUserForm'}
            model={'CustomUser'}
            url={'/main/custom-users/'}
            values={{
              business_id: business?.id,
              trucking_user: true,
            }}
            addToSubmit={() => addToSubmit()}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminFulfillmentUsers
