import React, { useState } from 'react'
import { Label } from 'semantic-ui-react'

function InventoryAttributeValue(props) {
  const [isOn, setIsOn] = useState(
    props.currentAttributeSelections?.includes(props.attributeSelection.id)
  )

  async function updateAttributeValue() {
    let atts = props.currentAttributeSelections
    const index = atts.indexOf(props.attributeSelection.id)
    if (index > -1) {
      atts.splice(index, 1)
      setIsOn(false)
    } else {
      atts.push(props.attributeSelection.id)
      setIsOn(true)
    }
    props.setCurrentAttributeSelections(atts)
  }

  return (
    <Label
      onClick={() => {
        updateAttributeValue()
      }}
      style={{ cursor: 'pointer !important' }}
      basic={!isOn}
      color={isOn ? 'blue' : 'grey'}
      className={'product-detail-label'}
    >
      {props.attributeSelection.value}
    </Label>
  )
}

export default InventoryAttributeValue
