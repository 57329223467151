import React, { useState, useEffect } from 'react'
import {
  Segment,
  Loader,
  Divider,
  Grid,
  Header,
  Button,
} from 'semantic-ui-react'
import './MxNews.css'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { useNavigate } from 'react-router-dom'
const MxNews = () => {
  const navigate = useNavigate(0)
  const axios = useAxios()
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (
      env.REACT_APP_REDIRECT &&
      env.REACT_APP_REDIRECT !== 'false' &&
      env.REACT_APP_REDIRECT !== 'null'
    ) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    const getNews = async () => {
      let res = await axios.get('/main/news/', {
        params: {
          today: new Date(),
        },
      })
      if (res.data.results) {
        setNews(res.data.results)
        setLoading(false)
      }
    }
    getNews()
  }, [])

  const getUrl = (url) => {
    return url.includes('://') ? url : `https://${url}`
  }

  return (
    <Grid id="news-page">
      <Grid.Column width={window.width < 768 ? 15 : 14}>
        <Header className="main-heading" as={'h1'} style={{ fontSize: '36px' }}>
          News{' '}
        </Header>
        <Divider />
        {news &&
          !loading &&
          news.length > 0 &&
          news?.map((newsArticle) => {
            return (
              <>
                <Grid stackable className="news-row" width={16}>
                  <Grid.Column className="info-wrapper" width={13}>
                    <h5 className="heading">{newsArticle.publish_date}</h5>
                    {newsArticle?.logo && (
                      <img
                        src={newsArticle.logo}
                        style={{ height: '100px', width: 'auto' }}
                        alt=""
                      />
                    )}
                    <h3 className="sec-heading">{newsArticle.title}</h3>
                    <p className="para-heading">
                      {newsArticle.description.slice(0, 375)}
                    </p>
                    {newsArticle.description &&
                      newsArticle.description.length > 375 && (
                        <a
                          className="read-more"
                          target="_blank"
                          href={getUrl(newsArticle.article_link)}
                          rel="noreferrer"
                        >
                          ... Read More
                        </a>
                      )}
                  </Grid.Column>
                  <Grid.Column className="button-wrapper" width={3}>
                    <Button
                      style={{
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                        padding: '1rem',
                        borderRadius: '500px',
                      }}
                      color="primary"
                      target="_blank"
                      href={getUrl(newsArticle.article_link)}
                    >
                      View Full Article
                    </Button>
                  </Grid.Column>
                </Grid>
                <Divider />
              </>
            )
          })}
        {!loading && news && news.length < 1 && (
          <MickeyPlaceholder
            icon={'newspaper'}
            message={'No News'}
            style={{
              backgroundColor: 'white',
              border: 'none',
              boxShadow: 'none',
            }}
          />
        )}
        {loading && news && news.length < 1 && (
          <Segment style={{ width: '100%', height: '200px' }}>
            <Loader active />
          </Segment>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default MxNews
