import React, { useEffect, useState, useRef } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import PageHeader from '../../../components/PageHeader/PageHeader'
import {
  Message,
  Menu,
  Modal,
  Button,
  Grid,
  Segment,
  Input,
} from 'semantic-ui-react'
import InventoryRow from './InventoryRow'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import MickeyForm from '../../../components/Forms/MickeyForm'
import _ from 'underscore'
import './Inventory.css'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import InventoryAttributeValue from './InventoryAttributeValue'
import InventoryTable from './InventoryTable'
import { startLoader, stopLoader } from '../../../lib/utils/utils'
import { Steps } from 'intro.js-react'
import { CSVLink } from 'react-csv'
import env from '../../../env'

function Inventory(props) {
  // props
  const { supplier, business } = props
  // state
  const [loadedLocations, setLoadedLocations] = useState(false)
  const [state, setState] = useState(Date.now())
  const [reload, setReload] = useState(false)
  const [editingProductId, setEditingProductId] = useState(null)
  const [tempEditingProductId, setTempEditingProductId] = useState(null)
  const [attributes, setAttributes] = useState(null)
  const [currentAttribute, setCurrentAttribute] = useState(null)
  const [reloadPopup, setReloadPopup] = useState(false)
  const [currentAttributeSelections, setCurrentAttributeSelections] = useState(
    []
  )
  const [allAttributeSelections, setAllAttributeSelections] = useState({})
  const [currentAttributePriority, setCurrentAttributePriority] = useState(1)
  const [loading, setLoading] = useState(null)
  const [numAttributes, setNumAttributes] = useState(null)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [openProductModal, setOpenProductModal] = useState(false)
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [csvTemplateData, setCsvTemplateData] = useState([])
  const [openAttributesModal, setOpenAttributesModal] = useState(false)
  const [locations, setLocations] = useState(null)
  const [currentLocation, setCurrentLocation] = useState(null)
  const [uploadTemplate, setUploadTemplate] = useState(null)
  const [runTour, setRunTour] = useState(
    !props.admin && !business?.completed_inventory_tour
  )
  // hooks
  const axios = useAxios()
  const dispatch = useDispatch()
  const exportRef = useRef(null)
  // vars
  const headerRows = null
  const productListings = []
  const tenantAware = props.admin ? false : true

  function handleAddModalForm(success, data) {
    setOpenProductModal(false)
    let sortedAttributes = data?.product?.attributes
    if (sortedAttributes?.length > 0) {
      setCurrentAttributeSelections([])
      setAttributes(sortedAttributes)
      setCurrentAttribute(sortedAttributes[0])
      setTempEditingProductId(data.id)
      setOpenAttributesModal(true)
    }
  }

  async function downloadTemplate() {
    let res = await axios.get(`/main/supplier-upload-download-inventory/`, {
      params: {
        supplier_id: supplier?.id,
        tenant_aware: tenantAware,
      },
    })
    setCsvTemplateData(res.data.results.csv_template)

    setTimeout(() => {
      exportRef.current.link.click()
    })
  }

  function handleChange(event) {
    setUploadTemplate(event.target.files[0])
  }
  async function uploadFunction() {
    const formData = new FormData()
    if (!uploadTemplate) {
      toast.error('Please add a file.')
      return
    }
    formData.append('file', uploadTemplate)
    formData.append('fileName', uploadTemplate.name)
    formData.append('supplier_id', supplier.id)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    let res = await axios.post(
      `/main/supplier-upload-download-inventory/`,
      formData,
      config
    )
    if (res.data.success) {
      setOpenUploadModal(false)
      toast.success('Updated inventory quantities and prices!')
    } else {
      toast.error(
        'Error updating inventory, your file may be corrupt. Please redownload the file and try again.'
      )
    }
  }

  function selectAll() {
    setCurrentAttributeSelections(
      _.map(
        currentAttribute?.attribute_selections_json,
        (attribute_selection) => {
          return attribute_selection.id
        }
      )
    )

    setState(Date.now())
  }

  async function uploadCSV() {
    setOpenUploadModal(true)
  }

  async function addProductVariant(productVariant) {
    startLoader(dispatch)
    const attributeSelectionIds = []
    productVariant.attribute_selections.forEach((attributeSelection) => {
      attributeSelectionIds.push(attributeSelection.attribute_selection_id)
    })
    if (!currentLocation) {
      toast.error(
        'Please add a location to your profile before adding inventory.'
      )
      return
    }
    let url = `/main/product-listing-prices/`
    if (!tenantAware) {
      url = `/main/product-listing-prices/?tenant_aware=false`
    }
    let res = await axios.post(url, {
      attribute_selection_ids: attributeSelectionIds,
      product_id: productVariant.product.id,
      supplier_id: supplier?.id,
      location_id: currentLocation?.id,
      admin: props.admin,
    })
    stopLoader(dispatch)
    if (res?.data?.id) {
      setEditingProductId(res.data?.product_listing?.id)
      setTempEditingProductId(res.data?.product_listing?.id)
      toast.success('Added product listing!.')
      setReload(true)
    } else {
      toast.error('Error adding product listing.')
    }
  }

  useEffect(() => {
    async function loadLocations() {
      let res = await axios.get(`/main/locations/`, {
        params: {
          business_id: business?.id,
          tenant_aware: tenantAware,
        },
      })
      if (res?.data?.results?.length > 0) {
        setCurrentLocation(res?.data.results[0])
      }
      setLocations(res?.data.results)
    }
    if (!loadedLocations) {
      loadLocations()
      setLoadedLocations(true)
    }
  }, [loadedLocations])

  useEffect(() => {
    setState(Date.now())
  }, [currentAttributeSelections])

  async function handleAddAttributesModalForm() {
    if (currentAttributePriority >= attributes.length) {
      setButtonLoading(true)
      let final_attribute_selections = {
        ...allAttributeSelections,
        [currentAttribute?.id]: currentAttributeSelections,
      }
      let url = `/main/create-product-listing-prices/`
      if (!tenantAware) {
        url = `/main/create-product-listing-prices/?tenant_aware=false`
      }
      let res = await axios.post(url, {
        product_listing: tempEditingProductId,
        all_attribute_selections: final_attribute_selections,
      })
      setButtonLoading(false)
      if (res?.data?.success) {
        setOpenAttributesModal(false)
        setEditingProductId(tempEditingProductId)
        setTempEditingProductId(null)
        setCurrentAttributeSelections([])
        toast.success(
          'Added product! Please wait while we add all variations. Once ready, please fill in the various prices for your product below.'
        )
      } else {
        setOpenAttributesModal(false)
        toast.error('Error adding product, please try again!')
      }
    } else {
      setAllAttributeSelections({
        ...allAttributeSelections,
        [currentAttribute?.id]: currentAttributeSelections,
      })
      setCurrentAttribute(null)
      setCurrentAttributeSelections([])
      setCurrentAttribute(attributes[currentAttributePriority])
      setCurrentAttributePriority(currentAttributePriority + 1)
    }
  }
  const tourSteps = [
    {
      element: '.step-1',
      title: 'Welcome to the Inventory Page',
      intro:
        'The Inventory Page is where you can view and manage your products. All of your active products are shown by location and will ultimately be displayed on your storefront.',
    },
    {
      element: '.step-2',
      title: 'Upload CSV Button',
      intro:
        'The Upload CSV button is an easy way to manage and update your inventory. To update your stocklist, first create or download your current inventory as a CSV file. Update your pricing, add product variants, and remove products as you see fit in that file. After saving you can then click the Upload CSV Button and add your CSV file to update your stocklist on Mickey.',
    },
    {
      element: '.step-3',
      title: 'Browse & Add Button',
      intro:
        'The Browse & Add button is another way for you to add new items into your Mickey Inventory. You can search our list of commodities and products and select which products and product variants you would like to add to your inventory. Make sure you have selected the correct location before adding products.',
    },
    {
      element: '.step-4',
      title: 'Location Tabs',
      intro:
        "The Location Tabs show all of your business's locations. You can select a location from these tabs to see and manage your inventory for that location. If you need to add new locations, go to Settings in the sidebar and and select the Manage Addresses tab. Here you will be able to add and edit your business's locations.",
    },
    {
      element: '.step-5',
      title: 'Products List',
      intro:
        'The Products List shows all of the products in your inventory for that location. When you click on a product, you can manage pricing and availability for the selected location.',
    },
  ]

  const updateFinishedPageTour = async () => {
    let url = `/main/businesses/${business?.id}/`
    if (!tenantAware) {
      url = `/main/businesses/${business?.id}/?tenant_aware=false`
    }
    await axios.patch(url, {
      completed_inventory_tour: true,
      business_id: business?.id,
      admin: tenantAware ? false : true,
    })
    setRunTour(false)
  }

  return (
    <div style={{ padding: props.admin ? '' : '20px' }}>
      {runTour && (
        <Steps
          enabled={runTour}
          steps={tourSteps}
          initialStep={0}
          onExit={() => {}}
          onComplete={() => updateFinishedPageTour()}
          options={{
            exitOnEsc: false,
            exitOnOverlayClick: false,
            showStepNumbers: true,
            showBullets: false,
            showProgress: true,
          }}
        />
      )}
      <Segment className={props.admin ? '' : 'main-segment-container'}>
        <Grid>
          <Grid.Column width={16} className={'main-container-left'}>
            <PageHeader
              icon={'box'}
              header={'Inventory'}
              id="step-1"
              subheader={'Your stock lists in the cloud.'}
              buttons={[
                {
                  buttonClass: 'step-2',
                  icon: 'up arrow',
                  color: 'primary',
                  content: 'Upload CSV',
                  onClick: () => {
                    uploadCSV()
                  },
                  style: {
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  },
                },
                {
                  buttonClass: 'step-3',
                  popupClass: 'inventory-popup',
                  icon: 'plus',
                  color: 'primary',
                  content: 'Browse & Add',
                  style: {
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  },
                  popupOffset: [-100, 0],
                  reloadPopup: reloadPopup,
                  popupContent: (
                    <InventoryTable
                      showAddButton
                      productVariantsRoute="/main/inventory/"
                      currentLocation={currentLocation}
                      setReloadPopup={setReloadPopup}
                      reloadPopup={reloadPopup}
                      reload={reload}
                      onAddButton={(result) => {
                        addProductVariant(result)
                      }}
                    />
                  ),
                  popup: true,
                  popupPosition: 'bottom right',
                },
              ]}
            />
            <Message className="inventory-instructions">
              <Message.Header>Inventory Setup</Message.Header>
              <p>
                To get started selling on Mickey, select the location you'd like
                to add or edit products and click the Browse & Add button above.
                For each product listing, click on the product listing row to
                view the variants that you've added and update inventory and
                price values.
              </p>
            </Message>
            <Menu
              className="inventory-menu step-4"
              style={{
                flexWrap: 'wrap',
              }}
              secondary
            >
              {locations &&
                locations.map((location, index) => {
                  return (
                    <Menu.Item
                      style={{
                        borderRadius: '15px',
                      }}
                      color={
                        currentLocation?.id === location?.id ? 'green' : 'grey'
                      }
                      key={index}
                      content={
                        location.address_1 +
                        ', ' +
                        location.city +
                        ', ' +
                        location.state
                      }
                      name={location?.id ? location.id.toString() : ''}
                      active={currentLocation?.id === location?.id}
                      onClick={() => {
                        setCurrentLocation(location)
                      }}
                    />
                  )
                })}
            </Menu>
            <div className="step-5" style={{ margin: '20px 10px' }}>
              {currentLocation && supplier && (
                <MickeyTable
                  className="supplier-inventory-table"
                  headerRow={headerRows}
                  url={'/main/supplier-inventory'}
                  reload={reload}
                  unselectable
                  compact
                  basicSegment
                  setReload={setReload}
                  renderBodyRow={(data, index) => {
                    return (
                      <InventoryRow
                        productListing={data}
                        index={index}
                        reload={reload}
                        loading={loading}
                        setLoading={setLoading}
                        setReload={setReload}
                        supplier={supplier}
                        numAttributes={numAttributes}
                        setNumAttributes={setNumAttributes}
                        editingProductId={editingProductId}
                        productListings={productListings}
                        setEditingProductId={setEditingProductId}
                        key={data ? data.id : null}
                      />
                    )
                  }}
                  urlParams={{
                    archived: 'False',
                    supplier_id: supplier?.id,
                    location_id: currentLocation?.id,
                  }}
                  dependencies={[currentLocation]}
                  emptyIcon={'box'}
                  emptyMessage={'No products yet!'}
                />
              )}
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
      <Modal
        onClose={() => setOpenProductModal(false)}
        onOpen={() => setOpenProductModal(true)}
        open={openProductModal}
        floated={'right'}
        size={'mini'}
      >
        <Modal.Header>Add Product</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'ProductListingForm'}
            model={'ProductListing'}
            successMessage={'Added product listing!'}
            values={{
              supplier: supplier?.id,
            }}
            failureMessage={'Error adding, please try again.'}
            url={'/main/product-listings/'}
            handleForm={handleAddModalForm}
            buttonLabel={'Next'}
            buttonFloatedRight
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenAttributesModal(false)}
        onOpen={() => setOpenAttributesModal(true)}
        open={openAttributesModal}
        closeOnDimmerClick={false}
        floated={'right'}
        size={'mini'}
      >
        <Modal.Header>
          Which {currentAttribute?.name} do you support?
        </Modal.Header>
        <Modal.Content key={state}>
          {currentAttribute?.attribute_selections_json?.map(
            (attributeSelection) => {
              return (
                <InventoryAttributeValue
                  // attribute={currentAttribute}
                  currentAttributeSelections={currentAttributeSelections}
                  setCurrentAttributeSelections={setCurrentAttributeSelections}
                  setAllAttributeSelections={setAllAttributeSelections}
                  attributeSelection={attributeSelection}
                />
              )
            }
          )}
          <br />
          <span
            onClick={selectAll}
            style={{ cursor: 'pointer', float: 'right' }}
          >
            Select All
          </span>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            style={{ marginTop: '10px' }}
            float={'right'}
            loading={buttonLoading}
            size={'small'}
            onClick={handleAddAttributesModalForm}
          >
            Next
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenProductModal(false)}
        onOpen={() => setOpenProductModal(true)}
        open={openProductModal}
        floated={'right'}
        size={'mini'}
      >
        <Modal.Header>Add Product</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'ProductListingForm'}
            model={'ProductListing'}
            successMessage={'Added product listing!'}
            values={{
              supplier: supplier?.id,
            }}
            failureMessage={'Error adding, please try again.'}
            url={'/main/product-listings/'}
            handleForm={handleAddModalForm}
            buttonLabel={'Next'}
            buttonFloatedRight
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenAttributesModal(false)}
        onOpen={() => setOpenAttributesModal(true)}
        open={openAttributesModal}
        closeOnDimmerClick={false}
        floated={'right'}
        size={'mini'}
      >
        <Modal.Header>
          Which {currentAttribute?.name} do you support?
        </Modal.Header>
        <Modal.Content key={state}>
          {currentAttribute?.attribute_selections_json?.map(
            (attributeSelection) => {
              return (
                <InventoryAttributeValue
                  // attribute={currentAttribute}
                  currentAttributeSelections={currentAttributeSelections}
                  setCurrentAttributeSelections={setCurrentAttributeSelections}
                  setAllAttributeSelections={setAllAttributeSelections}
                  attributeSelection={attributeSelection}
                />
              )
            }
          )}
          <br />
          <span
            onClick={selectAll}
            style={{ cursor: 'pointer', float: 'right' }}
          >
            Select All
          </span>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            style={{ marginTop: '10px' }}
            float={'right'}
            loading={buttonLoading}
            size={'small'}
            onClick={handleAddAttributesModalForm}
          >
            Next
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenUploadModal(false)}
        onOpen={() => setOpenUploadModal(true)}
        open={openUploadModal}
      >
        <Modal.Header>Upload Inventory CSV</Modal.Header>
        <Modal.Content>
          <Segment placeholder style={{ minHeight: '41vh' }}>
            <Grid columns={2} relaxed="very" stackable>
              <Grid.Column textAlign={'center'}>
                <p>
                  In order to upload your inventory to Mickey, first download a
                  template based on your current inventory by clicking the
                  button below. If you haven't added any inventory items, please
                  add those first by clicking the "Browse & Add" button after
                  closing this popup.
                </p>
                <Button
                  color={'orange'}
                  content="Download Template"
                  icon="arrow down"
                  onClick={downloadTemplate}
                />
                <CSVLink
                  data={csvTemplateData !== null ? csvTemplateData : ''}
                  filename={`${business?.name} Inventory ${new Date()}.csv`}
                  target="_blank"
                  ref={exportRef}
                />
              </Grid.Column>
              <Grid.Column textAlign={'center'} verticalAlign="middle">
                <Input type={'file'} onChange={handleChange} />
                <Button
                  primary
                  style={{ marginTop: '20px' }}
                  content="Upload Inventory"
                  icon="arrow up"
                  onClick={uploadFunction}
                />
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default Inventory
