import React, { useState } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { format_phone_number } from '../../../lib/utils/utils'

function ContactsRow(props) {
  const [confirmationModal, setConfirmationModal] = useState(false)
  const contact = props.contact

  return (
    <>
      {contact.archived === false ? (
        <>
          <Table.Row>
            <Table.Cell>{contact && contact.name}</Table.Cell>
            <Table.Cell>{contact && contact.contact_first_name}</Table.Cell>
            <Table.Cell>{contact && contact.contact_last_name}</Table.Cell>
            <Table.Cell>{contact && contact.contact_email}</Table.Cell>
            <Table.Cell>
              {contact && format_phone_number(contact.contact_phone)}
            </Table.Cell>
            <Table.Cell width={1}>
              <Icon name="close" onClick={(e) => setConfirmationModal(true)} />
            </Table.Cell>
          </Table.Row>
          <ConfirmationModal
            yesFunction={(e) => props.archiveContactInstance(contact.id)}
            noFunction={() => setConfirmationModal(false)}
            confirmationModal={confirmationModal}
            setConfirmationModal={setConfirmationModal}
            archiveItem={`${contact?.contact_first_name} ${contact?.contact_last_name}`}
          />
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default ContactsRow
