import { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Header,
  Loader,
  Input,
  Icon,
  Divider,
  Modal,
  Dropdown,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
import './ExchangeTable.css'
import env from '../../../../../env'
import { CaretDown } from 'phosphor-react'
import { useSelector } from 'react-redux'
import { useWebsocket } from '../../../../../hooks/useWebsocket'
import { track } from '@amplitude/analytics-browser'
import moment from 'moment-timezone'
import { us_timezones } from '../../../../../helpers/helpers'
import { hasPricing, inGroup } from '../../../../../lib/utils/utils'

const EditOrderModal = (props) => {
  const {
    setOpenEditBidAskModal,
    openEditBidAskModal,
    editingOrder,
    editingExchangeOrderBook,
    setEditingOrder,
    setEditingExchangeOrderBook,
    globalSettings,
    user,
    business,
    setReloadExchange,
    setExchangeTableLoaded,
    setActionBeforeWsReset,
    setInputExpiration,
    inputExpiration,
    is_admin,
    currentPortal,
    inputPrice,
    productVariant,
  } = props
  const [editedQuantity, setEditedQuantity] = useState(null)
  const [editedPrice, setEditedPrice] = useState(null)
  const [editedSecondaryPrice, setEditedSecondaryPrice] = useState(null)
  const { ws, resetWs, editOrderWsMessage } = useWebsocket()

  const [loadingEditBid, setLoadingEditBid] = useState(false)
  const tenant = useSelector((state) => state.globals.tenant)
  const reduxUser = useSelector((state) => state.user.user)

  function handleEditBidAskModal() {
    setLoadingEditBid(true)
    if (ws && ws.readyState === 1) {
      let orderId = editingOrder.id
      let expires_at = inputExpiration
      if (inputExpiration === 'End of Day') {
        expires_at = moment()
          .tz(us_timezones[user?.current_location?.state])
          .endOf('day')
          .toISOString()
      }
      if (inputExpiration === 'Good Till Cancelled') {
        expires_at = null
      }
      const update_order = {
        order_id: orderId,
        quantity: editedQuantity,
        price: editedPrice,
        status: 'open',
      }
      if (
        currentPortal === 'buyer' &&
        hasPricing(reduxUser, 'primary') &&
        !inGroup(user, 'Buyer') &&
        !inGroup(user, 'Supplier')
      ) {
        update_order.secondary_price = editedSecondaryPrice
      }
      let message = {
        tenant_id: tenant.id,
        update_order: [update_order],
      }
      if (expires_at) {
        message['update_order'][0]['expires_at'] = expires_at
      }
      track('Edit Bid', {
        'User ID': user?.id,
        'Business Name': business?.name,
      })
      if (editedPrice < 0) {
        toast.error('Please enter valid price')
      } else if (editedQuantity < 0) {
        toast.error('Please enter valid quantity')
      } else {
        ws.send(editOrderWsMessage(message))
        setLoadingEditBid(false)
        setExchangeTableLoaded(false)
        toast.success('Updated bid successfully!')
        setOpenEditBidAskModal(false)
        setEditedQuantity(null)
        setEditedPrice(null)
        setEditingOrder()
        setEditingExchangeOrderBook()
      }
      if (is_admin) {
        setReloadExchange(true)
      }
    } else {
      resetWs()
      setActionBeforeWsReset(handleEditBidAskModal)
    }
    setLoadingEditBid(false)
  }

  useEffect(() => {
    if (editingOrder) {
      setEditedPrice(editingOrder.price)
      setEditedSecondaryPrice(editingOrder.secondary_price)
      setEditedQuantity(editingOrder.quantity)
    }
  }, [editingOrder])

  return (
    <>
      <Modal
        onOpen={() => setOpenEditBidAskModal(true)}
        onClose={() => setOpenEditBidAskModal(false)}
        open={openEditBidAskModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Edit {currentPortal === 'supplier' ? 'Sell' : 'Buy'}
            </Header>
            <Header.Subheader
              as={'h4'}
              style={{
                textAlign: 'center',
                marginTop: '0',
                marginBottom: '.5rem',
              }}
            >
              {editingExchangeOrderBook &&
                editingExchangeOrderBook?.product_variant.product.material.name}
              {', '}
              {editingExchangeOrderBook?.product_variant &&
                editingExchangeOrderBook.product_variant.attribute_selections.map(
                  (attribute, index) => {
                    let check =
                      index !==
                      editingExchangeOrderBook.product_variant
                        .attribute_selections.length -
                        1
                    return attribute.attribute_selection_value + check
                      ? ', '
                      : ''
                  }
                )}
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              // overflow: "scroll",
              justifyContent: 'center',
            }}
          >
            <Grid style={{ padding: '2rem 0 0 0' }}>
              <Grid.Column>
                {props.currentPortal === 'buyer' &&
                  hasPricing(user, 'primary') &&
                  !inGroup(user, 'Buyer') && (
                    <>
                      <Header as={'h4'} textAlign="center">
                        Primary Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          defaultValue={inputPrice}
                          value={editedPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            if (d.value < 0) {
                              toast.error('Price must be greater than 0')
                              setEditedPrice(null)
                            } else if (
                              globalSettings?.round_exchange_order_prices &&
                              d.value % 1 !== 0
                            ) {
                              toast.error('Price must be a whole number')
                              setEditedPrice(null)
                            } else if (d.value > 999999) {
                              toast.error('Price must be less than 1000000')
                              setEditedPrice(null)
                            } else {
                              setEditedPrice(d.value)
                            }
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {productVariant?.unit_name
                            ? productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                      <Divider />
                      <Header as={'h4'} textAlign="center">
                        Secondary Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          value={editedSecondaryPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            if (d.value < 0) {
                              toast.error('Sales price must be greater than 0')
                              setEditedSecondaryPrice(null)
                            } else if (
                              globalSettings?.round_exchange_order_prices &&
                              d.value % 1 !== 0
                            ) {
                              toast.error('Sales price must be a whole number')
                              setEditedSecondaryPrice(null)
                            } else if (d.value > 999999) {
                              toast.error(
                                'Sales price must be less than 1000000'
                              )
                              setEditedSecondaryPrice(null)
                            } else {
                              setEditedSecondaryPrice(d.value)
                            }
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {productVariant?.unit_name
                            ? productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                    </>
                  )}
                {(props.currentPortal === 'supplier' ||
                  (props.currentPortal === 'buyer' &&
                    hasPricing(user, 'primary') &&
                    inGroup(user, 'Buyer'))) && (
                  <>
                    <Header as={'h4'} textAlign="center">
                      Your Price
                    </Header>
                    <div style={{ position: 'relative' }}>
                      <Input
                        icon={
                          <Icon
                            name="dollar"
                            size="big"
                            style={{ width: '20px' }}
                          />
                        }
                        defaultValue={inputPrice}
                        value={editedPrice}
                        iconPosition={'left'}
                        className="price-modal-input"
                        type={'number'}
                        onChange={(e, d) => {
                          if (d.value < 0) {
                            toast.error('Price must be greater than 0')
                            setEditedPrice(null)
                          } else if (
                            globalSettings?.round_exchange_order_prices &&
                            d.value % 1 !== 0
                          ) {
                            toast.error('Price must be a whole number')
                            setEditedPrice(null)
                          } else if (d.value > 999999) {
                            toast.error('Price must be less than 1000000')
                            setEditedPrice(null)
                          } else {
                            setEditedPrice(d.value)
                          }
                        }}
                        step={'1'}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          color: 'grey',
                          right: '0',
                          top: '0',
                          bottom: '0',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '1rem',
                        }}
                      >
                        /
                        {productVariant?.unit_name
                          ? productVariant?.unit_name
                          : 'MBF'}
                      </div>
                    </div>
                  </>
                )}
                {props.currentPortal === 'buyer' &&
                  !hasPricing(user, 'primary') && (
                    <>
                      <Header as={'h4'} textAlign="center">
                        Your Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          value={editedSecondaryPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            if (d.value < 0) {
                              toast.error('Sales price must be greater than 0')
                              setEditedSecondaryPrice(null)
                            } else if (
                              globalSettings?.round_exchange_order_prices &&
                              d.value % 1 !== 0
                            ) {
                              toast.error('Sales price must be a whole number')
                              setEditedSecondaryPrice(null)
                            } else if (d.value > 999999) {
                              toast.error(
                                'Sales price must be less than 1000000'
                              )
                              setEditedSecondaryPrice(null)
                            } else {
                              setEditedSecondaryPrice(d.value)
                            }
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {productVariant?.unit_name
                            ? productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                    </>
                  )}

                <Divider />
                <Header as={'h4'} textAlign="center">
                  Quantity
                </Header>
                <div style={{ position: 'relative' }}>
                  <Input
                    icon={
                      <Icon name="truck" size="big" style={{ width: '20px' }} />
                    }
                    iconPosition={'left'}
                    value={editedQuantity}
                    className="price-modal-input"
                    type={'number'}
                    onChange={(e, d) => {
                      if (d.value < 0) {
                        toast.error('Quantity must be greater than 0')
                        setEditedQuantity(null)
                      } else if (d.value % 1 !== 0) {
                        toast.error('Quantity must be a whole number')
                        setEditedQuantity(null)
                      } else if (d.value > 999999) {
                        toast.error('Quantity must be less than 1000000')
                        setEditedQuantity(null)
                      } else {
                        setEditedQuantity(d.value)
                      }
                    }}
                    step={1}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      color: 'grey',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: '1rem',
                    }}
                  >
                    Trucks
                  </div>
                </div>
                <Divider />
                <Header as={'h4'} textAlign="center">
                  Expiration
                </Header>
                <div style={{ position: 'relative', overflow: 'visible' }}>
                  <Dropdown
                    id="expiration-dropdown"
                    icon={<CaretDown style={{ float: 'right' }} />}
                    placeholder="Select Expiration"
                    fluid
                    selection
                    defaultValue={inputExpiration}
                    style={{
                      marginBottom: '1rem',
                      maxWidth: '100%',
                      zIndex: '1001',
                    }}
                    options={[
                      {
                        text: 'End of Day',
                        value: 'End of Day',
                      },
                      {
                        text: 'Good Till Cancelled',
                        value: 'Good Till Cancelled',
                      },
                    ]}
                    onChange={(e, d) => setInputExpiration(d.value)}
                  />
                </div>
                <Divider />
              </Grid.Column>
            </Grid>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              loading={loadingEditBid}
              disabled={!editedPrice || !editedQuantity || !inputExpiration}
              color="primary"
              onClick={() => handleEditBidAskModal()}
              clearable
            >
              {loadingEditBid && <Loader size="tiny" inline="centered" />}
              {!loadingEditBid && 'Update'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default EditOrderModal
