import React, { useState, useEffect } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import { format_phone_number } from '../../../lib/utils/utils'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

const ManageUsersRow = (props) => {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update User')
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState('')
  useEffect(() => {
    if (props?.editUserId && props.user?.id === props?.editUserId) {
      setOpenEditUserModal(true)
      props?.setEditUserId(null)
    }
  }, [])

  const deleteUser = async () => {
    setOpenDeleteUserModal(false)
    await axios
      .patch(`/main/custom-users/${props.user?.id}/?tenant_aware=false`, {
        archive_user_id: deleteUserId,
      })
      .then(() => {
        toast.success('Successfully deleted user')
      })
      .finally(() => setDeleteUserId(''))
    props.setReload(true)
  }

  const makeBusinessContact = async () => {
    await axios.patch(`/main/custom-users/${props.user.id}/`, {
      make_business_contact: true,
    })
    props.setReload(true)
  }

  async function resendUserInvite() {
    const res = await axios.patch(
      `/main/custom-users/${props.user?.id}/resend_user_invite/?tenant_aware=false`,
      {
        business_id: props?.business?.id,
      }
    )
    if (!res?.data?.error) {
      toast.success('Resent User Email!')
      props.setReload(true)
    } else {
      toast.error(res?.data?.error ? res.data.error : 'Error inviting user')
    }
  }

  return (
    <>
      <Table.Row>
        <Table.Cell onClick={() => setOpenEditUserModal(true)}>
          {props.user?.first_name} {props.user?.last_name}
        </Table.Cell>
        <Table.Cell onClick={() => setOpenEditUserModal(true)}>
          {props.user?.email}
        </Table.Cell>
        <Table.Cell onClick={() => setOpenEditUserModal(true)}>
          {format_phone_number(props.user?.phone)}
        </Table.Cell>
        <Table.Cell onClick={() => setOpenEditUserModal(true)}>
          {props.user?.created_at}
        </Table.Cell>
        <Table.Cell
          style={{
            maxWidth: '175px',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            {canUpdate && (
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: 'green',
                  padding: '0',
                  fontWeight: '600',
                  marginRight: '10px',
                }}
                onClick={() => {
                  resendUserInvite(props.user?.id)
                }}
              >
                Resend Invite
              </button>
            )}
            {hasPermission(user, 'Delete User') && (
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: 'indianred',
                  padding: '0',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setOpenDeleteUserModal(true)
                  setDeleteUserId(props.user?.id)
                }}
              >
                Delete User
              </button>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {props.user?.business_contact ? (
            <>Yes</>
          ) : (
            <>
              {canUpdate && (
                <div>
                  <button
                    style={{
                      backgroundColor: 'transparent',
                      color: 'green',
                      padding: '0',
                      fontWeight: '600',
                    }}
                    onClick={() => {
                      makeBusinessContact()
                    }}
                  >
                    Make Contact
                  </button>
                </div>
              )}
            </>
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => {
          setOpenEditUserModal(false)
          props.setReload(true)
        }}
        onOpen={() => setOpenEditUserModal(true)}
        open={openEditUserModal}
      >
        <Modal.Header>
          Edit {props.user?.first_name} {props.user?.last_name}
        </Modal.Header>
        <p
          style={{
            padding: '1.25rem 1.5rem',
            margin: '0',
          }}
        >
          Changes will update automatically. When you're finished click the
          Close button below.
        </p>
        <Modal.Content>
          <MickeyForm
            formName={'CustomUserForm'}
            model={'CustomUser'}
            disabled={!canUpdate}
            url={'/main/custom-users/'}
            instanceId={props?.user?.id}
            tenantAware={false}
            urlParams={{
              business_id: props.business?.id,
            }}
          />
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button
            onClick={() => {
              setOpenEditUserModal(false)
              props.setReload(true)
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenDeleteUserModal(false)}
        onOpen={() => setOpenDeleteUserModal(true)}
        open={openDeleteUserModal}
      >
        <Modal.Header>Delete User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button onClick={() => deleteUser()}>Yes</Button>
          <Button onClick={() => setOpenDeleteUserModal(false)}>No</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ManageUsersRow
