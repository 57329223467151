import React, { useState } from 'react'
import { Table, Label } from 'semantic-ui-react'
import { formatTotalPrice, formatQuantities } from '../../lib/utils/utils'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import SettlementStatementModal from './SettlementStatementModal'

function SettlementStatementRow(props) {
  const { settlementStatement, setReload, reload } = props
  const user = useSelector((state) => state.user.user)
  const [openDetailModal, setOpenDetailModal] = useState(false)

  const hasSignRequest = settlementStatement.box_sign_requests?.length > 0
  let signRequest
  if (hasSignRequest) {
    signRequest = settlementStatement.box_sign_requests[0]
  }
  return (
    <>
      <Table.Row onClick={() => setOpenDetailModal(true)}>
        <Table.Cell>{settlementStatement?.start_date}</Table.Cell>
        <Table.Cell>{settlementStatement?.end_date}</Table.Cell>
        <Table.Cell>{settlementStatement?.supplier?.business?.name}</Table.Cell>
        <Table.Cell>
          {settlementStatement?.net_weight_total &&
            formatQuantities(settlementStatement?.net_weight_total)}
        </Table.Cell>
        <Table.Cell>
          {settlementStatement?.total_amount &&
            formatTotalPrice(settlementStatement?.total_amount)}
        </Table.Cell>
        {hasPermission(user, 'Read Document') && (
          <Table.Cell>
            {hasSignRequest && (
              <>
                {signRequest.sign_status === 'pending_internal_review' && (
                  <>
                    <Label
                      style={{ color: '#1E90FF', backgroundColor: '#87CEFA' }}
                    >
                      Pending Internal Review
                    </Label>
                  </>
                )}
                {signRequest.sign_status === 'sent' && (
                  <>
                    <Label
                      style={{ color: 'orange', backgroundColor: '#FFDAB9' }}
                    >
                      Sent for Signature
                    </Label>
                  </>
                )}
                {signRequest.sign_status === 'signed' && (
                  <>
                    <Label
                      style={{ color: 'green', backgroundColor: '#CCFFCC' }}
                    >
                      Complete
                    </Label>
                  </>
                )}
                {signRequest.sign_status === 'declined' && (
                  <>
                    <Label style={{ color: 'red', backgroundColor: '#FFB6C1' }}>
                      Declined
                    </Label>
                  </>
                )}
              </>
            )}
          </Table.Cell>
        )}
      </Table.Row>
      <SettlementStatementModal
        settlementStatement={settlementStatement}
        openModal={openDetailModal}
        reload={reload}
        setReload={setReload}
        setOpenModal={setOpenDetailModal}
      />
    </>
  )
}

export default SettlementStatementRow
