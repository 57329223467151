import { Checks, TrashSimple } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { Header, Modal, Segment, Table, Button } from 'semantic-ui-react'
import './AdminOrder.css'
import Checkbox from '@mui/material/Checkbox'
import { green } from '@mui/material/colors'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import useAxios from '../../../lib/auth/useAxios'
import env from '../../../env'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import MickeyForm from '../../../components/Forms/MickeyForm'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
      <Box sx={{ paddingRight: '10px' }}>
        <Typography
          variant="body2"
          style={{
            color: props?.value >= 100 ? env.REACT_APP_PRIMARY_COLOR : 'black',
            fontWeight: props?.value >= 100 ? 'bold' : '',
          }}
        >
          {props.fraction}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          style={{
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          }}
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  )
}

function AdminOrderTasks(props) {
  const axios = useAxios()

  const [orderTasks, setOrderTasks] = useState([])
  const [orderTaskCompleted, setOrderTaskCompleted] = useState(0)
  const [reloadOrderTasks, setReloadOrderTasks] = useState(false)

  const [fulfillmentTasks, setFulfillmentTasks] = useState([])
  const [fulfillmentTaskCompleted, setFulfillmentTaskCompleted] = useState(0)
  const [activeAccordion, setActiveAccordion] = useState(null)

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false)

  async function removeTask(taskId) {
    let res = await axios.delete(`/main/admin-tasks/${taskId}/`)
    setReloadOrderTasks(true)
  }

  function updateTaskCompletion(task, index) {
    let newVal = !task.complete
    let orderTasksCopy = [...orderTasks]
    orderTasksCopy.forEach((t) => {
      if (task.id === t.id) {
        t.complete = newVal
      }
    })
    props.setOrder((prevState) => ({
      ...prevState,
      task_count:
        newVal === true ? prevState.task_count - 1 : prevState.task_count + 1,
    }))
    setOrderTasks(orderTasksCopy)
    axios.patch(`/main/admin-tasks/${task?.id}/`, {
      complete: newVal,
    })
  }

  function updateFulfillmentTaskCompletion(task) {
    let newVal = !task.complete
    let fulfillmentTaskCopy = [...fulfillmentTasks]
    fulfillmentTaskCopy.forEach((t) => {
      t.tasks.forEach((subT) => {
        if (task.id === subT.id) {
          subT.complete = newVal
          let numCompletedTasks = t.tasks.filter(
            (compTs) => compTs.complete
          ).length
          t.complete = numCompletedTasks === t?.tasks?.length
        }
      })
    })
    setFulfillmentTasks(fulfillmentTaskCopy)
    let tasksCompleted = fulfillmentTaskCopy?.filter(
      (task) => task?.complete
    )?.length
    setFulfillmentTaskCompleted(tasksCompleted)
    axios.patch(`/main/admin-tasks/${task?.id}/`, {
      complete: newVal,
    })
  }

  useEffect(() => {
    if (orderTasks?.length > 0) {
      let totalCompleted = 0
      orderTasks?.forEach((task) => {
        if (task.complete) {
          totalCompleted += 1
        }
      })
      setOrderTaskCompleted(totalCompleted)
      props.setOrder((prevState) => ({
        ...prevState,
        task_count: orderTasks?.length - totalCompleted,
      }))
    } else {
      setOrderTaskCompleted(0)
    }
  }, [orderTasks])

  async function getOrderTasks() {
    let res = await axios.get(`/main/admin-tasks/`, {
      params: {
        order_id: props?.order?.id,
        order_page: 'true',
      },
    })
    setOrderTasks(res?.data?.results)
    setReloadOrderTasks(false)
  }

  useEffect(() => {
    if (props?.order?.id) {
      getOrderTasks()
    }
  }, [props?.order?.id])

  useEffect(() => {
    if (reloadOrderTasks) {
      getOrderTasks()
    }
  }, [reloadOrderTasks])

  useEffect(() => {
    if (props?.order?.id) {
      async function getFulfillmentTasks() {
        let res = await axios.get(`/main/admin-tasks/`, {
          params: {
            order_id: props?.order?.id,
            fulfillment_only: true,
          },
        })
        let stackedTasks = {}
        res?.data?.results?.forEach((task) => {
          if (task?.name in stackedTasks) {
            stackedTasks[task?.name]['tasks'].push(task)
            if (stackedTasks[task?.name]['complete'] && !task.complete) {
              stackedTasks[task?.name]['complete'] = false
            }
          } else {
            stackedTasks[task?.name] = {
              tasks: [task],
              complete: task?.complete,
              name: task?.name,
            }
          }
        })
        let vals = Object.keys(stackedTasks).map(function (key) {
          return stackedTasks[key]
        })
        let tasksCompleted = vals?.filter((task) => task?.complete)?.length
        setFulfillmentTasks(vals)
        setFulfillmentTaskCompleted(tasksCompleted)
      }
      getFulfillmentTasks()
    }
  }, [props?.order?.id])

  return (
    <>
      <Segment>
        <Header style={{ padding: '20px' }}>
          Order Tasks
          <Box
            sx={{
              width: '200px',
              display: 'inline-block',
              marginLeft: '25px',
              marginTop: '-5px',
            }}
          >
            <LinearProgressWithLabel
              fraction={`${orderTaskCompleted}/${orderTasks?.length}`}
              color={'success'}
              variant="determinate"
              value={(orderTaskCompleted / orderTasks?.length) * 100}
            />
          </Box>
          <Button
            onClick={() => {
              setOpenAddTaskModal(true)
            }}
            color={'primary'}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              float: 'right',
            }}
            content={'Add Task'}
          />
        </Header>
        <MickeyTable
          headerRow={['', 'Description', 'Assigned To', 'Due Date', '']}
          style={{
            marginTop: '0px',
            margin: '5px',
            clear: 'right',
            fontSize: '1.3rem',
          }}
          basicSegment
          data={orderTasks}
          renderBodyRow={(data, index) => {
            return (
              <Table.Row>
                <Table.Cell>
                  <Checkbox
                    checked={data?.complete}
                    sx={{
                      color: green[800],
                      '&.Mui-checked': {
                        color: green[600],
                      },
                      '& .MuiSvgIcon-root': { fontSize: 28 },
                    }}
                    onClick={(e, d) => {
                      updateTaskCompletion(data)
                    }}
                  />
                </Table.Cell>
                <Table.Cell>{data?.name}</Table.Cell>
                <Table.Cell>
                  {data?.assignee?.first_name} {data?.assignee?.last_name}
                </Table.Cell>
                <Table.Cell>{data?.due_date}</Table.Cell>
                <Table.Cell>
                  {data?.removable && (
                    <TrashSimple
                      className={'grey-hover'}
                      onClick={() => {
                        removeTask(data?.id)
                      }}
                      size={20}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            )
          }}
          emptyIconHtml={<Checks size={40} />}
          emptyMessage={'No order tasks in this order.'}
        />
      </Segment>
      <Segment>
        <Header style={{ padding: '20px' }}>
          Fulfillment Tasks
          <Box
            sx={{
              width: '200px',
              display: 'inline-block',
              marginLeft: '25px',
              marginTop: '-5px',
            }}
          >
            <LinearProgressWithLabel
              fraction={`${fulfillmentTaskCompleted}/${fulfillmentTasks?.length}`}
              color={'success'}
              variant="determinate"
              value={
                (fulfillmentTaskCompleted / fulfillmentTasks?.length) * 100
              }
            />
          </Box>
        </Header>
        {fulfillmentTasks?.length <= 0 && (
          <MickeyPlaceholder
            iconHtml={<Checks size={40} />}
            message={'No fulfillment tasks in this order.'}
          />
        )}
        {fulfillmentTasks?.map((task, idx) => {
          return (
            <Accordion
              expanded={idx === activeAccordion}
              onChange={(e, d) => {
                setActiveAccordion(idx === activeAccordion ? null : idx)
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <Checkbox
                    checked={task?.complete}
                    sx={{
                      color: green[800],
                      '&.Mui-checked': {
                        color: green[600],
                      },
                      '& .MuiSvgIcon-root': { fontSize: 28 },
                    }}
                    onChange={() => {
                      task?.tasks?.map((t) => {
                        updateFulfillmentTaskCompletion(t)
                      })
                      setActiveAccordion(idx)
                    }}
                  />
                  {task?.name}

                  <Box
                    sx={{
                      width: '200px',
                      display: 'inline-block',
                      marginLeft: '25px',
                      marginTop: '-5px',
                    }}
                  >
                    <LinearProgressWithLabel
                      fraction={`${
                        task.tasks.filter((t) => t.complete).length
                      }/${task.tasks?.length}`}
                      color={'success'}
                      variant="determinate"
                      value={
                        (task.tasks.filter((t) => t.complete).length /
                          task.tasks?.length) *
                        100
                      }
                    />
                  </Box>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{ borderBottom: 'none', boxShadow: 'none' }}
              >
                <Table style={{ borderLeft: 'none', borderRight: 'none' }}>
                  {task?.tasks?.map((t) => {
                    return (
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            checked={t?.complete}
                            sx={{
                              color: green[800],
                              '&.Mui-checked': {
                                color: green[600],
                              },
                              '& .MuiSvgIcon-root': { fontSize: 28 },
                            }}
                            onClick={(e, d) => {
                              updateFulfillmentTaskCompletion(t)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {/* <Link */}
                          {/*   to={`/portal/admin/orders/${props.order?.string_token}`} */}
                          {/*   style={{ cursor: 'pointer' }} */}
                          {/* > */}
                          {t?.task_group?.cargo_unit_instance?.string_token}
                          {t?.task_group?.cargo_unit_instance
                            ?.shipper_load_id && (
                            <>
                              {' '}
                              (Load ID #
                              {
                                t?.task_group?.cargo_unit_instance
                                  ?.shipper_load_id
                              }
                              )
                            </>
                          )}
                          {/* </Link> */}
                        </Table.Cell>
                        <Table.Cell>
                          {t?.assignee?.first_name} {t?.assignee?.last_name}
                        </Table.Cell>
                        <Table.Cell>{t?.due_date}</Table.Cell>
                        <Table.Cell>
                          {t?.removable && (
                            <TrashSimple
                              className={'grey-hover'}
                              onClick={() => {
                                removeTask(t?.id)
                              }}
                              size={20}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </Segment>
      <Modal
        onClose={() => setOpenAddTaskModal(false)}
        onOpen={() => setOpenAddTaskModal(true)}
        open={openAddTaskModal}
      >
        <Modal.Header>Add A New Order Task</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AddTaskForm'}
            model={'Task'}
            url={'/main/admin-tasks/'}
            tenantAware={false}
            values={{
              order_id: props?.order?.id,
              removable: true,
            }}
            handleForm={() => {
              setReloadOrderTasks(true)
              setOpenAddTaskModal(false)
            }}
            buttonLabel={'Add Task'}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default AdminOrderTasks
