import PurchaseOrders from '../../../components/PurchaseOrders/PurchaseOrders'

function ProfilePurchaseOrders(props) {
  const { business, openAddPurchaseOrderModal, setOpenAddPurchaseOrderModal } =
    props

  return (
    <div style={{ margin: '0px' }}>
      <PurchaseOrders
        hideHeader={true}
        hideBusiness={true}
        business={business}
        openAddPurchaseOrderModal={openAddPurchaseOrderModal}
        setOpenAddPurchaseOrderModal={setOpenAddPurchaseOrderModal}
      />
    </div>
  )
}

export default ProfilePurchaseOrders
