import { requiredImageFileValidator } from '../components/Forms/validators'

export const NewsForm = {
  model: 'News',
  format: [
    {
      widths: 'equal',
      fields: {
        title: {
          type: 'text',
          required: true,
          label: 'Title',
        },
        article_link: {
          type: 'text',
          required: true,
          label: 'Article Link',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        publish_date: {
          type: 'date',
          required: true,
          label: 'Publish Date',
        },

        logo: {
          type: 'file',
          required: true,
          label: 'Logo',
          validator: (input) => {
            return requiredImageFileValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        description: {
          type: 'text area',
          required: true,
          label: 'Description',
        },
      },
    },
  ],
}
