import { Table, Button } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const AdminOrderRequestRow = (props) => {
  const user = useSelector((state) => state.user.user)
  const axios = useAxios()

  const updateRequest = async (status) => {
    let res = await axios.patch(`/main/buyer-requests/${props.request?.id}/`, {
      status: status,
    })
    if (res?.data) {
      props?.setReload(true)
    }
  }

  return (
    <>
      <Table.Row selectable onClick={props.onClick}>
        <Table.Cell>{props.request?.created_at}</Table.Cell>
        <Table.Cell>
          {props.request?.user?.first_name} {props.request?.user?.last_name}
        </Table.Cell>
        <Table.Cell>{props.request?.date}</Table.Cell>
        <Table.Cell>
          <OrderItemDetailTwo
            orderItem={{
              product_variant: props?.request?.product_variant,
            }}
          />
        </Table.Cell>
        <Table.Cell>{props.request?.quantity}</Table.Cell>
        <Table.Cell>{props.request?.user_note}</Table.Cell>
        <Table.Cell>{props.request?.status}</Table.Cell>
        {hasPermission(user, 'Update Buyer Request') && (
          <Table.Cell>
            <Button
              color="green"
              disabled={props.request?.status !== 'Pending'}
              onClick={() => updateRequest('Approved')}
            >
              Approve
            </Button>
            <Button
              color="red"
              disabled={props.request?.status !== 'Pending'}
              onClick={() => updateRequest('Denied')}
            >
              Deny
            </Button>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  )
}

export default AdminOrderRequestRow
