import React from 'react'
import PriceHistoryTable from '../../../components/PriceHistory/PriceHistoryTable'

const AdminPriceHistory = () => {
  return (
    <div style={{ margin: '25px', marginLeft: '40px' }}>
      <PriceHistoryTable />
    </div>
  )
}

export default AdminPriceHistory
