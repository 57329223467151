import { Modal } from 'semantic-ui-react'
import DealForm from './DealForm'

function DealModal(props) {
  const { openModal, closeModal, supplier } = props

  return (
    <>
      <Modal
        className="add-sales-order"
        size={'large'}
        open={openModal}
        onClose={closeModal}
      >
        <Modal.Header>
          <div>
            <span>Add Deal</span>
          </div>
        </Modal.Header>
        <Modal.Content>
          <DealForm supplier={supplier} />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default DealModal
