import React, { useState, useEffect } from 'react'
import { Grid, Modal, Sidebar } from 'semantic-ui-react'
import useAxios from '../../../../lib/auth/useAxios'
import '../AdminDashboard.css'
import { toast } from 'react-toastify'
import MickeyForm from '../../../../components/Forms/MickeyForm'
import MickeyTable from '../../../../components/MickeyTable/MickeyTable'
import AdminOrderBookRow from '../AdminOrderBookRow'
import ProductFilter from '../../../mx/mx_shared/MXExchange/ProductFilter/ProductFilter'
import PageHeader from '../../../../components/PageHeader/PageHeader'

function AdminOrderBooks(props) {
  const [laneModalOpen, setLaneModalOpen] = useState(false)
  const [filter, setFilter] = useState([])
  const [materialFilter, setMaterialFilter] = useState([])
  const [attributes, setAttributes] = useState([])
  const [businessFilter, setBusinessFilter] = useState([])
  const [activeAttributeIndex, setActiveAttributeIndex] = useState(0)
  const [filterSelections, setFilterSelections] = useState([])
  const axios = useAxios()
  const [tableLoaded, setTableLoaded] = useState(true)
  const [orderBookData, setOrderBookData] = useState([])
  const [orderBookTotalPages, setOrderBookTotalPages] = useState()
  const [orderBookActivePage, setOrderBookActivePages] = useState(1)
  const [customFilterLoading, setCustomFilterLoading] = useState(true)

  useEffect(() => {
    if (
      !props.product &&
      props.selectedCommodity &&
      props.selectedCommodity?.commodity_forms?.length > 0
    ) {
      props.setSelectedCommodityForm(
        props.selectedCommodity?.commodity_forms[0]
      )
    }
  }, [props.selectedCommodity])

  useEffect(() => {
    let didCancel = false
    const getAdminOrderBooks = async () => {
      setTableLoaded(false)
      let res = await axios.get('/main/admin-order-books/', {
        params: {
          page: orderBookActivePage,
          filter: filter,
          materialFilter: materialFilter,
          businessFilter: businessFilter,
          disclude_edges: true,
          disclude_orders: true,
        },
      })
      if (res && res?.data) {
        setOrderBookTotalPages(res.data.total_pages)
        setOrderBookData(res.data.results)
        setTableLoaded(true)
      }
    }
    getAdminOrderBooks()
    return () => {
      didCancel = true
    }
  }, [
    filter,
    materialFilter,
    businessFilter,
    filterSelections,
    orderBookActivePage,
  ])

  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              icon={'book'}
              header={'Order Books'}
              subheader={
                'Product variants with at least one active bid and ask.'
              }
              showDivider
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <Sidebar.Pushable id="exchange-pushable">
              <Sidebar
                id="pushable-sidebar"
                animation="overlay"
                icon="labeled"
                inverted="true"
                vertical="true"
                visible={true}
                width="wide"
              >
                <ProductFilter
                  watchlist={true}
                  showAdminBusiness={true}
                  businessFilter={businessFilter}
                  setBusinessFilter={setBusinessFilter}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  filter={filter}
                  setFilter={setFilter}
                  materialFilter={materialFilter}
                  setMaterialFilter={setMaterialFilter}
                  activeAttributeIndex={activeAttributeIndex}
                  setActiveAttributeIndex={setActiveAttributeIndex}
                  filterSelections={filterSelections}
                  setFilterSelections={setFilterSelections}
                  setCustomFilterLoading={setCustomFilterLoading}
                />
              </Sidebar>
              <MickeyTable
                headerRow={[
                  'Order ID',
                  'Product',
                  'Variant',
                  'Bids',
                  'Asks',
                  '',
                ]}
                basicSegment
                style={{
                  marginLeft: '290px',
                  marginTop: '10px',
                  padding: '15px',
                }}
                data={orderBookData}
                totalPages={orderBookTotalPages}
                activePage={orderBookActivePage}
                setActivePage={setOrderBookActivePages}
                loaded={tableLoaded && customFilterLoading}
                renderBodyRow={(data, index) => {
                  return (
                    <AdminOrderBookRow
                      orderBook={data}
                      index={index}
                      onClick={() => {}}
                      key={data ? data.id : null}
                    />
                  )
                }}
                emptyIcon={'file'}
                emptyMessage={'Order books will populate here'}
              />
            </Sidebar.Pushable>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        onClose={() => setLaneModalOpen(false)}
        onOpen={() => setLaneModalOpen(true)}
        open={laneModalOpen}
      >
        <Modal.Header>Add Lane To Daily Tracking</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'TruckingLaneForm'}
            model={'TruckingLane'}
            loadingHeight={'150px'}
            tenantAware={false}
            url={'/main/admin-trucking-lanes/'}
            handleForm={(status, data) => {
              if (status === 'success') {
                setLaneModalOpen(false)
              } else {
                toast.error('Error adding lane')
              }
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminOrderBooks
