import { Chats } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import './AdminOrder.css'
import AdminOrderRequestRow from './AdminOrderRequestRow'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function AdminOrderRequests(props) {
  const [reload, setReload] = useState(true)
  const user = useSelector((state) => state.user.user)

  useEffect(() => {
    setReload(true)
  }, [props.order])

  const headerRow = [
    'Requested Date',
    'User',
    'Date',
    'Product',
    'Quantity',
    'Buyer Note',
    'Status',
  ]

  if (hasPermission(user, 'Update Buyer Request')) {
    headerRow.push('Action')
  }

  return (
    <MickeyTable
      headerRow={headerRow}
      style={{ marginTop: '5px', margin: '10px', textAlign: 'center' }}
      basicSegment
      url={'/main/buyer-requests/'}
      urlParams={{
        order_id: props.order?.id,
      }}
      reload={reload}
      setReload={setReload}
      renderBodyRow={(data, index) => {
        return (
          <AdminOrderRequestRow
            request={data}
            index={index}
            key={data ? data.id : null}
            setReload={setReload}
          />
        )
      }}
      emptyIconHtml={<Chats size={40} />}
      emptyMessage={'Buyer requests will populate here'}
    />
  )
}

export default AdminOrderRequests
