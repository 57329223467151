import { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Header,
  Table,
  Checkbox,
  Breadcrumb,
  Pagination,
  Dropdown,
  Dimmer,
  Popup,
  GridRow,
  Icon,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
import useAxios from '../../../../../lib/auth/useAxios'
import { track } from '@amplitude/analytics-browser'
import { groupBy } from 'underscore'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import './ExchangeTable.css'
import useWindowDimensions from '../../../../../hooks/useWindowDimensions'
import env from '../../../../../env'
import { useLocation, useNavigate } from 'react-router-dom'
import MickeyPlaceholder from '../../../../../components/Placeholder/Placeholder'
import { addUser } from '../../../../../features/user/userSlice'
import MickeyPlaceholderLoading from '../../../../../components/PlaceholderLoading/PlaceholderLoading'
import ProductIcon from '../../../../../components/ProductIcon/ProductIcon'
import { motion } from 'framer-motion'
import { useWebsocket } from '../../../../../hooks/useWebsocket'
import ExchangeTableRow from './ExchangeTableRow'
import ExchangeTableMobileRow from './ExchangeTableMobileRow'
import AttributesModal from './AttributesModal'
import WatchlistModal from './WatchlistModal'
import EditOrderModal from './EditOrderModal'
import {
  Pause,
  Play,
  Trash,
  CaretDown,
  ListBullets,
  SquaresFour,
} from 'phosphor-react'
import ExchangeTableCard from './ExchangeTableCard'
import CopyWatchlistModal from './CopyWatchlistModal'
import _ from 'underscore'
import { hasPermission } from '../../../../../lib/utils/utils'

const ExchangeTable = (props) => {
  const {
    filter,
    setFilter,
    materialFilter,
    setMaterialFilter,
    openFilter,
    setActiveAttributeIndex,
    setFilterSelections,
    setInputExpiration,
    inputExpiration,
    is_admin,
    selectedCommodityForm,
    watchlist,
    currentPortal,
    setReloadHeaderStats,
    yourBids,
    reload,
    setReload,
    selectedCommodity,
    product,
    newbidReload,
    setNewbidReload,
    setSelectedCommodityForm,
    commodityForms,
    setProductVariant,
    setOpenPricingModal,
    setInputPrice,
    setInputSecondaryPrice,
    inputSecondaryPrice,
    setInputQuantity,
    inputPrice,
    productVariant,
    setAdminUser,
    timeframeFilter,
    truckingTypeFilter,
    currentSelectedLocation,
    publicLocation,
    equipmentType,
    user,
    setUser,
    publicExchange,
  } = props

  const {
    ws,
    wsOpen,
    resetWs,
    lastWsMessage,
    orderBookWsMessage,
    closeOrderWsMessage,
    editOrderWsMessage,
    currentMessageId,
  } = useWebsocket()
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.user.user)
  const reduxBusiness = useSelector((state) => state.user.business)
  const reduxSupplier = reduxBusiness.supplier
  const reduxBuyer = reduxBusiness.buyer
  const tenant = useSelector((state) => state.globals.tenant)
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const windowDimensions = useWindowDimensions()
  const pagewidth = document.body.clientWidth
  const mobileView = pagewidth <= 768
  const axios = useAxios()
  const location = useLocation()
  const navigate = useNavigate()
  // PROPS
  const [exchangeOrderBooks, setExchangeOrderBooks] = useState([])
  const [listView, setListView] = useState(true)
  const [lastLocation, setLastLocation] = useState(null)
  const [selectedBids, setSelectedBids] = useState([])
  // const [locations, setLocations] = useState([]);
  const [cancelBidLoading, setCancelBidLoading] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [lastActivePage, setLastActivePage] = useState(1)
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [hoverRow, setHoverRow] = useState()
  const [activeAskIds, setActiveAskIds] = useState([])
  const [activeBidIds, setActiveBidIds] = useState([])
  const [startWatchlistModal, setStartWatchlistModal] = useState(false)
  const [copyWatchlistModal, setCopyWatchlistModal] = useState(false)
  const [selectedProductId, setSelectedProductId] = useState()
  const [selectedProductLoading, setSelectedProductLoading] = useState(false)
  const [loadingNextModal, setLoadingNextModal] = useState(false)
  const [reloadExchange, setReloadExchange] = useState(false)
  const [openAttributesModal, setOpenAttributesModal] = useState(false)
  const [attributesForSelectedProduct, setAttributesForSelectedProduct] =
    useState(false)
  const [productForWatchlistModal, setProductForWatchlistModal] = useState({})
  const [lastCommodityFormID, setLastCommodityFormID] = useState(
    selectedCommodityForm?.id
  )
  const [actionBeforeWsReset, setActionBeforeWsReset] = useState()
  const [lastUser, setLastUser] = useState(null)
  const [business, setBusiness] = useState(null)
  const [lastFilterSent, setLastFilterSent] = useState(null)
  const [lastMaterialFilterSent, setLastMaterialFilterSent] = useState(null)
  const [lastLocationSent, setLastLocationSent] = useState(null)
  const [openEditBidAskModal, setOpenEditBidAskModal] = useState(null)
  const [editingOrder, setEditingOrder] = useState(null)
  const [editingExchangeOrderBook, setEditingExchangeOrderBook] = useState(null)
  const [lastTruckingTypeSent, setLastTruckingTypeSent] = useState(null)
  const [lastTimeframeSent, setLastTimeframeSent] = useState(null)
  const [exchangeTableLoaded, setExchangeTableLoaded] = useState(false)
  const [lastPortal, setLastPortal] = useState(null)
  const [lastProductVariantWatchlist, setLastProductVariantWatchlist] =
    useState(user ? user?.product_variant_watchlist : null)
  const [lastSelectedLocation, setLastSelectedLocation] = useState(
    currentSelectedLocation?.id || currentSelectedLocation?.id === 0
      ? currentSelectedLocation?.id
      : null
  )
  const [sortBy, setSortBy] = useState('orders+')
  const [lastSortBySent, setLastSortBySent] = useState(null)
  const [openSortBy, setOpenSortBy] = useState(false)
  const [sortByDim, setSortByDim] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [lastWsMessageId, setLastWsMessageId] = useState(null)

  const [watchlistSubmitting, setWatchlistSubmitting] = useState(false)
  const [attributeSelectionsFromDropdown, setAttributeSelectionsFromDropdown] =
    useState({})
  const popupPosition = mobileView ? 'left center' : 'bottom right'
  useEffect(() => {
    if (
      reduxUser?.id &&
      reduxBusiness?.id &&
      reduxUser?.id === user?.id &&
      ['/exchange/', '/exchange'].includes(location?.pathname)
    ) {
      navigate(
        `/portal/${(reduxSupplier && 'supplier') || (reduxBuyer && 'buyer')}`
      )
    }
    if (reduxUser?.id && setUser && reduxBusiness?.id && !is_admin) {
      setUser(reduxUser)
      setBusiness(reduxBusiness)
    } else if (is_admin && props?.user?.id && setAdminUser) {
      setAdminUser(props.user)
      setBusiness(props.business)
    }
  }, [
    reduxUser,
    reduxBusiness,
    is_admin,
    props.user,
    props.business,
    publicLocation,
    equipmentType,
  ])

  useEffect(() => {
    if (user) {
      setLastProductVariantWatchlist(user?.product_variant_watchlist)
      setLastSelectedLocation(user?.current_location?.id)
    }
  }, [user])

  useEffect(() => {
    if (is_admin && currentPortal !== lastPortal) {
      setLastPortal(currentPortal)
      setReloadExchange(true)
    }
  }, [is_admin, business, currentPortal])

  useEffect(() => {
    setReloadExchange(true)
  }, [selectedCommodity?.id])

  useEffect(() => {
    if (location?.pathname !== lastLocation?.pathname) {
      setExchangeTableLoaded(false)
      setLastLocation(location)
    }
  }, [location])

  useEffect(() => {
    if (user) {
      let aa = []
      let ab = []
      user?.active_asks?.forEach((ask) => {
        aa = aa.concat(ask?.order_books.map((ob) => ob.id))
      })
      user?.active_bids?.forEach((bid) => {
        ab = ab.concat(bid?.order_books.map((ob) => ob.id))
      })
      setActiveAskIds(aa)
      setActiveBidIds(ab)
    }
  }, [user?.active_asks, user?.active_bids])

  const handleChangeCommodityForm = (commodityForm) => {
    setSelectedCommodityForm(commodityForm)
    setFilter([])
    setActiveAttributeIndex(0)
    setFilterSelections([])
    setMaterialFilter([])
  }

  useEffect(() => {
    setExchangeOrderBooks([])
    if (reload) {
      setReloadExchange(true)
      if (setReload) {
        setReload(false)
      }
    }
  }, [reload])

  const handleCloseModal = () => {
    setStartWatchlistModal(false)
    setOpenAttributesModal(false)
    setSelectedProductId(null)
    setProductForWatchlistModal(null)
  }

  // ----------------------------------------------
  // WEBSOCKET start
  // ----------------------------------------------
  useEffect(() => {
    if (
      ws &&
      ws?.readyState === 1 &&
      user &&
      (currentSelectedLocation?.id ||
        currentSelectedLocation?.id === 0 ||
        publicExchange) &&
      (!watchlist && !yourBids
        ? selectedCommodityForm?.id !== undefined || product
        : true) &&
      (lastFilterSent !== filter ||
        lastMaterialFilterSent !== materialFilter ||
        lastTruckingTypeSent !== truckingTypeFilter ||
        lastTimeframeSent !== timeframeFilter ||
        lastLocationSent?.id !== currentSelectedLocation?.id ||
        lastCommodityFormID !== selectedCommodityForm?.id ||
        lastSelectedLocation !== currentSelectedLocation?.id ||
        (is_admin && user?.id !== lastUser?.id) ||
        lastActivePage !== activePage ||
        reloadExchange ||
        lastSortBySent !== sortBy ||
        newbidReload ||
        JSON.stringify(user?.product_variant_watchlist) !==
          JSON.stringify(lastProductVariantWatchlist))
    ) {
      console.log('running sendWsFilterOrderBooks')
      sendWsFilterOrderBooks()
      setExchangeTableLoaded(false)
      setLastCommodityFormID(selectedCommodityForm?.id)
      setLastSelectedLocation(currentSelectedLocation?.id)
      setLastActivePage(activePage)
      setLastUser(is_admin ? user : null)
      setLastSortBySent(sortBy)
      setReloadExchange(false)
      if (newbidReload) {
        setNewbidReload(false)
      }
    }
    if (!publicLocation && publicExchange) {
      setExchangeTableLoaded(true)
    }
    console.log('sendWsFilterOrderBooks state change')
  }, [
    wsOpen,
    ws?.readyState,
    filter,
    materialFilter,
    selectedLocation,
    activePage,
    user,
    currentSelectedLocation?.id,
    timeframeFilter,
    truckingTypeFilter,
    selectedCommodityForm?.id,
    selectedCommodity?.id,
    yourBids,
    product?.id,
    reloadExchange,
    sortBy,
  ])

  useEffect(() => {
    if (
      reload ||
      (currentPortal === 'supplier' && user?.active_asks) ||
      (currentPortal === 'buyer' && user?.active_bids)
    ) {
      setReloadExchange(true)
      if (reload && setReload) {
        setReload(false)
      }
    }
  }, [user?.active_asks?.length, user?.active_bids?.length])

  // ----------------------------------------------
  // WEBSOCKET
  // ----------------------------------------------

  useEffect(() => {
    if (!wsOpen) {
      resetWs()
    }
  }, [wsOpen])

  // ----------------------------------------------
  // WEBSOCKET
  // ----------------------------------------------
  useEffect(() => {
    setExchangeTableLoaded(false)
  }, [props?.selectedCommodityForm?.id, watchlist, yourBids])

  useEffect(() => {
    if (filter || materialFilter) {
      setActivePage(1)
    }
  }, [filter, materialFilter])

  useEffect(() => {
    if (ws && ws.readyState === 1 && actionBeforeWsReset) {
      if (typeof actionBeforeWsReset === 'object') {
        actionBeforeWsReset.function(actionBeforeWsReset.args)
      } else {
        actionBeforeWsReset()
      }
      setActionBeforeWsReset()
    }
  }, [ws])

  const sendWsFilterOrderBooks = () => {
    console.log('send filter order books')
    console.log(ws)
    setLastProductVariantWatchlist(user?.product_variant_watchlist)
    if (ws && ws.readyState === 1) {
      setLastFilterSent(filter)
      setLastMaterialFilterSent(materialFilter)
      setLastLocationSent(currentSelectedLocation)
      setLastTruckingTypeSent(truckingTypeFilter)
      setLastTimeframeSent(timeframeFilter)
      let message = {
        tenant_id: tenant?.id,
        filter_order_book: {
          page: activePage,
          per_page: 10,
        },
      }
      message['filter_order_book']['location_id'] = currentSelectedLocation?.id
      if (!yourBids && product) {
        message['filter_order_book']['product_id'] = product?.id
      } else if (!yourBids) {
        message['filter_order_book']['commodity_id'] = selectedCommodity?.id
        if (selectedCommodityForm?.id !== 0 || watchlist) {
          message['filter_order_book']['commodity_form_id'] =
            selectedCommodityForm?.id
        }
      }
      message['filter_order_book']['timeframe'] = timeframeFilter
      message['filter_order_book']['cargo_unit_ids'] = truckingTypeFilter
      if (props.watchlist) {
        message['filter_order_book']['watchlist'] = true
      }
      if (is_admin) {
        message['filter_order_book']['business_id'] = business?.id
        if (selectedCommodity?.id) {
          message['filter_order_book']['commodity_id'] = selectedCommodity?.id
        }
      }
      if (yourBids) {
        message['filter_order_book']['user_ids'] = [user.id]
        message['filter_order_book']['order_status'] = ['open', 'disabled']
        message['filter_order_book']['order_side'] = ['bid', 'ask']
        if (currentPortal === 'buyer') {
          message['filter_order_book']['users_side'] = 'bid'
        } else {
          message['filter_order_book']['users_side'] = 'ask'
        }
      } else if (!watchlist) {
        if (user?.id) {
          message['filter_order_book']['not_user_ids'] = [user?.id]
        }
        message['filter_order_book']['order_status'] = ['open']
        message['filter_order_book']['order_side'] = ['bid', 'ask']
        if (currentPortal === 'buyer' || publicExchange) {
          message['filter_order_book']['open_order_side'] = 'ask'
        } else {
          message['filter_order_book']['open_order_side'] = 'bid'
        }
      }
      if (!yourBids && watchlist) {
        let pvIds
        if (user?.product_variant_watchlist?.length > 0) {
          pvIds = []
          user?.product_variant_watchlist?.forEach((pv) => {
            console.log(`pv is ${pv}`)
            if (
              typeof pv === 'object' &&
              pv?.location_id === currentSelectedLocation?.id &&
              (pv?.side === (currentPortal === 'supplier' ? 'ask' : 'bid') ||
                !pv?.side)
            ) {
              pvIds.push(pv?.pv_id)
            } else if (typeof pv === 'number') {
              pvIds.push(pv)
            }
          })
          if (pvIds.length === 0) {
            pvIds = [0]
          }
        } else {
          pvIds = [0]
        }
        message['filter_order_book']['product_variant_ids'] = pvIds
      }
      if (filter?.length > 0) {
        message['filter_order_book']['attribute_selections'] = filter
      }
      if (materialFilter?.length > 0) {
        message['filter_order_book']['product_material_name'] = materialFilter
      }
      if (sortBy) {
        message['filter_order_book']['sort_by'] = sortBy
      }
      message['filter_order_book']['sort_by_side'] =
        props.currentPortal === 'supplier' ? 'bid' : 'ask'
      track('View Exchange', {
        Message: message,
        'User ID': user?.id,
        'Business Name': business?.name,
      })
      ws.send(orderBookWsMessage(message))
    } else {
      resetWs()
      setActionBeforeWsReset(sendWsFilterOrderBooks)
    }
  }

  // ----------------------------------------------
  // WEBSOCKET
  // ----------------------------------------------
  async function updateWatchlist(
    productVariantId,
    star = false,
    closeModal = false
  ) {
    let is_array = Array.isArray(productVariantId)
    let defaultUser = JSON.parse(JSON.stringify(user))

    if (!is_array) {
      let userCopy = { ...defaultUser }
      let filteredWatchlist = userCopy?.product_variant_watchlist?.filter(
        (obj) =>
          !(
            obj?.pv_id === productVariantId &&
            obj?.location_id === currentSelectedLocation?.id
          )
      )
      if (
        filteredWatchlist?.length !== user?.product_variant_watchlist?.length
      ) {
        userCopy.product_variant_watchlist = filteredWatchlist
      } else {
        userCopy.product_variant_watchlist.push({
          pv_id: productVariantId,
          location_id: currentSelectedLocation?.id,
        })
      }
      updateUser(userCopy)
    }

    let res = await axios.patch(`/main/custom-users/${user.id}/`, {
      watchlist_ids: is_array ? productVariantId : [productVariantId],
      current_location_id: currentSelectedLocation?.id,
      side: currentPortal === 'supplier' ? 'ask' : 'bid',
      business_id: business?.id,
    })
    if (res?.data?.id) {
      if (props.is_admin) {
        props?.loadData()
        if (!star) {
          setExchangeTableLoaded(false)
        }
      } else if (is_array) {
        dispatch(addUser(res.data))
      }
      toast.success('Watchlist updated!')
    } else if (res?.data?.error) {
      dispatch(addUser(defaultUser))
    }
    if (closeModal) {
      setOpenAttributesModal(false)
      setAttributeSelectionsFromDropdown({})
    }
    setWatchlistSubmitting(false)
    track('Update Watchlist', {
      'Product Variant IDs': is_array ? productVariantId : [productVariantId],
      'User ID': user?.id,
      'Business Name': business?.name,
    })
  }

  const cancelBid = (productVariantId, orderId, locationId) => {
    setCancelBidLoading(true)
    if (ws && ws.readyState === 1) {
      ws.send(
        closeOrderWsMessage({
          tenant_id: tenant.id,
          close_order: [
            {
              close_all: true,
              order_id: orderId,
            },
          ],
        })
      )
      toast.success('Order will be canceled momentarily')
      setReloadHeaderStats(true)
      setTimeout(() => {
        setCancelBidLoading(false)
      }, 15000)
      track('Cancel Bid', {
        'Product Variant ID': productVariantId,
        'Order ID': orderId,
        'Location ID': locationId,
      })
      setReloadExchange(true)
      refreshUserState()
      if (props.setReload) {
        props.setReload(true)
      }
      if (props.loadData) {
        props.loadData()
      }
    } else {
      resetWs()
      setActionBeforeWsReset({
        function: cancelBid,
        args: [productVariantId, orderId, locationId],
      })
    }
  }

  const updateUser = (data) => {
    if (is_admin && setAdminUser) {
      setAdminUser(data)
    } else {
      if (setUser) {
        setUser(data)
      }
      dispatch(addUser(data))
    }
  }

  const pauseBids = () => {
    if (ws && ws.readyState === 1) {
      let pauseOrders = selectedBids?.map((eo) => {
        let bidAskId
        if (currentPortal === 'supplier') {
          bidAskId = eo.ask_orders[0]?.id
        } else {
          bidAskId = eo.bid_orders[0]?.id
        }
        return {
          order_id: bidAskId,
          status: 'disabled',
        }
      })
      let message = {
        tenant_id: tenant.id,
        update_order: pauseOrders,
      }
      ws.send(editOrderWsMessage(message))
      toast.success('Success! Your order will be updated momentarily.')
      setSelectedBids([])
      track('Pause Bids', {
        'User ID': user?.id,
        'Business Name': business?.name,
        'Selected Bids': selectedBids,
      })
    } else {
      resetWs()
      setActionBeforeWsReset(pauseBids)
    }
  }

  const playBids = () => {
    if (ws && ws.readyState === 1) {
      let playOrders = selectedBids?.map((eo) => {
        let bidAskId
        if (currentPortal === 'supplier') {
          bidAskId = eo.ask_orders[0]?.id
        } else {
          bidAskId = eo.bid_orders[0]?.id
        }
        return {
          order_id: bidAskId,
          status: 'open',
        }
      })
      let message = {
        tenant_id: tenant.id,
        update_order: playOrders,
      }
      ws.send(editOrderWsMessage(message))
      toast.success('Success! Your order will be updated momentarily.')
      setSelectedBids([])
      track('Play Bids', {
        'User ID': user?.id,
        'Business Name': business?.name,
        'Selected Bids': selectedBids,
      })
    } else {
      resetWs()
      setActionBeforeWsReset(playBids)
    }
  }

  const deleteBids = () => {
    if (ws && ws.readyState === 1) {
      let closeOrders = selectedBids?.map((eo) => {
        let bidAskId
        if (currentPortal === 'supplier') {
          bidAskId = eo.ask_orders[0]?.id
        } else {
          bidAskId = eo.bid_orders[0]?.id
        }
        return {
          order_id: bidAskId,
          close_all: true,
        }
      })
      ws.send(
        closeOrderWsMessage({
          tenant_id: tenant.id,
          close_order: closeOrders,
        })
      )
      track('Delete Bid', {
        'User ID': user?.id,
        'Business Name': business?.name,
        'Selected Bids': selectedBids,
      })
      toast.success('Order will be canceled momentarily')
      setReloadHeaderStats(true)
      setExchangeTableLoaded(false)
      setSelectedBids([])
    } else {
      resetWs()
      setActionBeforeWsReset(deleteBids)
    }
  }

  useEffect(() => {
    if (lastWsMessage && user) {
      let message = lastWsMessage
      console.log(message)
      if (
        'trade' in message &&
        ((message?.trade?.side === 'ask' && currentPortal === 'supplier') ||
          (message?.trade?.side === 'bid' && currentPortal === 'buyer')) &&
        (!lastWsMessageId ||
          (lastWsMessageId && lastWsMessageId !== message?.message_id))
      ) {
        setLastWsMessageId(message?.message_id)
        let toastMessage = `${message['trade']['quantity']} ${message['trade']['cargo_unit']['plural_name']} ${message['trade']['product_variant']['product_material_name']}`
        if (message['trade']['side'] === 'ask') {
          toastMessage = `🎉  Order to Sell ${toastMessage} Matched!`
        } else {
          toastMessage = `🎉  Order to Buy ${toastMessage} Matched!`
        }
        setReloadHeaderStats(true)
        toast.success(toastMessage)
        sendWsFilterOrderBooks()
      } else if ('trade' in message && Array.isArray(message.trade)) {
        message?.trade?.forEach((msg) => {
          let toastMessage = `${msg['quantity']} ${msg['cargo_unit']['plural_name']} ${msg['product_variant']['product_material_name']}`
          console.log('toast message', toastMessage)
          if (msg['side'] === 'ask') {
            toastMessage = `🎉  Order to Sell ${toastMessage} Matched!`
          } else {
            toastMessage = `🎉  Order to Buy ${toastMessage} Matched!`
          }
          toast.success(toastMessage)
        })
        setReloadHeaderStats(true)
        sendWsFilterOrderBooks()
      }
      if ('order' in message) {
        refreshUserState()
        setExchangeTableLoaded(false)
        sendWsFilterOrderBooks()
      }
      if (
        'order_books' in message &&
        message['message_id'] === currentMessageId
      ) {
        let orderBooks = message['order_books']
        // Filter out negative total_prices
        orderBooks = orderBooks.map((orderBook) => {
          let obCopy = { ...orderBook }
          let highestBid
          let lowestAsk
          obCopy.ask_orders = obCopy.ask_orders?.filter((askOrder) => {
            const include =
              currentPortal === 'supplier' ||
              (askOrder.total_price > 0 && askOrder.user_id !== user.id)
            if (include && (!lowestAsk || lowestAsk > askOrder.total_price)) {
              lowestAsk = askOrder.total_price
            }
            return include
          })
          obCopy.bid_orders = obCopy.bid_orders?.filter((bidOrder) => {
            const include =
              currentPortal === 'buyer' ||
              (bidOrder.total_price > 0 && bidOrder.user_id !== user.id)
            if (include && (!highestBid || highestBid < bidOrder.total_price)) {
              highestBid = bidOrder.total_price
            }
            return (
              currentPortal === 'buyer' ||
              (bidOrder.total_price > 0 && bidOrder.user_id !== user.id)
            )
          })
          obCopy.lowest_ask = lowestAsk
          obCopy.highest_bid = highestBid
          return obCopy
        })
        let type = message['type']
        if (type === 'update') {
          let exchangeOrderBookCopy = [...exchangeOrderBooks]
          let exchangeOrderBookPVs = orderBooks?.map((orderBook) => {
            return orderBook?.product_variant?.id
          })
          exchangeOrderBookCopy?.forEach((orderBook, idx) => {
            let exchIdx = exchangeOrderBookPVs?.indexOf(
              orderBook?.product_variant?.id
            )
            if (exchIdx >= 0) {
              exchangeOrderBookCopy[idx] = orderBooks[exchIdx]
              orderBooks.splice(exchIdx, 1)
            }
          })
          let newExchangeOrderBooks = exchangeOrderBookCopy.concat(orderBooks)
          if (currentPortal === 'supplier' && !yourBids && !watchlist) {
            newExchangeOrderBooks = newExchangeOrderBooks.filter(
              (orderBook) => {
                return (
                  orderBook?.bid_orders?.length > 0 &&
                  orderBook?.highest_bid &&
                  orderBook?.highest_bid > 0
                )
              }
            )
          }
          if (currentPortal === 'buyer' && !yourBids && !watchlist) {
            newExchangeOrderBooks = newExchangeOrderBooks.filter(
              (orderBook) => {
                return (
                  orderBook?.ask_orders?.length > 0 &&
                  orderBook?.lowest_ask &&
                  orderBook?.lowest_ask > 0
                )
              }
            )
          }
          if (currentPortal === 'supplier' && yourBids && !watchlist) {
            newExchangeOrderBooks = newExchangeOrderBooks.filter(
              (orderBook) => {
                return orderBook?.ask_orders?.length > 0
              }
            )
          }
          if (currentPortal === 'buyer' && yourBids && !watchlist) {
            newExchangeOrderBooks = newExchangeOrderBooks.filter(
              (orderBook) => {
                return orderBook?.bid_orders?.length > 0
              }
            )
          }
          setExchangeOrderBooks(newExchangeOrderBooks)
          setExchangeTableLoaded(true)
        }
        if (type === 'initial') {
          setTotalPages(message['pages_count'])
          if (currentPortal === 'supplier' && !yourBids && !watchlist) {
            orderBooks = orderBooks.filter((orderBook) => {
              return orderBook?.bid_orders?.length > 0 && orderBook?.highest_bid
            })
          }
          if (currentPortal === 'buyer' && !yourBids && !watchlist) {
            orderBooks = orderBooks.filter((orderBook) => {
              return orderBook?.ask_orders?.length > 0 && orderBook?.lowest_ask
            })
          }
          if (currentPortal === 'supplier' && yourBids && !watchlist) {
            orderBooks = orderBooks.filter((orderBook) => {
              return orderBook?.ask_orders?.length > 0
            })
          }
          if (currentPortal === 'buyer' && yourBids && !watchlist) {
            orderBooks = orderBooks.filter((orderBook) => {
              return orderBook?.bid_orders?.length > 0
            })
          }
          setExchangeOrderBooks(orderBooks)
          setExchangeTableLoaded(true)
        }
        setReloadHeaderStats(true)
      }
    }
  }, [lastWsMessage, user])
  // ----------------------------------------------
  // WEBSOCKET end
  // ----------------------------------------------

  useEffect(() => {
    setExchangeTableLoaded(false)
  }, [selectedCommodityForm?.id, watchlist, yourBids])

  // ----------------------------------------------
  // API start
  // ----------------------------------------------
  const refreshUserState = async () => {
    let res = await axios.get(`/main/custom-users/${user.id}/`)
    if (res?.data?.id) {
      updateUser(res?.data)
    }
  }

  async function updateSelectedLocation() {
    setExchangeTableLoaded(false)
    let userCopy = { ...user }
    userCopy.current_location = selectedLocation
    updateUser(userCopy)
    let res = await axios.patch(`/main/custom-users/${user?.id}/`, {
      current_location_id: selectedLocation?.id,
      business_id: business?.id,
    })
    if (res?.data?.id) {
      updateUser(res.data)
    }
  }

  useEffect(() => {
    if (
      reduxUser &&
      (currentSelectedLocation?.id || currentSelectedLocation?.id === 0) &&
      selectedLocation?.id &&
      selectedLocation?.id !== currentSelectedLocation?.id
    ) {
      updateSelectedLocation()
    }
  }, [selectedLocation])

  useEffect(() => {
    if (currentSelectedLocation?.id !== selectedLocation?.id) {
      setSelectedLocation(currentSelectedLocation)
    }
  }, [currentSelectedLocation, publicLocation])

  // ----------------------------------------------
  // API end
  // ----------------------------------------------

  const sections = [
    {
      key: 'Home',
      content: 'Home',
      link: true,
      href:
        (props.currentPortal === 'buyer' && '/portal/buyer/') ||
        (props.currentPortal === 'supplier' && '/portal/supplier/') ||
        (!props.currentPortal && '/exchange/'),
    },
    {
      key: props?.product?.commodity.name,
      content: props?.product?.commodity.name,
      link: true,
      href:
        ((props.currentPortal === 'buyer' && '/portal/buyer/') ||
          (props.currentPortal === 'supplier' && '/portal/supplier/') ||
          (!props.currentPortal && '/exchange/')) +
        props?.product?.commodity?.slug,
    },
    {
      key: props?.product?.commodity_form.name,
      content: props?.product?.commodity_form.name,
      link: true,
      href:
        ((props.currentPortal === 'buyer' && '/portal/buyer/') ||
          (props.currentPortal === 'supplier' && '/portal/supplier/') ||
          (!props.currentPortal && '/exchange/')) +
        props?.product?.commodity?.slug +
        '/' +
        props?.product?.commodity_form?.slug,
    },
    {
      key: props?.product?.material.name,
      content: props?.product?.material.name,
      active: true,
    },
  ]

  const sortByFields = [
    {
      label: 'Best Match',
      value: 'orders+',
    },
    {
      label: 'Price (low to high)',
      value: 'price-',
    },
    {
      label: 'Price (high to low)',
      value: 'price+',
    },
    {
      label: `Number of ${
        props.currentPortal === 'supplier' ? 'Bids' : 'Asks'
      } (high to low)`,
      value: props.currentPortal === 'supplier' ? 'bids+' : 'asks+',
    },
    {
      label: `Number of ${
        props.currentPortal === 'supplier' ? 'Bids' : 'Asks'
      } (low to high)`,
      value: props.currentPortal === 'supplier' ? 'bids-' : 'asks-',
    },
    {
      label: 'Most Recently added',
      value: 'created+',
    },
  ]

  return (
    <>
      <Grid
        stackable
        style={{
          marginLeft: openFilter ? '270px' : '0',
        }}
        data-status={openFilter}
        id="exchange-table"
      >
        {!product &&
          !yourBids &&
          !watchlist &&
          commodityForms &&
          windowDimensions.width <= 768 && (
            <div
              style={{
                width: '100%',
                padding: '1rem 20px 1rem 20px',
              }}
            >
              <Dropdown
                selection
                fluid
                // defaultValue={selectedCommodityForm}
                value={selectedCommodityForm}
                onChange={(e, d) => handleChangeCommodityForm(d.value)}
                options={[
                  {
                    key: 0,
                    value: { id: 0 },
                    text: 'All',
                  },
                  ...commodityForms?.map((form, index) => {
                    return {
                      key: index,
                      value: form,
                      text: form.name,
                    }
                  }),
                ]}
              />
            </div>
          )}
        {!props.reload && (
          <motion.div
            animate={product ? 'open' : 'closed'}
            variants={{
              open: { opacity: 1, height: 'fit-content', width: '100%' },
              closed: { opacity: 0, height: '0px', width: '100%' },
            }}
            transition={{ duration: 0.75 }}
            // style={{ minHeight: "500px" }}
          >
            {product && (
              <Grid style={{ height: '100%', padding: '0 1rem 1rem 1rem' }}>
                <Grid.Column width={16}>
                  <Breadcrumb
                    icon="right angle"
                    sections={sections}
                    style={{ marginBottom: '2rem' }}
                  />
                  <Grid stackable style={{ height: '100%' }}>
                    <Grid.Column width={6} style={{ paddingRight: '3rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '3rem',
                        }}
                      >
                        <ProductIcon
                          productName={product?.material?.name}
                          width={'40px'}
                          height={'40px'}
                          fontSize={'25px'}
                        />
                        <Header
                          as={'h2'}
                          style={{ margin: '0 0 0 1rem', fontSize: '36px' }}
                        >
                          {product?.material?.name}
                        </Header>
                      </div>
                      <div>
                        <Header
                          style={{
                            fontSize: '13px',
                            color: env.REACT_APP_PRIMARY_COLOR,
                            letterSpacing: '.2rem',
                          }}
                        >
                          DESCRIPTION
                        </Header>
                        <p>{product?.material?.description}</p>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            )}
          </motion.div>
        )}
        <div className="exchange-table-heading">
          <div className="commodities-wrapper">
            {yourBids && (
              <>
                <Header
                  className={'commodity-header selected'}
                  as={'h2'}
                  color={'primary'}
                  style={{
                    margin: '.5rem 1rem .5rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  {currentPortal === 'buyer' ? 'My Buys' : 'My Sells'}
                </Header>
              </>
            )}
            {watchlist && (
              <>
                <Header
                  className={'commodity-header selected'}
                  as={'h2'}
                  color={'primary'}
                  style={{
                    margin: '.5rem 1rem .5rem 0',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  Watchlist
                </Header>
                {hasPermission(reduxUser, 'Update Exchange') && (
                  <div
                    className={'add-watchlist-button'}
                    style={{
                      width: '100%',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    <Button
                      icon={'plus'}
                      size={'medium'}
                      style={{ width: 'auto' }}
                      onClick={(e, d) => {
                        setStartWatchlistModal(true)
                      }}
                      content={'Add To Watchlist'}
                    />
                    {is_admin && (
                      <Button
                        icon={'plus'}
                        size={'medium'}
                        style={{ width: 'auto' }}
                        onClick={(e, d) => {
                          if (business?.user?.length < 2) {
                            toast.error(
                              "Business doesn't have enough users to copy watchlist"
                            )
                          } else if (
                            user?.product_variant_watchlist?.length > 0
                          ) {
                            setCopyWatchlistModal(true)
                          } else {
                            toast.error('Please add some watchlist first')
                          }
                        }}
                        content={'Copy Watchlist'}
                      />
                    )}
                  </div>
                )}
              </>
            )}
            {!product && !yourBids && !watchlist && commodityForms && (
              <>
                {[{ id: 0, name: 'All' }, ...commodityForms]?.map(
                  (commodityForm, index) => {
                    return (
                      <Header
                        key={index}
                        className={
                          selectedCommodityForm?.id === commodityForm?.id
                            ? 'commodity-header selected'
                            : 'commodity-header'
                        }
                        as={'h2'}
                        color={
                          selectedCommodityForm?.id === commodityForm?.id
                            ? 'primary'
                            : 'black'
                        }
                        onClick={() => handleChangeCommodityForm(commodityForm)}
                        style={{
                          margin: '.5rem 1rem .5rem 0',
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        }}
                      >
                        {commodityForm.name}
                      </Header>
                    )
                  }
                )}
              </>
            )}
          </div>
          <Button.Group className="card-view-toggle">
            <div
              className={`view-button ${
                !listView ? 'card-view' : 'view-button-card'
              }`}
              onClick={() => setListView(false)}
            >
              <SquaresFour size={25} />
            </div>
            <div
              className={`view-button ${
                listView ? 'list-view' : 'view-button-list'
              }`}
              onClick={() => setListView(true)}
            >
              <ListBullets size={25} />
            </div>
          </Button.Group>
        </div>
        <div style={{ width: '100%', padding: '0 .5rem' }}>
          <Dimmer page active={sortByDim} />
          <Popup
            on="click"
            position={popupPosition}
            offset={mobileView ? [0, -30] : [0, 0]}
            style={{
              marginRight: '40px',
              minWidth: !mobileView ? '310px' : '250px',
            }}
            onOpen={() => {
              setOpenSortBy(true)
              setSortByDim(true)
            }}
            onClose={() => {
              setOpenSortBy(false)
              setSortByDim(false)
            }}
            trigger={
              <Button
                style={{
                  float: 'right',
                  background: 'none',
                  color: '#000',
                  padding: '1rem 0.5rem',
                }}
                content={
                  <>
                    Sort by
                    {sortByFields?.find((field) => sortBy === field.value)
                      ?.label && (
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          color: '#3d3d3d',
                        }}
                      >
                        :{' '}
                        {
                          sortByFields?.find((field) => sortBy === field.value)
                            ?.label
                        }
                      </span>
                    )}
                  </>
                }
                icon={
                  <CaretDown
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      marginLeft: '10px',
                    }}
                  />
                }
              />
            }
            open={openSortBy}
          >
            <Grid
              className={'sort-by-dropdown'}
              style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
            >
              <GridRow
                className={'sort-by-popup'}
                style={{ alignItems: 'center', flexFlow: 'nowrap' }}
              >
                <Icon
                  size="large"
                  name="x"
                  corner="top left"
                  onClick={() => {
                    setOpenSortBy(false)
                    setSortByDim(false)
                  }}
                  style={{ cursor: 'pointer' }}
                />
                <Header
                  as="h3"
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '0',
                  }}
                  content={'Filters'}
                />
              </GridRow>
              {sortByFields.map((sortByField, index) => {
                return (
                  <GridRow className={'sort-by-popup'} key={index}>
                    <Checkbox
                      radio
                      label={sortByField.label}
                      name="checkboxRadioGroup"
                      value={sortByField.value}
                      checked={sortBy === sortByField.value}
                      onChange={(e, data) => setSortBy(data.value)}
                    />
                  </GridRow>
                )
              })}
            </Grid>
          </Popup>
        </div>
        <Grid.Column width={16} style={{ padding: '0px 15px 0 15px' }}>
          {(!exchangeTableLoaded || !props.customFilterLoading) && yourBids && (
            <MickeyPlaceholderLoading />
          )}
          {!currentSelectedLocation && !yourBids && (
            <MickeyPlaceholder
              icon={'exchange'}
              message={`Select a location above to see ${
                currentPortal === 'buyer' ? 'asks' : 'bids'
              }`}
            />
          )}
          {currentSelectedLocation &&
            !yourBids &&
            (!exchangeTableLoaded || !props.customFilterLoading) && (
              <MickeyPlaceholderLoading />
            )}
          {exchangeTableLoaded &&
            props.customFilterLoading &&
            currentSelectedLocation &&
            exchangeOrderBooks?.length <= 0 && (
              <>
                {props?.yourBids && (
                  <MickeyPlaceholder
                    icon={'exchange'}
                    message={`No ${
                      props?.currentPortal === 'supplier' ? 'asks' : 'bids'
                    } found.
                      Add a new ${
                        props?.currentPortal === 'supplier' ? 'ask' : 'bid'
                      } by clicking 
                      the Add ${
                        props?.currentPortal === 'supplier' ? 'Listing' : 'Buy'
                      } button above.`}
                  />
                )}
                {props?.watchlist && (
                  <MickeyPlaceholder
                    icon={'exchange'}
                    message={`No items on Watchlist. Click the Add to watchlist button to build your list.`}
                  />
                )}
                {!yourBids &&
                  !watchlist &&
                  (currentSelectedLocation?.id ||
                    currentSelectedLocation?.id === 0) && (
                    <MickeyPlaceholder
                      icon={'exchange'}
                      message={`No ${
                        props?.currentPortal === 'supplier' ? 'bids' : 'asks'
                      } found. Try updating your filter or come back soon!`}
                    />
                  )}
              </>
            )}
          {!listView && (
            <>
              <div
                className="card-wrapper"
                data-status={exchangeOrderBooks?.length < 3}
              >
                {exchangeTableLoaded &&
                  props.customFilterLoading &&
                  exchangeOrderBooks &&
                  (currentSelectedLocation?.id ||
                    currentSelectedLocation?.id === 0) &&
                  exchangeOrderBooks.length > 0 &&
                  exchangeOrderBooks?.map((exchangeOrderBook) => {
                    if (!exchangeOrderBook?.product_variant) {
                      return null
                    }
                    let productVariant
                    let product
                    let attributeSelections

                    productVariant = exchangeOrderBook?.product_variant
                    product = productVariant?.product
                    attributeSelections = productVariant?.attribute_selections

                    let groupedBids = Object.values(
                      groupBy(exchangeOrderBook?.bid_orders, (orderBook) => {
                        return orderBook?.total_price
                      })
                    )
                    let groupedAsks = Object.values(
                      groupBy(exchangeOrderBook?.ask_orders, (orderBook) => {
                        return orderBook?.total_price
                      })
                    )
                    const hasCurrentSideOrder =
                      (currentPortal === 'buyer' &&
                        exchangeOrderBook?.bid_orders?.length > 0) ||
                      (currentPortal === 'supplier' &&
                        exchangeOrderBook?.ask_orders?.length > 0)
                    const hasOpposingSideOrder =
                      (currentPortal === 'supplier' &&
                        exchangeOrderBook?.bid_orders?.length > 0) ||
                      (currentPortal === 'buyer' &&
                        exchangeOrderBook?.ask_orders?.length > 0)

                    let highestBidOrder
                    let lowestAskOrder
                    if (exchangeOrderBook.highest_bid) {
                      exchangeOrderBook.bid_orders?.forEach((bid) => {
                        if (
                          bid.total_price === exchangeOrderBook.highest_bid &&
                          !highestBidOrder
                        ) {
                          highestBidOrder = bid
                        }
                      })
                    }
                    if (exchangeOrderBook.lowest_ask) {
                      exchangeOrderBook.ask_orders?.forEach((ask) => {
                        if (
                          ask.total_price === exchangeOrderBook.lowest_ask &&
                          !lowestAskOrder
                        ) {
                          lowestAskOrder = ask
                        }
                      })
                    }
                    let userAsksOrders = []
                    let userBidsOrders = []
                    exchangeOrderBook?.ask_orders?.forEach((ask) => {
                      user?.active_asks?.forEach((uAsk) => {
                        if (uAsk?.id === ask?.id) {
                          userAsksOrders = [...userAsksOrders, ask]
                        }
                      })
                    })
                    exchangeOrderBook?.bid_orders?.forEach((bid) => {
                      user?.active_bids?.forEach((uBid) => {
                        if (uBid?.id === bid?.id) {
                          userBidsOrders = [...userBidsOrders, bid]
                        }
                      })
                    })

                    let pvList =
                      props.currentPortal === 'supplier' &&
                      userAsksOrders?.length > 0 &&
                      yourBids
                        ? userAsksOrders
                        : props.currentPortal === 'buyer' &&
                          userBidsOrders?.length > 0 &&
                          yourBids
                        ? userBidsOrders
                        : [exchangeOrderBook]
                    return _.map(pvList, (pv, i) => {
                      let userAsk
                      let userBid
                      if (
                        props.currentPortal === 'supplier' &&
                        userAsksOrders?.length > 0 &&
                        yourBids
                      ) {
                        if (currentSelectedLocation?.id === 0) {
                          userAsk = userAsksOrders[i]
                          if (truckingTypeFilter.length === 1) {
                            let ask = user?.active_asks.find(
                              (ask) =>
                                ask.id === userAsksOrders[i]?.id &&
                                ask?.cargo_unit?.id === truckingTypeFilter[0]
                            )
                            if (!ask) {
                              return
                            }
                          }
                        } else {
                          let ask = user?.active_asks.find(
                            (ask) =>
                              ask.id === userAsksOrders[i]?.id &&
                              ask?.location?.id === currentSelectedLocation?.id
                          )
                          if (ask) {
                            userAsk = userAsksOrders[i]
                            if (
                              truckingTypeFilter.length === 1 &&
                              ask?.cargo_unit?.id !== truckingTypeFilter[0]
                            ) {
                              return
                            }
                          } else {
                            return
                          }
                        }
                      } else if (
                        props.currentPortal === 'buyer' &&
                        userBidsOrders?.length > 0 &&
                        yourBids
                      ) {
                        if (currentSelectedLocation?.id === 0) {
                          userBid = userBidsOrders[i]
                          if (truckingTypeFilter.length === 1) {
                            let bid = user?.active_bids.find(
                              (bid) =>
                                bid.id === userBidsOrders[i]?.id &&
                                bid?.cargo_unit?.id === truckingTypeFilter[0]
                            )
                            if (!bid) {
                              return
                            }
                          }
                        } else {
                          let bid = user?.active_bids.find(
                            (bid) =>
                              bid.id === userBidsOrders[i]?.id &&
                              bid?.location?.id === currentSelectedLocation?.id
                          )
                          if (bid) {
                            userBid = userBidsOrders[i]
                            if (
                              truckingTypeFilter.length === 1 &&
                              userBid?.cargo_unit?.id !== truckingTypeFilter[0]
                            ) {
                              return
                            }
                          } else {
                            return
                          }
                        }
                      } else {
                        userAsk =
                          userAsksOrders.length > 0
                            ? userAsksOrders[0]
                            : userAsk
                        userBid =
                          userBidsOrders.length > 0
                            ? userBidsOrders[0]
                            : userBid
                      }

                      const hasActiveOrder =
                        (props.currentPortal === 'supplier' && userAsk) ||
                        (props.currentPortal === 'buyer' && userBid)
                      return (
                        <ExchangeTableCard
                          publicExchange={publicExchange}
                          openFilter={openFilter}
                          exchangeOrderBook={exchangeOrderBook}
                          product={product}
                          attributeSelections={attributeSelections}
                          user={user}
                          currentLocationId={currentSelectedLocation?.id}
                          setSelectedOrder={setSelectedOrder}
                          currentPortal={currentPortal}
                          cancelBid={cancelBid}
                          productVariant={productVariant}
                          index={i}
                          hasActiveOrder={hasActiveOrder}
                          hasCurrentSideOrder={hasCurrentSideOrder}
                          selectedOrder={selectedOrder}
                          updateWatchlist={updateWatchlist}
                          selectedLocation={selectedLocation}
                          hasOpposingSideOrder={hasOpposingSideOrder}
                          setOpenPricingModal={setOpenPricingModal}
                          userAsk={userAsk}
                          userBid={userBid}
                          is_admin={is_admin}
                          lowestAskOrder={lowestAskOrder}
                          highestBidOrder={highestBidOrder}
                          hoverRow={hoverRow}
                          setHoverRow={setHoverRow}
                          selectedBids={selectedBids}
                          setSelectedBids={setSelectedBids}
                          yourBids={yourBids}
                          setEditingOrder={setEditingOrder}
                          setEditingExchangeOrderBook={
                            setEditingExchangeOrderBook
                          }
                          groupedAsks={groupedAsks}
                          groupedBids={groupedBids}
                          activeAskIds={activeAskIds}
                          activeBidIds={activeBidIds}
                          setProductVariant={setProductVariant}
                          cancelBidLoading={cancelBidLoading}
                          setInputPrice={setInputPrice}
                          inputSecondaryPrice={inputSecondaryPrice}
                          setInputSecondaryPrice={setInputSecondaryPrice}
                          setOpenEditBidAskModal={setOpenEditBidAskModal}
                          setInputQuantity={setInputQuantity}
                          currentlySelectedTimeframe={
                            props.currentlySelectedTimeframe
                          }
                          setCurrentlySelectedTimeframe={
                            props.setCurrentlySelectedTimeframe
                          }
                          currentlySelectedTruckingType={
                            props.currentlySelectedTruckingType
                          }
                          setCurrentlySelectedTruckingType={
                            props.setCurrentlySelectedTruckingType
                          }
                        />
                      )
                    })
                  })}
              </div>
              <div
                style={{
                  width: '100%',
                  paddingBottom: exchangeOrderBooks?.length > 0 ? '10rem' : 0,
                }}
              >
                <Pagination
                  id="mx-pagination"
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  floated={'right'}
                  activePage={activePage}
                  totalPages={totalPages}
                  onPageChange={(event, data) => {
                    setActivePage(data.activePage)
                  }}
                />
              </div>
            </>
          )}
          {listView && (
            <>
              <Table compact unstackable id="exchange-rows-table">
                {exchangeTableLoaded &&
                  props.customFilterLoading &&
                  exchangeOrderBooks &&
                  (currentSelectedLocation?.id ||
                    currentSelectedLocation?.id === 0) &&
                  exchangeOrderBooks.length > 0 &&
                  windowDimensions.width - (openFilter ? 350 : 0) >= 1000 && (
                    <Table.Header>
                      <Table.Row
                        style={{
                          borderBottom: '1px solid lightgrey',
                          margin: '0px 0 10px 0',
                          paddingBottom: '6px',
                        }}
                      >
                        <Table.HeaderCell
                          colSpan={2}
                          className={'no-left-padding-or-margin'}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'left',
                            }}
                          >
                            {yourBids && !mobileView && (
                              <>
                                <span
                                  className={'orange-hover'}
                                  style={{
                                    marginRight: '10px',
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      selectedBids &&
                                      exchangeOrderBooks &&
                                      selectedBids.length ===
                                        exchangeOrderBooks.length
                                    }
                                    onClick={(e, d) => {
                                      if (d.checked) {
                                        setSelectedBids(exchangeOrderBooks)
                                      } else {
                                        setSelectedBids([])
                                      }
                                    }}
                                    label={
                                      selectedBids &&
                                      exchangeOrderBooks &&
                                      selectedBids.length ===
                                        exchangeOrderBooks.length
                                        ? 'Unselect All'
                                        : 'Select All'
                                    }
                                  />
                                </span>
                                {selectedBids?.length > 0 && (
                                  <span
                                    className={'orange-hover'}
                                    onClick={() => {
                                      pauseBids()
                                    }}
                                    style={{
                                      marginLeft: '20px',
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Pause
                                      style={{ marginRight: '5px' }}
                                      size={10}
                                    />
                                    <span
                                      className={'orange-hover'}
                                      style={{ color: 'grey' }}
                                    >
                                      Take Selected Offline
                                    </span>
                                  </span>
                                )}
                                {selectedBids?.length > 0 && (
                                  <span
                                    className={'orange-hover'}
                                    onClick={() => {
                                      playBids()
                                    }}
                                    style={{
                                      marginLeft: '20px',
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Play
                                      style={{ marginRight: '5px' }}
                                      size={10}
                                    />
                                    <span
                                      className={'orange-hover'}
                                      style={{ color: 'grey' }}
                                    >
                                      Bring Selected Online
                                    </span>
                                  </span>
                                )}
                                {selectedBids?.length > 0 && (
                                  <span
                                    className={'orange-hover'}
                                    onClick={() => {
                                      deleteBids()
                                    }}
                                    style={{
                                      marginLeft: '20px',
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Trash
                                      style={{ marginRight: '5px' }}
                                      size={10}
                                    />
                                    <span
                                      className={'orange-hover'}
                                      style={{ color: 'grey' }}
                                    >
                                      Cancel Selected
                                    </span>
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        </Table.HeaderCell>
                        {yourBids && <Table.HeaderCell colSpan={1} />}
                        <Table.HeaderCell
                          width={2}
                          style={{
                            textAlign: 'center',
                            color: '#666',
                          }}
                        >
                          {currentPortal === 'buyer'
                            ? 'Your Buys'
                            : 'Buy Price'}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width={2}
                          style={{
                            textAlign: 'center',
                            color: '#666',
                          }}
                        >
                          {currentPortal === 'buyer'
                            ? 'Sell Price'
                            : 'Your Sells'}
                        </Table.HeaderCell>
                        <Table.HeaderCell colSpan={1} />
                      </Table.Row>
                    </Table.Header>
                  )}
                {exchangeTableLoaded &&
                  props.customFilterLoading &&
                  (currentSelectedLocation?.id ||
                    currentSelectedLocation?.id === 0) && (
                    <Table.Body className="list-body">
                      {exchangeOrderBooks &&
                        exchangeOrderBooks?.map((exchangeOrderBook, index) => {
                          if (!exchangeOrderBook?.product_variant) {
                            return null
                          }
                          let productVariant
                          let product
                          if (exchangeOrderBook?.product_variant) {
                            productVariant = exchangeOrderBook?.product_variant
                            product = productVariant?.product
                          }
                          let groupedBids = Object.values(
                            groupBy(
                              exchangeOrderBook?.bid_orders,
                              (orderBook) => {
                                return orderBook?.total_price
                              }
                            )
                          )
                          let groupedAsks = Object.values(
                            groupBy(
                              exchangeOrderBook?.ask_orders,
                              (orderBook) => {
                                return orderBook?.total_price
                              }
                            )
                          )
                          let hasOpposingSideOrder = false
                          if (currentPortal === 'supplier') {
                            exchangeOrderBook?.bid_orders?.forEach((bid) => {
                              if (!hasOpposingSideOrder) {
                                hasOpposingSideOrder = true
                              }
                            })
                          } else if (currentPortal === 'buyer') {
                            exchangeOrderBook?.ask_orders?.forEach((ask) => {
                              if (!hasOpposingSideOrder) {
                                hasOpposingSideOrder = true
                              }
                            })
                          }
                          let hasCurrentSideOrder = false
                          if (currentPortal === 'buyer') {
                            exchangeOrderBook?.bid_orders?.forEach((bid) => {
                              if (!hasCurrentSideOrder) {
                                hasCurrentSideOrder = true
                              }
                            })
                          } else if (currentPortal === 'supplier') {
                            exchangeOrderBook?.ask_orders?.forEach((ask) => {
                              if (!hasCurrentSideOrder) {
                                hasCurrentSideOrder = true
                              }
                            })
                          }
                          let highestBidOrder
                          let lowestAskOrder
                          if (exchangeOrderBook.highest_bid) {
                            exchangeOrderBook.bid_orders?.forEach((bid) => {
                              if (
                                !highestBidOrder &&
                                bid.total_price ===
                                  exchangeOrderBook.highest_bid
                              ) {
                                highestBidOrder = bid
                              }
                            })
                          }
                          if (exchangeOrderBook.lowest_ask) {
                            exchangeOrderBook.ask_orders?.forEach((ask) => {
                              if (
                                !lowestAskOrder &&
                                ask.total_price === exchangeOrderBook.lowest_ask
                              ) {
                                lowestAskOrder = ask
                              }
                            })
                          }

                          let userAsksOrders = []
                          let userBidsOrders = []
                          exchangeOrderBook?.ask_orders?.forEach((ask) => {
                            user?.active_asks?.forEach((uAsk) => {
                              if (uAsk?.id === ask?.id) {
                                userAsksOrders = [...userAsksOrders, ask]
                              }
                            })
                          })
                          exchangeOrderBook?.bid_orders?.forEach((bid) => {
                            user?.active_bids?.forEach((uBid) => {
                              if (uBid?.id === bid?.id) {
                                userBidsOrders = [...userBidsOrders, bid]
                              }
                            })
                          })

                          let pvList =
                            props.currentPortal === 'supplier' &&
                            userAsksOrders?.length > 0 &&
                            yourBids
                              ? userAsksOrders
                              : props.currentPortal === 'buyer' &&
                                userBidsOrders?.length > 0 &&
                                yourBids
                              ? userBidsOrders
                              : [exchangeOrderBook]

                          return _.map(pvList, (pv, i) => {
                            let userAsk
                            let userBid
                            if (
                              props.currentPortal === 'supplier' &&
                              userAsksOrders?.length > 0 &&
                              yourBids
                            ) {
                              if (currentSelectedLocation?.id === 0) {
                                lowestAskOrder = userAsksOrders[i]
                                userAsk = userAsksOrders[i]
                                if (truckingTypeFilter.length === 1) {
                                  let ask = user?.active_asks.find(
                                    (ask) =>
                                      ask.id === userAsksOrders[i]?.id &&
                                      ask?.cargo_unit?.id ===
                                        truckingTypeFilter[0]
                                  )
                                  if (!ask) {
                                    return
                                  }
                                }
                              } else {
                                let ask = user?.active_asks.find(
                                  (ask) =>
                                    ask.id === userAsksOrders[i]?.id &&
                                    ask?.location?.id ===
                                      currentSelectedLocation?.id
                                )
                                if (ask) {
                                  userAsk = userAsksOrders[i]
                                  if (
                                    truckingTypeFilter.length === 1 &&
                                    ask?.cargo_unit?.id !==
                                      truckingTypeFilter[0]
                                  ) {
                                    return
                                  }
                                } else {
                                  return
                                }
                              }
                            } else if (
                              props.currentPortal === 'buyer' &&
                              userBidsOrders?.length > 0 &&
                              yourBids
                            ) {
                              if (currentSelectedLocation?.id === 0) {
                                highestBidOrder = userBidsOrders[i]
                                userBid = userBidsOrders[i]
                                if (truckingTypeFilter.length === 1) {
                                  let bid = user?.active_bids.find(
                                    (bid) =>
                                      bid.id === userBidsOrders[i]?.id &&
                                      bid?.cargo_unit?.id ===
                                        truckingTypeFilter[0]
                                  )
                                  if (!bid) {
                                    return
                                  }
                                }
                              } else {
                                let bid = user?.active_bids.find(
                                  (bid) =>
                                    bid.id === userBidsOrders[i]?.id &&
                                    bid?.location?.id ===
                                      currentSelectedLocation?.id
                                )
                                if (bid) {
                                  userBid = userBidsOrders[i]
                                  if (
                                    truckingTypeFilter.length === 1 &&
                                    userBid?.cargo_unit?.id !==
                                      truckingTypeFilter[0]
                                  ) {
                                    return
                                  }
                                } else {
                                  return
                                }
                              }
                            } else {
                              userAsk =
                                userAsksOrders.length > 0
                                  ? userAsksOrders[0]
                                  : userAsk
                              userBid =
                                userBidsOrders.length > 0
                                  ? userBidsOrders[0]
                                  : userBid
                            }

                            const hasActiveOrder =
                              (props.currentPortal === 'supplier' && userAsk) ||
                              (props.currentPortal === 'buyer' && userBid)
                            return windowDimensions.width -
                              (openFilter ? 350 : 0) >=
                              1000 ? (
                              <ExchangeTableRow
                                key={index}
                                is_admin={is_admin}
                                hasActiveOrder={hasActiveOrder}
                                userBid={userBid}
                                userAsk={userAsk}
                                currentLocationId={currentSelectedLocation?.id}
                                lowestAskOrder={lowestAskOrder}
                                highestBidOrder={highestBidOrder}
                                hasCurrentSideOrder={hasCurrentSideOrder}
                                hasOpposingSideOrder={hasOpposingSideOrder}
                                hoverRow={hoverRow}
                                selectedLocation={selectedLocation}
                                setHoverRow={setHoverRow}
                                selectedBids={selectedBids}
                                setSelectedBids={setSelectedBids}
                                product={product}
                                productVariant={productVariant}
                                exchangeOrderBook={exchangeOrderBook}
                                yourBids={yourBids}
                                currentPortal={currentPortal}
                                setEditingOrder={setEditingOrder}
                                setEditingExchangeOrderBook={
                                  setEditingExchangeOrderBook
                                }
                                groupedAsks={groupedAsks}
                                groupedBids={groupedBids}
                                user={user}
                                updateWatchlist={updateWatchlist}
                                activeAskIds={activeAskIds}
                                activeBidIds={activeBidIds}
                                index={index}
                                setProductVariant={setProductVariant}
                                setOpenPricingModal={setOpenPricingModal}
                                cancelBid={cancelBid}
                                cancelBidLoading={cancelBidLoading}
                                setInputPrice={setInputPrice}
                                inputSecondaryPrice={inputSecondaryPrice}
                                setInputSecondaryPrice={setInputSecondaryPrice}
                                setOpenEditBidAskModal={setOpenEditBidAskModal}
                                setInputQuantity={setInputQuantity}
                                publicExchange={publicExchange}
                                selectedCommodity={selectedCommodity}
                                selectedCommodityForm={selectedCommodityForm}
                                currentlySelectedTimeframe={
                                  props.currentlySelectedTimeframe
                                }
                                setCurrentlySelectedTimeframe={
                                  props.setCurrentlySelectedTimeframe
                                }
                                currentlySelectedTruckingType={
                                  props.currentlySelectedTruckingType
                                }
                                setCurrentlySelectedTruckingType={
                                  props.setCurrentlySelectedTruckingType
                                }
                              />
                            ) : (
                              <ExchangeTableMobileRow
                                key={index}
                                is_admin={is_admin}
                                hasActiveOrder={hasActiveOrder}
                                userBid={userBid}
                                userAsk={userAsk}
                                currentLocationId={currentSelectedLocation?.id}
                                lowestAskOrder={lowestAskOrder}
                                highestBidOrder={highestBidOrder}
                                hasCurrentSideOrder={hasCurrentSideOrder}
                                hasOpposingSideOrder={hasOpposingSideOrder}
                                hoverRow={hoverRow}
                                selectedLocation={selectedLocation}
                                setHoverRow={setHoverRow}
                                product={product}
                                productVariant={productVariant}
                                exchangeOrderBook={exchangeOrderBook}
                                yourBids={yourBids}
                                currentPortal={currentPortal}
                                groupedAsks={groupedAsks}
                                groupedBids={groupedBids}
                                user={user}
                                updateWatchlist={updateWatchlist}
                                activeAskIds={activeAskIds}
                                activeBidIds={activeBidIds}
                                index={index}
                                setProductVariant={setProductVariant}
                                setOpenPricingModal={setOpenPricingModal}
                                cancelBid={cancelBid}
                                cancelBidLoading={cancelBidLoading}
                                setInputPrice={setInputPrice}
                                inputSecondaryPrice={inputSecondaryPrice}
                                setInputSecondaryPrice={setInputSecondaryPrice}
                                setOpenEditBidAskModal={setOpenEditBidAskModal}
                                setEditingOrder={setEditingOrder}
                                setEditingExchangeOrderBook={
                                  setEditingExchangeOrderBook
                                }
                                setInputQuantity={setInputQuantity}
                                publicExchange={publicExchange}
                                selectedCommodity={selectedCommodity}
                                selectedCommodityForm={selectedCommodityForm}
                                currentlySelectedTimeframe={
                                  props.currentlySelectedTimeframe
                                }
                                setCurrentlySelectedTimeframe={
                                  props.setCurrentlySelectedTimeframe
                                }
                                currentlySelectedTruckingType={
                                  props.currentlySelectedTruckingType
                                }
                                setCurrentlySelectedTruckingType={
                                  props.setCurrentlySelectedTruckingType
                                }
                              />
                            )
                          })
                        })}
                    </Table.Body>
                  )}
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell
                      colSpan="16"
                      style={{
                        paddingBottom:
                          exchangeOrderBooks?.length > 0 ? '10rem' : 0,
                        background: 'none',
                        borderTop: 'none',
                      }}
                    >
                      <Pagination
                        id="mx-pagination"
                        firstItem={null}
                        lastItem={null}
                        pointing
                        secondary
                        floated={'right'}
                        activePage={activePage}
                        totalPages={totalPages}
                        onPageChange={(event, data) => {
                          setActivePage(data.activePage)
                        }}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </>
          )}
        </Grid.Column>
      </Grid>

      <EditOrderModal
        inputExpiration={inputExpiration}
        setInputExpiration={setInputExpiration}
        setOpenEditBidAskModal={setOpenEditBidAskModal}
        openEditBidAskModal={openEditBidAskModal}
        editingExchangeOrderBook={editingExchangeOrderBook}
        editingOrder={editingOrder}
        globalSettings={globalSettings}
        user={user}
        is_admin={is_admin}
        business={business}
        currentPortal={currentPortal}
        inputPrice={inputPrice}
        productVariant={productVariant}
        setEditingOrder={setEditingOrder}
        setEditingExchangeOrderBook={setEditingExchangeOrderBook}
        setExchangeTableLoaded={setExchangeTableLoaded}
        setReloadExchange={setReloadExchange}
        setActionBeforeWsReset={setActionBeforeWsReset}
      />
      <WatchlistModal
        handleClose={handleCloseModal}
        user={user}
        business={business}
        updateUser={updateUser}
        setStartWatchlistModal={setStartWatchlistModal}
        startWatchlistModal={startWatchlistModal}
        setSelectedProductId={setSelectedProductId}
        selectedProductLoading={selectedProductLoading}
        setSelectedProductLoading={setSelectedProductLoading}
        setLoadingNextModal={setLoadingNextModal}
        setAttributesForSelectedProduct={setAttributesForSelectedProduct}
        selectedProductId={selectedProductId}
        loadingNextModal={loadingNextModal}
        setOpenAttributesModal={setOpenAttributesModal}
        setProductForWatchlistModal={setProductForWatchlistModal}
      />
      <AttributesModal
        setOpenAttributesModal={setOpenAttributesModal}
        handleClose={handleCloseModal}
        productForWatchlistModal={productForWatchlistModal}
        attributesForSelectedProduct={attributesForSelectedProduct}
        loadingNextModal={loadingNextModal}
        openAttributesModal={openAttributesModal}
        user={user}
        selectedProductId={selectedProductId}
        business={business}
        updateWatchlist={updateWatchlist}
        setLoadingNextModal={setLoadingNextModal}
        watchlistSubmitting={watchlistSubmitting}
        setWatchlistSubmitting={setWatchlistSubmitting}
        setAttributeSelectionsFromDropdown={setAttributeSelectionsFromDropdown}
        attributeSelectionsFromDropdown={attributeSelectionsFromDropdown}
        currentPortal={currentPortal}
      />
      <CopyWatchlistModal
        copyWatchlistModal={copyWatchlistModal}
        setCopyWatchlistModal={setCopyWatchlistModal}
        business={business}
        user={user}
        currentPortal={currentPortal}
      />
    </>
  )
}
export default ExchangeTable
