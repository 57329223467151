export const ProductForm = {
  model: 'Product',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'dropdown',
        },
      },
    },
  ],
}
