import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useAxios from '../../../lib/auth/useAxios'

const AdminTickerRow = (props) => {
  const axios = useAxios()
  const [openEditTicker, setOpenEditTicker] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const handleClose = () => {
    setOpenEditTicker(false)
    props.setReload(true)
  }

  const deleteTicker = async () => {
    await axios.delete(`/main/ticker-fields/${props.ticker.id}/`)
    props.setReload(true)
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell width={2}>{props?.ticker?.name}</Table.Cell>
        <Table.Cell width={2}>{props?.ticker?.description}</Table.Cell>

        <Table.Cell width={2}>
          <Button
            color="grey"
            icon="edit"
            onClick={() => setOpenEditTicker(true)}
          />
          <Button
            color="red"
            icon="x"
            onClick={() => setConfirmationModal(true)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => handleClose()}
        onOpen={() => setOpenEditTicker(true)}
        open={openEditTicker}
      >
        <Modal.Header>Edit Ticker</Modal.Header>
        <Modal.Content>
          <MickeyForm
            instanceId={props.ticker.id}
            formName={'TickerFieldForm'}
            tenantAware={false}
            model={'TickerField'}
            buttonFloatedRight={true}
            successMessage={'Ticker Field Added!'}
            failureMessage={'Error adding ticker field.'}
            url={'/main/ticker-fields/'}
          />
        </Modal.Content>
      </Modal>
      <ConfirmationModal
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        yesFunction={deleteTicker}
        noFunction={() => setConfirmationModal(false)}
        archiveItem={'this ticker'}
      />
    </>
  )
}

export default AdminTickerRow
