import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import MickeyForm from '../../../components/Forms/MickeyForm'
import env from '../../../env'
import Hours from './Hours'
import { format_phone_number } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

const ManageLocationsRow = (props) => {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const [openDeleteLocationsModal, setOpenDeleteLocationsModal] =
    useState(false)
  const [openEditLocationModal, setOpenEditLocationModal] = useState(false)
  const [deleteLocationId, setDeleteLocationId] = useState()
  const deleteLocation = async () => {
    setOpenDeleteLocationsModal(false)
    await axios
      .patch(`/main/locations/${deleteLocationId}/?tenant_aware=false`, {
        archive_location_id: deleteLocationId,
      })
      .then(() => {
        toast.success('Successfully deleted location')
      })
      .finally(() => setDeleteLocationId(''))

    props.setReload(true)
  }
  const handleEditLocation = async () => {
    setOpenEditLocationModal(false)
    props.setReload(true)
  }
  return (
    <>
      <Table.Row>
        <Table.Cell
          onClick={() => {
            setOpenEditLocationModal(true)
          }}
        >
          {props.location?.name}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            setOpenEditLocationModal(true)
          }}
        >
          {props.location?.address_1}
          {props.location?.address_2 && `, ${props.location?.address_2}`}
          {props.location?.city && `, ${props.location?.city}`}
          {props.location?.state && `, ${props.location?.state}`}
          {props.location?.zip_code && `, ${props.location?.zip_code}`}
          {props.location?.country && `, ${props.location?.country}`}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            setOpenEditLocationModal(true)
          }}
        >
          {format_phone_number(props.location?.phone)}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            setOpenEditLocationModal(true)
          }}
        >
          {props.location?.created_at}
        </Table.Cell>
        {hasPermission(user, 'Delete Location') && (
          <Table.Cell
            style={{
              maxWidth: '175px',
            }}
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <Button
                className="transparent"
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={() => {
                  setOpenDeleteLocationsModal(true)
                  setDeleteLocationId(props.location?.id)
                }}
              >
                Delete Location
              </Button>
            </div>
          </Table.Cell>
        )}
      </Table.Row>
      <Modal
        onClose={() => handleEditLocation()}
        onOpen={() => setOpenEditLocationModal(true)}
        open={openEditLocationModal}
      >
        <Modal.Header>Edit {props.location?.name}</Modal.Header>
        <p
          style={{
            padding: '1.25rem 1.5rem',
            margin: '0',
          }}
        >
          Changes will update automatically. When you're finished click the
          Close button below.
        </p>
        <Modal.Content>
          <MickeyForm
            formName={'LocationForm'}
            model={'Location'}
            disabled={!hasPermission(user, 'Update Location')}
            instanceId={props.location?.id}
            tenantAware={false}
            url={'/main/locations/'}
            urlParams={{
              supplier_id: props.supplier?.id,
              buyer_id: props.buyer?.id,
              business_id: props?.business?.id,
            }}
          />
          <Hours location={props.location} />
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button onClick={() => handleEditLocation()}>Close</Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => setOpenDeleteLocationsModal(false)}
        onOpen={() => setOpenDeleteLocationsModal(true)}
        open={openDeleteLocationsModal}
      >
        <Modal.Header>Delete Location</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this location?</p>
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button onClick={() => deleteLocation()}>Yes</Button>
          <Button onClick={() => setOpenDeleteLocationsModal(false)}>No</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ManageLocationsRow
