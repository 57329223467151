import React, { useState, useEffect } from 'react'
import { Table, Modal, Button, Form } from 'semantic-ui-react'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useAxios from '../../../lib/auth/useAxios'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { Editor } from 'react-draft-wysiwyg'
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import env from '../../../env'

const AdminPressRow = (props) => {
  const axios = useAxios()
  const [openEditPressModal, setOpenEditPressModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [showError, setShowError] = useState(false)

  const updatePressRelease = async () => {
    if (
      !props.pressReleaseTitle ||
      !props.pressReleaseTitle.length > 0 ||
      !props.pressReleaseSubheader ||
      !props.pressReleaseSubheader.length > 0 ||
      !props.pressReleasePubDate ||
      !props.editorState?.getCurrentContent()?.hasText()
    ) {
      setShowError(true)
    } else {
      setOpenEditPressModal(false)
      await axios.patch(`/main/press-releases/${props.pressRelease.id}/`, {
        title: props.pressReleaseTitle,
        subheader: props.pressReleaseSubheader,
        publish_date: props.pressReleasePubDate.toLocaleDateString('en-US'),
        content: draftToHtml(
          convertToRaw(props.editorState.getCurrentContent())
        ),
      })
      props.setReload(true)
      setShowError(false)
    }
  }

  const handleClose = () => {
    setOpenEditPressModal(false)
    setShowError(false)
    props.setReload(true)
  }

  const deletePress = async () => {
    await axios.delete(`/main/press-releases/${props.pressRelease.id}/`)
    props.setReload(true)
  }

  useEffect(() => {
    props.setPressReleaseTitle(props.pressRelease.title)
    props.setPressReleaseSubheader(props.pressRelease.subheader)
    props.setPressReleasePubDate(new Date(props.pressRelease.publish_date))
    props.setEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.pressRelease.content)
        )
      )
    )
  }, [])

  const handleOpen = () => {
    setOpenEditPressModal(true)
    props.setPressReleaseTitle(props.pressRelease.title)
    props.setPressReleaseSubheader(props.pressRelease.subheader)
    props.setPressReleasePubDate(new Date(props.pressRelease.publish_date))
    props.setEditorState(() =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(props.pressRelease.content)
        )
      )
    )
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell width={2}>{props?.pressRelease?.title}</Table.Cell>
        <Table.Cell width={2}>{props?.pressRelease?.created_at}</Table.Cell>
        <Table.Cell width={2}>{props?.pressRelease?.publish_date}</Table.Cell>
        <Table.Cell>
          <div
            className="press-release-content"
            style={{ fontFamily: 'Brown' }}
            dangerouslySetInnerHTML={{
              __html: props.pressRelease?.content.slice(0, 375),
            }}
          />

          {/* {props.pressRelease.content.slice(0, 375)}{props.pressRelease.content.length >= 375 ? "..." : ""} */}
        </Table.Cell>
        <Table.Cell width={2}>
          <Button color="grey" icon="edit" onClick={() => handleOpen()} />
          <Button
            color="red"
            icon="x"
            onClick={() => setConfirmationModal(true)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => handleClose()}
        onOpen={() => setOpenEditPressModal(true)}
        open={openEditPressModal}
      >
        <Modal.Header>Edit Press Release</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                placeholder="Title"
                value={props.pressReleaseTitle}
                label="Title"
                onChange={(e, d) => props.handleTitle(d?.value)}
              />
              {(!props?.pressReleaseTitle ||
                !props?.pressReleaseTitle?.length > 0) &&
                showError && (
                  <p style={{ color: 'red' }}>
                    <small>This field is required</small>
                  </p>
                )}
            </Form.Field>
            <Form.Field>
              <Form.Input
                placeholder="Subheader"
                label="Subheader"
                value={props.pressReleaseSubheader}
                onChange={(e, d) => props.handleSubheader(d?.value)}
              />
              {(!props?.pressReleaseSubheader ||
                !props?.pressReleaseSubheader?.length > 0) &&
                showError && (
                  <p style={{ color: 'red' }}>
                    <small>This field is required</small>
                  </p>
                )}
            </Form.Field>
            <label
              style={{
                display: 'block',
                margin: '0em 0em 0.28571429rem 0em',
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '0.92857143em',
              }}
            >
              Publish Date
            </label>
            <div
              className="customDatePickerWidth"
              style={{ marginBottom: '1rem' }}
            >
              <SemanticDatepicker
                value={props.pressReleasePubDate}
                onChange={(e, d) => props.handlePub(d?.value)}
                format={'MMM D, YYYY'}
                clearable={true}
              />
              {!props.pressReleasePubDate && showError && (
                <p style={{ color: 'red' }}>
                  <small>This field is required</small>
                </p>
              )}
            </div>
            <Editor
              toolbar={{
                options: [
                  'inline',
                  'blockType',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'embedded',
                  'emoji',
                  'image',
                  'history',
                ],
              }}
              editorState={props.editorState}
              editorClassName="editor-wrapper"
              onEditorStateChange={props.setEditorState}
            />
            {!props.editorState?.getCurrentContent()?.hasText() &&
              showError && (
                <p style={{ color: 'red' }}>
                  <small>This field is required</small>
                </p>
              )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => handleClose()}
            color="red"
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => updatePressRelease()}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Update
          </Button>
        </Modal.Actions>
      </Modal>
      <ConfirmationModal
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        yesFunction={deletePress}
        noFunction={() => setConfirmationModal(false)}
        archiveItem={'this press release'}
      />
    </>
  )
}

export default AdminPressRow
