import { Header, Menu, Segment } from 'semantic-ui-react'
import './ProfileDashboard.css'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'

function ProfileDashboard(props) {
  const { business } = props
  const axios = useAxios()
  const [dashboardData, setDashboardData] = useState()
  const [loading, setLoading] = useState(true)

  async function getOrderDashboardData() {
    setLoading(true)
    let res = await axios.get(`/main/admin-orders/dashboard/`, {
      params: {
        business_id: business?.id,
      },
    })
    if (res?.data) {
      setDashboardData(res?.data)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (business?.id) {
      getOrderDashboardData()
    }
  }, [business?.id])

  const formatChanges = (data) => {
    data = parseFloat(data)
    if (!data) return `+0.0%`
    return `${data >= 0 ? '+' : ''}${data}%`
  }

  const getColor = (data) => {
    data = parseFloat(data)
    return data >= 0 ? '#46AF46' : 'red'
  }

  return (
    <Segment loading={loading} className="dashboard-segment">
      <Menu secondary widths={4} className="business-dashboard-menu">
        <Menu.Item>
          <div>
            <p>Total Deals</p>
            <Header as={'h1'}> {dashboardData?.total_order_count} </Header>
            <p>
              <span style={{ color: getColor(dashboardData?.order_changes) }}>
                {formatChanges(dashboardData?.order_changes)}
              </span>
              more last month
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div>
            <p>Locations</p>
            <Header as={'h1'}> {dashboardData?.location_count} </Header>
            <p>
              <span style={{ color: '#46AF46' }}>
                {' '}
                {dashboardData?.cmo_location_count}{' '}
              </span>
              location added this month
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div>
            <p>Average Sales Amount</p>
            <Header as={'h1'}>
              {' '}
              {formatTotalPrice(dashboardData?.avg_sales)}{' '}
            </Header>
            <p>
              <span
                style={{ color: getColor(dashboardData?.avg_sales_changes) }}
              >
                {formatChanges(dashboardData?.avg_sales_changes)}
              </span>
              from last month
            </p>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div>
            <p>Active Deals</p>
            <Header as={'h1'}> {dashboardData?.active_order_count} </Header>
            <p>
              <span
                style={{ color: '#46AF46' }}
                onClick={() => window.location.replace('/portal/admin/deals/')}
              >
                View
              </span>
              all orders
            </p>
          </div>
        </Menu.Item>
      </Menu>
    </Segment>
  )
}

export default ProfileDashboard
