import React, { useEffect, useState } from 'react'

import {
  Button,
  Popup,
  Table,
  Modal,
  Label,
  Input,
  PopupContent,
  List,
} from 'semantic-ui-react'
import { formatAddress } from '../../../helpers/helpers'
import fileDownload from 'js-file-download'
import useAxios from '../../../lib/auth/useAxios'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../../components/ConfirmationModal'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import MickeyForm from '../../../components/Forms/MickeyForm'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import Checkbox from '@mui/material/Checkbox'
import AdminOrderFulfillmentSelectedRow from './AdminOrderFulfillmentSelectedRow'
import { CaretDown, CaretRight, Check, Truck } from 'phosphor-react'
import { env } from 'process'
import '../AdminFulfillment/AdminFulfillment.css'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import AutoField from '../../../components/AutoField/AutoField'

const AdminOrderFulfillmentRow = (props) => {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update Material List')
  const canDelete = hasPermission(user, 'Delete Material List')
  const [confirmDeleteItem, setConfirmDeleteItem] = useState(false)
  const [reloadTasks, setReloadTasks] = useState()
  const [openMarkDeliveredModal, setOpenMarkDeliveredModal] = useState(false)
  const [openMarkShippedModal, setOpenMarkShippedModal] = useState(false)
  const [supplierPayout, setSupplierPayout] = useState(
    props.cargoUnitInstance.supplier_payout
  )
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [markAsPopup, setMarkAsPopup] = useState(false)

  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const [shippedQuantities, setShippedQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [shippedUOMs, setShippedUOMs] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return cci.order_item?.unit?.id
    })
  )
  const [deliveredQuantities, setDeliveredQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [grossWeightQuantities, setGrossWeightQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [tareWeightQuantities, setTareWeightQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [netWeightQuantities, setNetWeightQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [deliveredUOMs, setDeliveredUOMs] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return cci.order_item?.unit?.id
    })
  )
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false)

  useEffect(() => {
    props.updateTotalTruckingPrice()
  }, [props.cargoUnitInstance?.trucking_price])

  async function handleMarkOrderDelivered(status, data) {
    setAcceptLoading(false)
    if (status === 'success') {
      setOpenMarkDeliveredModal(false)
      props.setCargoUnitInstance(data)
      props.setReload(true)
    }
  }

  async function handleMarkOrderShipped(status, data) {
    setAcceptLoading(false)
    if (status === 'success') {
      setOpenMarkShippedModal(false)
      props.setCargoUnitInstance(data)
      props.setReload(true)
    }
  }
  async function deleteCargo() {
    let res = await axios.delete(
      `/main/admin-cargo-unit-instances/${props.cargoUnitInstance?.id}/`
    )
    setConfirmDeleteItem(false)
    if (res.status < 300) {
      props.setReload(true)
      let sm = [...props.selectedCargos]
      let index = sm.indexOf(props.cargoUnitInstance?.id)
      if (index > -1) {
        sm.splice(index, 1)
        props.setSelectedCargos(sm)
      }
      toast.success('Deleted Cargo')
    } else {
      toast.error('Error deleting cargo.')
    }
  }

  useEffect(() => {
    let tempNetWeightQuantities = []
    grossWeightQuantities?.forEach((gwq, index) => {
      tempNetWeightQuantities[index] = gwq - tareWeightQuantities[index]
    })
    setNetWeightQuantities(tempNetWeightQuantities)
  }, [grossWeightQuantities, tareWeightQuantities])
  console.log(props.cargoUnitInstance)
  console.log(props)
  return (
    <>
      <Table.Row
        selectable
        className={`fulfillment-row ${
          props.cargoUnitInstance?.id === props.selectedCargoUnitInstance?.id
            ? 'selection'
            : ''
        }`}
      >
        <Table.Cell width={2}>
          <span
            onClick={() => {
              if (
                props.selectedCargoUnitInstance?.id ===
                props.cargoUnitInstance?.id
              ) {
                props.setSelectedCargoUnitInstance(null)
              } else {
                props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
              }
            }}
          >
            <CaretRight size={20} />
          </span>
          {canUpdate && (
            <Checkbox
              color={'primary'}
              checked={props.selectedCargos.includes(
                props.cargoUnitInstance?.id
              )}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
              onClick={(e, d) => {
                let sm = [...props.selectedCargos]
                if (sm.includes(props.cargoUnitInstance?.id)) {
                  const index = sm.indexOf(props.cargoUnitInstance?.id)
                  if (index > -1) {
                    // only splice array when item is found
                    sm.splice(index, 1)
                    props.setSelectedCargos(sm)
                  }
                } else {
                  sm.push(props.cargoUnitInstance.id)
                  props.setSelectedCargos(sm)
                }
              }}
            />
          )}
          {props.cargoUnitInstance?.shipper_load_id}
        </Table.Cell>
        <Table.Cell
          width={2}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.created_at}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.supplier_name}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.estimated_ship_date
            ? props.cargoUnitInstance?.estimated_ship_date
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.shipped_date
            ? props.cargoUnitInstance?.shipped_date
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.estimated_delivery_date
            ? props.cargoUnitInstance?.estimated_delivery_date
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.delivery_date
            ? props.cargoUnitInstance?.delivery_date
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={2}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.pickup_location
            ? formatAddress(props.cargoUnitInstance?.pickup_location)
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={2}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.delivery_location
            ? formatAddress(props.cargoUnitInstance?.delivery_location)
            : '--'}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          {props.cargoUnitInstance?.trucking_price
            ? formatTotalPrice(props.cargoUnitInstance?.trucking_price)
            : '$0.00'}
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          <AutoField
            icon={'dollar'}
            iconPosition="left"
            model={'CargoUnitInstance'}
            field={'supplier_payout'}
            url={'/main/cargo-unit-instances/'}
            instanceId={props.cargoUnitInstance.id}
            value={props.cargoUnitInstance.supplier_payout}
            placeholder=""
            type="number"
            step="0.01"
            size={'small'}
            style={{
              width: '100px',
              display: 'inline-block',
            }}
          />
        </Table.Cell>
        <Table.Cell
          positive={props.cargoUnitInstance?.delivered}
          width={1}
          onClick={() => {
            if (
              props.selectedCargoUnitInstance?.id ===
              props.cargoUnitInstance?.id
            ) {
              props.setSelectedCargoUnitInstance(null)
            } else {
              props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
            }
          }}
        >
          <Label
            color={
              props.cargoUnitInstance?.delivered
                ? 'green'
                : !props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped
                ? 'orange'
                : !props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked
                ? 'grey'
                : ''
            }
            content={
              <>
                {props.cargoUnitInstance?.delivered && 'Delivered'}
                {!props.cargoUnitInstance?.delivered &&
                  !props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped &&
                  'In Transit'}
                {!props.cargoUnitInstance?.delivered &&
                  !props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked &&
                  'Booked'}
                {!props.cargoUnitInstance?.delivered &&
                  !props.cargoUnitInstance?.supplier_shipped &&
                  !props.cargoUnitInstance?.trucking_booked &&
                  'Unbooked'}
              </>
            }
          />
        </Table.Cell>
        {canUpdate && (
          <Table.Cell width={1}>
            <div id="selected-items-actions-no-margin">
              <Popup
                id={'mark-as-popup'}
                mountNode={document.getElementsByClassName(`mark-as-button`)[0]}
                on={'click'}
                position={'bottom right'}
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  width: '190px',
                }}
                open={markAsPopup}
                onOpen={() => setMarkAsPopup(true)}
                onClose={() => setMarkAsPopup(false)}
                offset={[0, 0]}
                trigger={
                  <Button
                    className="mark-as-button"
                    labelPosition={'left'}
                    color={'primary'}
                    style={{
                      '--primaryColor': 'grey',
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      '--rotate': markAsPopup ? 'rotate(180deg)' : '',
                    }}
                    size={'tiny'}
                    icon={<CaretDown size={20} weight="bold" />}
                    content={'Mark'}
                    onClick={() => {
                      setMarkAsPopup(true)
                    }}
                  />
                }
              >
                <PopupContent>
                  <List
                    style={{ border: '1px solid grey', borderRadius: '10px' }}
                  >
                    <List.Item
                      style={{ '--primaryColor': 'grey' }}
                      disabled={props.cargoUnitInstance?.supplier_shipped}
                      onClick={() => {
                        setMarkAsPopup(false)
                        setOpenMarkShippedModal(true)
                      }}
                    >
                      <Truck size={20} weight="bold" />
                      <b>Mark As Shipped</b>
                    </List.Item>
                    <List.Item
                      style={{ '--primaryColor': 'grey' }}
                      disabled={props.cargoUnitInstance?.delivered}
                      onClick={() => {
                        setMarkAsPopup(false)
                        setOpenMarkDeliveredModal(true)
                      }}
                    >
                      <Check size={20} weight="bold" />
                      <b>Mark As Delivered</b>
                    </List.Item>
                  </List>
                </PopupContent>
              </Popup>
            </div>
          </Table.Cell>
        )}
      </Table.Row>
      {props.selectedCargoUnitInstance?.id == props.cargoUnitInstance?.id && (
        <AdminOrderFulfillmentSelectedRow
          selectedCargoUnitInstance={props.selectedCargoUnitInstance}
          cargoUnitInstance={props.cargoUnitInstance}
          setCargoUnitInstance={props.setCargoUnitInstance}
          updateTotalTruckingPrice={props.updateTotalTruckingPrice}
          setRefreshPizzaTracker={props.setRefreshPizzaTracker}
          orderId={props.orderId}
          order={props.order}
          reload={props.reload}
          setReload={props.setReload}
        />
      )}
      <Modal
        onClose={() => setOpenMarkShippedModal(false)}
        onOpen={() => setOpenMarkShippedModal(true)}
        open={openMarkShippedModal}
      >
        <Modal.Header>Mark Cargo Shipped</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.cargoUnitInstance?.container_items?.map((cci, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={cci?.order_item} />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder={'Quantity'}
                        min={0}
                        type={'number'}
                        step={0.01}
                        value={shippedQuantities[index]}
                        onChange={(e, d) => {
                          if (d.value < 0) {
                            toast.error('Please Enter Valid Quantity')
                            return
                          }
                          let sq = [...shippedQuantities]
                          sq[index] = d.value
                          setShippedQuantities(sq)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={shippedUOMs[index]}
                        params={{
                          page_size: 25,
                          product_id:
                            cci?.order_item?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sq = [...shippedUOMs]
                          sq[index] = d.value
                          setShippedUOMs(sq)
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <MickeyForm
            model={'CargoUnitInstance'}
            formName={'MarkOrderShippedForm'}
            successMessage={'Cargo marked as shipped!'}
            failureMessage={'Error marking cargo as shipped, please try again.'}
            url={'/main/mark-cargo-unit-instance-shipped/'}
            handleForm={handleMarkOrderShipped}
            buttonLabel={'Mark Shipped'}
            values={{
              shipped_quantities: shippedQuantities,
              shipped_uoms: shippedUOMs,
              ccis: props.cargoUnitInstance?.container_items?.map(
                (cci, index) => {
                  return cci?.id
                }
              ),
              cargo_unit_instance_id: props.cargoUnitInstance?.id,
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size={'large'}
        onClose={() => setOpenMarkDeliveredModal(false)}
        onOpen={() => setOpenMarkDeliveredModal(true)}
        open={openMarkDeliveredModal}
      >
        <Modal.Header>Mark Cargo Delivered</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={'six'}>Product</Table.HeaderCell>
                {props.cargoUnitInstance?.delivery_type === 'Scale' && (
                  <>
                    <Table.HeaderCell width={2}>Gross Weight</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Tare Weight</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Net Weight</Table.HeaderCell>
                  </>
                )}
                {props.cargoUnitInstance?.delivery_type ===
                  'Atomic Measurement' && (
                  <Table.HeaderCell width={6}>Quantity</Table.HeaderCell>
                )}
                <Table.HeaderCell width={4}>Unit of Measure</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.cargoUnitInstance?.container_items?.map((cci, index) => {
                return (
                  <Table.Row>
                    <Table.Cell width={6}>
                      <OrderItemDetailTwo orderItem={cci?.order_item} />
                    </Table.Cell>
                    {props.cargoUnitInstance?.delivery_type === 'Scale' && (
                      <>
                        <Table.Cell width={2}>
                          <Input
                            placeholder={'Gross Weight'}
                            min={0}
                            type={'number'}
                            step={0.01}
                            value={grossWeightQuantities[index]}
                            onChange={(e, d) => {
                              if (d.value < 0) {
                                toast.error('Please Enter Valid Gross Weight')
                                return
                              }
                              let sq = [...grossWeightQuantities]
                              sq[index] = d.value
                              setGrossWeightQuantities(sq)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Input
                            placeholder={'Tare Weight'}
                            min={0}
                            type={'number'}
                            step={0.01}
                            value={tareWeightQuantities[index]}
                            onChange={(e, d) => {
                              if (d.value < 0) {
                                toast.error('Please Enter Valid Tare Weight')
                                return
                              }
                              let sq = [...tareWeightQuantities]
                              sq[index] = d.value
                              setTareWeightQuantities(sq)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <Input
                            placeholder={'Net Weight'}
                            disabled
                            min={0}
                            type={'number'}
                            step={0.01}
                            value={netWeightQuantities[index]}
                            onChange={(e, d) => {
                              if (d.value < 0) {
                                toast.error('Please Enter Valid Net Weight')
                                return
                              }
                              let sq = [...netWeightQuantities]
                              sq[index] = d.value
                              setNetWeightQuantities(sq)
                            }}
                          />
                        </Table.Cell>
                      </>
                    )}
                    {props.cargoUnitInstance?.delivery_type ===
                      'Atomic Measurement' && (
                      <Table.Cell width={6}>
                        <Input
                          placeholder={'Quantity'}
                          min={0}
                          type={'number'}
                          step={0.01}
                          value={deliveredQuantities[index]}
                          onChange={(e, d) => {
                            if (d.value < 0) {
                              toast.error('Please Enter Valid Quantity')
                              return
                            }
                            let sq = [...deliveredQuantities]
                            sq[index] = d.value
                            setDeliveredQuantities(sq)
                          }}
                        />
                      </Table.Cell>
                    )}
                    <Table.Cell width={4}>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={deliveredUOMs[index]}
                        params={{
                          page_size: 25,
                          product_id:
                            cci?.order_item?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sq = [...deliveredUOMs]
                          sq[index] = d.value
                          setDeliveredUOMs(sq)
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <MickeyForm
            model={'CargoUnitInstance'}
            formName={'MarkOrderDeliveredForm'}
            successMessage={'Cargo marked as delivered!'}
            failureMessage={
              'Error marking cargo as delivered, please try again.'
            }
            url={'/main/mark-cargo-unit-instance-delivered/'}
            handleForm={handleMarkOrderDelivered}
            buttonLabel={'Mark Delivered'}
            checkListTypeOfValue={true}
            values={{
              gross_weight_quantities: grossWeightQuantities,
              tare_weight_quantities: tareWeightQuantities,
              delivered_quantities: deliveredQuantities,
              delivered_uoms: deliveredUOMs,
              ccis: props.cargoUnitInstance?.container_items?.map(
                (cci, index) => {
                  return cci?.id
                }
              ),
              cargo_unit_instance_id: props.cargoUnitInstance?.id,
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenAddTaskModal(false)}
        onOpen={() => setOpenAddTaskModal(true)}
        open={openAddTaskModal}
      >
        <Modal.Header>Add New Fulfillment Task</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AddTaskForm'}
            model={'Task'}
            url={'/main/admin-tasks/'}
            tenantAware={false}
            values={{
              cargo_unit_instance_id: props.selectedCargoUnitInstance?.id,
              removable: true,
            }}
            handleForm={() => {
              setOpenAddTaskModal(false)
              setReloadTasks(true)
            }}
            buttonLabel={'Add Task'}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default AdminOrderFulfillmentRow
