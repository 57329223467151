import { Checkbox, Table } from 'semantic-ui-react'
import { useEffect } from 'react'
import MickeyTable from '../MickeyTable/MickeyTable'
import './SettlementStatement.css'
import { toast } from 'react-toastify'
import moment from 'moment'

function SettlementStatementFormCuiTableRow(props) {
  const { cargoUnitInstance, selectedCargoIds, handleToggleClick } = props

  return (
    <Table.Row selectable>
      <Table.Cell width={1}>
        <Checkbox
          color={'primary'}
          checked={selectedCargoIds.includes(cargoUnitInstance?.id)}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
          onClick={(e, d) => handleToggleClick(cargoUnitInstance?.id)}
        />
        {cargoUnitInstance?.shipper_load_id}
      </Table.Cell>
      <Table.Cell width={1}>
        {cargoUnitInstance?.trucking_company?.name}
      </Table.Cell>
      <Table.Cell width={1}>
        {cargoUnitInstance?.order?.order_number}
      </Table.Cell>
      <Table.Cell width={1}>{cargoUnitInstance?.buyer_name}</Table.Cell>
      <Table.Cell width={1}>
        {cargoUnitInstance?.estimated_delivery_date}
      </Table.Cell>
    </Table.Row>
  )
}

function SettlementStatementFormCuiTable(props) {
  const { fieldName, value, handleInputChange, values, error } = props
  let headerRow = [
    'Load ID',
    'Trucking Company',
    'Order',
    'Buyer',
    'Delivery Date',
  ]

  const url_params = {
    delivered: 'true',
    sort_order: 'asc',
    sort_by: 'estimated_delivery_date',
    light_serializer: true,
    exclude_container_items: true,
  }
  if (
    values?.start_date &&
    values?.end_date &&
    values?.start_date < values?.end_date
  ) {
    url_params['estimated_delivery_date_start'] = moment(
      values?.start_date
    ).format('YYYY-MM-DD')
    url_params['estimated_delivery_date_end'] = moment(values?.end_date).format(
      'YYYY-MM-DD'
    )
  }
  if (values?.supplier_id) {
    url_params['supplier_id'] = values?.supplier_id
  }

  const handleToggleClick = (id) => {
    let sm = [...value]
    if (sm.includes(id)) {
      const index = sm.indexOf(id)
      if (index > -1) {
        sm.splice(index, 1)
      }
    } else {
      sm.push(id)
    }
    let d = {
      name: fieldName,
      value: sm,
    }
    handleInputChange('', d)
  }

  useEffect(() => {
    if (
      values?.start_date &&
      values?.end_date &&
      values?.start_date > values?.end_date
    ) {
      toast.error('Start date should be less than end date.')
    }
    if (!values?.start_date || !values?.end_date || !values?.supplier_id) {
      let d = {
        name: fieldName,
        value: [],
      }
      handleInputChange('', d)
    }
  }, [JSON.stringify(values)])

  return (
    <>
      {values?.start_date &&
        values?.end_date &&
        values?.start_date < values?.end_date &&
        values?.supplier_id && (
          <>
            <MickeyTable
              headerRow={headerRow}
              url={'/main/admin-cargo-unit-instances/'}
              urlParams={url_params}
              basicSegment
              renderBodyRow={(data, index) => {
                return (
                  <>
                    <SettlementStatementFormCuiTableRow
                      cargoUnitInstance={data}
                      selectedCargoIds={value}
                      handleToggleClick={handleToggleClick}
                    />
                  </>
                )
              }}
              emptyIcon={'truck'}
              emptyMessage={'Fulfillment will populate here'}
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </>
        )}
    </>
  )
}

export default SettlementStatementFormCuiTable
