import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'intro.js/introjs.css'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './views/shared/Auth/Login/Login'
import ResetPassword from './views/shared/Auth/ResetPassword/ResetPassword'
import ResetPasswordComplete from './views/shared/Auth/ResetPassword/ResetPasswordComplete'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import { Segment } from 'semantic-ui-react'
import LoadingBar from 'react-top-loading-bar'
import { useDispatch, useSelector } from 'react-redux'
import Payment from './views/shared/Payment/Payment'
import {
  setLoaderPercentage,
  addTenant,
  addGlobalSettings,
} from './features/globals/globalSlice'
import { init, identify, Identify } from '@amplitude/analytics-node'
import Admin from './components/Menu/Admin'
import useAxios from './lib/auth/useAxios'
import { useAuth } from './lib/auth/useAuth'
import { objFull } from './lib/utils/utils'
import { useWebsocket } from './hooks/useWebsocket'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import ReactGA from 'react-ga4'
import MxEvents from './views/mx/MxEvents/MxEvents'
import MxNews from './views/mx/MxNews/MxNews'
import {
  loggedOut,
  removeBusiness,
  removeUser,
} from './features/user/userSlice'
// MX
import MXAbout from './views/mx/MXAbout/MXAbout'
import MXHeader from './views/mx/mx_shared/MXMain/MXHeader'
import MXFooter from './views/mx/mx_shared/MXMain/MXFooter'
import MXHomepage from './views/mx/mx_shared/MXHomepage/MXHomepage'
import MXExchange from './views/mx/mx_shared/MXExchange/MXExchange'
import MxContactUs from './views/mx/MxContactUs/MxContactUs'
import MxNotification from './views/mx/MXNotification/MxNotification'
import MXSignUp from './views/mx/MXSignUp/MXSignUp'
import MXProduct from './views/mx/mx_shared/MXMain/MXProduct'
import MXProductVariant from './views/mx/mx_shared/MXMain/MXProductVariant'
import AdminDashboard from './views/admin/AdminDashboard/AdminDashboard'
import AdminManageUsers from './views/admin/AdminDashboard/AdminManageUsers'
import AdminDocuments from './views/admin/AdminDashboard/AdminDocuments'
import AdminManageLocations from './views/admin/AdminDashboard/AdminManageLocations'
import AdminBusiness from './views/admin/AdminBusinesses/AdminBusiness'
import AdminOrder from './views/admin/AdminOrder/AdminOrder'
import AdminOrders from './views/admin/AdminOrders/AdminOrders'
import AdminOrderBooks from './views/admin/AdminDashboard/AdminOrderBook/AdminOrderBooks'
import AdminProducts from './views/admin/AdminProducts/AdminProducts'
import AdminBusinesses from './views/admin/AdminBusinesses/AdminBusinesses'
import Buyer from './components/Menu/Buyer'
import Supplier from './components/Menu/Supplier'
import SupplierOrder from './views/supplier/Orders/SupplierOrder'
import Contacts from './views/supplier/Contacts/Contacts'
import Inventory from './views/supplier/Inventory/Inventory'
import SupplierOrders from './views/supplier/Orders/SupplierOrders'
import useWindowDimensions from './hooks/useWindowDimensions'
import BuyerOrder from './views/buyer/Orders/BuyerOrder'
import BuyerOrders from './views/buyer/Orders/BuyerOrders'
import Setting from './views/mx/Settings'
import MXYourBids from './views/mx/mx_shared/MXMain/MXYourBids'
import MXWatchlist from './views/mx/mx_shared/MXMain/MXWatchlist'
import AdminOrderBook from './views/admin/AdminDashboard/AdminOrderBook/AdminOrderBook'
import UploadInfo from './views/shared/UploadInfo/UploadInfo'
import AdminResources from './views/admin/AdminResources/AdminResources'
import MxPressReleases from './views/mx/MxPress/MxPressReleases'
import MxPressRelease from './views/mx/MxPress/MxPressRelease'
import FourZeroFour from './views/shared/404/404'
import AdminDashboardExploreMissingCache from './views/admin/AdminDashboard/AdminDashboardExploreMissingCache'
import { initSentry } from './lib/sentry'
import AdminEmailsTable from './views/admin/AdminEmails/AdminEmailsTable'
import AdminReports from './views/admin/AdminReports/AdminReports'
import AdminReport from './views/admin/AdminReports/AdminReport'
import AdminPriceHistory from './views/admin/AdminDashboard/AdminPriceHistory'
import AdminLiveBidsAndAsks from './views/admin/AdminDashboard/AdminLiveBidsAndAsks'
import AdminTrucking from './views/admin/AdminDashboard/AdminTrucking'
import MXPayments from './views/mx/mx_shared/MXPayments/MXPayments'
import AdminExchangeSettings from './views/admin/AdminDashboard/AdminExchangeSettings'
import UserAgreement from './views/mx/legal/UserAgreement'
import Legal from './views/mx/legal/Legal'
import ContractorDirectTAC from './views/mx/legal/ContractorDirectTAC'
import MXPrivacyPolicy from './views/mx/MXPrivacyPolicy/MXPrivacyPolicy'
import OrderAckTAC from './views/mx/legal/OrderAckTAC'
import PurchaseOrderTAC from './views/mx/legal/PurchaseOrderTAC'
import AdminOrderInfo from './views/admin/AdminOrder/AdminOrderInfo'
import AdminOrderPayments from './views/admin/AdminOrder/AdminOrderPayments'
import AdminOrderRequests from './views/admin/AdminOrder/AdminOrderRequests'
import AdminOrderDocuments from './views/admin/AdminOrder/AdminOrderDocuments'
import AdminOrderFulfillment from './views/admin/AdminOrder/AdminOrderFulfillment'
import AdminOrderMaterialsList from './views/admin/AdminOrder/AdminOrderMaterialsList'
import AdminGod from './views/admin/AdminGod'
import AdminChangeOrders from './views/admin/AdminOrder/AdminChangeOrders'
import AdminCalendar from './views/admin/AdminCalendar/AdminCalendar'
import AdminPurchaseOrders from './views/admin/AdminDashboard/AdminPurchaseOrders'
import AdminSalesOrders from './views/admin/AdminDashboard/AdminSalesOrders'
import AdminFulfillment from './views/admin/AdminFulfillment/AdminFulfillment'
import AdminPermissions from './views/admin/AdminDashboard/AdminPermissions'
import PermissionRoute from './components/Permissions/PermissionRoute'
import AdminOrderTasks from './views/admin/AdminOrder/AdminOrderTasks'
import AdminSales from './views/admin/AdminDashboard/AdminSales/AdminSales'
import AdminSettlementStatement from './views/admin/AdminDashboard/AdminSettlementStatement'
import env from './env'
import AdminSettings from './views/admin/AdminSettings/AdminSettings'
import 'react-image-gallery/styles/css/image-gallery.css'

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  // Callback when a user is put into an A/B experiment
  // trackingCallback: (experiment, result) => {
  //   console.log("Experiment Viewed", {
  //     experimentId: experiment.key,
  //     variationId: result.variationId,
  //   });
  // },
})

initSentry()

function App() {
  const dispatch = useDispatch()
  const { disconnectWs } = useWebsocket()
  const progress = useSelector((state) => state.globals.loaderPercentage)
  const reduxUser = useSelector((state) => state.user.user)
  const axios = useAxios()
  const wd = useWindowDimensions()
  const [lastCalledTenant, setLastCalledTenant] = useState(null)
  const auth = useAuth()
  const theme = 'light'
  const [reAuthed, setReAuthed] = useState(false)
  const [productVariant, setProductVariant] = useState()
  const [inputPrice, setInputPrice] = useState()
  const [inputSecondaryPrice, setInputSecondaryPrice] = useState()
  const [inputQuantity, setInputQuantity] = useState()
  const [inputExpiration, setInputExpiration] = useState('Good Till Cancelled')
  const [hideFilter, setHideFilter] = useState(false)
  const [openExchangeFilter, setOpenExchangeFilter] = useState(
    window.innerWidth > 700
  )
  const [selectedTimeframe, setSelectedTimeframe] = useState('Prompt')
  const [publicLocation, setPublicLocation] = useState(null)
  const [currentTab, setCurrentTab] = useState('exchange')
  const [openStartListingModal, setStartListingModal] = useState(false)
  const [commodities, setCommodities] = useState([])
  const [commodityForms, setCommodityForms] = useState([])
  const [numberOfOrders, setNumberOfOrders] = useState(null)
  const [reloadHeaderStats, setReloadHeaderStats] = useState(false)
  const [selectedCommodityForm, setSelectedCommodityForm] = useState(null)
  const [openSetAttributesModal, setOpenAttributesModal] = useState(false)
  const [timeframe, setTimeframe] = useState('Prompt')
  const [equipmentType, setEquipmentType] = useState(null)
  const [selectedCommodity, setSelectedCommodity] = useState(null)
  const [openPricingModal, setOpenPricingModal] = useState(false)
  const [openReviewListingModal, setReviewListingModal] = useState(false)
  const [openTimeframeModal, setTimeframeModal] = useState(false)
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState(null)
  const [currentPortal, setCurrentPortal] = useState(null)
  const business = useSelector((state) => state.user.business)
  const supplier = business.supplier
  const buyer = business.buyer
  const [notifications, setNotifications] = useState([])
  const [user, setUser] = useState(null)
  const [equipmentTypes, setEquipmentTypes] = useState([])
  const [publicExchange, setPublicExchange] = useState(false)
  const [currentlySelectedTimeframe, setCurrentlySelectedTimeframe] =
    useState(null)
  const [currentlySelectedTruckingType, setCurrentlySelectedTruckingType] =
    useState(null)
  const [order, setOrder] = useState(null)
  const [orderItems, setOrderItems] = useState([])
  const [changeOrderModalOpen, setChangeOrderModalOpen] = useState(false)
  const [reloadAdminOrder, setReloadAdminOrder] = useState(true)
  const [addBidOrderModal, setAddBidOrderModal] = useState(null)
  const [bidOrderButtonActive, setAddBidOrderButtonActive] = useState(null)
  const [openAdminSettingsAddModal, setOpenAdminSettingsAddModal] =
    useState(false)
  const [adminHomepageSelectedTab, setAdminHomepageSelectedTab] = useState()

  init('7a3bcf99aa00577ba021d7fa64ecc238') // initializes default instance of Amplitude client

  ReactGA.initialize('G-S3129KNVS2')
  console.log(user)
  // overriding console.error method to filter error logs
  const originalConsoleError = console.error
  console.error = function () {
    let escapeError = [
      'Invalid prop `color` of value',
      'Invalid prop `position` of value `bottom right`',
      'Invalid prop `floated` supplied to `Menu`',
      'If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase',
      'supplied to `Flag`.',
      '`iconPosition` of value `right` supplied to `Input`',
    ]
    let exist
    try {
      for (let i = 0; i < escapeError.length; i++) {
        exist =
          arguments[0]?.includes(escapeError[i]) ||
          arguments[1]?.includes(escapeError[i]) ||
          arguments[2]?.includes(escapeError[i])
        if (exist) break
      }

      if (!exist) originalConsoleError.apply(console, arguments)
    } catch (error) {
      originalConsoleError.apply(console, arguments)
    }
  }

  // overriding console.warn method to stop warning on console
  console.warn = function () {}

  // overriding console.log method to only mickeytrading user can see the logs
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }, [])

  const getCurrentTenant = async () => {
    const resp = await axios.get('main/current-tenant/')
    dispatch(addTenant(resp.data.tenant))
    setLastCalledTenant(Date.now())
  }

  useEffect(() => {
    if (
      (!user?.id || business?.id) &&
      window?.location?.pathname?.includes('exchange')
    ) {
      setPublicExchange(true)
    }
  }, [window?.location?.pathname])

  const reAuth = async () => {
    // When a supplier switched from their supplier portal to the mickey buyer portal, redux state is cleared
    // so we need to reauthenticate to set state
    await auth.isLoggedIn()
  }

  const getGlobalSettings = async () => {
    const resp = await axios.get('main/global-setting/')
    if (resp?.data?.results) {
      dispatch(addGlobalSettings(resp?.data?.results[0]))
    }
  }

  useEffect(() => {
    const now = Date.now()
    if (!lastCalledTenant || now - lastCalledTenant > 60000) {
      getCurrentTenant()
    }
  }, [reduxUser?.id])

  setInterval(() => {
    const signOut = async () => {
      disconnectWs()
      dispatch(loggedOut())
      dispatch(removeUser())
      dispatch(removeBusiness())
      if (auth) {
        await auth.signOut()
      }
      if (
        env.REACT_APP_REDIRECT &&
        env.REACT_APP_REDIRECT !== 'false' &&
        env.REACT_APP_REDIRECT !== 'null'
      ) {
        window.location = '/login'
      } else {
        window.location = '/'
      }
    }
    if (reduxUser && !reduxUser.is_active) {
      signOut()
    }
  }, 5000)

  useEffect(() => {
    getGlobalSettings()
  }, [])

  useEffect(() => {
    // Load feature definitions from GrowthBook API
    // const FEATURES_ENDPOINT = "https://cdn.growthbook.io/api/features/key_prod_aa6dfd9c934f3d0f";
    // fetch(FEATURES_ENDPOINT)
    //   .then((res) => res.json())
    //   .then((parsed) => {
    //     growthbook.setFeatures(parsed.features);
    //   });

    if (objFull(reduxUser)) {
      const identifyObj = new Identify()
      identify(identifyObj, {
        user_id: reduxUser?.email,
      })

      // Set user attributes for targeting (from cookie, auth system, etc.)
      // growthbook.setAttributes({
      //   id: reduxUser.id,
      // })
    } else {
      if (!reAuthed) {
        setReAuthed(true)
        reAuth()
      }
    }
  }, [reduxUser])

  useEffect(() => {
    const getHeaderStats = async () => {
      let res = await axios.get(`/main/header-stats/`, {
        params: {
          user_id: reduxUser?.id,
          buyer_id: currentPortal === 'buyer' ? buyer?.id : '',
          supplier_id: currentPortal === 'supplier' ? supplier?.id : '',
        },
      })
      if (res?.data) {
        setNumberOfOrders(res?.data?.number_of_orders)
      }
      setReloadHeaderStats(false)
    }
    if (currentPortal && reduxUser?.id && (buyer?.id || supplier?.id)) {
      getHeaderStats()
    }
  }, [currentPortal, buyer?.id, supplier?.id, reduxUser?.id, reloadHeaderStats])

  useEffect(() => {
    const getNotifications = async () => {
      let res = await axios.get(`/main/notifications/`, {
        params: {
          user__id: reduxUser?.id,
          buyer__id: currentPortal === 'buyer' ? buyer?.id : '',
          supplier__id: currentPortal === 'supplier' ? supplier?.id : '',
          order__archived: false,
        },
      })
      if (res?.data?.results) {
        setNotifications(res?.data?.results)
      }
    }
    if (currentPortal) {
      getNotifications()
    }
  }, [currentPortal])

  useEffect(() => {
    const getPublicLocation = async () => {
      let publicLocationId = localStorage.getItem('publicLocation')
      let res = await axios.get(`/main/p-locations/`, {
        params: {
          public_location_id: publicLocationId,
        },
      })
      if (res?.data && !reduxUser && res?.data?.results?.length > 0) {
        setPublicLocation(res.data.results[0])
        setCurrentSelectedLocation(res.data.results[0])
      }
    }
    getPublicLocation()
  }, [])

  useEffect(() => {
    const getEquipmentTypes = async () => {
      let res = await axios.get(`main/cargo-units/`)
      if (res?.data?.results) {
        setEquipmentTypes(res?.data.results)
        if (!reduxUser) {
          const flatBed = res?.data?.results?.find(
            (result) => result.key === 'flat_bed_53'
          )
          setEquipmentType(flatBed?.id)
        } else {
          setEquipmentType(reduxUser?.current_equipment_type?.id)
        }
      }
    }
    getEquipmentTypes()
  }, [window?.location?.pathname])

  useEffect(() => {
    const initializePublicUser = () => {
      let flatBed = equipmentTypes?.find(
        (result) => result.key === 'flat_bed_53'
      )
      setUser({
        current_location: publicLocation,
        current_equipment_type: flatBed,
        current_delivery_timeframe: 'Prompt',
      })
    }
    if (
      !reduxUser &&
      publicExchange &&
      window?.location?.pathname?.includes('exchange')
    ) {
      initializePublicUser()
    }
  }, [equipmentType, publicExchange, window?.location?.pathname])

  useEffect(() => {
    //
    console.log(`
    +-----------------------------------+
    |                                   |
    | TRADING SYSTEMS                   |
    |                                   |
    | We're hiring! Send your resumé to |
    | Weston (wstewarttennes@gmail.com) |
    | to work on some exciting          |
    | problems.  Include "Dijon" in     |
    | the email subject so I notice     |
    | and I know you came from here :)  |
    |                                   |
    +-----------------------------------+
  `)
  }, [])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Segment
        basic
        className={'App ' + theme}
        id={'mickey'}
        style={{
          marginTop:
            window &&
            !window.location.pathname.startsWith('/portal') &&
            !window.location.pathname.includes('reset-password') &&
            !window.location.pathname.startsWith('/products') &&
            !window.location.pathname.includes('login') &&
            !window.location.pathname.startsWith('/payment') &&
            wd.width <= 950 &&
            '140px',
        }}
      >
        <BrowserRouter>
          {/* <Tenants> */}
          <ToastContainer />
          <LoadingBar
            color="#d6b913"
            progress={progress}
            onLoaderFinished={() => dispatch(setLoaderPercentage(0))}
          />
          <MXHeader
            user={reduxUser ? reduxUser : user}
            setUser={setUser}
            currentPortal={currentPortal}
            openExchangeFilter={openExchangeFilter}
            setOpenExchangeFilter={setOpenExchangeFilter}
            openStartListingModal={openStartListingModal}
            selectedCommodity={selectedCommodity}
            setSelectedCommodity={setSelectedCommodity}
            setStartListingModal={setStartListingModal}
            openSetAttributesModal={openSetAttributesModal}
            setOpenAttributesModal={setOpenAttributesModal}
            openPricingModal={openPricingModal}
            setOpenPricingModal={setOpenPricingModal}
            openReviewListingModal={openReviewListingModal}
            setReviewListingModal={setReviewListingModal}
            openTimeframeModal={openTimeframeModal}
            setTimeframeModal={setTimeframeModal}
            currentSelectedLocation={currentSelectedLocation}
            setCurrentSelectedLocation={setCurrentSelectedLocation}
            productVariant={productVariant}
            setProductVariant={setProductVariant}
            setInputPrice={setInputPrice}
            setInputSecondaryPrice={setInputSecondaryPrice}
            inputSecondaryPrice={inputSecondaryPrice}
            inputPrice={inputPrice}
            setInputQuantity={setInputQuantity}
            setReloadHeaderStats={setReloadHeaderStats}
            inputQuantity={inputQuantity}
            notifications={notifications}
            setNotifications={setNotifications}
            inputExpiration={inputExpiration}
            setInputExpiration={setInputExpiration}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            buyer={buyer}
            supplier={supplier}
            timeframe={timeframe}
            numberOfOrders={numberOfOrders}
            setTimeframe={setTimeframe}
            reAuth={reAuth}
            equipmentType={equipmentType}
            setEquipmentType={setEquipmentType}
            commodities={commodities}
            setCommodities={setCommodities}
            commodityForms={commodityForms}
            setCommodityForms={setCommodityForms}
            selectedCommodityForm={selectedCommodityForm}
            setSelectedCommodityForm={setSelectedCommodityForm}
            publicLocation={publicLocation}
            setPublicLocation={setPublicLocation}
            selectedTimeframe={selectedTimeframe}
            setSelectedTimeframe={setSelectedTimeframe}
            equipmentTypes={equipmentTypes}
            setEquipmentTypes={setEquipmentTypes}
            publicExchange={publicExchange}
            setPublicExchange={setPublicExchange}
            hideFilter={hideFilter}
            currentlySelectedTimeframe={currentlySelectedTimeframe}
            setCurrentlySelectedTimeframe={setCurrentlySelectedTimeframe}
            currentlySelectedTruckingType={currentlySelectedTruckingType}
            setCurrentlySelectedTruckingType={setCurrentlySelectedTruckingType}
          />
          <Routes>
            {/* <Route path={"/team"} element={<MXTeam />} /> */}
            <Route path={'/login'} element={<Login />} />
            <Route path={'/reset-password'} element={<ResetPassword />} />
            <Route
              path={'/reset-password-complete/:buid64/:token'}
              element={<ResetPasswordComplete />}
            />
            <Route path={'/press'} element={<MxPressReleases />} />
            <Route path={'/press/:pressSlug'} element={<MxPressRelease />} />
            <Route path={'/about'} element={<MXAbout />} />
            {/* <Route path={"/network"} element={<MxNetwork />} /> */}
            <Route path={'/contact-us'} element={<MxContactUs />} />
            <Route path={'/events'} element={<MxEvents />} />
            <Route path={'/news'} element={<MxNews />} />
            <Route path={'/apply'} element={<MXSignUp />} />
            <Route path={'/legal'} element={<Legal />} />
            <Route path={'/order-ack-tac'} element={<OrderAckTAC />} />
            <Route
              path={'/purchase-order-tac'}
              element={<PurchaseOrderTAC />}
            />
            <Route path={'/user-agreement'} element={<UserAgreement />} />
            <Route
              path={'/contractor-direct-tac'}
              element={<ContractorDirectTAC />}
            />
            <Route path={'/privacy-policy'} element={<MXPrivacyPolicy />} />
            <Route path={'/payment/:stringToken'} element={<Payment />} />
            <Route path={'/buyer-info/:stringToken'} element={<UploadInfo />} />
            <Route
              path={'/supplier-info/:stringToken'}
              element={<UploadInfo />}
            />
            <Route path={'/exchange'}>
              <Route
                index
                element={
                  <MXExchange
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    business={business}
                    user={reduxUser ? reduxUser : user}
                    setUser={setUser}
                    currentPortal={currentPortal}
                    setCurrentPortal={setCurrentPortal}
                    openExchangeFilter={openExchangeFilter}
                    setOpenExchangeFilter={setOpenExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    setStartListingModal={setStartListingModal}
                    currentSelectedLocation={currentSelectedLocation}
                    setCurrentSelectedLocation={setCurrentSelectedLocation}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    selectedCommodity={selectedCommodity}
                    setSelectedCommodity={setSelectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    setInputPrice={setInputPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    publicLocation={publicLocation}
                    setPublicLocation={setPublicLocation}
                    setPublicExchange={setPublicExchange}
                    publicExchange={publicExchange}
                    hideFilter={hideFilter}
                    setHideFilter={setHideFilter}
                    currentlySelectedTimeframe={currentlySelectedTimeframe}
                    setCurrentlySelectedTimeframe={
                      setCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      currentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      setCurrentlySelectedTruckingType
                    }
                  />
                }
              />
              <Route
                path={`:commoditySlug/:commodityFormSlug/:productSlug/:productVariantSlug`}
                element={
                  <MXProductVariant
                    business={business}
                    buyer={buyer}
                    user={reduxUser ? reduxUser : user}
                    setUser={setUser}
                    currentPortal={currentPortal}
                    setCurrentPortal={setCurrentPortal}
                    openExchangeFilter={openExchangeFilter}
                    setOpenExchangeFilter={setOpenExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    setStartListingModal={setStartListingModal}
                    selectedCommodity={selectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setSelectedCommodity={setSelectedCommodity}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    setInputPrice={setInputPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    publicExchange={publicExchange}
                    hideFilter={hideFilter}
                    setHideFilter={setHideFilter}
                  />
                }
              />
              <Route
                path={`:commoditySlug/:commodityFormSlug/:productSlug/`}
                element={
                  <MXProduct
                    business={business}
                    user={reduxUser ? reduxUser : user}
                    setUser={setUser}
                    currentPortal={currentPortal}
                    setCurrentPortal={setCurrentPortal}
                    openExchangeFilter={openExchangeFilter}
                    setOpenExchangeFilter={setOpenExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    setStartListingModal={setStartListingModal}
                    selectedCommodity={selectedCommodity}
                    setReloadHeaderStats={setReloadHeaderStats}
                    setSelectedCommodity={setSelectedCommodity}
                    currentSelectedLocation={currentSelectedLocation}
                    setCurrentSelectedLocation={setCurrentSelectedLocation}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    setInputPrice={setInputPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    publicExchange={publicExchange}
                    hideFilter={hideFilter}
                    setHideFilter={setHideFilter}
                  />
                }
              />
              <Route
                path=":commoditySlug/:commodityFormSlug/"
                element={
                  <MXExchange
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    business={business}
                    user={reduxUser ? reduxUser : user}
                    setUser={setUser}
                    currentPortal={currentPortal}
                    setCurrentPortal={setCurrentPortal}
                    openExchangeFilter={openExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    setStartListingModal={setStartListingModal}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    selectedCommodity={selectedCommodity}
                    setSelectedCommodity={setSelectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    setReloadHeaderStats={setReloadHeaderStats}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    setInputPrice={setInputPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    publicLocation={publicLocation}
                    setPublicLocation={setPublicLocation}
                    publicExchange={publicExchange}
                    setPublicExchange={setPublicExchange}
                    hideFilter={hideFilter}
                    setHideFilter={setHideFilter}
                    currentlySelectedTimeframe={currentlySelectedTimeframe}
                    setCurrentlySelectedTimeframe={
                      setCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      currentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      setCurrentlySelectedTruckingType
                    }
                  />
                }
              />
              <Route
                path=":commoditySlug"
                element={
                  <MXExchange
                    style={{
                      marginLeft: wd.width < 800 ? '10px' : '55px',
                      marginTop: '0',
                    }}
                    business={business}
                    user={reduxUser ? reduxUser : user}
                    setUser={setUser}
                    currentPortal={currentPortal}
                    setCurrentPortal={setCurrentPortal}
                    openExchangeFilter={openExchangeFilter}
                    openStartListingModal={openStartListingModal}
                    setStartListingModal={setStartListingModal}
                    setReloadHeaderStats={setReloadHeaderStats}
                    selectedCommodity={selectedCommodity}
                    setSelectedCommodity={setSelectedCommodity}
                    openSetAttributesModal={openSetAttributesModal}
                    setOpenAttributesModal={setOpenAttributesModal}
                    openPricingModal={openPricingModal}
                    setOpenPricingModal={setOpenPricingModal}
                    openReviewListingModal={openReviewListingModal}
                    setReviewListingModal={setReviewListingModal}
                    productVariant={productVariant}
                    setProductVariant={setProductVariant}
                    setInputPrice={setInputPrice}
                    setInputSecondaryPrice={setInputSecondaryPrice}
                    inputSecondaryPrice={inputSecondaryPrice}
                    inputPrice={inputPrice}
                    setInputQuantity={setInputQuantity}
                    inputExpiration={inputExpiration}
                    setInputExpiration={setInputExpiration}
                    inputQuantity={inputQuantity}
                    notifications={notifications}
                    setNotifications={setNotifications}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    equipmentType={equipmentType}
                    setEquipmentType={setEquipmentType}
                    commodities={commodities}
                    setCommodities={setCommodities}
                    commodityForms={commodityForms}
                    setCommodityForms={setCommodityForms}
                    selectedCommodityForm={selectedCommodityForm}
                    setSelectedCommodityForm={setSelectedCommodityForm}
                    publicLocation={publicLocation}
                    setPublicLocation={setPublicLocation}
                    setPublicExchange={setPublicExchange}
                    publicExchange={publicExchange}
                    hideFilter={hideFilter}
                    setHideFilter={setHideFilter}
                    currentlySelectedTimeframe={currentlySelectedTimeframe}
                    setCurrentlySelectedTimeframe={
                      setCurrentlySelectedTimeframe
                    }
                    currentlySelectedTruckingType={
                      currentlySelectedTruckingType
                    }
                    setCurrentlySelectedTruckingType={
                      setCurrentlySelectedTruckingType
                    }
                  />
                }
              />
            </Route>

            <Route path={'/portal'} element={<PrivateRoute />}>
              <Route
                path={'buyer'}
                element={
                  <PermissionRoute portal="buyer">
                    <Buyer
                      currentPortal={currentPortal}
                      setCurrentPortal={setCurrentPortal}
                      openExchangeFilter={openExchangeFilter}
                      openStartListingModal={openStartListingModal}
                      setReloadHeaderStats={setReloadHeaderStats}
                      setStartListingModal={setStartListingModal}
                      selectedCommodity={selectedCommodity}
                      openSetAttributesModal={openSetAttributesModal}
                      setOpenAttributesModal={setOpenAttributesModal}
                      openPricingModal={openPricingModal}
                      setOpenPricingModal={setOpenPricingModal}
                      openReviewListingModal={openReviewListingModal}
                      setReviewListingModal={setReviewListingModal}
                      productVariant={productVariant}
                      setProductVariant={setProductVariant}
                      inputExpiration={inputExpiration}
                      setInputExpiration={setInputExpiration}
                      setInputPrice={setInputPrice}
                      setInputSecondaryPrice={setInputSecondaryPrice}
                      inputSecondaryPrice={inputSecondaryPrice}
                      inputPrice={inputPrice}
                      setInputQuantity={setInputQuantity}
                      inputQuantity={inputQuantity}
                      notifications={notifications}
                      setNotifications={setNotifications}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      timeframe={timeframe}
                      setTimeframe={setTimeframe}
                      equipmentType={equipmentType}
                      setEquipmentType={setEquipmentType}
                      commodities={commodities}
                      setCommodities={setCommodities}
                      commodityForms={commodityForms}
                      setCommodityForms={setCommodityForms}
                      selectedCommodityForm={selectedCommodityForm}
                      setSelectedCommodityForm={setSelectedCommodityForm}
                    />
                  </PermissionRoute>
                }
              >
                <Route
                  path={`watchlist`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXWatchlist
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        setReviewListingModal={setReviewListingModal}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        setSelectedCommodity={setSelectedCommodity}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`my-bids`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXYourBids
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        openExchangeFilter={openExchangeFilter}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        reAuth={reAuth}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        setSelectedCommodity={setSelectedCommodity}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`orders/:orderId/:status/`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Order">
                      <BuyerOrder
                        business={business}
                        supplier={supplier}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`orders/:orderId`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Order">
                      <BuyerOrder
                        business={business}
                        buyer={buyer}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`orders`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Order">
                      <BuyerOrders
                        business={business}
                        tenantAware={false}
                        buyer={buyer}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`profile`}
                  element={
                    <PermissionRoute portal="buyer" permission="Read Business">
                      <Setting
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    </PermissionRoute>
                  }
                  style={{
                    marginTop: '0',
                  }}
                />
                <Route
                  path={`notifications`}
                  element={
                    <PermissionRoute
                      portal="buyer"
                      permission="Read Notification"
                    >
                      <MxNotification
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug/:productVariantSlug/:yourBids"
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXProductVariant
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setSelectedCommodity={setSelectedCommodity}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicExchange={publicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug/:productVariantSlug"
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXProductVariant
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setSelectedCommodity={setSelectedCommodity}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicExchange={publicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug"
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXProduct
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        setReloadHeaderStats={setReloadHeaderStats}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicExchange={publicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug"
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug"
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  index
                  element={
                    <PermissionRoute portal="buyer" permission="Read Exchange">
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentPortal={setCurrentPortal}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
              </Route>
              <Route
                path={'supplier'}
                element={
                  <PermissionRoute portal="supplier">
                    <Supplier
                      currentPortal={currentPortal}
                      setCurrentPortal={setCurrentPortal}
                      currentSelectedLocation={currentSelectedLocation}
                      openExchangeFilter={openExchangeFilter}
                      openStartListingModal={openStartListingModal}
                      setReloadHeaderStats={setReloadHeaderStats}
                      setStartListingModal={setStartListingModal}
                      selectedCommodity={selectedCommodity}
                      openSetAttributesModal={openSetAttributesModal}
                      setOpenAttributesModal={setOpenAttributesModal}
                      openPricingModal={openPricingModal}
                      setOpenPricingModal={setOpenPricingModal}
                      openReviewListingModal={openReviewListingModal}
                      setReviewListingModal={setReviewListingModal}
                      productVariant={productVariant}
                      setProductVariant={setProductVariant}
                      setInputPrice={setInputPrice}
                      setInputSecondaryPrice={setInputSecondaryPrice}
                      inputSecondaryPrice={inputSecondaryPrice}
                      inputPrice={inputPrice}
                      inputExpiration={inputExpiration}
                      setInputExpiration={setInputExpiration}
                      setInputQuantity={setInputQuantity}
                      inputQuantity={inputQuantity}
                      notifications={notifications}
                      setNotifications={setNotifications}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      timeframe={timeframe}
                      setTimeframe={setTimeframe}
                      equipmentType={equipmentType}
                      setEquipmentType={setEquipmentType}
                      commodities={commodities}
                      setCommodities={setCommodities}
                      commodityForms={commodityForms}
                      setCommodityForms={setCommodityForms}
                      selectedCommodityForm={selectedCommodityForm}
                      setSelectedCommodityForm={setSelectedCommodityForm}
                      setSelectedCommodity={setSelectedCommodity}
                    />
                  </PermissionRoute>
                }
              >
                <Route
                  path={`watchlist`}
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXWatchlist
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        setSelectedCommodity={setSelectedCommodity}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`my-asks`}
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXYourBids
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        setReloadHeaderStats={setReloadHeaderStats}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        reAuth={reAuth}
                        setNotifications={setNotifications}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        setSelectedCommodity={setSelectedCommodity}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`orders/:orderId/:status`}
                  element={
                    <PermissionRoute portal="supplier" permission="Read Order">
                      <SupplierOrder
                        business={business}
                        supplier={supplier}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`orders`}
                  element={
                    <PermissionRoute portal="supplier" permission="Read Order">
                      <SupplierOrders
                        supplier={supplier}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        business={business}
                        numberOfOrders={numberOfOrders}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`contacts`}
                  element={
                    <PermissionRoute portal="supplier" permission="Read User">
                      <Contacts
                        business={business}
                        supplier={supplier}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`profile`}
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Business"
                    >
                      <Setting
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`notifications`}
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Notification"
                    >
                      <MxNotification
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        currentPortal={currentPortal}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={`inventory`}
                  element={
                    <PermissionRoute portal="supplier" permission="Read Order">
                      <Inventory
                        business={business}
                        supplier={supplier}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug/:productVariantSlug/:yourBids"
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXProductVariant
                        business={business}
                        buyer={buyer}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setSelectedCommodity={setSelectedCommodity}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicExchange={publicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug/:productVariantSlug"
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXProductVariant
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setSelectedCommodity={setSelectedCommodity}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicExchange={publicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug/:productSlug"
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXProduct
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openExchangeFilter={openExchangeFilter}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        publicExchange={publicExchange}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug/:commodityFormSlug"
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path=":commoditySlug"
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />

                <Route
                  index
                  element={
                    <PermissionRoute
                      portal="supplier"
                      permission="Read Exchange"
                    >
                      <MXExchange
                        style={{
                          marginLeft: wd.width < 800 ? '10px' : '55px',
                          marginTop: '0',
                        }}
                        business={business}
                        supplier={supplier}
                        user={reduxUser ? reduxUser : user}
                        setUser={setUser}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        currentSelectedLocation={currentSelectedLocation}
                        setCurrentSelectedLocation={setCurrentSelectedLocation}
                        openExchangeFilter={openExchangeFilter}
                        setOpenExchangeFilter={setOpenExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setStartListingModal={setStartListingModal}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        selectedCommodity={selectedCommodity}
                        setSelectedCommodity={setSelectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        publicLocation={publicLocation}
                        setPublicLocation={setPublicLocation}
                        publicExchange={publicExchange}
                        setPublicExchange={setPublicExchange}
                        hideFilter={hideFilter}
                        setHideFilter={setHideFilter}
                        currentlySelectedTimeframe={currentlySelectedTimeframe}
                        setCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        currentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
              </Route>
              <Route
                path={'admin'}
                element={
                  <PermissionRoute portal="admin">
                    <Admin
                      currentPortal={currentPortal}
                      setCurrentPortal={setCurrentPortal}
                      openExchangeFilter={openExchangeFilter}
                      openStartListingModal={openStartListingModal}
                      setStartListingModal={setStartListingModal}
                      selectedCommodity={selectedCommodity}
                      openSetAttributesModal={openSetAttributesModal}
                      setOpenAttributesModal={setOpenAttributesModal}
                      openPricingModal={openPricingModal}
                      setOpenPricingModal={setOpenPricingModal}
                      openReviewListingModal={openReviewListingModal}
                      setReloadHeaderStats={setReloadHeaderStats}
                      setReviewListingModal={setReviewListingModal}
                      productVariant={productVariant}
                      setProductVariant={setProductVariant}
                      setInputPrice={setInputPrice}
                      setInputSecondaryPrice={setInputSecondaryPrice}
                      inputSecondaryPrice={inputSecondaryPrice}
                      inputPrice={inputPrice}
                      setInputQuantity={setInputQuantity}
                      inputQuantity={inputQuantity}
                      notifications={notifications}
                      setNotifications={setNotifications}
                      currentTab={currentTab}
                      setCurrentTab={setCurrentTab}
                      inputExpiration={inputExpiration}
                      setInputExpiration={setInputExpiration}
                      equipmentType={equipmentType}
                      setEquipmentType={setEquipmentType}
                      commodities={commodities}
                      setCommodities={setCommodities}
                      commodityForms={commodityForms}
                      setCommodityForms={setCommodityForms}
                      selectedCommodityForm={selectedCommodityForm}
                      setSelectedCommodityForm={setSelectedCommodityForm}
                      setSelectedCommodity={setSelectedCommodity}
                    />
                  </PermissionRoute>
                }
              >
                <Route
                  path={'order-book/:orderBookId'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Exchange">
                      <AdminOrderBook />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'dashboard/explore-missing-cache'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Report">
                      <AdminDashboardExploreMissingCache />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'dashboard'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Report">
                      <AdminDashboard
                        selectedCommodity={selectedCommodity}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        openExchangeFilter={openExchangeFilter}
                        commodities={commodities}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'calendar'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Calendar">
                      <AdminCalendar
                        selectedCommodity={selectedCommodity}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        openExchangeFilter={openExchangeFilter}
                        commodities={commodities}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'take-rate'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Exchange">
                      <AdminExchangeSettings
                        selectedCommodity={selectedCommodity}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        openExchangeFilter={openExchangeFilter}
                        commodities={commodities}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'order-books'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Exchange">
                      <AdminOrderBooks
                        selectedCommodity={selectedCommodity}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        openExchangeFilter={openExchangeFilter}
                        commodities={commodities}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'settings'}
                  element={
                    <AdminSettings
                      openAddModal={openAdminSettingsAddModal}
                      setOpenAddModal={setOpenAdminSettingsAddModal}
                      adminHomepageSelectedTab={adminHomepageSelectedTab}
                      setAdminHomepageSelectedTab={setAdminHomepageSelectedTab}
                    />
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                >
                  <Route
                    index
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminManageUsers
                          openAddUserModal={openAdminSettingsAddModal}
                          setOpenAddUserModal={setOpenAdminSettingsAddModal}
                          selectedCommodity={selectedCommodity}
                          selectedCommodityForm={selectedCommodityForm}
                          setSelectedCommodityForm={setSelectedCommodityForm}
                          openExchangeFilter={openExchangeFilter}
                          commodities={commodities}
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <Route
                    path={'manage-users'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminManageUsers
                          openAddUserModal={openAdminSettingsAddModal}
                          setOpenAddUserModal={setOpenAdminSettingsAddModal}
                          selectedCommodity={selectedCommodity}
                          selectedCommodityForm={selectedCommodityForm}
                          setSelectedCommodityForm={setSelectedCommodityForm}
                          openExchangeFilter={openExchangeFilter}
                          commodities={commodities}
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <Route
                    path={'trucking'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminTrucking
                          selectedCommodity={selectedCommodity}
                          selectedCommodityForm={selectedCommodityForm}
                          setSelectedCommodityForm={setSelectedCommodityForm}
                          openExchangeFilter={openExchangeFilter}
                          commodities={commodities}
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <Route
                    path={'manage-dev-users'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        group="Development"
                        permission="Read Settings"
                      >
                        <AdminManageUsers
                          openAddUserModal={openAdminSettingsAddModal}
                          setOpenAddUserModal={setOpenAdminSettingsAddModal}
                          selectedCommodity={selectedCommodity}
                          selectedCommodityForm={selectedCommodityForm}
                          setSelectedCommodityForm={setSelectedCommodityForm}
                          openExchangeFilter={openExchangeFilter}
                          commodities={commodities}
                          developmentUsers
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <Route
                    path={'manage-locations'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminManageLocations
                          openAddLocationModal={openAdminSettingsAddModal}
                          setOpenAddLocationModal={setOpenAdminSettingsAddModal}
                          selectedCommodity={selectedCommodity}
                          selectedCommodityForm={selectedCommodityForm}
                          setSelectedCommodityForm={setSelectedCommodityForm}
                          openExchangeFilter={openExchangeFilter}
                          commodities={commodities}
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                  <Route
                    path={'documents'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminDocuments />
                      </PermissionRoute>
                    }
                  />
                  <Route
                    path={'permissions'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminPermissions />
                      </PermissionRoute>
                    }
                  />
                  <Route
                    path={'homepage-settings'}
                    element={
                      <PermissionRoute
                        portal="admin"
                        permission="Read Settings"
                      >
                        <AdminResources
                          openAddModal={openAdminSettingsAddModal}
                          setOpenAddModal={setOpenAdminSettingsAddModal}
                          selectedTab={adminHomepageSelectedTab}
                          setSelectedTab={setAdminHomepageSelectedTab}
                        />
                      </PermissionRoute>
                    }
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                </Route>
                <Route
                  path={'price-history'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Payment">
                      <AdminPriceHistory />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={'purchase-orders'}
                  element={
                    <PermissionRoute
                      portal="admin"
                      permission="Read Purchase Orders"
                    >
                      <AdminPurchaseOrders />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={'sales-orders'}
                  element={
                    <PermissionRoute
                      portal="admin"
                      permission="Read Sales Orders"
                    >
                      <AdminSalesOrders />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={'settlement-statements'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Payment">
                      <AdminSettlementStatement />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={'live-bids-and-asks'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Exchange">
                      <AdminLiveBidsAndAsks
                        selectedCommodity={selectedCommodity}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        openExchangeFilter={openExchangeFilter}
                        commodities={commodities}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'report/:reportId'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Report">
                      <AdminReport />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'reports'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Report">
                      <AdminReports />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'businesses'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Business">
                      <AdminBusinesses />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'business/:businessId'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Business">
                      <AdminBusiness
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        setSelectedCommodity={setSelectedCommodity}
                        currentPortal={currentPortal}
                        setCurrentPortal={setCurrentPortal}
                        reAuth={reAuth}
                        openExchangeFilter={openExchangeFilter}
                        openStartListingModal={openStartListingModal}
                        setReloadHeaderStats={setReloadHeaderStats}
                        setStartListingModal={setStartListingModal}
                        selectedCommodity={selectedCommodity}
                        openSetAttributesModal={openSetAttributesModal}
                        setOpenAttributesModal={setOpenAttributesModal}
                        openPricingModal={openPricingModal}
                        setOpenPricingModal={setOpenPricingModal}
                        openReviewListingModal={openReviewListingModal}
                        setReviewListingModal={setReviewListingModal}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        inputExpiration={inputExpiration}
                        setInputExpiration={setInputExpiration}
                        setInputPrice={setInputPrice}
                        setInputSecondaryPrice={setInputSecondaryPrice}
                        inputSecondaryPrice={inputSecondaryPrice}
                        inputPrice={inputPrice}
                        setInputQuantity={setInputQuantity}
                        inputQuantity={inputQuantity}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        timeframe={timeframe}
                        setTimeframe={setTimeframe}
                        equipmentType={equipmentType}
                        setEquipmentType={setEquipmentType}
                        commodities={commodities}
                        setCommodities={setCommodities}
                        commodityForms={commodityForms}
                        setCommodityForms={setCommodityForms}
                        selectedCommodityForm={selectedCommodityForm}
                        setSelectedCommodityForm={setSelectedCommodityForm}
                        adminCurrentlySelectedTimeframe={
                          currentlySelectedTimeframe
                        }
                        setAdminCurrentlySelectedTimeframe={
                          setCurrentlySelectedTimeframe
                        }
                        adminCurrentlySelectedTruckingType={
                          currentlySelectedTruckingType
                        }
                        setAdminCurrentlySelectedTruckingType={
                          setCurrentlySelectedTruckingType
                        }
                      />
                    </PermissionRoute>
                  }
                />
                <Route
                  path={'deals/:orderId'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Order">
                      <AdminOrder
                        order={order}
                        setOrder={setOrder}
                        orderItems={orderItems}
                        setOrderItems={setOrderItems}
                        currentPortal={currentPortal}
                        changeOrderModalOpen={changeOrderModalOpen}
                        setChangeOrderModalOpen={setChangeOrderModalOpen}
                        reloadAdminOrder={reloadAdminOrder}
                        setReloadAdminOrder={setReloadAdminOrder}
                        addBidOrderModal={addBidOrderModal}
                        setAddBidOrderModal={setAddBidOrderModal}
                        bidOrderButtonActive={bidOrderButtonActive}
                        setAddBidOrderButtonActive={setAddBidOrderButtonActive}
                      />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                >
                  <Route
                    path={'info'}
                    element={<AdminOrderInfo order={order} />}
                  />
                  <Route
                    path={'materials-list'}
                    element={
                      <AdminOrderMaterialsList
                        order={order}
                        setOrder={setOrder}
                        orderItems={orderItems}
                        setOrderItems={setOrderItems}
                        reloadAdminOrder={reloadAdminOrder}
                        setReloadAdminOrder={setReloadAdminOrder}
                        addBidOrderModal={addBidOrderModal}
                        setAddBidOrderModal={setAddBidOrderModal}
                        bidOrderButtonActive={bidOrderButtonActive}
                        setAddBidOrderButtonActive={setAddBidOrderButtonActive}
                      />
                    }
                  />
                  <Route
                    path={'fulfillment'}
                    element={
                      <AdminOrderFulfillment
                        order={order}
                        reloadAdminOrder={reloadAdminOrder}
                        setReloadAdminOrder={setReloadAdminOrder}
                      />
                    }
                  />
                  <Route
                    path={'tasks'}
                    element={
                      <AdminOrderTasks order={order} setOrder={setOrder} />
                    }
                  />
                  <Route
                    path={'documents'}
                    element={<AdminOrderDocuments order={order} />}
                  />
                  <Route
                    path={'payments'}
                    element={<AdminOrderPayments order={order} />}
                  />
                  <Route
                    path={'buyer-requests'}
                    element={<AdminOrderRequests order={order} />}
                  />
                  <Route
                    path={'change-orders'}
                    element={
                      <AdminChangeOrders
                        order={order}
                        setOrder={setOrder}
                        orderItems={orderItems}
                        setOrderItems={setOrderItems}
                        reload={reloadAdminOrder}
                        setReload={setReloadAdminOrder}
                        inputPrice={inputPrice}
                        setInputPrice={setInputPrice}
                        inputQuantity={inputQuantity}
                        setInputQuantity={setInputQuantity}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        changeOrderModalOpen={changeOrderModalOpen}
                        setChangeOrderModalOpen={setChangeOrderModalOpen}
                      />
                    }
                  />
                  <Route
                    index
                    element={
                      <AdminOrderMaterialsList
                        order={order}
                        setOrder={setOrder}
                        orderItems={orderItems}
                        setOrderItems={setOrderItems}
                        reloadAdminOrder={reloadAdminOrder}
                        setReloadAdminOrder={setReloadAdminOrder}
                        addBidOrderModal={addBidOrderModal}
                        setAddBidOrderModal={setAddBidOrderModal}
                        bidOrderButtonActive={bidOrderButtonActive}
                        setAddBidOrderButtonActive={setAddBidOrderButtonActive}
                      />
                    }
                  />
                </Route>
                <Route
                  path={'deals'}
                  element={<AdminOrders />}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'sales'}
                  element={<AdminSales />}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'fulfillment'}
                  element={<AdminFulfillment />}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'god'}
                  element={
                    <PermissionRoute portal="admin" isGod>
                      <AdminGod />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'homepage-settings'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Settings">
                      <AdminResources />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'email'}
                  element={
                    <PermissionRoute
                      portal="admin"
                      permission="Read Notification"
                    >
                      <AdminEmailsTable />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  path={'payments'}
                  element={
                    <PermissionRoute portal="admin" permission="Read Payment">
                      <MXPayments />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <Route
                  index
                  element={
                    <PermissionRoute portal="admin" permission="Read Order">
                      <AdminOrders />
                    </PermissionRoute>
                  }
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </Route>
            </Route>
            <Route path="*" element={<FourZeroFour />} />
            <Route path="/" element={<MXHomepage />} />
          </Routes>
          {/* </Tenants> */}
          <MXFooter
            currentPortal={currentPortal}
            openExchangeFilter={openExchangeFilter}
            setOpenExchangeFilter={setOpenExchangeFilter}
            openStartListingModal={openStartListingModal}
            selectedCommodity={selectedCommodity}
            setSelectedCommodity={setSelectedCommodity}
            setStartListingModal={setStartListingModal}
            openSetAttributesModal={openSetAttributesModal}
            setOpenAttributesModal={setOpenAttributesModal}
            openPricingModal={openPricingModal}
            inputExpiration={inputExpiration}
            setInputExpiration={setInputExpiration}
            setOpenPricingModal={setOpenPricingModal}
            openReviewListingModal={openReviewListingModal}
            setReviewListingModal={setReviewListingModal}
            productVariant={productVariant}
            setProductVariant={setProductVariant}
            setInputPrice={setInputPrice}
            setInputSecondaryPrice={setInputSecondaryPrice}
            inputSecondaryPrice={inputSecondaryPrice}
            inputPrice={inputPrice}
            setInputQuantity={setInputQuantity}
            inputQuantity={inputQuantity}
            notifications={notifications}
            setNotifications={setNotifications}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            buyer={buyer}
            supplier={supplier}
            timeframe={timeframe}
            setTimeframe={setTimeframe}
            reAuth={reAuth}
            equipmentType={equipmentType}
            setEquipmentType={setEquipmentType}
            commodities={commodities}
            setCommodities={setCommodities}
            commodityForms={commodityForms}
            setCommodityForms={setCommodityForms}
            selectedCommodityForm={selectedCommodityForm}
            setSelectedCommodityForm={setSelectedCommodityForm}
            publicExchange={publicExchange}
          />
        </BrowserRouter>
      </Segment>
    </GrowthBookProvider>
  )
}

export default App
