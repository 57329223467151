import React, { useEffect, useState } from 'react'
import { Grid, Form, Menu, Segment } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import './Inventory.css'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import _ from 'underscore'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getUniqueListBy } from '../../../lib/utils/utils'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import InventoryTableRow from './InventoryTableRow'
import { arraysEqual } from '../../../lib/utils/utils'

function InventoryTable(props) {
  // state
  const [loadedLocations, setLoadedLocations] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedCommodity, setSelectedCommodity] = useState(1)
  const [results, setResults] = useState([])
  const [orderItems, setOrderItems] = useState([])
  const [locations, setLocations] = useState(null)
  const [activeLocation, setActiveLocation] = useState(null)
  const [attributes, setAttributes] = useState([])
  const [editedAttributeSelections, setEditedAttributeSelections] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)
  const [trucks, setTrucks] = useState([{ order_items: [] }])
  const [productVariantsRoute, setProductVariantsRouteState] = useState(
    props.supplierOrder ? '/main/inventory/' : props.productVariantsRoute
  )
  const [tableHeader, setTableHeader] = useState({})
  // redux state
  const tenant = useSelector((state) => state.globals.tenant)
  // hooks
  const axios = useAxios()
  // vars
  const formik = useFormik({
    initialValues: {
      onlyShowInventory: false,
    },
  })

  function fullTruckload(result) {
    let ts = [...trucks]
    if (ts[ts.length - 1]['order_items'].length < 1) {
      ts[ts.length - 1] = {
        order_items: [
          {
            product_variant: result,
            percent_filled: 100,
          },
        ],
        filled: true,
      }
    } else {
      ts.push({
        order_items: [
          {
            product_variant: result,
            percent_filled: 100,
          },
        ],
        filled: true,
      })
    }

    setTrucks(ts)
    setOrderItems([
      ...orderItems,
      {
        product_variant_id: result?.id,
        quantity: 'TRUCKLOAD',
      },
    ])
    setSubmitButtonLoading(-1)
  }

  useEffect(() => {
    if (props.productId) {
      getProductAttributes(props.productId)
      getSelectedProduct(props.productId)
    }
  }, [props.productId])

  useEffect(() => {
    async function loadLocations() {
      let res = await axios.get(`/main/locations/`, {
        params: {
          business_id: props.business?.id,
        },
      })
      if (res?.data?.results?.length > 0) {
        const location = res?.data.results[0]
        setActiveLocation(location)
        if (props.handleActiveLocation) {
          props.handleActiveLocation(location)
        }
      }
      setLocations(res?.data.results)
    }
    if (props.supplier && !loadedLocations) {
      loadLocations()
      setLoadedLocations(true)
    }
  }, [loadedLocations, props.supplier])

  function fillCurrentTruck(result) {
    let ts = [...trucks]
    ts[ts.length - 1] = {
      ...ts[ts.length - 1],
      filled: true,
    }
    setTrucks(ts)
    setOrderItems([
      ...orderItems,
      {
        product_variant_id: result?.id,
        quantity: 100,
      },
    ])
  }

  async function getSelectedProduct(productId) {
    if (results?.length > 0) {
      setResults([])
    }
    let res = await axios.get(`/main/products/${productId}/`)
    setSelectedProduct(res?.data)
  }

  async function getProductAttributes(productId) {
    let res = await axios.get('/main/attributes/', {
      params: {
        product_id: productId,
      },
    })
    setAttributes(res?.data?.results)
  }

  useEffect(() => {
    if (attributes?.length > 0) {
      const headerRow = ['Product']
      const headerSearch = []
      const headerSort = []
      attributes.forEach((attribute, index) => {
        headerRow.push(attribute.name)
        headerSearch.push({
          header: attribute.name,
          type: 'dropdown',
          options: attribute?.attribute_selections_json?.map((selection) => {
            return {
              key: selection.id,
              value: selection.id,
              text: selection.value ? selection.value : 'Unspecified',
            }
          }),
          search_param: `attribute_selection_id_${index + 1}`,
        })
        headerSort.push({
          header: attribute.name,
          sort_by: 'attribute__selection__value',
        })
      })
      headerRow.push('')
      if (
        !tenant?.mickey_tenant &&
        props.supplier &&
        props.supplier?.show_product_prices_in_shop
      ) {
        headerRow.push(
          `Price/${selectedProduct?.default_unit_of_measure?.short_name}`
        )
      }
      if (editedAttributeSelections) {
        headerRow.push('Update Results')
        // Need to add functionality that shows button on mickey table header
        // <Button
        //   color={'brown'}
        //   loading={loading}
        //   onClick={() => {
        //     handleAttributeFilterSelection()
        //   }}
        // >
        //   Update Results
        // </Button>
      }
      setTableHeader({
        headerRow: headerRow,
        headerSort: headerSort,
        headerSearch: headerSearch,
      })
    } else {
      setTableHeader({})
    }
  }, [attributes])

  const getProductVariantsParams = (productId) => {
    const params = {
      product_id: productId,
      page: activePage,
    }
    if (tenant?.mickey_tenant === true) {
      params.tenant_aware = false
    }
    if (props.currentLocation) {
      params.location_id = props.currentLocation?.id
    }
    if (activeLocation) {
      params.location_id = activeLocation.id
    }
    // TODO
    if (props.filterActivePvs) {
      params.active = true
    }
    return params
  }

  const setProductVariantsRoute = (resetSearch) => {
    if (props.supplierOrder) {
      if (formik.values.onlyShowInventory === false) {
        setProductVariantsRouteState('/main/inventory/')
      } else {
        setProductVariantsRouteState('/main/product-variants/')
      }
      if (resetSearch) {
        setResults([])
        searchProducts()
      }
    }
  }

  useEffect(() => {
    if (props.supplier) {
      if (activeLocation) {
        searchProducts()
      }
    } else {
      searchProducts()
    }
  }, [activePage, activeLocation])

  useEffect(() => {
    setProductVariantsRoute(true)
  }, [formik.values.onlyShowInventory])

  function searchProducts() {
    if (selectedProduct?.id) {
      setEditedAttributeSelections(false)
    }
  }

  useEffect(() => {
    if (!props.reload) {
      setSubmitButtonLoading(-1)
    }
  }, [props.reload])

  let tableStartMessage =
    "Start by entering a product you'd like to purchase in the search box above."
  if (props.tableStartMessage !== undefined) {
    tableStartMessage = props.tableStartMessage
  }
  let productDowndownWidth = 16
  if (props.supplierOrder && !props.dealForm) {
    productDowndownWidth = 12
  }

  return (
    <Grid
      stackable
      style={
        props.dealForm
          ? {
              overflow: selectedProduct?.id ? 'scroll' : '',
            }
          : {
              minWidth: props?.style?.minWidth
                ? props?.style?.minWidth
                : '92vw',
              maxWidth: props.noMaxWidth ? 'inherit' : 'calc(100vw - 300px)',
              maxHeight: '70vh',
              overflow: selectedProduct?.id ? 'scroll' : '',
            }
      }
    >
      {!props.hideLocations &&
        props.supplier &&
        !props.productId &&
        locations?.length > 0 && (
          <Grid.Column width={16}>
            <Menu secondary style={{ overflow: 'scroll' }}>
              {props.supplier &&
                locations?.map((location) => {
                  return (
                    <Menu.Item
                      color={'green'}
                      style={{
                        borderRadius: '25px',
                      }}
                      onClick={() => {
                        setActiveLocation(location)
                        if (props.handleActiveLocation) {
                          props.handleActiveLocation(location)
                        }
                      }}
                      active={activeLocation === location}
                    >
                      {location?.name}
                    </Menu.Item>
                  )
                })}
            </Menu>
          </Grid.Column>
        )}
      {!props.productId ? (
        <>
          <Grid.Column width={productDowndownWidth / 2}>
            <div style={{ display: 'inline' }}>
              <MickeyDropdown
                url={'/main/products/'}
                name={'product'}
                params={{
                  commodity_id: selectedCommodity,
                  page_size: 25,
                }}
                value={selectedProduct}
                autoComplete="new-password"
                placeholder={'Select Product'}
                titleFields={['material.name']}
                descriptionFields={['commodity_form.name']}
                onChange={(e, d) => {
                  setProductVariantsRoute(false)
                  getSelectedProduct(d.value)
                  getProductAttributes(d.value)
                }}
              />
            </div>
          </Grid.Column>
        </>
      ) : null}
      {props.supplierOrder ? (
        <>
          {/* <Grid.Column width={4} style={{ display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Field
                control="input"
                type="checkbox"
                label="Only Show Inventory Items"
                name="onlyShowInventory"
                value={formik.values.onlyShowInventory}
                checked={formik.values.onlyShowInventory}
                onChange={formik.handleChange}
              />
            </div>
          </Grid.Column> */}
        </>
      ) : null}
      <Grid.Column width={16}>
        {Object.keys(tableHeader)?.length > 0 && selectedProduct?.id && (
          <MickeyTable
            headerRow={tableHeader?.headerRow}
            headerSearch={tableHeader?.headerSearch}
            headerSort={tableHeader?.headerSort}
            urlParams={{
              ...getProductVariantsParams(selectedProduct?.id),
            }}
            basicSegment
            showHeaderNoData={true}
            url={productVariantsRoute}
            hideFooter={true}
            reload={loading}
            setReload={setLoading}
            infiniteScroll={true}
            renderBodyRow={(result, index) => {
              let show = true
              for (let i = 0; i < props.addedResults?.length; i++) {
                let a = props.addedResults[i]?.attribute_selections?.map(
                  (sel) => sel?.attribute_selection_id
                )
                let b = result?.attribute_selections?.map(
                  (sel) => sel?.attribute_selection_id
                )
                let equal = arraysEqual(a, b)
                if (equal) {
                  show = false
                  break
                }
              }
              let a = props.seletedOrderItems?.find(
                (item) =>
                  item?.product_variant?.product?.id === result?.product?.id
              )?.product_variant?.attribute_selection_ids
              let b = result?.attribute_selections?.map(
                (item) => item.attribute_selection_id
              )
              return !arraysEqual(a, b) && show ? (
                <InventoryTableRow
                  result={result}
                  tenant={tenant}
                  fillCurrentTruck={fillCurrentTruck}
                  fullTruckload={fullTruckload}
                  submitButtonLoading={submitButtonLoading}
                  setSubmitButtonLoading={setSubmitButtonLoading}
                  activeLocation={activeLocation}
                  supplier={props.supplier}
                  showProductDetail={props.showProductDetail}
                  showAddButton={props.showAddButton}
                  onAddButton={props.onAddButton}
                  showAddToOrderButton={props.showAddToOrderButton}
                  includeSetSubmitButtonLoadingOnAddButton={
                    props.includeSetSubmitButtonLoadingOnAddButton
                  }
                  disableSubmitLoading={props.disableSubmitLoading}
                  addedProductVariants={props.addedProductVariants}
                />
              ) : (
                <></>
              )
            }}
            handleResponse={(data) => {
              if (!productVariantsRoute) {
                setProductVariantsRouteState('/main/inventory/')
              }
              setEditedAttributeSelections(false)
              let response_data = [...results, ...data]
              let uniqueKey = 'id'
              if (productVariantsRoute === '/main/inventory/') {
                uniqueKey = 'uuid'
              }
              response_data = getUniqueListBy(response_data, uniqueKey)
              if (props.setReloadPopup && props.setReloadPopup) {
                props.setReloadPopup(!props.reloadPopup)
              }
              setResults(response_data)
              return data
            }}
            emptyMessage={'No products found.'}
          />
        )}
        {!(Object.keys(tableHeader)?.length > 0 && selectedProduct?.id) && (
          <Segment>{tableStartMessage}</Segment>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default InventoryTable
