import React, { useEffect, useState, useRef } from 'react'
import { Form } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import '../../lib/css/Animations.css'
import './AutoField.css'
import { debounce } from 'lodash'

function AutoField(props) {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(props?.value)
  const [className, setClassName] = useState(props.className)
  const [resetCurrentFieldValue, setResetCurrentFieldValue] = useState(true)
  const axios = useAxios()

  useEffect(() => {
    if (props.currentUpdatingField !== props.field) {
      setResetCurrentFieldValue(true)
    }
    if (props?.resetvalue && props?.value !== value && resetCurrentFieldValue) {
      setValue(props.value)
      props.setResetvalue(false)
    }
  }, [props?.resetvalue, props?.value])

  useEffect(() => {
    async function fetchData() {
      let res = await axios.get(props.url + props.instanceId + '/')
      setValue(res.data[props.field])
      if (props.setValue) {
        props.setValue(res.data[props.field])
      }
      setLoading(false)
    }
    if (props.fetchInitialValue) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (props.overrideValue) {
      setValue(props.overrideValue)
    }
  }, [props.overrideValue])

  // const updateValue = debounce(async (value) => {
  //   let update = true
  //   if (props.preHandleUpdate) {
  //     update = props.preHandleUpdate(value)
  //   }
  //
  //   // setValue(value)
  //   if (props.canNotTakeNeagativeValue && (props.type === "number") && (value < 0) ) {
  //     return
  //   }
  //   if (update) {
  //     setLoading(true);
  //
  //     const timeoutId = setTimeout(() => {
  //       axios.post(`/main/auto-update/`, {
  //         model: props.model,
  //         field: props.field,
  //         value: value,
  //         instance_id: props.instanceId,
  //         serializer: props.serializer
  //       }).then((res) => {
  //       })
  //     }, 300);
  //
  //     return () => clearTimeout(timeoutId);
  //   } else {
  //     if (props.setValue) {
  //       props.setValue(value)
  //     }
  //
  //     if (props.setUpdate) {
  //       props.setUpdate(true);
  //     }
  //   }
  // }, 300);

  const updateValue = (value) => {
    if (
      props.canNotTakeNeagativeValue &&
      props.type === 'number' &&
      value < 0
    ) {
      return
    }
    setLoading(true)

    axios
      .post('/main/auto-update/', {
        model: props.model,
        field: props.field,
        updating_fields: props.updating_fields,
        value: value,
        instance_id: props.instanceId,
        serializer: props.serializer,
      })
      .then((res) => {
        if (props.setValue) {
          props.setValue(value)
        }

        if (props.setUpdate) {
          props.setUpdate(true)
        }

        if (props.onUpdate) {
          props.onUpdate(res)
        }
        if (props.setResetvalue) {
          setResetCurrentFieldValue(false)
          props.setResetvalue(true)
        }

        setTimeout(() => {
          if (props.className) {
            setClassName(props.className)
          } else {
            setClassName('')
          }
        }, 100)
        setLoading(false)
      })
      .catch((error) => {})
  }

  const debouncedUpdateData = useRef(debounce(updateValue, 300)).current
  //
  const handleInputChange = (value) => {
    setValue(value)
    debouncedUpdateData(value)
  }

  return (
    <>
      {props.textarea && (
        <Form.TextArea
          label={props.label}
          value={value}
          disabled={props.disabled}
          style={props.style}
          type={props.type}
          step={props.step}
          className={'auto-field ' + className}
          transparent={props.transparent}
          onChange={(event, data) => {
            handleInputChange(data.value)
            setValue(data.value)
          }}
          loading={loading}
        />
      )}
      {!props.textarea && (
        <Form.Input
          label={props.label}
          style={props.style}
          disabled={props.disabled}
          value={value}
          className={'auto-field ' + className}
          icon={loading ? '' : props.icon}
          iconPosition={props.iconPosition ? props.iconPosition : 'left'}
          size={props.size}
          type={props.type}
          step={props.step}
          transparent={props.transparent}
          onChange={(event, data) => {
            if (props.onChange) {
              props.onChange(event, data)
            }
            if (props.setCurrentUpdatingField) {
              props.setCurrentUpdatingField(props.field)
            }
            handleInputChange(data.value)
            setValue(data.value)
          }}
          loading={loading}
        />
      )}
    </>
  )
}

export default AutoField
