import SettlementStatement from '../../../components/SettlementStatement/SettlementStatement'

function AdminSettlementStatement(props) {
  return (
    <div style={{ margin: '25px' }}>
      <SettlementStatement />
    </div>
  )
}

export default AdminSettlementStatement
