import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import env from '../../env'
import { Button } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import './Slider.css'
import ProductIcon from '../ProductIcon/ProductIcon'

function ProductSlider(props) {
  let { tenant, tenantProducts, products } = props
  const navigate = useNavigate()

  const CustomDot = ({ active, onClick }) => {
    return (
      <li>
        <button
          className={`custom-dot ${active ? 'custom-dot--active' : ''}`}
          onClick={() => onClick()}
        />
      </li>
    )
  }
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 6, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <>
      {!tenant?.mickey_tenant && tenantProducts?.length > 0 && (
        <div>
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots
            customDot={<CustomDot />}
            infinite={true}
            autoPlay={true}
            focusOnSelect={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass="custom-dot-list-style"
            itemClass="image-item"
          >
            {tenantProducts.map((tenantProduct) => {
              if (tenantProduct?.material?.material_display_image === null) {
                return null
              } else {
                return (
                  <div className="species-slide">
                    <img
                      src={
                        env.REACT_APP_ENVIRONMENT === 'local'
                          ? `http://marketplace.localhost:8002${tenantProduct?.material?.material_display_image}`
                          : tenantProduct?.material?.material_display_image
                      }
                      alt=""
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <ProductIcon
                        productName={tenantProduct?.material?.name}
                      />
                      <p style={{ marginLeft: '1rem' }}>
                        {tenantProduct?.material?.name}
                      </p>
                    </div>
                    <Button
                      color="primary"
                      style={{
                        margin: '0 1rem 0 1rem',
                        borderRadius: '500px',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      }}
                      onClick={() =>
                        navigate(
                          `${
                            (!props.user && '/exchange') ||
                            (props.buyer && '/portal/buyer') ||
                            (props.supplier && '/portal/supplier') +
                              '/' +
                              tenantProduct?.commodity?.slug +
                              '/' +
                              tenantProduct?.commodity_form?.slug +
                              '/' +
                              tenantProduct?.slug
                          }`
                        )
                      }
                    >
                      View
                    </Button>
                  </div>
                )
              }
            })}
          </Carousel>
        </div>
      )}

      {tenant?.mickey_tenant && products.length > 0 && (
        <div>
          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots
            customDot={<CustomDot />}
            infinite={true}
            autoPlay={true}
            focusOnSelect={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            dotListClass="custom-dot-list-style"
            itemClass="image-item"
          >
            {products.map((product) => {
              if (product?.material?.material_display_image === null) {
                return null
              } else {
                return (
                  <div className="species-slide">
                    <a
                      href={`/exchange/${product?.commodity?.slug}/${product?.commodity_form?.slug}/${product.slug}/`}
                    >
                      <img
                        src={
                          env.REACT_APP_ENVIRONMENT === 'local'
                            ? `http://marketplace.localhost:8002${product?.material?.material_display_image}`
                            : product?.material?.material_display_image
                        }
                        alt=""
                      />
                    </a>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1.5rem',
                      }}
                    >
                      <a
                        href={`/exchange/${product?.commodity?.slug}/${product?.commodity_form?.slug}/${product.slug}/`}
                      >
                        <ProductIcon
                          productName={product?.material?.name}
                          width={'26px'}
                          padding={'3px'}
                          height={'26px'}
                        />
                      </a>
                      <a
                        href={`/exchange/${product?.commodity?.slug}/${product?.commodity_form?.slug}/${product.slug}/`}
                        style={{ margin: '0 0 0 1rem', color: 'black' }}
                      >
                        {product?.material?.name}
                      </a>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1.5rem",
                      }}
                    >
                      <a href={`/portal/${currentPortal}/products/${product?.slug}`}>Explore Options</a>
                      <ArrowRight
                        size={22}
                        color={"#4183C4"}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div> */}
                  </div>
                )
              }
            })}
          </Carousel>
        </div>
      )}
    </>
  )
}

export default ProductSlider
