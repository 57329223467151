import { useState } from 'react'
import env from '../../env'
import { Grid, Modal, Loader } from 'semantic-ui-react'
import SettlementStatementRow from './SettlementStatementRow'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import MickeyForm from '../Forms/MickeyForm'
import { toast } from 'react-toastify'
import { Plus } from 'phosphor-react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../lib/utils/utils'

function SettlementStatement(props) {
  const user = useSelector((state) => state.user.user)
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const [openAddSettlementStatementModal, setOpenAddSettlementStatementModal] =
    useState()
  const [reload, setReload] = useState()
  const [loading, setLoading] = useState()
  const headerRow = [
    'Start Date',
    'End Date',
    'Supplier',
    'Total Quantity',
    'Total Price',
  ]
  if (hasPermission(user, 'Read Document')) {
    headerRow.push('Document Generation')
  }
  const headerSearch = [
    {
      header: 'Start Date',
      type: 'date_range',
      search_param_start: 'start_date_start',
      search_param_end: 'start_date_end',
    },
    {
      header: 'End Date',
      type: 'date_range',
      search_param_start: 'end_date_start',
      search_param_end: 'end_date_end',
    },
    {
      header: 'Supplier',
      type: 'dropdown',
      options_url: 'suppliers/',
      options_params: { tenant_aware: false },
      options_titleFields: ['business.name'],
      search_param: 'supplier_id',
    },
    {
      header: 'Total Quantity',
      type: 'number_range',
      search_param_start: 'net_weight_total_start',
      search_param_end: 'net_weight_total_end',
    },
    {
      header: 'Total Price',
      type: 'number_range',
      search_param_start: 'total_amount_start',
      search_param_end: 'total_amount_end',
    },
    {
      header: 'Document Generation',
      type: 'dropdown',
      options: [
        {
          text: 'Pending',
          value: 'sent',
        },
        {
          text: 'Complete',
          value: 'signed',
        },
        {
          text: 'Declined',
          value: 'declined',
        },
      ],
      search_param: 'box_sign_requests__sign_status',
    },
  ]
  const headerSort = [
    {
      header: 'Start Date',
      sort_by: 'start_date',
    },
    {
      header: 'End Date',
      sort_by: 'end_date',
    },
    {
      header: 'Supplier',
      sort_by: 'supplier__business__name',
    },
    {
      header: 'Total Quantity',
      sort_by: 'net_weight_total',
    },
    {
      header: 'Total Price',
      sort_by: 'total_amount',
    },
    {
      header: 'Document Generation',
      sort_by: 'box_sign_requests__sign_status',
    },
  ]

  return (
    <>
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={'Settlement Statements'}
              subheader={
                'Add, archive, or edit settlement statement at your company.'
              }
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
              buttons={[
                {
                  color: 'primary',
                  content: 'Add Settlement Statement',
                  icon: <Plus weight="bold" />,
                  onClick: () => {
                    setOpenAddSettlementStatementModal(true)
                  },
                  style: {
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  },
                },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={headerRow}
              headerSearch={headerSearch}
              headerSort={headerSort}
              basicSegment
              urlParams={{}}
              showHeaderNoData={true}
              url={'/main/admin-settlement-statement/'}
              hideFooter={false}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <SettlementStatementRow
                    index={index}
                    reload={reload}
                    setReload={setReload}
                    settlementStatement={data}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Settlement statement will populate here'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        className="add-settlement-statement"
        size={'large'}
        open={openAddSettlementStatementModal}
        onOpen={() => setOpenAddSettlementStatementModal(true)}
        onClose={() => setOpenAddSettlementStatementModal(false)}
        closeIcon
      >
        <Modal.Header>Add Settlement Statement</Modal.Header>
        <Modal.Content>
          {loading && (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 10000,
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10000,
                }}
              >
                <Loader active inline="centered" />
                <div
                  style={{
                    marginTop: '10px',
                    color: 'white',
                    fontSize: '16px',
                  }}
                >
                  Queueing Document Generation...
                </div>
              </div>
            </>
          )}
          <MickeyForm
            formName={'SettlementStatementForm'}
            model={'SettlementStatement'}
            tenantAware={false}
            url={'/main/admin-settlement-statement/'}
            buttonLabel={'Create'}
            preSubmit={() => {
              setLoading(true)
              toast.success(
                'Please wait while the settlement statement document generation is queued'
              )
            }}
            handleForm={(status) => {
              if (status === 'success') {
                setReload(true)
                setOpenAddSettlementStatementModal(false)
                toast.success(
                  `Settlement statement document generation started!`
                )
              } else if (status === 'failure') {
                toast.error(`Settlement statement generation failed`)
              }
              setLoading(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default SettlementStatement
