import React, { useEffect } from 'react'
import { Header, Grid } from 'semantic-ui-react'
import './MxContactUs.css'
import MickeyForm from '../../../components/Forms/MickeyForm'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import env from '../../../env'
import { useNavigate } from 'react-router-dom'

const MxContactUs = () => {
  const messageSent = false
  const wd = useWindowDimensions()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      env.REACT_APP_REDIRECT &&
      env.REACT_APP_REDIRECT !== 'false' &&
      env.REACT_APP_REDIRECT !== 'null'
    ) {
      navigate('/login')
    }
  }, [])

  return (
    <Grid width={16} id="contact-us-page">
      <Grid.Column
        width={
          (wd?.width > 1300 && 7) || (wd?.width < 1300 && wd.width >= 768 && 10)
        }
      >
        <Header textAlign="center" as={'h1'} style={{ fontSize: '36px' }}>
          Contact Us
        </Header>
        {!messageSent && (
          <MickeyForm
            style={{ marginTop: '25px', padding: '0 15px' }}
            formName={'ContactUsForm'}
            basicSegment
            buttonLabel={'Submit'}
            url={`/main/contact-us/`}
            successMessage={
              "Thanks reaching out! We'll get back to you shortly!"
            }
            failureMessage={'Something went wrong. Please try again.'}
            buttonSize={'full'}
            buttonHeight={'50px'}
            handleForm={(message, data) => {
              if (message === 'success') {
                navigate('/')
              }
            }}
          />
        )}
        {messageSent && (
          <Header as={'h1'}>
            Thank you for reaching out! We'll get back to you soon!
          </Header>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default MxContactUs
