import React from 'react'
import CustomLumberLengthFilter from './CustomLumberAttributeFilters/CustomLumberLengthFilter'
const CustomLumberFilter = (props) => {
  const {
    attribute,
    handleFilterSelection,
    commodityForm,
    handleRemoveFilterSelection,
    updateCustomSelections,
    checkFilterSelection,
    setCustomFilterSelections,
    customFilterSelections,
    commodity,
  } = props
  return (
    <>
      {attribute?.name === 'Length' && (
        <CustomLumberLengthFilter
          attribute={attribute}
          handleFilterSelection={handleFilterSelection}
          commodityForm={commodityForm}
          commodity={commodity}
          handleRemoveFilterSelection={handleRemoveFilterSelection}
          updateCustomSelections={updateCustomSelections}
          checkFilterSelection={checkFilterSelection}
          setCustomFilterSelections={setCustomFilterSelections}
          customFilterSelections={customFilterSelections}
        />
      )}
    </>
  )
}

export default CustomLumberFilter
