import ProductAttributeFormFields from '../components/PriceHistory/ProductAttributeFormFields'

export const ExchangeOrderHistorySecondaryForm = {
  model: 'ExchangeOrderHistory',
  format: [
    {
      widths: 'equal',
      fields: {
        business: {
          required: true,
          type: 'dropdown search',
          url: '/main/businesses/',
          label: 'Business',
          title_fields: ['name'],
          filter_field: 'name__icontains',
          disabled_default_field: true,
          params: {
            tenant_aware: false,
            all_businesses: 'true',
            extra_light_serializer: 'true',
          },
          description_fields: ['contact_first_name', 'contact_last_name'],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        location: {
          required: true,
          type: 'dropdown search',
          url: '/main/locations/',
          require_params: true,
          related_params: {
            business: 'business_id',
          },
          params: {
            tenant_aware: false,
          },
          title_fields: ['name'],
          description_fields: ['address_1', 'city', 'state', 'zip_code'],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        product: {
          required: true,
          type: 'dropdown search',
          url: '/main/products/',
          params: {
            tenant_aware: false,
          },
          title_fields: ['name'],
          model: 'Product',
          description_fields: ['str'],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        attribute_selection_ids: {
          type: 'custom field',
          hide_label: true,
          hide_error: true,
          custom_field_components: (
            key,
            value,
            handleInputChange,
            labelSuccessField,
            instanceLabel,
            values,
            error
          ) => {
            return (
              <ProductAttributeFormFields
                fieldName={key}
                value={value}
                handleInputChange={handleInputChange}
                labelSuccessField={labelSuccessField}
                instanceLabel={instanceLabel}
                values={values}
                error={error}
              />
            )
          },
          handleFormDataAppend: (key, value, formData) => {
            try {
              value?.forEach((item) => {
                formData.append(key + '[]', item)
              })
              return formData
            } catch {
              return formData
            }
          },
          validator: (input) => {
            let error = false
            if (input && Array.isArray(input)) {
              input?.map((val, idx) => {
                if (val === 0) {
                  error = true
                }
              })
              if (!input?.length) {
                error = true
              }
            }
            return error
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        user: {
          required: true,
          type: 'dropdown search',
          url: '/main/admin-users/',
          params: {
            tenant_aware: false,
            extra_light_serializer: 'true',
          },
          title_fields: ['first_name', 'last_name'],
          model: 'CustomUser',
          description_fields: ['email'],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        unit: {
          label: 'Unit of Measure',
          type: 'dropdown search',
          url: '/main/unit-of-measures/',
          require_params: true,
          related_params: {
            product: 'product_id',
          },
          params: {
            tenant_aware: false,
          },
          title_fields: ['plural_name'],
          description_fields: ['short_name'],
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        side: {
          required: true,
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        _secondary_price: {
          label: 'Price',
          required: false,
          type: 'number',
          step: 0.01,
        },
      },
    },
  ],
}
