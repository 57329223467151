export const BoxItemESignTemplateForm = {
  model: 'BoxItem',
  format: [
    {
      widths: 'equal',
      fields: {
        e_sign_email_subject: {
          required: true,
          type: 'text',
          label: 'Email Subject',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        e_sign_email_message: {
          required: true,
          type: 'text area',
          label: 'Email Message',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        e_sign_document_visibility: {
          required: true,
          label: 'Signed Document Visibility',
          type: 'dropdown',
        },
      },
    },
  ],
}
