import { useState } from 'react'
import _ from 'underscore'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { Modal, Header, Segment, Button, Grid } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import AdminBusinessesRow from './AdminBusinessesRow'
import env from '../../../env'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import '@silevis/reactgrid/styles.css'
import AdminDashboardMap from '../AdminDashboard/AdminDashboardMap'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import { Plus } from 'phosphor-react'

function AdminBusinesses(props) {
  const user = useSelector((state) => state.user.user)
  const [reload, setReload] = useState(false)
  const [openBusinessModal, setOpenBusinessModal] = useState(null)
  const [activeItem, setActiveItem] = useState('list')
  const [applicationType, setApplicationType] = useState('Buyer')

  let headerRow = [
    'Name',
    'Type',
    'Contact Name',
    'Email',
    'Phone',
    'Salespeople',
    'Account Status',
  ]
  if (hasPermission(user, 'Delete Business')) {
    headerRow.push('Actions')
  }

  let Type = [(1, 'Buyer'), (2, 'Supplier')]

  function handleBusinessForm(status, data) {
    setReload(true)
  }

  const headerSearch = [
    {
      header: 'Name',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Business',
        query_dict: { name__isnull: false },
        filter_field_value_list: ['name'],
      },
      options_titleFields: ['name'],
      options_valueFields: ['name'],
      search_param: 'name',
    },
    {
      header: 'Type',
      type: 'dropdown',
      options: [
        { key: 1, value: 1, text: 'Buyer' },
        { key: 2, value: 2, text: 'Supplier' },
      ],
      search_param: 'type',
    },
    {
      header: 'Contact Name',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Business',
        query_dict: { contact_first_name__isnull: false },
        filter_field_value_list: ['contact_first_name', 'contact_last_name'],
      },
      options_titleFields: ['contact_first_name', 'contact_last_name'],
      options_valueFields: ['contact_first_name', 'contact_last_name'],
      search_param: 'contact_full_name',
    },
    {
      header: 'Email',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Business',
        query_dict: { contact_email__isnull: false },
        filter_field_value_list: ['contact_email'],
      },
      options_titleFields: ['contact_email'],
      options_valueFields: ['contact_email'],
      search_param: 'contact_email',
    },
    {
      header: 'Phone',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Business',
        query_dict: { contact_phone__isnull: false },
        filter_field_value_list: ['contact_phone'],
      },
      options_titleFields: ['contact_phone'],
      options_valueFields: ['contact_phone'],
      options_formate_phone_number: true,
      search_param: 'contact_phone',
    },
    {
      header: 'Salespeople',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Business',
        query_dict: { salespersons__isnull: false },
        filter_field_value_list: [
          'salespersons__first_name',
          'salespersons__last_name',
        ],
      },
      options_titleFields: [
        'salespersons__first_name',
        'salespersons__last_name',
      ],
      options_valueFields: [
        'salespersons__first_name',
        'salespersons__last_name',
      ],
      search_param: 'salespeople',
    },
    {
      header: 'Account Status',
      type: 'dropdown',
      search_param: 'status',
      options: [
        {
          key: 'Pending Approval',
          text: 'Pending Approval',
          value: 'Pending Approval',
        },
        { key: 'Approved', text: 'Approved', value: 'Approved' },
        { key: 'Contacted', text: 'Contacted', value: 'Contacted' },
        { key: 'Lead', text: 'Lead', value: 'Lead' },
        { key: 'Unqualified', text: 'Unqualified', value: 'Unqualified' },
      ],
    },
  ]
  const headerSort = [
    {
      header: 'Name',
      sort_by: 'name',
    },
    {
      header: 'Type',
      hide_sort_by: true,
    },
    {
      header: 'Contact Name',
      sort_by: 'contact_first_name',
    },
    {
      header: 'Email',
      sort_by: 'contact_email',
    },
    {
      header: 'Phone',
      sort_by: 'contact_phone',
    },
    {
      header: 'Salespeople',
      sort_by: 'salespersons__first_name',
    },
    {
      header: 'Account Status',
      hide_sort_by: true,
    },
  ]

  const urlParams = {
    light_serializer: 'true',
    tenant_aware: false,
    salesperson_id: props?.salespersonId,
  }

  return (
    <div className={'main-container'}>
      {!props.hideHeader && (
        <PageHeader
          icon={'address book'}
          header={'Businesses'}
          buttons={
            hasPermission(user, 'Create Business')
              ? [
                  {
                    color: 'primary',
                    icon: <Plus weight="bold" />,
                    content: 'Add business',
                    onClick: () => {
                      setOpenBusinessModal(true)
                    },
                    style: {
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    },
                  },
                ]
              : []
          }
        />
      )}
      {!props.hideItems && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={16} style={{ paddingLeft: '25px' }}>
              <div className="exchange-table-heading">
                <div className="commodities-wrapper">
                  <Header
                    as={'h2'}
                    className={
                      activeItem === 'list'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={activeItem === 'list' ? 'primary' : 'black'}
                    onClick={() => setActiveItem('list')}
                    style={{
                      margin: '0 1rem 1rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Business List
                  </Header>
                  <Header
                    as={'h2'}
                    className={
                      activeItem === 'map'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={activeItem === 'map' ? 'primary' : 'black'}
                    onClick={() => setActiveItem('map')}
                    style={{
                      margin: '0 1rem 1rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Locations Map
                  </Header>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {activeItem === 'list' && (
        <Segment style={{ overflowX: 'auto' }}>
          <MickeyTable
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            url={'/main/admin-businesses/'}
            hideFooter={false}
            reload={reload}
            setReload={setReload}
            renderBodyRow={(data, index) => {
              return (
                <AdminBusinessesRow
                  business={data}
                  setBusiness={(business) => {
                    data = business
                  }}
                  setReload={setReload}
                  reload={reload}
                  index={index}
                  key={data ? data.id : null}
                />
              )
            }}
            emptyIcon={'file'}
            emptyMessage={'Business will populate here'}
          />
        </Segment>
      )}

      {activeItem === 'map' && <AdminDashboardMap />}
      <Modal
        onClose={() => setOpenBusinessModal(false)}
        onOpen={() => setOpenBusinessModal(true)}
        open={openBusinessModal}
      >
        <Modal.Header>Add Business</Modal.Header>
        <Modal.Content>
          <Grid stackable>
            <Grid.Row style={{ marginTop: '0px' }}>
              <Grid.Column>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Button.Group
                    style={{
                      borderRadius: '5px',
                    }}
                  >
                    <Button
                      color={
                        applicationType === 'Supplier' ? 'lightgrey' : 'green'
                      }
                      onClick={() => setApplicationType('Buyer')}
                    >
                      Buyer
                    </Button>
                    <Button.Or />
                    <Button
                      color={
                        applicationType === 'Supplier' ? 'orange' : 'lightgrey'
                      }
                      onClick={() => setApplicationType('Supplier')}
                    >
                      Supplier
                    </Button>
                  </Button.Group>
                </div>
                <Segment
                  className={' slide-up'}
                  style={{
                    paddingBottom: '25px',
                  }}
                >
                  <Header
                    style={{ textAlign: 'center', padding: '10px' }}
                    color={applicationType === 'Supplier' ? 'orange' : 'green'}
                  >
                    <b>{applicationType} Application</b>
                  </Header>
                  <MickeyForm
                    formName={'AdminBusinessApplicationForm'}
                    model={'Business'}
                    innerSuccessMessage={
                      "Thanks for applying! We'll get back to you shortly!"
                    }
                    buttonFloatedRight={true}
                    successMessage={
                      "Thanks for applying! We'll get back to you shortly!"
                    }
                    handleForm={handleBusinessForm}
                    failureMessage={'Error with your application.'}
                    url={'/main/p-businesses/'}
                    buttonLabel={
                      applicationType === 'Supplier'
                        ? 'Add Supplier'
                        : 'Add Buyer'
                    }
                    values={{
                      is_supplier: applicationType === 'Supplier' ? true : null,
                      is_buyer: applicationType === 'Buyer' ? true : null,
                    }}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminBusinesses
