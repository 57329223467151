import env from '../env'
import { format_phone_number } from '../lib/utils/utils'

export const SalesLogForm = {
  model: 'SalesLog',
  format: [
    {
      widths: 'equal',
      fields: {
        user: {
          type: 'dropdown search',
          required: false,
          url: '/main/custom-users/',
          title_fields: ['first_name', 'last_name'],
          description_fields: [],
          require_params: true,
          url_params: ['business_id'],
          params: {
            tenant_aware: false,
            extra_light_serializer: 'true',
          },
          allow_add: true,
          show_allow_add_as_option: true,
          allow_add_option_text: 'Add User',
          allow_add_url_params: ['business_id'],
          model: 'CustomUser',
          model_form: 'AdminCustomUserForm',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        communication_type: {
          type: 'dropdown',
          required: true,
          require_url_keys: ['user'],
          description_fields: [''],
          fetch: async (axios, url_keys) => {
            let url = `/main/custom-users/`
            url_keys?.map((key) => {
              url = url + `${key}/`
            })
            const res = await axios.get(url)
            let choices = []
            if (res?.data) {
              const email = res?.data?.email
              const phone = res?.data?.phone
              choices.push({
                text: email,
                value: email,
                key: email,
              })
              if (phone) {
                choices.push({
                  text: format_phone_number(phone),
                  value: phone,
                  key: phone,
                })
              } else {
                choices.push({
                  text: phone ? phone : 'Add User Phone Number',
                  value: phone ? phone : 'Add User Phone Number',
                  key: phone ? phone : 'Add User Phone Number',
                  content: (
                    <b style={{ color: env.REACT_APP_PRIMARY_COLOR }}>
                      Add User Phone Number
                    </b>
                  ),
                })
              }
            }
            return choices
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        description: {
          rows: 6,
          required: true,
          type: 'text area',
        },
      },
    },
  ],
}
