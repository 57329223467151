import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Modal, Menu, Segment, Sidebar, Header } from 'semantic-ui-react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import InventoryTable from '../../supplier/Inventory/InventoryTable'
import { toast } from 'react-toastify'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'
import './AdminOrder.css'
import env from '../../../env'
import {
  Chats,
  Swap,
  FileText,
  Info,
  Package,
  TextAlignLeft,
  Truck,
  X,
  Plus,
  Lock,
  ArrowClockwise,
  ListBullets,
  Calculator,
} from 'phosphor-react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import MickeySideBar from '../../../components/MickeySideBar/MickeySideBar'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import PillIcon from '../../../components/PillIcon'

function AdminOrder(props) {
  const axios = useAxios()
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const user = useSelector((state) => state.user.user)
  const {
    order,
    setOrder,
    orderItems,
    setOrderItems,
    setChangeOrderModalOpen,
    reloadAdminOrder,
    setReloadAdminOrder,
  } = props
  const [supplier, setSupplier] = useState(false)
  const [business, setBusiness] = useState(false)
  const [reloadPopup, setReloadPopup] = useState(false)
  const [pickupLocation, setPickupLocation] = useState(null)
  const [loading, setLoading] = useState(false)
  const [activeButton, setActiveButton] = useState('Info')
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [openCancelOrderPopUp, setOpenCancelOrderPopUp] = useState(false)
  const [sidebarVisible, setSidebarVisible] = useState(true)
  const [sendingInvoiceLoading, setSendingInvoiceLoading] = useState(false)
  const [openAddProductModal, setOpenAddProductModal] = useState(false)
  const [addedResults, setAddedResults] = useState([])
  const { orderId } = useParams()
  const windowDimensions = useWindowDimensions()
  const mobiledisplay = windowDimensions.width < 768
  let navigate = useNavigate()
  const location = useLocation()
  let handleHeaderClick = (name) => {
    setActiveButton(name)
    navigate(tabUrl[name])
  }

  const tabUrl = {
    Info: 'info',
    'Material List': 'materials-list',
    Fulfillment: 'fulfillment',
    Tasks: 'tasks',
    'Buyer Requests': 'buyer-requests',
    Documents: 'documents',
    Accounting: 'payments',
    'Change Orders': 'change-orders',
  }

  useEffect(() => {
    setActiveButton(
      location.pathname.includes(tabUrl['Info'])
        ? 'Info'
        : location.pathname.includes(tabUrl['Material List'])
        ? 'Material List'
        : location.pathname.includes(tabUrl['Fulfillment'])
        ? 'Fulfillment'
        : location.pathname.includes(tabUrl['Buyer Requests'])
        ? 'Buyer Requests'
        : location.pathname.includes(tabUrl['Documents'])
        ? 'Documents'
        : location.pathname.includes(tabUrl['Accounting'])
        ? 'Accounting'
        : location.pathname.includes(tabUrl['Change Orders'])
        ? 'Change Orders'
        : location.pathname.includes(tabUrl['Tasks'])
        ? 'Tasks'
        : 'Material List'
    )
  }, [location?.pathname])

  async function createOrderItem(result) {
    setShowAddItemModal(false)
    const attributeSelectionIds = result.attribute_selections.map(
      (attributeSelection) => {
        return attributeSelection.attribute_selection_id
      }
    )
    let res = await axios.post(`/main/order-items/`, {
      location_id: pickupLocation?.id,
      product_variant_id: result.id,
      attribute_selection_ids: attributeSelectionIds,
      product_id: result.product.id,
      supplier_id: supplier?.id,
      order_id: order?.id,
      pickup_location_id: pickupLocation?.id,
    })
    if (res?.data?.id) {
      setReloadAdminOrder(true)
      toast.success('Added item to order!')
      setAddedResults((prevstate) => [...prevstate, result])
    }
  }

  async function loadData() {
    if (reloadAdminOrder) {
      setLoading(true)
    }
    let res = await axios.get(`/main/orders/${orderId}/`, {
      params: {
        tenant_aware: false,
      },
    })

    setOrder(res?.data)
    setPickupLocation(res?.data?.pickup_address)
    setSupplier(res?.data?.supplier)
    setBusiness(res?.data?.supplier?.business)
    let copyOrderItems = [...res?.data?.order_items]
    if (
      res?.data?.mx_order &&
      parseFloat(res?.data?.buyer_marketplace_fee) > 0
    ) {
      copyOrderItems = [
        ...res?.data?.order_items,
        { is_buyer_marketplace_fee: true, ...res?.data },
      ]
    }
    copyOrderItems = copyOrderItems.map((oi) => {
      return {
        ...oi,
        selected_sales_order_unit_of_measure: oi.buyer_unit?.id,
        selected_sales_order_quantity: oi.buyer_quantity,
        selected_invoice_quantity: oi.buyer_quantity,
        selected_invoice_unit_of_measure: oi.buyer_unit?.id,
      }
    })
    setOrderItems(copyOrderItems)
    setLoading(false)
    setReloadAdminOrder(false)
  }

  useEffect(() => {
    if (reloadAdminOrder || activeButton === 'Material List') {
      loadData()
    }
  }, [reloadAdminOrder, activeButton])

  useEffect(() => {
    setReloadAdminOrder(true)
  }, [orderId])

  useEffect(() => {
    setOrderItems([])
  }, [])

  async function cancelOrder() {
    setSendingInvoiceLoading(true)
    let res = await axios.patch(
      '/main/admin-orders/' + order.string_token + '/cancel_order/'
    )
    if (res.status < 400 && !res.data?.error) {
      toast.success('Order canceled')
      setSendingInvoiceLoading(false)
      setOrder(res?.data)
      setPickupLocation(res?.data?.pickup_address)
      setSupplier(res?.data?.supplier)
      setBusiness(res?.data?.supplier?.business)
      setOrderItems(res?.data?.order_items)
      setOpenCancelOrderPopUp(false)
    } else {
      toast.error('Error canceling order.')
    }
  }

  let breadcrumbData = [
    { name: 'Deals', href: '/portal/admin/deals' },
    {
      name: 'Deal #' + order?.order_number,
      href: '/portal/admin/deals/' + order?.string_token,
    },
  ]

  const finalizeBid = async () => {
    await axios.patch(
      `/main/orders/${order?.string_token}/?tenant_aware=false/`,
      {
        finalizing: true,
        order_id: order?.id,
        locked: true,
      }
    )
    setReloadAdminOrder(true)
  }

  const ledgerItemsTotal = () => {
    let lit = 0
    order?.ledger_items?.forEach((ledgerItem) => {
      if (ledgerItem.type === 'Disbursement') {
        lit -= parseFloat(ledgerItem.amount_transferred)
      }
      if (ledgerItem.type === 'Receipt') {
        lit += parseFloat(ledgerItem.amount_transferred)
      }
    })
    return lit
  }

  let buttons = []
  if (supplier?.id === order?.supplier?.id) {
    if (hasPermission(user, 'Delete Order')) {
      buttons.push({
        icon: <X weight="bold" />,
        color: 'red',
        content: 'Cancel Deal',
        disabled: order?.admin_order_status === 6,
        hidden: activeButton !== 'Info',
        labelPosition: 'left',
        popupOffset: [-325, 0],
        popupOpen: openCancelOrderPopUp,
        popupOnOpen: () => setOpenCancelOrderPopUp(true),
        popupOnClose: () => setOpenCancelOrderPopUp(false),
        popup: true,
        popupPosition: 'bottom right',
        id: 'mobile-buttons',
        popupContent: (
          <Grid style={{ minWidth: '300px', padding: '0 15px' }}>
            <Grid.Row>
              <div style={{ margin: '10px auto' }}>Are you sure!</div>
              <MickeyButton
                color={'red'}
                content={'Cancel Deal'}
                loading={false}
                disabled={order?.admin_order_status === 6}
                innerStyle={{ width: '100%' }}
                style={{
                  marginTop: '20px',
                  width: '90%',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  cancelOrder()
                }}
              />
            </Grid.Row>
          </Grid>
        ),
      })
    }
    // if (hasPermission(user, 'Update Order')) {
    //   buttons.push({
    //     icon: <PaperPlaneTilt weight="bold" />,
    //     color: "orange",
    //     content: "Send Bid",
    //     hidden: activeButton !== "Material List",
    //     disabled: bidOrderButtonActive,
    //     id: 'mobile-buttons',
    //     labelPosition: "left",
    //     style: { height: "40px" },
    //     onClick: () => { setAddBidOrderModal(true) }
    //   })
    // }
    if (hasPermission(user, 'Create Material List')) {
      buttons.push({
        icon: <Plus weight="bold" />,
        color: 'green',
        content: 'Add Product',
        hidden: activeButton !== 'Material List',
        disabled:
          order?.paid || order?.locked || order?.admin_order_status === 6,
        id: 'mobile-buttons',
        onClick: () => {
          setOpenAddProductModal(true)
        },
      })
    }
    if (hasPermission(user, 'Create Material List')) {
      buttons.push({
        icon: <Lock weight="bold" />,
        color: 'green',
        content: 'Finalize Bid',
        hidden:
          activeButton !== 'Material List' ||
          globalSettings?.hide_change_orders,
        disabled:
          order?.paid || order?.locked || order?.admin_order_status == 6,
        id: 'mobile-buttons',
        onClick: () => finalizeBid(),
      })
    }
    if (hasPermission(user, 'Create Change Order')) {
      buttons.push({
        icon: <ArrowClockwise weight="bold" />,
        color: 'yellow',
        content: 'Create Change Order',
        hidden:
          activeButton !== 'Change Orders' ||
          globalSettings?.hide_change_orders,
        id: 'mobile-buttons',
        onClick: () => setChangeOrderModalOpen(true),
      })
    }
  }
  if (!order) return <></>
  return (
    <div id={'admin-order'}>
      <MickeySideBar
        as={Menu}
        animation="Uncover"
        icon="labeled"
        vertical
        visible={sidebarVisible}
        width="thin"
        activeButton={activeButton}
        setActiveButton={handleHeaderClick}
        sidebarContent={{
          header: {
            icon: (
              <Package
                weight="fill"
                size={30}
                style={{
                  marginBottom: '0px',
                  paddingBottom: '0px',
                  display: 'inline',
                }}
              />
            ),
            header: `Deal #${order?.order_number}`,
            sub_header: (
              <>
                {order?.mobile_app && (
                  <PillIcon
                    style={{ marginLeft: '-4px', marginTop: '3px' }}
                    text={'mobile app'}
                  />
                )}
                {order?.scrapgo_status === 'New Deal' && (
                  <PillIcon
                    color={'red'}
                    style={{ marginLeft: '-4px', marginTop: '3px' }}
                    text={'new'}
                  />
                )}
                {order?.scrapgo_status === 'Active Deal' && (
                  <PillIcon
                    color={'yellow'}
                    style={{ marginLeft: '-4px', marginTop: '3px' }}
                    text={'active'}
                  />
                )}
                {order?.scrapgo_status === 'Completed Deal' && (
                  <PillIcon
                    color={'green'}
                    style={{ marginLeft: '-4px', marginTop: '3px' }}
                    text={'completed'}
                  />
                )}
              </>
            ),
          },
          sections: {
            ORDER: {
              items: [
                {
                  icon: <Info size={20} />,
                  text: 'Info',
                  permission: hasPermission(user, 'Read Order'),
                },
                {
                  icon: <TextAlignLeft size={20} />,
                  text: 'Material List',
                  permission: hasPermission(user, 'Read Material List'),
                },
                {
                  icon: <Truck size={20} />,
                  text: 'Fulfillment',
                  permission: hasPermission(user, 'Read Fulfillment'),
                },
                {
                  icon: <ListBullets size={20} />,
                  text: 'Tasks',
                  html: (
                    <span>{order?.task_count ? order?.task_count : 0}</span>
                  ),
                },
                {
                  icon: <Chats size={20} />,
                  text: 'Buyer Requests',
                  permission:
                    hasPermission(user, 'Read Buyer Request') &&
                    globalSettings?.id &&
                    !globalSettings?.hide_buyer_requests,
                },
                {
                  icon: <Swap size={20} />,
                  text: 'Change Orders',
                  permission:
                    order?.locked &&
                    globalSettings?.id &&
                    !globalSettings?.hide_change_orders,
                },
              ],
            },
            DOCUMENTS: {
              permission: hasPermission(user, 'Read Document'),
              items: [
                {
                  icon: <FileText size={20} />,
                  text: 'Documents',
                  activeButtonText: 'Documents',
                },
              ],
            },
            ACCOUNTING: {
              permission: hasPermission(user, 'Read Payment'),
              items: [
                {
                  icon: <Calculator size={20} />,
                  text: 'Accounting',
                  html: (ledgerItemsTotal < 0 || ledgerItemsTotal > 0) && (
                    <div
                      style={{
                        backgroundColor: 'red',
                        width: '10px',
                        height: '10px',
                        borderRadius: '5px',
                      }}
                    ></div>
                  ),
                },
              ],
            },
          },
        }}
      />
      <>
        <Segment
          basic
          loading={loading}
          style={{ minHeight: '200px' }}
          className={'main-segment-sidebar-container'}
        >
          <PageHeader
            icon={'file'}
            header={activeButton}
            breadcrumb={breadcrumbData}
            buttons={buttons}
          />
          <div className="no-top-padding">
            <Outlet />
          </div>
        </Segment>
      </>
      <Modal
        onClose={() => setOpenAddProductModal(false)}
        onOpen={() => setOpenAddProductModal(true)}
        open={openAddProductModal}
        size={'large'}
        style={{ width: '60%' }}
      >
        <Modal.Header>Add Product</Modal.Header>
        <Modal.Content>
          <InventoryTable
            addedResults={addedResults}
            setAddedResults={setAddedResults}
            seletedOrderItems={orderItems}
            hideLocations={true}
            supplierOrder={true}
            showAddToOrderButton
            supplier={supplier}
            business={business}
            setReloadPopup={setReloadPopup}
            reloadPopup={reloadPopup}
            reload={reloadAdminOrder}
            productVariantsRoute={'/main/product-variants/'}
            tableStartMessage={
              "Start by searching for the product you'd like to add to this order."
            }
            onAddButton={(result) => {
              createOrderItem(result)
            }}
            style={{ minWidth: '100%' }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setShowAddItemModal(false)}
        onOpen={() => setShowAddItemModal(true)}
        open={showAddItemModal}
        size={'fullscreen'}
      >
        <Modal.Header>Add Order Items</Modal.Header>
        <Modal.Content>
          <InventoryTable
            supplierOrder={true}
            showAddToOrderButton
            supplier={supplier}
            business={business}
            setReloadPopup={setReloadPopup}
            reloadPopup={reloadPopup}
            reload={reloadAdminOrder}
            productVariantsRoute={'/main/product-variants/'}
            tableStartMessage={
              "Start by searching for the product you'd like to add to this order."
            }
            onAddButton={(result) => {
              createOrderItem(result)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminOrder
