export const SupplierOrderForm = {
  model: 'Order',
  format: [
    {
      widths: 'equal',
      fields: {
        buyer: {
          required: true,
          type: 'dropdown search',
          url: '/main/buyers/',
          label: 'Buyer',
          title_fields: ['business.name'],
          filter_field: 'business__name__icontains',
          params: {
            tenant_aware: false,
          },
          description_fields: [
            'business.contact_first_name',
            'business.contact_last_name',
          ],
        },
        delivery_address: {
          required: true,
          type: 'dropdown search',
          url: '/main/locations/',
          // This says: require all 'related_params' in order to display values
          require_params: true,
          // this says: pass in the current buyer field (above field) as "buyer_id" into the dropdown search
          related_params: {
            buyer: 'buyer_id',
          },
          params: {
            tenant_aware: false,
          },
          title_fields: ['name'],
          description_fields: ['address_1', 'city', 'state', 'zip_code'],
          filter_field: 'name__icontains',
          // allow_add: true,
          // model: 'Location',
          // model_form: 'LocationForm',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        estimated_ship_date: {
          required: true,
          type: 'date',
          label: 'Estimated Pickup Date',
          filterDate: (date) => {
            var result = new Date()
            result.setDate(result.getDate() + 2)
            return date >= result
          },
        },
        po_number: {
          required: false,
          type: 'text',
          url: '/main/orders/',
          params: {
            tenant_aware: false,
          },
        },
      },
    },
  ],
}
