export const PressReleaseForm = {
  model: 'PressRelease',
  format: [
    {
      header: 'Add Press Release',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        title: {
          type: 'text',
          label: 'Title',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        subheader: {
          type: 'text',
          label: 'Subheader',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        content: {
          type: 'editor',
          label: 'Press Release Content',
        },
      },
    },
  ],
}
