import { zipCodeValidator } from '../components/Forms/validators'

export const TruckingLaneForm = {
  model: 'TruckingLane',
  format: [
    {
      widths: 'equal',
      fields: {
        pickup_zip_code: {
          label: 'Pickup Zip Code',
          type: 'text',
          required: true,
          validator: (input) => {
            return zipCodeValidator(input)
          },
        },
        destination_zip_code: {
          label: 'Destination Zip Code',
          type: 'text',
          required: true,
          validator: (input) => {
            return zipCodeValidator(input)
          },
        },
      },
    },
  ],
}
