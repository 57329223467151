import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import {
  Grid,
  Modal,
  Menu,
  Table,
  Message,
  Header,
  Dropdown,
  Icon,
  Popup,
} from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import SupplierOrderItemsRow from './SupplierOrderItemsRow'
import SupplierOrderDocuments from './SupplierOrderDocuments'
import PizzaTracker from '../../shared/PizzaTracker/PizzaTracker'
import BuyerOrderMap from '../../buyer/Orders/BuyerOrderMap'
import MickeyForm from '../../../components/Forms/MickeyForm'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import InventoryTable from '../Inventory/InventoryTable'
import { formatOrderNumber } from '../../../lib/utils/utils'
import { toast } from 'react-toastify'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import SupplierOrderLedgerRow from './SupplierOrderLedgerRow'
import './SupplierOrder.css'
import UploadInfo from '../../shared/UploadInfo/UploadInfo'
import env from '../../../env'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import SupplierOrderFulfillmentRow from './SupplierOrderFulfillmentRow'
import { hasPermission } from '../../../lib/utils/utils'
import { Info, Lock, PaperPlaneTilt, Plus } from 'phosphor-react'

function SupplierOrder(props) {
  //state
  const user = useSelector((state) => state.user.user)
  const [order, setOrder] = useState(false)
  const [orderItem, setOrderItem] = useState(false)
  const [reload, setReload] = useState(true)
  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false)
  const [sendingInvoiceLoading, setSendingInvoiceLoading] = useState(false)
  const [reloadPopup, setReloadPopup] = useState(false)
  const [refreshPizzaTracker, setRefreshPizzaTracker] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [selectedInvoiceMethod, setSelectedInvoiceMethod] = useState(null)
  const [pickupLocation, setPickupLocation] = useState(null)
  const [deliverdPrice, setDeliveredPrice] = useState(0)
  const [loading, setLoading] = useState(false)
  const [orderItems, setOrderItems] = useState([])
  const [showAddItemModal, setShowAddItemModal] = useState(false)
  const [activeButton, setActiveButton] = useState('Order Details')
  const [ledgerItemsTotal, setLedgerItemsTotal] = useState(0)
  const [orderLedgerItems, setOrderLedgerItems] = useState([])
  const [openUploadInformationModal, setOpenUploadInformationModal] =
    useState(false)
  const [reloadFulfillment, setReloadFulfillment] = useState(false)
  const [selectedCargoUnitInstance, setSelectedCargoUnitInstance] =
    useState(false)
  //redux state
  const allItemsShipped = false
  const { orderId } = useParams()
  const supplier = useSelector((state) => state.user.supplier)
  //hooks
  const axios = useAxios()
  const window = useWindowDimensions()

  const itemsHeaderRow = [
    'Item Number',
    'Order Date',
    'Product Details',
    'Quantity',
    'Price Per Unit',
    'Total Price',
    'Trucking',
    'Delivered Price',
    'Actions',
  ]

  const mxItemsHeaderRow = [
    'Item ID',
    'Order Date',
    'Product Details',
    'Quantity',
    'Price Per Unit',
    'Units/ Piece',
    'FOB Price',
  ]
  const supplierLedgerHeaderRow = [
    'Balance ID',
    'Payment Method',
    'Payment Date/Time',
    'Balance Status',
    'Business',
    'Type',
    'Amount',
  ]

  const displayOrderItem = (orderItem) => {
    let use = true
    if (orderItem.is_credit_line_item) {
      use = false
    }
    return use
  }

  useEffect(() => {
    let lit = 0
    orderLedgerItems?.forEach((ledgerItem) => {
      if (ledgerItem.type === 'Disbursement') {
        lit += parseFloat(ledgerItem.amount_transferred)
      }
    })
    setLedgerItemsTotal(lit)
  }, [orderLedgerItems])

  useEffect(() => {
    let tp = 0
    let dp = 0
    orderItems?.forEach((orderItem) => {
      if (displayOrderItem(orderItem)) {
        tp += orderItem.price * orderItem.quantity
        dp +=
          orderItem.price * orderItem.quantity -
          (orderItem?.discount ? orderItem?.discount : 0) +
          (orderItem?.trucking_price
            ? parseFloat(orderItem?.trucking_price)
            : 0)
      }
    })
    setTotalPrice(tp)
    setDeliveredPrice(dp)
  }, [orderItems])

  async function createOrderItem(result) {
    setShowAddItemModal(false)
    const attributeSelectionIds = result.attribute_selections.map(
      (attributeSelection) => {
        return attributeSelection.attribute_selection_id
      }
    )
    let res = await axios.post(`/main/order-items/`, {
      location_id: result.location_id,
      product_variant_id: result.id,
      attribute_selection_ids: attributeSelectionIds,
      product_id: result.product.id,
      supplier_id: props.supplier?.id,
      order_id: order?.id,
      pickup_location_id: pickupLocation?.id,
    })
    if (res?.data?.id) {
      setReload(true)
      toast.success('Added item to order!')
    }
  }

  async function sendBuyerPayment() {
    setSendingInvoiceLoading(true)
    setReloadPopup(true)
    let res = await axios.put(`/main/admin-mark-order-paid/`, {
      order_id: order?.id,
    })
    setSendingInvoiceLoading(false)
    if (res?.data?.success) {
      setReload(true)
      toast.success('Sent invoice to buyer!')
    } else {
      toast.error(
        res?.data?.error
          ? res?.data?.error
          : 'There was an error submitting this transaction. Please reach out to our support team for help.'
      )
    }
  }

  async function markBuyerPaid() {
    setSendingInvoiceLoading(true)
    setShowAddItemModal(false)
    let res = await axios.post(`/main/mark-buyer-paid/`, {
      order_id: order?.id,
    })
    setSendingInvoiceLoading(false)
    if (res?.data?.success) {
      setReload(true)
      toast.success('Marked order as paid!')
    }
  }

  async function sendBuyerInvoice() {
    setSendingInvoiceLoading(true)
    setShowAddItemModal(false)
    let res = await axios.post(`/main/send-buyer-invoice/`, {
      order_id: order?.id,
    })
    setSendingInvoiceLoading(false)
    if (res?.data?.success) {
      setReload(true)
      toast.success('Sent invoice to buyer!')
    }
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      let res = await axios.get(`/main/orders/${orderId}/`, {
        params: {
          tenant_aware: false,
          supplier_id: supplier?.id,
        },
      })
      setOrder(res?.data)
      setOrderItems(res?.data?.order_items)
      if (res?.data?.order_items?.length > 0) {
        setOrderItem(res?.data?.order_items[0])
      }
      setOrderLedgerItems(res?.data?.ledger_items)
      setLoading(false)
      setReload(false)
    }

    if (supplier && (reload || !openUploadInformationModal)) {
      loadData()
    }
  }, [reload, openUploadInformationModal, supplier])

  let breadcrumbData = [{ name: 'Orders', href: '/portal/supplier/orders' }]

  return (
    <div id="supplier-order">
      {loading && <MickeyPlaceholderLoading />}
      {!loading && (
        <>
          <PageHeader
            breadcrumb={breadcrumbData}
            icon={'file'}
            header={
              'Order ' +
              formatOrderNumber(order?.order_number) +
              (order?.so_number ? ` (SO #${order.so_number})` : '')
            }
            buttons={
              order?.mx_order
                ? [
                    {
                      color: 'primary',
                      style: {
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      },
                      icon: <Info weight="bold" />,
                      content: 'Upload Information',
                      hidden:
                        order?.so_number || !hasPermission(user, 'Update Order')
                          ? true
                          : false,
                      disabled: order?.supplier_order_status == 6,
                      popup: false,
                      onClick: () => {
                        setOpenUploadInformationModal(true)
                      },
                    },

                    order?.locked && {
                      icon: <Lock weight="bold" />,
                      color: 'primary',
                      disabled: true,
                      content: 'Order Locked',
                      style: {
                        '--primaryColor': 'grey',
                        '--secondaryButtonColor': 'grey',
                      },
                    },
                  ]
                : props.supplier?.id === order?.supplier?.id &&
                  !order?.locked && [
                    {
                      buttonClass: 'step-4',
                      popupClass: 'inventory-popup',
                      icon: <PaperPlaneTilt weight="bold" />,
                      disabled:
                        order.paid || order?.supplier_order_status === 6,
                      color: order.paid ? 'green' : 'grey',
                      hidden: !hasPermission(user, 'Create Payment'),
                      content: order.paid ? 'Paid' : 'Send To Buyer',
                      popupOffset: [0, 0],
                      reloadPopup: reloadPopup,
                      popupContent: (
                        <Grid
                          style={{ minWidth: '300px', paddingLeft: '15px' }}
                        >
                          <Grid.Row>
                            {/* <Header content={'Send Invoice to ' + order?.buyer?.business?.name} /> */}
                            <div style={{ margin: '10px auto' }}>
                              <Dropdown
                                className={'invoice-dropdown'}
                                clearable
                                placeholder={'Select Invoice Type'}
                                label={'Select Invoice Type'}
                                onChange={(e, d) => {
                                  setSelectedInvoiceMethod(d.value)
                                }}
                                options={[
                                  {
                                    key: 'send-buyer-payment',
                                    value: 'Payment Through Mickey',
                                    text: 'Payment Through Mickey',
                                  },
                                  {
                                    key: 'mark-buyer-paid',
                                    value: 'Mark Invoice Paid Externally',
                                    text: 'Mark Invoice Paid Externally',
                                  },
                                ]}
                              />
                              <Popup
                                position={'bottom right'}
                                offset={[0, 0]}
                                content={
                                  <div style={{ padding: '10px' }}>
                                    <Header
                                      as={'h4'}
                                      content={'Payment Through Mickey'}
                                    />
                                    <p>
                                      Select this option if you'd like Mickey to
                                      take the buyer through a payment checkout
                                      process, including offering terms.
                                    </p>
                                    {/* <Header as={'h4'} content={'Send Templated Invoice'} />
                                <p>Select this option if you'd like Mickey to send an invoice to the buyer via email on your behalf.</p> */}
                                    <Header
                                      as={'h4'}
                                      content={'Mark Invoice Paid Externally'}
                                    />
                                    <p>
                                      Select this option if you collected
                                      payment outside the Mickey app.
                                    </p>
                                  </div>
                                }
                                style={{ marginLeft: '5px' }}
                                on="hover"
                                pinned
                                trigger={
                                  <Icon
                                    name={'info circle'}
                                    style={{ marginLeft: '7px' }}
                                  />
                                }
                              />
                            </div>
                            <MickeyButton
                              color={'green'}
                              content={'Submit'}
                              loading={sendingInvoiceLoading}
                              onClick={() => {
                                if (
                                  selectedInvoiceMethod ===
                                  'Mark Invoice Paid Externally'
                                ) {
                                  markBuyerPaid()
                                } else if (
                                  selectedInvoiceMethod ===
                                  'Send Templated Invoice'
                                ) {
                                  sendBuyerInvoice()
                                } else if (
                                  selectedInvoiceMethod ===
                                  'Payment Through Mickey'
                                ) {
                                  sendBuyerPayment()
                                } else {
                                  toast.error(
                                    'Please select an invoice method.'
                                  )
                                }
                              }}
                              innerStyle={{
                                width: '100%',
                              }}
                              style={{
                                marginTop: '20px',
                                width: '100%',
                                cursor: 'pointer',
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      ),
                      popup: true,
                      popupPosition: 'bottom right',
                    },
                    {
                      icon: <Plus weight="bold" />,
                      color: 'orange',
                      content: 'Add Line Item',
                      disabled: order?.supplier_order_status === 6,
                      popupOffset: [0, 0],
                      hidden: !hasPermission(user, 'Update Order'),
                      reloadPopup: reloadPopup,
                      popupContent: (
                        <MickeyForm
                          style={{ marginTop: '25px', padding: '0 15px' }}
                          formName={'ExtraLineOrderItemForm'}
                          disabled={order?.locked}
                          basicSegment
                          model={'OrderItem'}
                          values={{
                            order_id: order?.id,
                            supplier_id: props.supplier?.id,
                          }}
                          handleForm={(status, data) => {
                            setReload(true)
                            setReloadPopup(true)
                          }}
                          url={'/main/extra-line-order-item/'}
                        />
                      ),
                      popup: true,
                      popupPosition: 'bottom right',
                    },
                    {
                      icon: <Plus weight="bold" />,
                      color: 'green',
                      content: 'Add Product',
                      hidden: !hasPermission(user, 'Update Order'),
                      disabled: order?.admin_order_status == 6,
                      popupOffset: [0, 0],
                      reloadPopup: reloadPopup,
                      popupContent: (
                        <InventoryTable
                          supplierOrder={true}
                          showAddToOrderButton
                          supplier={props.supplier}
                          business={props.business}
                          setReloadPopup={setReloadPopup}
                          reloadPopup={reloadPopup}
                          handleActiveLocation={setPickupLocation}
                          reload={reload}
                          productVariantsRoute={'/main/product-variants/'}
                          tableStartMessage={
                            "Start by searching for the product you'd like to add to this order."
                          }
                          onAddButton={(result) => {
                            createOrderItem(result)
                          }}
                        />
                      ),
                      popup: true,
                      popupPosition: 'bottom right',
                    },
                  ]
            }
          />
          {!order?.so_number && (
            <Message negative>
              <Message.Header>
                We're missing some information from you
              </Message.Header>
              <p>
                In order to complete this order we need some more information
                from you. Please click the Upload Information button above to
                add it.
              </p>
            </Message>
          )}
          <Menu secondary className={'supplier-order-buttons'}>
            {hasPermission(user, 'Read Material List') && (
              <Menu.Item
                content="Order Details"
                active={activeButton === 'Order Details'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Order Details')
                }}
              />
            )}
            {hasPermission(user, 'Read Fulfillment') && (
              <Menu.Item
                content="Fulfillment"
                active={activeButton === 'Fulfillment'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Fulfillment')
                }}
              />
            )}
            {hasPermission(user, 'Read Document') && (
              <Menu.Item
                content="Documents"
                active={activeButton === 'Documents'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Documents')
                }}
              />
            )}
            {hasPermission(user, 'Read Payment') && (
              <Menu.Item
                content="Ledger"
                active={activeButton === 'Ledger'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Ledger')
                }}
              />
            )}
          </Menu>
          {order &&
            order?.supplier?.id === props.supplier?.id &&
            !order?.mx_order && (
              <MickeyForm
                style={{ marginTop: '25px', padding: '0 15px' }}
                formName={'SupplierOrderForm'}
                disabled={order?.locked || !hasPermission(user, 'Update Order')}
                basicSegment
                model={'Order'}
                instanceId={order?.string_token}
                instanceIdOverwrite={order?.id}
                url={'/main/orders/'}
                // TODO: DAVE WHY DO I NEED THIS HERE FOR THE SUPPLIER ORDER FORM TO WORK?
                tenantAware={false}
              />
            )}
          {order && activeButton === 'Order Details' && (
            <MickeyTable
              headerRow={order?.mx_order ? mxItemsHeaderRow : itemsHeaderRow}
              style={{ marginTop: '5px', margin: '10px' }}
              basicSegment
              data={orderItems}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                if (displayOrderItem(data)) {
                  return (
                    <SupplierOrderItemsRow
                      canEdit={false}
                      order={order}
                      orderId={orderId}
                      orderItem={data}
                      supplier={props.supplier}
                      selectedOrderItepm={orderItem}
                      setReload={setReload}
                      setRefreshPizzaTracker={setRefreshPizzaTracker}
                      setOrderItem={(d) => {
                        let ois = [...orderItems]
                        ois[index] = { ...d }
                        setOrderItems(ois)
                      }}
                      index={index}
                      onClick={() => {
                        setOrderItem(data)
                      }}
                      key={data ? data.id : null}
                      locked={order.locked}
                    />
                  )
                }
              }}
              urlParams={{
                order_id: order?.id,
              }}
              emptyIcon={'file'}
              footerRow={
                <Table.Row>
                  <Table.HeaderCell colSpan={'6'}>
                    <b>Totals</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="1">
                    <b>{formatTotalPrice(totalPrice)}</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="1"></Table.HeaderCell>
                  {!order?.mx_order && (
                    <Table.HeaderCell colSpan="1">
                      <b>{formatTotalPrice(deliverdPrice)}</b>
                    </Table.HeaderCell>
                  )}
                </Table.Row>
              }
              emptyMessage={'Order items will populate here'}
            />
          )}
          {order && activeButton === 'Fulfillment' && (
            <MickeyTable
              style={{ margin: '10px' }}
              headerRow={[
                'Status',
                'Cargo Number',
                'Pickup Location',
                'Pickup Date',
                'Delivery Date',
                'Order Items',
                'Payment',
                'Actions',
              ]}
              basicSegment
              url={'/main/cargo-unit-instances/'}
              urlParams={{
                order_id: order?.id,
                supplier_id: supplier?.id,
                light_serializer: true,
              }}
              reload={reloadFulfillment}
              setReload={setReloadFulfillment}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrderFulfillmentRow
                    cargoUnitInstance={data}
                    selectedCargoUnitInstance={selectedCargoUnitInstance}
                    setSelectedCargoUnitInstance={setSelectedCargoUnitInstance}
                    index={index}
                    allItemsShipped={allItemsShipped}
                    setReload={setReload}
                    setCargoUnitInstance={(cui) => {
                      data = cui
                    }}
                    positive={data?.id === orderItem?.id}
                    supplier={supplier}
                    onClick={() => {
                      setOrderItem(data)
                    }}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Fulfillment will populate here'}
              footerRow={<Table.Row></Table.Row>}
            />
          )}
          {selectedCargoUnitInstance && activeButton === 'Fulfillment' && (
            <>
              {selectedCargoUnitInstance?.pickup_location?.state &&
                selectedCargoUnitInstance?.delivery_location?.state && (
                  <Header as={'h2'} style={{ padding: '10px' }}>
                    {`${selectedCargoUnitInstance?.pickup_location?.city}, ${selectedCargoUnitInstance?.pickup_location?.state} `}
                    <Icon
                      name="arrow right"
                      size="small"
                      style={{ padding: '0 1rem', fontSize: '20px' }}
                    />
                    {` ${selectedCargoUnitInstance?.delivery_location?.city}, ${selectedCargoUnitInstance?.delivery_location?.state}`}
                  </Header>
                )}
              <PizzaTracker
                supplier
                cargoUnitInstance={selectedCargoUnitInstance}
                refreshPizzaTracker={refreshPizzaTracker}
              />
              <Grid stackable style={{ margin: '20px 0' }}>
                <Grid.Column width={16}>
                  <BuyerOrderMap
                    cargoUnitInstance={selectedCargoUnitInstance}
                    supplier={props.supplier}
                  />
                </Grid.Column>
              </Grid>
            </>
          )}
          {order && activeButton === 'Documents' && (
            <SupplierOrderDocuments
              supplier={props.supplier}
              order={order}
              currentPortal={props.currentPortal}
            />
          )}
          {order && activeButton === 'Ledger' && (
            <MickeyTable
              headerRow={supplierLedgerHeaderRow}
              style={{ marginTop: '5px', margin: '10px', textAlign: 'center' }}
              basicSegment
              data={ledgerItemsTotal <= 0 ? [] : orderLedgerItems}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrderLedgerRow
                    orderId={orderId}
                    ledgerItem={data}
                    supplier={supplier}
                    setReload={setReload}
                    index={index}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'money'}
              footerRow={
                <Table.Row>
                  <Table.HeaderCell colSpan={'6'}>
                    <b>Totals</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    colSpan="1"
                    positive={ledgerItemsTotal >= 0}
                    negative={ledgerItemsTotal < 0}
                  >
                    <b>{formatTotalPrice(ledgerItemsTotal)}</b>
                  </Table.HeaderCell>
                </Table.Row>
              }
              emptyMessage={'Ledger items will populate here'}
            />
          )}
        </>
      )}

      <Modal
        onClose={() => setOpenAddDocumentModal(false)}
        onOpen={() => setOpenAddDocumentModal(true)}
        open={openAddDocumentModal}
      >
        <Modal.Header>Add Document</Modal.Header>
        <Modal.Content>
          <MickeyPlaceholder
            icon={'file'}
            message={'Document upload unavailable for this order.'}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setShowAddItemModal(false)}
        onOpen={() => setShowAddItemModal(true)}
        open={showAddItemModal}
        size={'fullscreen'}
      >
        <Modal.Header>Add Order Items</Modal.Header>
        <Modal.Content>
          <InventoryTable
            supplierOrder={true}
            showAddToOrderButton
            supplier={props.supplier}
            business={props.business}
            setReloadPopup={setReloadPopup}
            reloadPopup={reloadPopup}
            reload={reload}
            productVariantsRoute={'/main/product-variants/'}
            tableStartMessage={
              "Start by searching for the product you'd like to add to this order."
            }
            onAddButton={(result) => {
              createOrderItem(result)
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onOpen={() => setOpenUploadInformationModal(true)}
        onClose={() => setOpenUploadInformationModal(false)}
        open={openUploadInformationModal}
        style={{
          height: '70vh',
          width:
            window.width >= 1200
              ? '25vw'
              : window.width >= 768
              ? '45vw'
              : '90vw',
          overflow: 'scroll',
        }}
      >
        <UploadInfo
          order={order}
          supplier={supplier}
          setOpenUploadInformationModal={setOpenUploadInformationModal}
        />
      </Modal>
    </div>
  )
}

export default SupplierOrder
