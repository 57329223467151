import React, { useRef } from 'react'
import { Modal, Button, Grid } from 'semantic-ui-react'
import MickeyForm from '../Forms/MickeyForm'
import { toast } from 'react-toastify'
import MickeyTable from '../MickeyTable/MickeyTable'
import BoxDocumentTagRow from './BoxDocumentTagRow'

const EditESignTemplateModal = (props) => {
  const { boxItem, showModal, toggleModal } = props
  const linkRef = useRef(null)
  let displayName = boxItem.display_name
  if (displayName.includes('One Page Template')) {
    displayName = displayName.replace('One Page Template', '').trim()
  } else {
    displayName = displayName.replace('Template', '').trim()
  }
  return (
    <>
      <Modal
        onClose={() => toggleModal(false)}
        onOpen={() => toggleModal(true)}
        open={showModal}
        size={'large'}
      >
        <Modal.Header>
          <Grid.Row>
            <Grid.Column>{displayName}</Grid.Column>
          </Grid.Row>
        </Modal.Header>
        <Modal.Content>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <MickeyForm
                  formName={'BoxItemESignTemplateForm'}
                  model={'BoxItem'}
                  tenantAware={false}
                  instanceId={boxItem?.id}
                  url={'/main/box-items/'}
                  style={{ border: 'none', boxShadow: 'none' }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h4>Email Prefilled Tags</h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MickeyTable
                  headerRow={['Document Tag ID', 'Description']}
                  basicSegment
                  unselectable
                  data={boxItem.e_sign_email_prefilled_tags}
                  renderBodyRow={(data) => {
                    return (
                      <BoxDocumentTagRow
                        documentTag={data}
                        hideDataType={true}
                      />
                    )
                  }}
                  emptyMessage={'No Prefill Tags'}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <h4>Template Prefilled Tags</h4>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MickeyTable
                  headerRow={['Document Tag ID', 'Description', 'Data Type']}
                  basicSegment
                  unselectable
                  data={boxItem.prefilled_tags}
                  renderBodyRow={(data) => {
                    return <BoxDocumentTagRow documentTag={data} />
                  }}
                  emptyMessage={'No Prefill Tags'}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={1} style={{ marginRight: '20px' }}>
                <Button onClick={() => toggleModal(false)}>Close</Button>
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  onClick={() => {
                    fetch(boxItem.document.file)
                      .then((resp) => resp.blob())
                      .then((blob) => {
                        const url = window.URL.createObjectURL(blob)
                        const link = linkRef.current
                        link.href = url
                        link.download = boxItem.display_name
                        link.click()
                        window.URL.revokeObjectURL(url)
                        toast.success('Document Downloaded')
                      })
                      .catch((e) => console.error(e))
                  }}
                >
                  Download
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
      <a style={{ display: 'none' }} ref={linkRef} />
    </>
  )
}

export default EditESignTemplateModal
