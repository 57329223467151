import React from 'react'
import { Table } from 'semantic-ui-react'
import { formatTotalPrice } from '../../lib/utils/utils'
import { formatAddress } from '../../helpers/helpers'
import { hasPricing } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import OrderItemDetailTwo from '../OrderItemDetail/OrderItemDetailTwo'
import './PriceHistoryTable.css'
import ProductIcon from '../ProductIcon/ProductIcon'

const PriceHistoryRow = (props) => {
  const { priceHistory, hideBusiness } = props
  const user = useSelector((state) => state.user.user)
  const primaryPricing = hasPricing(user, 'primary')
  return (
    <>
      <Table.Row selectable>
        <Table.Cell width={3}>
          <div
            class="product-name"
            style={{
              '--product-status': priceHistory?.live ? '#4daf7c' : 'red',
            }}
          >
            <div class="ring-container">
              <div class="ringring"></div>
              <div class="circle"></div>
            </div>
            {priceHistory.product_variant && (
              <OrderItemDetailTwo
                productVariant={priceHistory?.product_variant}
              />
            )}
            {!priceHistory.product_variant && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ProductIcon
                  margin={'5px'}
                  productName={priceHistory.product?.material?.name}
                />
                <span style={{ fontWeight: 'bold' }}>
                  {priceHistory.product?.material?.name}(
                  {priceHistory.product?.commodity_form?.name})
                </span>
              </div>
            )}
          </div>
        </Table.Cell>
        <Table.Cell width={1}>
          <p>{formatAddress(priceHistory?.location)}</p>
        </Table.Cell>
        {primaryPricing && (
          <>
            <Table.Cell width={1}>
              <p>{formatTotalPrice(priceHistory?.price)}</p>
            </Table.Cell>
            <Table.Cell width={1}>
              <p>{formatTotalPrice(priceHistory?.secondary_price)}</p>
            </Table.Cell>
          </>
        )}
        {!primaryPricing && (
          <Table.Cell width={1}>
            <p>{formatTotalPrice(priceHistory?.secondary_price)}</p>
          </Table.Cell>
        )}
        <Table.Cell width={1}>
          <p>{priceHistory?.unit?.plural_name}</p>
        </Table.Cell>
        {!hideBusiness && (
          <Table.Cell width={1}>
            <p>{priceHistory?.business?.name}</p>
          </Table.Cell>
        )}
        <Table.Cell width={1}>
          <p>{priceHistory?.created_at}</p>
        </Table.Cell>
        <Table.Cell width={1}>
          <p>
            {priceHistory?.user?.first_name} {priceHistory?.user?.last_name} |{' '}
            {priceHistory?.user?.email}
          </p>
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default PriceHistoryRow
