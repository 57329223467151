import { useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import env from '../../../env'

function BreadCrumb(props) {
  const navigate = useNavigate()

  return (
    <Breadcrumb>
      {props.breadcrumb?.map((breadcrumb, index) => {
        return (
          <span key={index}>
            <Breadcrumb.Section
              link
              style={{
                color: env.REACT_APP_PRIMARY_COLOR,
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(breadcrumb.href)
              }}
            >
              {breadcrumb.name}
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right chevron" />
          </span>
        )
      })}
      <Breadcrumb.Section
        active
        style={{
          color: env.REACT_APP_PRIMARY_COLOR,
        }}
      >
        {props.header}
      </Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default BreadCrumb
