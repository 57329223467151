import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useAxios from '../../../lib/auth/useAxios'
const AdminEventsRow = (props) => {
  const axios = useAxios()
  const [openEditEventModal, setOpenEditEventModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const handleClose = () => {
    setOpenEditEventModal(false)
    props.setReload(true)
  }

  const deleteEvent = async () => {
    await axios.delete(`/main/events/${props.events.id}/`)
    props.setReload(true)
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell width={2}>{props?.events?.event_date}</Table.Cell>
        <Table.Cell width={2}>{props?.events?.name}</Table.Cell>
        <Table.Cell width={2}>{props?.events?.event_link}</Table.Cell>
        <Table.Cell>{props?.events?.description}</Table.Cell>
        <Table.Cell width={2}>
          <Button
            color="grey"
            icon="edit"
            onClick={() => setOpenEditEventModal(true)}
          />
          <Button
            color="red"
            icon="x"
            onClick={() => setConfirmationModal(true)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => handleClose()}
        onOpen={() => setOpenEditEventModal(true)}
        open={openEditEventModal}
      >
        <Modal.Header>Edit Event</Modal.Header>
        <Modal.Content>
          <MickeyForm
            instanceId={props.events.id}
            formName={'AddEventForm'}
            tenantAware={false}
            model={'Event'}
            buttonFloatedRight={true}
            successMessage={'Event Added!'}
            failureMessage={'Error adding event.'}
            url={'/main/events/'}
          />
        </Modal.Content>
      </Modal>
      <ConfirmationModal
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        yesFunction={deleteEvent}
        noFunction={() => setConfirmationModal(false)}
        archiveItem={'this event'}
      />
    </>
  )
}

export default AdminEventsRow
