import React, { useEffect } from 'react'
import CustomLumberFilter from './Lumber/CustomLumberFilter'
import useAxios from '../../../../../../lib/auth/useAxios'
import { Loader } from 'semantic-ui-react'

const CustomFilter = (props) => {
  const {
    commodityForm,
    attribute,
    commodity,
    handleFilterSelection,
    handleRemoveFilterSelection,
    setCustomFilterSelections,
    customFilterSelections,
    setCustomFilterValues,
    setLoadingCustomAttributes,
    loadingCustomAttributes,
  } = props
  const axios = useAxios()

  const getAttributeSelectionValues = async () => {
    setLoadingCustomAttributes(true)
    let res = await axios.get(`/main/product-filter/`, {
      params: {
        custom: attribute?.custom,
        custom_filter_selections: customFilterSelections,
        attribute_name: attribute?.name,
        custom_commodity_form: commodityForm,
      },
    })
    if (res?.data?.custom_filter_values) {
      handleFilterSelection('custom', res?.data?.custom_filter_values)
      setCustomFilterValues(res?.data?.custom_filter_values)
      setLoadingCustomAttributes(false)
    }
  }

  useEffect(() => {
    getAttributeSelectionValues()
  }, [customFilterSelections])

  const updateCustomSelections = (customSelection) => {
    const existingCustomSelectionCommodityForm = customFilterSelections.filter(
      (customFilterSelection) =>
        customFilterSelection.commodityForm === customSelection.commodityForm
    )
    if (existingCustomSelectionCommodityForm.length > 0) {
      const existingCustomAttribute =
        existingCustomSelectionCommodityForm[0]?.values.filter(
          (customFilterSelection) =>
            customFilterSelection.attributeName ===
            customSelection.attributeName
        )
      if (existingCustomAttribute.length > 0) {
        let customFilterSelectionsCopy = [...customFilterSelections]
        let existingCommodityFormIndex = customFilterSelectionsCopy.indexOf(
          existingCustomSelectionCommodityForm[0]
        )
        let existingCustomAttributeIndex = customFilterSelectionsCopy[
          existingCommodityFormIndex
        ].values.indexOf(existingCustomAttribute[0])
        if (
          !customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.customFilterValues?.includes(customSelection?.value)
        ) {
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.customFilterValues?.push(customSelection.value)
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.filterSelectionDisplay?.push(customSelection.filterValue)
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.customFilterValues?.sort(function (a, b) {
            return a - b
          })
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.filterSelectionDisplay?.sort(function (a, b) {
            return a.order - b.order
          })
          setCustomFilterSelections(customFilterSelectionsCopy)
        } else {
          let customValueIndex = customFilterSelectionsCopy[
            existingCommodityFormIndex
          ]?.values[existingCustomAttributeIndex]?.customFilterValues?.indexOf(
            customSelection.value
          )
          let customDisplay = customFilterSelectionsCopy[
            existingCommodityFormIndex
          ]?.values[existingCustomAttributeIndex]?.filterSelectionDisplay?.find(
            (display) => display.customValueInput === customSelection.value
          )
          let customDisplayIndex =
            customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
              existingCustomAttributeIndex
            ]?.filterSelectionDisplay?.indexOf(customDisplay)
          handleRemoveFilterSelection(true, customSelection)
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.customFilterValues?.splice(customValueIndex, 1)
          customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
            existingCustomAttributeIndex
          ]?.filterSelectionDisplay?.splice(customDisplayIndex, 1)
          if (
            customFilterSelectionsCopy[existingCommodityFormIndex]?.values[
              existingCustomAttributeIndex
            ]?.customFilterValues?.length < 1
          ) {
            customFilterSelectionsCopy[
              existingCommodityFormIndex
            ]?.values?.splice(existingCustomAttributeIndex)
            if (
              customFilterSelectionsCopy[existingCommodityFormIndex]?.values
                ?.length < 1
            ) {
              customFilterSelectionsCopy.splice(existingCommodityFormIndex)
            }
          }
          setCustomFilterSelections(customFilterSelectionsCopy)
        }
      }
    } else {
      let customFilterSelection = {
        commodityForm: customSelection.commodityForm,
        commodity: customSelection.commodity,
        values: [
          {
            attributeName: customSelection.attributeName,
            filterSelectionDisplay: [customSelection.filterValue],
            customFilterValues: [customSelection.value],
          },
        ],
      }
      setCustomFilterSelections((customFilterSelections) => [
        ...customFilterSelections,
        customFilterSelection,
      ])
    }
  }

  const checkFilterSelection = (commodityForm, attributeName, filterValue) => {
    const checkCommodityForm = customFilterSelections?.filter(
      (customSelection) => customSelection.commodityForm === commodityForm
    )
    const checkAttribute = checkCommodityForm[0]?.values?.filter(
      (commodityFormCustomAttribute) =>
        commodityFormCustomAttribute.attributeName === attributeName
    )
    if (
      checkAttribute &&
      checkAttribute[0]?.customFilterValues.includes(filterValue)
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {loadingCustomAttributes && (
        <div
          style={{
            minHeight: '135px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Loader
            style={{
              position: 'relative',
              left: 0,
            }}
            active
          />
        </div>
      )}
      {(commodityForm?.name === 'Lumber' ||
        commodity?.name === 'Softwood' ||
        !commodity) &&
        !loadingCustomAttributes && (
          <CustomLumberFilter
            attribute={attribute}
            handleFilterSelection={handleFilterSelection}
            commodityForm={commodityForm}
            commodity={commodity}
            handleRemoveFilterSelection={handleRemoveFilterSelection}
            updateCustomSelections={updateCustomSelections}
            checkFilterSelection={checkFilterSelection}
            setCustomFilterSelections={setCustomFilterSelections}
            customFilterSelections={customFilterSelections}
          />
        )}
    </>
  )
}

export default CustomFilter
