import React, { useState, useRef } from 'react'
import {
  Table,
  Input,
  Dropdown,
  Popup,
  Header,
  Button,
  Icon,
} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import _ from 'underscore'
import useAxios from '../../lib/auth/useAxios'
import moment from 'moment'
import { X } from 'phosphor-react'
import { CaretUp, CaretDown } from 'phosphor-react'
import './MickeyTableHeader.css'
import env from '../../env'
import { format_phone_number } from '../../lib/utils/utils'

// props
//
// headerRow
//   required list of column headers
//   example - ["Product", "Live", "Side", "Price", "Set At", "Set By User"]
//
// headerSearch
//   optional list of objects representing header column search. if headerSearch includes an object with header = an item in headerRow, the table header will be searchable.
//   keys include:
//     header
//       required
//       the header from headerRow prop to apply search to
//     type
//       required
//       descrption - the type of search input
//       allowed values - "search", "dropdown", "date", "date_range", "number_range"
//     input_type
//       required if type = "search"
//       description - defines whether the search input allows text or numbers
//       allowed values - "text", "number
//     options
//       required if type = "dropdown" and options_url is null
//       description - hard coded options for a type = "dropdown" search field
//     options_url
//        required if type = "dropdown" and options is null
//        description - api endpoint to fetch dynamic options for a type = "dropdown"
//     options_titleFields
//         description - the field to map from api results to dropdown's texts to display if using options_url to fetch dynamic options for a type = "dropdown"
//     options_valueFields
//         required if type = "dropdown" and options_url is null and need selected dropdown data other than id
//         description - the field to map from api results to dropdown's values other then id for backend filter if using options_url to fetch dynamic options for a type = "dropdown"
//
//     options_params
//         description - URL params to send to the options_url when fetching dynamic options for a type = "dropdown"
//     search_param
//         required if type one of "search", "dropdown", "date"
//         description - field set to MickeyTable urlParams for the api view to filter on
//     search_param_start
//         required if type one of "date_range", "number_range"
//         description = start range set to MickeyTable urlParams for the api view to filter on
//     search_param_end
//         required if type one of "date_range", "number_range"
//         description = end range set to MickeyTable urlParams for the api view to filter on
//
//   headerSearch examples:
//
//   type "search" text input example
//     headerSearch = [{
//       header: "Product",
//       type: "search",
//       input_type: "text",
//       search_param: "product_name_icontains"
//     }]
//
//     MickeyTable urlParams set to product_name_icontains: "header input value"
//
//     django view example text search
//
//     def get_queryset(self):
//       product_name_icontains = self.request.query_params.get("product_name_icontains")
//       if product_name_icontains:
//         queryset = queryset.filter(product__product_name__icontains=product_name_icontains)
//
//       return queryset
//
//
//   type "search" number input example
//     headerSearch = [{
//       header: "Exchange Order Price",
//       type: "search",
//       input_type: "number",
//       search_param: "price"
//     }]
//
//   type "dropdown" hardcoded options example
//     headerSearch = [{
//        header: "Side",
//        type: "dropdown",
//        options: [
//            {
//              text: "Bid",
//              value: "bid"
//            },
//            {
//              text: "Ask",
//              value: "ask"
//            }
//       ],
//        search_param: "side"
//      }]
//
//     MickeyTable urlParams set to side: "ask" || "bid"
//
//   type "dropdown" dyanamic options example
//     headerSearch = [{
//        header: "Side",
//        type: "dropdown",
//        options_url: "custom-users/",
//        options_titleFields: ["email"],
//        options_valueFields: ["email"],
//        options_params: {business_id: 1},
//        search_param: "user_id"
//      }]
//
//      MickeyTable urlParams set to user_id: 1
//
//   type "number_range" example
//     headerSearch = [{
//        header: "Price",
//        type: "number_range",
//        search_param_start: "price_start",
//        search_param_end: "price_end"
//      }]
//
//     MickeyTable urlParams set to price_start: 1, price_end: 2
//
//     django view example for number range search
//
//     def get_queryset(self):
//       price_start = self.request.query_params.get("price_start")
//       price_end = self.request.query_params.get("price_end")
//       if price_start:
//         queryset = queryset.filter(price__gte=price_start)
//
//       if price_end:
//           queryset = queryset.filter(price__lte=price_start)
//
//       return queryset
//
//   type "date_range" example
//     headerSearch = [{
//        header: "Set At",
//        type: "date_range",
//        search_param_start: "created_at_start",
//        search_param_end: "created_at_end"
//      }]
//
// headerSort
//   optional list of objects representing header column sorting. if headerSort includes an object with header = an item in headerRow, the table header will be sortable.
//   keys include:
//     header
//       required
//       the header from headerRow prop to apply sorting too
//     sort_by
//       required
//       the django model field to sort on. the API view must be written to support sorting.
//     hide_sort_by
//        description - hide sort_by if set to true.
//
//     django view example
//
//     def get_queryset(self):
//       sort_by = self.request.query_params.get("sort_by")
//       sort_order = self.request.query_params.get("sort_order")
//       if sort_by and sort_order:
//         if sort_order == "desc":
//           queryset = queryset.order_by(f"-{sort_by}")
//         elif sort_order == "asc":
//           queryset = queryset.order_by(sort_by)
//
//       return queryset
//
//   headerSort example:
//
//   headerSort = [{header: "Product", sort_by: "product__product_name", hide_sort_by: true}]

function MickeyTableHeader(props) {
  const { headerRow, headerSearch, headerSort, setUrlParams, urlParams } = props
  const axios = useAxios()
  const updateTimeoutRef = useRef(null)
  const lastInputRef = useRef(null)
  const [rangeStartEnd, setRangeStartEnd] = useState({})
  const [openPopups, setOpenPopups] = useState({})

  const [headerState, setHeaderState] = useState(() => {
    if (!headerSearch && !headerSort) return null
    let sortState = headerSort
      ? headerSort.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.header]: { sort: null },
          }),
          {}
        )
      : {}
    if (!headerSearch) return sortState
    return headerSearch.reduce((acc, cur) => {
      let searchState = {
        value: null,
        sort: sortState[cur.header]?.sort ?? null,
      }
      if (cur.type === 'number_range') {
        searchState.value = { low: null, high: null }
      }
      if (cur.type === 'dropdown') {
        if (cur.options) {
          searchState.options = cur.options.map((option, i) => ({
            text: option.text,
            value: option.value,
            key: i,
          }))
        } else {
          getOptions(cur)
        }
      }
      return { ...acc, [cur.header]: searchState }
    }, {})
  })

  async function getOptions(header, search) {
    const params = {
      tenant_aware: false,
      ...(header.options_params || {}),
      ...(search ? { q: search } : {}),
    }
    const res = await axios.get(`/main/${header.options_url}`, { params })
    const data = res?.data
    let options = []
    if (data) {
      if (header.generateOptions) {
        options = header.generateOptions(data?.results)
      } else {
        options = _.reduce(
          data?.results,
          (uniqueOptions, d) => {
            function index(obj, i) {
              return obj ? obj[i] : null
            }
            let title = ''
            header.options_titleFields?.forEach((field) => {
              let val = field.split('.').reduce(index, d)
              if (val) {
                if (title.length > 0) {
                  title += ' '
                }
                title += val
              }
            })
            if (header?.options_formate_phone_number) {
              title = format_phone_number(title)
            }
            let description = ''
            if (header?.options_descriptionFields?.length > 0) {
              header?.options_descriptionFields.forEach((field) => {
                let val = field.split('.').reduce(index, d)
                if (val) {
                  if (description.length > 0) {
                    description += header.descriptionFieldSeparator
                      ? header.descriptionFieldSeparator
                      : ' - '
                  }
                  description += val
                }
              })
            }
            if (!header.hideDescriptionFieldParenthesis && description) {
              description = '(' + description + ')'
            }
            let value = ''
            header?.options_valueFields?.forEach((field) => {
              let val = field.split('.').reduce(index, d)
              if (val) {
                if (value.length > 0) {
                  value += ' | '
                }
                value += val
              }
            })
            const existingOption = uniqueOptions.find(
              (option) => option.text === title
            )

            if (!existingOption && title) {
              uniqueOptions.push({
                text: title,
                value: header?.options_valueFields?.length ? value : d.id,
                key: d.id,
                content: (
                  <>
                    <b>{title}</b>
                    <br />
                    {description}
                  </>
                ),
              })
            }

            return uniqueOptions
          },
          []
        )
      }
    }
    setHeaderState((prevState) => ({
      ...prevState,
      [header.header]: {
        ...prevState[header.header],
        options: options,
      },
    }))
  }

  const updateSortHeaderState = (header) => {
    let sort = headerState[header]?.sort
    if (sort === 'asc') {
      sort = 'desc'
    } else if (sort === 'desc' || !sort) {
      sort = 'asc'
    }

    setHeaderState((prevState) => ({
      ...prevState,
      [header]: {
        ...prevState[header],
        sort,
      },
    }))

    const sortBy = headerSort.find((obj) => obj.header === header).sort_by
    updateSortUrlParams(sortBy, sort)
  }

  const updateSortUrlParams = (sort_by, sort_order) => {
    setUrlParams((prevState) => ({
      ...prevState,
      sort_by,
      sort_order,
    }))
  }

  const resetSortUrlParams = () => {
    setUrlParams((prevState) => {
      const { sort_by, sort_order, ...rest } = prevState
      return rest
    })
  }

  const getRangeValue = (start_key, end_key, type = '') => {
    let return_val = ''
    if (urlParams && (urlParams[start_key] || urlParams[end_key])) {
      return_val =
        (urlParams[start_key]
          ? `${type === 'Price' ? '$' : ''}${urlParams[start_key]}`
          : type === 'Date'
          ? `Start Date`
          : `Min`) +
        ' - ' +
        (urlParams[end_key]
          ? `${type === 'Price' ? '$' : ''}${urlParams[end_key]}`
          : type === 'Date'
          ? `End Date`
          : `Max`)
    }
    return return_val
  }

  const handleRangeFilter = (
    start_key,
    end_key,
    clear_all = true,
    add = false
  ) => {
    if (add) {
      setUrlParams((prevState) => ({
        ...prevState,
        [start_key]: rangeStartEnd[start_key],
        [end_key]: rangeStartEnd[end_key],
      }))
      setRangeStartEnd({})
    } else {
      if (rangeStartEnd[start_key] || rangeStartEnd[end_key]) {
        let range_start_end = {}
        for (const [key, value] of Object.entries(rangeStartEnd)) {
          if (![start_key, end_key].includes(key)) range_start_end[key] = value
        }
        setRangeStartEnd(range_start_end)
      }
      if ((urlParams[start_key] || urlParams[end_key]) && clear_all) {
        let url_params = {}
        for (const [key, value] of Object.entries(urlParams)) {
          if (![start_key, end_key].includes(key)) url_params[key] = value
        }
        setUrlParams(url_params)
      }
    }
  }

  const handleRangeHeaderState = (start_key, end_key, header, add) => {
    if (add) {
      setHeaderState((prevState) => ({
        ...prevState,
        [header]: {
          ...prevState[header],
          value: {
            ...prevState[header].value,
            high: rangeStartEnd[end_key],
            low: rangeStartEnd[start_key],
          },
        },
      }))
    }
    setHeaderState((prevState) => ({
      ...prevState,
      [header]: {
        ...prevState[header],
        value: {
          ...prevState[header].value,
          high: null,
          low: null,
        },
      },
    }))
  }

  return (
    <Table.Header>
      <Table.Row id="mickey-table-header">
        {headerRow?.map((header, index) => {
          let sort
          if (headerState?.[header]?.sort !== undefined) {
            const headerSortObj = headerSort.find(
              (obj) => obj.header === header
            )
            if (!headerSortObj?.hide_sort_by) {
              sort = (
                <>
                  <span
                    style={{ margin: '0px 4px', color: '#8C8C8C' }}
                    onClick={() => updateSortHeaderState(header)}
                  >
                    <CaretUp
                      size={13}
                      weight={'bold'}
                      style={{ display: 'block' }}
                    />
                    <CaretDown
                      size={13}
                      weight={'bold'}
                      style={{ display: 'block' }}
                    />
                  </span>
                </>
              )
            }
          }
          let headerJsx = (
            <>
              {header} {sort}
            </>
          )
          if (headerSearch) {
            const headerSearchItem = headerSearch.find(
              (obj) => obj.header === header
            )
            if (headerSearchItem) {
              if (headerSearchItem.type === 'search') {
                headerJsx = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {sort}
                    <Input
                      type={headerSearchItem.input_type}
                      name={header}
                      placeholder={header}
                      value={
                        headerState[header].value
                          ? headerState[header].value
                          : ''
                      }
                      onChange={(e, d) => {
                        setHeaderState((prevState) => ({
                          ...prevState,
                          [header]: {
                            ...prevState[header],
                            value: d.value,
                          },
                        }))
                        lastInputRef.current = {
                          value: d.value,
                          search_param: headerSearchItem.search_param,
                        }
                        if (updateTimeoutRef.current) {
                          clearTimeout(updateTimeoutRef.current)
                        }
                        updateTimeoutRef.current = setTimeout(() => {
                          setUrlParams((prevState) => {
                            const { value, search_param } = lastInputRef.current
                            if (value === '') {
                              const { [search_param]: _, ...newState } =
                                prevState
                              return newState
                            } else {
                              return {
                                ...prevState,
                                [search_param]: value,
                              }
                            }
                          })
                        }, 500)
                      }}
                    />
                  </div>
                )
              } else if (headerSearchItem.type === 'dropdown') {
                headerJsx = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {sort}
                    <Dropdown
                      floating
                      clearable
                      basic
                      search
                      selection
                      color={'green'}
                      placeholder={header}
                      name={'header'}
                      value={
                        urlParams[headerSearchItem?.search_param]
                          ? urlParams[headerSearchItem?.search_param]
                          : ''
                      }
                      options={
                        headerState[header].options
                          ? headerState[header].options
                          : []
                      }
                      onSearchChange={(e, d) => {
                        if (headerSearchItem?.options_url) {
                          getOptions(headerSearchItem, e.target.value)
                        }
                      }}
                      onChange={(e, d) => {
                        setUrlParams((prevState) => ({
                          ...prevState,
                          [headerSearchItem.search_param]: d.value,
                        }))
                      }}
                    />
                  </div>
                )
              } else if (headerSearchItem.type === 'date') {
                headerJsx = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {sort}
                    <SemanticDatepicker
                      clearable={true}
                      autoComplete="nope"
                      format={'MMM D, YYYY'}
                      placeholder={header}
                      onChange={(e, d) => {
                        if (d.value) {
                          setUrlParams((prevState) => ({
                            ...prevState,
                            [headerSearchItem.search_param]: moment(
                              d.value
                            ).format('YYYY-MM-DD'),
                          }))
                        } else {
                          setUrlParams((prevState) => ({
                            ...prevState,
                            [headerSearchItem.search_param]: null,
                          }))
                        }
                      }}
                    />
                  </div>
                )
              } else if (headerSearchItem.type === 'date_range') {
                headerJsx = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {sort}
                    <Popup
                      id={'filter-range-popup'}
                      on="click"
                      open={openPopups[header]}
                      onOpen={() =>
                        setOpenPopups((prevState) => ({
                          ...prevState,
                          [header]: true,
                        }))
                      }
                      onClose={() =>
                        setOpenPopups((prevState) => ({
                          ...prevState,
                          [header]: false,
                        }))
                      }
                      wide
                      position={'bottom'}
                      popperDependencies={[!!props.reloadPopup]}
                      trigger={
                        <Input
                          type={'text'}
                          name={header}
                          placeholder={`${header} Range`}
                          value={getRangeValue(
                            headerSearchItem.search_param_start,
                            headerSearchItem.search_param_end,
                            'Date'
                          )}
                          autoComplete="off"
                          icon={
                            getRangeValue(
                              headerSearchItem.search_param_start,
                              headerSearchItem.search_param_end,
                              'Date'
                            ) != '' ? (
                              <div className="custom-icon">
                                <X
                                  size={18}
                                  weight="bold"
                                  onClick={() =>
                                    handleRangeFilter(
                                      headerSearchItem.search_param_start,
                                      headerSearchItem.search_param_end
                                    )
                                  }
                                />
                              </div>
                            ) : null
                          }
                        />
                      }
                    >
                      <Header>{`${header} Range`}</Header>
                      <Popup.Content>
                        <div>
                          <div>
                            <SemanticDatepicker
                              clearable={true}
                              autoComplete="nope"
                              format={'MMM D, YYYY'}
                              placeholder={`${header} Start Range`}
                              onChange={(e, d) => {
                                if (d.value) {
                                  setRangeStartEnd((prevState) => ({
                                    ...prevState,
                                    [headerSearchItem.search_param_start]:
                                      moment(d.value).format('YYYY-MM-DD'),
                                  }))
                                } else {
                                  setRangeStartEnd((prevState) => ({
                                    ...prevState,
                                    [headerSearchItem.search_param_start]: null,
                                  }))
                                }
                              }}
                            />
                          </div>
                          <div>-</div>
                          <div>
                            <SemanticDatepicker
                              clearable={true}
                              autoComplete="nope"
                              placeholder={`${header} End Range`}
                              format={'MMM D, YYYY'}
                              onChange={(e, d) => {
                                if (d.value) {
                                  setRangeStartEnd((prevState) => ({
                                    ...prevState,
                                    [headerSearchItem.search_param_end]: moment(
                                      d.value
                                    ).format('YYYY-MM-DD'),
                                  }))
                                } else {
                                  setRangeStartEnd((prevState) => ({
                                    ...prevState,
                                    [headerSearchItem.search_param_end]: null,
                                  }))
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="range-filter-buttons">
                          <div
                            onClick={() => {
                              handleRangeFilter(
                                headerSearchItem.search_param_start,
                                headerSearchItem.search_param_end,
                                false
                              )
                              setOpenPopups((prevState) => ({
                                ...prevState,
                                [header]: false,
                              }))
                            }}
                          >
                            Cancel
                          </div>
                          <Button
                            color={'primary'}
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              '--secondaryButtonColor':
                                env.REACT_APP_SECONDARY_BUTTON_COLOR,
                            }}
                            onClick={() => {
                              handleRangeFilter(
                                headerSearchItem.search_param_start,
                                headerSearchItem.search_param_end,
                                false,
                                true
                              )
                              setOpenPopups((prevState) => ({
                                ...prevState,
                                [header]: false,
                              }))
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      </Popup.Content>
                    </Popup>
                  </div>
                )
              } else if (headerSearchItem.type === 'number_range') {
                headerJsx = (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {sort}
                    <Popup
                      id={'filter-range-popup'}
                      on="click"
                      open={openPopups[header]}
                      onOpen={() =>
                        setOpenPopups((prevState) => ({
                          ...prevState,
                          [header]: true,
                        }))
                      }
                      onClose={() =>
                        setOpenPopups((prevState) => ({
                          ...prevState,
                          [header]: false,
                        }))
                      }
                      wide
                      position={'bottom'}
                      popperDependencies={[!!props.reloadPopup]}
                      trigger={
                        <Input
                          type={'text'}
                          name={header}
                          placeholder={`${header} Range`}
                          value={getRangeValue(
                            headerSearchItem.search_param_start,
                            headerSearchItem.search_param_end,
                            header
                          )}
                          autoComplete="off"
                          icon={
                            getRangeValue(
                              headerSearchItem.search_param_start,
                              headerSearchItem.search_param_end,
                              header
                            ) != '' ? (
                              <div className="custom-icon">
                                <X
                                  size={18}
                                  weight="bold"
                                  onClick={() => {
                                    handleRangeFilter(
                                      headerSearchItem.search_param_start,
                                      headerSearchItem.search_param_end
                                    )
                                    handleRangeHeaderState(
                                      headerSearchItem.search_param_start,
                                      headerSearchItem.search_param_end,
                                      header,
                                      false
                                    )
                                  }}
                                />
                              </div>
                            ) : null
                          }
                        />
                      }
                    >
                      <Header>{`${header} Range`}</Header>
                      <Popup.Content>
                        <div>
                          <div>
                            <Input
                              type={'number'}
                              name={header}
                              placeholder={`${header} Start Range`}
                              onChange={(e, d) => {
                                setHeaderState((prevState) => ({
                                  ...prevState,
                                  [header]: {
                                    ...prevState[header],
                                    value: {
                                      ...prevState[header].value,
                                      low: d.value,
                                    },
                                  },
                                }))
                                setRangeStartEnd((prevState) => ({
                                  ...prevState,
                                  [headerSearchItem.search_param_start]:
                                    d.value,
                                }))
                              }}
                            />
                          </div>
                          <div>-</div>
                          <div>
                            <Input
                              type={'number'}
                              name={header}
                              placeholder={`${header} End Range`}
                              onChange={(e, d) => {
                                setHeaderState((prevState) => ({
                                  ...prevState,
                                  [header]: {
                                    ...prevState[header],
                                    value: {
                                      ...prevState[header].value,
                                      high: d.value,
                                    },
                                  },
                                }))
                                setRangeStartEnd((prevState) => ({
                                  ...prevState,
                                  [headerSearchItem.search_param_end]: d.value,
                                }))
                              }}
                            />
                          </div>
                        </div>
                        <div className="range-filter-buttons">
                          <div
                            onClick={() => {
                              handleRangeFilter(
                                headerSearchItem.search_param_start,
                                headerSearchItem.search_param_end,
                                false
                              )
                              setOpenPopups((prevState) => ({
                                ...prevState,
                                [header]: false,
                              }))
                            }}
                          >
                            Cancel
                          </div>
                          <Button
                            color={'primary'}
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              '--secondaryButtonColor':
                                env.REACT_APP_SECONDARY_BUTTON_COLOR,
                            }}
                            onClick={() => {
                              handleRangeFilter(
                                headerSearchItem.search_param_start,
                                headerSearchItem.search_param_end,
                                false,
                                true
                              )
                              handleRangeHeaderState(
                                headerSearchItem.search_param_start,
                                headerSearchItem.search_param_end,
                                header,
                                true
                              )
                              setOpenPopups((prevState) => ({
                                ...prevState,
                                [header]: false,
                              }))
                            }}
                          >
                            Apply
                          </Button>
                        </div>
                      </Popup.Content>
                    </Popup>
                  </div>
                )
              }
            }
          }
          return (
            <Table.HeaderCell key={index} verticalAlign={'middle'}>
              {headerJsx}
            </Table.HeaderCell>
          )
        })}
      </Table.Row>
    </Table.Header>
  )
}

export default MickeyTableHeader
