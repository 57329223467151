import React, { useState, useEffect, useMemo } from 'react'
import {
  Header,
  Segment,
  Image,
  Grid,
  Accordion,
  Progress,
  Button,
  Card,
  Divider,
  Icon,
  Modal,
  Form,
  Radio,
  Tab,
} from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import logo from '../../../img/mickey-tree-logo-words.png'
import useAxios from '../../../lib/auth/useAxios'
import BalanceCheckout from '../../buyer/PlaceOrder/BalanceCheckout'
import _ from 'underscore'
import { toast } from 'react-toastify'
import Cart from '../Cart/Cart'
import './Payment.css'
import {
  Bank,
  CreditCard,
  ArrowRight,
  Swap,
  Check,
  CaretRight,
} from 'phosphor-react'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { formatTotalPrice } from '../../../lib/utils/utils'
import PaymentMap from './PaymentMap'

function Payment() {
  // State
  const [order, setOrder] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [creditApplicationUrl, setCreditApplicationUrl] = useState(false)
  const [totalPrice, setTotalPrice] = useState()
  const [creditLimit, setCreditLimit] = useState(false)
  const [currentTerms, setCurrentTerms] = useState('ACH')
  const [creditAmount, setCreditAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [successUrl, setSuccessUrl] = useState(false)
  const [balanceLoading, setBalanceLoading] = useState(false)
  const [accordionIndex, setAccordionIndex] = useState(2)
  const [selectedPayment, setSelectedPayment] = useState('ACH Debit')
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [activeMobileSummary, setActiveMobileSummary] = useState(false)
  const [lineItems, setLineItems] = useState(null)
  const [allDelivered, setAllDelivered] = useState(null)
  // Redux state
  const user = useSelector((state) => state.user.user)
  // Hooks
  const axios = useAxios()
  const wd = useWindowDimensions()
  let { stringToken } = useParams()
  // Vars
  const filteredOrderItem = null
  const terms = useMemo(() => {
    return {
      'Net 60': 0.04,
      'Net 45': 0.03,
      'Net 30': 0.02,
      'Net 15': 0.01,
      'Credit Card': 0.029,
    }
  }, [])

  useEffect(() => {
    async function updateBalanceTransaction() {
      let res = await axios.post('/main/update-balance-transaction/', {
        invoice_string_token: stringToken,
        terms: currentTerms,
      })
      if (res.status < 300 && !res?.error) {
        setBalanceLoading(false)
      } else {
        toast.error('Transaction failed to update.')
      }
    }
    if (currentTerms) {
      setBalanceLoading(true)
      let termsPrice = 0
      Object.keys(terms).forEach((key) => {
        if (currentTerms === key) {
          termsPrice = totalPrice * terms[key]
        }
      })
      if (currentTerms !== 'ACH') {
        let payment_term_list = JSON.parse(
          window.localStorage.getItem('payment_terms')
        )
        let index = payment_term_list?.findIndex(
          (object) => object.stringToken === stringToken
        )
        if (index !== -1 && payment_term_list) {
          payment_term_list[index].currentTerms = currentTerms
        } else if (payment_term_list) {
          payment_term_list.push({
            stringToken: stringToken,
            currentTerms: currentTerms,
          })
        } else {
          payment_term_list = []
        }
        window.localStorage.setItem(
          'payment_terms',
          JSON.stringify(payment_term_list)
        )
      }
      setCreditAmount(termsPrice)
      updateBalanceTransaction()
    }
  }, [currentTerms])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      let res = await axios.get(`/main/p-payment-order/`, {
        params: {
          string_token: stringToken,
        },
      })
      if (res?.data?.balance_transaction_id) {
        setInvoice(res?.data)
        setLineItems(res?.data?.line_items)
        setOrder(res?.data?.order)
        setCreditLimit(res?.data?.credit_limit)
        setCreditApplicationUrl(res?.data?.credit_application_url)
        setTotalPrice(res?.data?.total_price)
        let payment_terms = JSON.parse(
          window.localStorage.getItem('payment_terms')
        )?.find((object) => object.stringToken === stringToken)
        if (payment_terms) {
          setCreditAmount(
            parseFloat(
              terms.hasOwnProperty(payment_terms.currentTerms)
                ? res?.data?.terms_fee
                : creditAmount
            )
          )
          setCurrentTerms(
            payment_terms.currentTerms
              ? payment_terms.currentTerms
              : currentTerms
          )
        } else if (res?.data?.terms_fee && res?.data?.terms_fee > 0) {
          let total_price = res?.data?.total_price - res?.data?.terms_fee
          setCreditAmount(parseFloat(res?.data?.terms_fee))
          Object.keys(terms)?.forEach((key) => {
            let term_price = total_price * terms[key]
            let diff = term_price.toFixed(2) - parseFloat(res?.data?.terms_fee)
            setCurrentTerms(diff === 0 ? key : currentTerms)
          })
        } else {
          setCreditAmount(parseFloat(res?.data?.terms_fee))
        }
        setLoading(false)
      } else {
        toast.error('Error: Cannot find payment.')
      }
    }
    fetchData()
  }, [successUrl, creditLimit])

  function renderBalanceCheckout() {
    if (invoice?.balance_checkout_token) {
      window.balanceCheckout.render(
        invoice?.balance_checkout_token,
        '#balance-checkout'
      )
    }
  }

  const updatePaymentSelection = (selection) => {
    setSelectedPayment(selection)
    setCurrentTerms(selection)
  }

  const shipDate = new Date(order?.estimated_ship_date).toDateString()
  const createdAtDate = new Date(order?.created_at).toDateString()

  useEffect(() => {
    let allDelivered = true
    lineItems?.forEach((lineItem) => {
      lineItem?.lineItems?.forEach((li) => {
        if (li?.cci && !li?.delivered) {
          allDelivered = false
        }
      })
    })
    setAllDelivered(allDelivered)
  }, [lineItems])

  const tabPanes = (lineItems) => {
    let dryVanCount = 0
    let flatBedCount = 0

    let panes = _.map(lineItems, (lineItem, index) => {
      if (
        lineItem?.cargo_unit_instance?.cargo_unit?.singular_name ===
        "Dry Van 53'"
      ) {
        dryVanCount = dryVanCount + 1
      }
      if (
        lineItem?.cargo_unit_instance?.cargo_unit?.singular_name ===
        "Flat Bed 53'"
      ) {
        flatBedCount = flatBedCount + 1
      }
      let menuItemName = `${
        lineItem?.cargo_unit_instance?.cargo_unit?.singular_name
      } ${
        lineItem?.cargo_unit_instance?.cargo_unit?.singular_name ===
        "Dry Van 53'"
          ? dryVanCount
          : flatBedCount
      }`
      return {
        menuItem: menuItemName,
        render: () => (
          <Tab.Pane>
            <PaymentMap
              deliveryLocation={
                lineItem?.cargo_unit_instance?.delivery_location
              }
            />
          </Tab.Pane>
        ),
      }
    })

    return panes
  }

  return (
    <>
      <Grid id="payment-page" stackable>
        <Grid.Column
          width={9}
          style={{
            textAlign: 'center',
            backgroundColor: '#fff',
            zIndex: '1',
            minHeight: '75vh',
          }}
        >
          <Header className="header-wrapper" image>
            {order?.mx_order && (
              <Image
                src={env.REACT_APP_LOGO}
                style={{ width: '300px', marginTop: '30px' }}
              />
            )}
            {!order?.mx_order && <Image src={logo} />}
            <div className="progress-wrapper">
              <Progress
                style={{
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                className={'pizza-tracker-progress'}
                percent={100}
                size="tiny"
                color="primary"
              >
                <div
                  className="progress-node"
                  style={{
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                >
                  <label className="node-label">
                    Order Created <br />
                    <label style={{ fontSize: '10px', fontWeight: 'lighter' }}>
                      {createdAtDate}
                    </label>
                  </label>
                </div>
              </Progress>
              <Progress
                style={{
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                className={'pizza-tracker-progress'}
                percent={100}
                size="tiny"
                color="primary"
              >
                <div className="progress-node ">
                  <label className="node-label">
                    Shipped <br />
                    <label style={{ fontSize: '10px', fontWeight: 'lighter' }}>
                      {shipDate}
                    </label>
                  </label>
                </div>
              </Progress>
              <Progress
                style={{
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                className={'pizza-tracker-progress'}
                percent={100}
                size="tiny"
                color={allDelivered ? 'primary' : 'grey'}
              >
                <div className="progress-node">
                  <label
                    className={
                      invoice?.paid ? 'node-label' : 'node-label unpaid'
                    }
                    style={{
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Payment
                  </label>
                </div>
                {!allDelivered && (
                  <div className="progress-node grey">
                    <label className="node-label">Delivered</label>
                  </div>
                )}
                {allDelivered && (
                  <div className="progress-node delivered">
                    <label className="node-label">Delivered</label>
                  </div>
                )}
              </Progress>
            </div>
          </Header>
          <Segment
            className={'payment-left-segment'}
            basic
            loading={loading}
            style={{ textAlign: 'left', marginTop: '4rem' }}
          >
            {!invoice?.paid && (
              <>
                <Divider />
                <div>
                  <Accordion
                    className={'payment-accordion'}
                    style={{ width: '100%' }}
                  >
                    {/* MOBILE SUMMARY */}
                    <Accordion.Title
                      className="mobile-summary-title"
                      active={accordionIndex === 0}
                      index={0}
                      onClick={() => setAccordionIndex(0)}
                    >
                      <div className="order-summary-wrapper">
                        Order #{order?.order_number} Summary -
                        {formatTotalPrice(
                          creditAmount &&
                            creditAmount > 0 &&
                            !filteredOrderItem?.price
                            ? order?.buyer_total + creditAmount
                            : filteredOrderItem?.price
                            ? order?.buyer_total +
                              creditAmount -
                              filteredOrderItem?.price
                            : order?.buyer_total
                        )}
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              accordionIndex === 0 ? 'rotate(90deg)' : '',
                          }}
                        />
                      </div>
                    </Accordion.Title>
                    <Accordion.Content
                      className="mobile-summary-content"
                      active={accordionIndex === 0}
                    >
                      {order && lineItems && invoice && (
                        <Cart
                          order={order}
                          invoice={invoice}
                          lineItems={lineItems}
                          totalPrice={totalPrice}
                          setTotalPrice={setTotalPrice}
                          creditAmount={creditAmount}
                          balanceLoading={balanceLoading}
                          renderBalanceCheckout={renderBalanceCheckout}
                        />
                      )}
                    </Accordion.Content>
                    <Divider className="mobile-summary-divider" />

                    {/* BILLING ADDRESS */}
                    <Accordion.Title
                      active={accordionIndex === 1}
                      index={2}
                      onClick={() => setAccordionIndex(1)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h3">
                          Billing Address
                          {accordionIndex === 1 && (
                            <Header.Subheader as={'p'}>
                              This is the current billing address.
                            </Header.Subheader>
                          )}
                        </Header>
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              accordionIndex === 1 ? 'rotate(90deg)' : '',
                          }}
                        />
                      </div>
                    </Accordion.Title>
                    <Accordion.Content active={accordionIndex === 1}>
                      {invoice?.buyer ? (
                        <>
                          {invoice?.buyer?.business?.contact_first_name}{' '}
                          {invoice?.buyer?.business?.contact_last_name} <br />
                          {
                            invoice?.buyer?.business?.billing_address_line_1
                          }{' '}
                          <br />
                          {invoice?.buyer?.business?.billing_address_line_2 ? (
                            <>
                              {' '}
                              {
                                invoice?.buyer?.business?.billing_address_line_2
                              }{' '}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {invoice?.buyer?.business?.billing_address_city +
                            ', '}
                          {invoice?.buyer?.business?.billing_address_state +
                            ' '}
                          {invoice?.buyer?.business?.zip_code} <br />
                          {
                            invoice?.buyer?.business
                              ?.billing_address_country_code
                          }
                        </>
                      ) : (
                        <></>
                      )}
                    </Accordion.Content>
                    <Divider />

                    {/* PAYMENT METHOD */}
                    <Accordion.Title
                      active={accordionIndex === 2}
                      index={3}
                      onClick={() => setAccordionIndex(2)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h3">
                          Payment Method
                          {accordionIndex === 2 && (
                            <Header.Subheader as={'p'}>
                              Select a payment option below.
                            </Header.Subheader>
                          )}
                        </Header>
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              accordionIndex === 2 ? 'rotate(90deg)' : '',
                          }}
                        />
                      </div>
                    </Accordion.Title>
                    <Accordion.Content
                      className="payment-terms terms-wrapper"
                      active={accordionIndex === 2}
                    >
                      {!successUrl && !invoice?.paid && (
                        <>
                          <Card
                            onClick={() => updatePaymentSelection('ACH Debit')}
                            className={
                              selectedPayment === 'ACH Debit'
                                ? 'payment-method selected'
                                : 'payment-method'
                            }
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            }}
                          >
                            <Icon
                              name="question circle"
                              color="black"
                              onClick={() => setOpenInfoModal(true)}
                            />

                            <div>
                              <Bank
                                size={25}
                                color={
                                  selectedPayment === 'ACH Debit'
                                    ? env.REACT_APP_PRIMARY_COLOR
                                    : '#2b2727'
                                }
                              />
                              <Header
                                as={'p'}
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                              >
                                Bank Account
                              </Header>
                            </div>
                          </Card>
                          <Card
                            onClick={() =>
                              updatePaymentSelection('Credit Card')
                            }
                            className={
                              selectedPayment === 'Credit Card'
                                ? 'payment-method selected'
                                : 'payment-method'
                            }
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            }}
                          >
                            <Icon
                              name="question circle"
                              color="black"
                              onClick={() => setOpenInfoModal(true)}
                            />
                            <div>
                              <CreditCard
                                size={25}
                                color={
                                  selectedPayment === 'Credit Card'
                                    ? env.REACT_APP_PRIMARY_COLOR
                                    : '#2b2727'
                                }
                              />
                              <Header
                                as={'p'}
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                              >
                                Credit Card
                              </Header>
                            </div>
                          </Card>
                          <Card
                            onClick={() => updatePaymentSelection('ACH Credit')}
                            className={
                              selectedPayment === 'ACH Credit'
                                ? 'payment-method selected'
                                : 'payment-method'
                            }
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            }}
                          >
                            <Icon
                              name="question circle"
                              color="black"
                              onClick={() => setOpenInfoModal(true)}
                            />
                            <div>
                              <ArrowRight
                                size={25}
                                color={
                                  selectedPayment === 'ACH Credit'
                                    ? env.REACT_APP_PRIMARY_COLOR
                                    : '#2b2727'
                                }
                              />
                              <Header
                                as={'p'}
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                              >
                                ACH/Wire
                              </Header>
                            </div>
                          </Card>
                          {Boolean(
                            creditLimit === 'not_qualified' || creditLimit <= 0
                          ) && (
                            <a
                              id="apply-for-terms"
                              href={creditApplicationUrl}
                              target="_blank"
                              style={{
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              }}
                              rel="noreferrer"
                            >
                              Apply for Terms
                            </a>
                          )}
                          {Boolean(creditLimit >= totalPrice) && (
                            <Card
                              onClick={() => updatePaymentSelection('Terms')}
                              className={
                                selectedPayment === 'Terms'
                                  ? 'payment-method selected'
                                  : 'payment-method'
                              }
                              style={{
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              }}
                            >
                              <Icon
                                name="question circle"
                                color="black"
                                onClick={() => setOpenInfoModal(true)}
                              />
                              <div>
                                <Swap
                                  size={25}
                                  color={
                                    selectedPayment === 'Terms'
                                      ? env.REACT_APP_PRIMARY_COLOR
                                      : '#2b2727'
                                  }
                                />
                                <Header
                                  as={'p'}
                                  style={{
                                    '--primaryColor':
                                      env.REACT_APP_PRIMARY_COLOR,
                                  }}
                                >
                                  Terms
                                </Header>
                              </div>
                            </Card>
                          )}
                        </>
                      )}
                      {Boolean(
                        creditLimit &&
                          creditLimit >= totalPrice &&
                          selectedPayment === 'Terms'
                      ) && (
                        <div id="payment-radio-wrapper">
                          <label>Select Term Option:</label>
                          <Form>
                            <Form.Field>
                              <Radio
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                                label="15 Days (1% Fee)"
                                name="Net 15"
                                value={'Prompt'}
                                checked={currentTerms === 'Net 15'}
                                onChange={() => setCurrentTerms('Net 15')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Radio
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                                label="30 Days (2% Fee)"
                                name="Net 30"
                                value={'Prompt'}
                                checked={currentTerms === 'Net 30'}
                                onChange={() => setCurrentTerms('Net 30')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Radio
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                                label="45 Days (3% Fee)"
                                name="Net 45"
                                value={'Prompt'}
                                checked={currentTerms === 'Net 45'}
                                onChange={() => setCurrentTerms('Net 45')}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Radio
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                }}
                                label="60 Days (4% Fee)"
                                name="Net 60"
                                value={'Prompt'}
                                checked={currentTerms === 'Net 60'}
                                onChange={() => setCurrentTerms('Net 60')}
                              />
                            </Form.Field>
                          </Form>
                        </div>
                      )}
                    </Accordion.Content>
                  </Accordion>
                  <div
                    style={{
                      minWidth: '400px',
                    }}
                  >
                    <BalanceCheckout
                      order={order}
                      orderId={order?.id}
                      invoice={invoice}
                      setSuccessUrl={setSuccessUrl}
                      paymentMethodNames={[currentTerms].map((term) => {
                        if (term === 'ACH Debit') {
                          return 'bank'
                        } else if (term === 'ACH Credit') {
                          return 'bank'
                        } else if (term === 'Credit Card') {
                          return 'creditCard'
                        } else {
                          return 'payWithTerms'
                        }
                      })}
                    />
                  </div>
                </div>
              </>
            )}
            {invoice?.paid && (
              <div className="paid-wrapper">
                <Accordion className="mobile-order-summary">
                  <Accordion.Title
                    active={activeMobileSummary}
                    onClick={() => setActiveMobileSummary(!activeMobileSummary)}
                  >
                    <div className="order-summary-wrapper">
                      Order #{order?.order_number} Summary -
                      {formatTotalPrice(
                        creditAmount &&
                          creditAmount > 0 &&
                          !filteredOrderItem?.price
                          ? order?.buyer_total + creditAmount
                          : filteredOrderItem?.price
                          ? order?.buyer_total +
                            creditAmount -
                            filteredOrderItem?.price
                          : order?.buyer_total
                      )}
                      <CaretRight
                        size={20}
                        style={{
                          transition: '.2s ease-in-out',
                          transform: activeMobileSummary ? 'rotate(90deg)' : '',
                        }}
                      />
                    </div>
                  </Accordion.Title>
                  <Accordion.Content
                    id="order-summary-content"
                    active={activeMobileSummary}
                  >
                    {order && lineItems && invoice && (
                      <Cart
                        order={order}
                        lineItems={lineItems}
                        invoice={invoice}
                        totalPrice={totalPrice}
                        setTotalPrice={setTotalPrice}
                        creditAmount={creditAmount}
                        balanceLoading={balanceLoading}
                        renderBalanceCheckout={renderBalanceCheckout}
                      />
                    )}
                  </Accordion.Content>
                </Accordion>
                <div className="thanks-wrapper">
                  <div
                    style={{
                      borderRadius: '5000px',
                      height: '40px',
                      width: '40px',
                      border: '1px solid green',
                      display: 'flex  ',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '1rem',
                    }}
                  >
                    <Check color="green" size={30} />
                  </div>
                  <div>
                    <Header className="thanks-name" as={'p'}>
                      Thanks {user?.first_name}!
                      <Header.Subheader
                        className="payment-successful"
                        as={'h2'}
                      >
                        Payment Successful
                      </Header.Subheader>
                    </Header>
                  </div>
                </div>
                <div className="on-the-way">
                  <Header as={'h3'}>Your order is on its way!</Header>
                  <Tab
                    className="order-shipping-address-tab-wrapper"
                    panes={tabPanes(lineItems)}
                  />
                </div>
                <div className="order-information-wrapper">
                  <Header as={'h3'}>Order Information</Header>
                  <Grid
                    width={16}
                    style={{
                      flexDirection: wd?.width <= 860 ? 'column' : 'row',
                    }}
                  >
                    <Grid.Column width={8}>
                      <div className="info-section">
                        <b>Customer Email</b>
                        <p>{user?.email}</p>
                      </div>
                      <div className="info-section">
                        <b>Billing Address</b>
                        <p>
                          {user?.first_name} {user?.last_name}
                        </p>
                        <p>
                          {invoice?.buyer?.business?.billing_address_line_1}
                        </p>
                        {invoice?.buyer?.business?.billing_address_line_2 && (
                          <p>
                            {invoice?.buyer?.business?.billing_address_line_2}
                          </p>
                        )}
                        <p>
                          {invoice?.buyer?.business?.billing_address_city},{' '}
                          {invoice?.buyer?.business?.billing_address_state}{' '}
                          {invoice?.buyer?.business?.billing_address_zip_code}{' '}
                        </p>
                        <p>{invoice?.buyer?.business?.country_code} </p>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <div className="info-section">
                        <b>Payment Method</b>
                        <p>
                          {currentTerms ? currentTerms : invoice?.net_terms}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
            )}

            {!successUrl && !invoice?.paid && (
              <div
                style={{
                  marginTop: '4rem',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Button
                  className="checkout-button"
                  style={{
                    '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor': 'transparent',
                  }}
                  content={'Proceed to Checkout'}
                  color={'primary'}
                  circular
                  disabled={balanceLoading}
                  loading={balanceLoading}
                  onClick={() => {
                    if (balanceLoading) {
                      return
                    }
                    if (currentTerms) {
                      renderBalanceCheckout()
                    } else {
                      toast.error('Please select a payment term.')
                    }
                  }}
                />
                <p style={{ fontFamily: 'Brown Light', marginTop: '1rem' }}>
                  Powered By{' '}
                  <span style={{ fontFamily: 'Brown' }}>Ba/ance</span>
                </p>
                <p style={{ marginTop: '1rem' }}>
                  Need Help?{' '}
                  <a
                    className="contact-us"
                    style={{
                      fontWeight: 'bold',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                    href="/contact-us"
                  >
                    Contact Us
                  </a>
                </p>
              </div>
            )}
          </Segment>
        </Grid.Column>

        {wd?.width > 768 && (
          <Divider vertical style={{ position: 'relative', height: '101vh' }} />
        )}

        <Grid.Column className="desktop-cart-wrapper" width={7}>
          {order && lineItems && invoice && (
            <Cart
              order={order}
              invoice={invoice}
              lineItems={lineItems}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              creditAmount={creditAmount}
              balanceLoading={balanceLoading}
              renderBalanceCheckout={renderBalanceCheckout}
            />
          )}
        </Grid.Column>
      </Grid>
      <Modal
        onClose={() => setOpenInfoModal(false)}
        onOpen={() => setOpenInfoModal(true)}
        open={openInfoModal}
        closeIcon
        size="tiny"
      >
        <Modal.Header>
          {selectedPayment === 'ACH Debit' && 'Bank Account'}
          {selectedPayment === 'Credit Card' && 'Credit Card'}
          {selectedPayment === 'ACH Credit' && 'ACH/Wire'}
          {selectedPayment === 'Terms' && 'Terms'}
        </Modal.Header>
        <Modal.Content>
          {selectedPayment === 'Terms' &&
            'Our payment provider Balance will pull from your account based on term agreement. Term periods start on day of delivery:  1% for 15 days, 2% for 30 days, 3% for 45 days, or 4% for 60 days.'}
          {selectedPayment === 'ACH Debit' &&
            "Our payment processor will withdraw the amount due once you're logged into your  account via Plaid."}
          {selectedPayment === 'Credit Card' &&
            'Our payment processor will withdraw the amount due once your Credit Card information is submitted via Plaid.'}
          {selectedPayment === 'ACH Credit' &&
            'We email you wire instructions and you push funds to the account provided.'}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default Payment
