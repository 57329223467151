import React, { useState } from 'react'
import {
  Table,
  Popup,
  Label,
  Button,
  Form,
  Input,
  Modal,
} from 'semantic-ui-react'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import { formatOrderNumber } from '../../../lib/utils/utils'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { X } from 'phosphor-react'
import './BuyerOrderItemsRow.css'
import { formatAddress } from '../../../helpers/helpers'
import MickeyForm from '../../../components/Forms/MickeyForm'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const BuyerOrderFulfillmentRow = (props) => {
  const { orderItem } = props
  const user = useSelector((state) => state.user.user)
  const [openTruckingModal, setOpenTruckingModal] = useState(false)
  const [openMarkDeliveredModal, setOpenMarkDeliveredModal] = useState(false)
  const wd = useWindowDimensions()

  const [deliveredQuantities, setDeliveredQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [deliveredUOMs, setDeliveredUOMs] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return cci.order_item?.unit?.id
    })
  )

  function handleMarkOrderDelivered(status, data) {
    if (status === 'success') {
      setOpenMarkDeliveredModal(false)
      props.setCargoUnitInstance(data)
      props.setReload(true)
    }
  }

  return (
    <>
      <Table.Row
        style={{
          backgroundColor: props.positive ? 'rgb(250, 255, 250)' : '',
        }}
        onClick={() => props.setSelectedCargo(props.cargoUnitInstance)}
        selectable
        positive={props.cargoUnitInstance?.id === props.selectedCargo?.id}
      >
        <Table.Cell
          style={{ fontSize: wd.width >= 768 ? '' : '1.5rem' }}
          width={2}
        >
          {props.cargoUnitInstance?.id === props.selectedCargo?.id && (
            <div
              style={{
                marginRight: '10px',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor: '#4daf7c',
                display: 'inline-block',
              }}
            ></div>
          )}
          {formatOrderNumber(props.cargoUnitInstance?.string_token)}
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.estimated_delivery_date}
        </Table.Cell>
        <Table.Cell width={3}>
          {formatAddress(props.cargoUnitInstance?.delivery_location)}
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.container_items?.length > 0 ? (
            <Popup
              trigger={
                <span style={{ cursor: 'pointer' }}>
                  {props.cargoUnitInstance?.container_items?.length.toString()}{' '}
                  Item
                  {props.cargoUnitInstance?.container_items?.length > 1
                    ? 's'
                    : ''}
                </span>
              }
              position={'bottom middle'}
              flowing
              content={
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.cargoUnitInstance?.container_items?.map(
                      (containerItem) => {
                        return (
                          <Table.Row>
                            <Table.Cell>
                              <OrderItemDetailTwo
                                orderItem={{
                                  product_variant:
                                    containerItem?.product_variant,
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {containerItem?.quantity}{' '}
                              {containerItem?.unit?.short_name}
                            </Table.Cell>
                          </Table.Row>
                        )
                      }
                    )}
                  </Table.Body>
                </Table>
              }
            />
          ) : (
            'No Items'
          )}
        </Table.Cell>
        <Table.Cell width={1}>
          <Label
            color={
              props.cargoUnitInstance?.delivered
                ? 'green'
                : !props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped
                ? 'red'
                : !props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked
                ? 'orange'
                : ''
            }
            content={
              <>
                {props.cargoUnitInstance?.delivered && 'Delivered'}
                {!props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped &&
                  'In Transit'}
                {!props.cargoUnitInstance?.delivered &&
                  !props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked &&
                  'Booked'}
                {!props.cargoUnitInstance?.delivered &&
                  !props.cargoUnitInstance?.supplier_shipped &&
                  !props.cargoUnitInstance?.trucking_booked &&
                  'New'}
              </>
            }
          />
        </Table.Cell>
        <Table.Cell width={2}>
          {hasPermission(user, 'Update Fulfillment') && (
            <Button
              content={'Mark Delivered'}
              size={'mini'}
              color={'green'}
              style={{
                cursor: 'pointer',
                color: 'white',
                display:
                  props.cargoUnitInstance?.supplier_shipped &&
                  !props.cargoUnitInstance?.delivered
                    ? ''
                    : 'none',
              }}
              disabled={
                !(
                  props.cargoUnitInstance?.supplier_shipped &&
                  !props.cargoUnitInstance?.delivered
                )
              }
              onClick={() => {
                setOpenMarkDeliveredModal(true)
              }}
            />
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        className="trucking-modal"
        onClose={() => setOpenTruckingModal(false)}
        onOpen={() => setOpenTruckingModal(true)}
        open={openTruckingModal}
      >
        <Modal.Header>
          <div>
            <span>Trucking Details</span>
            <X
              size={25}
              link
              onClick={() => {
                setOpenTruckingModal(false)
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group className="form-header">
              <Form.Field>
                <span>Date & Time</span>
              </Form.Field>
              <Form.Field>
                <span>Logistics/Cost</span>
              </Form.Field>
              <Form.Field>
                <span>Truck Size</span>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label style={{ marginBottom: '1rem' }}>
                  Location for Pickup
                </label>
                <Input
                  type={'text'}
                  value={
                    orderItem?.pickup_location?.address_1 +
                    ', ' +
                    orderItem?.pickup_location?.city +
                    ', ' +
                    orderItem?.pickup_location?.state +
                    ' ' +
                    orderItem?.pickup_location?.zip_code
                  }
                  placeholder={'Location for Pickup'}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Truck Size*</label>
                <Input
                  type={'text'}
                  value={orderItem?.trucking_quote?.type}
                  placeholder={'Truck Size'}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Logistics Company</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.trucking_company}
                  placeholder={'Logistics Company'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Pick-Up Date*</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.pickup_date}
                  placeholder={'Pick-Up Date'}
                />
              </Form.Field>
              <Form.Field>
                <label>Estimated Load Weight</label>
                <Input
                  type={'text'}
                  disabled
                  value={
                    orderItem?.trucking_quote?.api_response?.data?.items?.weight
                  }
                  placeholder={'Estimated Load Weight'}
                />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.rate}
                  placeholder={'Cost'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Drop-off Date*</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.delivery_date}
                  placeholder={'Drop-off Date'}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>

      <Modal
        onClose={() => setOpenMarkDeliveredModal(false)}
        onOpen={() => setOpenMarkDeliveredModal(true)}
        open={openMarkDeliveredModal}
      >
        <Modal.Header>Mark Cargo Delivered</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.cargoUnitInstance?.container_items?.map((cci, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={cci?.order_item} />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder={'Quantity'}
                        min={0}
                        type={'number'}
                        step={0.01}
                        value={deliveredQuantities[index]}
                        onChange={(e, d) => {
                          if (d.value < 0) {
                            toast.error('Please Enter Valid Quantity')
                            return
                          }
                          let sq = [...deliveredQuantities]
                          sq[index] = d.value
                          setDeliveredQuantities(sq)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={deliveredUOMs[index]}
                        params={{
                          page_size: 'infinite',
                          product_id:
                            cci?.order_item?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sq = [...deliveredUOMs]
                          sq[index] = d.value
                          setDeliveredUOMs(sq)
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <MickeyForm
            model={'CargoUnitInstance'}
            formName={'MarkOrderDeliveredForm'}
            successMessage={'Cargo marked as delivered!'}
            failureMessage={
              'Error marking cargo as delivered, please try again.'
            }
            url={'/main/mark-cargo-unit-instance-delivered/'}
            handleForm={handleMarkOrderDelivered}
            buttonLabel={'Mark Delivered'}
            values={{
              delivered_quantities: deliveredQuantities,
              delivered_uoms: deliveredUOMs,
              ccis: props.cargoUnitInstance?.container_items?.map(
                (cci, index) => {
                  return cci?.id
                }
              ),
              cargo_unit_instance_id: props.cargoUnitInstance?.id,
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default BuyerOrderFulfillmentRow
