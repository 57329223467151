import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Segment, Card, Icon, Button, Modal } from 'semantic-ui-react'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { toast } from 'react-toastify'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import fileDownload from 'js-file-download'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function BuyerOrderDocuments(props) {
  const user = useSelector((state) => state.user.user)
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [openDocumentForm, setOpenDocumentForm] = useState(false)
  const axios = useAxios()

  useEffect(() => {
    async function loadData() {
      if (loading) return
      setLoading(true)
      let res = await axios.get(`/main/documents/`, {
        params: {
          order_id: props.order?.id,
          buyer_id: props.buyer?.id,
          order_item_id: props.order_item?.id,
          visibilities: ['Buyer', 'Both'],
        },
      })
      setLoading(false)
      if (res?.data?.results) {
        setDocuments(res.data.results)
      } else {
        toast.error('Error loading documents :(')
      }
      setReload(false)
    }
    loadData()
  }, [reload])

  async function downloadData(document_id, document_name, document_extension) {
    let res = await axios.get('/main/download-document/', {
      responseType: 'blob',
      params: {
        document_id: document_id,
        document_field_name: 'file',
        tenant_aware: false,
      },
    })
    fileDownload(res.data, document_name + document_extension)
  }
  useEffect(() => {
    setOpenDocumentForm(props.openDocumentForm)
  }, [props.openDocumentForm])

  useEffect(() => {
    props.setOpenDocumentForm(openDocumentForm)
  }, [openDocumentForm])

  return (
    <div>
      <Segment basic>
        {loading && <MickeyPlaceholderLoading />}
        {!loading && documents?.length < 1 && (
          <MickeyPlaceholder icon={'file'} message={'No order documents'} />
        )}
        <Grid stackable>
          {!loading &&
            documents?.length > 0 &&
            documents?.map((document) => {
              return (
                <Grid.Column width={3}>
                  <Card style={{ width: '100%' }}>
                    <Card.Content>
                      <Card.Header>{document.document_name}</Card.Header>
                      <Card.Meta>
                        <span className="date">{document.created_at}</span>
                      </Card.Meta>
                      <Card.Description>
                        {document.document_type}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      {document.file && (
                        <Button
                          onClick={() => {
                            downloadData(
                              document.id,
                              document.document_name,
                              document.document_extension
                            )
                          }}
                        >
                          <Icon name="file" />
                          Download
                        </Button>
                      )}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              )
            })}
        </Grid>
        <ESignRequestsTable
          businessIdUsers={props.order?.buyer?.business.id}
          orderId={props.order?.id}
          style={{ marginTop: '50px' }}
        />
      </Segment>
      <Modal
        onClose={() => setOpenDocumentForm(false)}
        onOpen={() => setOpenDocumentForm(true)}
        open={openDocumentForm && hasPermission(user, 'Create Document')}
      >
        <Modal.Header>Add New Document</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'DocumentUploadForm'}
            model={'Document'}
            basicSegment
            url={'/main/documents/'}
            tenantAware={false}
            currentPortal={props.currentPortal}
            values={{
              order_id: props.order?.id,
              buyer_id: props.buyer?.id,
              visiblity: 'Buyer',
            }}
            handleForm={() => {
              setReload(true)
              setOpenDocumentForm(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default BuyerOrderDocuments
