import PageHeader from '../../../components/PageHeader/PageHeader'
import TakeRate from './GlobalTakeRate'

function AdminExchangeSettings(props) {
  return (
    <div className={'main-container'} id="admin-dashboard">
      <PageHeader header={'Take Rate'} />
      <TakeRate />
    </div>
  )
}

export default AdminExchangeSettings
