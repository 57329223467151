import React, { useRef, useEffect, useState } from 'react'
import { Modal, Grid, Loader } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'

const ESignRequestIframeModal = (props) => {
  const {
    documentName,
    setOpenModal,
    openModal,
    embedUrl,
    signRequestId,
    reviewer,
    reviewerOrder,
    totalReviewers,
    setReload,
  } = props
  const modalRef = useRef(null)
  const axios = useAxios()
  const [closing, setClosing] = useState(false)
  const [iframeSize, setIframeSize] = useState({ width: 1000, height: 500 })

  useEffect(() => {
    const handleResize = () => {
      if (modalRef.current) {
        const width = modalRef.current.offsetWidth
        const height = modalRef.current.offsetHeight
        setIframeSize({ width, height })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const toggleModal = async () => {
    setClosing(true)
    await axios.post('/main/box-sign-requests/sync_box_user_sign_requests/', {
      id: signRequestId,
    })
    setClosing(false)
    setOpenModal(false)
    if (setReload) {
      setReload(true)
    }
  }

  if (openModal && iframeSize?.height)
    return (
      <>
        <Modal
          className="settlement-statement-detail"
          size={'fullscreen'}
          open={openModal}
          onOpen={() => setOpenModal(true)}
          onClose={() => {
            toggleModal()
          }}
          closeIcon
        >
          <Modal.Header>
            <span
              style={{ fontSize: '22px', color: '#00000', fontWeight: 'bold' }}
            >
              {documentName}
            </span>
          </Modal.Header>
          <Modal.Content style={{ position: 'relative' }}>
            {closing && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                ></div>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1001,
                  }}
                >
                  <Loader active inline="centered" />
                  <div
                    style={{
                      marginTop: '10px',
                      color: 'white',
                      fontSize: '16px',
                    }}
                  >
                    Document Status Syncing...
                  </div>
                </div>
              </>
            )}
            <Grid.Row>
              <Grid.Column>
                <p style={{ fontSize: '16px', color: '#a1a1a1' }}>Reviewer</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <p
                  style={{
                    fontSize: '18px',
                    color: '#00000',
                    fontWeight: 'bold',
                  }}
                >
                  {reviewer}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: '10px' }}>
              <Grid.Column>
                <p style={{ fontSize: '16px', color: '#a1a1a1' }}>
                  Review Order
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginBottom: '20px' }}>
              <Grid.Column>
                <p
                  style={{
                    fontSize: '18px',
                    color: '#00000',
                    fontWeight: 'bold',
                  }}
                >
                  {reviewerOrder} of {totalReviewers}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <iframe
                  className="full-width-iframe"
                  src={embedUrl}
                  height={iframeSize.height}
                  frameborder="0"
                  allowfullscreen
                  webkitallowfullscreen
                  msallowfullscreen
                />
              </Grid.Column>
            </Grid.Row>
          </Modal.Content>
        </Modal>
      </>
    )
}

export default ESignRequestIframeModal
