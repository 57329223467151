import React, { useState } from 'react'
import { Table, Button, Icon, Modal, Input } from 'semantic-ui-react'
const AdminChangeableOrderRow = (props) => {
  const [editQuantity, setEditQuantity] = useState(null)
  const [editPieceCount, setEditPieceCount] = useState(null)
  const [editOrderItemModal, setEditOrderItemModal] = useState(false)
  const [cancelOrderItemModal, setCancelOrderItemModal] = useState(false)

  const { orderItem, createLineItem } = props

  return (
    <>
      <Table.Row>
        <Table.Cell width={2}>
          {orderItem?.product_variant?.product?.material.name}
        </Table.Cell>
        <Table.Cell width={2}>{orderItem?.supplier?.business?.name}</Table.Cell>
        <Table.Cell width={2}>
          {orderItem?.buyer_quantity} /{orderItem?.buyer_unit?.short_name}
        </Table.Cell>
        <Table.Cell>
          {orderItem?.buyer_quantity_values &&
            orderItem?.buyer_quantity_values['booked_quantity']}{' '}
          /{orderItem?.buyer_unit?.short_name}
        </Table.Cell>
        <Table.Cell>
          {orderItem?.buyer_quantity_values &&
            orderItem?.buyer_quantity_values['destination_location_quantity'] +
              orderItem?.buyer_quantity_values['booked_quantity']}{' '}
          /{orderItem?.buyer_unit?.short_name}
        </Table.Cell>
        <Table.Cell>
          {orderItem?.buyer_quantity_values &&
            orderItem?.buyer_quantity_values[
              'unbooked_origin_location_quantity'
            ]}{' '}
          /{orderItem?.buyer_unit?.short_name}
        </Table.Cell>
        <Table.Cell>
          <div style={{ display: 'flex' }}>
            <Button onClick={() => setCancelOrderItemModal(true)} color="red">
              <Icon name="x" style={{ margin: '0' }} />
            </Button>
            <Button onClick={() => setEditOrderItemModal(true)} color="grey">
              <Icon name="edit" style={{ margin: '0' }} />
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
      {/* CANCEL ORDER ITEM */}
      <Modal
        onClose={() => setCancelOrderItemModal(false)}
        onOpen={() => setCancelOrderItemModal(true)}
        open={cancelOrderItemModal}
        size={'small'}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        {/* only cancel if no payments and no trucking vals */}
        <Modal.Header>
          Cancel{' '}
          {orderItem?.buyer_quantity_values &&
          (orderItem?.buyer_quantity_values['booked_quantity'] !== 0 ||
            orderItem?.buyer_quantity_values[
              'destination_location_quantity'
            ] !== 0 ||
            orderItem?.buyer_quantity_values['in_transit_quantity'] !== 0)
            ? 'remaining'
            : ''}{' '}
          {orderItem?.product_variant?.product?.material?.name} for order{' '}
          {orderItem?.order?.order_number}?
        </Modal.Header>
        <Modal.Actions>
          <Button color="red" onClick={() => setCancelOrderItemModal(false)}>
            No
          </Button>
          <Button
            color="green"
            onClick={() =>
              createLineItem(
                orderItem?.id,
                orderItem?.buyer_unit,
                orderItem?.product_variant?.attribute_selections,
                null,
                false,
                true,
                false,
                orderItem?.product_variant,
                null,
                null
              )
            }
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      {/* EDIT ORDER ITEM */}
      <Modal
        onClose={() => setEditOrderItemModal(false)}
        onOpen={() => setEditOrderItemModal(true)}
        open={editOrderItemModal}
        size={'small'}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <Modal.Header>
          Change order request for{' '}
          {orderItem?.product_variant?.product?.material?.name}
        </Modal.Header>
        <Modal.Content>
          {/* new quantity for eligible product */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
              <Input
                style={{ width: '100%' }}
                placeholder={'Quantity'}
                label={`${orderItem?.buyer_quantity_values['unbooked_origin_location_quantity']} /${orderItem?.buyer_unit?.short_name}`}
                labelPosition="right"
                onChange={(e, d) => {
                  setEditQuantity(d?.value)
                }}
              />
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={() => setEditOrderItemModal(false)}>
            Cancel
          </Button>
          <Button
            disabled={
              editQuantity === null ||
              editQuantity === '' ||
              editQuantity <=
                (orderItem?.buyer_quantity_values &&
                  orderItem?.buyer_quantity_values[
                    'unbooked_origin_location_quantity'
                  ] * -1)
            }
            color="green"
            onClick={() =>
              createLineItem(
                orderItem?.id,
                orderItem?.buyer_unit,
                orderItem?.product_variant?.attribute_selections,
                parseFloat(editQuantity),
                false,
                false,
                true,
                orderItem?.product_variant,
                null,
                null
              )
            }
          >
            Submit Change Order Request
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminChangeableOrderRow
