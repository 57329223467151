import { useEffect, useState } from 'react'
import _ from 'underscore'
import PageHeader from '../../../components/PageHeader/PageHeader'
import {
  Modal,
  Header,
  Segment,
  Grid,
  Placeholder,
  Pagination,
} from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import AdminOrdersRow from './AdminOrdersRow'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../../lib/auth/useAxios'
import env from '../../../env'
import { toast } from 'react-toastify'
import '@silevis/reactgrid/styles.css'
import './AdminOrders.css'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { Plus } from 'phosphor-react'
import DealModal from '../../../components/Deal/DealModal'

function AdminOrders(props) {
  const [reload, setReload] = useState(false)
  const user = useSelector((state) => state.user.user)
  const [openAddOrderModal, setOpenAddOrderModal] = useState(null)
  const [activeItem, setActiveItem] = useState('orders')
  const [spreadsheetRows, setSpreadsheetRows] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [openDealForm, setOpenDealForm] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const navigate = useNavigate()
  let axios = useAxios()

  async function createTestOrder() {
    let res = await axios.post(`/main/admin-create-test-order/`)
    if (res?.data?.success) {
      setReload(true)
      toast.success('Added test order.')
    } else {
      toast.error('Error adding test order.')
    }
  }

  async function getOrderSpreadsheetData() {
    setLoaded(false)
    let res = await axios.get(`/main/admin-orders/spreadsheet/`, {
      params: {
        business_id: props.business?.id,
        page: activePage,
        page_size: 15,
      },
    })
    if (res?.data?.results) {
      let rows = [
        {
          rowId: 'header',
          cells: [
            { type: 'header', text: 'Date' },
            { type: 'header', text: 'Deal Number' },
            { type: 'header', text: 'Salespeople' },
            { type: 'header', text: 'Buyer' },
            { type: 'header', text: 'Invoice Date' },
            { type: 'header', text: 'Seller' },
            { type: 'header', text: 'AR' },
            { type: 'header', text: 'AP' },
            { type: 'header', text: 'Received' },
            { type: 'header', text: 'Received Date' },
            { type: 'header', text: 'Paid' },
            { type: 'header', text: 'Paid Date' },
            { type: 'header', text: 'Freight Cost' },
            { type: 'header', text: 'Freight Carrier' },
            { type: 'header', text: 'Freight Paid' },
            { type: 'header', text: 'Freight Paid Date' },
            { type: 'header', text: 'Buyer Marketplace Fee' },
            { type: 'header', text: 'Seller Marketplace Fee' },
            { type: 'header', text: 'Total Mickey Take (excl. MF)' },
          ],
        },
        ...res.data.results?.map((row, idx) => ({
          rowId: idx,
          cells: row,
          height: calculateTextHeight(row),
        })),
      ]
      setSpreadsheetRows(rows)
      setTotalPages(res.data?.total_pages ? res.data?.total_pages : 1)
      setLoaded(true)
    }
  }

  useEffect(() => {
    if (activeItem === 'spreadsheet') {
      getOrderSpreadsheetData()
    }
  }, [activePage])

  function calculateTextHeight(row) {
    let sellers = row[5].text
    let freightCarriers = row[13].text
    let maxLines = Math.max(
      sellers.split('\n').length,
      freightCarriers.split('\n').length
    )
    return maxLines * 25
  }

  const spreadsheetColumns = [
    { columnId: 'date', width: 100 },
    { columnId: 'order_number', width: 120 },
    { columnId: 'salespeople', width: 200 },
    { columnId: 'buyer', width: 150 },
    { columnId: 'invoice_date', width: 150 },
    { columnId: 'seller', width: 150 },
    { columnId: 'ar', width: 150 },
    { columnId: 'ap', width: 150 },
    { columnId: 'received', width: 150 },
    { columnId: 'received_date', width: 150 },
    { columnId: 'paid', width: 150 },
    { columnId: 'paid_date', width: 150 },
    { columnId: 'freight_cost', width: 150 },
    { columnId: 'freight_carrier', width: 150 },
    { columnId: 'freight_paid', width: 150 },
    { columnId: 'freight_paid_date', width: 150 },
    { columnId: 'buyer_marketplace_fee', width: 150 },
    { columnId: 'seller_marketplace_fee', width: 150 },
    { columnId: 'mickey_take', width: 150 },
  ]

  const headerRow = [
    'Status',
    'Mobile',
    'Deal Number',
    'Date/Time',
    'Supplier',
    'Buyer',
    'Logistics Status',
    'Payment Status',
    '',
  ]
  const headerSearch = [
    {
      header: 'Deal Number',
      type: 'search',
      input_type: 'number',
      search_param: 'order_number',
    },
    {
      header: 'Status',
      type: 'dropdown',
      options: [
        {
          text: 'New Deal',
          value: 'New Deal',
        },
        {
          text: 'Active Deal',
          value: 'Active Deal',
        },
        {
          text: 'Completed Deal',
          value: 'Completed Deal',
        },
      ],
      search_param: 'scrapgo_status',
    },
    {
      header: 'Mobile',
      type: 'dropdown',
      options: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      search_param: 'mobile_app',
    },
    {
      header: 'Date/Time',
      type: 'date',
      search_param: 'date',
    },
    {
      header: 'Supplier',
      type: 'dropdown',
      options_url: 'suppliers/',
      options_params: { tenant_aware: false },
      options_titleFields: ['business.name'],
      search_param: 'supplier_id',
    },
    {
      header: 'Buyer',
      type: 'dropdown',
      options_url: 'buyers/',
      options_params: { tenant_aware: false },
      options_titleFields: ['business.name'],
      search_param: 'buyer_id',
    },
    {
      header: 'Logistics Status',
      type: 'dropdown',
      options: [
        { key: 1, value: 1, text: 'Order Submitted' },
        { key: 2, value: 2, text: 'Order Matched' },
        { key: 3, value: 3, text: 'Logistics Booked' },
        { key: 4, value: 4, text: 'In Route' },
        { key: 5, value: 5, text: 'Delivered' },
      ],
      search_param: 'logistics_status',
    },
    {
      header: 'Payment Status',
      type: 'dropdown',
      options: [
        {
          key: 'Requested Payment',
          value: 'Requested Payment',
          text: 'Requested Payment',
        },
        { key: 'Buyer Paid', value: 'Buyer Paid', text: 'Buyer Paid' },
        { key: 'Paid Supplier', value: 'Paid Supplier', text: 'Paid Supplier' },
      ],
      search_param: 'payment_status',
    },
  ]
  const headerSort = [
    {
      header: 'Deal Number',
      sort_by: 'order_number',
    },
    {
      header: 'Date/Time',
      sort_by: 'created_at',
    },
    {
      header: 'Supplier',
      sort_by: 'supplier__business__name',
    },
    {
      header: 'Buyer',
      sort_by: 'buyer__business__name',
    },
    {
      header: 'Logistics Status',
      hide_sort_by: true,
    },
    {
      header: 'Payment Status',
      hide_sort_by: true,
    },
  ]
  const urlParams = {
    tenant_aware: false,
    active: true,
    business_id: props.business?.id,
    salesperson_id: props.salespersonId,
  }

  return (
    <div className={'main-container'}>
      {!props.hideHeader && (
        <PageHeader
          icon={'file'}
          header={'Deals'}
          buttons={
            hasPermission(user, 'Create Order')
              ? [
                  {
                    color: 'primary',
                    content: 'Add Deal',
                    icon: <Plus weight="bold" />,
                    onClick: () => {
                      if (props?.business?.supplier) {
                        setOpenDealForm(true)
                      } else {
                        setOpenAddOrderModal(true)
                      }
                    },
                    style: {
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    },
                  },
                ]
              : []
          }
        />
      )}
      {activeItem === 'orders' && (
        <Segment style={{ overflowX: 'auto' }}>
          <MickeyTable
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            url={'/main/admin-orders/'}
            hideFooter={false}
            reload={reload}
            setReload={setReload}
            renderBodyRow={(data, index) => {
              return (
                <AdminOrdersRow
                  order={data}
                  index={index}
                  reload={reload}
                  setReload={setReload}
                  key={data ? data.id : null}
                />
              )
            }}
            emptyIcon={'file'}
            emptyMessage={'Deals will populate here'}
          />
        </Segment>
      )}
      <Modal
        onClose={() => setOpenAddOrderModal(false)}
        onOpen={() => setOpenAddOrderModal(true)}
        open={openAddOrderModal}
      >
        <Modal.Header>Deal Detail</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AdminOrderForm'}
            tenantAware={false}
            model={'Order'}
            buttonFloatedRight={true}
            buttonLabel={'Add Deal'}
            successMessage={'Deal Added!'}
            defaults={
              props.business?.business?.buyer
                ? {
                    mx_order: false,
                    buyer: props?.business?.buyer?.id,
                  }
                : {
                    mx_order: false,
                  }
            }
            hiddenFields={props?.business?.buyer && ['buyer']}
            handleForm={(success, order) => {
              if (success === 'success') {
                setOpenAddOrderModal(false)
                navigate(`/portal/admin/deals/${order.string_token}/`)
              }
            }}
            failureMessage={'Error adding deal.'}
            url={'/main/orders/'}
            values={{
              active: true,
              supplier_accepted: true,
            }}
          />
        </Modal.Content>
      </Modal>
      {openDealForm && (
        <DealModal
          supplier={props.business.supplier}
          openModal={openDealForm}
          closeModal={() => setOpenDealForm(false)}
        />
      )}
    </div>
  )
}

export default AdminOrders
