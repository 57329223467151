import { useState } from 'react'
import {
  Button,
  Header,
  Loader,
  Modal,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react'
import { track } from '@amplitude/analytics-browser'
import './ExchangeTable.css'
import env from '../../../../../env'
import useAxios from '../../../../../lib/auth/useAxios'
import CustomAttribute from '../../MXMain/CustomAttributes/CustomAttribute'
import { X } from 'phosphor-react'

const AttributesModal = (props) => {
  const {
    setOpenAttributesModal,
    handleClose,
    productForWatchlistModal,
    attributesForSelectedProduct,
    loadingNextModal,
    openAttributesModal,
    user,
    selectedProductId,
    business,
    updateWatchlist,
    setLoadingNextModal,
    watchlistSubmitting,
    setWatchlistSubmitting,
    attributeSelectionsFromDropdown,
    setAttributeSelectionsFromDropdown,
    currentPortal,
  } = props
  const axios = useAxios()
  const [useCustomTally, setUseCustomTally] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(null)
  const [customAttributesSelected, setCustomAttributesSelected] = useState([])

  let selection_ids = {}
  let selection_ids_list = []

  function handleWatchlistCheckbox(attribute) {
    let attributeLength = attributesForSelectedProduct?.find(
      (old_attribute) => old_attribute.name === attribute
    )?.attribute_selections.length
    let selectedAttributeLength =
      attributeSelectionsFromDropdown[attribute]?.length
    if (
      selectedAttributeLength &&
      attributeLength === selectedAttributeLength
    ) {
      let existingAttributeSelectionsFromDropdown = {}
      Object.keys(attributeSelectionsFromDropdown)?.forEach((attributeName) => {
        if (attributeName !== attribute) {
          existingAttributeSelectionsFromDropdown[attributeName] =
            attributeSelectionsFromDropdown[attributeName]
        }
      })
      setAttributeSelectionsFromDropdown(
        existingAttributeSelectionsFromDropdown
      )
    } else {
      setAttributeSelectionsFromDropdown({
        ...attributeSelectionsFromDropdown,
        [attribute]: attributesForSelectedProduct
          ?.find((product_attribute) => product_attribute.name === attribute)
          ?.attribute_selections?.map((selection) => {
            return {
              attributeName: attribute,
              selectionId: selection.id,
              selectionValue: selection.value,
              selectionValueDisplay: selection.value_display,
            }
          }),
      })
    }
  }

  const getProductVariant = async (selection) => {
    let attribute_selections = Object.values(selection).map((attribute) => {
      return {
        selection_id: attribute.selectionId,
        selection_value: attribute.selectionValue,
        attribute_name: attribute.attributeName,
      }
    })
    setLoadingNextModal(true)
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        attribute_selections: attribute_selections,
        product_id: selectedProductId,
      },
    })
    if (res?.data) {
      setLoadingNextModal(false)
      return res?.data
    }
    return null
  }

  const completeWatchlistSubmit = async (new_selection) => {
    let productVariant = await getProductVariant(Object.values(new_selection))
    const current_user = user
    let productVariantAlready = false
    if (
      current_user?.product_variant_watchlist.find(
        (product_variant) =>
          product_variant['pv_id'] === productVariant?.id &&
          product_variant['location_id'] ===
            current_user?.current_location_id &&
          product_variant['side'] ===
            (currentPortal === 'supplier' ? 'ask' : 'bid')
      )
    ) {
      productVariantAlready = true
    }
    if (productVariant != null && !productVariantAlready) {
      return productVariant?.id
    }
    return true
  }

  const getCombnations = (array, keys, index) => {
    let key = keys[index]
    array[key].forEach((val) => {
      selection_ids[key] = val
      let selection_dict = {}
      Object.keys(selection_ids)?.forEach((selection_key) => {
        selection_dict[selection_key] =
          selection_key === key ? val : selection_ids[selection_key]
      })
      if (keys[index + 1]) {
        getCombnations(array, keys, index + 1)
      } else {
        selection_ids_list.push(selection_dict)
      }
    })
    if (index === 0) {
      return selection_ids_list
    }
  }

  const handleWatchlistSubmit = async (closeModal) => {
    setSubmitLoading(closeModal)
    setWatchlistSubmitting(true)
    let product_varients = []
    let alredy_exists = []
    let updatedWatchlist = false
    let keys = Object.keys(attributeSelectionsFromDropdown)
    let combinations = await getCombnations(
      attributeSelectionsFromDropdown,
      keys,
      0
    )
    combinations?.map(async (comb, index) => {
      let res = await completeWatchlistSubmit(comb)
      if (typeof res === 'boolean' && res === true) {
        alredy_exists.push(res)
      } else if (res) {
        product_varients.push(res)
      }
      if (combinations.length === index + 1) {
        if (!updatedWatchlist) {
          updatedWatchlist = true
        }
        await updateWatchlist(product_varients, false, closeModal)
      }
    })
    selection_ids = {}
    selection_ids_list = []
  }

  const handleUpdateCustomValues = (attributeName, customValues) => {
    let attributeSelectionsFromDropdownCopy = attributeSelectionsFromDropdown
    if (attributeSelectionsFromDropdownCopy[attributeName]) {
      attributeSelectionsFromDropdownCopy[attributeName]?.forEach(
        (selection) => {
          if (selection.attributeName === attributeName) {
            selection.selectionValue = customValues
          }
        }
      )
      setAttributeSelectionsFromDropdown(attributeSelectionsFromDropdownCopy)
    }
  }

  const handleUpdateDropdownSelections = (attribute, selection) => {
    if (selection.id === 'custom') {
      setUseCustomTally(true)
    }
    if (selection.id === 'custom' && customAttributesSelected.length < 1) {
      setCustomAttributesSelected([attribute.name])
    }
    // if (selection.id === "custom" && !customAttributesSelected.includes(attribute.name) && customAttributesSelected.length >= 1) {
    //   setCustomAttributesSelected(tally => [...tally, attribute.name])
    // }
    // if (attribute.id !== "custom" && customAttributesSelected.includes(attribute.name) && customAttributesSelected.length >= 1) {
    //   let customAttributesSelectedCopy = [...customAttributesSelected]
    //   let customIndex = customAttributesSelectedCopy.indexOf(attribute.name)
    //   customAttributesSelectedCopy.splice(customIndex)
    //   setCustomAttributesSelected(customAttributesSelectedCopy)
    // }

    let existingAttributeValue
    if (Object.keys(attributeSelectionsFromDropdown).includes(attribute.name)) {
      existingAttributeValue = attributeSelectionsFromDropdown[attribute.name]
      let valueAlreadyExisted = existingAttributeValue?.filter(
        (eAV) => eAV?.selectionValueDisplay === selection.value_display
      )
      if (valueAlreadyExisted && valueAlreadyExisted?.length) {
        return
      }
      existingAttributeValue.push({
        attributeName: attribute.name,
        selectionId: selection.id,
        selectionValue: selection.value,
        selectionValueDisplay: selection.value_display,
      })
    } else {
      existingAttributeValue = [
        {
          attributeName: attribute.name,
          selectionId: selection.id,
          selectionValue: selection.value,
          selectionValueDisplay: selection.value_display,
        },
      ]
    }

    setAttributeSelectionsFromDropdown({
      ...attributeSelectionsFromDropdown,
      [attribute.name]: existingAttributeValue,
    })
  }

  const handleRemoveAttributeSelection = (attributeName, selectionValueId) => {
    if (selectionValueId === 'custom') {
      setUseCustomTally(false)
    }
    let existingAttributeValue = attributeSelectionsFromDropdown[
      attributeName
    ]?.filter(
      (existingAttribute) => existingAttribute.selectionId !== selectionValueId
    )
    if (existingAttributeValue?.length > 0) {
      setAttributeSelectionsFromDropdown({
        ...attributeSelectionsFromDropdown,
        [attributeName]: existingAttributeValue,
      })
    } else {
      let existingAttributeSelectionsFromDropdown = {}
      Object.keys(attributeSelectionsFromDropdown)?.forEach((attribute) => {
        if (attribute !== attributeName) {
          existingAttributeSelectionsFromDropdown[attribute] =
            attributeSelectionsFromDropdown[attribute]
        }
      })
      setAttributeSelectionsFromDropdown(
        existingAttributeSelectionsFromDropdown
      )
    }
  }

  return (
    <>
      <Modal
        onOpen={() => {
          setOpenAttributesModal(true)
          track('Add Exchange Order Page 2', {
            Product: selectedProductId,
            'User ID': user?.id,
            'Business Name': business?.name,
          })
        }}
        onClose={() => {
          setAttributeSelectionsFromDropdown({})
          handleClose()
          setWatchlistSubmitting(false)
          setUseCustomTally(false)
          setCustomAttributesSelected([])
        }}
        open={openAttributesModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Select Attributes
            </Header>
            <Header.Subheader style={{ textAlign: 'center' }}>
              For{' '}
              {productForWatchlistModal &&
                productForWatchlistModal?.material?.name}{' '}
              {productForWatchlistModal &&
                productForWatchlistModal?.commodity_form?.name}
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              overflow: 'visible',
            }}
          >
            {attributesForSelectedProduct &&
              attributesForSelectedProduct?.map((attribute) => {
                return (
                  <>
                    <div style={{ margin: '.5rem 0' }}>
                      <Checkbox
                        checked={
                          attribute.attribute_selections.length ===
                          attributeSelectionsFromDropdown[attribute.name]
                            ?.length
                        }
                        onChange={(e, d) => {
                          handleWatchlistCheckbox(attribute.name)
                        }}
                      />
                      <label style={{ margin: '0 5px' }}>
                        {attribute.name}
                      </label>
                    </div>
                    <Dropdown
                      label={attribute.name}
                      style={{
                        marginBottom: '1rem',
                      }}
                      placeholder={`Select ${attribute.name}`}
                      multiple
                      selection
                      fluid
                      text={
                        attributeSelectionsFromDropdown[attribute.name] ? (
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginTop: '10px',
                              padding: '0 20px',
                            }}
                          >
                            {attributeSelectionsFromDropdown[
                              attribute.name
                            ]?.map((selectedAttribute, i) => {
                              return (
                                <Button
                                  key={i}
                                  size={'mini'}
                                  color="grey"
                                  className={'selected-btn'}
                                  style={{
                                    margin: '0',
                                    padding: '0.2rem 0.5rem 0.2rem 0.1rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                    '--primaryColor':
                                      env.REACT_APP_PRIMARY_COLOR,
                                    '--secondaryButtonColor':
                                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                  }}
                                >
                                  <Button
                                    icon
                                    size={'mini'}
                                    circular
                                    style={{
                                      backgroundColor: 'transparent',
                                      padding: '.2rem',
                                    }}
                                    onClick={() =>
                                      handleRemoveAttributeSelection(
                                        attribute?.name,
                                        selectedAttribute?.selectionId
                                      )
                                    }
                                  >
                                    <X
                                      size={14}
                                      style={{ margin: '0', color: 'white' }}
                                    />
                                  </Button>
                                  {selectedAttribute.selectionValueDisplay
                                    ? selectedAttribute.selectionValueDisplay
                                    : 'Unspecified'}
                                </Button>
                              )
                            })}
                          </div>
                        ) : (
                          ''
                        )
                      }
                      options={attribute?.attribute_selections?.map(
                        (selection) => {
                          return (
                            <Dropdown.Item
                              value={selection.value}
                              onClick={() =>
                                handleUpdateDropdownSelections(
                                  attribute,
                                  selection
                                )
                              }
                            >
                              {selection.value_display
                                ? selection.value_display
                                : 'Unspecified'}
                            </Dropdown.Item>
                          )
                        }
                      )}
                    />
                    {useCustomTally &&
                      customAttributesSelected.includes(attribute.name) && (
                        <CustomAttribute
                          attributeName={attribute.name}
                          product={productForWatchlistModal}
                          handleUpdateCustomValues={handleUpdateCustomValues}
                        />
                      )}
                  </>
                )
              })}
          </div>
          <div
            style={{
              height: '50px',
              display: 'flex',
              flexDirection: 'row',
              flex: '0 1',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                width: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              color="grey"
              onClick={() => handleWatchlistSubmit(false)}
              disabled={
                Object.keys(attributeSelectionsFromDropdown).length !==
                attributesForSelectedProduct?.length
              }
              clearable
            >
              {!submitLoading && (loadingNextModal || watchlistSubmitting) && (
                <Loader size="tiny" inline="centered" />
              )}
              {(submitLoading || (!loadingNextModal && !watchlistSubmitting)) &&
                'Submit And Add Another'}
            </Button>
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                width: '50%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              color="primary"
              onClick={() => handleWatchlistSubmit(true)}
              disabled={
                Object.keys(attributeSelectionsFromDropdown).length !==
                attributesForSelectedProduct?.length
              }
              clearable
            >
              {submitLoading && (loadingNextModal || watchlistSubmitting) && (
                <Loader size="tiny" inline="centered" />
              )}
              {(!submitLoading ||
                (!loadingNextModal && !watchlistSubmitting)) &&
                'Submit'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default AttributesModal
