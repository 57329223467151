import React from 'react'
import { Grid } from 'semantic-ui-react'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import ESignTemplateRow from './ESignTemplateRow'

const ESignTemplatesTable = (props) => {
  const { reload, hideHeader } = props
  return (
    <>
      {!hideHeader && (
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={'Document Generation Templates'}
              showDivider
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={16}>
          <MickeyTable
            selectable={true}
            headerRow={['Name', 'Email Subject', 'Visibility', '']}
            urlParams={{
              e_sign_templates: true,
            }}
            reload={reload}
            basicSegment
            url={'/main/box-items/'}
            renderBodyRow={(data, index) => {
              return <ESignTemplateRow boxItem={data} key={index} />
            }}
            emptyIcon={'file'}
            emptyMessage={'No document generation templates'}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default ESignTemplatesTable
