import {
  emailValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const TruckingUserForm = {
  model: 'CustomUser',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          type: 'text',
        },
        last_name: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          type: 'text',
          validator: (input) => {
            return emailValidator(input)
          },
        },
        phone: {
          type: 'phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        trucking_company: {
          required: true,
          label: 'Trucking Company',
          type: 'dropdown search',
          url: '/main/list-trucking-companies/',
          title_fields: ['name'],
          description_fields: ['type'],
        },
      },
    },
  ],
}
