import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useAxios from '../../../lib/auth/useAxios'

const AdminNewsRow = (props) => {
  const axios = useAxios()
  const [openEditArticleModal, setOpenEditArticleModal] = useState(false)
  const [openLogoModal, setOpenLogoModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)

  const handleClose = () => {
    setOpenEditArticleModal(false)
    props.setReload(true)
  }

  const deleteArticle = async () => {
    await axios.delete(`/main/news/${props.news.id}/`)
    props.setReload(true)
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell width={2}>{props?.news?.title}</Table.Cell>
        <Table.Cell width={2}>{props?.news?.publish_date}</Table.Cell>
        <Table.Cell width={2}>{props?.news?.created_at}</Table.Cell>
        <Table.Cell width={2}>{props.news.article_link}</Table.Cell>
        <Table.Cell>{props.news.description}</Table.Cell>
        <Table.Cell>
          <Button
            className={'news-logo-btn'}
            icon="file image"
            onClick={() => setOpenLogoModal(true)}
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Button
            color="grey"
            icon="edit"
            onClick={() => setOpenEditArticleModal(true)}
          />
          <Button
            color="red"
            icon="x"
            onClick={() => setConfirmationModal(true)}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => handleClose()}
        onOpen={() => setOpenEditArticleModal(true)}
        open={openEditArticleModal}
      >
        <Modal.Header>Edit News Article</Modal.Header>
        <Modal.Content>
          <MickeyForm
            instanceId={props.news.id}
            formName={'NewsForm'}
            tenantAware={false}
            model={'News'}
            buttonFloatedRight={true}
            successMessage={'News Article Added!'}
            failureMessage={'Error adding news.'}
            url={'/main/news/'}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size={'large'}
        open={openLogoModal}
        onClose={() => setOpenLogoModal(false)}
        onOpen={() => setOpenLogoModal(true)}
      >
        <Modal.Content style={{ padding: '0' }}>
          <img style={{ width: '100%' }} src={props.news.logo} />
        </Modal.Content>
      </Modal>
      <ConfirmationModal
        setConfirmationModal={setConfirmationModal}
        confirmationModal={confirmationModal}
        yesFunction={deleteArticle}
        noFunction={() => setConfirmationModal(false)}
        archiveItem={'this news article'}
      />
    </>
  )
}

export default AdminNewsRow
