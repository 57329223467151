import { useState } from 'react'
import PurchaseOrders from '../../../components/PurchaseOrders/PurchaseOrders'

function AdminPurchaseOrders(props) {
  const [openAddPurchaseOrderModal, setOpenAddPurchaseOrderModal] =
    useState(false)

  return (
    <div style={{ margin: '25px' }}>
      <PurchaseOrders
        openAddPurchaseOrderModal={openAddPurchaseOrderModal}
        setOpenAddPurchaseOrderModal={setOpenAddPurchaseOrderModal}
      />
    </div>
  )
}

export default AdminPurchaseOrders
