import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { GeoJson, Map, Marker, GeoJsonFeature } from 'pigeon-maps'
import env from '../../../env'
import { Segment } from 'semantic-ui-react'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'

function BuyerOrderMap(props) {
  const axios = useAxios()
  const [loading, setLoading] = useState(false)
  const [currentLocationCoordinates, setCurrentLocationCoordinates] =
    useState(null)
  const [stopsCoordinates, setStopsCoordinates] = useState(null)
  const [zoom, setZoom] = useState(9)

  useEffect(() => {
    if (currentLocationCoordinates?.length !== 2) return
  }, [currentLocationCoordinates])

  function getCenterOfMap() {
    if (currentLocationCoordinates) {
      return currentLocationCoordinates
    } else {
      return [37.0902, -95.7129]
    }
  }

  function calculateDistance(point1, point2) {
    const earthRadius = 3958.8 // miles
    const lat1 = point1[0]
    const lng1 = point1[1]
    const lat2 = point2[0]
    const lng2 = point2[1]

    const dLat = ((lat2 - lat1) * Math.PI) / 180
    const dLng = ((lng2 - lng1) * Math.PI) / 180

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return earthRadius * c
  }

  function calculateZoom() {
    let zoom
    let a = stopsCoordinates[0]
    let b = stopsCoordinates[1]
    let distance = calculateDistance(a, b)

    if (distance < 100) {
      zoom = 9
    } else if (distance < 200) {
      zoom = 8
    } else if (distance < 500) {
      zoom = 7
    } else if (distance < 1000) {
      zoom = 6
    } else if (distance < 2000) {
      zoom = 5
    } else if (distance < 3000) {
      zoom = 4
    } else if (distance < 4000) {
      zoom = 3
    } else if (distance < 5000) {
      zoom = 2
    } else {
      zoom = 1
    }

    return zoom
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      let res = await axios.get(`/main/trucking-status/`, {
        params: {
          cargo_unit_instance_id: props.cargoUnitInstance?.id,
          buyer_id: props.buyer?.id,
          supplier_id: props.supplier?.id,
        },
      })
      setLoading(false)
      if (res?.data) {
        setStopsCoordinates(res.data.stops_coordinates)
        let avgLat = 0
        let avgLong = 0
        res.data.stops_coordinates?.forEach((coordinate) => {
          avgLat += coordinate[0]
          avgLong += coordinate[1]
        })

        avgLat = avgLat / res.data.stops_coordinates?.length
        avgLong = avgLong / res.data.stops_coordinates?.length
        setCurrentLocationCoordinates([avgLat, avgLong])
      }
    }
    if (props.cargoUnitInstance?.id) {
      loadData()
    }
  }, [props.cargoUnitInstance?.id])

  return (
    <div loading={loading} style={{ width: '100%', zIndex: -1 }}>
      {(!currentLocationCoordinates || !stopsCoordinates) && (
        <MickeyPlaceholderLoading />
      )}
      {currentLocationCoordinates && stopsCoordinates && (
        <Map
          height={600}
          defaultCenter={getCenterOfMap()}
          defaultZoom={calculateZoom()}
          metaWheelZoom
        >
          {stopsCoordinates?.length > 0 &&
            stopsCoordinates?.map((stop) => {
              return (
                <Marker position={stop} color={env.REACT_APP_PRIMARY_COLOR} />
              )
            })}
          <GeoJson
            svgAttributes={{
              strokeWidth: '3',
              stroke: env.REACT_APP_PRIMARY_COLOR,
            }}
          >
            <GeoJsonFeature
              feature={{
                type: 'Feature',
                properties: { prop0: 'value0' },
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [stopsCoordinates[0][1], stopsCoordinates[0][0]],
                    [stopsCoordinates[1][1], stopsCoordinates[1][0]],
                  ],
                },
              }}
            />
          </GeoJson>
        </Map>
      )}
    </div>
  )
}

export default BuyerOrderMap
