import React, { useState } from 'react'
import { Table, Button, Popup, Menu, Label } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import ConfirmationModal from '../../../components/ConfirmationModal'

function AdminOrdersRow(props) {
  const windowWidth = useWindowDimensions()
  const user = useSelector((state) => state.user.user)
  const [openArchiveConfirmationModal, setOpenArchiveConfirmationModal] =
    useState(false)
  const order = props.order
  let supplierAccepted = false
  order.order_items?.forEach((orderItem) => {
    if (!supplierAccepted && orderItem.supplier_accepted) {
      supplierAccepted = true
    }
  })
  console.log(props)
  const navigate = useNavigate()
  const axios = useAxios()

  async function archiveOrder() {
    setOpenArchiveConfirmationModal(false)
    let res = await axios.delete(
      '/main/admin-orders/' + order.string_token + '/'
    )
    if (res.status < 400) {
      toast.success('Deal archived')
      props.setReload(true)
    } else {
      toast.error('Error archiving deal')
    }
  }
  const handleClick = (e, d) => {
    navigate(`/portal/admin/deals/${order?.string_token}/`)
  }

  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell onClick={handleClick}>{order?.scrapgo_status}</Table.Cell>
        <Table.Cell onClick={handleClick}>
          {order?.mobile_app ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell style={{ paddingLeft: '20px' }} onClick={handleClick}>
          {order?.order_number}
        </Table.Cell>
        <Table.Cell onClick={handleClick}>{order?.order_date}</Table.Cell>
        <Table.Cell onClick={handleClick}>
          {order?.supplier_businesses?.length > 0
            ? order?.supplier_businesses?.map((name) => {
                return <p key={name}>{name}</p>
              })
            : order?.supplier_business?.name}
        </Table.Cell>
        <Table.Cell onClick={handleClick}>
          {order?.buyer_business?.name}
        </Table.Cell>
        <Table.Cell onClick={handleClick}>
          {order?.admin_order_status === 1 && <>Logistics Booked</>}
          {order?.admin_order_status === 2 && <>Shipped</>}
          {order?.admin_order_status === 3 && <>Delivered</>}
        </Table.Cell>
        <Table.Cell
          onClick={handleClick}
          positive={order?.paid_supplier && order?.paid}
          warning={order?.paid}
        >
          {order?.payment_requested && !order.paid && (
            <Label
              size={'mini'}
              color={'yellow'}
              content={'Requested Payment'}
            />
          )}
          {order?.paid_supplier && (
            <Label size={'mini'} color={'red'} content={'Paid Supplier'} />
          )}
          {order?.paid && (
            <Label size={'mini'} color={'green'} content={'Buyer Paid'} />
          )}
        </Table.Cell>
        {hasPermission(user, 'Delete Order') && (
          <Table.Cell
            width={1}
            textAlign={windowWidth.width > 765 ? 'right' : 'left'}
          >
            <Button
              circular
              color="red"
              content={'Archive'}
              size={'mini'}
              onClick={() => setOpenArchiveConfirmationModal(true)}
            />
            <ConfirmationModal
              message={`Are you sure you want to archive this deal?`}
              confirmationModal={openArchiveConfirmationModal}
              setConfirmationModal={setOpenArchiveConfirmationModal}
              yesFunction={() => archiveOrder()}
              noFunction={() => setOpenArchiveConfirmationModal(false)}
            />
          </Table.Cell>
        )}
      </Table.Row>
    </>
  )
}

export default AdminOrdersRow
