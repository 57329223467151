import { Grid, Table, Form } from 'semantic-ui-react'
import { useState } from 'react'
import MickeyTable from '../MickeyTable/MickeyTable'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import useAxios from '../../lib/auth/useAxios'
import { toast } from 'react-toastify'

function OrderItemPriceOffers(props) {
  const { orderItemId } = props
  const [reload, setReload] = useState(false)
  const [counterOffer, setCounterOffer] = useState()
  const axios = useAxios()

  const createCounterOffer = async (orderItemId) => {
    if (!counterOffer || isNaN(counterOffer) || counterOffer <= 0) {
      toast.error('Counter offer greater than zero required')
    } else {
      await axios.post(`/main/order-item-price-offers/`, {
        order_item_id: orderItemId,
        price: counterOffer,
      })
      toast.success('Counter offer sent to supplier')
      setCounterOffer()
      setReload(true)
      setReload(false)
    }
  }

  const updateCounterOffer = async (orderItemPriceOfferId) => {
    if (!counterOffer || isNaN(counterOffer) || counterOffer <= 0) {
      toast.error('Counter offer must be greater than zero required')
    } else {
      await axios.put(
        `/main/order-item-price-offers/${orderItemPriceOfferId}/`,
        {
          price: counterOffer,
        }
      )
      toast.success('Updated counter offer sent to supplier')
      setCounterOffer()
      setReload(true)
      setReload(false)
    }
  }

  const deleteCounterOffer = async (orderItemPriceOfferId) => {
    await axios.delete(
      `/main/order-item-price-offers/${orderItemPriceOfferId}/`
    )
    setReload(true)
    setReload(false)
  }

  const acceptPriceOffer = async (orderItemPriceOfferId) => {
    await axios.put(`/main/order-item-price-offers/${orderItemPriceOfferId}/`, {
      accepted_by_scrapgo: true,
    })
    setReload(true)
    setReload(false)
  }

  const unAcceptPriceOffer = async (orderItemPriceOfferId) => {
    await axios.put(`/main/order-item-price-offers/${orderItemPriceOfferId}/`, {
      accepted_by_scrapgo: false,
    })
    setReload(true)
    setReload(false)
  }

  const urlParams = {}
  if (orderItemId) {
    urlParams.order_item_id = orderItemId
  }
  const headerRow = [
    'Created At',
    'Created By',
    'Price',
    'Accepted By Scrapgo',
    'Accepted By Supplier',
    '',
    '',
  ]
  //   const urlParams = business ? { business_id: business?.id } : {}
  //   const headerSearch = [
  //     {
  //       header: 'Sales Order Number',
  //       type: 'search',
  //       input_type: 'number',
  //       search_param: 'sales_order_number',
  //     },
  //     {
  //       header: 'Total Amount',
  //       type: 'number_range',
  //       search_param_start: 'total_amount_start',
  //       search_param_end: 'total_amount_end',
  //     },
  //   ]
  //   const headerSort = [
  //     {
  //       header: 'Sales Order Number',
  //       sort_by: 'sales_order_number',
  //     },
  //     {
  //       header: 'Total Amount',
  //       sort_by: 'total_amount',
  //     },
  //   ]
  //   if (!hideBusiness) {
  //     headerRow.splice(5, 0, 'Buyer')
  //     headerSort.splice(5, 0, {
  //       header: 'Buyer',
  //       sort_by: 'buyer__business__name',
  //     })
  //     headerSearch.splice(5, 0, {
  //       header: 'Buyer',
  //       type: 'dropdown',
  //       options_url: 'businesses/',
  //       options_params: {
  //         extra_light_serializer: 'true',
  //         all_businesses: 'true',
  //       },
  //       options_result: 'name',
  //       search_param: 'business_id',
  //     })
  //   }

  return (
    <div className={'main-container'}>
      <Grid stackable>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={headerRow}
              basicSegment
              urlParams={urlParams}
              url={'/main/order-item-price-offers/'}
              hideFooter={false}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                const supplierCreated =
                  data.created_by.groups[0].name === 'Supplier'
                return (
                  <Table.Row>
                    <Table.Cell>{data.created_at}</Table.Cell>
                    <Table.Cell>
                      {data.created_by.first_name} {data.created_by.last_name}{' '}
                      {supplierCreated ? '(Supplier}' : '(ScrapGo)'}
                    </Table.Cell>
                    <Table.Cell>
                      ${data.price}/
                      {data.order_item.unit.short_name.toUpperCase()}
                    </Table.Cell>
                    <Table.Cell>
                      {index === 0 && (
                        <>
                          {data.accepted_by_scrapgo ? (
                            <>
                              Yes{' '}
                              {data.accepted_by_scrapgo_user && (
                                <>
                                  ({data.accepted_by_scrapgo_user.first_name}{' '}
                                  {data.accepted_by_scrapgo_user.last_name})
                                </>
                              )}
                            </>
                          ) : (
                            'No'
                          )}
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {index === 0 && (
                        <>
                          {data.accepted_by_supplier ? (
                            <>
                              Yes ({data.accepted_by_supplier_user?.first_name}{' '}
                              {data.accepted_by_supplier_user?.last_name})
                            </>
                          ) : (
                            'No'
                          )}
                        </>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {index === 0 && (
                        <>
                          {data.created_by_type === 'scrapgo' && (
                            <>
                              <div>
                                <button
                                  style={{
                                    marginLeft: '5px',
                                    marginBottom: '10px',
                                    backgroundColor: 'transparent',
                                    color: 'red',
                                    cursor: 'pointer',
                                    padding: '0',
                                    fontWeight: '600',
                                  }}
                                  onClick={() => {
                                    deleteCounterOffer(data.id)
                                  }}
                                >
                                  Delete Counter Offer
                                </button>
                              </div>
                              <div>
                                <button
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: 'green',
                                    marginLeft: '5px',
                                    cursor: 'pointer',
                                    padding: '0',
                                    fontWeight: '600',
                                  }}
                                  onClick={() => {
                                    updateCounterOffer(data.id)
                                  }}
                                >
                                  Update Counter Offer
                                </button>
                                <Form.Input
                                  placeholder={'Counter Offer'}
                                  type={'number'}
                                  value={counterOffer}
                                  onChange={(e, d) => {
                                    setCounterOffer(d.value)
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {data.created_by_type === 'supplier' && (
                            <>
                              {data.accepted_by_scrapgo && (
                                <>
                                  <button
                                    style={{
                                      marginLeft: '10px',
                                      backgroundColor: 'transparent',
                                      color: 'red',
                                      cursor: 'pointer',
                                      padding: '0',
                                      fontWeight: '600',
                                    }}
                                    onClick={() => {
                                      unAcceptPriceOffer(data.id)
                                    }}
                                  >
                                    Unaccept
                                  </button>
                                </>
                              )}
                              {!data.accepted_by_scrapgo && (
                                <>
                                  <div>
                                    <button
                                      style={{
                                        backgroundColor: 'transparent',
                                        color: 'green',
                                        cursor: 'pointer',
                                        marginBottom: '10px',
                                        padding: '0',
                                        fontWeight: '600',
                                      }}
                                      onClick={() => {
                                        acceptPriceOffer(data.id)
                                      }}
                                    >
                                      Accept Offer
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      style={{
                                        backgroundColor: 'transparent',
                                        color: 'red',
                                        cursor: 'pointer',
                                        padding: '0',
                                        fontWeight: '600',
                                      }}
                                      onClick={() => {
                                        createCounterOffer(data.order_item.id)
                                      }}
                                    >
                                      Send Counter Offer
                                    </button>
                                    <Form.Input
                                      placeholder={'Counter Offer'}
                                      type={'number'}
                                      value={counterOffer}
                                      onChange={(e, d) => {
                                        setCounterOffer(d.value)
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'No Supplier Price Negotiation'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default OrderItemPriceOffers
