import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Icon,
} from 'semantic-ui-react'
import './Login.css'
import { useAuth } from '../../../../lib/auth/useAuth'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import env from '../../../../env'
import {
  addUser,
  addBusiness,
  addBuyer,
  addSupplier,
  addLocations,
  loggedIn,
} from '../../../../features/user/userSlice'
import useAxios from '../../../../lib/auth/useAxios'
import { Link, useNavigate } from 'react-router-dom'
import { objFull, inGroup } from '../../../../lib/utils/utils'
import { toast } from 'react-toastify'
import qs from 'qs'
import { useWebsocket } from '../../../../hooks/useWebsocket'

function Login(props) {
  // State
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  // Redux state
  const user = useSelector((state) => state.user.user)
  // hooks
  const auth = useAuth()
  const axios = useAxios()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { resetWs } = useWebsocket()
  let [searchParams] = useSearchParams()
  // vars
  const next = searchParams.get('next')

  useEffect(() => {
    if (user && objFull(user)) {
      if (next) {
        navigate(next)
      } else if (user.is_god) {
        navigate('/portal/admin')
      } else if (user?.business?.primary_business === 'Buyer') {
        navigate('/portal/buyer')
      } else if (user?.business?.primary_business === 'Supplier') {
        navigate('/portal/supplier')
      }
    }
  }, [user])

  return (
    <div id="login">
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: '500px' }}>
          <Segment
            className="main-background-texture"
            basic
            style={{ padding: '10px 54px' }}
          >
            <Image
              centered
              src={env.REACT_APP_LOGO}
              alt="Logo"
              style={{ marginTop: '20px', maxWidth: '200px', height: 'auto' }}
            />
            <Header
              textAlign="center"
              style={{
                color: '#000',
                fontSize: '24px',
                margin: '45px auto',
                letterSpacing: '1px',
              }}
            >
              Sign in to {env.REACT_APP_BUSINESS_NAME}
            </Header>
            <Form
              size="large"
              onSubmit={async () => {
                setLoading(true)
                const response = await auth.signin(username, password)
                if (response?.data?.user) {
                  const toastsToDelete = []
                  response.data.user.pending_toasts.forEach((pendingToast) => {
                    if (pendingToast.toast_type === 'trade-match') {
                      // cant store emojis in SQL
                      toast.success(`🎉  ${pendingToast.toast}`)
                      toastsToDelete.push(pendingToast.id)
                    } else {
                      toast.success(pendingToast.toast)
                      toastsToDelete.push(pendingToast.id)
                    }
                  })
                  if (toastsToDelete.length > 0) {
                    await axios.delete('main/delete-pending-toasts/', {
                      params: {
                        ids: [toastsToDelete],
                      },
                      paramsSerializer: (params) => {
                        return qs.stringify(params)
                      },
                    })
                  }
                  dispatch(loggedIn())
                  const user = response.data.user
                  dispatch(addUser(user))
                  if (response.data.user?.business) {
                    dispatch(addBusiness(response.data.user.business))
                  }
                  if (response.data.user?.business?.locations) {
                    dispatch(
                      addLocations(response.data.user.business.locations)
                    )
                  }
                  if (response.data.user?.business?.buyer) {
                    dispatch(addBuyer(response.data.user?.business.buyer))
                  }
                  if (response.data.user?.business?.supplier) {
                    dispatch(
                      addSupplier(response.data.user?.business?.supplier)
                    )
                  }
                  resetWs()
                  if (next) {
                    navigate(next)
                  } else if (
                    inGroup(user, [
                      'Admin',
                      'Development',
                      'Sales Manager',
                      'Sales Associate',
                      'Trucking Manager',
                      'Trucking Agent',
                      'Pricing Admin',
                      'Customer Service',
                    ])
                  ) {
                    navigate('/portal/admin')
                  } else if (inGroup(user, 'Buyer')) {
                    if (response.data.user.business.completed_profile) {
                      navigate('/portal/buyer')
                    } else {
                      navigate('/portal/buyer/profile')
                    }
                  } else if (inGroup(user, 'Supplier')) {
                    if (response.data.user.business.completed_profile) {
                      navigate('/portal/supplier')
                    } else {
                      navigate('/portal/supplier/profile')
                    }
                  }
                }
                setLoading(false)
              }}
            >
              <Form.Input
                inverted
                fluid
                placeholder="Email"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={{ margin: '40px auto', borderRadius: '5px' }}
              />
              <Form.Input
                fluid
                placeholder="Password"
                type={visible ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginTop: '10px' }}
                icon={
                  password ? (
                    <Icon
                      name={visible ? 'eye' : 'eye slash'}
                      link
                      onClick={(e, d) => {
                        setVisible(!visible)
                      }}
                    />
                  ) : (
                    ''
                  )
                }
                iconPosition={'right'}
              />
              <Message
                error
                visible={auth.error}
                header="Incorrect Username or Password"
                content="Please try again."
              />
              <div>
                <a
                  style={{
                    color: 'rgb(241 89 42)',
                    fontSize: '12px',
                    float: 'left',
                    marginBottom: '40px',
                    cursor: 'pointer',
                  }}
                  href={'/reset-password'}
                >
                  Forgot Password?
                </a>
              </div>

              <Button
                color={'primary'}
                loading={loading}
                className={'login-btn'}
                fluid
                size="large"
                type="submit"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
              >
                Sign In
              </Button>
            </Form>

            <Link to="/apply">
              <Button
                style={{
                  border: 'none !important',
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                className="signup-btn"
              >
                Create New Account
              </Button>
            </Link>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default Login
