import { useEffect, useState } from 'react'
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Modal,
  Popup,
  Segment,
} from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import InventoryTable from '../../views/supplier/Inventory/InventoryTable'
import { ResponsiveLine } from '@nivo/line'
import { formatTotalPrice } from '../../lib/utils/utils'
import { timeFormat } from 'd3-time-format'
import _ from 'underscore'
import SelectProductAttribute from './SelectProductAttribute'
import { sub } from 'date-fns'

function PriceHistoryGraph(props) {
  const {
    side,
    timeWindow,
    setTimeWindow,
    newProductVarientModel,
    setNewProductVarientModel,
    selectedProducts,
    setSelectedProducts,
    productVariant,
    setProductVariant,
  } = props

  const axios = useAxios()
  const [itemWidthValue, setItemWidthValue] = useState(0)
  const [chartData, setChartData] = useState([])
  const [startDate, setStartDate] = useState()

  const getStartDateFromTimeWindow = (tw, currentDate) => {
    let timeDelta = null
    if (tw === '2-weeks') {
      timeDelta = { days: 14 }
    } else if (tw === 'quarter') {
      timeDelta = { days: 90 }
    } else if (tw === '6-months') {
      timeDelta = { days: 180 }
    } else if (tw === 'year') {
      timeDelta = { days: 365 }
    }
    if (timeDelta) {
      return sub(currentDate, timeDelta)
    }
    return null
  }

  useEffect(() => {
    if (timeWindow) {
      let start_date = getStartDateFromTimeWindow()
      setStartDate(start_date)
    }
  }, [timeWindow])

  function getTextWidth(text) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const width = context.measureText(text).width
    return width
  }

  const getChartData = (data) => {
    let copyChartData = []
    let itemLength = 0

    data?.results?.forEach((result) => {
      let formatedData = _.map(result?.product_data, (d, index) => {
        let date = new Date(d['created_at']).toLocaleDateString()
        return {
          x: date,
          y: d['price'],
        }
      })
      let dataSet = {
        id: result['product_name'],
        data: formatedData,
      }
      itemLength = Math.max(getTextWidth(result['product_name']), itemLength)
      copyChartData = copyChartData ? [...copyChartData, dataSet] : [dataSet]
    })
    setItemWidthValue(itemLength + 65)
    setChartData(copyChartData)
  }

  const getPriceHistoryData = async () => {
    let res = await axios.get(
      `/main/exchange-order-price-history/get_eoh_chart_data/`,
      {
        params: {
          side: side,
          product_list: selectedProducts,
          product_variant_list: productVariant,
          time_window: timeWindow,
        },
      }
    )
    if (res?.data) {
      getChartData(res?.data)
    }
  }

  useEffect(() => {
    if (selectedProducts?.length || productVariant?.length) {
      getPriceHistoryData()
    }
  }, [side, selectedProducts, productVariant, timeWindow])

  return (
    <Grid className="price-history-graph">
      <Grid.Row className={'no-top-padding'}>
        <Grid.Column>
          {!(selectedProducts?.length || productVariant?.length) && (
            <Segment className="graph-segment">
              <Header icon>
                <Icon name="line graph" />
                Add products to display graph.
              </Header>
            </Segment>
          )}
          {(selectedProducts?.length || productVariant?.length > 0) && (
            <div>
              <ResponsiveLine
                data={chartData}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{
                  type: 'time',
                  format: '%d/%m/%Y',
                  min: getStartDateFromTimeWindow(timeWindow, new Date()),
                  max: new Date().toLocaleDateString(),
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: false,
                  reverse: false,
                }}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  format: '%b %d, %Y',
                  tickValues: `every ${
                    timeWindow === '2-weeks'
                      ? '1 day'
                      : timeWindow === 'quarter'
                      ? '5 day'
                      : timeWindow === '6-months'
                      ? '15 day'
                      : timeWindow === 'year'
                      ? '25 day'
                      : '1 month'
                  }`,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle',
                  format: (value) => `$${value}`,
                }}
                enableGridX={false}
                colors={{ scheme: 'red_grey' }}
                lineWidth={3}
                enablePoints={false}
                pointSize={8}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableArea={true}
                areaOpacity={0.1}
                useMesh={true}
                tooltip={({ point }) => (
                  <div className="tooltip-div">
                    <i style={{ background: point.borderColor }} />
                    <span>Date: {timeFormat('%d-%m-%Y')(point.data.x)},</span>
                    <span>Price: {formatTotalPrice(point.data.y)}</span>
                  </div>
                )}
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemWidth: itemWidthValue,
                    itemHeight: 10,
                    itemsSpacing: 0,
                    symbolSize: 10,
                    symbolShape: 'square',
                    itemDirection: 'left-to-right',
                    itemTextColor: '#777',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                motionConfig="default"
              />
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default PriceHistoryGraph
