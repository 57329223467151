import {
  emailValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const ContactForm = {
  model: 'Contact',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          type: 'text',
        },
        last_name: {
          type: 'text',
        },
        company_name: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          type: 'text',
          validator: (input) => {
            return emailValidator(input)
          },
        },
        phone: {
          type: 'phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
      },
    },
  ],
}
