import React, { useState } from 'react'
import { Modal, Grid, Dropdown, Button, Form } from 'semantic-ui-react'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const AdminOrderDocumentGenerationModal = (props) => {
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const { order, showModal, toggleModal } = props
  const [document, setDocument] = useState()
  const [offsiteDeliveryAddressLineOne, setoffsiteDeliveryAddressLineOne] =
    useState('')
  const [offsiteDeliveryAddressLineTwo, setoffsiteDeliveryAddressLineTwo] =
    useState('')
  const [offsiteDeliveryAddressCity, setoffsiteDeliveryAddressCity] =
    useState('')
  const [offsiteDeliveryAddressState, setoffsiteDeliveryAddressState] =
    useState('')
  const [offsiteDeliveryAddressZip, setoffsiteDeliveryAddressZip] = useState('')
  const axios = useAxios()

  const sendBoxSignRequest = async (email) => {
    let errors = false
    if (
      email &&
      ['project_information_sheet', 'offsite_delivery_agreement'].includes(
        document
      ) &&
      !order.buyer.business.contact
    ) {
      toast.error(
        `${order.buyer.business.name} requires a contact user to send files for signature`
      )
      errors = true
    } else if (email && document === 'joint_check_agreement') {
      if (
        !order.gc_company_contact_email ||
        !order.framer_subcontractor_contact_email
      ) {
        toast.error(
          'Please add general contacts and subcontractor contant name and email'
        )
        errors = true
      }
    }
    if (document === 'offsite_delivery_agreement') {
      if (!offsiteDeliveryAddressLineOne) {
        toast.error('Offiste delivery address line one required')
      } else if (!offsiteDeliveryAddressCity) {
        toast.error('Offiste delivery address city required')
      } else if (!offsiteDeliveryAddressState) {
        toast.error('Offiste delivery address state required')
      } else if (!offsiteDeliveryAddressZip) {
        toast.error('Offiste delivery address zip code required')
      }
    }
    if (!errors) {
      const res = await axios.post(`/main/box-sign-requests/`, {
        file_type: document,
        order_id: order?.id,
        send_to_customer: email,
        offsite_delivery_agreement_delivery_location_address_line_one:
          offsiteDeliveryAddressLineOne,
        offsite_delivery_agreement_delivery_location_address_line_two:
          offsiteDeliveryAddressLineTwo,
        offsite_delivery_agreement_delivery_location_address_city:
          offsiteDeliveryAddressCity,
        offsite_delivery_agreement_delivery_location_address_state:
          offsiteDeliveryAddressState,
        offsite_delivery_agreement_delivery_location_address_zip:
          offsiteDeliveryAddressZip,
      })
      if (res.data.success) {
        if (document === 'offsite_delivery_agreement') {
          toast.success(
            `Offsite delivery agreement generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
          )
        } else if (document === 'joint_check_agreement') {
          toast.success(
            `Joint check agreement generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
          )
        } else if (document === 'project_information_sheet') {
          toast.success(
            `Project information sheet generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
          )
        }
      } else {
        toast.error('Error generating document')
      }
    }
  }

  return (
    <>
      <Modal
        onClose={() => {
          toggleModal()
          setDocument()
          setoffsiteDeliveryAddressLineOne('')
          setoffsiteDeliveryAddressLineTwo('')
          setoffsiteDeliveryAddressCity('')
          setoffsiteDeliveryAddressState('')
          setoffsiteDeliveryAddressZip('')
        }}
        open={showModal}
        size={document === 'offsite_delivery_agreement' ? 'medium' : 'small'}
      >
        <Modal.Header>
          <Grid.Row>
            <Grid.Column>Document Generation</Grid.Column>
          </Grid.Row>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8} style={{ marginRight: '20px' }}>
                  <label>Select Document</label>
                  <Dropdown
                    placeholder={'Select Document'}
                    fluid
                    selection
                    value={document}
                    options={[
                      {
                        text: 'Project Information Sheet',
                        value: 'project_information_sheet',
                        key: 'project_information_sheet',
                        content: <>Project Information Sheet</>,
                      },
                      {
                        text: 'Offsite Delivery Agreement',
                        value: 'offsite_delivery_agreement',
                        key: 'offsite_delivery_agreement',
                        content: <>Offsite Delivery Agreement</>,
                      },
                      {
                        text: 'Joint Check Agreement',
                        value: 'joint_check_agreement',
                        key: 'joint_check_agreement',
                        content: <>Joint Check Agreement</>,
                      },
                    ]}
                    onChange={(_, d) => {
                      setDocument(d.value)
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              {document === 'offsite_delivery_agreement' && (
                <>
                  <Grid.Row>
                    <Grid.Column>Offsite Delivery Location</Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Form.Group>
                        <Form.Field width={4}>
                          <label>Address Line One</label>
                          <Form.Input
                            placeholder={'Address Line One'}
                            value={offsiteDeliveryAddressLineOne}
                            onChange={(_, d) => {
                              setoffsiteDeliveryAddressLineOne(d.value)
                            }}
                          />
                        </Form.Field>
                        <Form.Field width={4}>
                          <label>Address Line Two</label>
                          <Form.Input
                            placeholder={'Address Line Two'}
                            value={offsiteDeliveryAddressLineTwo}
                            onChange={(_, d) => {
                              setoffsiteDeliveryAddressLineTwo(d.value)
                            }}
                          />
                        </Form.Field>
                        <Form.Field width={4}>
                          <label>Address City</label>
                          <Form.Input
                            placeholder={'Address City'}
                            value={offsiteDeliveryAddressCity}
                            onChange={(_, d) => {
                              setoffsiteDeliveryAddressCity(d.value)
                            }}
                          />
                        </Form.Field>
                        <Form.Field width={4}>
                          <label>Address State</label>
                          <Form.Input
                            placeholder={'Address State'}
                            value={offsiteDeliveryAddressState}
                            onChange={(_, d) => {
                              setoffsiteDeliveryAddressState(d.value)
                            }}
                          />
                        </Form.Field>
                        <Form.Field width={4}>
                          <label>Address Zip Code</label>
                          <Form.Input
                            placeholder={'Address Zip Code'}
                            value={offsiteDeliveryAddressZip}
                            onChange={(_, d) => {
                              setoffsiteDeliveryAddressZip(d.value)
                            }}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              toggleModal()
              setDocument()
              setoffsiteDeliveryAddressLineOne('')
              setoffsiteDeliveryAddressLineTwo('')
              setoffsiteDeliveryAddressCity('')
              setoffsiteDeliveryAddressState('')
              setoffsiteDeliveryAddressZip('')
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!document}
            type="submit"
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            onClick={() => {
              if (document) {
                sendBoxSignRequest(false)
              }
            }}
          >
            Create
          </Button>
          <Button
            type="submit"
            disabled={!document}
            color="primary"
            icon="paper plane"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            onClick={() => {
              if (document) {
                sendBoxSignRequest(true)
              }
            }}
            content={'Create & Send'}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminOrderDocumentGenerationModal
