import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Message, Table, Modal, Segment, Menu } from 'semantic-ui-react'
import { useParams } from 'react-router-dom'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import BuyerOrderItemsRow from './BuyerOrderItemsRow'
import BuyerOrderDocuments from './BuyerOrderDocuments'
import PizzaTracker from '../../shared/PizzaTracker/PizzaTracker'
import BuyerOrderMap from './BuyerOrderMap'
import { useSelector } from 'react-redux'
import { formatOrderNumber, formatTotalPrice } from '../../../lib/utils/utils'
import './BuyerOrder.css'
import MickeyForm from '../../../components/Forms/MickeyForm'
import BuyerOrderLedgerRow from './BuyerOrderLedgerRow'
import UploadInfo from '../../shared/UploadInfo/UploadInfo'
import env from '../../../env'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import BuyerOrderFulfillmentRow from './BuyerOrderFulfillmentRow'
import InventoryTable from '../../supplier/Inventory/InventoryTable'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import { toast } from 'react-toastify'
import { hasPermission } from '../../../lib/utils/utils'
import { Info, Plus } from 'phosphor-react'

function BuyerOrder(props) {
  const axios = useAxios()
  const [order, setOrder] = useState(false)
  const [orderItem, setOrderItem] = useState(false)
  const [reload, setReload] = useState()
  const [reloadPopup, setReloadPopup] = useState(false)
  const [extraLineItems, setExtraLineItems] = useState([])
  const { orderId } = useParams()
  const user = useSelector((state) => state.user.user)
  const business = user.business
  const window = useWindowDimensions()
  const buyer = useSelector((state) => state.user.buyer)
  const [loading, setLoading] = useState(false)
  const [allItemsShipped, setAllItemsShipped] = useState(true)
  const [reloadOrder, setReloadOrder] = useState(false)
  const [activeButton, setActiveButton] = useState('Order Details')
  const [ledgerItemsTotal, setLedgerItemsTotal] = useState(0)
  const [orderLedgerItems, setOrderLedgerItems] = useState([])
  const [openUploadInformationModal, setOpenUploadInformationModal] =
    useState(false)
  const [reloadFulfillment, setReloadFulfillment] = useState(false)
  const [openDocumentForm, setOpenDocumentForm] = useState(false)
  const [openRequestForm, setOpenRequestForm] = useState(false)
  const [buyerTotal, setBuyerTotal] = useState(0)
  const [selectedCargo, setSelectedCargo] = useState(null)
  const [selectedProductVariant, setSelectedProductVariant] = useState(null)

  let buyerHeaderRow = [
    'Order Item ID',
    'Order Date',
    'Product Details',
    'Quantity',
    'Price',
    'Delivered Price',
  ]
  const buyerLedgerHeaderRow = [
    'Balance ID',
    'Payment Method',
    'Payment Date/Time',
    'Balance Status',
    'Business',
    'Type',
    'Amount',
  ]
  useEffect(() => {
    async function loadOrder() {
      setLoading(true)
      let totalPriceCopy = 0
      let res = await axios.get(`/main/orders/${orderId}/`, {
        params: {
          buyer_id: props.buyer ? props.buyer?.id : buyer?.id,
          tenant_aware: false,
        },
      })
      if (res?.data?.order_items?.length > 0) {
        res?.data.order_items?.forEach((item) => {
          if (!item?.is_credit_line_item && !item?.supplier_shipped) {
            setAllItemsShipped(false)
          }
          if (
            item?.supplier_shipped &&
            !item?.delivered &&
            hasPermission(user, 'Update Material List') &&
            buyerHeaderRow?.length === 6
          ) {
            buyerHeaderRow.splice(6, 0, 'Action')
          }
          if (item?.buyer_quantity && item?.delivered_price) {
            totalPriceCopy =
              totalPriceCopy +
              parseFloat(item?.buyer_quantity) *
                parseFloat(item?.delivered_price)
          }
        })
        setBuyerTotal(totalPriceCopy)
        setOrderItem(res?.data?.order_items[0])
      }
      setOrderLedgerItems(res?.data?.ledger_items)
      setOrder(res?.data)
      setLoading(false)
    }
    loadOrder()
    if (props.setCurrentTab) {
      props.setCurrentTab('orders')
    }
  }, [orderId, reloadOrder, buyer, props.buyer, openUploadInformationModal])

  useEffect(() => {
    let lit = 0
    orderLedgerItems?.forEach((ledgerItem) => {
      if (ledgerItem.type === 'Receipt') {
        lit += parseFloat(ledgerItem.amount_transferred)
      }
    })
    setLedgerItemsTotal(lit)
  }, [orderLedgerItems])

  useEffect(() => {
    let eli = []
    if (
      order?.mx_order &&
      order?.buyer_marketplace_fee &&
      parseFloat(order?.buyer_marketplace_fee) > 0
    ) {
      eli.push({
        quantity: 1,
        is_marketplace_fee: true,
        extra_line_item_description: 'Marketplace Fee',
        buyer_marketplace_fee: parseFloat(order?.buyer_marketplace_fee),
      })
      setBuyerTotal((prev) => prev + parseFloat(order?.buyer_marketplace_fee))
    }
    if (
      allItemsShipped &&
      order?.sales_tax_total &&
      parseFloat(order?.sales_tax_total) > 0
    ) {
      eli.push({
        quantity: 1,
        is_sales_tax_fee: true,
        extra_line_item_description: 'Sales Tax',
        sales_tax_fee: parseFloat(order?.sales_tax_total),
      })
      setBuyerTotal((prev) => prev + parseFloat(order?.sales_tax_total))
    }
    setExtraLineItems(eli)
  }, [order])

  let breadcrumbData = [{ name: 'Orders', href: '/portal/buyer/orders' }]

  const GetProductVariant = async (data) => {
    let selection_ids = data?.attribute_selections?.map(
      (selection) => selection?.attribute_selection_id
    )
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        selection_ids: selection_ids,
        product_id: data?.product?.id,
      },
    })
    if (res?.data?.id) {
      data.product_variant_id = res?.data?.id
      setSelectedProductVariant(data)
    }
  }

  return (
    <div id="buyer-order" className={'buyer-container'}>
      {loading && <MickeyPlaceholderLoading />}
      {!loading && (
        <>
          <PageHeader
            breadcrumb={breadcrumbData}
            icon={'file'}
            header={
              'Order ' +
              formatOrderNumber(order?.order_number) +
              (order?.po_number ? ` (PO #${order.po_number})` : '')
            }
            buttons={[
              {
                color: 'primary',
                style: {
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  marginTop: '1em',
                  marginLeft: '1em',
                  padding: '2em',
                },
                icon: <Info weight="bold" />,
                content: 'Upload Information',
                hidden:
                  order?.po_number || !hasPermission(user, 'Update Order')
                    ? true
                    : false,
                popup: false,
                onClick: () => {
                  setOpenUploadInformationModal(true)
                },
              },
              {
                onClick: () => setOpenRequestForm(true),
                icon: <Plus weight="bold" />,
                hidden: hasPermission(user, 'Create Buyer Request')
                  ? false
                  : true,
                style: {
                  marginTop: '1em',
                  marginLeft: '1em',
                  padding: '2em',
                },
                color: 'orange',
                content: 'New Request',
              },
              {
                onClick: () => setOpenDocumentForm(true),
                hidden:
                  activeButton !== 'Documents' ||
                  !hasPermission(user, 'Create Document'),
                icon: <Plus weight="bold" />,
                style: {
                  marginTop: '1em',
                  marginLeft: '1em',
                  padding: '2em',
                },
                color: 'green',
                content: 'Upload Documents',
              },
            ]}
          />
          {!order?.po_number && (
            <Message negative>
              <Message.Header>
                We're missing some information from you
              </Message.Header>
              <p>
                In order to complete this order we need some more information
                from you. Please click the Upload Information button above to
                add it.
              </p>
            </Message>
          )}
          <Menu secondary className={'buyer-order-buttons'}>
            {hasPermission(user, 'Read Material List') && (
              <Menu.Item
                content="Order Details"
                active={activeButton === 'Order Details'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Order Details')
                }}
              />
            )}
            {hasPermission(user, 'Read Fulfillment') && (
              <Menu.Item
                content="Fulfillment"
                active={activeButton === 'Fulfillment'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Fulfillment')
                }}
              />
            )}
            {hasPermission(user, 'Read Document') && (
              <Menu.Item
                content="Documents"
                active={activeButton === 'Documents'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Documents')
                }}
              />
            )}
            {hasPermission(user, 'Read Payment') && (
              <Menu.Item
                content="Payments"
                active={activeButton === 'Ledger'}
                style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                onClick={(e, d) => {
                  setActiveButton('Ledger')
                }}
              />
            )}
          </Menu>
          {order && activeButton === 'Order Details' && (
            <MickeyTable
              style={{ margin: '10px' }}
              headerRow={buyerHeaderRow}
              basicSegment
              data={order?.order_items.concat(extraLineItems)}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                if (
                  !data.is_credit_line_item ||
                  data.is_marketplace_fee ||
                  data.is_sales_tax_fee ||
                  parseFloat(data.quantity) * parseFloat(data.price) > 0
                ) {
                  return (
                    <BuyerOrderItemsRow
                      orderItem={data}
                      index={index}
                      allItemsShipped={allItemsShipped}
                      setReloadOrder={setReloadOrder}
                      positive={data?.id === orderItem?.id}
                      onClick={() => {
                        setOrderItem(data)
                      }}
                      key={data ? data.id : null}
                    />
                  )
                }
              }}
              emptyIcon={'file'}
              emptyMessage={'Order items will populate here'}
              footerRow={
                <Table.Row>
                  <Table.HeaderCell colSpan={'5'}>
                    <b>Total</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    positive
                    colSpan={buyerHeaderRow?.length > 6 ? '2' : '1'}
                  >
                    {formatTotalPrice(buyerTotal)}
                  </Table.HeaderCell>
                </Table.Row>
              }
            />
          )}
          {order && activeButton === 'Fulfillment' && (
            <MickeyTable
              style={{ margin: '10px' }}
              headerRow={[
                'Cargo Number',
                'Delivery Date',
                'Delivery Location',
                'Number of Order Items',
                'Status',
                'Actions',
              ]}
              basicSegment
              url={'/main/cargo-unit-instances/'}
              urlParams={{
                order_id: order?.id,
                light_serializer: true,
              }}
              reload={reloadFulfillment}
              setReload={setReloadFulfillment}
              renderBodyRow={(data, index) => {
                return (
                  <BuyerOrderFulfillmentRow
                    cargoUnitInstance={data}
                    index={index}
                    selectedCargo={selectedCargo}
                    setSelectedCargo={setSelectedCargo}
                    allItemsShipped={allItemsShipped}
                    setReloadOrder={setReloadOrder}
                    positive={data?.id === orderItem?.id}
                    onClick={() => {
                      setOrderItem(data)
                    }}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Fulfillment will populate here'}
              footerRow={<Table.Row></Table.Row>}
            />
          )}
          {order && activeButton === 'Documents' && (
            <BuyerOrderDocuments
              order={order}
              openDocumentForm={openDocumentForm}
              setOpenDocumentForm={setOpenDocumentForm}
              buyer={props.buyer ? props.buyer?.id : buyer}
              currentPortal={props.currentPortal}
            />
          )}
          {order && activeButton === 'Ledger' && (
            <MickeyTable
              headerRow={buyerLedgerHeaderRow}
              style={{ marginTop: '5px', margin: '10px', textAlign: 'center' }}
              basicSegment
              data={ledgerItemsTotal <= 0 ? [] : orderLedgerItems}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <BuyerOrderLedgerRow
                    orderId={orderId}
                    ledgerItem={data}
                    buyer={props.buyer ? props.buyer?.id : buyer?.id}
                    setReload={setReload}
                    index={index}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'money'}
              footerRow={
                <Table.Row>
                  <Table.HeaderCell colSpan={'6'}>
                    <b>Totals</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    colSpan="1"
                    positive={ledgerItemsTotal >= 0}
                    negative={ledgerItemsTotal < 0}
                  >
                    <b>{formatTotalPrice(ledgerItemsTotal)}</b>
                  </Table.HeaderCell>
                </Table.Row>
              }
              emptyMessage={'Ledger items will populate here'}
            />
          )}
          {selectedCargo && activeButton === 'Fulfillment' && (
            <>
              <PizzaTracker
                buyer
                cargoUnitInstance={selectedCargo}
                orderCancel={order?.buyer_order_status === 6}
              />
              <Grid stackable style={{ margin: '20px 0' }}>
                <Grid.Column width={16}>
                  <BuyerOrderMap
                    cargoUnitInstance={selectedCargo}
                    buyer={props.buyer ? props.buyer?.id : buyer?.id}
                  />
                </Grid.Column>
              </Grid>
            </>
          )}
        </>
      )}
      <Modal
        onOpen={() => setOpenUploadInformationModal(true)}
        onClose={() => setOpenUploadInformationModal(false)}
        open={openUploadInformationModal}
        style={{
          height: '70vh',
          width:
            window.width >= 1200
              ? '25vw'
              : window.width >= 768
              ? '45vw'
              : '90vw',
          overflow: 'scroll',
        }}
      >
        <UploadInfo
          order={order}
          buyer={props.buyer ? props.buyer : buyer}
          setOpenUploadInformationModal={setOpenUploadInformationModal}
        />
      </Modal>
      <Modal
        onOpen={() => setOpenRequestForm(true)}
        onClose={() => {
          setOpenRequestForm(false)
          setSelectedProductVariant(null)
        }}
        size={'fullscreen'}
        open={openRequestForm}
      >
        <Modal.Header>New Request</Modal.Header>
        <Modal.Content>
          {selectedProductVariant && (
            <Segment>
              <OrderItemDetailTwo
                orderItem={{
                  product_variant: selectedProductVariant,
                }}
              />
            </Segment>
          )}
          {!selectedProductVariant && (
            <>
              <h3>Start by selecting the product you would like to request.</h3>
              <Segment>
                <InventoryTable
                  supplierOrder={true}
                  showAddToOrderButton
                  setReloadPopup={setReloadPopup}
                  business={business}
                  reloadPopup={reloadPopup}
                  height={'300px'}
                  reload={reload}
                  productVariantsRoute={'/main/product-variants/'}
                  tableStartMessage={
                    "Start by searching for the product you'd like to request."
                  }
                  onAddButton={(result) => {
                    if (result?.product_variant_id) {
                      setSelectedProductVariant(result)
                    } else {
                      GetProductVariant(result)
                    }
                  }}
                  style={{ minWidth: '90vw' }}
                />
              </Segment>
            </>
          )}
          <MickeyForm
            formName={'BuyerRequestForm'}
            model={'BuyerRequest'}
            url={'/main/buyer-requests/'}
            disabled={!selectedProductVariant}
            values={{
              product_variant_id: selectedProductVariant?.product_variant_id,
              order_id: order?.id,
              user_id: user?.id,
            }}
            tenantAware={false}
            handleForm={() => {
              setOpenRequestForm(false)
              setSelectedProductVariant(null)
              toast.success(
                'Request submitted! Our team will follow up shortly.'
              )
            }}
            buttonLabel={'Send Request'}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default BuyerOrder
