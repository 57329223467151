import React, { useState, useEffect } from 'react'
import { Grid, Header } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import 'rc-time-picker/assets/index.css'
import 'rsuite/dist/rsuite.min.css'
import './Profile.css'
import IndividualHour from './IndividualHour'

function Hours(props) {
  const axios = useAxios()
  const [reload, setReload] = useState(false)
  const supplier = props.supplier
  const location = props.location

  const [dayHours, setDayHours] = useState({
    hours: [],
  })

  useEffect(() => {
    if (location) {
      async function getData() {
        let res = await axios.get(
          `/main/location-business-hours/by_day_of_week/`,
          {
            params: {
              location_id: location?.id,
            },
          }
        )
        setDayHours({
          hours: res.data.results,
        })
        setReload(false)
      }
      getData()
    }
  }, [reload])

  const labels = [
    '5:00 am',
    '6:00 am',
    '7:00 am',
    '8:00 am',
    '9:00 am',
    '10:00 am',
    '11:00 am',
    '12:00 pm',
    '1:00 pm',
    '2:00 pm',
    '3:00 pm',
    '4:00 pm',
    '5:00 pm',
    '6:00 pm',
    '7:00 pm',
    '8:00 pm',
    '9:00 pm',
  ]

  return (
    <Grid>
      <Grid.Column>
        <Header as={'h3'} style={{ textAlign: 'left' }}>
          Business Hours
          <Header.Subheader style={{ margin: '1rem 0 2.5rem 0' }}>
            These are the hours we will send you orders.
          </Header.Subheader>
          <Header.Subheader>
            {supplier && supplier?.timezone}
            {location && location?.timezone}
          </Header.Subheader>
        </Header>
        <div className={'slider-container'}>
          {dayHours.hours?.map((dayHour) => {
            return (
              <IndividualHour
                dayHour={dayHour}
                labels={labels}
                dayHours={dayHours}
                setDayHours={setDayHours}
                location={location}
                supplier={supplier}
              />
            )
          })}
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default Hours
