export const AddNewsArticleForm = {
  model: 'News',
  format: [
    {
      header: 'Add News Article',
      fields: {},
    },
    {
      widths: 'equal',
      fields: {
        title: {
          type: 'text',
          label: 'Article Title',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        description: {
          type: 'text area',
          label: 'Article Description',
        },
      },
    },
  ],
}
