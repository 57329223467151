import React, { useState, useEffect } from 'react'
import useAxios from '../../lib/auth/useAxios'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import './AutoDatepicker.css'

import { format } from 'date-fns'

function AutoDatepicker(props) {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(
    props.value ? new Date(props.value) : undefined
  )
  const axios = useAxios()

  useEffect(() => {
    if (props.value) {
      setValue(new Date(props.value))
    }
  }, [props.value])

  async function updateField(value) {
    setLoading(true)
    if (props.onChange) {
      props.onChange(value)
    }
    await axios.post(`/main/auto-update/`, {
      model: props.model,
      field: props.field,
      value: value ? format(value, 'yyyy-MM-dd') : '',
      instance_id: props.instanceId,
    })
    if (props.onSuccess) {
      props.onSuccess(value)
    }
    setLoading(false)
  }

  return (
    <div className="customDatePickerWidth auto-datepicker">
      <SemanticDatepicker
        fluid
        selection
        clearable={props.clearable}
        label={props.label}
        datePickerOnly
        filterDate={props.filterDate}
        disabled={props.disabled}
        value={value}
        onChange={(event, data) => {
          setValue(data.value)
          updateField(data.value)
        }}
        autoComplete={'off'}
        loading={loading || props.loading}
        format={'MMM D, YYYY'}
      />
    </div>
  )
}

export default AutoDatepicker
