import React, { useState, useEffect } from 'react'
import { Grid, Sidebar } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import './AdminDashboard.css'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminExchangeOrderRow from './AdminExchangeOrderRow'
import ProductFilter from '../../mx/mx_shared/MXExchange/ProductFilter/ProductFilter'
import PageHeader from '../../../components/PageHeader/PageHeader'

function AdminLiveBidsAndAsks(props) {
  const [filter, setFilter] = useState([])
  const [materialFilter, setMaterialFilter] = useState([])
  const [attributes, setAttributes] = useState([])
  const [sideFilter, setSideFilter] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const [businessFilter, setBusinessFilter] = useState([])
  const [usersFilter, setUsersFilter] = useState([])
  const [locationsFilter, setLocationsFilter] = useState([])
  const [timeframeFilter, setTimeframeFilter] = useState([])
  const [activeAttributeIndex, setActiveAttributeIndex] = useState(0)
  const [filterSelections, setFilterSelections] = useState([])
  const [price, setPrice] = useState(null)
  const [quantity, setQuantity] = useState(null)
  const [liveBidAskData, setLiveBidAskData] = useState([])
  const [expDate, setExpDate] = useState()
  const axios = useAxios()
  const [tableLoaded, setTableLoaded] = useState(true)
  const [liveBidAskTotalPages, setLiveBidAskTotalPages] = useState()
  const [liveBidAskActivePage, setLiveBidAskActivePages] = useState(1)
  const [customFilterLoading, setCustomFilterLoading] = useState(true)

  useEffect(() => {
    let didCancel = false
    const getAdminExchangeOrders = async () => {
      setTableLoaded(false)
      let res = await axios.get('main/admin-exchange-orders/', {
        params: {
          page: liveBidAskActivePage,
          filter: filter,
          status: ['open'],
          materialFilter: materialFilter,
          sideFilter: sideFilter,
          businessFilter: businessFilter,
          usersFilter: usersFilter,
          locationsFilter: locationsFilter,
          timeframeFilter: timeframeFilter,
          price: price > 0 ? price : null,
          expirationDate: expDate,
          quantity: quantity > 0 ? quantity : null,
        },
      })
      if (!didCancel) {
        if (res && res.data) {
          setLiveBidAskTotalPages(res.data.total_pages)
          setLiveBidAskData(res.data.results)
          setTableLoaded(true)
        }
      }
    }
    getAdminExchangeOrders()
    return () => {
      didCancel = true
    }
  }, [
    filter,
    materialFilter,
    sideFilter,
    statusFilter,
    businessFilter,
    usersFilter,
    locationsFilter,
    timeframeFilter,
    filterSelections,
    price,
    expDate,
    quantity,
    liveBidAskActivePage,
  ])

  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              icon={'bid'}
              header={'Live Bids & Asks'}
              subheader={
                'Bids and asks that are currently listed on the exchange.'
              }
              showDivider
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <Sidebar.Pushable id="exchange-pushable">
              <Sidebar
                id="pushable-sidebar"
                animation="overlay"
                icon="labeled"
                inverted="true"
                vertical="true"
                visible={true}
                width="wide"
              >
                <ProductFilter
                  height={window?.innerHeight}
                  yourBids={true}
                  watchlist={true}
                  showAdminBusiness={true}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  openFilter={props.openExchangeFilter}
                  filter={filter}
                  setFilter={setFilter}
                  materialFilter={materialFilter}
                  setMaterialFilter={setMaterialFilter}
                  activeAttributeIndex={activeAttributeIndex}
                  setActiveAttributeIndex={setActiveAttributeIndex}
                  filterSelections={filterSelections}
                  setFilterSelections={setFilterSelections}
                  sideFilter={sideFilter}
                  setSideFilter={setSideFilter}
                  businessFilter={businessFilter}
                  setBusinessFilter={setBusinessFilter}
                  usersFilter={usersFilter}
                  setUsersFilter={setUsersFilter}
                  locationsFilter={locationsFilter}
                  setLocationsFilter={setLocationsFilter}
                  timeframeFilter={timeframeFilter}
                  setTimeframeFilter={setTimeframeFilter}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                  price={price}
                  setPrice={setPrice}
                  expDate={expDate}
                  setExpDate={setExpDate}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  extra_filters={true}
                  setCustomFilterLoading={setCustomFilterLoading}
                />
              </Sidebar>
              <MickeyTable
                headerRow={[
                  'Product',
                  'Business',
                  'User',
                  'Side',
                  'Location',
                  'Timeframe',
                  'Timestamp',
                  'Expiration',
                  'Status',
                  'Quantity',
                  'Price',
                ]}
                basicSegment
                data={liveBidAskData}
                totalPages={liveBidAskTotalPages}
                activePage={liveBidAskActivePage}
                setActivePage={setLiveBidAskActivePages}
                loaded={tableLoaded && customFilterLoading}
                renderBodyRow={(data, index) => {
                  return (
                    <AdminExchangeOrderRow
                      exchangeOrder={data}
                      index={index}
                      onClick={() => {}}
                      key={data ? data.id : null}
                    />
                  )
                }}
                style={{
                  marginLeft: '290px',
                  marginTop: '10px',
                  padding: '15px',
                }}
                emptyIcon={'file'}
                emptyMessage={'Live bids and asks will populate here'}
              />
            </Sidebar.Pushable>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AdminLiveBidsAndAsks
