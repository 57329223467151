import {
  requiredEmailValidator,
  requiredPhoneNumberValidator,
  requiredOnlyDigtsNotAllowedValidator,
} from '../components/Forms/validators'

export const LocationUpdateForm = {
  model: 'Location',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
          label: 'Address Name',
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(input, 'name')
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        address_2: {
          required: false,
          type: 'text',
          label: 'Address 2',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        type: {
          required: true,
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        location_truck_capabilities: {
          type: 'multiple dropdown',
          label: 'Trucking Capabilities',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        on_site_contact_name: {
          required: true,
          type: 'text',
          label: 'On-Site Contact Name',
          validator: (input) => {
            return requiredOnlyDigtsNotAllowedValidator(input, 'contact name')
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        phone: {
          required: true,
          type: 'phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          required: true,
          type: 'text',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        location_tax_id: {
          label: 'Location Tax ID Number',
          type: 'text',
        },
      },
    },
  ],
}
