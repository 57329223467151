import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Segment, Card, Icon, Button, Modal } from 'semantic-ui-react'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { toast } from 'react-toastify'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import fileDownload from 'js-file-download'
import MickeyForm from '../../../components/Forms/MickeyForm'
import env from '../../../env'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function SupplierOrderDocuments(props) {
  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const [openDocumentForm, setOpenDocumentForm] = useState(false)
  useEffect(() => {
    async function loadData() {
      if (loading) return
      setLoading(true)
      let res = await axios.get(`/main/documents/`, {
        params: {
          order_id: props.order?.id,
          supplier_id: props.supplier.id,
          tenant_aware: false,
        },
      })
      setLoading(false)
      if (res?.data?.results) {
        setDocuments(res.data.results)
      } else {
        toast.error('Error loading documents :(')
      }
      setReload(false)
    }
    loadData()
  }, [reload, props.supplier])

  async function downloadData(document_id, document_name, document_extension) {
    let res = await axios.get('/main/download-document/', {
      responseType: 'blob',
      params: {
        document_id: document_id,
        document_field_name: 'file',
        tenant_aware: false,
      },
    })
    fileDownload(res.data, document_name + document_extension)
  }

  return (
    <div>
      {!loading && hasPermission(user, 'Create Document') && (
        <Button
          onClick={() => setOpenDocumentForm(true)}
          style={{
            marginTop: '1em',
            marginLeft: '1em',
            padding: '2em',
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
            '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
          }}
          color="primary"
        >
          Upload Documents
        </Button>
      )}
      <Segment basic>
        {loading && <MickeyPlaceholderLoading />}
        {!loading && documents.length < 1 && (
          <MickeyPlaceholder icon={'file'} message={'No order documents'} />
        )}
        <Grid stackable>
          {!loading &&
            documents.length > 0 &&
            documents?.map((document) => {
              return (
                <Grid.Column width={3}>
                  <Card style={{ width: '100%' }}>
                    <Card.Content>
                      <Card.Header>{document.document_name}</Card.Header>
                      <Card.Meta>
                        <span className="date">{document.created_at}</span>
                      </Card.Meta>
                      <Card.Description>
                        {document.document_type}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      {document.anvil_url && (
                        <a
                          target="_blank"
                          href={document.anvil_url}
                          rel="noreferrer"
                        >
                          <Icon name="file" />
                          View
                        </a>
                      )}
                      {document.file && (
                        <Button
                          onClick={() => {
                            downloadData(
                              document.id,
                              document.document_name,
                              document.document_extension
                            )
                          }}
                        >
                          <Icon name="file" />
                          Download
                        </Button>
                      )}
                    </Card.Content>
                  </Card>
                </Grid.Column>
              )
            })}
        </Grid>
      </Segment>
      <ESignRequestsTable
        businessIdUsers={props.supplier.business.id}
        style={{ marginTop: '50px' }}
      />
      <Modal
        onClose={() => setOpenDocumentForm(false)}
        onOpen={() => setOpenDocumentForm(true)}
        open={openDocumentForm}
      >
        <Modal.Header>Add New Document</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'DocumentUploadForm'}
            model={'Document'}
            basicSegment
            url={'/main/documents/'}
            tenantAware={false}
            currentPortal={props.currentPortal}
            values={{
              order_id: props.order?.id,
              supplier_id: props.supplier?.id,
              visiblity: 'Supplier',
            }}
            handleForm={() => {
              setReload(true)
              setOpenDocumentForm(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default SupplierOrderDocuments
