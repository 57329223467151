import React, { useState } from 'react'
import {
  Table,
  Button,
  Popup,
  PopupContent,
  List,
  Loader,
  Label,
} from 'semantic-ui-react'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import useAxios from '../../lib/auth/useAxios'
import fileDownload from 'js-file-download'
import env from '../../env'
import { CaretRight, CaretDown, File } from 'phosphor-react'
import './ESignRequest.css'
import ESignRequestIframeModal from '../Documents/ESignRequestIframeModal'

const ESignRequestRow = (props) => {
  const { eSignRequest, setInnerReload, generationDescription, actions } = props
  const user = useSelector((state) => state.user.user)
  const axios = useAxios()
  const [embedUrl, setEmbedUrl] = useState()
  const [reviewer, setReviewer] = useState()
  const [reviewerOrder, setReviewerOrder] = useState()
  const [cancelLoading, setCancelLoading] = useState(false)
  const [openActions, setOpenActions] = useState(false)
  const [openSigners, setOpenSigners] = useState(false)

  async function downloadFile(document_id, document_name, document_extension) {
    let res = await axios.get('/main/download-document/', {
      responseType: 'blob',
      params: {
        document_id: document_id,
        document_field_name: 'file',
        tenant_aware: false,
      },
    })
    if (res.status === 404) {
      toast.error('File not found')
    } else {
      fileDownload(res.data, document_name + document_extension)
    }
  }

  return (
    <>
      <Table.Row
        style={{
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
      >
        <Table.Cell
          width={1}
          onClick={() => {
            setOpenSigners(!openSigners)
          }}
        >
          <p
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              margin: 0,
            }}
          >
            {openSigners ? (
              <CaretDown size={20} weight="bold" />
            ) : (
              <CaretRight size={20} weight="bold" />
            )}
            <span
              style={{
                marginLeft: '5px',
                display: 'inline-block',
                width: '150px',
              }}
            >
              {eSignRequest?.document_name}
            </span>
          </p>
        </Table.Cell>
        <Table.Cell
          width={1}
          onClick={() => {
            setOpenSigners(!openSigners)
          }}
        >
          <p>{eSignRequest.created_at}</p>
        </Table.Cell>
        <Table.Cell
          width={2}
          onClick={() => {
            setOpenSigners(!openSigners)
          }}
        >
          {eSignRequest.sign_status === 'pending_internal_review' && (
            <>
              <Label style={{ color: '#1E90FF', backgroundColor: '#87CEFA' }}>
                Pending Internal Review
              </Label>
            </>
          )}
          {eSignRequest.sign_status === 'sent' && (
            <>
              <Label style={{ color: 'orange', backgroundColor: '#FFDAB9' }}>
                Sent for Signature
              </Label>
            </>
          )}
          {eSignRequest.sign_status === 'signed' && (
            <>
              <Label style={{ color: 'green', backgroundColor: '#CCFFCC' }}>
                Complete
              </Label>
            </>
          )}
          {eSignRequest.sign_status === 'declined' && (
            <>
              <Label style={{ color: 'red', backgroundColor: '#FFB6C1' }}>
                Declined
              </Label>
            </>
          )}
        </Table.Cell>
        {actions && (
          <Table.Cell width={1} className="document-actions">
            <div id="selected-esign-actions">
              <Popup
                style={{
                  zIndex: 1000,
                }}
                id={'send-document-popup'}
                mountNode={
                  document.getElementsByClassName(`document-actions`)[0]
                }
                on={'click'}
                position={'left center'}
                positionFixed
                size={'small'}
                open={openActions}
                onOpen={() => setOpenActions(true)}
                onClose={() => setOpenActions(false)}
                offset={[0, 0]}
                trigger={
                  <Button
                    basic
                    className="send-document-button"
                    labelPosition={'left'}
                    color={'primary'}
                    style={{
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      color: env.REACT_APP_PRIMARY_COLOR,
                      boxShadow: env.REACT_APP_PRIMARY_COLOR,
                    }}
                    size={'tiny'}
                    icon={
                      openActions ? (
                        <CaretDown size={20} weight="bold" />
                      ) : (
                        <CaretRight size={20} weight="bold" />
                      )
                    }
                    content={'Actions'}
                    onClick={() => {
                      setOpenActions(true)
                    }}
                  />
                }
              >
                <PopupContent>
                  <List>
                    {hasPermission(user, 'Read Document') && (
                      <List.Item
                        style={{
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          const document = eSignRequest.box_item.document
                          if (document?.id) {
                            downloadFile(
                              document.id,
                              document.document_name,
                              document.document_extension
                            )
                          }
                        }}
                      >
                        Download
                      </List.Item>
                    )}
                    {eSignRequest.sign_status === 'sent' &&
                      hasPermission(user, 'Update Document') && (
                        <List.Item
                          style={{
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={async () => {
                            toast.success('Document Generation Request Resent')
                            await axios.post(
                              '/main/box-sign-requests/resend_sign_request/',
                              { id: eSignRequest.id }
                            )
                          }}
                        >
                          Resend Generation Email
                        </List.Item>
                      )}
                    {hasPermission(user, 'Delete Document') && (
                      <List.Item
                        style={{
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={async () => {
                          if (!cancelLoading) {
                            setCancelLoading(true)
                            await axios.delete(
                              `/main/box-sign-requests/${eSignRequest.id}/`
                            )
                            toast.success('Document Generation Request Deleted')
                            setCancelLoading(false)
                            if (setInnerReload) {
                              setInnerReload(true)
                            }
                          }
                        }}
                      >
                        {cancelLoading && <Loader />}
                        {!cancelLoading && (
                          <>
                            {generationDescription ? (
                              <>Delete Generation Request</>
                            ) : (
                              <>Delete Sign Request</>
                            )}
                          </>
                        )}
                      </List.Item>
                    )}
                  </List>
                </PopupContent>
              </Popup>
            </div>
          </Table.Cell>
        )}
      </Table.Row>
      {openSigners && (
        <>
          {eSignRequest.user_box_sign_requests.map((signer) => (
            <Table.Row>
              <Table.Cell width={1}></Table.Cell>
              <Table.Cell width={3}>
                {signer.email ? signer.email : signer.user.email}
              </Table.Cell>
              <Table.Cell width={3}>
                {(['signed', 'declined'].includes(signer.sign_status) ||
                  signer?.id ===
                    eSignRequest.current_user_box_sign_request_reviewer
                      ?.id) && (
                  <>
                    {signer.sign_status === 'pending_internal_review' && (
                      <>
                        <Label
                          style={{
                            color: '#1E90FF',
                            backgroundColor: '#87CEFA',
                          }}
                        >
                          Pending Internal Review
                        </Label>
                      </>
                    )}
                    {signer.sign_status === 'sent' && (
                      <>
                        <Label
                          style={{
                            color: 'orange',
                            backgroundColor: '#FFDAB9',
                          }}
                        >
                          Sent for Signature
                        </Label>
                      </>
                    )}
                    {signer.sign_status === 'signed' && (
                      <>
                        <Label
                          style={{ color: 'green', backgroundColor: '#CCFFCC' }}
                        >
                          Complete
                        </Label>
                      </>
                    )}
                    {signer.sign_status === 'declined' && (
                      <>
                        <Label
                          style={{ color: 'red', backgroundColor: '#FFB6C1' }}
                        >
                          Declined
                        </Label>
                      </>
                    )}
                  </>
                )}
              </Table.Cell>
              <Table.Cell width={3}>
                {signer?.id ===
                  eSignRequest.current_user_box_sign_request_reviewer.id &&
                  ['sent', 'pending_internal_review'].includes(
                    signer.sign_status
                  ) && (
                    <Button
                      className="signer-review-document-button"
                      style={{
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setEmbedUrl(signer.embed_url)
                        setReviewer(
                          signer.email ? signer.email : signer.user.email
                        )
                        setReviewerOrder(signer.sign_order)
                      }}
                    >
                      <File size={20} weight="bold" />
                      Review Document
                    </Button>
                  )}
              </Table.Cell>
            </Table.Row>
          ))}
        </>
      )}
      {embedUrl && (
        <ESignRequestIframeModal
          generationDescription={generationDescription}
          reviewer={reviewer}
          reviewerOrder={reviewerOrder}
          embedUrl={embedUrl}
          documentName={eSignRequest?.document_name}
          openModal={embedUrl}
          setReload={setInnerReload}
          setOpenModal={setEmbedUrl}
          totalReviewers={eSignRequest.user_box_sign_requests.length}
          signRequestId={eSignRequest.id}
        />
      )}
    </>
  )
}

export default ESignRequestRow
