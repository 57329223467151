import React from 'react'
import { Table } from 'semantic-ui-react'
import { formatTotalPrice } from '../../../lib/utils/utils'
import ProductIcon from '../../../components/ProductIcon/ProductIcon'
import moment from 'moment-timezone'
import { useNavigate } from 'react-router-dom'

function AdminExchangeOrderRow(props) {
  const exchangeOrder = props.exchangeOrder
  const navigate = useNavigate()

  return (
    <>
      <Table.Row
        selectable={'true'}
        className={'admin-order-book-row'}
        onClick={() => {
          navigate(`/portal/admin/business/${exchangeOrder?.business?.id}/`, {
            state: {
              user: exchangeOrder?.user,
              timeframe: exchangeOrder?.timeframe,
              location: exchangeOrder?.location,
              cargoUnit: exchangeOrder?.cargo_unit,
              side: exchangeOrder?.side,
            },
          })
        }}
      >
        <Table.Cell
          width={4}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
          style={{
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            {exchangeOrder?.product_variant?.length > 0 && (
              <>
                <ProductIcon
                  productName={
                    exchangeOrder?.product_variant[0]?.product?.material?.name
                  }
                  width={'25px'}
                  height={'25px'}
                />
                <div
                  className="product-material-name"
                  style={{
                    marginLeft: '10px',
                    marginRight: '30px',
                    display: 'inline-block',
                    justifyContent: 'left',
                  }}
                >
                  {exchangeOrder?.product_variant[0]?.product?.material?.name}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                  }}
                >
                  {exchangeOrder?.product_variant[0]?.attribute_selections?.map(
                    (attribute, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            display: 'inline-block',
                            marginLeft: '10px',
                            paddingTop: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          <p
                            style={{
                              margin: '0',
                              fontSize: '1em',
                              marginTop: '0px',
                              textAlign: 'center',
                            }}
                          >
                            {attribute?.attribute_selection_value}
                          </p>
                          <p
                            style={{
                              fontSize: '0.7em',
                              textAlign: 'center',
                            }}
                          >
                            {attribute?.attribute_name}
                          </p>
                        </div>
                      )
                    }
                  )}
                </div>
              </>
            )}
          </div>
        </Table.Cell>
        <Table.Cell
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
          width={2}
        >
          {exchangeOrder?.business?.name}
        </Table.Cell>
        <Table.Cell
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
          width={2}
        >
          {exchangeOrder?.user?.first_name} {exchangeOrder?.user?.last_name}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.side}
        </Table.Cell>
        <Table.Cell
          width={2}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.location?.address_1}{' '}
          {exchangeOrder?.location?.address_2}
          <br></br>
          {exchangeOrder?.location?.city}, {exchangeOrder?.location?.state}{' '}
          {exchangeOrder?.location?.zip_code}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.timeframe}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.updated_at
            ? moment
                .tz(exchangeOrder?.updated_at, 'UTC')
                .tz(
                  exchangeOrder?.location?.timezone
                    ? exchangeOrder?.location?.timezone
                    : 'US/Central'
                )
                .format('MMM DD, Y hh:mm A z')
            : ''}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.expires_at
            ? moment
                .tz(exchangeOrder?.expires_at, 'UTC')
                .tz(
                  exchangeOrder?.location?.timezone
                    ? exchangeOrder?.location?.timezone
                    : 'US/Central'
                )
                .format('MMM DD, Y hh:mm A z')
            : ''}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.status}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {exchangeOrder?.quantity} {exchangeOrder?.cargo_unit?.plural_name}
        </Table.Cell>
        <Table.Cell
          width={1}
          positive={exchangeOrder?.side === 'bid'}
          warning={exchangeOrder?.side === 'ask'}
          onClick={() => {}}
        >
          {formatTotalPrice(exchangeOrder?.price)}
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default AdminExchangeOrderRow
