import React, { useState } from 'react'
import { Button, Label, Table, Image } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import AutoDropdown from '../../../components/AutoDropdown/AutoDropdown'
import ConfirmationModal from '../../../components/ConfirmationModal'
import bluecheck from '../../../img/verticals/BLUECHECK.png'
import { format_phone_number } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import './AdminBusinesses.css'

function AdminBusinessesRow(props) {
  const user = useSelector((state) => state.user.user)
  const axios = useAxios()
  const windowWidth = useWindowDimensions()
  const mobileWidth = windowWidth.width > 765
  const [
    archiveBusinessConfirmationModel,
    setArchiveBusinessConfirmationModel,
  ] = useState()
  const [
    resendInviteBusinessConfirmationModel,
    setResendInviteBusinessConfirmationModel,
  ] = useState()
  const [loading, setLoading] = useState(false)
  let navigate = useNavigate()

  function getDropdownBackgroundColor(leadType) {
    if (leadType === 'Approved') {
      return 'rgba(79, 170, 128, 0.17)'
    } else if (leadType === 'Lead') {
      return 'rgba(194, 214, 238, 0.2)'
    } else if (leadType === 'Unqualified') {
      return 'rgba(141, 44, 144, 0.15)'
    } else if (leadType === 'Not Contacted') {
      return 'rgba(181, 139, 95, 0.2)'
    } else if (leadType === 'Pending') {
      return 'rgba(241, 89, 42, 0.15)'
    }
  }
  function getDropdownColor(leadType) {
    if (leadType === 'Approved') {
      return 'rgba(79, 170, 128, 1)'
    } else if (leadType === 'Lead') {
      return 'rgba(43, 93, 188, 1)'
    } else if (leadType === 'Unqualified') {
      return 'rgba(144, 44, 121, 1)'
    } else if (leadType === 'Not Contacted') {
      return 'rgba(181, 133, 95, 1)'
    } else if (leadType === 'Pending') {
      return 'rgba(241, 89, 42, 1)'
    }
  }
  async function approveBusiness() {
    setLoading(true)
    const res = await axios.patch(
      `/main/admin-businesses/${props?.business?.id}/approve_business/`,
      {
        name: props?.business?.name,
        business_id: props?.business?.id,
        contact_email: props.business?.contact_email,
        first_name: props.business?.contact_first_name,
        last_name: props.business?.contact_last_name,
        supplier: props?.business?.supplier ? true : false,
        buyer: props?.business?.buyer ? true : false,
      }
    )
    setLoading(false)
    if (!res?.data.error) {
      toast.success('Approved Business!')
      props.setReload(true)
    }
  }

  const archiveBusiness = async () => {
    let res = await axios.delete(
      `/main/admin-businesses/${props.business?.id}/`
    )
    if (res?.status < 300) {
      props.setReload(true)
    }
  }

  async function resendBusinessUserInvite() {
    setLoading(true)
    const res = await axios.patch(
      `/main/admin-businesses/${props?.business?.id}/approve_business/`,
      {
        name: props?.business?.name,
        business_id: props?.business?.id,
        contact_email: props.business?.contact_email,
        first_name: props.business?.contact_first_name,
        last_name: props.business?.contact_last_name,
        resend: true,
      }
    )
    setLoading(false)
    if (!res?.data.error) {
      toast.success('Resent Business Email!')
      props.setReload(true)
    } else {
      toast.error(res?.data?.error ? res.data.error : 'Error inviting user')
    }
  }
  console.log(props.business)
  return (
    <>
      <Table.Row style={{ fontSize: '14px' }}>
        <Table.Cell
          style={{
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            navigate(`/portal/admin/business/${props.business?.id}/`)
          }}
        >
          {props.business?.name}
          {props.business?.verified_customer && (
            <Image
              src={bluecheck}
              alt="bluecheck"
              style={{ marginLeft: '10px', width: '20px' }}
            />
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`/portal/admin/business/${props.business?.id}/`)
          }}
        >
          {props.business?.is_god && <Label color={'green'}>Admin</Label>}
          {props.business?.buyer && !props.business?.is_god && (
            <Label>Buyer</Label>
          )}
          {props.business?.supplier && !props.business?.is_god && (
            <Label>Supplier</Label>
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`/portal/admin/business/${props.business?.id}/`)
          }}
        >
          {props.business?.contact_first_name}{' '}
          {props.business?.contact_last_name}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`/portal/admin/business/${props.business?.id}/`)
          }}
        >
          {props.business?.contact_email}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`/portal/admin/business/${props.business?.id}/`)
          }}
        >
          {format_phone_number(props.business?.contact_phone)}
        </Table.Cell>
        <Table.Cell>
          {props.business?.salespersons?.map((salesperson) => (
            <>
              {salesperson.first_name} {salesperson.last_name}
              <br />
            </>
          ))}
        </Table.Cell>
        <Table.Cell textAlign={mobileWidth ? 'center' : 'left'} width={2}>
          <AutoDropdown
            button
            size={'mini'}
            disabled={!hasPermission(user, 'Update Business')}
            style={{
              backgroundColor: getDropdownBackgroundColor(
                props.business?.status
              ),
              color: getDropdownColor(props.business?.status),
            }}
            model={'Business'}
            className={'auto-dropdown-hover-button'}
            field={'status'}
            inline
            value={props.business?.status}
            instanceId={props.business?.id}
            options={[
              {
                key: 'Pending Approval',
                text: 'Pending Approval',
                value: 'Pending Approval',
              },
              { key: 'Approved', text: 'Approved', value: 'Approved' },
              { key: 'Contacted', text: 'Contacted', value: 'Contacted' },
              { key: 'Lead', text: 'Lead', value: 'Lead' },
              { key: 'Unqualified', text: 'Unqualified', value: 'Unqualified' },
            ]}
            onChange={(e, d) => {
              if (d.value === 'Approved' && !props.business?.user) {
                approveBusiness()
              } else {
                props.setReload(true)
              }
            }}
          />
        </Table.Cell>
        {hasPermission(user, 'Delete Business') && (
          <Table.Cell width={1}>
            <div className="business-table-action">
              <Button
                color={'red'}
                icon={'x'}
                loading={loading}
                onClick={() => {
                  setArchiveBusinessConfirmationModel(true)
                }}
              />
              <ConfirmationModal
                message={'Are you sure you want to archive this business?'}
                confirmationModal={archiveBusinessConfirmationModel}
                setConfirmationModal={setArchiveBusinessConfirmationModel}
                yesFunction={() => archiveBusiness()}
                noFunction={() => setArchiveBusinessConfirmationModel(false)}
              />
              {props.business?.status === 'Approved' &&
                hasPermission(user, 'Update Business') && (
                  <Button
                    icon={'paper plane'}
                    circular
                    loading={loading}
                    color={'grey'}
                    onClick={() => {
                      setResendInviteBusinessConfirmationModel(true)
                    }}
                  />
                )}
              <ConfirmationModal
                message={
                  'Are you sure you want to resend this business invite email?'
                }
                confirmationModal={resendInviteBusinessConfirmationModel}
                setConfirmationModal={setResendInviteBusinessConfirmationModel}
                yesFunction={() => resendBusinessUserInvite()}
                noFunction={() =>
                  setResendInviteBusinessConfirmationModel(false)
                }
              />
            </div>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  )
}

export default AdminBusinessesRow
