import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { addGlobalSettings } from '../../../features/globals/globalSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

function BusinessTakeRate(props) {
  const user = useSelector((state) => state.user.user)
  const [globalSetting, setGlobalSetting] = useState()
  const [business, setBusiness] = useState()
  const axios = useAxios()
  const dispatch = useDispatch()

  const getGlobalSetting = async () => {
    const res = await axios.get('/main/global-setting/')
    if (res.data?.results) {
      setGlobalSetting(res.data.results[0])
      dispatch(addGlobalSettings(res?.data?.results[0]))
    }
  }

  const getBusiness = async () => {
    const res = await axios.get(`/main/businesses/${props.business.id}/`, {
      params: {
        tenant_aware: false,
        admin: true,
      },
    })
    if (res.status === 200) {
      setBusiness(res.data)
    }
  }

  useEffect(() => {
    if (!globalSetting) {
      getGlobalSetting()
    }
    if (!business) {
      getBusiness()
    }
  }, [globalSetting, business])

  if (!business || !globalSetting) return <></>
  const globalBuyerTakeRate =
    Math.round(
      parseFloat(globalSetting.default_marketplace_buyer_take_rate) * 100
    ) / 100
  const globalSupplierTakeRate =
    Math.round(
      parseFloat(globalSetting.default_marketplace_supplier_take_rate) * 100
    ) / 100
  let buyerTakeAdjustment
  let buyerTakeRate
  if (business.buyer) {
    buyerTakeAdjustment =
      Math.round(parseFloat(business.buyer?.buyer_take_adjustment) * 100) / 100
    buyerTakeRate =
      Math.round((globalBuyerTakeRate + buyerTakeAdjustment) * 100) / 100
  }
  let supplierTakeAdjustment
  let supplierTakeRate
  if (business.supplier) {
    supplierTakeAdjustment =
      Math.round(
        parseFloat(business.supplier?.supplier_take_adjustment) * 100
      ) / 100
    supplierTakeRate =
      Math.round((globalSupplierTakeRate + supplierTakeAdjustment) * 100) / 100
  }
  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={14}>
            <h3>Adjust the Business Take Rate</h3>
          </Grid.Column>
        </Grid.Row>
        {business?.buyer && (
          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={3}>
              <MickeyForm
                formName={'AdminBuyerTakeAdjustmentForm'}
                model={'Buyer'}
                disabled={!hasPermission(user, 'Update Take Rate')}
                tenantAware={false}
                instanceId={business.buyer?.id}
                url={'/main/buyers/'}
                values={{
                  business_id: business.id,
                }}
                style={{ border: 'none', boxShadow: 'none' }}
                postSubmit={() => {
                  getBusiness()
                  getGlobalSetting()
                }}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <div style={{ marginTop: '30px', color: '#2FB000' }}>
                + {globalBuyerTakeRate}% Global Take Rate = {buyerTakeRate}%
                Adjusted Take Rate
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
        {business?.supplier && (
          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column width={3}>
              <MickeyForm
                formName={'AdminSupplierTakeAdjustmentForm'}
                model={'Supplier'}
                tenantAware={false}
                disabled={!hasPermission(user, 'Update Take Rate')}
                instanceId={business.supplier?.id}
                url={'/main/suppliers/'}
                values={{
                  business_id: business.id,
                }}
                postSubmit={() => {
                  getBusiness()
                  getGlobalSetting()
                }}
                style={{ border: 'none', boxShadow: 'none' }}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <div style={{ marginTop: '30px', color: '#2FB000' }}>
                + {globalSupplierTakeRate}% Global Take Rate ={' '}
                {supplierTakeRate}% Adjusted Take Rate
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  )
}

export default BusinessTakeRate
