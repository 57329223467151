import React, { useEffect, useState, useRef } from 'react'
import {
  Form,
  Grid,
  Header,
  Segment,
  Table,
  Button,
  Statistic,
  Divider,
} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { formatTotalPrice, formatQuantities } from '../../../lib/utils/utils'
import './AdminReports.css'
import { CSVLink } from 'react-csv'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import env from '../../../env'
import { toast } from 'react-toastify'
import MickeyPlaceholderLoadingLarge from '../../../components/PlaceholderLoading/PlaceholderLoadingLarge'

function AdminReport({ navigation }) {
  const [report, setReport] = useState(false)
  const [loading, setLoading] = useState(false)
  const axios = useAxios()
  const inputRef = useRef()

  const [exportedReport, setExportedReport] = useState([])
  const [currentRange, setCurrentRange] = useState([
    moment().subtract(1, 'weeks').toDate(),
    moment().toDate(),
  ])
  const { reportId } = useParams()
  const exportRef = useRef(null)

  useEffect(() => {
    if (!currentRange || currentRange.length < 2) {
      return
    }
    async function fetchData() {
      if (currentRange && currentRange.length >= 2) {
        setLoading(true)
        let report = await axios.get('/main/admin-report/', {
          params: {
            report_id: reportId,
            start_date: currentRange[0].toUTCString(),
            end_date: currentRange[1].toUTCString(),
            data: {
              data_sources: [],
              contact: null,
            },
          },
        })
        setReport(report.data.report)
        setLoading(false)
      }
    }

    fetchData()
  }, [currentRange, reportId, setExportedReport])

  useEffect(() => {
    if (inputRef?.current) {
      let { offsetTop } = inputRef.current
      if (offsetTop <= 100) {
        inputRef.current.style.position = 'fixed'
      } else {
        inputRef.current.style.position = 'auto'
      }
    }
  }, [inputRef?.current?.offsetTop])

  const exportReport = async (e) => {
    if (!currentRange || currentRange?.length < 2) {
      toast.error('Please select dates.')
      return
    }

    let res = await axios.get('/main/admin-export-report/', {
      params: {
        report_id: reportId,
        start_date: currentRange[0].toUTCString(),
        end_date: currentRange[1].toUTCString(),
        data: {
          data_sources: [],
          contact: null,
        },
      },
    })
    setExportedReport(res.data)
    setTimeout(() => {
      exportRef.current.link.click()
    })
  }

  function formatReportItem(value, format, extra) {
    if (format === 'money') {
      return formatTotalPrice(value)
    } else if (format === 'quantity') {
      return formatQuantities(value)
    } else if (format === 'link') {
      return <Button content={value} href={extra?.url} />
    } else {
      return value
    }
  }
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/' },
    { name: 'Reports', href: '/portal/admin/reports/' },
  ]

  return (
    <div className={'no-sidebar'} id={'report'}>
      <PageHeader
        icon={'file outline'}
        header={report?.header}
        subheader={report?.description}
        breadcrumb={breadcrumbData}
      />
      <Segment basic className={'report'}>
        {report?.data?.summaries?.length > 0 && (
          <Segment>
            <Grid columns={'equal'}>
              {report?.data?.summaries?.map((summary) => {
                return (
                  <Grid.Column style={{ padding: '20px' }}>
                    <Header style={{ fontWeight: 'normal' }}>
                      {summary?.name}
                    </Header>
                    <Statistic>
                      <Statistic.Value>
                        {formatReportItem(
                          summary?.value,
                          summary?.format,
                          null
                        )}
                      </Statistic.Value>
                      {summary?.unit && (
                        <Statistic.Label>{summary?.unit}</Statistic.Label>
                      )}
                    </Statistic>
                  </Grid.Column>
                )
              })}
            </Grid>
          </Segment>
        )}
        <Form>
          <Grid stackable>
            <Grid.Row columns="three">
              <Grid.Column className="form-wrapper">
                <Form.Field>
                  <label>Dates</label>
                  <div className="customDatePickerWidth">
                    <SemanticDatepicker
                      onChange={(e, d) => setCurrentRange(d.value)}
                      value={currentRange}
                      type="range"
                      format={'MMM D, YYYY'}
                    />
                  </div>
                </Form.Field>
              </Grid.Column>
              <Grid.Column />
              <Grid.Column floated="right" textAlign="right">
                <Form.Field>
                  <label style={{ height: '19px' }} />
                  <Button color={'green'} onClick={(e) => exportReport()}>
                    Export to CSV
                  </Button>
                  <CSVLink
                    data={exportedReport !== null ? exportedReport : ''}
                    filename={'report.csv'}
                    target="_blank"
                    ref={exportRef}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {loading && (
          <MickeyPlaceholderLoadingLarge style={{ marginTop: '25px' }} />
        )}
        {!loading && report && (
          <div id="report-container">
            <Table celled structured>
              <Table.Header ref={inputRef}>
                <Table.Row>
                  {report?.data?.headers?.map((header, index) => {
                    return (
                      <Table.HeaderCell
                        style={{
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        }}
                        width={header.width}
                        key={index}
                      >
                        {header.name}
                      </Table.HeaderCell>
                    )
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {report &&
                  report.data.rows &&
                  report.data.rows.map((row, index) => {
                    return (
                      <Table.Row key={index}>
                        {row.map((item, idx) => {
                          return (
                            <Table.Cell
                              rowSpan={item.rowspan ? item.rowspan : 1}
                              width={item.width}
                              key={idx}
                              positive={item.positive}
                              negative={item.negative}
                            >
                              {item.format
                                ? formatReportItem(
                                    item.value,
                                    item.format,
                                    item
                                  )
                                : item.value}
                            </Table.Cell>
                          )
                        })}
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
            {report && (!report.data.rows || report.data.rows.length < 1) && (
              <Segment placeholder className={'mickey-table-placeholder'}>
                <Header icon>No items found in date range.</Header>
              </Segment>
            )}
          </div>
        )}
      </Segment>
    </div>
  )
}

export default AdminReport
