import React, { useState, useContext, createContext } from 'react'
import { reset as resetUserStore } from '../../features/user/userSlice'
import { reset as resetGlobalStore } from '../../features/globals/globalSlice'
import useAxios from './useAxios'
import {
  addUser,
  addBusiness,
  addBuyer,
  addSupplier,
  addLocations,
  loggedIn,
} from '../../features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useInterval } from '../../hooks/useInterval'

const authContext = createContext()
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
const useProvideAuth = () => {
  const dispatch = useDispatch()
  const axios = useAxios()
  const [user, setUser] = useState(null)
  const [error, setError] = useState(false)
  const [lastVerifyOn, setLastVerifyOn] = useState()

  const signin = async (username, password) => {
    try {
      setError(false)
      setUser(null)

      const response = await axios.post('main/token/obtain/', {
        email: username,
        password: password,
      })
      console.log("RESPONSE", response)
      console.log("Access Token", response.data.access)
      console.log("Refresh Token", response.data.refresh)
      localStorage.setItem('access_token', response.data.access)
      localStorage.setItem('refresh_token', response.data.refresh)
      localStorage.setItem('is_logged_in', true)
      return response
    } catch {
      setError(true)
    }
    return false
  }

  const signout = async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token')
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      localStorage.setItem('is_logged_in', false)
      resetUserStore()
      resetGlobalStore()
      await axios.post('/main/blacklist/', {
        refresh_token: refreshToken,
      })
    } catch {}
  }

  const loggedInVerify = async () => {
    try {
      const response = await axios.post('main/token/verify/')
      if (response && response.data && response.data.success) {
        localStorage.setItem('access_token', response.data.access)
        localStorage.setItem('refresh_token', response.data.refresh)
        if (response?.data?.user) {
          setUser(response?.data?.user)
          dispatch(loggedIn())
          localStorage.setItem('is_logged_in', true)
          dispatch(addUser(response.data.user))
          if (response.data.user?.business) {
            dispatch(addBusiness(response.data.user.business))
          }
          if (response.data.user?.business?.locations) {
            dispatch(addLocations(response.data.user?.business?.locations))
          }
          if (response.data.user?.business?.buyer) {
            dispatch(addBuyer(response.data.user?.business?.buyer))
          }
          if (response.data.user?.business?.supplier) {
            dispatch(addSupplier(response.data.user?.business?.supplier))
          }
        }
        return response
      } else {
        setUser(null)
        return false
      }
    } catch {
      setUser(null)
      return false
    }
  }
  const isLoggedIn = async () => {
    console.log('refreshing token')
    const currentTime = new Date()
    if (
      !lastVerifyOn ||
      currentTime.getTime() - lastVerifyOn.getTime() > 240000
    ) {
      setLastVerifyOn(new Date())
      const response = await loggedInVerify()
      return response
    } else {
      console.log('token has been refreshed less than 4 minutes ago')
    }
  }

  useInterval(
    async () => {
      await isLoggedIn()
    },
    1000 * 60 * 4
  )

  return {
    user,
    setUser,
    signin,
    signout,
    error,
    isLoggedIn,
    loggedInVerify,
  }
}
