import React from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles({
  style: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
})

const Legal = () => {
  const wd = useWindowDimensions()
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <div
      style={{
        padding: wd.width <= 991 ? '2rem' : '6rem 5% 6rem 5%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>Legal</h1>
      <h3 className={classes.style} onClick={() => navigate('/user-agreement')}>
        User Agreement Terms and Conditions
      </h3>
      <h3
        className={classes.style}
        onClick={() => navigate('/contractor-direct-tac')}
      >
        Contractor Direct Terms and Conditions
      </h3>
      <h3 className={classes.style} onClick={() => navigate('/order-ack-tac')}>
        Order Acknowledgement Terms and Conditions
      </h3>
      <h3
        className={classes.style}
        onClick={() => navigate('/purchase-order-tac')}
      >
        Purchase Order Terms and Conditions
      </h3>
    </div>
  )
}

export default Legal
