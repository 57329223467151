import React, { useState, useEffect } from 'react'
import NotificationTriggers from './NotificationTriggers'
import AddNotificationTriggers from './AddNotificationTriggers'
import PageHeader from '../../../components/PageHeader/PageHeader'
import env from '../../../env'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

const Notifications = (props) => {
  const { userId } = props
  const user = useSelector((state) => state.user.user)
  const [reloadTriggersTable, setReloadTriggersTable] = useState(false)
  const [showAddNotificationsModal, setShowAddNotificationsModal] =
    useState(false)

  useEffect(() => {
    setShowAddNotificationsModal(props.showAddNotificationsModal)
  }, [props.showAddNotificationsModal])

  useEffect(() => {
    if (props?.setShowAddNotificationsModal) {
      props?.setShowAddNotificationsModal(showAddNotificationsModal)
    }
  }, [showAddNotificationsModal])

  return (
    <>
      {props.showHeaders && (
        <PageHeader
          header={`Notifications`}
          subheader={`Edit your user notification preferences.`}
          buttons={
            hasPermission(user, 'Create Notification')
              ? [
                  {
                    color: 'primary',
                    className: 'supplier-step-2 buyer-step-2',
                    content: 'Add Notification Trigger',
                    onClick: () => {
                      setShowAddNotificationsModal(true)
                    },
                    style: {
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    },
                  },
                ]
              : []
          }
        />
      )}
      <div
        className={`supplier-step-2 buyer-step-2`}
        style={{ marginTop: '0px' }}
      >
        <AddNotificationTriggers
          userId={userId}
          business={props.business}
          admin={props.admin}
          setReloadTriggersTable={setReloadTriggersTable}
          showModal={showAddNotificationsModal}
          setShowModal={setShowAddNotificationsModal}
        />
        <NotificationTriggers
          reload={reloadTriggersTable}
          business={props.business}
          setReload={setReloadTriggersTable}
          userId={userId}
          admin={props.admin}
        />
      </div>
    </>
  )
}

export default Notifications
