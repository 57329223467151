import {
  requiredEmailValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const AdminDevelopmentCustomUserForm = {
  model: 'CustomUser',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          required: true,
          type: 'text',
        },
        last_name: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          required: true,
          type: 'text',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
        phone: {
          type: 'phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
        type: {
          type: 'text',
          label: 'Position',
          disabled: true,
          disclude_key: 'type',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        receive_sms_messages: {
          type: 'checkbox',
          label: 'Receive SMS Messages',
        },
      },
    },
  ],
}
