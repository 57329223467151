import React, { useState, useEffect } from 'react'
import './MxPressRelease.css'
import { Button, Header, Grid, Divider } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import { useParams, useNavigate } from 'react-router-dom'
import MxLogo from '../../../img/mx-logo.png'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import env from '../../../env'

const MxPressRelease = () => {
  const { pressSlug } = useParams()
  const axios = useAxios()
  const navigate = useNavigate()
  const wd = useWindowDimensions()
  const [pressRelease, setPressRelease] = useState()
  useEffect(() => {
    const getPressRelease = async () => {
      let res = await axios.get(`/main/press-releases/`, {
        params: {
          press_link: pressSlug,
        },
      })
      if (res.data.results) {
        setPressRelease(res.data.results[0])
      }
    }
    getPressRelease()
  }, [])

  return (
    <div id="press-release-container">
      <Grid style={{ justifyContent: 'center' }}>
        <Grid.Column width={wd.width <= 950 ? 14 : 12}>
          <div className="press-release-heading">
            <Header as={'h6'}>{pressRelease?.publish_date}</Header>
            <img src={MxLogo} alt="" />
            <Header as={'h1'}>
              {pressRelease?.title}
              <Header.Subheader as={'h3'} style={{ marginTop: '2rem' }}>
                {pressRelease?.subheader}
              </Header.Subheader>
            </Header>
          </div>
          <Divider style={{ margin: '0 0 2rem 0' }} />
          <div
            className="press-release-content"
            dangerouslySetInnerHTML={{ __html: pressRelease?.content }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '150px',
            }}
          >
            <span>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  padding: '1rem',
                  borderRadius: '500px',
                }}
                color={'primary'}
                onClick={() => navigate(`/press`)}
              >
                Back to Press Releases
              </Button>
            </span>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default MxPressRelease
