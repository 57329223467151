import React, { useEffect, useState, useRef } from 'react'
import {
  Table,
  Segment,
  Header,
  Icon,
  Placeholder,
  Input,
} from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import { compareDictionaries } from '../../lib/utils/utils'
import FooterRow from '../FooterRow'
import './MickeyTable.css'
import MickeyTableHeader from './MickeyTableHeader'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
/**
 * Represents a MickeyTable object which can be used to create tables from templates that fill in from an API call's data.
 * @constructor
 * @param {string} url - The url to get the data from (should return a response with a list of items in the "data" field or "data.results"). EITHER use url OR data, but not both.
 * @param {Object[]} data - Array of objects to use as data for the table. EITHER use url OR data, but not both.
 *
 * @param {string[]} headerRow - Header row of the table.
 * @param {string} urlParams - URL params to pass to the URL when getting data.
 *
 * @param {Object[]} dependencies - Variables that when changed will refresh the table.
 *
 * @param {boolean} loaded - Parent loaded state.
 * @param {boolean} setLoaded - Parent setLoaded state.
 * @param {boolean} reload - Allows parent to reload.
 * @param {boolean} hideIfEmpty - Hides table if no data is present.
 * @param {boolean} basicSegment - Makes segment background not visible.
 *
 * @param {boolean} search - Enables search bar -- will pass filter into URL get query as 'q' on user type.
 *
 * @param {function} handleResponse - Allow's parent to do something once the data is returned from the url.
 *
 * @param {string} emptyIcon - Icon if no data is returned.
 * @param {string} emptyMessage - Message if no data is returned.
 * @param {Object} emptyButton - Button if no data is returned.
 *
 * @param {Object} footerRow - Custom footer row for table
 * @param {string} containerClassName
 * @param {Object} containerStyle
 * @param {Object} setParentRow - Allows you to pass in a set useState variable that will update the parent row variable.
 */

function MickeyTable(props) {
  const { CancelToken } = axios
  const axiosClient = useAxios()
  const isMounted = useRef(true)
  const latestData = useRef(null)
  const cancelTokenSource = useRef(null)
  const [rows, setRows] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [loaded, setLoaded] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [filter, setFilter] = useState(null)
  const [urlParams, setUrlParams] = useState(
    props.urlParams ? props.urlParams : {}
  )

  const [moreRows, setMoreRows] = useState(true)
  let headerRow = props.headerRow
  let dependencies
  const { setRowsLength } = props
  if (props.dependencies) {
    dependencies = [
      ...props.dependencies,
      props.data,
      activePage,
      props.loaded,
      props.reload,
      urlParams,
      filter,
    ]
  } else {
    dependencies = [
      activePage,
      props.data,
      props.reload,
      props.loaded,
      urlParams,
      filter,
    ]
  }

  useEffect(() => {
    if (props.urlParams) {
      setUrlParams(props.urlParams)
    }
  }, [JSON.stringify(props.urlParams)])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    let sameDicts = compareDictionaries(props.urlParams, urlParams)
    if (!sameDicts) {
      console.log('hio')
      setUrlParams(props.urlParams)
    }
  }, [JSON.stringify(props.urlParams)])

  useEffect(() => {
    async function fetchData() {
      setLoaded(false)
      if (cancelTokenSource.current) {
        cancelTokenSource.current?.cancel('Request canceled by user')
      }

      cancelTokenSource.current = CancelToken?.source()
      let response = await axiosClient
        .get(`${props.url}`, {
          cancelToken: cancelTokenSource.current?.token,
          params: {
            ...urlParams,
            page: activePage,
            q: filter,
          },
        })
        .catch((error) => {})
      if (response && response.data) {
        let responseData = response.data.results
        setMoreRows(!(responseData?.length < 25))
        if (props.handleResponse) {
          responseData = props.handleResponse(responseData)
        }
        if (props.filterFunction) {
          responseData = responseData.filter(props.filterFunction)
        }
        latestData.current = responseData
        if (isMounted.current) {
          setRows(latestData.current)
          if (setRowsLength) {
            setRowsLength(latestData.current.length)
          }
          if (props.setParentRow) {
            props.setParentRow(latestData.current)
          }
          setTotalPages(
            response.data.total_pages ? response.data.total_pages : 1
          )
          setLoaded(true)
          if (props.setLoaded) {
            props.setLoaded(true)
          }
        }
      }
      if (props.setReload) {
        props.setReload(false)
      }
    }
    if (props.data) {
      if (props.loaded === true || props.loaded === undefined) {
        setRows(props.data)
        setLoaded(true)
        if (setRowsLength) {
          setRowsLength(props.data.length)
        }
        if (props.setLoaded) {
          props.setLoaded(true)
        }
      } else {
        setLoaded(false)
      }
    } else {
      fetchData()
    }
  }, dependencies)

  const nextRows = () => {
    setActivePage(activePage + 1)
  }

  if (rows?.length === 0 && props.hideIfEmpty) {
    return <></>
  }
  let footerRow = <></>
  if (!props.hideFooter) {
    if (props.footerRow) {
      footerRow = props.footerRow
    } else {
      footerRow = (
        <FooterRow
          totalPages={props.totalPages ? props.totalPages : totalPages}
          activePage={props.activePage ? props.activePage : activePage}
          setActivePage={
            props.setActivePage ? props.setActivePage : setActivePage
          }
        />
      )
    }
  }
  console.log(urlParams)
  return (
    <div className={props.containerClassName} style={props.style}>
      {props.search && (
        <Input
          placeholder="Filter"
          name="q"
          className={'mickey-table-filter input-inverted'}
          value={filter}
          onChange={(e, d) => {
            setFilter(d.value)
          }}
        />
      )}
      <Segment
        basic={props.basicSegment}
        id={props.id}
        style={{ overflowX: 'scroll', minHeight: '400px' }}
      >
        {!props.infiniteScroll && (
          <Table
            compact={props.compact}
            selectable={!props.unselectable || props.selectable}
            collapsing={props.collapsing}
            className={
              props.className
                ? `${props.className} mickey-table`
                : 'mickey-table'
            }
            basic={'very'}
            sortable
            stackable
          >
            <MickeyTableHeader
              headerRow={headerRow}
              headerSearch={props.headerSearch}
              headerSort={props.headerSort}
              setUrlParams={setUrlParams}
              urlParams={urlParams}
            />
            {((rows && rows.length > 0) || props.showHeaderNoData) && (
              <>
                {(loaded || props.loaded) && (
                  <>
                    <Table.Body>
                      {rows.map((row, index) => {
                        return props.renderBodyRow(row, index)
                      })}
                    </Table.Body>
                    {footerRow && rows?.length > 0 && (
                      <Table.Footer>{footerRow}</Table.Footer>
                    )}
                  </>
                )}
              </>
            )}
            {loaded && rows?.length <= 0 && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={16}>
                    <Segment placeholder className={'mickey-table-placeholder'}>
                      <Header icon>
                        {props.emptyIconHtml}
                        <Icon name={props.emptyIcon} />
                        {props.emptyMessage}
                        <div style={{ marginTop: '10px' }}>
                          {props.emptyButton}
                        </div>
                      </Header>
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
            {!loaded && !props.loaded && (
              <Table.Body>
                <Table.Row>
                  <Table.Cell colSpan={16}>
                    <Placeholder
                      fluid
                      className={'mickey-table-loading'}
                      style={{ borderRadius: '25px', ...props.loadedStyle }}
                    >
                      <Placeholder.Line />
                      <Placeholder.Line />
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            )}
          </Table>
        )}
        {props.infiniteScroll && (
          <InfiniteScroll
            dataLength={rows?.length ? rows?.length : 0}
            next={nextRows}
            height={props.height ? props.height : 450}
            hasMore={moreRows}
            loader={''}
          >
            <Table
              compact={props.compact}
              selectable={!props.unselectable || props.selectable}
              collapsing={props.collapsing}
              className={
                props.className
                  ? `${props.className} mickey-table`
                  : 'mickey-table'
              }
              basic={'very'}
              sortable
              stackable
            >
              <MickeyTableHeader
                headerRow={headerRow}
                headerSearch={props.headerSearch}
                headerSort={props.headerSort}
                setUrlParams={setUrlParams}
                urlParams={urlParams}
              />
              {((rows && rows.length > 0) || props.showHeaderNoData) && (
                <>
                  {(loaded || props.loaded) && (
                    <>
                      <Table.Body>
                        {rows.map((row, index) => {
                          return props.renderBodyRow(row, index)
                        })}
                      </Table.Body>
                      {footerRow && rows?.length > 0 && (
                        <Table.Footer>{footerRow}</Table.Footer>
                      )}
                    </>
                  )}
                </>
              )}
              {loaded && rows?.length <= 0 && (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={16}>
                      <Segment
                        placeholder
                        className={'mickey-table-placeholder'}
                      >
                        <Header icon>
                          {props.emptyIconHtml}
                          <Icon name={props.emptyIcon} />
                          {props.emptyMessage}
                          <div style={{ marginTop: '10px' }}>
                            {props.emptyButton}
                          </div>
                        </Header>
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
              {!loaded && !props.loaded && (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={16}>
                      <Placeholder
                        fluid
                        className={'mickey-table-loading'}
                        style={{ borderRadius: '25px', ...props.loadedStyle }}
                      >
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                          <Placeholder.Line />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          </InfiniteScroll>
        )}
      </Segment>
    </div>
  )
}

export default MickeyTable
