import { useState } from 'react'
import { Button, Header, Table, Popup, Checkbox } from 'semantic-ui-react'
import { reduce } from 'underscore'
import './ExchangeTable.css'
import env from '../../../../../env'
import { formatTotalPrice } from '../../../../../lib/utils/utils'
import { Star } from 'phosphor-react'
import ProductIcon from '../../../../../components/ProductIcon/ProductIcon'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PriceHistoryModal from '../../../../../components/PriceHistory/PriceHistoryModal'
import {
  hasPermission,
  hasPricing,
  inGroup,
} from '../../../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const ExchangeTableRow = (props) => {
  const navigate = useNavigate()
  const [selectedOrder, setSelectedOrder] = useState(null)
  const {
    hasActiveOrder,
    userBid,
    userAsk,
    lowestAskOrder,
    highestBidOrder,
    hasCurrentSideOrder,
    hasOpposingSideOrder,
    publicExchange,
    product,
    productVariant,
    setCurrentlySelectedTimeframe,
    setCurrentlySelectedTruckingType,
    currentLocationId,
  } = props

  const user = useSelector((state) => state.user.user)
  const [showPriceHistory, setShowPriceHistory] = useState(false)
  const [priceHistoryOrderId, setPriceHistoryOrderId] = useState()
  const handleCheckBoxClick = () => {
    if (
      props.selectedBids
        .map((eo) => eo?.id)
        .includes(props.exchangeOrderBook?.id)
    ) {
      props.setSelectedBids(
        props.selectedBids.filter(
          (eo) => eo?.id !== props.exchangeOrderBook?.id
        )
      )
    } else {
      props.setSelectedBids([...props.selectedBids, props.exchangeOrderBook])
    }
  }

  const handleCancelOrderClick = () => {
    setSelectedOrder(props?.exchangeOrderBook)
    let orderId = props.currentPortal === 'supplier' ? userAsk?.id : userBid?.id
    let locationId =
      props.currentPortal === 'supplier'
        ? userBid?.location_id
        : highestBidOrder?.location_id

    props.cancelBid(
      props.exchangeOrderBook?.product_variant?.id,
      orderId,
      locationId
    )
  }

  const handleNavigateToProductPages = (to) => {
    let navigation_url = `${
      (publicExchange && '/exchange/') ||
      (!publicExchange && '/portal/' + props?.currentPortal + '/')
    }${product?.commodity?.slug}/${product?.commodity_form?.slug}/${
      product?.slug
    }`
    if (to !== 'Product') {
      navigation_url = navigation_url + `/${productVariant?.slug}/`
      if (!publicExchange) {
        navigation_url = navigation_url + `${props.yourBids ? true : false}/`
      }
    }
    return navigate(navigation_url)
  }

  const buyTimeframe =
    props.currentPortal === 'buyer'
      ? hasActiveOrder &&
        hasCurrentSideOrder &&
        (props.currentPortal === 'supplier'
          ? userAsk
            ? userAsk?.timeframe
            : ''
          : userBid
          ? userBid?.timeframe
          : '')
      : (props.currentPortal === 'supplier' &&
          props.exchangeOrderBook?.bid_orders?.length > 0) ||
        (props.currentPortal === 'buyer' &&
          props.exchangeOrderBook?.ask_orders?.length > 0)
      ? props.currentPortal === 'supplier'
        ? highestBidOrder?.timeframe
        : lowestAskOrder?.timeframe
      : ''

  const sellTimeframe =
    props.currentPortal === 'supplier' && userAsk
      ? userAsk.timeframe
      : props.currentPortal === 'buyer' && lowestAskOrder
      ? lowestAskOrder.timeframe
      : ''

  const attributeSelectionsCssCondition = () => {
    let attributeSelectionsTextLength =
      props.productVariant?.attribute_selections
        ?.map((attribute, idx) => {
          return attribute?.attribute_selection_value?.length
        })
        .reduce(
          (accumulator, currentValue) => accumulator + 1 + currentValue,
          0
        )

    return (
      (props.productVariant?.attribute_selections?.length > 4 &&
        attributeSelectionsTextLength > 50) ||
      attributeSelectionsTextLength > 50
    )
  }

  return (
    <>
      <Table.Row
        onMouseEnter={() => props.setHoverRow(props.index)}
        onMouseLeave={() => props.setHoverRow(null)}
        className="listing-row"
        style={{
          height: '50px',
          borderBottom: '1px solid lightgrey',
          paddingBottom: '6px',
        }}
      >
        <Table.Cell
          width={2}
          style={{
            justifyContent: 'left',
            alignItems: 'left',
            minWidth: '125px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            {props.yourBids && (
              <>
                <Checkbox
                  style={{ marginRight: '10px' }}
                  checked={props.selectedBids
                    .map((eo) => eo?.id)
                    .includes(props.exchangeOrderBook?.id)}
                  onClick={handleCheckBoxClick}
                />
              </>
            )}
            <ProductIcon productName={props.product?.material?.name} />
            <span
              className="product-material-name"
              style={{
                marginLeft: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleNavigateToProductPages('Product')}
            >
              {props.exchangeOrderBook?.product_material_name}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell width={7}>
          <div className="product-attribute-div">
            {props.productVariant?.attribute_selections?.map(
              (attribute, idx) => {
                return (
                  <div
                    key={idx}
                    style={{
                      marginLeft: attributeSelectionsCssCondition()
                        ? '8px'
                        : '10px',
                      paddingTop: attributeSelectionsCssCondition()
                        ? '10px'
                        : '20px',
                    }}
                    onClick={() =>
                      handleNavigateToProductPages('ProductVariant')
                    }
                  >
                    <p>{attribute?.attribute_selection_value}</p>
                    <p
                      style={{
                        marginBottom: attributeSelectionsCssCondition()
                          ? '4px'
                          : '14px',
                      }}
                      className={
                        props.hoverRow === props.index
                          ? 'attribute-hover visible'
                          : 'attribute-hover'
                      }
                    >
                      {attribute?.attribute_name}
                    </p>
                  </div>
                )
              }
            )}
          </div>
        </Table.Cell>
        {props.yourBids && (
          <Table.Cell
            width={2}
            style={{
              textAlign: 'center',
            }}
          >
            {props.currentPortal === 'supplier'
              ? userAsk?.formatted_address
              : userBid?.formatted_address}
          </Table.Cell>
        )}
        <Table.Cell
          width={3}
          style={{
            textAlign: 'center',
            margin: '0 15px',
            minWidth: '100px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {hasActiveOrder &&
              hasCurrentSideOrder &&
              props.currentPortal === 'buyer' &&
              userBid?.status === 'open' && (
                <div class="ring-container">
                  <div class="ringring"></div>
                  <div class="circle"></div>
                </div>
              )}
            {hasActiveOrder &&
              hasCurrentSideOrder &&
              props.currentPortal === 'buyer' &&
              userBid?.status === 'disabled' && (
                <div class="disabled-ring-container">
                  <div class="disabled-circle"></div>
                </div>
              )}
            <div
              style={{
                display: 'inline-block',
                marginLeft: '10px',
                paddingTop: '10px',
                cursor: 'pointer',
              }}
            >
              <p
                style={{
                  margin: '0',
                  fontSize: '1em',
                  marginTop: '1px',
                  textAlign: 'center',
                }}
              >
                <b>
                  {props.currentPortal === 'supplier' &&
                    props.exchangeOrderBook?.bid_orders?.length > 0 &&
                    highestBidOrder && (
                      <Popup
                        content={
                          <div
                            style={{
                              width: props?.is_admin ? '65em' : '250px',
                              paddingTop: '10px',
                            }}
                          >
                            <Header as={'h2'} textAlign={'center'}>
                              {' '}
                              {props.currentPortal === 'supplier'
                                ? 'Bids'
                                : 'Asks'}
                            </Header>
                            <p
                              style={{
                                fontSize: '12px',
                                margin: '0',
                                textAlign: 'center',
                              }}
                            >
                              {props.productVariant &&
                                props.productVariant.product.material.name}
                              {', '}
                              {props.productVariant &&
                                props.productVariant?.attribute_selections?.map(
                                  (attribute, index) => {
                                    if (
                                      index !==
                                      props.productVariant.attribute_selections
                                        .length -
                                        1
                                    ) {
                                      return (
                                        attribute.attribute_selection_value +
                                        ', '
                                      )
                                    }
                                    if (
                                      index ===
                                      props.productVariant.attribute_selections
                                        .length -
                                        1
                                    ) {
                                      return attribute.attribute_selection_value
                                    }
                                  }
                                )}
                            </p>
                            <Table basic compact unstackable>
                              <Table.Header>
                                <Table.HeaderCell textAlign={'center'}>
                                  $/
                                  {
                                    props.productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Number of Trucks</>
                                </Table.HeaderCell>
                                {props?.is_admin && (
                                  <>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Business</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Location</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>FOB Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Lowest Freight Price</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Provider</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>Divisor</>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign={'center'}>
                                      <>TWD</>
                                    </Table.HeaderCell>
                                  </>
                                )}
                              </Table.Header>
                              <Table.Body>
                                {props.currentPortal === 'supplier' &&
                                  props.groupedBids
                                    ?.sort((a, b) => {
                                      let aPrice = a.total_price
                                      let bPrice = b.total_price
                                      if (aPrice > bPrice) return -1
                                      if (bPrice <= aPrice) return -1
                                    })
                                    .map((bids, idx) => {
                                      return (
                                        <Table.Row key={idx}>
                                          <Table.Cell textAlign={'center'}>
                                            {bids[0]?.total_price > 0
                                              ? formatTotalPrice(
                                                  bids[0]?.total_price
                                                )
                                              : '----'}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {reduce(
                                              bids.map((bid) => {
                                                return bid.quantity
                                              }),
                                              (memo, num) => {
                                                return memo + num
                                              },
                                              0
                                            )}
                                          </Table.Cell>
                                          {props?.is_admin && (
                                            <>
                                              <Table.Cell
                                                textAlign={'center'}
                                                width={2}
                                              >
                                                {bids?.map((bid) => {
                                                  return bid?.business_name
                                                    ? bid?.business_name
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.formatted_address
                                                    ? bid?.formatted_address
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.price
                                                    ? formatTotalPrice(
                                                        bid?.price
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.rate > 0
                                                    ? formatTotalPrice(
                                                        bid?.trucking?.rate
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking
                                                    ?.trucking_company
                                                    ? bid?.trucking
                                                        ?.trucking_company
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.divisor
                                                    ? bid?.trucking?.divisor
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                              <Table.Cell textAlign={'center'}>
                                                {bids?.map((bid) => {
                                                  return bid?.trucking?.rate &&
                                                    bid?.trucking?.divisor &&
                                                    bid?.trucking?.rate /
                                                      bid?.trucking?.divisor >
                                                      0
                                                    ? formatTotalPrice(
                                                        bid?.trucking?.rate /
                                                          bid?.trucking?.divisor
                                                      )
                                                    : '----'
                                                })}
                                              </Table.Cell>
                                            </>
                                          )}
                                        </Table.Row>
                                      )
                                    })}
                              </Table.Body>
                            </Table>
                          </div>
                        }
                        on={'click'}
                        offset={props?.is_admin ? [-60, 40] : [-10, 15]}
                        trigger={
                          <b className={'hover-price-underline'}>
                            {hasPricing(user, 'primary') &&
                              !inGroup(user, 'Buyer') &&
                              !inGroup(user, 'Supplier') && (
                                <>
                                  <span style={{ fontSize: '11.9px' }}>
                                    Primary
                                  </span>{' '}
                                  {highestBidOrder.total_price ? (
                                    formatTotalPrice(
                                      highestBidOrder.total_price
                                    )
                                  ) : (
                                    <>N/A </>
                                  )}
                                  /
                                  {
                                    props.productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                  <br />
                                  <span style={{ fontSize: '11.9px' }}>
                                    Secondary
                                  </span>{' '}
                                  {highestBidOrder.secondary_total_price ? (
                                    <>
                                      {formatTotalPrice(
                                        highestBidOrder.secondary_total_price
                                      )}
                                      /
                                      {
                                        props.productVariant?.product
                                          ?.default_unit_of_measure?.short_name
                                      }
                                    </>
                                  ) : (
                                    <>
                                      N/A /
                                      {
                                        props.productVariant?.product
                                          ?.default_unit_of_measure?.short_name
                                      }
                                    </>
                                  )}
                                </>
                              )}
                            {hasPricing(user, 'primary') &&
                              (inGroup(user, 'Buyer') ||
                                inGroup(user, 'Supplier')) && (
                                <>
                                  {formatTotalPrice(
                                    highestBidOrder.total_price
                                  )}
                                  /
                                  {
                                    props.productVariant?.product
                                      ?.default_unit_of_measure?.short_name
                                  }
                                </>
                              )}
                            {!hasPricing(user, 'primary') && (
                              <>
                                {highestBidOrder.secondary_total_price ? (
                                  <>
                                    {formatTotalPrice(
                                      highestBidOrder.secondary_total_price
                                    )}
                                    /
                                    {
                                      props.productVariant?.product
                                        ?.default_unit_of_measure?.short_name
                                    }
                                  </>
                                ) : (
                                  <>
                                    N/A /
                                    {
                                      props.productVariant?.product
                                        ?.default_unit_of_measure?.short_name
                                    }
                                  </>
                                )}
                              </>
                            )}
                          </b>
                        }
                      />
                    )}
                  {props.currentPortal === 'supplier' &&
                    props.exchangeOrderBook?.bid_orders?.length == 0 && (
                      <b>--</b>
                    )}
                  {props.currentPortal === 'buyer' && userBid && (
                    <b
                      onClick={() => {
                        if (hasPermission(user, 'Read Price History')) {
                          setPriceHistoryOrderId(userBid.id)
                          setShowPriceHistory(true)
                        }
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = 'underline')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = 'none')
                      }
                    >
                      {hasPricing(user, 'primary') &&
                        !inGroup(user, 'Buyer') &&
                        !inGroup(user, 'Supplier') && (
                          <>
                            <span style={{ fontSize: '11.9px' }}>Primary</span>{' '}
                            {userBid.price ? (
                              formatTotalPrice(userBid.price)
                            ) : (
                              <>N/A </>
                            )}
                            /
                            {
                              props.productVariant?.product
                                ?.default_unit_of_measure?.short_name
                            }
                            <br />
                            <span style={{ fontSize: '11.9px' }}>
                              Secondary
                            </span>{' '}
                            {userBid.secondary_price ? (
                              <>
                                {formatTotalPrice(userBid.secondary_price)}/
                                {
                                  props.productVariant?.product
                                    ?.default_unit_of_measure?.short_name
                                }
                              </>
                            ) : (
                              <>
                                N/A /
                                {
                                  props.productVariant?.product
                                    ?.default_unit_of_measure?.short_name
                                }
                              </>
                            )}
                          </>
                        )}
                      {hasPricing(user, 'primary') &&
                        (inGroup(user, 'Buyer') ||
                          inGroup(user, 'Supplier')) && (
                          <>
                            {userBid.price ? (
                              formatTotalPrice(userBid.price)
                            ) : (
                              <>N/A </>
                            )}
                            /
                            {
                              props.productVariant?.product
                                ?.default_unit_of_measure?.short_name
                            }
                          </>
                        )}
                      {!hasPricing(user, 'primary') && (
                        <>
                          {userBid.secondary_price ? (
                            <>
                              {userBid.secondary_price ? (
                                formatTotalPrice(userBid.secondary_price)
                              ) : (
                                <>N/A </>
                              )}
                              /
                              {
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              }
                            </>
                          ) : (
                            <>
                              N/A /
                              {
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              }
                            </>
                          )}
                        </>
                      )}
                    </b>
                  )}
                  {((props.currentPortal === 'buyer' && !userBid) ||
                    publicExchange) && <b>--</b>}
                </b>
              </p>
              <p
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'light',
                  marginBottom: '14px',
                  textAlign: 'center',
                }}
                className={
                  props.hoverRow === props.index || props.yourBids
                    ? 'attribute-hover visible'
                    : 'attribute-hover visible'
                }
              >
                {props.currentPortal === 'buyer'
                  ? ((props.currentPortal === 'supplier' && userAsk) ||
                      (props.currentPortal === 'buyer' && userBid)) &&
                    (props.currentPortal === 'supplier'
                      ? userAsk?.quantity +
                        ' ' +
                        userAsk?.cargo_unit?.plural_name
                      : userBid?.quantity +
                        ' ' +
                        userBid?.cargo_unit?.plural_name)
                  : (props.currentPortal === 'supplier' &&
                      props.exchangeOrderBook?.bid_orders?.length > 0) ||
                    (props.currentPortal === 'buyer' &&
                      props.exchangeOrderBook?.ask_orders?.length > 0)
                  ? props.currentPortal === 'supplier'
                    ? highestBidOrder?.quantity +
                      ' ' +
                      highestBidOrder?.cargo_unit?.plural_name
                    : lowestAskOrder?.quantity +
                      ' ' +
                      lowestAskOrder?.cargo_unit?.plural_name
                  : ''}
              </p>
            </div>
            {buyTimeframe ? (
              <label
                style={{
                  backgroundColor:
                    buyTimeframe === 'Prompt' ? '#d0f3cc' : '#c7eaec',
                  color: buyTimeframe === 'Prompt' ? '#0D5740' : '#225061',
                  padding: '0 5px',
                  margin: '0px 0px 15px 9px',
                  borderRadius: '3px',
                  fontSize: '0.83em',
                  height: '20px',
                  width: '90px',
                }}
              >
                {buyTimeframe}
              </label>
            ) : (
              ''
            )}
          </div>
        </Table.Cell>
        <Table.Cell
          width={2}
          style={{
            textAlign: 'center',
            padding: '0 15px',
            minWidth: '130px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 15px',
            }}
          >
            {hasActiveOrder &&
              hasCurrentSideOrder &&
              props.currentPortal === 'supplier' &&
              lowestAskOrder?.status === 'open' && (
                <div class="ring-container">
                  <div class="ringring"></div>
                  <div class="circle"></div>
                </div>
              )}
            {hasActiveOrder &&
              hasCurrentSideOrder &&
              props.currentPortal === 'supplier' &&
              lowestAskOrder?.status === 'disabled' && (
                <div class="disabled-ring-container">
                  <div class="disabled-circle"></div>
                </div>
              )}
            <div
              style={{
                display: 'inline-block',
                marginLeft: '10px',
                paddingTop: '10px',
                cursor: 'pointer',
              }}
            >
              <p
                style={{
                  margin: '0',
                  fontSize: '1em',
                  marginTop: '1px',
                  textAlign: 'center',
                }}
              >
                {props.currentPortal === 'supplier' ? (
                  hasActiveOrder && hasCurrentSideOrder ? (
                    // TODO: this should make sure the 0th ask or bid is the user's
                    <b
                      onClick={() => {
                        if (hasPermission(user, 'Read Price History')) {
                          if (props.currentPortal === 'supplier') {
                            setPriceHistoryOrderId(userAsk.id)
                          } else {
                            setPriceHistoryOrderId(userBid.id)
                          }
                          setShowPriceHistory(true)
                        }
                      }}
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.textDecoration = 'underline')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.textDecoration = 'none')
                      }
                    >
                      {formatTotalPrice(
                        props.currentPortal === 'supplier'
                          ? userAsk?.price
                          : userBid?.price
                      )}
                      /
                      {
                        props.productVariant?.product?.default_unit_of_measure
                          ?.short_name
                      }
                    </b>
                  ) : (
                    <b>--</b>
                  )
                ) : (props.currentPortal === 'supplier' && highestBidOrder) ||
                  (props.currentPortal === 'buyer' && lowestAskOrder) ? (
                  <Popup
                    content={
                      <div
                        style={{
                          width: props?.is_admin ? '65em' : '250px',
                          paddingTop: '10px',
                        }}
                      >
                        <Header as={'h2'} textAlign={'center'}>
                          {' '}
                          {props.currentPortal === 'supplier' ? 'Bids' : 'Asks'}
                        </Header>
                        <p
                          style={{
                            fontSize: '12px',
                            margin: '0',
                            textAlign: 'center',
                          }}
                        >
                          {props.productVariant &&
                            props.productVariant.product.material.name}
                          {', '}
                          {props.productVariant &&
                            props.productVariant?.attribute_selections?.map(
                              (attribute, index) => {
                                if (
                                  index !==
                                  props.productVariant.attribute_selections
                                    .length -
                                    1
                                ) {
                                  return (
                                    attribute.attribute_selection_value + ', '
                                  )
                                }
                                if (
                                  index ===
                                  props.productVariant.attribute_selections
                                    .length -
                                    1
                                ) {
                                  return attribute.attribute_selection_value
                                }
                              }
                            )}
                        </p>
                        <Table basic compact unstackable>
                          <Table.Header>
                            <Table.HeaderCell textAlign={'center'}>
                              $/
                              {
                                props.productVariant?.product
                                  ?.default_unit_of_measure?.short_name
                              }
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                              <>Number of Trucks</>
                            </Table.HeaderCell>
                            {props?.is_admin && (
                              <>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Business</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Location</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>FOB Price</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Lowest Freight Price</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Provider</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>Divisor</>
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign={'center'}>
                                  <>TWD</>
                                </Table.HeaderCell>
                              </>
                            )}
                          </Table.Header>
                          <Table.Body>
                            {props.currentPortal === 'buyer' &&
                              props.groupedAsks
                                ?.sort((a, b) => {
                                  let aPrice = a.total_price
                                  let bPrice = b.total_price
                                  if (aPrice < bPrice) return -1
                                  if (bPrice >= aPrice) return -1
                                })
                                .map((asks, idx) => {
                                  return (
                                    <Table.Row key={idx}>
                                      <Table.Cell textAlign={'center'}>
                                        {asks[0]?.total_price > 0
                                          ? formatTotalPrice(
                                              asks[0]?.total_price
                                            )
                                          : '----'}
                                      </Table.Cell>
                                      <Table.Cell textAlign={'center'}>
                                        {reduce(
                                          asks.map((ask) => {
                                            return ask.quantity
                                          }),
                                          (memo, num) => {
                                            return memo + num
                                          },
                                          0
                                        )}
                                      </Table.Cell>
                                      {props?.is_admin && (
                                        <>
                                          <Table.Cell
                                            textAlign={'center'}
                                            width={2}
                                          >
                                            {asks?.map((ask) => {
                                              return ask?.business_name
                                                ? ask?.business_name
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.formatted_address
                                                ? ask?.formatted_address
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.price
                                                ? formatTotalPrice(ask?.price)
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.trucking?.rate > 0
                                                ? formatTotalPrice(
                                                    ask?.trucking?.rate
                                                  )
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.trucking
                                                ?.trucking_company
                                                ? ask?.trucking
                                                    ?.trucking_company
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.trucking?.divisor
                                                ? ask?.trucking?.divisor
                                                : '----'
                                            })}
                                          </Table.Cell>
                                          <Table.Cell textAlign={'center'}>
                                            {asks?.map((ask) => {
                                              return ask?.trucking?.rate &&
                                                ask?.trucking?.divisor &&
                                                ask?.trucking?.rate /
                                                  ask?.trucking?.divisor >
                                                  0
                                                ? formatTotalPrice(
                                                    ask?.trucking?.rate /
                                                      ask?.trucking?.divisor
                                                  )
                                                : '----'
                                            })}
                                          </Table.Cell>
                                        </>
                                      )}
                                    </Table.Row>
                                  )
                                })}
                            {props.currentPortal === 'supplier' &&
                              props.groupedBids
                                ?.sort((a, b) => {
                                  let aPrice = a.total_price
                                  let bPrice = b.total_price
                                  if (aPrice > bPrice) return -1
                                  if (bPrice <= aPrice) return -1
                                })
                                .map((bids, idx) => {
                                  return (
                                    <Table.Row key={idx}>
                                      <Table.Cell textAlign={'center'}>
                                        {bids[0]?.total_price > 0
                                          ? formatTotalPrice(
                                              bids[0]?.total_price
                                            )
                                          : '----'}
                                      </Table.Cell>
                                      <Table.Cell textAlign={'center'}>
                                        {reduce(
                                          bids.map((bid) => {
                                            return bid.quantity
                                          }),
                                          (memo, num) => {
                                            return memo + num
                                          },
                                          0
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                })}
                          </Table.Body>
                        </Table>
                      </div>
                    }
                    on={'click'}
                    offset={props?.is_admin ? [-60, 40] : [-10, 30]}
                    trigger={
                      <b className="hover-price-underline">
                        {formatTotalPrice(
                          props.currentPortal === 'supplier'
                            ? highestBidOrder?.total_price
                            : lowestAskOrder?.total_price
                        )}
                        /
                        {
                          props.productVariant?.product?.default_unit_of_measure
                            ?.short_name
                        }
                      </b>
                    }
                  />
                ) : (
                  <b>--</b>
                )}
              </p>
              <p
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'light',
                  marginBottom: '14px',
                  textAlign: 'center',
                }}
                className={
                  props.hoverRow === props.index || props.yourBids
                    ? 'attribute-hover visible'
                    : 'attribute-hover visible'
                }
              >
                {' '}
                {props.currentPortal === 'supplier'
                  ? hasActiveOrder &&
                    hasCurrentSideOrder &&
                    (props.currentPortal === 'supplier'
                      ? userAsk?.quantity +
                        ' ' +
                        userAsk?.cargo_unit?.plural_name
                      : userBid?.quantity +
                        ' ' +
                        userBid?.cargo_unit?.plural_name)
                  : hasOpposingSideOrder
                  ? props.currentPortal === 'supplier'
                    ? highestBidOrder?.quantity +
                      ' ' +
                      highestBidOrder?.cargo_unit?.plural_name
                    : lowestAskOrder?.quantity +
                      ' ' +
                      lowestAskOrder?.cargo_unit?.plural_name
                  : ''}
              </p>
            </div>
            {sellTimeframe ? (
              <label
                style={{
                  backgroundColor:
                    sellTimeframe === 'Prompt' ? '#d0f3cc' : '#c7eaec',
                  color: sellTimeframe === 'Prompt' ? '#0D5740' : '#225061',
                  padding: '0 5px',
                  margin: '0px 0px 15px 9px',
                  borderRadius: '3px',
                  fontSize: '0.83em',
                  height: '20px',
                  width: '90px',
                }}
              >
                {sellTimeframe}
              </label>
            ) : (
              ''
            )}
          </div>
        </Table.Cell>
        <Table.Cell
          width={1}
          style={{
            textAlign: 'center',
          }}
        >
          <Star
            style={{ cursor: 'pointer' }}
            className={'start-watchlist'}
            size={27}
            color={
              props.user?.product_variant_watchlist &&
              props.user?.product_variant_watchlist.find(
                (product_variant) =>
                  product_variant['pv_id'] ===
                    props.exchangeOrderBook?.product_variant?.id &&
                  product_variant['location_id'] ===
                    props.user?.current_location?.id
              )
                ? 'orange'
                : 'grey'
            }
            weight={
              props.user?.product_variant_watchlist &&
              props.user?.product_variant_watchlist.find(
                (product_variant) =>
                  product_variant['pv_id'] ===
                    props.exchangeOrderBook?.product_variant?.id &&
                  product_variant['location_id'] ===
                    props.user?.current_location?.id
              )
                ? 'duotone'
                : 'regular'
            }
            onClick={() => {
              if (hasPermission(user, 'Update Exchange')) {
                if (publicExchange) {
                  toast.error('Login to access Watchlist')
                } else if (!currentLocationId) {
                  toast.error(
                    'Select a delivering from location to update watchlist'
                  )
                } else {
                  props.updateWatchlist(
                    props.exchangeOrderBook?.product_variant?.id,
                    true
                  )
                }
              }
            }}
          />
        </Table.Cell>
        <Table.Cell
          width={1}
          style={{
            textAlign: 'center',
            minWidth: '80px',
          }}
        >
          <div style={{ padding: '0 15px' }}>
            {((props.currentPortal === 'supplier' && userAsk) ||
              (props.currentPortal === 'buyer' && userBid)) &&
              hasPermission(user, 'Update Exchange') && (
                <Button
                  style={{
                    borderRadius: '3px',
                    padding: '10px',
                    height: '35px',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                  color="grey"
                  onClick={() => {
                    props.setOpenEditBidAskModal(true)
                    props.setEditingOrder(
                      props.currentPortal === 'supplier' ? userAsk : userBid
                    )
                    props.setEditingExchangeOrderBook(props.exchangeOrderBook)
                  }}
                >
                  Edit
                </Button>
              )}
            {((props.currentPortal === 'supplier' && !userAsk) ||
              (props.currentPortal === 'buyer' && !userBid) ||
              publicExchange) &&
            hasPermission(user, 'Update Exchange') ? (
              <Button
                basic
                style={{
                  borderRadius: '3px',
                }}
                color="green"
                className={'view-listing-button'}
                onClick={() => {
                  if (publicExchange) {
                    toast.error('Login to Bid')
                  } else {
                    if (setCurrentlySelectedTimeframe) {
                      setCurrentlySelectedTimeframe(
                        props.currentPortal === 'buyer'
                          ? sellTimeframe
                          : buyTimeframe
                      )
                    }
                    if (setCurrentlySelectedTruckingType) {
                      setCurrentlySelectedTruckingType(
                        props.currentPortal === 'buyer'
                          ? lowestAskOrder?.cargo_unit
                          : highestBidOrder?.cargo_unit
                      )
                    }
                    props.setOpenPricingModal(true)
                    props.setProductVariant(
                      props.exchangeOrderBook?.product_variant
                    )
                  }
                }}
              >
                {props.currentPortal === 'supplier' ? 'Ask' : 'Bid'}
              </Button>
            ) : (
              <div style={{ width: '60px' }}></div>
            )}
          </div>
        </Table.Cell>
        <Table.Cell
          width={1}
          style={{
            textAlign: 'center',
            minWidth: '100px',
          }}
        >
          {((props.currentPortal === 'supplier' && userAsk) ||
            (props.currentPortal === 'buyer' && userBid)) &&
            hasPermission(user, 'Delete Exchange') && (
              <>
                <Button
                  style={{
                    borderRadius: '3px',
                    padding: '10px',
                    margin: '0 auto',
                    height: '35px',
                    width: '110px',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  }}
                  color="primary"
                  loading={
                    props.cancelBidLoading &&
                    selectedOrder?.id === props?.exchangeOrderBook?.id
                  }
                  onClick={handleCancelOrderClick}
                >
                  Cancel {props.currentPortal === 'supplier' ? 'Offer' : 'Buy'}
                </Button>
              </>
            )}
          {((props.currentPortal === 'supplier' &&
            !userAsk &&
            highestBidOrder) ||
            (props.currentPortal === 'buyer' && !userBid && lowestAskOrder) ||
            publicExchange) &&
            hasPermission(user, 'Create Exchange') && (
              <Button
                style={{
                  borderRadius: '3px',
                  height: '35px',
                  width: '110px',
                }}
                color="green"
                onClick={() => {
                  if (publicExchange) {
                    toast.error('Login to Buy Now')
                  } else {
                    if (setCurrentlySelectedTimeframe) {
                      setCurrentlySelectedTimeframe(
                        props.currentPortal === 'buyer'
                          ? sellTimeframe
                          : buyTimeframe
                      )
                    }
                    if (setCurrentlySelectedTruckingType) {
                      setCurrentlySelectedTruckingType(
                        props.currentPortal === 'buyer'
                          ? lowestAskOrder?.cargo_unit
                          : highestBidOrder?.cargo_unit
                      )
                    }
                    props.setOpenPricingModal(true)
                    props.setProductVariant(
                      props.exchangeOrderBook?.product_variant
                    )
                    props.setInputQuantity(
                      props.currentPortal === 'supplier'
                        ? highestBidOrder?.quantity
                        : lowestAskOrder?.quantity
                    )
                    props.setInputPrice(
                      props.currentPortal === 'supplier'
                        ? Math.ceil(highestBidOrder?.total_price * 100) / 100
                        : Math.ceil(lowestAskOrder?.total_price * 100) / 100
                    )
                  }
                }}
              >
                {props.currentPortal === 'supplier' ? 'Sell Now' : 'Buy Now'}
              </Button>
            )}
        </Table.Cell>
      </Table.Row>
      <PriceHistoryModal
        showModal={showPriceHistory}
        toggleModal={setShowPriceHistory}
        hideAdd
        exchangeOrderId={priceHistoryOrderId}
      />
    </>
  )
}

export default ExchangeTableRow
