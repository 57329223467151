import { Image, Container } from 'semantic-ui-react'
import logo from '../../../img/404 -01.png'
import './404.css'
import { useNavigate } from 'react-router-dom'
import env from '../../../env'

export default function FourZeroFour() {
  const navigate = useNavigate()
  return (
    <Container style={{ margin: '50px 0' }}>
      <Image centered size={'big'} src={logo} alt="Logo" />
      <div className="info_text">
        <h1>
          <span style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}>
            Oops
          </span>
          , this page may be on a
        </h1>
        <h1>truck being delivered to its buyer.</h1>
        <h3 style={{ fontWeight: 'normal' }}>
          The page you are looking for might have been removed, had its name
          changed, or is temporarily unavailable
        </h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button
            className="ui button bad-route-btn "
            style={{
              width: '13rem',
              height: '3.5rem',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
            onClick={() => navigate('/')}
          >
            Go Back Home
          </button>
        </div>
      </div>
    </Container>
  )
}
