import React, { useEffect, useState } from 'react'
import { Table, Popup, Header } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'

const AdminChangeOrderItemsRow = (props) => {
  const { changeOrderLineItem } = props
  const axios = useAxios()
  const [orderItemChanges, setOrderItemChanges] = useState([])

  useEffect(() => {
    const getChangeOrderLineItems = async () => {
      let res = await axios.get(`/main/change-order-line-items/`, {
        params: {
          order_item_id: changeOrderLineItem.order_item?.id,
        },
      })
      if (res?.data?.results) {
        setOrderItemChanges(res?.data?.results)
      }
    }
    if (changeOrderLineItem) {
      getChangeOrderLineItems()
    }
  }, [])

  return (
    <Table.Row>
      <Table.Cell width={2}>
        {changeOrderLineItem.order_item.product_variant.product.material.name}{' '}
        {
          changeOrderLineItem.order_item.product_variant.product.commodity_form
            .name
        }
      </Table.Cell>

      <Table.Cell width={2}>
        {changeOrderLineItem.previous_quantity} /
        {changeOrderLineItem.unit_of_measure.short_name}
      </Table.Cell>
      <Table.Cell width={2}>
        {parseFloat(changeOrderLineItem.quantity)} /
        {changeOrderLineItem.unit_of_measure.short_name}
      </Table.Cell>
      <Table.Cell width={2}>
        {changeOrderLineItem.change_in_quantity > 0
          ? `+${changeOrderLineItem.change_in_quantity} /${changeOrderLineItem.unit_of_measure.short_name}`
          : `${changeOrderLineItem.change_in_quantity} /${changeOrderLineItem.unit_of_measure.short_name}`}
      </Table.Cell>
    </Table.Row>
  )
}

export default AdminChangeOrderItemsRow
