import {
  emailValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const BuyerForm = {
  model: 'Business',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'text',
          label: 'Legal Business Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_first_name: {
          type: 'text',
          label: 'First Name',
        },
        contact_last_name: {
          type: 'text',
          label: 'Last Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        contact_email: {
          type: 'text',
          label: 'Email',
          validator: (input) => {
            return emailValidator(input)
          },
        },
        contact_phone: {
          type: 'phone',
          label: 'Phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
      },
    },
  ],
}
