import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Header, Popup } from 'semantic-ui-react'
import _ from 'underscore'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import PriceHistoryRow from './PriceHistoryRow'
import { hasPricing } from '../../lib/utils/utils'
import env from '../../env'
import { useSelector } from 'react-redux'
import PriceHistoryFormModal from './PriceHistoryFormModal'
import './PriceHistoryTable.css'
import PriceHistoryGraph from './PriceHistoryGraph'
import SelectProductAttribute from './SelectProductAttribute'
import { Plus } from 'phosphor-react'

const PriceHistoryTable = (props) => {
  const {
    businessId,
    businessName,
    hideHeader,
    hideBusiness,
    productVariantId,
    exchangeOrderId,
    hideAdd,
    hideTabs,
    supplier,
    buyer,
    hideFullHeader,
  } = props
  const [side, setSide] = useState(
    (hideBusiness && supplier) || !hideBusiness ? 'ask' : 'bid'
  )
  const [activeItem, setActiveItem] = useState('Table')
  const user = useSelector((state) => state.user.user)
  const [showPriceHistoryForm, setShowPriceHistoryForm] = useState(false)
  const [editPriceHistory, setEditPriceHistory] = useState()
  const [reload, setReload] = useState()
  const primaryPricing = hasPricing(user, 'primary')
  const [timeWindow, setTimeWindow] = useState('2-weeks')
  const [newProductVarientModel, setNewProductVarientModel] = useState(null)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [productVariant, setProductVariant] = useState([])
  const headerRow = primaryPricing
    ? [
        'Product',
        'Location',
        'Primary Price',
        'Secondary Price',
        'Unit of Measure',
        'Date',
        'Set By User',
      ]
    : ['Product', 'Location', 'Price', 'Unit of Measure', 'Date', 'Set By User']
  const hideBusiness_ = hideBusiness ? hideBusiness : false
  const userSearchOptionParams = {
    extra_light_serializer: 'true',
  }
  const locationSearchOptionParams = {}
  if (businessId) {
    userSearchOptionParams.business_id = businessId
    locationSearchOptionParams.business_id = businessId
  }
  const headerSort = [
    {
      header: 'Product',
      sort_by: 'product__product_name',
    },
    {
      header: 'Location',
      sort_by: 'location',
    },
    {
      header: 'Primary Price',
      sort_by: '_price',
    },
    {
      header: 'Price',
      sort_by: '_price',
    },
    {
      header: 'Secondary Price',
      sort_by: '_secondary_price',
    },
    {
      header: 'Date',
      sort_by: 'created_at',
    },
    {
      header: 'Unit or Measure',
      sort_by: 'unit',
    },
    {
      header: 'Set By User',
      sort_by: 'user__email',
    },
  ]
  const headerSearch = [
    {
      header: 'Product',
      type: 'search',
      input_type: 'text',
      search_param: 'product_name_icontains',
    },
    {
      header: 'Live',
      type: 'dropdown',
      options: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      search_param: 'live',
    },
    {
      header: 'Side',
      type: 'dropdown',
      options: [
        {
          text: 'Bid',
          value: 'bid',
        },
        {
          text: 'Ask',
          value: 'ask',
        },
      ],
      search_param: 'side',
    },
    {
      header: 'Location',
      type: 'dropdown',
      options_url: 'admin-locations/',
      options_titleFields: ['address_1', 'address_2'],
      options_descriptionFields: ['city', 'state', 'zip_code'],
      options_params: locationSearchOptionParams,
      search_param: 'location_id',
    },
    {
      header: 'Price',
      type: 'number_range',
      search_param_start: 'price_start',
      search_param_end: 'price_end',
    },
    {
      header: 'Primary Price',
      type: 'number_range',
      search_param_start: 'price_start',
      search_param_end: 'price_end',
    },
    {
      header: 'Secondary Price',
      type: 'number_range',
      search_param_start: 'secondary_price_start',
      search_param_end: 'secondary_price_end',
    },
    {
      header: 'Date',
      type: 'date_range',
      search_param_start: 'created_at_start',
      search_param_end: 'created_at_end',
    },
    {
      header: 'Unit of Measure',
      type: 'dropdown',
      options_url: 'unit-of-measures/',
      options_titleFields: ['short_name'],
      options_params: {},
      search_param: 'unit_id',
    },
    {
      header: 'Set By User',
      type: 'dropdown',
      options_url: 'custom-users/',
      options_titleFields: ['email'],
      options_params: userSearchOptionParams,
      search_param: 'user_id',
    },
  ]
  if (!hideBusiness_) {
    headerRow.splice(5, 0, 'Set By Business')
    headerSort.splice(5, 0, {
      header: 'Set By Business',
      sort_by: 'exchange_order__business__name',
    })
    headerSearch.splice(5, 0, {
      header: 'Set By Business',
      type: 'dropdown',
      options_url: 'businesses/',
      options_params: {
        extra_light_serializer: 'true',
        all_businesses: 'true',
      },
      options_titleFields: ['name'],
      search_param: 'business_id',
    })
  }
  const urlParams = {}
  if (businessId) {
    urlParams.business_id = businessId
  }
  if (productVariantId) {
    urlParams.product_variant_id = productVariantId
  }
  if (exchangeOrderId) {
    urlParams.exchange_order_id = exchangeOrderId
  }
  if (!exchangeOrderId) {
    urlParams.side = side
  }

  useEffect(() => {
    if (activeItem === 'Graph') {
      setTimeWindow('2-weeks')
      setNewProductVarientModel(false)
      setSelectedProducts([])
      setProductVariant([])
    }
  }, [activeItem])

  const headerTabJSx = (set) => {
    return (
      <Header
        as={'h2'}
        className={
          activeItem === set ? 'commodity-header selected' : 'commodity-header'
        }
        color={activeItem === set ? 'primary' : 'black'}
        onClick={() => setActiveItem(set)}
        style={{
          margin: '0 1rem 1rem 0',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        }}
        content={set}
      />
    )
  }

  let dropdownOptions = []
  if ((hideBusiness && supplier) || !hideBusiness) {
    dropdownOptions.push({ key: 1, value: 'ask', text: 'Supplier' })
  }
  if ((hideBusiness && buyer) || !hideBusiness) {
    dropdownOptions.push({ key: 1, value: 'bid', text: 'Buyer' })
  }

  let buttons = []
  if (primaryPricing && !hideAdd) {
    buttons.push({
      color: 'primary',
      className: 'supplier-step-2 buyer-step-2',
      icon: <Plus weight="bold" />,
      content: 'Add Price History',
      onClick: () => {
        setShowPriceHistoryForm(true)
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    })
  }

  return (
    <div id="price-history">
      <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
        {!hideFullHeader && (
          <Grid.Column
            width={8}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={hideHeader ? '' : 'Price History'}
              showDivider={false}
              buttons={buttons}
              otherComponent={
                <Dropdown
                  className="header-dropdown"
                  fluid
                  selection
                  placeholder={'Select side'}
                  value={side}
                  onChange={(e, d) => setSide(d.value)}
                  options={dropdownOptions}
                />
              }
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        )}
        {!hideTabs && (
          <Grid.Column width={8} className="ph-tabs">
            <div className="exchange-table-heading">
              <div className="commodities-wrapper">
                {headerTabJSx('Table')}
                {headerTabJSx('Graph')}
              </div>
              {activeItem === 'Graph' && (
                <div className="graph-btn">
                  <Popup
                    on="click"
                    offset={[-100, 0]}
                    open={newProductVarientModel}
                    onOpen={() => setNewProductVarientModel(true)}
                    onClose={() => setNewProductVarientModel(false)}
                    trigger={
                      <Button
                        icon="plus"
                        content={'Add Comparison Product'}
                        onClick={() => setNewProductVarientModel(true)}
                      />
                    }
                    position="bottom left"
                    content={
                      <SelectProductAttribute
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        productVariant={productVariant}
                        setProductVariant={setProductVariant}
                        setNewProductVarientModel={setNewProductVarientModel}
                      />
                    }
                  />
                  <Dropdown
                    fluid
                    selection
                    placeholder={'Time Window'}
                    value={timeWindow}
                    onChange={(e, d) => setTimeWindow(d.value)}
                    options={[
                      { key: 1, value: '2-weeks', text: 'Last 2 weeks' },
                      { key: 2, value: 'quarter', text: 'Last quarter' },
                      { key: 3, value: '6-months', text: 'Last 6 months' },
                      { key: 4, value: 'year', text: 'Last year' },
                    ]}
                  />
                </div>
              )}
            </div>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={8}>
          {activeItem === 'Table' && (
            <MickeyTable
              headerRow={headerRow}
              headerSearch={headerSearch}
              headerSort={headerSort}
              urlParams={urlParams}
              reload={reload}
              basicSegment
              showHeaderNoData={true}
              url={'/main/exchange-order-price-history/'}
              renderBodyRow={(data) => {
                return (
                  <PriceHistoryRow
                    priceHistory={data}
                    hideBusiness={hideBusiness_}
                    setReload={setReload}
                    openEditForm={() => {
                      setShowPriceHistoryForm(true)
                      setEditPriceHistory(data)
                    }}
                  />
                )
              }}
              emptyIcon={'money'}
              emptyMessage={'No price history'}
            />
          )}
          {activeItem === 'Graph' && (
            <PriceHistoryGraph
              side={side}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              productVariant={productVariant}
              setProductVariant={setProductVariant}
              newProductVarientModel={newProductVarientModel}
              setNewProductVarientModel={setNewProductVarientModel}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <PriceHistoryFormModal
        showModal={showPriceHistoryForm}
        toggleModal={() => {
          setShowPriceHistoryForm(false)
          setEditPriceHistory()
        }}
        businessId={businessId}
        businessName={businessName}
        setReload={setReload}
        priceHistory={editPriceHistory}
      />
    </div>
  )
}

export default PriceHistoryTable
