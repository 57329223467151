import React, { useEffect } from 'react'
import './MXAbout.css'
import { Button, Header, Grid } from 'semantic-ui-react'
import face from '../../../img/face.svg'
import people from '../../../img/users-three.svg'
import security from '../../../img/security-check.svg'
import location from '../../../img/map-pin-line.svg'
import forward from '../../../img/fast-forward.svg'
import headphone from '../../../img/headphone.svg'
import AutomatedLogistics from '../../../img/automated-logistics.png'
import BalanceAndInvoicing from '../../../img/balance-and-invoicing.png'
import BackgroundVideo from '../../../video/about-page-video.webm'
import BackgroundVideoStill from '../../../img/background-video-still.png'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import OurTechnology from '../../../img/our-technology.png'
import TechnologySlider from './TechnologySlider'
import { useNavigate } from 'react-router-dom'

const MXAbout = () => {
  const wd = useWindowDimensions()
  const navigate = useNavigate()
  useEffect(() => {
    if (
      env.REACT_APP_REDIRECT &&
      env.REACT_APP_REDIRECT !== 'false' &&
      env.REACT_APP_REDIRECT !== 'null'
    ) {
      navigate('/login')
    }
  }, [])
  return (
    <div id="about-wrapper">
      <div id="video-wrapper">
        {wd.width <= 500 && <img src={BackgroundVideoStill} alt="" />}
        {wd.width > 500 && (
          <video
            className="background-video"
            preload="true"
            loop
            muted
            autoPlay
          >
            <source src={BackgroundVideo} type="video/webm" />
          </video>
        )}
        <Grid
          className="overlay-copy"
          style={{
            flexDirection: 'column',
            top: wd.width <= 950 && '10vh',
          }}
          width={16}
        >
          <Grid.Column
            width={
              (wd.width <= 950 && wd.width >= 768 && 10) ||
              (wd.width < 768 && 13) ||
              (wd.width > 950 && 6)
            }
            style={{ marginBottom: '3rem' }}
          >
            <Header
              textAlign="center"
              className="future"
              as={'p'}
              style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)' }}
            >
              WELCOME TO THE FUTURE OF THE {env.REACT_APP_INDUSTRY} INDUSTRY
            </Header>
            <Header
              textAlign="center"
              className="digital"
              as={'h1'}
              style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)' }}
            >
              {env.REACT_APP_ABOUT_PAGE_TITLE}
            </Header>
          </Grid.Column>
          <Grid.Column
            width={
              (wd.width <= 950 && wd.width >= 768 && 10) ||
              (wd.width < 768 && 13) ||
              (wd.width > 950 && 8)
            }
            style={{ marginBottom: '3rem' }}
          >
            <Header
              textAlign="center"
              className="mission"
              as={'p'}
              style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)' }}
            >
              {env.REACT_APP_ABOUT_PAGE_SUBTITLE}
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            width={8}
            style={{ marginBottom: '5rem' }}
          >
            <Button
              color="primary"
              href="/apply"
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
                padding: '1rem',
                borderRadius: '500px',
              }}
            >
              Request Demo
            </Button>
          </Grid.Column>
        </Grid>
      </div>
      <Grid stackable>
        <Grid.Row
          width={
            (wd.width <= 950 && wd.width >= 768 && 10) ||
            (wd.width < 768 && 13) ||
            (wd.width > 950 && 6)
          }
        >
          <Header
            textAlign="center"
            as={'h1'}
            style={{
              fontSize: '48px',
              width: wd.width > 768 ? '40%' : '100%',
              padding: '4rem 1rem',
              margin: '0 auto',
            }}
          >
            Innovative technology at each stage of your orders
          </Header>
        </Grid.Row>
        <Grid.Row>
          <TechnologySlider />
        </Grid.Row>
      </Grid>
      <Grid
        stackable
        width={16}
        className="page-section"
        style={{ flexDirection: wd.width <= 950 ? 'column' : 'row' }}
      >
        <Grid.Column
          textAlign={wd.width <= 950 ? 'center' : 'left'}
          width={wd.width <= 950 ? 14 : 7}
          className="section-left"
          style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
        >
          <Header as={'h1'} className="section-header multi-gray">
            {env.REACT_APP_ABOUT_PAGE_HEADER1}
            <Header.Subheader as={'p'} className="section-subheader">
              {env.REACT_APP_ABOUT_PAGE_SUBHEADER1}
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={wd.width <= 950 ? 14 : 9} className="section-right">
          <img src={OurTechnology} className="section-image" alt="" />
        </Grid.Column>
      </Grid>

      <Grid
        style={{ flexDirection: wd.width <= 950 ? 'column-reverse' : 'row' }}
        stackable
        width={16}
        className="page-section"
      >
        <Grid.Column width={wd.width <= 950 ? 14 : 9} className="section-left">
          <img src={AutomatedLogistics} className="section-image" alt="" />
        </Grid.Column>
        <Grid.Column
          textAlign={wd.width <= 950 ? 'center' : 'left'}
          width={wd.width <= 950 ? 14 : 7}
          className="section-right"
          style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
        >
          <Header as={'p'} style={{ fontSize: '18px', color: '#f1592a' }}>
            The Platform
          </Header>
          <Header
            as={'h1'}
            className="section-header"
            style={{ fontSize: '36px' }}
          >
            {env.REACT_APP_ABOUT_PAGE_HEADER2}
            <Header.Subheader
              as={'p'}
              className="section-subheader"
              style={{ fontSize: '16px', lineHeight: '1.5em' }}
            >
            {env.REACT_APP_ABOUT_PAGE_SUBHEADER2}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid>

      <Grid
        style={{ flexDirection: wd.width <= 950 ? 'column' : 'row' }}
        stackable
        width={16}
        className="page-section"
      >
        <Grid.Column
          textAlign={wd.width <= 950 ? 'center' : 'left'}
          width={wd.width <= 950 ? 14 : 7}
          className="section-left"
          style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
        >
          <Header as={'p'} style={{ fontSize: '18px', color: '#f1592a' }}>
            The Platform
          </Header>
          <Header
            as={'h1'}
            className="section-header"
            style={{ fontSize: '36px' }}
          >
            {env.REACT_APP_ABOUT_PAGE_HEADER3}
            <Header.Subheader
              as={'p'}
              className="section-subheader"
              style={{ fontSize: '16px', lineHeight: '1.5em' }}
            >
            {env.REACT_APP_ABOUT_PAGE_SUBHEADER3}
            </Header.Subheader>
          </Header>
        </Grid.Column>
        <Grid.Column width={wd.width <= 950 ? 14 : 9} className="section-right">
          <img src={BalanceAndInvoicing} className="section-image" alt="" />
        </Grid.Column>
      </Grid>

      {/* <Grid stackable width={16} className='page-section'>
                <Grid.Column width={16}>
                    <Header as={"h2"} className="section-header" textAlign='center'>Affiliations</Header>
                    <Header as={"p"} className="section-subheader" style={{ fontSize: "24px" }} textAlign='center'>We Support the Industry</Header>
                </Grid.Column>
                <Grid.Row columns={wd.width > 950 ? 6 : ""} verticalAlign='center' style={{ flexDirection: wd.width <= 950 ? "column" : "row", alignItems: wd.width <= 950 ? "center" : "" }}>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: (wd.width <= 950 && wd.width >= 768 && "3rem") || (wd.width < 768 && "6rem !important") }} textAlign='center' >
                        <img className='affiliation-logo' src={SBCA} alt=''/>
                    </Grid.Column>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: wd.width <= 950 ? "3rem" : "" }} textAlign='center' >
                        <img className='affiliation-logo' src={LAT} alt=''/>
                    </Grid.Column>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: wd.width <= 950 ? "3rem" : "" }} textAlign='center'>
                        <img className='affiliation-logo' src={NAWLA} alt=''/>
                    </Grid.Column>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: wd.width <= 950 ? "3rem" : "" }} textAlign='center'>
                        <img className='affiliation-logo' style={{ maxWidth: "80px" }} src={BMSA} alt=''/>
                    </Grid.Column>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: wd.width <= 950 ? "3rem" : "" }} textAlign='center'>
                        <img className='affiliation-logo' src={NLBMDA} alt=''/>
                    </Grid.Column>
                    <Grid.Column style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: wd.width <= 950 ? "40%" : "", marginBottom: wd.width <= 950 ? "3rem" : "" }} textAlign='center'>
                        <img className='affiliation-logo' src={Alliance} alt=''/>
                    </Grid.Column>
                </Grid.Row>
            </Grid> */}

      {/* <Grid */}
      {/*   stackable */}
      {/*   width={16} */}
      {/*   className="page-section" */}
      {/*   style={{ justifyContent: 'center' }} */}
      {/* > */}
      {/*   <Grid.Column width={wd.width <= 950 ? 14 : 7}> */}
      {/*     <Header */}
      {/*       as={'h1'} */}
      {/*       className="section-header multi-gray" */}
      {/*       textAlign="center" */}
      {/*     > */}
      {/*       Marketplace */}
      {/*       <Header.Subheader */}
      {/*         as={'p'} */}
      {/*         className="section-subheader" */}
      {/*         style={{ textAlign: wd.width <= 950 ? 'center' : 'left' }} */}
      {/*       > */}
      {/*         Our marketplace is user-friendly and ultra-efficient. It is a */}
      {/*         network of digitally connected users, communicating in real-time */}
      {/*         with instantaneous interactions. */}
      {/*       </Header.Subheader> */}
      {/*     </Header> */}
      {/*   </Grid.Column> */}
      {/* </Grid> */}
      <Grid
        stackable
        width={16}
        style={{ justifyContent: 'space-between', padding: '0 6rem' }}
      >
        <Grid.Row style={{ marginBottom: wd.width <= 768 ? '' : '3rem' }}>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={face} alt="" />
            <Header as={'h5'} className="value-header">
              User-friendly
              <Header.Subheader as={'p'} className="value-subheader">
                Buying and selling lumber should be easy
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={people} alt="" />
            <Header as={'h5'} className="value-header">
              Transparent Pricing
              <Header.Subheader as={'p'} className="value-subheader">
                Accessing a product's price should be available to everyone
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={security} alt="" />
            <Header as={'h5'} className="value-header">
              Security
              <Header.Subheader as={'p'} className="value-subheader">
                Transferring money between parties for goods bought and sold
                should be quick, seamless, and secure.
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={location} alt="" />
            <Header as={'h5'} className="value-header">
              Trackable
              <Header.Subheader as={'p'} className="value-subheader">
                Shipping from seller to buyer should be trackable and
                hassle-free.
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={forward} alt="" />
            <Header as={'h5'} className="value-header">
              Fast
              <Header.Subheader as={'p'} className="value-subheader">
                Making transactions should be instant — with just a click of a
                button.
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column className="value-section" textAlign="center" width={4}>
            <img className="value-image" src={headphone} alt="" />
            <Header as={'h5'} className="value-header">
              Support
              <Header.Subheader as={'p'} className="value-subheader">
                Our team will support you troubleshoot and help you with
                problems on our app.
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid
        stackable
        width={16}
        className="page-section"
        style={{ justifyContent: 'center', marginBottom: '200px' }}
      >
        <Grid.Column textAlign="center" width={wd.width <= 950 ? 14 : 8}>
          <Header
            as={'h1'}
            className="section-header"
            textAlign="center"
            style={{ marginBottom: '3rem' }}
          >
            <span style={{ color: '  rgb(161, 161, 161)' }}>Customers</span>{' '}
            matter <span style={{ color: '  rgb(161, 161, 161)' }}>to </span>
            {env.REACT_APP_BUSINESS_NAME}
            <Header.Subheader
              as={'p'}
              className="section-subheader"
              style={{ textAlign: wd.width <= 950 ? 'center' : 'left' }}
            >
              Our marketplace is user-friendly and ultra-efficient. It is a
              network of digitally connected users, communicating in real-time
              with instantaneous interactions.
            </Header.Subheader>
          </Header>
          <Button
            color="primary"
            href="/contact-us"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              padding: '1rem',
              borderRadius: '500px',
            }}
          >
            Contact Us
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default MXAbout
