import React, { useState, useEffect } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import './CustomLumberLength.css'

const CustomLumberLength = (props) => {
  const { attributeName, handleUpdateCustomValues } = props
  const [lumberLengthCustomTally, setLumberLengthCustomTally] = useState([])

  const updateCustomTally = (tallyInput) => {
    if (lumberLengthCustomTally.length < 1) {
      setLumberLengthCustomTally([tallyInput])
    }
    if (lumberLengthCustomTally.length > 0) {
      let existingInput = lumberLengthCustomTally.find(
        (tally) => tally.order === tallyInput.order
      )
      if (existingInput) {
        let lumberLengthCustomTallyCopy = [...lumberLengthCustomTally]
        const existingIndex = lumberLengthCustomTallyCopy.findIndex(
          (tally) => tally.order === tallyInput.order
        )
        existingInput.value = tallyInput.value
        if (existingIndex !== -1) {
          lumberLengthCustomTally[existingIndex] = existingInput
          const sortedTallies = lumberLengthCustomTallyCopy.sort(
            (a, b) => a.order - b.order
          )
          setLumberLengthCustomTally(sortedTallies)
        }
      } else {
        setLumberLengthCustomTally((lumberLengthCustomTally) => [
          ...lumberLengthCustomTally,
          tallyInput,
        ])
      }
    }
  }

  const handleNumberInput = (obj) => {
    if (obj.value) {
      obj.value = parseInt(obj.value, 10).toString()
    }
    updateCustomTally(obj)
  }

  useEffect(() => {
    handleUpdateCustomValues(attributeName, lumberLengthCustomTally)
  }, [lumberLengthCustomTally])

  return (
    <Form id="custom-tally-form">
      <Grid>
        <Grid.Column width={8}>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">8' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 0,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">10' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 1,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">12' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 2,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">14' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 3,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">16' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 4,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">18' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 5,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
          <div className="length-wrapper" style={{ display: 'flex' }}>
            <p className="length-value">20' Lengths</p>
            <Form.Input
              type="number"
              min="0"
              onChange={(e, d) =>
                handleNumberInput({
                  order: 6,
                  value: d.value,
                  attributeName: attributeName,
                })
              }
            />
          </div>
        </Grid.Column>
      </Grid>
    </Form>
  )
}

export default CustomLumberLength
