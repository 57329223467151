import React, { useEffect, useState } from 'react'
import {
  Grid,
  Loader,
  Button,
  List,
  Popup,
  PopupContent,
} from 'semantic-ui-react'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import ESignRequestRow from './ESignRequestRow'
import { toast } from 'react-toastify'
import useAxios from '../../lib/auth/useAxios'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import env from '../../env'
import { CaretDown, CaretRight } from 'phosphor-react'
import './ESignRequestsTable.css'

const ESignRequestsTable = (props) => {
  const {
    businessId,
    orderId,
    supplierId,
    buyerId,
    reload,
    setReload,
    style,
    businessIdUsers,
    settlementStatementId,
    regenerateArgs,
    documentName,
    generationDescription,
    popupPosition,
    hideActions,
    hideHeader,
    purchaseOrderId,
    cargoUnitInstanceId,
  } = props
  const [innerReload, setInnerReload] = useState(
    reload === true || reload === false ? reload : false
  )
  const [regenerateLoading, setRegenerateLoading] = useState(false)
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const [openActions, setOpenActions] = useState(false)

  useEffect(() => {
    if (innerReload) {
      setInnerReload(false)
    }
  }, [innerReload])

  async function sendSignRequest(sendEmail) {
    if (!regenerateLoading) {
      setRegenerateLoading(true)
      if (!regenerateArgs.delay) {
        toast.success(
          `Please wait while the ${documentName} generation is queued`
        )
      }
      regenerateArgs.send_to_customer = sendEmail
      let res = await axios.post(`/main/box-sign-requests/`, regenerateArgs)
      if (res?.data.success) {
        if (innerReload) {
          setInnerReload(false)
        }
        setInnerReload(true)
        toast.success(`${documentName} generation started!`)
      } else if (res?.data && res?.data?.error) {
        toast.error(res?.data?.error)
      } else {
        toast.error(`Error generating ${documentName}`)
      }
      setRegenerateLoading(false)
    }
  }

  const urlParams = () => {
    const params = {
      page_size: 10,
    }
    if (businessId) {
      params.business_id = businessId
    }
    if (settlementStatementId) {
      params.settlement_statement_id = settlementStatementId
    }
    if (orderId) {
      params.order_id = orderId
    }
    if (supplierId) {
      params.supplier_id = supplierId
    }
    if (buyerId) {
      params.buyer_id = buyerId
    }
    if (cargoUnitInstanceId) {
      params.cargo_unit_instance_id = cargoUnitInstanceId
    }
    if (businessIdUsers) {
      params.business_id_users = businessIdUsers
    }
    if (purchaseOrderId) {
      params.purchase_order_id = purchaseOrderId
    }
    return params
  }
  const headers = ['Document', 'Date Sent', 'Generation Status']
  if (!hideActions) {
    headers.push('')
  }

  const headerSearch = [
    {
      header: 'Document',
      type: 'dropdown',
      options_url: 'box-sign-requests/',
      options_titleFields: ['document_name'],
      options_valueFields: ['document_name'],
      search_param: 'document_name',
    },
    {
      header: 'Date Sent',
      type: 'date_range',
      search_param_start: 'created_at_start',
      search_param_end: 'created_at_end',
    },
    {
      header: 'Generation Status',
      type: 'dropdown',
      options: [
        {
          text: 'Pending Internal Review',
          value: 'pending_internal_review',
        },
        {
          text: 'Sent for Signature',
          value: 'sent',
        },
        {
          text: 'Complete',
          value: 'signed',
        },
        {
          text: 'Declined',
          value: 'declined',
        },
      ],
      search_param: 'sign_status',
    },
  ]
  const headerSort = [
    {
      header: 'Document',
      sort_by: 'document_name',
    },
    {
      header: 'Date Sent',
      sort_by: 'created_at',
    },
    {
      header: 'Generation Status',
      sort_by: 'sign_status',
    },
  ]

  return (
    <>
      <Grid.Row className={'no-bottom-margin no-bottom-padding'} style={style}>
        <Grid.Column
          width={16}
          className={'no-bottom-margin no-bottom-padding'}
        >
          {!hideHeader && (
            <PageHeader
              header={
                generationDescription
                  ? 'Document Generation'
                  : 'Document Generation'
              }
              showDivider
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          )}
        </Grid.Column>
        {!hideActions &&
          regenerateArgs &&
          hasPermission(user, 'Create Document') && (
            <>
              <Grid.Column>
                <div id="esign-request-actions">
                  <Popup
                    id={'send-document-popup'}
                    mountNode={
                      document.getElementsByClassName(`send-document-button`)[0]
                    }
                    on={'click'}
                    position={'bottom center'}
                    style={{
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    }}
                    open={openActions}
                    onOpen={() => setOpenActions(true)}
                    onClose={() => setOpenActions(false)}
                    offset={[0, 0]}
                    trigger={
                      <Button
                        basic
                        className="send-document-button"
                        labelPosition={'left'}
                        color={'primary'}
                        style={{
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          '--secondaryButtonColor':
                            env.REACT_APP_SECONDARY_BUTTON_COLOR,
                          color: env.REACT_APP_PRIMARY_COLOR,
                          boxShadow: env.REACT_APP_PRIMARY_COLOR,
                        }}
                        size={'tiny'}
                        icon={
                          openActions ? (
                            <CaretDown size={20} weight="bold" />
                          ) : (
                            <CaretRight size={20} weight="bold" />
                          )
                        }
                        content={'Actions'}
                        onClick={() => {
                          setOpenActions(true)
                        }}
                      />
                    }
                  >
                    <PopupContent>
                      <List>
                        <List.Item
                          style={{
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            sendSignRequest(false)
                          }}
                        >
                          {regenerateLoading && <Loader />}
                          {!regenerateLoading && <>Generate Document</>}
                        </List.Item>
                        <List.Item
                          style={{
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          loading={regenerateLoading}
                          onClick={() => {
                            sendSignRequest(true)
                          }}
                        >
                          {regenerateLoading && <Loader />}
                          {!regenerateLoading && (
                            <>Generate Document and Send to Customer</>
                          )}
                        </List.Item>
                      </List>
                    </PopupContent>
                  </Popup>
                </div>
              </Grid.Column>
            </>
          )}
      </Grid.Row>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={16}>
          <MickeyTable
            selectable={true}
            headerRow={headers}
            basicSegment
            headerSort={headerSort}
            headerSearch={headerSearch}
            reload={innerReload}
            unselectable
            setReload={setInnerReload}
            urlParams={urlParams()}
            url={'/main/box-sign-requests/'}
            renderBodyRow={(data, index) => {
              return (
                <ESignRequestRow
                  key={index}
                  generationDescription={generationDescription}
                  eSignRequest={data}
                  setInnerReload={setInnerReload}
                  setReload={setReload}
                  reload={reload}
                  popupPosition={popupPosition}
                  actions={!hideActions}
                  regenerateArgs={regenerateArgs}
                />
              )
            }}
            emptyIcon={'file'}
            emptyMessage={
              generationDescription
                ? 'No document generation'
                : 'No document generation'
            }
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default ESignRequestsTable
