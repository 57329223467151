import React, { useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import './TechnologySlider.css'
import Delivered from '../../../img/delivered.png'
import Invoice from '../../../img/invoice.png'
import OrderConfirmation from '../../../img/order-confirmation.png'
import { Grid, Header } from 'semantic-ui-react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { ArrowCircleRight, Bank, CreditCard, Swap } from 'phosphor-react'
import env from '../../../env'


function TechnologySlider(props) {
  const wd = useWindowDimensions()
  const [selectedTab, setSelectedTab] = useState(1)

  return (
    <>
      <Grid.Column style={{ width: '100%' }}>
        <ol className="slider">
          <li
            onClick={() => {
              setSelectedTab(1)
            }}
            style={{ textAlign: 'left' }}
          >
            <span
              style={{
                fontWeight: 'bold',
                color: selectedTab === 1 ? 'black' : 'gray',
                display: 'block',
                height: '39px',
              }}
            >
              1. Order Generation
            </span>
            <button
              className={'slider-button' + (selectedTab === 1 ? ' active' : '')}
            />
          </li>
          <li
            onClick={() => {
              setSelectedTab(2)
            }}
          >
            <span
              style={{
                fontWeight: 'bold',
                color: selectedTab === 2 ? 'black' : 'gray',
                display: 'block',
                height: '39px',
                paddingLeft: '10px',
              }}
            >
              2. Payment
            </span>
            <button
              className={'slider-button' + (selectedTab === 2 ? ' active' : '')}
            />
          </li>
          <li
            onClick={() => {
              setSelectedTab(3)
            }}
            style={{ textAlign: 'right' }}
          >
            <span
              style={{
                fontWeight: 'bold',
                color: selectedTab === 3 ? 'black' : 'gray',
                display: 'block',
                height: '39px',
                paddingLeft: '7px',
              }}
            >
              3. Order Delivery
            </span>
            <button
              className={'slider-button' + (selectedTab === 3 ? ' active' : '')}
            />
          </li>
        </ol>
      </Grid.Column>
      {selectedTab === 1 && (
        <Grid
          style={{
            flexDirection: wd.width <= 950 ? 'column-reverse' : 'row',
            marginTop: '100px',
          }}
          stackable
          width={16}
          className="page-section"
        >
          <Grid.Column
            width={wd.width <= 950 ? 14 : 9}
            className="section-left"
          >
            <img src={OrderConfirmation} className="section-image" alt="" />
          </Grid.Column>
          <Grid.Column
            textAlign={wd.width <= 950 ? 'center' : 'left'}
            width={wd.width <= 950 ? 14 : 7}
            className="section-right"
            style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
          >
            <Header
              as={'h1'}
              className="section-header"
              style={{ fontSize: '36px' }}
            >
              Tech Enabled order matching and fulfillment
              <Header.Subheader as={'p'} className="tab-subheader">
                Our online portal and automated emails will guide you through
                every step of the order.
              </Header.Subheader>
            </Header>
            <ul className="bullets">
              <li>
                A buyer and supplier match on item, price, and delivery
                expectations. Included in the total price the material cost and
                shipping. A marketplace fee ($150/ truck and $500/ rail car) is
                marked as another line item.
              </li>
              <li>
                Buyer and supplier receive an order confirmation email and are
                prompted to enter a PO and SO, respectively into their order
                portals on {env.REACT_APP_BUSINESS_NAME}.
              </li>
              <li>A truck is then dispatched.</li>
            </ul>
          </Grid.Column>
        </Grid>
      )}
      {selectedTab === 2 && (
        <Grid
          style={{
            flexDirection: wd.width <= 950 ? 'column-reverse' : 'row',
            marginTop: '100px',
          }}
          stackable
          width={16}
          className="page-section"
        >
          <Grid.Column
            width={wd.width <= 950 ? 14 : 9}
            className="section-left"
          >
            <img src={Invoice} className="section-image" alt="" />
          </Grid.Column>
          <Grid.Column
            textAlign={wd.width <= 950 ? 'center' : 'left'}
            width={wd.width <= 950 ? 14 : 7}
            className="section-right"
            style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
          >
            <Header
              as={'h1'}
              className="section-header"
              style={{ fontSize: '36px' }}
            >
              Payment simplified
              <Header.Subheader as={'p'} className="tab-subheader">
                At loading, the supplier enters the BOL into {env.REACT_APP_BUSINESS_NAME}, which
                automatically invoices the buyer with 4 payment options.
              </Header.Subheader>
            </Header>
            <Grid style={{ width: '100%' }}>
              <Grid.Column style={{ width: wd.width <= 768 ? '100%' : '50%' }}>
                <Bank size={48} color="#f1592a" />
                <Header as={'h4'} style={{ margin: '0' }} subheader={''}>
                  Connect Bank Account (free)
                  <Header.Subheader style={{ lineHeight: '1.5em' }}>
                    Our payment processor will withdraw the amount due once your
                    account is linked via Plaid.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column style={{ width: wd.width <= 768 ? '100%' : '50%' }}>
                <Swap size={48} color="#f1592a" />
                <Header as={'h4'} style={{ margin: '0' }}>
                  Qualify for Terms
                  <Header.Subheader>
                    <p style={{ margin: '0', lineHeight: '1.5em' }}>
                      1% for 15 days
                    </p>
                    <p style={{ margin: '0', lineHeight: '1.5em' }}>
                      2% for 30 days
                    </p>
                    <p style={{ margin: '0', lineHeight: '1.5em' }}>
                      3% for 45 days
                    </p>
                    <p style={{ margin: '0', lineHeight: '1.5em' }}>
                      4% for 60 days
                    </p>
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column style={{ width: wd.width <= 768 ? '100%' : '50%' }}>
                <CreditCard size={48} color="#f1592a" />
                <Header as={'h4'} style={{ margin: '0' }}>
                  Credit Card
                  <Header.Subheader style={{ lineHeight: '1.5em' }}>
                    Connect your Credit Card to pay, with a 2.9% processing fee.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column style={{ width: wd.width <= 768 ? '100%' : '50%' }}>
                <ArrowCircleRight size={48} color="#f1592a" />
                <Header as={'h4'} style={{ margin: '0' }}>
                  ACH/ Wire Transfer (free)
                  <Header.Subheader style={{ lineHeight: '1.5em' }}>
                    Pay via an email link from Balance.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      )}
      {selectedTab === 3 && (
        <Grid
          style={{
            flexDirection: wd.width <= 950 ? 'column-reverse' : 'row',
            marginTop: '100px',
          }}
          stackable
          width={16}
          className="page-section"
        >
          <Grid.Column
            width={wd.width <= 950 ? 14 : 9}
            className="section-left"
          >
            <img src={Delivered} className="section-image" alt="" />
          </Grid.Column>
          <Grid.Column
            textAlign={wd.width <= 950 ? 'center' : 'left'}
            width={wd.width <= 950 ? 14 : 7}
            className="section-right"
            style={{ marginBottom: wd.width <= 950 ? '2rem' : '' }}
          >
            <Header
              as={'h1'}
              className="section-header"
              style={{ fontSize: '36px' }}
            >
              Reliable Fulfillment
              <Header.Subheader as={'p'} className="tab-subheader">
                At loading, the supplier enters the BOL into {env.REACT_APP_BUSINESS_NAME}, which
                automatically invoices the buyer with 4 payment options.
              </Header.Subheader>
            </Header>
            <ul className="bullets">
              <li>
                The supplier receives payment the next day after buyer pays.
              </li>
              <li>Upon delivery, buyer uploads POD into {env.REACT_APP_BUSINESS_NAME}.</li>
              <li>
                {env.REACT_APP_BUSINESS_NAME} will intermediate when a buyer claims an issue
                with the lumber per the Terms and Conditions
              </li>
            </ul>
          </Grid.Column>
        </Grid>
      )}
    </>
  )
}

export default TechnologySlider
