import React from 'react'
import { Table, Accordion, Divider } from 'semantic-ui-react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminChangeOrderItemsRow from './AdminChangeOrderItemsRow'

const AdminChangeOrderRow = (props) => {
  const {
    changeOrder,
    index,
    activeIndex,
    setActiveIndex,
    addLineItemSelectedUom,
  } = props

  const handleActiveIndex = () => {
    if (index !== activeIndex) {
      setActiveIndex(index)
    }
    if (index === activeIndex) {
      setActiveIndex(null)
    }
  }
  const changeOrderItemsHeader = [
    'Product',
    'Previous Quantity',
    'New Quantity',
    'Change In Quantity',
  ]

  return (
    <Accordion
      styled
      style={{
        minHeight: '0',
        width: '100%',
        margin: '0',
        borderRadius: '0',
      }}
    >
      <Accordion.Title
        style={{ paddingBottom: activeIndex === index ? '0' : '' }}
        onClick={() => handleActiveIndex()}
        active={index === activeIndex}
      >
        <strong>Change Order - {props.changeOrder.created_at}</strong>
      </Accordion.Title>
      <Accordion.Content
        style={{ paddingTop: '0' }}
        active={index === activeIndex}
      >
        <Divider />
        <MickeyTable
          headerRow={changeOrderItemsHeader}
          style={{ textAlign: 'center' }}
          basicSegment
          data={changeOrder.change_order_line_items}
          renderBodyRow={(data, index) => {
            return <AdminChangeOrderItemsRow changeOrderLineItem={data} />
          }}
        />
      </Accordion.Content>
    </Accordion>
  )
}

export default AdminChangeOrderRow
