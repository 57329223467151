import React, { useState } from 'react'
import { Accordion, Table, Icon } from 'semantic-ui-react'

function AdminDashboardExploreMissingCacheRow(props) {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell onClick={() => {}}>
          {props.tlcu.cargo_unit.singular_name} {props.tlcu.trucking_lane.id}
        </Table.Cell>
        <Table.Cell onClick={() => {}}>
          {props.tlcu.trucking_lane.pickup_zip_code}
        </Table.Cell>
        <Table.Cell onClick={() => {}}>
          {props.tlcu.trucking_lane.destination_zip_code}
        </Table.Cell>
        <Table.Cell onClick={() => {}}>
          <Accordion>
            {props.tlcu.shippercantships.map((scs, i) => {
              return (
                <>
                  <Accordion.Title
                    active={activeIndex === i}
                    index={i}
                    onClick={() => {
                      setActiveIndex(i)
                    }}
                  >
                    <Icon name="dropdown" />
                    <b>{scs.trucking_company.name}</b> {scs.created_at}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === i}>
                    <p>{scs.reason}</p>
                  </Accordion.Content>
                </>
              )
            })}
          </Accordion>
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default AdminDashboardExploreMissingCacheRow
