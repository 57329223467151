import axios from 'axios'
import env from '../../env'
import axiosRetry from 'axios-retry'

const useAxios = () => {
  const axiosClient = axios.create({
    baseURL:
      env.REACT_APP_ENVIRONMENT === 'local'
        ? `http://${window.location.hostname}:8002/api/`
        : `https://${env.REACT_APP_API_URL}/api/`,
    timeout: 1000000,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  })
  axiosClient.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('access_token')
      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config
      if (
        error &&
        error.response &&
        error.response.status === 401 &&
        (originalRequest.url === '/main/token/refresh/' ||
          originalRequest.url === 'main/token/refresh/')
      ) {
        return Promise.reject(error)
      }
      if (error && error.response && error.response.status === 401) {
        const refreshToken = localStorage.getItem('refresh_token')
        if (refreshToken && refreshToken !== 'null') {
          const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))
          const now = Math.ceil(Date.now() / 1000)
          if (tokenParts.exp > now) {
            return axiosClient
              .post('/main/token/refresh/', { refresh: refreshToken })
              .then((response) => {
                if (response && response.data) {
                  localStorage.setItem('access_token', response.data.access)
                  localStorage.setItem('refresh_token', response.data.refresh)
                  axiosClient.defaults.headers['Authorization'] =
                    'Bearer ' + response.data.access
                  originalRequest.headers['Authorization'] =
                    'Bearer ' + response.data.access
                }
                return axiosClient(originalRequest)
              })
              .catch((err) => {})
          }
        }
      }
      return Promise.reject(error)
    }
  )
  axiosRetry(axiosClient, { retries: 3 })
  return axiosClient
}

export default useAxios
