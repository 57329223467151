export const DocumentUploadForm = {
  model: 'Order',
  format: [
    {
      widths: 'equal',
      fields: {
        file: {
          required: true,
          type: 'file',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        document_type: {
          required: true,
          type: 'dropdown',
        },
        visiblity: {
          type: 'dropdown',
          visibleto: ['admin'],
        },
      },
    },
  ],
}
