import React, { useState, useEffect } from 'react'
import useAxios from '../../lib/auth/useAxios'
import {
  Icon,
  Form,
  Header,
  Button,
  Segment,
  Dimmer,
  Loader,
  Divider,
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
import './MickeyForm.css'
import './MickeyFormField.css'
import { useSelector } from 'react-redux'
import getForm from '../../forms/BaseForm'
import MickeyFormField from './MickeyFormField'
import _ from 'underscore'
import MickeyButton from '../MickeyButton/MickeyButton'
import { requiredValidator } from './validators'
import { trackEvent } from '../../lib/utils/utils'
import env from '../../env'
// import ReCAPTCHA from 'react-google-recaptcha'
import { objEqual } from '../../lib/utils/utils'
/**
 * Represents a MickeyForm object which can be used to create forms from templates that submit new data and update existing data corresponding to a Django model instance.
 * @constructor
 * @param {string} formName - The form name as included in the BaseForm.js file.
 * @param {string} model - The django model name .
 * @param {string} instanceId  - The django instance Id to target. NOTE: this will make the form 'editing' a model instead of adding.
 *
 * @param {string} url  - The django url to call on submit.
 *
 * @param {string} buttonLabel . - Button label on form
 * @param {string} buttonSize . - Button size
 * @param {string} buttonColor . - Button color
 *
 * @param {boolean} disabled . - Disables form
 * @param {Object} disabledFields . - Disables specific fields (include as [key]: true in dictionary)
 *
 * @param {function} handleForm - Handle submit method after the submission (returns success/failure and data).
 * @param {function} onSubmit - Overwrite onSubmit method (don't call URL) but still validates.
 * @param {string} successMessage - On submit, on success, display this message in toast.
 * @param {string} failureMessage - On submit, on failure, display this message in toast.
 * @param {string} innerSuccessMessage - On submit, on success, display this message in segment.
 * @param {string} innerButtonMessage - On submit, on success, display this message in a button.
 * @param {string} innerButtonHref - On submit, on success, button link.
 *
 * @param {Object} defaults - Provide initial values to the form as a dictionary of key values.
 * @param {Object[]} extraFields - Extra fields to pass in name, value
 * @param {string} extraFields[].name - Extra fields to pass in name, value
 * @param {string} extraFields[].value - Extra fields to pass in name, value
 *
 * @param {function} setReload - Updates parent's reload boolean.
 * @param {function} onUpdate - Called on field update.
 * @param {Object} values - Values to pass into on submit.
 *
 * @param {string} instanceIdOverwrite - Some models use a string token as their primary key. This allows editing to work properly by passing in a different ID value for the update compared to the on submit.
 * @param {string} editingButNoInstanceId - If you want to be editing but don't need to pass an editing ID to the URL.
 *
 */
function MickeyForm(props) {
  // Vars
  const axios = useAxios()
  let {
    formName,
    successMessage,
    failureMessage,
    url,
    instanceId,
    HandleDropdownOption,
    disabledDefaultFields,
    instanceLabel,
  } = props

  // Field Values
  const [values, setValues] = useState(null)
  const [groups, setGroups] = useState(null)
  const [handleInputChangeComplete, setHandleInputChangeComplete] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [dependencies, setDependencies] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [showInnerSuccessMessage, setShowInnerSuccessMessage] = useState(false)
  const [labelSuccessField, setLabelSuccessField] = useState(false)
  const [form, setForm] = useState(null)
  const [defaultSearchResults, setDefaultSearchResults] = useState({})
  const [lastValues, setLastValues] = useState({})
  const [captchaReady, setCaptchaReady] = useState(false)
  const [recaptchaVarified, setRecaptchaVarified] = useState(false)
  const buyer = useSelector((state) => state.user.buyer)
  const user = useSelector((state) => state.user.user)
  const supplier = useSelector((state) => state.user.supplier)
  const business = useSelector((state) => state.user.business)
  const globalSetting = useSelector((state) => state.globals.globalSettings)
  const buttons = props?.buttons

  let urlParams = {}
  if (props.urlParams) {
    urlParams = props.urlParams
  }
  if (props.tenantAware === false) {
    urlParams.tenant_aware = false
  }

  function clearValues() {
    let newDict = {}
    for (let val in values) {
      newDict[val] = undefined
    }
    setValues(newDict)
  }

  const handleSetValues = async (obj) => {
    const isEqual = await objEqual(values, obj)
    if (!isEqual) {
      setValues(obj)
    }
  }

  function getFormInitialValues(form) {
    let values = {}
    form.groups.forEach((group) => {
      for (const [key, field] of Object.entries(group['fields'])) {
        if (props.defaults && props.defaults[key]) {
          values[key] = props.defaults[key]
        } else {
          if (field.type === 'checkbox') {
            values[key] = false
          } else if (field.type === 'country') {
            values[key] = 'US'
          } else {
            values[key] = ''
          }
        }
      }
    })
    return values
  }

  function getForeignKeyDependencies(key) {
    let fields = []
    if (!dependencies) {
      return []
    }
    dependencies.forEach((dep, index) => {
      let type = dep.type
      if (!dep.deps) {
        return
      }
      dep.deps.forEach((d) => {
        if (type === 'foreign_key_default' && key === d.fk) {
          fields.push(dep)
        }
      })
    })
    return fields
  }

  async function getForeignKeyValues(upFields, key, id) {
    let fields = getForeignKeyDependencies(key)
    let updatedFields = Object.assign({}, upFields)

    for (let field of fields) {
      let fieldToChange = field.field
      let transaction
      let getValue

      for (let dep of field.deps) {
        if (key !== dep.fk) {
          continue
        }
        transaction = await axios.get(`${dep.url}${id}/`, { params: urlParams })
        if (!transaction) {
          continue
        }

        let passesIfStatements = false

        let fieldToGet = dep.field
        getValue = transaction.data[fieldToGet]

        if (dep.if) {
          let innerUpdatedField = updatedFields
          dep.if.forEach((i) => {
            if (key !== dep.fk) {
              return
            }
            let val = innerUpdatedField[i.field]
            if (i.values.includes(val)) {
              passesIfStatements = true
            }
          })
        } else {
          passesIfStatements = true
        }
        if (passesIfStatements && key === dep.fk) {
          updatedFields = {
            ...updatedFields,
            [fieldToChange]: getValue,
          }
        }
      }
    }
    return updatedFields
  }

  function index(obj, i) {
    return obj ? obj[i] : null
  }

  const djangoResponseToInputValues = (groups, data) => {
    let clone = { ...data }
    for (let group in groups) {
      for (let field in groups[group].fields) {
        if (clone[field] || (props.defaults && props.defaults[field])) {
          let type = groups[group].fields[field]['type']
          if (props.defaults && props.defaults[field]) {
            clone[field] = props.defaults[field]
          } else if (type === 'date') {
            clone[field] = data[field] ? new Date(data[field]) : undefined
          } else if (['multiple dropdown', 'multiple dropdown model']) {
            clone[field] = Array.isArray(data[field])
              ? _.map(data[field], (field) => {
                  return field.id ? field.id : field
                })
              : data[field]
          } else if (type === 'search') {
            clone[field] =
              data[field] && data[field].id ? data[field].id : data[field]
          }
        }
      }
    }
    return clone
  }

  useEffect(() => {
    setLoaded(false)
    async function theForm() {
      let form = getForm(formName)
      if (form) {
        setForm(Object.assign({}, form))
      }
      if (!form) {
        return
      }
      setGroups(form.groups)
      setDependencies(form.dependencies)
      clearErrors(form)
      if (instanceId) {
        clearValues()
        let resInstance = await axios.get(props.url + props.instanceId + '/', {
          params: urlParams,
        })
        let searchResults = {}
        for (let group in form.groups) {
          for (let field in form.groups[group].fields) {
            let type = form.groups[group].fields[field]['type']
            if (type === 'search') {
              searchResults[field] = resInstance.data[field]
                ? [
                    {
                      title:
                        form.groups[group].fields[field]['title_fields']
                          .length > 1
                          ? _.reduce(
                              form.groups[group].fields[field]['title_fields'],
                              (memo, num) => {
                                return `${memo
                                  .split('.')
                                  .reduce(index, resInstance.data[field])} ${num
                                  .split('.')
                                  .reduce(index, resInstance.data[field])}`
                              }
                            )
                          : form.groups[group].fields[field]['title_fields'][0]
                              .split('.')
                              .reduce(index, resInstance.data[field]),
                      description: '',
                      id: resInstance.data[field]
                        ? resInstance.data[field].id
                        : null,
                    },
                  ]
                : []
            }
          }
        }
        setDefaultSearchResults(searchResults)
        let vals = djangoResponseToInputValues(
          form?.groups,
          resInstance?.data,
          axios
        )
        await handleSetValues(vals)
        if (resInstance.data) {
          setLastValues(vals)
        }
      } else {
        await handleSetValues(getFormInitialValues(form))
      }
      if (props.extraFields) {
        props.extraFields.forEach(async (field) => {
          let v = {}
          Object.assign(v, values)
          v[field.name] = field.value
          await handleSetValues(v)
        })
      }
      setLoaded(true)
      if (props.setReload) {
        props.setReload(false)
      }
    }

    theForm()
    return () => {
      clearValues()
    }
  }, [formName, instanceId, props.url, props.extraFields])

  function getDefaultDependencies(key) {
    let fields = []
    if (!dependencies) {
      return []
    }
    dependencies.forEach((dep, index) => {
      let type = dep.type
      if (type === 'default' && dep?.dep?.fields?.includes(key)) {
        fields.push(dep)
      }
    })
    return fields
  }

  const handleInputChange = async (event, data, overwriteValue = null) => {
    if (!loaded) {
      return
    }
    let value
    let key = data.name
    if (!key) {
      return
    }
    if (overwriteValue) {
      value = overwriteValue
    } else {
      if (data.type === 'checkbox') {
        value = data.checked
      } else if (data.type === 'file') {
        value = event.target.files[0]
      } else if (data.type === 'date') {
        value = data.value.toLocaleDateString('en-US')
      } else {
        value = 'result' in data && data.result ? data.result.id : data.value
      }
    }
    if (Array.isArray(value)) {
      value = value.filter((e) => e !== 'None')
    }
    let updatedFields = {
      ...values,
      [data.name]: value,
    }

    await handleSetValues(updatedFields)

    updatedFields = await getForeignKeyValues(updatedFields, key, value)

    let fields = getDefaultDependencies(key)
    let allParams = true

    fields.forEach((field) => {
      let fieldToChange = field.field
      let params = []
      field.dep.input.forEach((f) => {
        let v = updatedFields[f]
        if (v) {
          params.push(parseFloat(updatedFields[f]))
        } else {
          allParams = false
        }
      })
      if (allParams) {
        let getValue = field.dep.function(...params)
        updatedFields = { ...updatedFields, [fieldToChange]: getValue }
      }
    })
    await handleSetValues(updatedFields)
    if (props.onUpdate) {
      props.onUpdate(updatedFields)
    }
    setHandleInputChangeComplete(true)
  }

  function autoUpdateFieldValidation(key, field) {
    let isValidated = true
    let errorMessage
    if (field.required) {
      errorMessage = requiredValidator(values[key])
    }
    if (!errorMessage && field.validator) {
      const validatorInputs = [values[key]]
      if (field.validatorInputs) {
        field.validatorInputs.forEach((input) => {
          if (input === 'global-supplier-take-rate') {
            validatorInputs.push(
              parseFloat(globalSetting.default_marketplace_supplier_take_rate)
            )
          } else if (input === 'global-buyer-take-rate') {
            validatorInputs.push(
              parseFloat(globalSetting.default_marketplace_buyer_take_rate)
            )
          }
        })
      }
      errorMessage = field.validator(...validatorInputs)
    }
    if (errorMessage) {
      setFormFieldError(key, errorMessage)
      if (isValidated) {
        isValidated = false
      }
    } else if (field.error) {
      setFormFieldError(key, null)
    }

    return isValidated
  }

  function updateDatabaseValue(key, field, modelOverride) {
    clearErrors(form)
    if (
      props.instanceId &&
      values[key] !== lastValues[key] &&
      autoUpdateFieldValidation(key, field)
    ) {
      let value = values[key]
      if (values[key] instanceof Date) {
        value = values[key].toLocaleDateString('en-US')
      }
      axios.post(`/main/auto-update/`, {
        model: modelOverride ? modelOverride : props.model,
        field: key,
        value: value,
        instance_id: props.instanceIdOverwrite
          ? props.instanceIdOverwrite
          : instanceId,
      })
      setLabelSuccessField(key)
      setTimeout(() => {
        setLabelSuccessField(null)
      }, 2000)

      if (props.setReload) {
        props.setReload(true)
      }
      setLastValues((prevState) => {
        prevState[key] = values[key]
        return prevState
      })
      if (props.postSubmit) {
        props.postSubmit()
      }
      if (props.currentUpdatedField) {
        props.currentUpdatedField(key, values[key])
      }
      if (props.asyncPostSubmit) {
        ;(async () => await props.asyncPostSubmit())()
      }
    }
  }

  const setFormFieldError = (setField, error) => {
    setForm((initialState) => {
      initialState.groups.forEach((group) => {
        for (const [field, fieldDef] of Object.entries(group.fields)) {
          if (field === setField) {
            if (fieldDef.error !== error) {
              fieldDef.error = error
            }
            break
          }
        }
      })
      return Object.assign({}, initialState)
    })
  }

  function clearErrors(form) {
    form.groups.forEach((group) => {
      for (const fieldDef of Object.values(group.fields)) {
        if (fieldDef.error) {
          fieldDef.error = null
        }
      }
    })
  }

  function checkDependencies(key, type) {
    if (!dependencies) {
      return true
    }
    for (var dep of dependencies) {
      if (!values) {
        return true
      }
      if (key === dep.field && dep.type === type) {
        let deps = dep.deps
        for (var d in deps) {
          let val = values[deps[d].field]
          if (!deps[d].values.includes(val)) {
            return false
          }
        }
      }
    }
    return true
  }

  function validation() {
    let isValidated = true
    form.groups.forEach((group) => {
      for (const [key, field] of Object.entries(group.fields)) {
        let errorMessage
        if (field.required) {
          errorMessage = requiredValidator(values[key])
        }
        if (!errorMessage && field.validator) {
          const validatorInputs = [values[key]]
          if (field.validatorInputs) {
            field.validatorInputs.forEach((input) => {
              if (input === 'global-supplier-take-rate') {
                validatorInputs.push(
                  parseFloat(
                    globalSetting.default_marketplace_supplier_take_rate
                  )
                )
              } else if (input === 'global-buyer-take-rate') {
                validatorInputs.push(
                  parseFloat(globalSetting.default_marketplace_buyer_take_rate)
                )
              }
            })
          }
          errorMessage = field.validator(...validatorInputs)
        }
        if (errorMessage && checkDependencies(key, 'hidden')) {
          setFormFieldError(key, errorMessage)
          if (isValidated) {
            isValidated = false
          }
        } else if (field.error) {
          setFormFieldError(key, null)
        }
      }
    })
    return isValidated
  }

  function getField(key) {
    let group = _.find(groups, (group) => {
      return group?.fields[key]
    })
    if (group) {
      return group?.fields[key]
    }
    return null
  }

  useEffect(() => {
    if (props.recaptcha) {
      // Dynamically add the reCAPTCHA script
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6LcgoZEqAAAAAPa1F-R2fggBPfE4diXzwzMqKuye';
      script.async = true;

      script.onload = () => {
        window.grecaptcha.enterprise.ready(() => {
          console.log('reCAPTCHA ready');
          setCaptchaReady(true); // Mark the captcha as ready
        });
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [props.recaptcha]); // Runs when the recaptcha prop changes

  async function onSubmit() {
    let grecaptcha = window.grecaptcha;

    if (captchaReady) {
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute('6LcgoZEqAAAAAPa1F-R2fggBPfE4diXzwzMqKuye', { action: 'SIGNUP' });

        // Send the token to your backend for verification
        try {
          const res = await axios.post('/main/verify-captcha/', { token: token });
          console.log(res);
        } catch (error) {
          console.error('Captcha verification failed', error);
        }
      });
    } else {
      console.error('reCAPTCHA script not loaded or not ready');
    }
    setLoading(true)
    clearErrors(form)
    if (!validation()) {
      setLoading(false)
      return
    }
    if (props.onSubmit) {
      props.onSubmit(values)
      setLoading(false)
      return
    }
    if (props.preSubmit) {
      const results = props.preSubmit(values)
      if (results?.errors) {
        setLoading(false)
        return
      }
    }
    if (props.addToSubmit) {
      props.addToSubmit()
    }

    if (
      props.gaTracking &&
      props.gaTracking.eventName &&
      props.gaTracking.eventParams
    ) {
      let eventParams = props.gaTracking.eventParams
      let eventName = props.gaTracking.eventName
      if (props.gaTracking && props.gaTracking.valuesNeededFromSubmit) {
        props.gaTracking.valuesNeededFromSubmit.forEach((value) => {
          if (eventParams[value.googleKey] === null || undefined || '') {
            eventParams[value.googleKey] = values[value.databaseValue]
            trackEvent(eventName, eventParams)
            return
          }
        })
      } else {
        trackEvent(eventName, eventParams)
        return
      }
    }

    const formData = new FormData()
    for (var key in values) {
      let field = getField(key)
      if (field?.type === 'date') {
        try {
          formData.append(key, values[key].toLocaleDateString('en-US'))
        } catch {}
      } else if (
        ['multiple dropdown', 'multiple dropdown model'].includes(field.type)
      ) {
        try {
          const seen = []
          values[key]?.forEach((item) => {
            if (!seen.includes(item)) {
              formData.append(key + '[]', item)
              seen.push(item)
            }
          })
        } catch {}
      } else if (
        field?.type === 'dropdown' &&
        field.fetch &&
        typeof values[key] === 'object'
      ) {
        for (const choiceKey in values[key]) {
          formData.append(choiceKey, values[key][choiceKey])
        }
      } else if (
        field?.type === 'custom field' &&
        field?.handleFormDataAppend
      ) {
        field.handleFormDataAppend(key, values[key], formData)
      } else {
        formData.append(key, values[key])
      }
    }
    if (props.createAttributes) {
      for (var createAttr in props.createAttributes) {
        formData.append(createAttr, props.createAttributes[createAttr])
      }
    }
    for (var key2 in props.values) {
      if (props.checkListTypeOfValue && Array.isArray(props.values[key2])) {
        props.values[key2]?.forEach((item) => {
          formData.append(key2, item)
        })
      } else {
        formData.append(key2, props.values[key2])
      }
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    let res
    if (props.tenantAware === false) {
      res = await axios.post(url, formData, config, {
        params: { tenant_aware: false },
      })
    } else {
      res = await axios.post(url, formData, config)
    }
    if (res?.data?.error) {
      toast.error(res.data.error)
      if (props.handleForm) {
        props.handleForm('failure', res?.data)
      }
    } else if (
      (res && res.data && res.data.success) ||
      (res && res.status && res.status && res.status < 205)
    ) {
      if (successMessage) toast.success(successMessage)
      if (props.innerSuccessMessage) {
        setShowInnerSuccessMessage(true)
      }
      if (props.handleForm) {
        props.handleForm('success', res?.data)
      }
    } else {
      if (res?.data?.error) {
        toast.error(res.data.error)
      } else if (failureMessage) {
        toast.error(failureMessage)
      }
      if (props.handleForm) {
        props.handleForm('failure', res.data)
      }
    }
    setLoading(false)
  }

  const isFormBlank = () => {
    let blank = false
    if (values && groups) {
      groups.forEach((group) => {
        Object.entries(group.fields).forEach((field) => {
          const [key, value] = field
          if (value['required'] && !values[key]) {
            blank = true
          }
        })
      })
    }
    return blank
  }

  function checkHeaderDependencies(group) {
    let depResults = []
    if (!group.deps) {
      return true
    } else {
      group.deps.forEach((dep) => {
        if (values.hasOwnProperty(dep.field)) {
          dep.values.map((depVal) =>
            depResults.push(depVal === values[dep.field])
          )
        }
      })
      if (depResults.includes(false)) {
        return false
      } else {
        return true
      }
    }
  }

  function checkGroupDependencies(group) {
    let hiddenGroup = false
    Object.keys(group.fields).map((key) => {
      if (checkDependencies(key, 'hidden')) {
        hiddenGroup = true
      }
    })
    return hiddenGroup
  }

  return (
    <>
      <Form
        id="mickey-form"
        autoComplete="new-password"
        style={{ ...props.style, paddingBottom: '20px' }}
      >
        {!loaded && (
          <Segment
            basic={props.basicSegment}
            style={{
              minHeight: props.loadingHeight ? props.loadingHeight : '300px',
              ...props.style,
            }}
          >
            <Dimmer inverted active>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {showInnerSuccessMessage && props.innerSuccessMessage && (
          <Segment
            basic={props.basicSegment}
            placeholder
            className={'mickey-table-placeholder'}
          >
            <Header icon>
              <Icon color={'green'} name={'check'} />
              {props.innerSuccessMessage}
              {props.innerButtonMessage && (
                <Button
                  as={'a'}
                  color={'green'}
                  className={'mickey-btn'}
                  href={props.innerButtonHref}
                >
                  {props.innerButtonMessage}
                </Button>
              )}
            </Header>
          </Segment>
        )}
        {loaded &&
          !showInnerSuccessMessage &&
          groups &&
          groups.map((group, key) => {
            if (group.header && checkHeaderDependencies(group)) {
              return (
                <Header style={{ width: '100%' }} key={key}>
                  {group.header}
                  <Divider />
                </Header>
              )
            }
            return (
              checkGroupDependencies(group) && (
                <Form.Group {...group} key={key}>
                  {group.fields &&
                    Object.keys(group.fields).map((key) => {
                      let field = group.fields[key]
                      let conditions = props.conditions?.find(
                        (obj) => obj.label === field?.label
                      )?.checks
                      return (field?.visibleto?.includes(
                        props.currentPortal
                      ) === undefined ||
                        field?.visibleto?.includes(props.currentPortal)) &&
                        (conditions || conditions === undefined) &&
                        (!props.disclude_keys ||
                          !field.disclude_key ||
                          !props.disclude_keys.includes(field.disclude_key)) ? (
                        <MickeyFormField
                          key={key}
                          hiddenFields={props.hiddenFields}
                          instanceId={instanceId}
                          instanceLabel={instanceLabel}
                          disabledDefaultFields={disabledDefaultFields}
                          tenantAware={props.tenantAware}
                          field={field}
                          handleInputChangeComplete={handleInputChangeComplete}
                          setHandleInputChangeComplete={
                            setHandleInputChangeComplete
                          }
                          keyValue={key}
                          choicesFetchArgs={props.choicesFetchArgs}
                          model={props.model}
                          values={values}
                          labelSuccessField={labelSuccessField}
                          disabled={props.disabled}
                          disabledFields={props.disabledFields}
                          setValues={handleSetValues}
                          checkDependencies={checkDependencies}
                          updateDatabaseValue={updateDatabaseValue}
                          handleInputChange={handleInputChange}
                          user={user}
                          buyer={buyer}
                          supplier={supplier}
                          business={props.business ? props.business : business}
                          defaultSearchResults={defaultSearchResults}
                          HandleDropdownOption={HandleDropdownOption}
                        />
                      ) : null
                    })}
                </Form.Group>
              )
            )
          })}
        {loaded && props.extraFields && !showInnerSuccessMessage && (
          <Form.Group>
            {props.extraFields.map((field) => {
              return (
                <field.field.type
                  {...field.field.props}
                  value={values[field.name]}
                  onChange={(e, d) => {
                    handleInputChange(e, d)
                  }}
                />
              )
            })}
          </Form.Group>
        )}
        {/* {loaded && props?.reCaptcha && !showInnerSuccessMessage && ( */}
        {/*   <ReCAPTCHA */}
        {/*     sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY} */}
        {/*     onChange={(token) => { */}
        {/*       setRecaptchaVarified(token) */}
        {/*     }} */}
        {/*   /> */}
        {/* )} */}
        {!instanceId && !showInnerSuccessMessage && !buttons && (
          <MickeyButton
            className={'submit-btn'}
            loading={loading}
            size={props.buttonSize}
            color={props.buttonColor ? props.buttonColor : 'primary'}
            floated={'right'}
            type={'submit'}
            disabled={
              props.disabled ||
              (props?.reCaptcha
                ? (props.disableButton && isFormBlank()) || !recaptchaVarified
                : props.disableButton && isFormBlank())
            }
            content={props.buttonLabel ? props.buttonLabel : 'Add'}
            style={{
              width: props.buttonSize === 'full' && '100%',
              height: props.buttonHeight !== undefined && props.buttonHeight,
              margin: '20px',
              position: 'relative',
              marginBottom: '20px',
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              // "--secondaryButtonColor": env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            onClick={() => {
              onSubmit()
            }}
          />
        )}
        {buttons && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '20px',
              marginBottom: 0,
            }}
          >
            {buttons?.map((button, idx) => {
              return (
                <MickeyButton
                  key={idx}
                  className={button.type === 'submit' ? 'submit-btn' : ''}
                  basic={button?.basic}
                  loading={button.type === 'submit' && loading}
                  size={'medium'}
                  color={button.color ? button.color : 'primary'}
                  type={button.type}
                  disabled={button.disabled ? isFormBlank() : false}
                  content={button.content}
                  style={button.style}
                  innerStyle={button.innerStyle}
                  onClick={() => {
                    if (button.type === 'submit') {
                      onSubmit()
                    } else if (button?.onClick) {
                      button.onClick()
                    }
                  }}
                />
              )
            })}
          </div>
        )}
        {props.buttonFloatedRight && <div style={{ height: '30px' }}></div>}
      </Form>
    </>
  )
}

export default MickeyForm
