import { Grid } from 'semantic-ui-react'
import _ from 'underscore'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { ChatDots } from 'phosphor-react'
import SalesLogRow from './SalesLogRow'
import { format_phone_number } from '../../../lib/utils/utils'

const NotesTable = (props) => {
  const { businessId, business, hideHeader, reload, setReload } = props
  const headerRow = ['Date', 'Salesperson', 'User', 'Contact Method', 'Notes']
  const userSearchOptionParams = {}
  if (businessId) {
    userSearchOptionParams.business_id = businessId
  }
  const headerSort = [
    {
      header: 'Date',
      sort_by: 'created_at',
    },
    {
      header: 'Salesperson',
      sort_by: 'salesperson__first_name',
    },
    {
      header: 'User',
      sort_by: 'user__first_name',
    },
    {
      header: 'Contact Method',
      sort_by: 'communication_type',
    },
    {
      header: 'Notes',
    },
  ]
  const headerSearch = [
    {
      header: 'Date',
      type: 'date_range',
      search_param_start: 'created_at_start',
      search_param_end: 'created_at_end',
    },
    {
      header: 'Salesperson',
      type: 'dropdown',
      options_url: 'admin-users/',
      options_params: {
        extra_light_serializer: 'true',
      },
      options_titleFields: ['first_name', 'last_name'],
      search_param: 'salesperson_id',
    },
    {
      header: 'User',
      type: 'dropdown',
      options_url: 'custom-users/',
      options_titleFields: ['first_name', 'last_name'],
      options_params: {
        business_id: businessId,
        extra_light_serializer: 'true',
      },
      search_param: 'user_id',
    },
    {
      header: 'Contact Method',
      type: 'dropdown',
      generateOptions: (results) => {
        let options = []
        results.forEach((d) => {
          options.push({
            text: d.email,
            value: d.email,
            key: d.email,
          })
          if (d.phone && d.phone !== '1') {
            options.push({
              text: format_phone_number(d.phone),
              value: d.phone,
              key: d.phone,
            })
          }
        })
        return options
      },
      options_url: 'custom-users/',
      options_params: {
        light_serializer: 'true',
      },
      search_param: 'communication_type',
    },
  ]

  const urlParams = {}
  if (businessId) {
    urlParams.business_id = businessId
  }

  return (
    <>
      <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
        {!hideHeader && (
          <Grid.Column
            width={8}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={'Notes'}
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={8}>
          <MickeyTable
            reload={reload}
            setReload={setReload}
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            url={'/main/admin-sales-logs/'}
            renderBodyRow={(data, index) => {
              return (
                <SalesLogRow
                  salesLog={data}
                  index={index}
                  business={business}
                  key={index}
                  setReload={setReload}
                />
              )
            }}
            emptyIconHtml={<ChatDots size={40} />}
            emptyMessage={'No notes'}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default NotesTable
