import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { hasPermission, inGroup, hasPortalAccess } from '../../lib/utils/utils'

const ProtectedRoute = ({ children, permission, group, isGod, portal }) => {
  const user = useSelector((state) => state.user.user)
  const navigate = useNavigate()
  let isAllowed = false
  if (permission) {
    isAllowed = hasPermission(user, permission)
  }
  if (group && (!permission || isAllowed)) {
    isAllowed = inGroup(user, group)
  }
  if (portal) {
    isAllowed = hasPortalAccess(user, portal)
  }

  useEffect(() => {
    if (!isAllowed) {
      navigate('/')
    }
  }, [isAllowed, navigate])

  if ((isGod && !user.is_god) || !isAllowed) {
    return null
  }

  return children
}

export default ProtectedRoute
