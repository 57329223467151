import React, { useState } from 'react'
import { List, Menu, Grid, Header, Divider } from 'semantic-ui-react'
import './MXFooter.css'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import {
  Storefront,
  Receipt,
  Bell,
  User,
  PlusCircle,
  ArrowRight,
} from 'phosphor-react'
import { useNavigate, NavLink } from 'react-router-dom'
import env from '../../../../env'
import {
  loggedOut,
  removeBusiness,
  removeUser,
} from '../../../../features/user/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../../lib/auth/useAuth'
import { hasPortalAccess } from '../../../../lib/utils/utils'

const MXFooter = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const user = useSelector((state) => state.user.user)
  const business = useSelector((state) => state.user.business)
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
  const notifications = props.notifications
  const windowDimensions = useWindowDimensions()
  const [display, setDisplay] = useState('none')

  return (
    <>
      {[
        '/',
        '/legal',
        '/purchase-order-tac',
        '/user-agreement',
        '/order-ack-tac',
        '/purchase-order-tac',
      ].includes(window.location.pathname) && (
        <div style={{ paddingTop: '50px' }} id="exchange-footer">
          {windowDimensions?.width < 769 && isLoggedIn && (
            <Menu
              style={{
                position: 'fixed',
                bottom: '0',
                width: '100%',
                height: '80px',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 1rem 0 1rem',
                borderTop: '0.5px #6D6E70 solid',
              }}
              className="footer-menu"
            >
              <Menu.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                className="nav-item"
                as={NavLink}
                to={
                  window?.location?.pathname?.includes('/buyer')
                    ? '/portal/buyer'
                    : '/portal/supplier'
                }
                onClick={() => setDisplay('none')}
              >
                <Storefront
                  style={{ cursor: 'pointer' }}
                  size={25}
                  weight="duotone"
                  color={
                    (props.currentTab === 'exchange' ||
                      props.currentTab === 'my-bids' ||
                      props.currentTab === 'watchlist') &&
                    display === 'none'
                      ? '#f1592a'
                      : '#6D6E70'
                  }
                />
                <span
                  className="footer-icon-font"
                  style={{
                    paddingTop: '5px',
                    color:
                      (props.currentTab === 'exchange' ||
                        props.currentTab === 'my-bids' ||
                        props.currentTab === 'watchlist') &&
                      display === 'none'
                        ? '#f1592a'
                        : '#6D6E70',
                  }}
                >
                  Exchange
                </span>
              </Menu.Item>

              <Menu.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                className="nav-item"
                as={NavLink}
                to={
                  window?.location?.pathname?.includes('/buyer')
                    ? '/portal/buyer/orders'
                    : '/portal/supplier/orders'
                }
                onClick={() => setDisplay('none')}
              >
                <Receipt
                  style={{ cursor: 'pointer' }}
                  size={25}
                  color={
                    props.currentTab === 'orders' && display === 'none'
                      ? '#f1592a'
                      : '#6D6E70'
                  }
                />
                <span
                  className="footer-icon-font"
                  style={{
                    paddingTop: '5px',
                    color:
                      props.currentTab === 'orders' && display === 'none'
                        ? '#f1592a'
                        : '#6D6E70',
                  }}
                >
                  Orders
                </span>
              </Menu.Item>

              <Menu.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                }}
                className="nav-item"
                as={NavLink}
                to={
                  window?.location?.pathname?.includes('/buyer')
                    ? '/portal/buyer/notifications'
                    : '/portal/supplier/notifications'
                }
                onClick={() => setDisplay('none')}
              >
                <Bell
                  style={{ cursor: 'pointer' }}
                  size={25}
                  color={
                    props.currentTab === 'notifications' && display === 'none'
                      ? '#f1592a'
                      : '#6D6E70'
                  }
                />
                <span
                  className="footer-icon-font"
                  style={{
                    paddingTop: '5px',
                    color:
                      props.currentTab === 'notifications' && display === 'none'
                        ? '#f1592a'
                        : '#6D6E70',
                  }}
                >
                  Alerts
                </span>

                <div
                  style={{
                    display: notifications.length < 1 ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '15px',
                    height: '15px',
                    backgroundColor: env.REACT_APP_PRIMARY_COLOR,
                    position: 'absolute',
                    borderRadius: '500px',
                    top: '-5px',
                    right: '20px',
                    color: 'white',
                    padding: '11px',
                    fontSize: '11px',
                  }}
                >
                  {notifications.legnth < 1 && ''}
                  {notifications.length < 10 && notifications.length}
                  {notifications.length > 9 && '10+'}
                </div>
              </Menu.Item>

              <Menu.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                className="nav-item"
                as={'div'}
                onClick={() => {
                  props.setStartListingModal(true)
                }}
              >
                <PlusCircle
                  style={{ cursor: 'pointer' }}
                  size={25}
                  color={'#6D6E70'}
                />
                <span
                  className="footer-icon-font"
                  style={{ paddingTop: '5px', color: '#6D6E70' }}
                >
                  Add
                </span>
              </Menu.Item>

              <Menu.Item
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                onClick={() => {
                  setDisplay(display === 'none' ? 'block' : 'none')
                  // props?.setProfileModel(!props?.profileModel)
                }}
              >
                <User
                  style={{ cursor: 'pointer' }}
                  size={25}
                  color={display !== 'none' ? '#f1592a' : '#6D6E70'}
                />
                <span
                  className="footer-icon-font"
                  style={{
                    paddingTop: '5px',
                    color: display !== 'none' ? '#f1592a' : '#6D6E70',
                  }}
                >
                  {user?.first_name}
                </span>
              </Menu.Item>
              <div
                style={{
                  display: display,
                  position: 'absolute',
                  left: '0',
                  bottom: '80px',
                  width: '100%',
                  height:
                    props.currentPortal === 'admin'
                      ? 'calc(100vh - 132px)'
                      : 'calc(100vh - 55px)',
                  backgroundColor: 'white',
                  padding: '0 1rem 0 1rem',
                }}
              >
                <Grid centered style={{ padding: '1rem 0rem', margin: '0' }}>
                  <Grid.Row style={{ margin: '0', padding: '2rem 0rem' }}>
                    <Header as="h1">{business?.name}</Header>
                  </Grid.Row>
                  <Grid.Row style={{ margin: '0', padding: '0' }}>
                    <p>{user?.first_name + ' ' + user?.last_name}</p>
                  </Grid.Row>
                  <Grid.Row style={{ margin: '0', padding: '0' }}>
                    <p style={{ color: '#6D6E70' }}>{user?.email}</p>
                  </Grid.Row>
                  <Grid.Row style={{ padding: '3rem' }}>
                    <div
                      onClick={() => {
                        setDisplay('none')
                        if (
                          window?.location?.pathname?.includes(
                            '/portal/buyer/profile'
                          ) ||
                          window?.location?.pathname?.includes(
                            '/portal/supplier/profile'
                          )
                        ) {
                        } else {
                          if (
                            window?.location?.pathname?.includes('/supplier')
                          ) {
                            navigate('/portal/supplier/profile')
                          } else {
                            navigate('/portal/buyer/profile')
                          }
                        }
                      }}
                      style={{
                        color: env.REACT_APP_PRIMARY_COLOR,
                        outline: '1px solid #F1592A',
                        'line-height': '50px',
                        width: '200px',
                        height: '50px',
                        'border-radius': '25px',
                        'font-size': 'medium',
                      }}
                    >
                      Manage Your Profile
                    </div>
                  </Grid.Row>
                  {((props.currentPortal === 'supplier' && props.buyer?.id) ||
                    (props.currentPortal === 'buyer' &&
                      props?.supplier?.id)) && (
                    <>
                      <Divider />
                      <Grid.Row
                        style={{
                          cursor: 'pointer',
                          padding: '0',
                        }}
                        onClick={() => {
                          setDisplay('none')
                          if (props.currentPortal === 'buyer') {
                            window.location = '/portal/supplier'
                          } else {
                            window.location = '/portal/buyer'
                          }
                        }}
                      >
                        <p
                          style={{
                            width: '100%',
                            display: 'flex',
                            flex: '0 1 75%',
                            margin: '0px',
                          }}
                          className={'switch-portal-row'}
                        >
                          {props.currentPortal === 'buyer' ? (
                            <>{business?.name} Supplier Portal</>
                          ) : (
                            <>{business?.name} Buyer Portal</>
                          )}
                        </p>
                        <ArrowRight
                          size={'25px'}
                          style={{ display: 'flex', flex: '0 1 16%' }}
                        />
                      </Grid.Row>
                      <Divider />
                    </>
                  )}

                  {props.currentPortal === 'admin' &&
                    props.supplier?.id &&
                    hasPortalAccess(user, 'supplier') && (
                      <>
                        <Divider />
                        <Grid.Row
                          style={{
                            cursor: 'pointer',
                            padding: '0',
                          }}
                          onClick={() => {
                            setDisplay('none')
                            navigate('/portal/supplier')
                          }}
                        >
                          <p
                            style={{
                              width: '100%',
                              display: 'flex',
                              flex: '0 1 75%',
                              margin: '0px',
                            }}
                            className={'switch-portal-row'}
                          >
                            {business?.name} Supplier Portal
                          </p>
                          <ArrowRight
                            size={'25px'}
                            style={{ display: 'flex', flex: '0 1 16%' }}
                          />
                        </Grid.Row>
                      </>
                    )}
                  {props.currentPortal === 'admin' &&
                    props.buyer?.id &&
                    hasPortalAccess(user, 'buyer') && (
                      <>
                        <Divider />
                        <Grid.Row
                          style={{
                            cursor: 'pointer',
                            padding: '0',
                          }}
                          onClick={() => {
                            setDisplay('none')
                            navigate('/portal/buyer')
                          }}
                        >
                          <p
                            style={{
                              width: '100%',
                              display: 'flex',
                              flex: '0 1 75%',
                              margin: '0px',
                            }}
                            className={'switch-portal-row'}
                          >
                            {business?.name} Buyer Portal
                          </p>
                          <ArrowRight
                            size={'25px'}
                            style={{ display: 'flex', flex: '0 1 16%' }}
                          />
                        </Grid.Row>
                      </>
                    )}
                  {props.currentPortal !== 'admin' &&
                    hasPortalAccess(user, 'admin') && (
                      <>
                        <Divider />
                        <Grid.Row
                          style={{ cursor: 'pointer', padding: '0' }}
                          onClick={() => {
                            setDisplay('none')
                            navigate('/portal/admin')
                          }}
                        >
                          <p
                            style={{
                              width: '100%',
                              display: 'flex',
                              flex: '0 1 75%',
                              margin: '0px',
                            }}
                            className={'switch-portal-row'}
                          >
                            Switch to Admin Portal
                          </p>
                          <ArrowRight
                            size={'25px'}
                            style={{ display: 'flex', flex: '0 1 16%' }}
                          />
                        </Grid.Row>
                      </>
                    )}
                  <Divider />
                  <Grid.Row
                    style={{
                      cursor: 'pointer',
                      padding: '0',
                    }}
                  >
                    <Grid.Column>
                      <p
                        style={{
                          color: '#E63323',
                          padding: '0',
                        }}
                        onClick={async () => {
                          await auth.signout()
                          setDisplay('none')
                          dispatch(loggedOut())
                          dispatch(removeUser())
                          dispatch(removeBusiness())
                          if (
                            env.REACT_APP_REDIRECT &&
                            env.REACT_APP_REDIRECT !== 'false' &&
                            env.REACT_APP_REDIRECT !== 'null'
                          ) {
                            window.location = '/login'
                          } else {
                            window.location = '/'
                          }
                        }}
                      >
                        Log Out
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Grid>
              </div>
            </Menu>
          )}
          {(windowDimensions.width > 768 ||
            (windowDimensions.width <= 768 && !user)) && (
            <footer>
              <List className="footer_ul">
                <List.Item className="Footer_logo">
                  <div className="footer_logo">
                    <a href="/">
                      <img src={env.REACT_APP_MINI_LOGO} alt="" />
                    </a>
                  </div>
                </List.Item>
                <List.Item className="socials">
                  <p
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    className="footer-sub-heading"
                  >
                    SOCIALS
                  </p>
                  <a
                    href={env.REACT_APP_INSTAGRAM}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="instagram icon"></i>
                  </a>
                  <a
                    href={env.REACT_APP_TWITTER}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="twitter icon"></i>
                  </a>
                  <a
                    href={env.REACT_APP_LINKEDIN}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="linkedin icon"></i>
                  </a>
                  <a
                    href={env.REACT_APP_FACEBOOK}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i aria-hidden="true" className="facebook icon"></i>
                  </a>
                </List.Item>
                <List.Item className="legal">
                  <p
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    className="footer-sub-heading"
                  >
                    LEGAL
                  </p>
                  <p>
                    <a href="/privacy-policy">Privacy</a>
                  </p>
                  <p>
                    <a href="/legal">Legal</a>
                  </p>
                </List.Item>
                <List.Item className="news">
                  <p
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    className="footer-sub-heading"
                  >
                    NEWS
                  </p>
                  <p>
                    <a href="/press">Press</a>
                  </p>
                  <p>
                    <a href="/news">News</a>
                  </p>
                </List.Item>
                <List.Item className="contact">
                  <p
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    className="footer-sub-heading"
                  >
                    CONTACT
                  </p>
                  <p>{env.REACT_APP_BUSINESS_CONTACT_PHONE}</p>
                  <p>
                    <a href={`mailto:${env.REACT_APP_BUSINESS_CONTACT_EMAIL}`}>
                      {env.REACT_APP_BUSINESS_CONTACT_EMAIL}
                    </a>
                  </p>
                </List.Item>
                <List.Item className="copyright">
                  {env.REACT_APP_BUSINESS_NAME} © {new Date().getFullYear()}
                </List.Item>
              </List>
            </footer>
          )}
        </div>
      )}
    </>
  )
}

export default MXFooter
