import { useState, useEffect } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import './AutoDropdown.css'
import _ from 'underscore'

function AutoDropdown(props) {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState()
  const [options, setOptions] = useState([])
  const axios = useAxios()

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [props.value])

  async function updateField(e, d) {
    if (props.onChange) {
      props.onChange(e, d)
    }
    if (!props.dontUpdate) {
      setLoading(true)
      await axios
        .post(`/main/auto-update/`, {
          model: props.model,
          field: props.field,
          value: d.value,
          instance_id: props.instanceId,
          updating_fields: props.updating_fields,
          serializer: props.serializer,
        })
        .then((res) => {
          if (props.onUpdate) {
            props.onUpdate(res)
          }
        })
      setLoading(false)
    }
  }

  useEffect(() => {
    async function getOptions() {
      let params = props.optionsParams ? props.optionsParams : {}
      if (props.optionsUrl) {
        let res = await axios.get(props.optionsUrl, { params: params })
        if (res?.data?.results) {
          if (props.optionsFunction) {
            setOptions(props.optionsFunction(res?.data?.results))
            if (props.setParentOptions) {
              props.setParentOptions(props.optionsFunction(res?.data?.results))
            }
          } else {
            let opt = _.map(res.data.results, (option) => {
              return {
                key: option?.id,
                value: option?.id,
                text: option[props.optionTitleField],
              }
            })
            setOptions(opt)
            if (props.setParentOptions) {
              props.setParentOptions(res.data.results)
            }
          }
        } else {
          setOptions(res?.data)
          if (props.setParentOptions) {
            props.setParentOptions(res?.data)
          }
        }
      } else {
        setOptions(props.options)
        if (props.setParentOptions) {
          props.setParentOptions(props.options)
        }
      }
    }

    getOptions()
  }, [])

  return (
    <>
      {props.header && <p>{props.header}</p>}
      {props.inline ? (
        <Dropdown
          button
          size={props.size}
          className={'auto-dropdown ' + props.className ? props.className : ''}
          color={props.color}
          icon={props.icon}
          error={props.error}
          style={props.style}
          placeholder={props.placeholder}
          value={value}
          basic={props.basic}
          disabled={props.disabled}
          onChange={(event, data) => {
            updateField(event, data)
            setValue(data.value)
          }}
          loading={loading}
          options={options}
        />
      ) : (
        <Form.Dropdown
          fluid
          className={'auto-dropdown'}
          size={props.size}
          color={props.color}
          selection
          error={props.error}
          disabled={props.disabled}
          button={props.button}
          label={props.label}
          value={value}
          onChange={(event, data) => {
            updateField(event, data)
            setValue(data.value)
          }}
          loading={loading}
          options={options}
        />
      )}
    </>
  )
}

export default AutoDropdown
