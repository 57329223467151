import MickeyForm from '../../../components/Forms/MickeyForm'

function AdminOrderInfo(props) {
  const { order } = props

  return (
    <MickeyForm
      style={{ marginTop: '25px', padding: '0 15px' }}
      formName={'AdminOrderForm'}
      basicSegment
      model={'Order'}
      tenantAware={false}
      instanceId={order?.string_token}
      instanceIdOverwrite={order?.id}
      url={'/main/orders/'}
      buttonLabel={'Add Order'}
    />
  )
}

export default AdminOrderInfo
