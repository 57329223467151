import React, { useState, useEffect } from 'react'
import { Divider, Grid, Header, Button } from 'semantic-ui-react'
import env from '../../../env'
import './MxEvent.css'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import useAxios from '../../../lib/auth/useAxios'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { useNavigate } from 'react-router-dom'

const MxEvents = () => {
  const navigate = useNavigate()
  const axios = useAxios()
  const window = useWindowDimensions()
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (
      env.REACT_APP_REDIRECT &&
      env.REACT_APP_REDIRECT !== 'false' &&
      env.REACT_APP_REDIRECT !== 'null'
    ) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    const getEvents = async () => {
      let res = await axios.get(`/main/events/`)
      if (res?.data?.results) {
        setEvents(res?.data?.results)
      }
    }
    getEvents()
  }, [])

  return (
    <Grid id="event-page">
      <Grid.Column width={window.width < 768 ? 15 : 14}>
        <Header className="main-heading" as={'h1'} style={{ fontSize: '36px' }}>
          Events{' '}
        </Header>
        <Divider />
        {events && events?.length > 0 ? (
          events?.map((event) => {
            return (
              <>
                <Grid stackable className="event-row" width={16}>
                  <Grid.Column className="info-wrapper" width={13}>
                    <h5 className="heading">{event.event_date}</h5>
                    <h3 className="sec-heading">{event?.name}</h3>
                    <p className="para-heading">{event.description}</p>
                  </Grid.Column>
                  <Grid.Column className="button-wrapper" width={3}>
                    <Button
                      style={{
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        '--secondaryButtonColor':
                          env.REACT_APP_SECONDARY_BUTTON_COLOR,
                        padding: '1rem',
                      }}
                      color="primary"
                    >
                      View Event
                    </Button>
                  </Grid.Column>
                </Grid>
                <Divider />
              </>
            )
          })
        ) : (
          <MickeyPlaceholder
            icon={'globe'}
            message={'No Events'}
            style={{
              backgroundColor: 'white',
              border: 'none',
              boxShadow: 'none',
            }}
          />
        )}
      </Grid.Column>
    </Grid>
  )
}

export default MxEvents
