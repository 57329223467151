import React, { useState, useEffect } from 'react'
import './Menu.css'
import {
  Menu,
  Dropdown,
  Header,
  Modal,
  Dimmer,
  Popup,
  Grid,
  Divider,
  Sidebar,
} from 'semantic-ui-react'
import {
  X,
  ArrowRight,
  Package,
  AddressBook,
  CalendarBlank,
  Recycle,
  Gear,
  FileCloud,
  Bank,
  Truck,
  MagnifyingGlass,
  CirclesThreePlus,
} from 'phosphor-react'
import { useAuth } from '../../lib/auth/useAuth'
import MickeySearch from '../Search/MickeySearch'
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { useSelector } from 'react-redux'
import env from '../../env'
import MickeyForm from '../Forms/MickeyForm'
import {
  loggedOut,
  removeBusiness,
  removeUser,
} from '../../features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useWebsocket } from '../../hooks/useWebsocket'
import { hasPermission, hasPortalAccess, inGroup } from '../../lib/utils/utils'
import './Admin.css'
import { Squash } from 'hamburger-react'

export default function Admin(props) {
  const { disconnectWs } = useWebsocket()
  const user = useSelector((state) => state.user.user)
  const business = useSelector((state) => state.user.business)
  const dispatch = useDispatch()
  const [activeItem, setActiveItem] = useState('deals')
  let handleItemClick = (e, { name }) => {
    setActiveItem(name)
  }
  const [loading, setLoading] = useState(false)
  const [openAddEvent, setOpenAddEvent] = useState(false)
  const [openAddNewsArticle, setOpenAddNewsArticle] = useState(false)
  const [dim, setDim] = useState(false)
  const [open, setOpen] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)

  const auth = useAuth()
  let navigate = useNavigate()
  const windowWidth = useWindowDimensions()
  const location = useLocation()

  useEffect(() => {
    setActiveItem(
      location.pathname.includes('/portal/admin/deals')
        ? 'deals'
        : location.pathname.includes('/portal/admin/businesses') ||
          location.pathname.includes('/portal/admin/business')
        ? 'businesses'
        : location.pathname.includes('/portal/admin/order-books') ||
          location.pathname.includes('/portal/admin/live-bids-and-asks') ||
          location.pathname.includes('/portal/admin/take-rate')
        ? 'exchange'
        : location.pathname.includes('/portal/admin/price-history') ||
          location.pathname.includes('/portal/admin/payments') ||
          location.pathname.includes('/portal/admin/purchase-orders') ||
          location.pathname.includes('/portal/admin/settlement-statements') ||
          location.pathname.includes('/portal/admin/sales-orders')
        ? 'accounting'
        : location.pathname.includes('/portal/admin/calendar')
        ? 'calendar'
        : location.pathname.includes('/portal/admin/dashboard') ||
          location.pathname.includes('/portal/admin/reports') ||
          location.pathname.includes('/portal/admin/report/load_log_report') ||
          location.pathname.includes('/portal/admin/report/sales_tax_report') ||
          location.pathname.includes(
            '/portal/admin/report/active_businesses_report'
          )
        ? 'reports'
        : location.pathname.includes('/portal/admin/settings')
        ? 'settings'
        : location.pathname.includes('/portal/admin/sales')
        ? 'sales'
        : location.pathname.includes('/portal/admin/fulfillment')
        ? 'fulfillment'
        : 'deals'
    )
  }, [location?.pathname])

  useEffect(() => {
    props.setCurrentPortal('admin')
    document.body.style.zoom = '85%'
  })

  useEffect(() => {
    if (user?.id && !user?.is_god) {
      navigate('/login')
    }
  }, [user])
  const accountingOptions = []
  if (hasPermission(user, 'Read Payment')) {
    accountingOptions.push({
      key: 'Accounting',
      text: 'Accounting',
      value: 'Accounting',
    })
  }
  if (hasPermission(user, 'Read Price History')) {
    accountingOptions.push({
      key: 'Price History',
      text: 'Price History',
      value: 'Price History',
    })
  }
  if (hasPermission(user, 'Read Sales Order')) {
    accountingOptions.push({
      key: 'Sales Orders',
      text: 'Sales Orders',
      value: 'Sales Orders',
    })
  }
  if (hasPermission(user, 'Read Purchase Order')) {
    accountingOptions.push({
      key: 'Purchase Orders',
      text: 'Purchase Orders',
      value: 'Purchase Orders',
    })
  }
  if (hasPermission(user, 'Read Purchase Order')) {
    accountingOptions.push({
      key: 'Settlement Statements',
      text: 'Settlement Statements',
      value: 'Settlement Statements',
    })
  }

  const menuItemsJSX = (
    <>
      {hasPermission(user, 'Read Order') && (
        <Menu.Item
          as={NavLink}
          icon={
            <Package weight="fill" size={15} style={{ marginRight: '5px' }} />
          }
          name="deals"
          className={'main-item'}
          style={{
            color: activeItem === 'deals' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
          onClick={handleItemClick}
          to="/portal/admin/deals"
        />
      )}
      {hasPermission(user, 'Read Business') && (
        <Menu.Item
          as={NavLink}
          icon={
            <AddressBook
              weight="fill"
              size={15}
              style={{ marginRight: '5px' }}
            />
          }
          name={'businesses'}
          className={'main-item'}
          style={{
            color:
              activeItem === 'businesses' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
          onClick={handleItemClick}
          to="/portal/admin/businesses"
        />
      )}
      {hasPermission(user, 'Read Exchange') && (
        <Menu.Item
          icon={
            <Recycle weight="fill" size={15} style={{ marginRight: '5px' }} />
          }
          name={'exchange'}
          className={'main-item'}
          content={
            <Dropdown
              text="Exchange"
              className={'sales-dropdown'}
              value={null}
              hover
              onChange={(e, d) => {
                setActiveItem('exchange')
                if (d.value === 'Order Books') {
                  navigate('/portal/admin/order-books')
                } else if (d.value === 'Live Bids & Asks') {
                  navigate('/portal/admin/live-bids-and-asks')
                } else if (d.value === 'Exchange Settings') {
                  navigate('/portal/admin/take-rate')
                }
              }}
              options={[
                {
                  key: 'Order Books',
                  text: 'Order Books',
                  value: 'Order Books',
                },
                {
                  key: 'Live Bids & Asks',
                  text: 'Live Bids & Asks',
                  value: 'Live Bids & Asks',
                },
                {
                  key: 'Exchange Settings',
                  text: 'Exchange Settings',
                  value: 'Exchange Settings',
                },
              ]}
            />
          }
          style={{
            color: activeItem === 'exchange' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
        />
      )}
      <Menu.Item
        as={NavLink}
        icon={
          <CirclesThreePlus
            weight="fill"
            size={15}
            style={{ marginRight: '5px' }}
          />
        }
        name="sales"
        className={'main-item'}
        style={{
          color: activeItem === 'sales' ? env.REACT_APP_PRIMARY_COLOR : '',
          backgroundColor: 'transparent',
        }}
        onClick={handleItemClick}
        to="/portal/admin/sales"
      />
      <Menu.Item
        as={NavLink}
        icon={<Truck weight="fill" size={15} style={{ marginRight: '5px' }} />}
        name={'fulfillment'}
        className={'main-item'}
        style={{
          color:
            activeItem === 'fulfillment' ? env.REACT_APP_PRIMARY_COLOR : '',
          backgroundColor: 'transparent',
        }}
        onClick={handleItemClick}
        to="/portal/admin/fulfillment"
      />
      {(hasPermission(user, 'Read Payment') ||
        hasPermission(user, 'Read Price History')) && (
        <Menu.Item
          icon={<Bank weight="fill" size={15} style={{ marginRight: '5px' }} />}
          name={'accounting'}
          className={'main-item'}
          content={
            <Dropdown
              text="Accounting"
              className={'sales-dropdown'}
              value={null}
              onClick={() => {}}
              onChange={(e, d) => {
                setActiveItem('accounting')
                if (d.value === 'Price History') {
                  navigate('/portal/admin/price-history')
                } else if (d.value === 'Accounting') {
                  navigate('/portal/admin/payments')
                } else if (d.value === 'Purchase Orders') {
                  navigate('/portal/admin/purchase-orders')
                } else if (d.value === 'Sales Orders') {
                  navigate('/portal/admin/sales-orders')
                } else if (d.value === 'Settlement Statements') {
                  navigate('/portal/admin/settlement-statements')
                }
              }}
              options={accountingOptions}
            />
          }
          style={{
            color:
              activeItem === 'accounting' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
        />
      )}
      {hasPermission(user, 'Read Calendar') && (
        <Menu.Item
          as={NavLink}
          icon={
            <CalendarBlank
              weight="fill"
              size={15}
              style={{ marginRight: '5px' }}
            />
          }
          name={'calendar'}
          className={'main-item'}
          style={{
            color: activeItem === 'calendar' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
          onClick={handleItemClick}
          to="/portal/admin/calendar"
        />
      )}
      {hasPermission(user, 'Read Report') && (
        <Menu.Item
          icon={
            <FileCloud weight="fill" size={15} style={{ marginRight: '5px' }} />
          }
          name={'reports'}
          className={'main-item'}
          content={
            <Dropdown
              text="Reports"
              className={'sales-dropdown'}
              value={null}
              hover
              onClick={() => {}}
              onChange={(e, d) => {
                setActiveItem('reports')
                if (d.value === 'Dashboard') {
                  navigate('/portal/admin/dashboard')
                } else if (d.value === 'Reports') {
                  navigate('/portal/admin/reports')
                }
              }}
              options={[
                { key: 'Dashboard', text: 'Dashboard', value: 'Dashboard' },
                { key: 'Reports', text: 'Reports', value: 'Reports' },
              ]}
            />
          }
          style={{
            color: activeItem === 'reports' ? env.REACT_APP_PRIMARY_COLOR : '',
            backgroundColor: 'transparent',
          }}
        />
      )}
      <Dimmer page active={dim} style={{ opacity: '0.35' }} />
      <Popup
        as={Menu.Item}
        on="click"
        onOpen={() => {
          setOpen(true)
          setDim(true)
        }}
        onClose={() => {
          setOpen(false)
          setDim(false)
        }}
        offset={[-315, 0]}
        trigger={
          <Menu.Item style={{ backgroundColor: 'transparent' }}>
            <div className="profile-icon">
              {user?.first_name ? user?.first_name[0] : ''}
              {user?.last_name ? user?.last_name[0] : ''}
            </div>
          </Menu.Item>
        }
        open={open}
        position="bottom right"
        className="admin-user-popup"
        wide
      >
        <Grid
          centered
          style={{ padding: '1rem 0rem', margin: '0', width: '100%' }}
        >
          <Grid.Row
            style={{ margin: '0 1rem', padding: '0', justifyContent: 'right' }}
          >
            <X
              size={24}
              weight="bold"
              style={{ margin: '0', cursor: 'pointer' }}
              onClick={() => {
                setOpen(false)
                setDim(false)
              }}
            />
          </Grid.Row>
          <Grid.Row style={{ margin: '0', padding: '2rem 0rem' }}>
            <Header as="h1">{business?.name}</Header>
          </Grid.Row>
          <Grid.Row style={{ margin: '0', padding: '0' }}>
            <p>{user?.first_name + ' ' + user?.last_name}</p>
          </Grid.Row>
          <Grid.Row style={{ margin: '0', padding: '0' }}>
            <p style={{ color: '#6D6E70' }}>{user?.email}</p>
          </Grid.Row>
          {hasPermission(user, 'Read Settings') && (
            <Grid.Row style={{ padding: '3rem' }}>
              <div
                className="manage-settings-btn"
                onClick={() => {
                  setOpen(false)
                  setDim(false)
                  navigate('/portal/admin/settings')
                }}
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Manage Settings
              </div>
            </Grid.Row>
          )}
          {business?.supplier?.id &&
            !business?.supplier?.archived &&
            hasPortalAccess(user, 'supplier') && (
              <>
                <Divider style={{ margin: '1rem 0rem' }} />
                <Grid.Row
                  style={{
                    cursor: 'pointer',
                    padding: '0',
                  }}
                  onClick={() => {
                    navigate('/portal/supplier')
                  }}
                >
                  <div
                    className={'switch-portal-row'}
                    style={{ display: 'contents' }}
                  >
                    <p
                      style={{
                        width: '100%',
                        display: 'flex',
                        flex: '0 1 75%',
                        margin: '0px',
                      }}
                    >
                      {business?.name} Supplier Portal
                    </p>
                    <ArrowRight
                      size={'25px'}
                      style={{ display: 'flex', flex: '0 1 16%' }}
                    />
                  </div>
                </Grid.Row>
              </>
            )}
          {business?.buyer?.id &&
            !business?.buyer?.archived &&
            hasPortalAccess(user, 'buyer') && (
              <>
                <Divider style={{ margin: '1rem 0rem' }} />
                <Grid.Row
                  style={{
                    cursor: 'pointer',
                    padding: '0',
                  }}
                  onClick={() => {
                    navigate('/portal/buyer')
                  }}
                >
                  <div
                    className={'switch-portal-row'}
                    style={{ display: 'contents' }}
                  >
                    <p
                      style={{
                        width: '100%',
                        display: 'flex',
                        flex: '0 1 75%',
                        margin: '0px',
                      }}
                    >
                      {business?.name} Buyer Portal
                    </p>
                    <ArrowRight
                      size={'25px'}
                      style={{ display: 'flex', flex: '0 1 16%' }}
                    />
                  </div>
                </Grid.Row>
              </>
            )}
          <Divider style={{ margin: '1rem 0rem' }} />
          <Grid.Row
            style={{
              cursor: 'pointer',
              padding: '0',
            }}
          >
            <Grid.Column>
              <p
                className={'logout-row'}
                style={{
                  padding: '0',
                }}
                onClick={async () => {
                  disconnectWs()
                  dispatch(loggedOut())
                  dispatch(removeUser())
                  dispatch(removeBusiness())
                  await auth.signout()
                  if (
                    env.REACT_APP_REDIRECT &&
                    env.REACT_APP_REDIRECT !== 'false' &&
                    env.REACT_APP_REDIRECT !== 'null'
                  ) {
                    window.location = '/login'
                  } else {
                    window.location = '/'
                  }
                }}
              >
                Log Out
              </p>
            </Grid.Column>
          </Grid.Row>
          <Divider style={{ margin: '1rem 0rem' }} />
        </Grid>
      </Popup>
    </>
  )

  return (
    <div id="admin" className={'header-container'}>
      <div
        className={'header-container'}
        style={{ position: 'fixed', width: '100%', backgroundColor: 'white' }}
      >
        <p
          style={{
            textAlign: 'right',
            color:
              props.currentPortal === 'supplier'
                ? env.REACT_APP_PRIMARY_COLOR
                : props.currentPortal === 'buyer'
                ? '#4FAA80'
                : '',
            fontSize: '12px',
            padding: '6px 10px',
            margin: 0,
          }}
        >
          Admin |{' '}
          <span style={{ fontWeight: 'Bold' }}>
            {env.REACT_APP_BUSINESS_NAME}
          </span>
        </p>
        <Menu
          className={'admin-mode'}
          style={{ margin: 0, backgroundColor: 'white' }}
        >
          <Menu.Item
            header
            as={Header}
            onClick={handleItemClick}
            className={'logo-container main-item'}
            to="/portal/admin"
          >
            <img
              className={'logo-img'}
              src={env.REACT_APP_LOGO}
              alt="Logo"
              style={{ height: '40px', marginLeft: '20px' }}
            />
          </Menu.Item>
          {windowWidth.width >= 450 && (
            <Menu.Item>
              <MickeySearch
                emptyFieldAfterRedirect={true}
                placeholder={'Search orders businesses, etc.'}
                url="/main/global-search/"
                name={'global-search'}
                className={'global-search'}
                locationString={location?.pathname}
                titleFields={['title']}
                descriptionFields={['description']}
                onChange={(e, d) => {
                  if (
                    d.result.url.includes('/business/') &&
                    location?.pathname?.includes('/business/')
                  ) {
                    window.location.href = d.result.url
                  } else {
                    navigate(d.result.url)
                  }
                }}
                icon={<MagnifyingGlass size={20} weight="bold" />}
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
                loading={loading}
                setLoading={setLoading}
              />
            </Menu.Item>
          )}
          <Menu.Menu position="right">
            {windowWidth.width < 1199 && (
              <Menu.Item>
                <Squash
                  size={25}
                  toggled={openMobileMenu}
                  toggle={setOpenMobileMenu}
                />
              </Menu.Item>
            )}
            {windowWidth.width >= 1199 && <>{menuItemsJSX}</>}
          </Menu.Menu>
        </Menu>
      </div>
      {windowWidth.width < 1199 && (
        <Sidebar
          id="admin-sidebar"
          className={openMobileMenu ? 'visible' : ''}
          as={Menu}
          direction="right"
        >
          {menuItemsJSX}
        </Sidebar>
      )}
      <div style={{ paddingTop: '90px' }}>
        <Outlet />
      </div>
      <Modal
        onClose={() => setOpenAddEvent(false)}
        onOpen={() => setOpenAddEvent(true)}
        open={openAddEvent}
        size="large"
        style={{
          padding: '2rem',
        }}
      >
        <MickeyForm
          formName={'AddEventForm'}
          model={'Event'}
          loadingHeight={'150px'}
          tenantAware={false}
          buttonLabel={'Add Event'}
          url={'/main/events/'}
          handleForm={() => setOpenAddEvent(false)}
        />
      </Modal>
      <Modal
        onClose={() => setOpenAddNewsArticle(false)}
        onOpen={() => setOpenAddNewsArticle(true)}
        open={openAddNewsArticle}
        size="large"
        style={{
          padding: '2rem',
        }}
      >
        <MickeyForm
          formName={'AddNewsArticleForm'}
          model={'News'}
          loadingHeight={'150px'}
          tenantAware={false}
          buttonLabel={'Add News Article'}
          url={'/main/news/'}
          handleForm={() => setOpenAddNewsArticle(false)}
        />
      </Modal>
    </div>
  )
}
