import React from 'react'
import { Grid } from 'semantic-ui-react'
import Permissions from '../../../components/Permissions/Permissions'

const AdminPermissions = () => {
  return (
    <div className={'main-container'} id="permissions">
      <Grid stackable>
        <Permissions />
      </Grid>
    </div>
  )
}

export default AdminPermissions
