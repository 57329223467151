import PageHeader from '../../../../components/PageHeader/PageHeader'
import { Segment, Button, Dropdown } from 'semantic-ui-react'
import env from '../../../../env'
import './AdminSalesDashboard.css'
import { useState, useEffect } from 'react'
import useAxios from '../../../../lib/auth/useAxios'
import Chart from 'react-apexcharts'
import AdminSalesScenarioModal from './AdminSalesScenarioModal'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import { Plus } from 'phosphor-react'

const AdminSalesDashboard = (props) => {
  const axios = useAxios()
  const [openSalesScenario, setOpenSalesScenario] = useState(null)
  const [data, setData] = useState(null)
  const [chartData, setChartData] = useState(null)
  const [topFilter, setTopFilter] = useState({
    header_filter_unit: 'week',
    header_filter_period: 1,
  })
  const [graphFilter, setGraphFilter] = useState({
    graph_filter_unit: 'month',
    graph_filter_period: 2,
  })

  useEffect(() => {
    const getdata = async () => {
      const res = await axios.get(`/main/admin-users/${props.userId}/sales/`, {
        params: {
          ...topFilter,
          ...graphFilter,
        },
      })
      if (res?.data) {
        setData(res?.data)
        setChartData({
          options: {
            chart: {
              id: 'sales-order-chart',
              toolbar: {
                tools: {
                  download: false,
                },
              },
            },
            xaxis: {
              categories: res?.data?.monthly_orders?.map((obj) => obj?.month),
            },
            dataLabels: {
              enabled: false,
            },
          },
          series: [
            {
              name: 'Orders',
              data: res?.data?.monthly_orders?.map((obj) => obj?.orders),
            },
          ],
        })
      }
    }

    getdata()
  }, [topFilter, graphFilter])

  let breadcrumbData = [{ name: 'Admin', href: '/portal/admin/orders' }]

  const topFilterChoices = {
    '6M': {
      header_filter_unit: 'month',
      header_filter_period: 6,
    },
    '3M': {
      header_filter_unit: 'month',
      header_filter_period: 3,
    },
    '1M': {
      header_filter_unit: 'month',
      header_filter_period: 1,
    },
    '1W': {
      header_filter_unit: 'week',
      header_filter_period: 1,
    },
  }

  const graphFilterChoices = [
    {
      key: '2 Month',
      text: '2 Month',
      value: '2 Month',
      data: {
        graph_filter_unit: 'month',
        graph_filter_period: 2,
      },
    },
    {
      key: '6 Month',
      text: '6 Month',
      value: '6 Month',
      data: {
        graph_filter_unit: 'month',
        graph_filter_period: 6,
      },
    },
    {
      key: '1 Year',
      text: '1 Year',
      value: '1 Year',
      data: {
        graph_filter_unit: 'year',
        graph_filter_period: 1,
      },
    },
  ]
  return (
    <>
      <PageHeader
        header="Dashboard"
        breadcrumbHeader="Sales Dashboard"
        breadcrumb={breadcrumbData}
        buttons={[
          {
            color: 'primary',
            icon: <Plus weight="bold" />,
            content: 'New Sales Scenario',
            onClick: () => setOpenSalesScenario(true),
            style: {
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            },
          },
        ]}
      />
      <Segment id="sales-analysis">
        <div className="filter">
          {Object.keys(topFilterChoices)?.map((key, i) => {
            return (
              <Button
                key={i}
                color="grey"
                size={'mini'}
                basic={
                  !(
                    topFilterChoices[key]?.header_filter_unit ===
                      topFilter?.header_filter_unit &&
                    topFilterChoices[key]?.header_filter_period ===
                      topFilter?.header_filter_period
                  )
                }
                style={{
                  borderRadius: '3px',
                  margin: '.25rem',
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
                onClick={() => setTopFilter(topFilterChoices[key])}
              >
                {key}
              </Button>
            )
          })}
        </div>
        <div className="analysis">
          <div className="box">
            <div>
              <div className="header">Total Sales</div>
              <div className="number">
                {formatTotalPrice(data?.sales?.total_sales)}
              </div>
              <div className="percentage">
                <span
                  className={
                    data?.sales?.diffrence > 0
                      ? 'positive'
                      : data?.sales?.diffrence < 0
                      ? 'negative'
                      : ''
                  }
                >
                  {data?.sales?.diffrence > 0
                    ? `+${data?.sales?.diffrence}`
                    : data?.sales?.diffrence}
                  %
                </span>{' '}
                from last{' '}
                {topFilter?.header_filter_period > 1 &&
                  topFilter?.header_filter_period}{' '}
                {topFilter?.header_filter_unit}
              </div>
            </div>
          </div>
          <div className="box">
            <div>
              <div className="header">New Businesses</div>
              <div className="number">{data?.business?.count}</div>
              <div className="percentage">
                <span
                  className={
                    data?.business?.diffrence > 0
                      ? 'positive'
                      : data?.business?.diffrence < 0
                      ? 'negative'
                      : ''
                  }
                >
                  {data?.business?.diffrence > 0
                    ? `+${data?.business?.diffrence}`
                    : data?.business?.diffrence}
                  %
                </span>{' '}
                from last{' '}
                {topFilter?.header_filter_period > 1 &&
                  topFilter?.header_filter_period}{' '}
                {topFilter?.header_filter_unit}
              </div>
            </div>
          </div>
          <div className="box">
            <div>
              <div className="header">Number of Deals</div>
              <div className="number">{data?.order?.count}</div>
              <div className="percentage">
                <span
                  className={
                    data?.order?.diffrence > 0
                      ? 'positive'
                      : data?.order?.diffrence < 0
                      ? 'negative'
                      : ''
                  }
                >
                  {data?.order?.diffrence > 0
                    ? `+${data?.order?.diffrence}`
                    : data?.order?.diffrence}
                  %
                </span>{' '}
                from last{' '}
                {topFilter?.header_filter_period > 1 &&
                  topFilter?.header_filter_period}{' '}
                {topFilter?.header_filter_unit}
              </div>
            </div>
          </div>
          <div className="box">
            <div>
              <div className="header">Active Deals</div>
              <div className="number">{data?.active_order?.count}</div>
            </div>
          </div>
        </div>
      </Segment>

      <Segment id="transactions">
        <div className="header">
          <span className="label">Deals</span>
          <span>
            <Dropdown
              id="mickey-dropdown"
              defaultValue={'2 Month'}
              fluid
              selection
              options={graphFilterChoices}
              onChange={(e, d) => {
                const option_data = d?.options?.find(
                  (option) => option.value === d.value
                )?.data
                setGraphFilter(option_data)
              }}
              style={{ width: '110px' }}
            />
          </span>
        </div>
        {chartData && (
          <Chart
            options={chartData?.options}
            series={chartData?.series}
            type="bar"
            width="100%"
            height={320}
          />
        )}
      </Segment>

      <AdminSalesScenarioModal
        open={openSalesScenario}
        setOpen={setOpenSalesScenario}
      />
    </>
  )
}

export default AdminSalesDashboard
