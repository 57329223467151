import React, { useEffect } from 'react'
import PriceHistoryTable from '../../../components/PriceHistory/PriceHistoryTable'
import './Profile.css'

const ProfilePriceHistory = (props) => {
  const { business } = props

  return (
    <div style={{ margin: '0px' }}>
      <PriceHistoryTable
        hideHeader={true}
        hideBusiness={true}
        businessId={business?.id}
        supplier={business?.supplier?.id ? true : false}
        buyer={business?.buyer?.id ? true : false}
        businessName={business?.name}
      />
    </div>
  )
}

export default ProfilePriceHistory
