import { useState, useEffect } from 'react'
import { Button, Header, Modal, Dropdown, Checkbox } from 'semantic-ui-react'
import './ExchangeTable.css'
import env from '../../../../../env'
import useAxios from '../../../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import MickeyDropdown from '../../../../../components/MickeyDropdown/MickeyDropdown'

const CopyWatchlistModal = (props) => {
  const {
    copyWatchlistModal,
    setCopyWatchlistModal,
    business,
    user,
    currentPortal,
  } = props
  const axios = useAxios()
  const [copytoUsers, setCopytoUsers] = useState([])
  const [currentlySelectedLocationId, setCurrentlySelectedLocationId] =
    useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    let user_options = []
    business?.user?.forEach((usr) => {
      if (usr.id) {
        user_options.push({
          text: `${usr?.first_name} ${usr.last_name}`,
          value: usr?.id,
          key: usr?.id,
        })
      }
    })
    setOptions(user_options)
  }, [business])

  async function handleCopyWatchlistModal() {
    let res = await axios.patch(`/main/custom-users/${user?.id}/`, {
      copy_watchlist: true,
      business_id: business?.id,
      user_ids: copytoUsers,
      users_location_id: currentlySelectedLocationId,
      side: currentPortal === 'supplier' ? 'ask' : 'bid',
    })
    if (res?.data?.success) {
      toast.success(res?.data?.success)
      setCopyWatchlistModal(false)
      setCurrentlySelectedLocationId(null)
      setCopytoUsers([])
    }
  }

  return (
    <>
      <Modal
        onOpen={() => setCopyWatchlistModal(true)}
        onClose={() => {
          setCopyWatchlistModal(false)
          setCurrentlySelectedLocationId(null)
          setCopytoUsers([])
        }}
        open={copyWatchlistModal}
        style={{
          minHeight: '420px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Copy Your Watchlist
            </Header>
            <Header.Subheader
              as={'h4'}
              style={{
                textAlign: 'center',
                marginTop: '0',
                marginBottom: '.5rem',
              }}
            >
              Select the user you would like to copy your watchlist items to.
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <label>Select Location</label>
            <MickeyDropdown
              url={'/main/locations/'}
              name={'locations'}
              autoComplete="new-password"
              label={'Select Location'}
              params={{
                business_id: business?.id,
                page_size: 'infinite',
              }}
              tenantAware={false}
              placeholder={'Select Address'}
              titleFields={['address_1']}
              descriptionFields={['city', 'state', 'zip_code']}
              style={{
                margin: '15px 0 20px 0',
                zIndex: '1003',
              }}
              onChange={(e, d) => {
                setCurrentlySelectedLocationId(d.value)
              }}
            />
            <div style={{ margin: '.5rem 0' }}>
              <Checkbox
                checked={options?.length === copytoUsers?.length}
                onChange={(e, d) => {
                  setCopytoUsers(
                    d?.checked ? options?.map((record) => record?.key) : []
                  )
                }}
              />
              <label style={{ margin: '0 5px' }}>Users</label>
            </div>
            <Dropdown
              label="users"
              style={{
                marginBottom: '1rem',
              }}
              placeholder={`Select users`}
              multiple
              selection
              fluid
              options={options}
              value={copytoUsers}
              onChange={(e, d) => {
                setCopytoUsers(d?.value)
              }}
            />
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              disabled={
                !(copytoUsers?.length > 0) || !currentlySelectedLocationId
              }
              color="primary"
              onClick={() => handleCopyWatchlistModal()}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default CopyWatchlistModal
