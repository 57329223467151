import * as Sentry from '@sentry/browser'
import env from '../env'

export const initSentry = () => {
  if (env.REACT_APP_ENVIRONMENT !== 'local') {
    Sentry.init({
      environment: env.REACT_APP_ENVIRONMENT,
      dsn: env.REACT_APP_SENTRY_DNS,
      tracesSampleRate: 1.0,
      release: env.REACT_APP_VERSION,
    })
  }
}
