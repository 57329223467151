import React, { useState, useEffect } from 'react'
import { Grid, Modal } from 'semantic-ui-react'
import './AdminDashboard.css'
import MickeyForm from '../../../components/Forms/MickeyForm'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import AdminManageUsersRow from './AdminManageUsersRow'

function AdminManageUsers(props) {
  const { developmentUsers, openAddUserModal, setOpenAddUserModal } = props
  const [reload, setReload] = useState(true)

  const params = developmentUsers
    ? {
        only_development_group: true,
        light_serializer: true,
      }
    : {
        disclude_development_group: true,
        light_serializer: true,
      }

  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={[
                'First Name',
                'Last Name',
                'Position',
                'Email',
                'Phone',
                'Receive SMS',
                'Enabled',
              ]}
              urlParams={params}
              url={'main/admin-users/'}
              basicSegment
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <AdminManageUsersRow
                    user={data}
                    developmentUsers={developmentUsers}
                    index={index}
                    setReload={setReload}
                    onClick={() => {}}
                    key={data ? data.id : null}
                  />
                )
              }}
              style={{ padding: '15px' }}
              emptyIcon={'user'}
              emptyMessage={'Admin users will populate here'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        onClose={() => setOpenAddUserModal(false)}
        onOpen={() => setOpenAddUserModal(true)}
        open={openAddUserModal}
      >
        <Modal.Header>Add User</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={
              developmentUsers
                ? 'AdminDevelopmentCustomUserForm'
                : 'AdminCustomUserForm'
            }
            model={'CustomUser'}
            url={'/main/admin-users/'}
            tenantAware={false}
            createAttributes={
              developmentUsers && {
                development: true,
              }
            }
            disclude_keys={['type']}
            handleForm={(status, data) => {
              if (status === 'success') {
                setReload(true)
                setOpenAddUserModal(false)
              }
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminManageUsers
