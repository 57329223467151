import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import useAxios from '../../../../lib/auth/useAxios'
import {
  Grid,
  Header,
  Divider,
  Breadcrumb,
  List,
  Button,
} from 'semantic-ui-react'
import env from '../../../../env'
import ProductIcon from '../../../../components/ProductIcon/ProductIcon'
import ProductSlider from '../../../../components/SliderComponent/Slider'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { track } from '@amplitude/analytics-browser'
import { useWebsocket } from '../../../../hooks/useWebsocket'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import { toast } from 'react-toastify'
import _ from 'underscore'
import PriceHistoryTable from '../../../../components/PriceHistory/PriceHistoryTable'
import MXProductVariantGraph from './MXProductVariantGraph'

const MXProductVariant = (props) => {
  const { user, setHideFilter, publicExchange } = props
  const axios = useAxios()
  const location = useLocation()
  const windowDimensions = useWindowDimensions()
  const { productSlug, productVariantSlug } = useParams()
  const { ws, wsOpen, resetWs, lastWsMessage, orderBookWsMessage } =
    useWebsocket()
  const tenant = useSelector((state) => state.globals.tenant)
  const business = useSelector((state) => state.user.business)
  const [productVariant, setProductVariant] = useState(null)
  const [similarProducts, setSimilarProducts] = useState([])
  const [lastEquipmentTypeSent, setLastEquipmentTypeSent] = useState(null)
  const [exchangeOrderBook, setExchangeOrderBook] = useState()
  const [lastTimeframeSent, setLastTimeframeSent] = useState(null)
  const [lastLocationSent, setLastLocationSent] = useState(null)
  const [reload, setReload] = useState(true)
  const [showPriceHistory, setShowPriceHistory] = useState(false)

  useEffect(() => {
    if (
      location.pathname.includes(`${productSlug}`) ||
      location.pathname.includes(`${productVariantSlug}`)
    ) {
      setHideFilter(true)
    }
    return () => {
      setHideFilter(false)
    }
  }, [])

  useEffect(() => {
    if (!wsOpen) {
      resetWs()
    }
  }, [wsOpen])

  const sendWsFilterOrderBooks = () => {
    setReload(false)
    setLastLocationSent(user?.current_location)
    setLastEquipmentTypeSent(user?.current_equipment_type?.id)
    setLastTimeframeSent(user?.current_delivery_timeframe)
    let message = {
      tenant_id: tenant?.id,
      filter_order_book: {
        page: 1,
        per_page: 15,
      },
    }
    message['filter_order_book']['location_id'] = user?.current_location?.id
    if (props.equipmentType) {
      message['filter_order_book']['cargo_unit_ids'] = [
        user?.current_equipment_type?.id,
      ]
    }
    if (productVariant) {
      message['filter_order_book']['product_variant_ids'] = [productVariant?.id]
    }
    ws.send(orderBookWsMessage(message))
    track('View Exchange', {
      Message: message,
      'User ID': user?.id,
      'Business Name': business?.name,
    })
  }

  useEffect(() => {
    if (
      ws &&
      wsOpen &&
      productVariant &&
      user?.current_location?.id &&
      (lastEquipmentTypeSent !== user?.current_equipment_type?.id ||
        lastTimeframeSent !== user?.current_delivery_timeframe ||
        lastLocationSent?.id !== user?.current_location?.id ||
        reload)
    ) {
      sendWsFilterOrderBooks()
    }
  }, [
    user?.current_location?.id,
    user?.current_delivery_timeframe,
    user?.current_equipment_type?.id,
    props.selectedCommodityForm?.id,
    props.selectedCommodity?.id,
    props.yourBids,
    props.product?.id,
    reload,
    productVariant,
    ws,
    wsOpen,
  ])

  useEffect(() => {
    if (lastWsMessage) {
      // If does not equal connection ID then this is an old message that has been overwritten
      let message = lastWsMessage
      let orderBooks = message['order_books']
      let type = message['type']
      if ('order' in message || 'trade' in message) {
        // an order was created or trade matched, resend the filter ob message for fasters update
        sendWsFilterOrderBooks()
      }
      if (type === 'update') {
        let exchangeOrderBookCopy = []
        let exchangeOrderBookPVs = orderBooks?.map((orderBook) => {
          return orderBook?.product_variant?.id
        })
        exchangeOrderBookCopy?.forEach((orderBook, idx) => {
          let exchIdx = exchangeOrderBookPVs?.indexOf(
            orderBook?.product_variant?.id
          )
          if (exchIdx >= 0) {
            exchangeOrderBookCopy[idx] = orderBooks[exchIdx]
            orderBooks.splice(exchIdx, 1)
          }
        })
        let newExchangeOrderBooks = exchangeOrderBookCopy.concat(orderBooks)
        if (props.currentPortal === 'supplier' && !props.yourBids) {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return orderBook?.bid_orders?.length > 0
          })
        }
        if (props.currentPortal === 'buyer' && !props.yourBids) {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return orderBook?.ask_orders?.length > 0
          })
        }
        if (props.currentPortal === 'supplier' && props.yourBids) {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return orderBook?.ask_orders?.length > 0
          })
        }
        if (props.currentPortal === 'buyer' && props.yourBids) {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return orderBook?.bid_orders?.length > 0
          })
        }
        if (props.currentPortal === 'supplier') {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return (
              orderBook?.bid_orders?.length > 0 &&
              orderBook?.highest_bid &&
              orderBook?.highest_bid > 0
            )
          })
        }
        if (props.currentPortal === 'buyer') {
          newExchangeOrderBooks = newExchangeOrderBooks.filter((orderBook) => {
            return (
              orderBook?.ask_orders?.length > 0 &&
              orderBook?.lowest_ask &&
              orderBook?.lowest_ask > 0
            )
          })
        }
        setExchangeOrderBook(
          newExchangeOrderBooks.length > 0 ? newExchangeOrderBooks[0] : {}
        )
      }
      if (type === 'initial') {
        if (props.currentPortal === 'supplier') {
          orderBooks = orderBooks.filter((orderBook) => {
            return orderBook?.bid_orders?.length > 0 && orderBook?.highest_bid
          })
        }
        if (props.currentPortal === 'buyer') {
          orderBooks = orderBooks.filter((orderBook) => {
            return orderBook?.ask_orders?.length > 0 && orderBook?.lowest_ask
          })
        }
        setExchangeOrderBook(orderBooks.length > 0 ? orderBooks[0] : {})
      }
    }
  }, [lastWsMessage])

  useEffect(() => {
    const getProductVariant = async (productId) => {
      let res = await axios.get(`/main/product-variants/`, {
        params: {
          slug: productVariantSlug,
          tenant_aware: false,
        },
      })
      if (res?.data?.results) {
        setProductVariant(res?.data?.results[0])
        return res?.data?.results[0].id
      }
      return
    }

    getProductVariant()
  }, [])

  useEffect(() => {
    const getSimilarProducts = async () => {
      let res = await axios.get(`/main/products/`, {
        params: {
          tenant_aware: false,
          commodity_form_id: productVariant?.commodity_form_id,
        },
      })
      if (res?.data?.results) {
        setSimilarProducts(res?.data?.results)
        setShowPriceHistory(true)
      }
    }
    if (productVariant) {
      getSimilarProducts()
    }
  }, [productVariant])

  let sections = [
    {
      key: 'Home',
      content: 'Home',
      link: true,
      href:
        props.currentPortal === 'buyer' ? '/portal/buyer' : '/portal/supplier',
    },
    {
      key: productVariant?.product?.commodity.name,
      content: productVariant?.product?.commodity.name,
      link: true,
      href:
        (props.currentPortal === 'buyer'
          ? '/portal/buyer/'
          : '/portal/supplier/') + productVariant?.product?.commodity?.slug,
    },
    {
      key: productVariant?.product?.commodity_form.name,
      content: productVariant?.product?.commodity_form.name,
      link: true,
      href:
        (props.currentPortal === 'buyer'
          ? '/portal/buyer/'
          : '/portal/supplier/') +
        productVariant?.product?.commodity?.slug +
        '/' +
        productVariant?.product?.commodity_form?.slug,
    },
    {
      key: productVariant?.product?.material.name,
      content: productVariant?.product?.material.name,
      href:
        (props.currentPortal === 'buyer'
          ? '/portal/buyer/'
          : '/portal/supplier/') +
        productVariant?.product?.commodity?.slug +
        '/' +
        productVariant?.product?.commodity_form?.slug +
        '/' +
        productVariant?.product?.slug +
        '/',
    },
    {
      key: _.map(productVariant?.attribute_selections, (attributeSelection) => {
        return attributeSelection.attribute_selection_value
      }).join(', '),
      content: _.map(
        productVariant?.attribute_selections,
        (attributeSelection) => {
          return attributeSelection.attribute_selection_value
        }
      ).join(', '),
      active: true,
    },
  ]

  return (
    <Grid
      stackable
      style={{
        padding:
          windowDimensions.width < 768
            ? '100px 40px 100px 40px'
            : '100px 0 100px 0',
      }}
    >
      {sections && productVariant && (
        <Breadcrumb
          icon="right angle"
          sections={sections}
          style={{
            marginBottom: '2rem',
            marginLeft: '10%',
            lineHeight: '1.5em',
          }}
        />
      )}
      <Grid.Row style={{ justifyContent: 'center', marginBottom: '2rem' }}>
        <Grid.Column width={6}>
          <img
            style={{
              width: windowDimensions.width < 768 ? '100%' : '450px',
              height: 'auto',
            }}
            src={
              env.REACT_APP_ENVIRONMENT === 'local'
                ? `http://marketplace.localhost:8002${productVariant?.product?.material?.material_display_image}`
                : productVariant?.product?.material?.material_display_image
            }
            alt=""
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ProductIcon
              productName={productVariant?.product?.material?.name}
              width={'56px'}
              height={'56px'}
              fontSize={'30px'}
              margin={'0 1rem 0 0'}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Header
                as={'h1'}
                style={{ margin: '0 1rem 0 0', fontSize: '36px' }}
              >
                {productVariant?.product_material_name}
              </Header>
              <p style={{ margin: '0', fontWeight: 'normal', opacity: '.6' }}>
                {productVariant?.attribute_selections?.map(
                  (selection, index) =>
                    productVariant?.attribute_selections.length - 1 !== index
                      ? selection.attribute_selection_value + ', '
                      : selection.attribute_selection_value
                )}
              </p>
            </div>
          </div>
          <Divider />
          <p style={{ marginBottom: '1rem', fontSize: '16px' }}>
            <strong>Attributes:</strong>
          </p>
          <List style={{ marginLeft: '2rem' }}>
            {productVariant?.attribute_selections?.map((selection) => {
              return (
                <List.Item style={{ display: 'flex' }}>
                  <strong style={{ marginRight: '.5rem', fontSize: '14px' }}>
                    {selection.attribute_name}:
                  </strong>
                  <p style={{ fontSize: '14px' }}>
                    {selection.attribute_selection_value}
                  </p>
                </List.Item>
              )
            })}
          </List>
          <Divider />
          <p style={{ marginBottom: '1rem', fontSize: '16px' }}>
            <strong>Price:</strong>
          </p>
          <Grid stackable>
            <Grid.Column width={8}>
              <Header style={{ marginLeft: '2rem' }} as={'h2'}>
                <b>
                  {props.currentPortal === 'supplier' ? (
                    <b>
                      {exchangeOrderBook?.highest_bid
                        ? formatTotalPrice(exchangeOrderBook?.highest_bid)
                        : exchangeOrderBook?.ask_orders?.length > 0
                        ? formatTotalPrice(
                            exchangeOrderBook?.ask_orders[0]?.price
                          )
                        : '----'}
                    </b>
                  ) : (
                    <b>
                      {exchangeOrderBook?.lowest_ask
                        ? formatTotalPrice(exchangeOrderBook?.lowest_ask)
                        : exchangeOrderBook?.bid_orders?.length > 0
                        ? formatTotalPrice(
                            exchangeOrderBook?.bid_orders[0]?.price
                          )
                        : '----'}
                    </b>
                  )}
                </b>
              </Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                onClick={() => {
                  if (publicExchange) {
                    toast.error('Login to Place Bid')
                  } else {
                    props.setOpenPricingModal(true)
                    props.setProductVariant(productVariant)
                  }
                }}
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #4faa80',
                  color: '#4faa80',
                  borderRadius: '5px',
                  width: '100%',
                  height: '40px',
                  marginBottom: '1rem',
                }}
              >
                Bid
              </Button>
              <Button
                color="green"
                style={{ borderRadius: '5px', width: '100%', height: '40px' }}
                onClick={() => {
                  if (publicExchange) {
                    toast.error('Login to Buy Now')
                  } else {
                    props.setOpenPricingModal(true)
                    props.setProductVariant(productVariant)
                    props.setInputQuantity(1)
                    props.setInputPrice(
                      props.currentPortal === 'supplier' &&
                        exchangeOrderBook?.bid_orders?.length > 0
                        ? Math.max(
                            ...exchangeOrderBook?.bid_orders?.map(
                              (item) => item.total_price
                            )
                          )
                        : exchangeOrderBook?.ask_orders?.length > 0
                        ? Math.min(
                            ...exchangeOrderBook?.ask_orders?.map(
                              (item) => item.total_price
                            )
                          )
                        : 0
                    )
                  }
                }}
              >
                Buy Now
              </Button>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '2rem', justifyContent: 'center' }}>
        <Grid.Column width={12}>
          <Header>
            Description:
            <Header.Subheader
              as={'p'}
              style={{ marginTop: '1rem', lineHeight: '1.5em' }}
            >
              {productVariant?.ai_description
                ? productVariant.ai_description
                : 'Check back soon to see product description.'}
            </Header.Subheader>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '2rem', justifyContent: 'center' }}>
        <Grid.Column width={12} style={{ overflowX: 'auto' }}>
          <Header>Products Data:</Header>
          {showPriceHistory &&
            productVariant &&
            similarProducts &&
            exchangeOrderBook && (
              <PriceHistoryTable
                hideFullHeader={true}
                hideHeader={true}
                productVariantId={productVariant.id}
                hideTabs={true}
              />
            )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '2rem', justifyContent: 'center' }}>
        <Grid.Column width={12}>
          <MXProductVariantGraph productVariant={productVariant} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '2rem', justifyContent: 'center' }}>
        <Grid.Column width={12}>
          <Header>Similar Products:</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ marginBottom: '2rem', justifyContent: 'center' }}>
        <Grid.Column width={12}>
          <ProductSlider
            // tenant={tenant}
            // products={similarProducts}
            currentPortal={props.currentPortal}
            tenantProducts={similarProducts}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default MXProductVariant
