import SalesOrders from '../../../components/SalesOrders/SalesOrders'

function ProfileSalesOrders(props) {
  const { business, openAddSalesOrderModel, setOpenAddSalesOrderModel } = props

  return (
    <div style={{ margin: '0px' }}>
      <SalesOrders
        hideHeader={true}
        hideBusiness={true}
        business={business}
        openAddSalesOrderModel={openAddSalesOrderModel}
        setOpenAddSalesOrderModel={setOpenAddSalesOrderModel}
      />
    </div>
  )
}

export default ProfileSalesOrders
