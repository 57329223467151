import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ProvideAuth } from './lib/auth/useAuth'
import { Provider } from 'react-redux'
import store from './store'
import 'semantic-ui-less/semantic.less'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as Sentry from '@sentry/react'
import env from './env'
import WebsocketProvider from './hooks/useWebsocket'

if (env.REACT_APP_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://05ebc1bda6de4930bbe86f4783454e52@o603075.ingest.sentry.io/6724244',
    integrations: [],
    environment: env.REACT_APP_ENVIRONMENT,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Provider store={store}>
    <ProvideAuth>
      <WebsocketProvider>
        <App />
      </WebsocketProvider>
    </ProvideAuth>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
