import _ from 'underscore'
import { setLoaderPercentage } from '../../features/globals/globalSlice'
import ReactGA from 'react-ga4'

var dateFormat = require('dateformat')

export function hasPermission(user, targetPermission) {
  if (!user || !user.groups) {
    return false
  }
  return user.groups.some((group) =>
    group.permissions.some((permission) => permission.name === targetPermission)
  )
}

export function hasPricing(user, pricing) {
  if (!user || !user.groups) {
    return false
  }

  return user.groups.some((group) => group.pricing === pricing.toUpperCase())
}

export function hasPortalAccess(user, portal) {
  if (!user || !user.groups) {
    return false
  }
  return user.groups.some((group) => {
    if (portal === 'admin' && group.admin_portal) {
      return true
    } else if (portal === 'buyer' && group.buyer_portal) {
      return true
    } else if (portal === 'supplier' && group.supplier_portal) {
      return true
    }
    return false
  })
}

export function inGroup(user, targetGroup) {
  if (!user || !user.groups) {
    return false
  }
  return user.groups.some((group) => {
    if (typeof targetGroup === 'string') {
      return group.name === targetGroup
    } else {
      return targetGroup.includes(group.name)
    }
  })
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function stopLoader(dispatch) {
  dispatch(setLoaderPercentage(0))
}
export async function startLoader(dispatch) {
  let progress = 0
  while (progress !== 0) {
    if (progress < 50) {
      progress += 2
    } else if (progress < 70) {
      progress += 1
    } else {
      progress += 0.5
    }
    dispatch(setLoaderPercentage(progress))
    await sleep(70)
  }
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export function formatOrderNumber(orderNumber) {
  if (!orderNumber) return ''
  orderNumber = orderNumber.toString()
  return orderNumber
  // return orderNumber.slice(0, 3).toUpperCase() + "-" + orderNumber.slice(4, 7).toUpperCase() + "-" + orderNumber.slice(8, orderNumber.length + 1).toUpperCase()
}

export function titleCase(value) {
  if (!value) {
    return ''
  }
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function camelToTitleCase(str) {
  return titleCase(str.replace('_', ' '))
}

export function formatDjangoDate(d) {
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

function index(obj, i) {
  return obj[i]
}

export function djangoResponseToInputValues(groups, data) {
  let clone = { ...data }
  for (let group in groups) {
    for (let field in groups[group].fields) {
      if (clone[field]) {
        let type = groups[group].fields[field]['type']

        if (type === 'date') {
          clone[field] = data[field] ? new Date(data[field]) : undefined
        } else if (type === 'multiple dropdown') {
          clone[field] = Array.isArray(data[field])
            ? _.map(data[field], (field) => {
                return field.id ? field.id : field
              })
            : data[field]
        } else if (type === 'search') {
          clone[field] =
            data[field] && data[field].id ? data[field].id : data[field]
          // clone[field] = groups[group].fields[field]['title_fields'].length > 1 ? _.reduce(groups[group].fields[field]['title_fields'], (memo, num) => {
          //   return `${memo.split('.').reduce(index, data[field])} ${num.split('.').reduce(index, data[field])}`;
          // }) : groups[group].fields[field]['title_fields'][0].split('.').reduce(index, data[field])
        }
      }
    }
  }

  return clone
}

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export function formatPercentage(number) {
  return (number * 100).toString() + '%'
}

export function formatTotalPrice(number) {
  if (!number && number !== 0) {
    return ''
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(number)
}

export function formatUnitPrice(number) {
  if (!number) {
    return ''
  }

  var parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  let total = parts.join('.').replace(/^/, '$')
  return total
}

export function formatQuantities(number) {
  if (!number) {
    return '0'
  }

  var parts = number.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function formatDate(javascriptDate) {
  return dateFormat(javascriptDate, 'mmmm dS, yyyy')
}

export function arraysEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getCookie(name) {
  const cookies = `; ${document.cookie}`
  const parts = cookies.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift()
  }
  return undefined
}

export const setCookie = (
  name,
  value,
  domain = window.location.hostname,
  path = '/',
  expires = 1,
  maxAge = 365,
  sameSite = 'Lax'
) => {
  const expiresDate = new Date()
  expiresDate.setTime(expiresDate.getTime() + expires * 24 * 60 * 60 * 1000)
  const maxAgeDate = new Date()
  maxAgeDate.setTime(maxAgeDate.getTime() + maxAge * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value};expires=${expiresDate.toUTCString()};path=${path};domain=${domain};samesite=${sameSite}; max-age=${maxAgeDate.toUTCString()}`
}

export const objEmpty = (obj) => {
  let empty = true
  if (obj && Object.keys(obj).length > 0) {
    empty = false
  }
  return empty
}

export const objFull = (obj) => {
  return !objEmpty(obj)
}

export const objEqual = async (a, b) => {
  if ((await !isObj(a)) || (await !isObj(b))) return false
  const a_keys = Object.keys(a)
  const b_keys = Object.keys(b)

  if (a_keys?.length !== b_keys?.length) {
    return false
  }
  for (const key of a_keys) {
    if (a[key] !== b[key]) return false
  }
  return true
}

export const isObj = (obj) => {
  return obj !== null && typeof obj === 'object' && !Array.isArray(obj)
}

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const trackConversion = (eventName, params) => {
  // eventName is a string matching Event Name in GA e.g. "sign_up"
  return ReactGA.ga('event', eventName, params)
}
export const trackEvent = (eventName, params) => {
  // eventName is a string matching Event Name in GA e.g. "sign_up"
  return ReactGA.gtag('event', eventName, params)
}

export const format_phone_number = (phone) => {
  if (phone && phone != '1') {
    return `+${phone.slice?.(0, 1)} (${phone.slice?.(1, 4)}) ${phone.slice?.(
      4,
      7
    )}-${phone.slice?.(7, 11)}`
  }
  return null
}

export function compareDictionaries(d1, d2) {
  // quick check for the same object
  if (d1 == d2) return true

  // check for null
  if (d1 == null || d2 == null) return false

  // go through the keys in d1 and check if they're in d2 - also keep a count
  var count = 0
  for (var key in d1) {
    // check if the key exists
    if (!(key in d2)) return false

    // check that the values are the same
    if (d1[key] != d2[key]) return false

    count++
  }

  // now just make sure d2 has the same number of keys
  var count2 = 0
  for (key in d2) count2++

  // return if they're the same size
  return count == count2
}
