import React, { useState, useEffect } from 'react'
import { Progress, Grid, GridRow, GridColumn } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import './PizzaTracker.css'
import _ from 'underscore'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { X } from 'phosphor-react'

function PizzaTracker(props) {
  const axios = useAxios()
  const [progressSteps, setProgressSteps] = useState(null)
  const [loading, setLoading] = useState(false)
  const windowDimensions = useWindowDimensions()
  const mobiledisplay = windowDimensions.width < 770

  useEffect(() => {
    if (!props.cargoUnitInstance) return
    async function getData() {
      setLoading(true)
      const res = await axios.get('/main/pizzatracker/', {
        params: {
          cargo_unit_instance_id: props.cargoUnitInstance?.id,
          tenant_aware: false,
        },
      })
      const pizzaTracker = res?.data?.results[0]
      if (props.buyer && pizzaTracker) {
        let ps = _.map(pizzaTracker?.buyer_choices, (step, idx) => {
          return {
            name: step[1],
            active: step[0] <= pizzaTracker.buyer_stage,
            date: pizzaTracker.pizza_tracker_status[idx]?.created_at,
          }
        })
        setProgressSteps(ps)
      } else if (props.supplier && pizzaTracker) {
        let ps = _.map(pizzaTracker?.supplier_choices, (step, idx) => {
          return {
            name: step[1],
            active: step[0] <= pizzaTracker.supplier_stage,
            date: pizzaTracker.pizza_tracker_status[idx]?.created_at,
          }
        })
        setProgressSteps(ps)
      } else if (props.admin && pizzaTracker) {
        let ps = _.map(pizzaTracker?.admin_choices, (step, idx) => {
          return {
            name: step[1],
            active: step[0] <= pizzaTracker.admin_stage,
            date: pizzaTracker.pizza_tracker_status[idx]?.created_at,
          }
        })
        setProgressSteps(ps)
      }
      setLoading(false)
    }
    getData()
  }, [props.cargoUnitInstance, props.order, props.refreshPizzaTracker])

  return (
    <div
      className={`pizza-tracker${props.fixed ? '-fixed' : ''}`}
      style={{ padding: mobiledisplay ? '0 18px' : '0 40px' }}
    >
      {loading && <MickeyPlaceholderLoading siz={'tiny'} />}
      {!loading && (
        <>
          <b style={{ color: env.REACT_APP_PRIMARY_COLOR }}>Tracking</b>
          <div>
            <Grid
              className="progress-steps"
              columns={'equal'}
              textAlign="="
              style={{ marginTop: '0', 'padding-top': '15px' }}
            >
              <GridRow style={{ padding: '0px' }}>
                {progressSteps?.map((step, idx) => {
                  if (step.active && step.name === 'Delivered') {
                    return (
                      <GridColumn
                        className={`progress-bar`}
                        textAlign={'center'}
                        style={{ padding: '0px' }}
                      >
                        <Progress
                          style={{
                            position: 'relative',
                            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          }}
                          className={'pizza-tracker-progress'}
                          percent={100}
                          color={mobiledisplay ? 'orange' : 'green'}
                        >
                          {!props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#444444'
                                  : '#0a3f51',
                                width: mobiledisplay ? '16px' : '40px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                bottom: '3px',
                                right: '-5px',
                              }}
                            ></div>
                          )}
                          {props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#e63322'
                                  : '#e63322',
                                width: mobiledisplay ? '16px' : '40px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                position: 'absolute',
                                bottom: '3px',
                                right: '-5px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <X
                                size={mobiledisplay ? 11 : 32}
                                weight="bold"
                                style={{
                                  position: 'relative',
                                  color: 'white',
                                  left: mobiledisplay ? '3px' : '4px',
                                }}
                              />
                            </div>
                          )}
                        </Progress>
                      </GridColumn>
                    )
                  } else if (
                    step.active &&
                    step.name === 'In Route' &&
                    progressSteps.length > idx - 1 &&
                    !progressSteps[idx + 1].active
                  ) {
                    return (
                      <GridColumn
                        className={`progress-bar`}
                        textAlign={'center'}
                        style={{ padding: '0px' }}
                      >
                        <Progress
                          style={{
                            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          }}
                          className={'pizza-tracker-progress'}
                          percent={53}
                          color={mobiledisplay ? 'orange' : 'green'}
                        >
                          {!props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#444444'
                                  : '#0a3f51',
                                width: mobiledisplay ? '16px' : '40px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'absolute',
                                bottom: '3px',
                                left: `calc(50% - ${
                                  mobiledisplay ? '9px' : '25px'
                                })`,
                              }}
                            ></div>
                          )}
                          {props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#e63322'
                                  : '#e63322',
                                width: mobiledisplay ? '16px' : '40px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                position: 'absolute',
                                bottom: '3px',
                                left: `calc(50% - ${
                                  mobiledisplay ? '9px' : '25px'
                                })`,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <X
                                size={mobiledisplay ? 11 : 32}
                                weight="bold"
                                style={{
                                  position: 'relative',
                                  color: 'white',
                                  left: mobiledisplay ? '3px' : '4px',
                                }}
                              />
                            </div>
                          )}
                        </Progress>
                      </GridColumn>
                    )
                  } else if (step.active) {
                    return (
                      <GridColumn
                        className={`progress-bar`}
                        textAlign={'center'}
                        style={{ padding: '0px' }}
                      >
                        <Progress
                          style={{
                            position: 'relative',
                            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          }}
                          className={'pizza-tracker-progress'}
                          percent={100}
                          color={mobiledisplay ? 'orange' : 'green'}
                        ></Progress>
                      </GridColumn>
                    )
                  } else if (
                    progressSteps[idx - 1].active &&
                    progressSteps[idx - 1].name !== 'In Route' &&
                    idx !== 5
                  ) {
                    return (
                      <GridColumn
                        className={`progress-bar`}
                        textAlign={'center'}
                        style={{ padding: '0px' }}
                      >
                        <div
                          style={{
                            height: mobiledisplay ? '16px' : '40px',
                            width: '100%',
                            borderRadius: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            background: '#E5E5E5',
                          }}
                        >
                          {!props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#444444'
                                  : '#0a3f51',
                                width: mobiledisplay ? '17px' : '41px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                position: 'absolute',
                                left: '0',
                              }}
                            ></div>
                          )}
                          {props?.orderCancel && (
                            <div
                              style={{
                                backgroundColor: mobiledisplay
                                  ? '#e63322'
                                  : '#e63322',
                                width: mobiledisplay ? '17px' : '41px',
                                height: mobiledisplay ? '16px' : '40px',
                                borderRadius: '50%',
                                position: 'absolute',
                                left: '0',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <X
                                size={mobiledisplay ? 11 : 32}
                                weight="bold"
                                style={{
                                  position: 'relative',
                                  color: 'white',
                                  left: mobiledisplay ? '3px' : '4px',
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </GridColumn>
                    )
                  } else {
                    return (
                      step?.name !== 'Cancelled' && (
                        <GridColumn
                          className={`progress-bar`}
                          textAlign={'center'}
                          style={{ padding: '0px 0px 0px 3px' }}
                        >
                          <div
                            style={{
                              width: 'max',
                              height: mobiledisplay ? '16px' : '40px',
                              padding: '0px',
                              'border-radius': '25px',
                              background: '#E5E5E5',
                            }}
                          />
                        </GridColumn>
                      )
                    )
                  }
                })}
              </GridRow>
              <GridRow style={{ padding: '0px' }}>
                {progressSteps?.map((step, idx) => {
                  return (
                    step?.name !== 'Cancelled' && (
                      <Grid.Column
                        className={`progress-step`}
                        textAlign={
                          idx === 0
                            ? 'center'
                            : idx === progressSteps.length - 1
                            ? 'center'
                            : 'center'
                        }
                      >
                        <b
                          className={`${
                            idx === 0
                              ? 'progress-step-left'
                              : idx === progressSteps.length - 1
                              ? 'progress-step-right'
                              : ''
                          }`}
                          style={{
                            'font-size': '12px',
                            color:
                              props?.orderCancel &&
                              ((progressSteps[idx - 1]?.active &&
                                !step.active &&
                                idx < 4) ||
                                (step.name === 'In Route' &&
                                  step.active &&
                                  !progressSteps[idx + 1]?.active) ||
                                (step.name === 'Delivered' && step.active)) &&
                              'red',
                          }}
                        >
                          {props?.orderCancel &&
                          ((progressSteps[idx - 1]?.active &&
                            !step.active &&
                            idx < 4) ||
                            (step.name === 'In Route' &&
                              step.active &&
                              !progressSteps[idx + 1]?.active) ||
                            (step.name === 'Delivered' && step.active))
                            ? 'Cancelled'
                            : !props?.orderCancel || step.active
                            ? step.name
                            : ' '}
                        </b>
                      </Grid.Column>
                    )
                  )
                })}
              </GridRow>
              <GridRow style={{ padding: '0px 0px 30px 0px' }}>
                {progressSteps?.map((step, idx) => {
                  if (step.name === 'Delivered' && !props?.orderCancel) {
                    if (props.cargoUnitInstance.delivered) {
                      return (
                        <Grid.Column
                          className={`progress-step`}
                          textAlign={
                            idx === 0
                              ? 'center'
                              : idx === progressSteps.length - 1
                              ? 'center'
                              : 'center'
                          }
                        >
                          <p style={{ color: '#7B7B7B' }}>
                            {progressSteps[idx].date?.slice(0, 12)}
                          </p>
                        </Grid.Column>
                      )
                    } else {
                      return (
                        <Grid.Column
                          className={`progress-step`}
                          textAlign={
                            idx === 0
                              ? 'center'
                              : idx === progressSteps.length - 1
                              ? 'center'
                              : 'center'
                          }
                        >
                          <p style={{ color: '#7B7B7B' }}>
                            Estimated date:{' '}
                            {props.cargoUnitInstance.estimated_delivery_date}
                          </p>
                        </Grid.Column>
                      )
                    }
                  }
                  return (
                    step?.name !== 'Cancelled' && (
                      <Grid.Column
                        className={`progress-step`}
                        textAlign={
                          idx === 0
                            ? 'center'
                            : idx === progressSteps.length - 1
                            ? 'center'
                            : 'center'
                        }
                      >
                        <p
                          style={{
                            color: '#7B7B7B',
                            'font-size': props?.orderCancel ? '12px' : '14px',
                          }}
                        >
                          {props?.orderCancel &&
                          ((progressSteps[idx - 1]?.active &&
                            !step.active &&
                            idx < 4) ||
                            (step.name === 'In Route' &&
                              step.active &&
                              !progressSteps[idx + 1]?.active) ||
                            (step.name === 'Delivered' && step.active))
                            ? props?.orderItem.cancel_date
                            : !props?.orderCancel || step.active
                            ? progressSteps[idx].date?.slice(0, 12)
                            : ' '}
                        </p>
                      </Grid.Column>
                    )
                  )
                })}
              </GridRow>
            </Grid>
          </div>
        </>
      )}
    </div>
  )
}

export default PizzaTracker

/*
import React, { useState, useEffect } from 'react';
import {
    Progress, Step, Segment, Header, Icon, Modal, Tab, Grid, Label
} from 'semantic-ui-react'
import useAxios from "../../../lib/auth/useAxios";
import { useSelector } from "react-redux"
import './PizzaTracker.css'
import _ from 'underscore';
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder';
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading';

function PizzaTracker(props) {
    const axios = useAxios();
    const [progressSteps, setProgressSteps] = useState(null)
    const [progressPercent, setProgressPercent] = useState(9.5)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!props.orderItem && !props.order) return;
        async function getData() {
            setLoading(true)
            const res = await axios.get('/main/pizzatracker/', {
                params: {
                    order_item_id: props.orderItem?.id,
                    order_id: props.order?.id,
                    tenant_aware: false
                }
            })
            const pizzaTracker = res.data.results[0]
            if (props.buyer && pizzaTracker) {
                let ps = _.map(pizzaTracker?.buyer_choices, (step, idx) => {
                    return {
                        'name': step[1],
                        'active': step[0] <= pizzaTracker.buyer_stage
                    }
                })
                setProgressPercent(((pizzaTracker?.buyer_stage - 1) * (100 / pizzaTracker.buyer_choices.length)) + 10)
                setProgressSteps(ps)
            } else if (props.supplier && pizzaTracker) {
                let ps = _.map(pizzaTracker?.supplier_choices, (step, idx) => {
                    return {
                        'name': step[1],
                        'active': step[0] <= pizzaTracker.supplier_stage
                    }
                })
                setProgressPercent(((pizzaTracker?.supplier_stage - 1) * (100 / pizzaTracker?.supplier_choices.length)) + 10)
                setProgressSteps(ps)
            } else if (props.admin && pizzaTracker) {
                let ps = _.map(pizzaTracker?.admin_choices, (step, idx) => {
                    return {
                        'name': step[1],
                        'active': step[0] <= pizzaTracker.admin_stage
                    }
                })
                setProgressPercent(((pizzaTracker.admin_stage - 1) * (100 / pizzaTracker?.admin_choices.length)) + 10)
                setProgressSteps(ps)
            }
            setLoading(false)
        }
        getData()

    }, [props.orderItem, props.order])

    return (
        <div className={`pizza-tracker${props.fixed ? '-fixed' : ''}`}>
            {loading &&
                <MickeyPlaceholderLoading />
            }
            {!loading &&
                <>
                    <Progress
                        style={{ 'margin': '0' }}
                        className={'pizza-tracker-progress'}
                        color={'green'}
                        percent={progressPercent}
                        indicating
                    />
                    <Grid className="progress-steps" columns={'equal'} textAlign='=' style={{ 'marginTop': '0' }}>
                        {progressSteps?.map((step, idx) => {
                            return <Grid.Column className={`progress-step`} textAlign={idx === 0 ? 'center' : (idx === progressSteps.length - 1 ? 'center' : 'center')}>
                                <Label className={`${idx === 0 ? 'progress-step-left' : (idx === progressSteps.length - 1 ? 'progress-step-right' : '')}`} pointing={'above'} basic={!step.active} color='green'>
                                    {step.name}
                                </Label>
                            </Grid.Column>
                        })}
                    </Grid>
                </>
            }
        </div>
    );
}

export default PizzaTracker;
*/
