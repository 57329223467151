import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  current_location: null,
  business: {},
  supplier: {},
  buyer: {},
  locations: [],
  isLoggedIn: false,
}
export const userSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState
    },
    addUser: (state, data) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.user = data.payload
    },
    removeUser: (state, formId) => {
      state.user = {}
    },
    addBusiness: (state, data) => {
      state.business = data.payload
    },
    removeBusiness: (state, formId) => {
      state.business = {}
    },
    addBuyer: (state, data) => {
      state.buyer = data.payload
    },
    removeBuyer: (state, formId) => {
      state.buyer = {}
    },
    addCurrentLocation: (state, data) => {
      state.current_location = data.location
    },
    removeCurrentLocation: (state, formId) => {
      state.current_location = null
    },
    addSupplier: (state, data) => {
      state.supplier = data.payload
    },
    removeSupplier: (state, formId) => {
      state.supplier = {}
    },
    addLocations: (state, data) => {
      state.locations = data.payload
    },
    loggedIn: (state) => {
      state.isLoggedIn = true
    },
    loggedOut: (state) => {
      state.isLoggedIn = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  loggedIn,
  loggedOut,
  reset,
  addUser,
  removeUser,
  addBusiness,
  removeBusiness,
  addBuyer,
  addLocations,
  removeBuyer,
  addSupplier,
  removeSupplier,
  addCurrentLocation,
  removeCurrentLocation,
} = userSlice.actions

export default userSlice.reducer
