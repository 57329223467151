import React, { useEffect, useState, useRef } from 'react'
import { Segment } from 'semantic-ui-react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import env from '../../../env'
mapboxgl.accessToken = env.REACT_APP_MAPBOX_TOKEN

function PaymentMap(props) {
  // State
  const [lng, setLng] = useState(null)
  const [lat, setLat] = useState(null)
  // Hooks
  const mapContainer = useRef(null)
  const map = useRef(null)
  // Vars
  const loading = false
  const zoom = 15

  useEffect(() => {
    if (!lat || !lng) return
    if (map.current) return // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/westonmickey/cl2gbpoe2000m16qr147gca43',
      center: [lng, lat],
      zoom: zoom,
    })
    new mapboxgl.Marker({ color: env.REACT_APP_PRIMARY_COLOR })
      .setLngLat([lng, lat])
      .addTo(map.current)
  }, [lng, lat])

  useEffect(() => {
    if (props.deliveryLocation && props.deliveryLocation?.id) {
      setLat(props.deliveryLocation?.latitude)
      setLng(props.deliveryLocation?.longitude)
    }
  }, [props.deliveryLocation])

  return (
    <Segment loading={loading} style={{ minHeight: '200px' }}>
      {!loading && (!lat || !lng) && (
        <MickeyPlaceholder icon={'file'} message={'No map available'} />
      )}
      {lat && lng && (
        <div
          ref={mapContainer}
          className="map-container"
          style={{ height: '400px' }}
        />
      )}
    </Segment>
  )
}

export default PaymentMap
