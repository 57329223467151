export const BuyerRequestForm = {
  model: '',
  format: [
    {
      widths: 'equal',
      fields: {
        quantity: {
          type: 'number',
          min: '0',
          step: '0.01',
        },
        unit_of_measure: {
          label: 'Unit of Measure',
          type: 'dropdown search',
          url: '/main/unit-of-measures/',
          title_fields: ['plural_name'],
          description_fields: ['short_name'],
        },
        date: {
          type: 'date',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        user_note: {
          type: 'text area',
          label: 'Additional notes',
        },
      },
    },
  ],
}
