import _ from 'underscore'
import React, { useState, useEffect } from 'react'
import { Dropdown, Form } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import './MickeyDropdown.css'
import { objFull } from '../../lib/utils/utils'
import MickeyInfiniteScroll from '../MickeyInfiniteScroll/MickeyInfiniteScroll'

function MickeyDropdown(props) {
  const axios = useAxios()
  const [value, setValue] = useState(props.defaultValue)
  const [loading, setLoading] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)
  const [results, setResults] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [next, setNext] = useState(null)
  const [lastSelectedOption, setLastSelectedOption] = useState(null)
  let delayDebounceFn

  const getUrlParams = () => {
    const params = props.params ? { ...props.params } : {}
    if (props.tenantAware !== null || props.tenantAware !== undefined) {
      params.tenant_aware = props.tenantAware
    }
    if (props.filterField) {
      params[props.filterField] = searchTerm
    }
    return params
  }

  const generateOption = (d, last = false) => {
    function index(obj, i) {
      return obj ? obj[i] : null
    }

    let title = ''
    props.titleFields.forEach((field) => {
      let val = field.split('.').reduce(index, d)
      if (val) {
        if (title.length > 0) {
          title += ' '
        }
        title += val
      }
    })
    let description
    if (props.descriptionFields.length > 0) {
      description = ''
      props.descriptionFields.forEach((field) => {
        let val = field.split('.').reduce(index, d)
        if (val) {
          if (description.length > 0) {
            description += props.descriptionFieldSeparator
              ? props.descriptionFieldSeparator
              : ' - '
          }
          description += val
        }
      })
    } else {
      description = ''
    }
    let extraFields = {}
    if (props.extraFields?.length > 0) {
      props.extraFields.forEach((field) => {
        if (field in d) {
          extraFields[field] = d[field]
        }
      })
    }
    if (
      !props.hideDescriptionFieldParenthesis &&
      props.field?.showDescriptionFieldParenthesis &&
      description
    ) {
      description = '(' + description + ')'
    }

    return {
      text: `${title} ` + (description ? `(${description})` : ''),
      value: d['id'],
      key: d['id'],
      content: (
        <div id={last ? 'last-element' : undefined}>
          <b>{title}</b>
          <br />
          {description}
        </div>
      ),
    }
  }

  async function getSelectedResult() {
    let data = null
    if (!value) return data
    if (
      !results.find((result) => result?.value === value) &&
      value !== lastSelectedOption?.value
    ) {
      let url
      if (!props.discludeValueUrl) {
        url =
          props.url +
          (props.url[props.url?.length - 1] === '/' ? `${value}/` : `${value}/`)
      } else {
        url = props.url
      }
      let res = await axios.get(url, {
        params: getUrlParams(),
      })

      if (res.data) {
        data = await generateOption(res.data)
        setLastSelectedOption(data)
      }
    } else if (value === lastSelectedOption?.value) {
      data = lastSelectedOption
    }

    return data
  }

  async function getResults(res_data) {
    let results = _.map(res_data, (d, idx) => {
      return generateOption(d, idx + 1 === res_data?.length)
    })

    if (!results.find((result) => result?.value === value)) {
      let value_obj = await getSelectedResult()
      if (value_obj) {
        results.unshift(value_obj)
      }
    }

    if (props.allowEmpty) {
      results.unshift({
        text: 'Remove',
        value: null,
        key: null,
      })
    }
    return results
  }

  useEffect(() => {
    const controller = new AbortController()
    async function fetchData() {
      let response
      setLoading(true)
      const params = getUrlParams()
      if (objFull(params)) {
        response = await axios.get(props.url, {
          params: params,
        })
      } else {
        response = await axios.get(props.url)
      }
      let res_data =
        response?.data &&
        typeof response?.data === 'object' &&
        'results' in response?.data
          ? response?.data?.results
          : response?.data
      setNext(response?.data?.links?.next)
      let result = await getResults(res_data)
      if (props.allowAddOptions && props.allowAddOptions?.length) {
        setResults([...result, ...props.allowAddOptions])
      } else {
        setResults(result)
      }
      if (props.defaultValue && initialLoad) {
        results?.forEach((v) => {
          if (
            v.id === props.defaultValue ||
            (props.defaultValue && v?.id === props.defaultValue?.id)
          ) {
          }
        })
      }
      setLoading(false)
      setInitialLoad(false)
      if (props.setReloadOptions) props?.setReloadOptions(false)
    }
    if (
      !props.hasRequiredParams &&
      (!props.setReloadOptions || props.reloadOptions)
    ) {
      if (!props.results) {
        fetchData()
      } else {
        setLoading(false)
      }
    } else if (props.disabled || props.hasRequiredParams) {
      setLoading(false)
    }
    return () => {
      controller.abort()
    }
  }, [
    props?.results,
    JSON.stringify(props.params),
    props.url,
    props.defaultValue,
    props.disabled,
    props.hasRequiredParams,
    searchTerm,
    props.reloadOptions,
  ])

  useEffect(() => {
    if (props?.results) {
      setResults(props?.results)
    }
  }, [props?.results])

  const dropdownOnChange = (e, d) => {
    let option = d?.options.find((obj) => d?.value === obj?.value)
    if (option?.content) {
      let content = { ...option?.content }
      content.props = { children: content?.props?.children }
      option = {
        text: option.text,
        value: option.key,
        key: option.key,
        content: content,
      }
    }
    setLastSelectedOption(option?.value ? option : null)
    setValue(d.value)
    if (props.setValue) {
      props.setValue(d)
    }
    if (props.onChange) {
      props.onChange(e, d)
    }
    setSearchTerm(null)
  }

  const dropdownOnSearchChange = (e, d) => {
    clearTimeout(delayDebounceFn)
    delayDebounceFn = setTimeout(() => {
      setSearchTerm(e.target.value)
    }, 1000)
  }

  return (
    <>
      <MickeyInfiniteScroll
        next={next}
        setNext={setNext}
        loading={loading}
        setLoading={setLoading}
        results={results}
        setResults={setResults}
        getResults={getResults}
      />
      {props.isForm ? (
        <Form.Dropdown
          id="mickey-dropdown"
          loading={loading}
          placeholder={props.placeholder ? props.placeholder : 'Select'}
          disabled={(props.disabled || props.hasRequiredParams) && !loading}
          fluid
          label={props.label}
          multiple={props.multiple}
          name={props.name}
          style={props.style}
          search
          icon={props.icon}
          openOnFocus={props.openOnFocus}
          selection
          value={value}
          options={results}
          onSearchChange={(e, d) => {
            dropdownOnSearchChange(e, d)
          }}
          onChange={(e, d) => {
            dropdownOnChange(e, d)
          }}
        />
      ) : (
        <Dropdown
          id="mickey-dropdown"
          loading={loading}
          placeholder={props.placeholder ? props.placeholder : 'Select'}
          disabled={(props.disabled || props.hasRequiredParams) && !loading}
          fluid
          multiple={props.multiple}
          name={props.name}
          style={props.style}
          search
          icon={props.icon}
          openOnFocus={props.openOnFocus}
          selection
          value={value}
          options={results}
          onSearchChange={(e, d) => {
            dropdownOnSearchChange(e, d)
          }}
          onChange={(e, d) => {
            dropdownOnChange(e, d)
          }}
        />
      )}
    </>
  )
}

export default MickeyDropdown
