export const AdminOrderSupplierForm = {
  model: 'Order',
  format: [
    {
      widths: 'equal',
      fields: {
        buyer: {
          required: true,
          type: 'search',
          url: '/main/buyers/',
          label: 'Buyer',
          title_fields: ['business.name'],
          filter_field: 'business__name__icontains',
          params: {
            tenant_aware: false,
            business__archived: false,
          },
          description_fields: [
            'business.contact_first_name',
            'business.contact_last_name',
          ],
        },
        delivery_address: {
          required: true,
          type: 'dropdown search',
          url: '/main/locations/',
          // This says: require all 'related_params' in order to display values
          require_params: true,
          // this says: pass in the current buyer field (above field) as "buyer_id" into the dropdown search
          related_params: {
            buyer: 'buyer_id',
          },
          params: {
            tenant_aware: false,
          },
          title_fields: ['name'],
          description_fields: ['address_1', 'city', 'state', 'zip_code'],
          filter_field: 'name__icontains',
          allow_add: true,
          // this says: pass in the current supplier field (above field) as "supplier_id" into the add form values
          allow_add_related_params: {
            buyer: 'buyer_id',
          },
          model: 'Location',
          model_form: 'LocationForm',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        order_number: {
          label: 'Deal Number',
          required: false,
          type: 'text',
        },
        order_date: {
          label: 'Deal Date',
          type: 'date',
          required: true,
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        po_number: {
          required: false,
          type: 'text',
        },
        so_number: {
          required: false,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        scrapgo_status: {
          required: true,
          type: 'dropdown',
        },
        availability: {
          required: true,
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        salespersons: {
          label: 'Salespeople',
          type: 'multiple dropdown model',
          url: '/main/admin-users/?light_serializer=true',
          option_text_fields: ['full_name', 'email'],
        },
        mx_order: {
          required: false,
          type: 'checkbox',
          label: 'Exchange Order',
          params: {
            tenant_aware: false,
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        buyer_marketplace_fee: {
          required: false,
          label: 'Buyer Marketplace Fee',
          type: 'number',
          step: 0.01,
        },
        supplier_marketplace_fee: {
          required: false,
          label: 'Supplier Marketplace Fee',
          type: 'number',
          step: 0.01,
        },
      },
    },
  ],
}
