import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../../components/Forms/MickeyForm'
import { format_phone_number } from '../../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../../lib/utils/utils'

const AdminSalesNotesRow = (props) => {
  const user = useSelector((state) => state.user.user)
  const [openEditSalesLogModal, setOpenEditSalesLogModal] = useState(false)

  return (
    <>
      <Table.Row
        onClick={() => {
          if (hasPermission(user, 'Update Sales Log')) {
            setOpenEditSalesLogModal(true)
          }
        }}
      >
        <Table.Cell>{props.salesLog?.created_at}</Table.Cell>
        <Table.Cell>
          {props.salesLog?.salesperson?.first_name}{' '}
          {props.salesLog?.salesperson?.last_name}
        </Table.Cell>
        <Table.Cell>
          {props.salesLog?.user?.first_name} {props.salesLog?.user?.last_name} (
          {props.salesLog?.business?.name})
        </Table.Cell>
        <Table.Cell onClick={() => setOpenEditSalesLogModal(true)}>
          {/^\d+$/.test(props.salesLog?.communication_type)
            ? format_phone_number(props.salesLog?.communication_type)
            : props.salesLog?.communication_type}
        </Table.Cell>
        <Table.Cell>{props.salesLog?.description}</Table.Cell>
        <Modal
          onClose={() => {
            setOpenEditSalesLogModal(false)
            props.setReload(true)
          }}
          onOpen={() => setOpenEditSalesLogModal(true)}
          open={openEditSalesLogModal}
        >
          <Modal.Header>Edit Sales Log</Modal.Header>
          <p
            style={{
              padding: '1.25rem 1.5rem',
              margin: '0',
            }}
          >
            Changes will update automatically. When you're finished click the
            Close button below.
          </p>
          <Modal.Content>
            <MickeyForm
              formName={'SalesLogForm'}
              model={'SalesLog'}
              url={'/main/admin-sales-logs/'}
              instanceId={props?.salesLog?.id}
              business={props?.salesLog?.business}
              tenantAware={false}
              onUpdate={() => {}}
            />
          </Modal.Content>
          <Modal.Actions floated={'left'}>
            <Button
              onClick={() => {
                setOpenEditSalesLogModal(false)
                props.setReload(true)
              }}
              content="Close"
            />
          </Modal.Actions>
        </Modal>
      </Table.Row>
    </>
  )
}

export default AdminSalesNotesRow
