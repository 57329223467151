import React, { useEffect, useState } from 'react'
import { Header } from 'semantic-ui-react'
import env from '../../../env'
import './AdminFulfillment.css'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import AdminOrderFulfillmentSelectedRow from '../AdminOrder/AdminOrderFulfillmentSelectedRow'
import AdminFulfillmentTruckingRow from './AdminFulfillmentTruckingRow'

function AdminFulfillmentTrucking(props) {
  const [reload, setReload] = useState(false)
  const [selectedCargoUnitInstance, setSelectedCargoUnitInstance] =
    useState(null)
  const [selectedTab, setSelectedTab] = useState('Booked')
  const [cargoUnitInstances, setCargoUnitInstances] = useState(null)

  let headerRow = [
    'Load ID',
    'Trucking Company',
    'Order',
    'Supplier',
    'Buyer',
    'Pickup Date',
    'Status',
  ]
  const headerSearch = [
    {
      header: 'Load ID',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'CargoUnitInstance',
        query_dict: { shipper_load_id__isnull: false },
        filter_field_value_list: ['shipper_load_id'],
      },
      options_titleFields: ['shipper_load_id'],
      options_valueFields: ['shipper_load_id'],
      search_param: 'shipper_load_id',
    },
    {
      header: 'Trucking Company',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'CargoUnitInstance',
        query_dict: { trucking_company__isnull: false },
        filter_field_value_list: ['trucking_company__name'],
      },
      options_titleFields: ['trucking_company__name'],
      options_valueFields: ['trucking_company__name'],
      search_param: 'trucking_company',
    },
    {
      header: 'Order',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'CargoUnitInstance',
        query_dict: { order__order_number__isnull: false },
        filter_field_value_list: ['order__order_number'],
      },
      options_titleFields: ['order__order_number'],
      options_valueFields: ['order__order_number'],
      search_param: 'order_number',
    },
    {
      header: 'Supplier',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Supplier',
        query_dict: {},
        filter_field_value_list: ['business__name'],
      },
      options_titleFields: ['business__name'],
      options_valueFields: ['business__name'],
      search_param: 'supplier',
    },
    {
      header: 'Pickup Date',
      type: 'date',
      search_param: 'estimated_ship_date',
    },
    {
      header: 'Buyer',
      type: 'dropdown',
      options_url: 'get-fields-value/',
      options_params: {
        tenant_aware: false,
        model: 'Buyer',
        query_dict: {},
        filter_field_value_list: ['business__name'],
      },
      options_titleFields: ['business__name'],
      options_valueFields: ['business__name'],
      search_param: 'buyer',
    },
  ]
  const headerSort = [
    {
      header: 'Load ID',
      sort_by: 'shipper_load_id',
    },
    {
      header: 'Trucking Company',
      sort_by: 'trucking_company__name',
    },
    {
      header: 'Order',
      hide_sort_by: true,
    },
    {
      header: 'Supplier',
      hide_sort_by: true,
    },
    {
      header: 'Pickup Date',
      sort_by: 'estimated_ship_date',
    },
    {
      header: 'Buyer',
      hide_sort_by: true,
    },
    {
      header: 'Status',
    },
  ]

  return (
    <div style={{ padding: 0, marginTop: '0px' }}>
      {!props.hideHeader && (
        <PageHeader icon={'truck'} header={'Fulfillment'} />
      )}
      <div
        className="exchange-table-heading"
        style={{ marginLeft: '10px', marginBottom: '15px' }}
      >
        <div className="commodities-wrapper">
          <Header
            as={'h2'}
            className={
              selectedTab === 'Booked'
                ? 'commodity-header selected'
                : 'commodity-header'
            }
            color={selectedTab === 'Booked' ? 'primary' : 'black'}
            onClick={() => setSelectedTab('Booked')}
            style={{
              margin: '0 1rem 0rem 0',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
          >
            Booked
          </Header>
          <Header
            as={'h2'}
            className={
              selectedTab === 'Unbooked'
                ? 'commodity-header selected'
                : 'commodity-header'
            }
            color={selectedTab === 'Unbooked' ? 'primary' : 'black'}
            onClick={() => setSelectedTab('Unbooked')}
            style={{
              margin: '0 1rem 0rem 0',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
          >
            Unbooked
          </Header>
        </div>
      </div>
      <MickeyTable
        headerRow={headerRow}
        headerSearch={headerSearch}
        headerSort={headerSort}
        url={'/main/admin-cargo-unit-instances/'}
        urlParams={{
          trucking_booked: selectedTab === 'Booked' ? 'true' : 'false',
          salesperson_id: props?.salespersonId,
          light_serializer: true,
          exclude_container_items: true,
        }}
        reload={reload}
        setReload={setReload}
        setParentRow={setCargoUnitInstances}
        basicSegment
        renderBodyRow={(data, index) => {
          return (
            <>
              <AdminFulfillmentTruckingRow
                selectedCargoUnitInstance={selectedCargoUnitInstance}
                setSelectedCargoUnitInstance={setSelectedCargoUnitInstance}
                cargoUnitInstance={cargoUnitInstances[index]}
                setCargoUnitInstance={(cui) => {
                  let cuisCopy = [...cargoUnitInstances]
                  cuisCopy[index] = cui
                  setCargoUnitInstances(cuisCopy)
                }}
              />
              {selectedCargoUnitInstance?.id === data?.id && (
                <AdminOrderFulfillmentSelectedRow
                  selectedCargoUnitInstance={selectedCargoUnitInstance}
                  setSelectedCargoUnitInstance={setSelectedCargoUnitInstance}
                  setCargoUnitInstance={(cui) => {
                    let cuisCopy = [...cargoUnitInstances]
                    cuisCopy[index] = cui
                    setCargoUnitInstances(cuisCopy)
                  }}
                  cargoUnitInstance={cargoUnitInstances[index]}
                />
              )}
            </>
          )
        }}
        emptyIcon={'truck'}
        emptyMessage={'Fulfillment will populate here'}
      />
    </div>
  )
}

export default AdminFulfillmentTrucking
