import { Button } from 'semantic-ui-react'
import './MickeyButton.css'

export default function MickeyButton(props) {
  return (
    <div
      style={props.style}
      className={`${props.color} ${props.basic ? 'basic' : ''} mickey-button`}
      phosphor-icon={
        props.icon && typeof props.icon !== 'string' ? 'true' : 'false'
      }
    >
      <Button
        {...props}
        size={props.size}
        onClick={() => {
          if (!props.loading && props.onClick) {
            props.onClick()
          }
        }}
        style={{
          height: props.innerStyle?.height
            ? props.innerStyle?.height
            : props.style.height
            ? props.style.height
            : '100%',
          width: props.innerStyle?.width
            ? props.innerStyle?.width
            : props.style.width
            ? props.style.width
            : '',
          ...props.innerStyle,
          float: props.floated ? props.floated : '',
        }}
        className={props.className ? props.className + ' front' : 'front'}
      />
    </div>
  )
}
