export const ShopSettingsLogoForm = {
  model: 'Supplier',
  format: [
    {
      widths: 'equal',
      fields: {
        logo: {
          type: 'file',
          modelOverride: 'Business',
          label: 'Business Logo',
        },
      },
    },
  ],
}
