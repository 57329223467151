import React, { useEffect, useState } from 'react'
import useAxios from '../../lib/auth/useAxios'

function AdminGod({ navigation }) {
  const axios = useAxios()
  useEffect(() => {
    async function test() {
      let res = await axios.get('/main/test/')
    }
    test()
  }, [])

  return <></>
}

export default AdminGod
