import { Table } from 'semantic-ui-react'
import { formatTotalPrice } from '../../../../lib/utils/utils'

const MXPaymentsRow = (props) => {
  const { row, headerRow } = props

  return (
    <>
      <Table.Row selectable onClick={props.onClick}>
        {headerRow?.map((col) => {
          return (
            <Table.Cell
              positive={
                (col === 'Payment Type' || col === 'Type') &&
                row?.type === 'Receipt'
              }
              negative={
                (col === 'Payment Type' || col === 'Type') &&
                row?.type === 'Disbursement'
              }
            >
              {col === 'Order Number'
                ? row?.order?.order_number
                : col === 'Business Paying'
                ? row?.buyer
                  ? row?.buyer?.business?.name
                  : row?.other_business?.name
                : col === 'Business Receiving Payment'
                ? row?.supplier
                  ? row?.supplier?.business?.name
                  : row?.other_business?.name
                : col === 'Balance ID'
                ? row?.balance_id
                : col === 'Payment Method'
                ? row?.balance_payment_method
                : col === 'Payment Date/Time'
                ? row?.balance_payment_datetime
                : col === 'Payment Status'
                ? row?.balance_status
                : col === 'Payment Type'
                ? row?.type
                : col === 'Amount'
                ? formatTotalPrice(row?.amount_transferred)
                : null}
            </Table.Cell>
          )
        })}
      </Table.Row>
    </>
  )
}

export default MXPaymentsRow
