import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import GroupTableRow from './GroupTableRow'
import GroupModal from './GroupModal'
import { useSelector } from 'react-redux'
import { inGroup } from '../../lib/utils/utils'

const Permissions = (props) => {
  const user = useSelector((state) => state.user.user)
  const [groupDetail, setGroupDetail] = useState()
  const [showGroupDetail, setShowGroupDetail] = useState(false)
  const [reloadGroups, setReloadGroups] = useState(false)

  const toggleGroupModal = (group = null) => {
    if (group) {
      setGroupDetail(group)
      setShowGroupDetail(true)
    } else {
      setShowGroupDetail(false)
      setGroupDetail()
    }
  }

  useEffect(() => {
    if (reloadGroups) {
      setReloadGroups(false)
    }
  }, [reloadGroups])

  return (
    <>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={16}>
          <MickeyTable
            headerRow={['Group', 'Users', 'Action']}
            basicSegment
            unselectable
            reload={reloadGroups}
            url={'/main/group/'}
            renderBodyRow={(data, index) => {
              if (data.name !== 'Development' || inGroup(user, 'Development')) {
                return (
                  <GroupTableRow
                    group={data}
                    setReload={setReloadGroups}
                    toggleGroupModal={toggleGroupModal}
                  />
                )
              }
            }}
            emptyIcon={'id badge'}
            emptyMessage={'No Groups'}
          />
        </Grid.Column>
      </Grid.Row>
      <GroupModal
        group={groupDetail}
        showModal={showGroupDetail}
        toggleModal={toggleGroupModal}
        setReload={setReloadGroups}
      />
    </>
  )
}

export default Permissions
