import { Grid } from 'semantic-ui-react'
import PageHeader from '../PageHeader/PageHeader'
import env from '../../env'
import { useEffect, useState } from 'react'
import MickeyTable from '../MickeyTable/MickeyTable'
import SalesOrdersRow from './SalesOrdersRow'
import AddSalesOrder from './AddSalesOrder'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { Plus } from 'phosphor-react'

function SalesOrders(props) {
  const {
    hideHeader,
    hideBusiness,
    business,
    openAddSalesOrderModel,
    setOpenAddSalesOrderModel,
  } = props
  const [reload, setReload] = useState(false)
  const [update, setUpdate] = useState(false)
  const [salesOrder, setSalesOrder] = useState()
  const user = useSelector((state) => state.user.user)

  const headerRow = [
    'Sales Order Number',
    'Products',
    'Quantity',
    'UOM',
    'Price',
    'Total Amount',
  ]
  const urlParams = business ? { business_id: business?.id } : {}
  const headerSearch = [
    {
      header: 'Sales Order Number',
      type: 'search',
      input_type: 'number',
      search_param: 'sales_order_number',
    },
    {
      header: 'Total Amount',
      type: 'number_range',
      search_param_start: 'total_amount_start',
      search_param_end: 'total_amount_end',
    },
  ]
  const headerSort = [
    {
      header: 'Sales Order Number',
      sort_by: 'sales_order_number',
    },
    {
      header: 'Total Amount',
      sort_by: 'total_amount',
    },
  ]
  if (!hideBusiness) {
    headerRow.splice(5, 0, 'Buyer')
    headerSort.splice(5, 0, {
      header: 'Buyer',
      sort_by: 'buyer__business__name',
    })
    headerSearch.splice(5, 0, {
      header: 'Buyer',
      type: 'dropdown',
      options_url: 'businesses/',
      options_params: {
        extra_light_serializer: 'true',
        all_businesses: 'true',
      },
      options_result: 'name',
      search_param: 'business_id',
    })
  }

  return (
    <div className={'main-container'}>
      <Grid stackable>
        {!hideHeader && (
          <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
            <Grid.Column
              width={16}
              className={'no-bottom-margin no-bottom-padding'}
            >
              <PageHeader
                header={'Sales Orders'}
                subheader={
                  'Add, archive, or edit sales orders at your company.'
                }
                className={
                  'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
                }
                dividerClassName={'no-bottom-margin no-bottom-padding'}
                buttons={[
                  {
                    color: 'primary',
                    content: 'Add Sales Order',
                    hidden: !hasPermission(user, 'Create Sales Order'),
                    icon: <Plus weight="bold" />,
                    onClick: () => {
                      setOpenAddSalesOrderModel(true)
                    },
                    style: {
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    },
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={headerRow}
              headerSearch={headerSearch}
              headerSort={headerSort}
              urlParams={urlParams}
              basicSegment
              showHeaderNoData={true}
              url={'/main/admin-sales-order/'}
              hideFooter={false}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <SalesOrdersRow
                    index={index}
                    salesOrder={data}
                    setSalesOrder={setSalesOrder}
                    hideBusiness={hideBusiness}
                    setUpdate={setUpdate}
                    setOpenAddSalesOrderModel={setOpenAddSalesOrderModel}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Sales Orders will populate here'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <AddSalesOrder
        buyer_id={business?.buyer?.id}
        salesOrder={salesOrder}
        setSalesOrder={setSalesOrder}
        reload={reload}
        setReload={setReload}
        update={update}
        setUpdate={setUpdate}
        hideBusiness={hideBusiness}
        openAddSalesOrderModel={openAddSalesOrderModel}
        setOpenAddSalesOrderModel={setOpenAddSalesOrderModel}
      />
    </div>
  )
}

export default SalesOrders
