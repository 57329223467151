import React, { useEffect, useState } from 'react'
import {
  Table,
  Popup,
  Icon,
  Segment,
  Divider,
  Form,
  Modal,
  Header,
  Button,
  Grid,
  Input,
  PopupContent,
  List,
} from 'semantic-ui-react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { formatTotalPrice } from '../../../lib/utils/utils'
import env from '../../../env'
import './AdminOrder.css'
import BuyerOrderMap from '../../buyer/Orders/BuyerOrderMap'
import PizzaTracker from '../../shared/PizzaTracker/PizzaTracker'
import ConfirmationModal from '../../../components/ConfirmationModal'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import _ from 'underscore'
import AdminOrderFulfillmentRow from './AdminOrderFulfilmentRow'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import { toast } from 'react-toastify'
import useAxios from '../../../lib/auth/useAxios'
import { ExclamationTriangle } from 'react-bootstrap-icons'
import { hasPermission } from '../../../lib/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  CaretDown,
  CaretRight,
  Checks,
  PaperPlaneTilt,
  Plus,
  Trash,
  TrashSimple,
  Truck,
} from 'phosphor-react'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'

function AdminOrderFulfillment(props) {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update Fulfillment')
  const canDelete = hasPermission(user, 'Delete Fulfillment')
  const [globalSettings, setGlobalSettings] = useState()
  const windowDimensions = useWindowDimensions()
  const mobiledisplay = windowDimensions.width < 768
  const [invoiceNumber, setInvoiceNumber] = useState(null)
  const [refreshPizzaTracker, setRefreshPizzaTracker] = useState(false)
  const [selectedCargoUnitInstance, setSelectedCargoUnitInstance] =
    useState(null)
  const [selectedCargos, setSelectedCargos] = useState([])
  const [cargos, setCargos] = useState([])
  const [totalTruckingPrice, setTotalTruckingPrice] = useState([])
  const [sendingSupplierPayout, setSendingSupplierPayout] = useState(false)
  const [orderItemsMissingSupplier, setOrderItemsMissingSupplier] = useState()
  const [selectedCUIHasInvoice, setSelectedCUIHasInvoice] = useState(false)
  const [openPurchaseOrderModal, setOpenPurchaseOrderModal] = useState(null)
  const [openSalesOrderModal, setOpenSalesOrderModal] = useState(null)
  const [salesOrderNumber, setSalesOrderNumber] = useState(null)
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState()
  const [frtTerm, setFrtTerm] = useState()
  const [documentGenerationLoading, setDocumentGenerationLoading] =
    useState(false)
  const [referenceNumber, setReferenceNumber] = useState()
  const [shipVia, setShipVia] = useState()
  const [verbalPoNumber, setVerbalPoNumber] = useState()
  const [millPickupNumber, setMillPickupNumber] = useState()
  const [transportNumber, setTransportNumber] = useState()
  const [openPayoutSupplierModal, setOpenPayoutSupplierModal] = useState(false)
  const [openInvoiceBuyerModal, setOpenInvoiceBuyerModal] = useState(false)
  const [selectedInvoiceMethod, setSelectedInvoiceMethod] = useState(false)
  const [sendDocumentPopup, setSendDocumentPopup] = useState(null)
  const { order, reloadAdminOrder, setReloadAdminOrder } = props
  const [priceConfirmationModal, setPriceConfirmationModal] = useState(null)

  function updateTotalTruckingPrice() {
    let sumTrucking = 0
    cargos.forEach((cargo) => {
      sumTrucking += parseFloat(cargo.trucking_price)
    })
    setTotalTruckingPrice(sumTrucking)
  }

  const getGlobalSettings = async () => {
    const resp = await axios.get('main/global-setting/')
    if (resp?.data?.results) {
      setGlobalSettings(resp?.data?.results[0])
    }
  }

  useEffect(() => {
    getGlobalSettings()
  }, [])

  useEffect(() => {
    updateTotalTruckingPrice()
  }, [])

  async function sendSupplierPayout() {
    setSendingSupplierPayout(true)
    let res = await axios.post(`/main/send-supplier-payment/`, {
      cargo_unit_instance_ids: selectedCargos,
      order_id: order?.id,
    })
    setSendingSupplierPayout(false)
    if (res?.data?.success) {
      setOpenPayoutSupplierModal(false)
      setReloadAdminOrder(true)
      toast.success('Sent payout to supplier!')
    }
  }

  function selectedDifferentSupplier() {
    let suppliers = {}
    cargos?.forEach((cargo) => {
      selectedCargos?.forEach((selectedCargo) => {
        if (
          cargo?.id === selectedCargo &&
          cargo?.container_items?.length > 0 &&
          cargo?.container_items[0]?.order_item?.supplier?.id
        ) {
          suppliers[cargo?.container_items[0]?.order_item?.supplier?.id] = true
        }
      })
    })
    return Object.keys(suppliers).length > 1
  }

  const [archiveCargoConfirmationModel, setArchiveCargoConfirmationModel] =
    useState(false)

  const itemsHeaderRow = [
    'Load ID',
    'Date Booked',
    'Supplier',
    'Estimated Pickup Date',
    'Pickup Date',
    'Estimated Delivery Date',
    'Delivery Date',
    'Pickup Location',
    'Delivery Location',
    'Trucking Price',
    'Supplier Payout',
    'Delivery Status',
  ]

  if (canUpdate || canDelete) {
    itemsHeaderRow.push('Actions')
  }

  //TODO archive the cargo unit instance
  async function archiveCargoUnitInstances() {
    let resInstance = await axios.post(
      `/main/admin-cargo-unit-instances/bulk_archive/`,
      {
        order_id: props.order.id,
        cui_ids: selectedCargos,
      }
    )
    setArchiveCargoConfirmationModel(false)
    if (resInstance.data.error) {
      toast.error(resInstance.data.error)
    } else if (resInstance?.status < 300) {
      if (selectedCargos?.includes(selectedCargoUnitInstance?.id))
        setSelectedCargoUnitInstance(null)
      setSelectedCargos([])
      setReloadAdminOrder(true)
    } else {
      toast.error('Error archiving order items.')
    }
  }

  const addSalesOrPurchaseOrder = async ({
    salesOrder = null,
    purchaseOrder = null,
    email = true,
  }) => {
    let errors = false
    if (salesOrder) {
      if (email && !order?.buyer?.business?.contact) {
        toast.error(
          `${order?.buyer?.business?.name} requires a contact user to send files for signature`
        )
        errors = true
      } else if (!salesOrderNumber) {
        toast.error('Sales order number required')
        errors = true
      }
    } else if (purchaseOrder) {
      if (!purchaseOrderNumber) {
        toast.error('Purchase order number required')
        errors = true
      } else if (!frtTerm) {
        toast.error('FRT term required')
        errors = true
      } else if (!referenceNumber) {
        toast.error('Reference number required')
        errors = true
      } else if (!shipVia) {
        toast.error('Ship via number required')
        errors = true
      } else if (!verbalPoNumber) {
        toast.error('Verbal PO number required')
        errors = true
      } else if (!millPickupNumber) {
        toast.error('Verbal PO number required')
        errors = true
      } else if (!transportNumber) {
        toast.error('Transport number required')
        errors = true
      }
    }
    if (!errors) {
      let orderItemIds = []
      let orderItems = []
      let supplierMissingContact = false
      let supplierMissingContactBusName
      let supplierId
      cargos.forEach((cui) => {
        if (selectedCargos.includes(cui?.id)) {
          if (purchaseOrder) {
            cui?.container_items.forEach((ci) => {
              if (!supplierId) {
                supplierId = ci.order_item?.supplier?.id
              }
              if (
                !supplierMissingContact &&
                !ci.order_item?.supplier?.business?.contact
              ) {
                supplierMissingContact = true
                supplierMissingContactBusName =
                  ci.order_item?.supplier?.business.name
              }
            })
          }
          cui?.container_items?.forEach((cci) => {
            if (salesOrder) {
              orderItems.push({
                quantity: cci.selected_sales_order_quantity
                  ? cci.selected_sales_order_quantity
                  : cci.order_item.buyer_quantity,
                buyer_uom_id: cci.selected_sales_order_unit_of_measure
                  ? cci.selected_sales_order_unit_of_measure
                  : cci.order_item?.buyer_unit?.id,
                oi_id: cci.order_item.id,
                product_variant_id: cci.order_item.product_variant_id,
                price: cci.order_item?.delivered_price,
              })
            } else {
              orderItems.push({
                quantity: cci.selected_purchase_order_quantity
                  ? cci.selected_purchase_order_quantity
                  : cci.quantity,
                uom_id: cci.selected_purchase_order_unit_of_measure
                  ? cci.selected_purchase_order_unit_of_measure
                  : cci.unit?.id,
                oi_id: cci.order_item.id,
                product_variant_id: cci.order_item.product_variant_id,
                price: cci.order_item?.price,
              })
            }
          })
          orderItemIds = [
            ...orderItemIds,
            ...cui?.container_items?.map((cci) => cci.order_item.id),
          ]
        }
      })
      if (email && purchaseOrder && supplierMissingContact) {
        toast.error(
          `${supplierMissingContactBusName} requires a contact user to send files for signature`
        )
        return
      }
      let res = await axios.post(`/main/admin-order-accounting-view/`, {
        sales_order: salesOrder,
        purchase_order: purchaseOrder,
        order_id: order?.id,
        order_items: orderItems,
        sales_order_number: salesOrderNumber,
        supplier_id: supplierId,
        purchase_order_number: purchaseOrderNumber,
        frt_term: frtTerm,
        reference_number: referenceNumber,
        ship_via: shipVia,
        verbal_po: verbalPoNumber,
        mill_pickup_number: millPickupNumber,
        transport_number: transportNumber,
        send_email: email,
      })
      if (res.data.success) {
        if (salesOrder) {
          toast.success(
            `Sales order generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
          )
        } else {
          toast.success(
            `Purchase order generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
          )
        }
      } else {
        if (salesOrder) {
          toast.error('Error sending sales order')
        } else {
          toast.error('Error sending purchase order')
        }
      }
    }
  }

  async function sendPaymentConfirmation(sendEmail) {
    setDocumentGenerationLoading(true)
    let filteredCargos = cargos.filter((cui) => selectedCargos.includes(cui.id))
    let cui_ids = filteredCargos.map((obj) => obj.id)
    if (sendEmail) {
      if (!props?.order?.buyer?.business?.contact) {
        toast.error(
          `${props.order.buyer.business.name} requires a contact user to send document generation requests`
        )
        setDocumentGenerationLoading(false)
        return
      } else if (
        !filteredCargos[0]?.container_items[0]?.order_item?.supplier?.business
          ?.contact
      ) {
        toast.error(
          `${filteredCargos[0]?.container_items[0]?.order_item?.supplier?.business?.name} requires a contact user to send document generation requests`
        )
        setDocumentGenerationLoading(false)
        return
      }
    }
    const orderItemIds = []
    filteredCargos?.forEach((cui) => {
      cui?.container_items?.forEach((cci) => {
        if (!cci.order_item?.is_buyer_marketplace_fee) {
          // if (!cci.order_item.quantity || parseFloat(cci.order_item.quantity) <= 0) {
          //   toast.error("Quantity must be greater than 0")
          //   return
          // }
          orderItemIds.push(cci.order_item?.id)
        }
      })
    })
    let res = await axios.post(`/main/box-sign-requests/`, {
      file_type: 'price_confirmation',
      send_to_customer: sendEmail,
      order_item_ids: orderItemIds,
      order_id: props.order?.id,
      cargo_unit_instance_ids: cui_ids,
      delay: true,
    })
    if (res?.status === 201) {
      toast.success(
        `Price Confirmation generation queued. Please check the document section shortly to review the document.`
      )
      setReloadAdminOrder(true)
    } else {
      toast.error('Error Creating Price confirmation Request')
    }
    setDocumentGenerationLoading(false)
    setPriceConfirmationModal(false)
  }

  async function markBuyerPaid() {
    let res = await axios.put(`/main/admin-mark-order-paid/`, {
      mark_paid: true,
      order_id: order?.id,
      cargo_unit_instance_ids: selectedCargos,
    })
    if (res?.data?.success) {
      toast.success('Marked order as paid!')
      setReloadAdminOrder(true)
      setOpenInvoiceBuyerModal(false)
    }
  }

  async function createInvoice(sendEmail) {
    if (!invoiceNumber) {
      toast.error('Invoice number required')
      return
    } else if (sendEmail && !order?.buyer?.business?.contact) {
      toast.error(
        `${order?.buyer?.business?.name} requires a contact user to send files for signature`
      )
      return
    }
    const orderItemIds = []
    const customOrderItems = []
    const cargoUnitInstanceIds = []
    cargos.forEach((cargo) => {
      if (selectedCargos.includes(cargo.id)) {
        cargoUnitInstanceIds.push(cargo.id)
        cargo.container_items.forEach((containerItem) => {
          if (
            !containerItem?.selected_invoice_order_quantity ||
            parseFloat(containerItem?.selected_invoice_order_quantity) <= 0
          ) {
            toast.error('Quantity must be greater than 0')
            return
          }
          customOrderItems.push({
            order_item_id: containerItem.order_item.id,
            buyer_quantity: containerItem.selected_invoice_order_quantity,
            buyer_unit_id: containerItem.selected_invoice_order_unit_of_measure,
            cargo_container_item_id: containerItem.id,
          })
          orderItemIds.push(containerItem.order_item.id)
        })
      }
    })
    let sendToCustomer = sendEmail
    if (
      ['invoice_only', 'payment_via_mickey'].includes(selectedInvoiceMethod) &&
      sendEmail
    ) {
      sendToCustomer = false
    }
    let res = await axios.post(`/main/box-sign-requests/`, {
      file_type: 'invoice',
      send_to_customer: sendToCustomer,
      send_invoice_to_customer: sendEmail,
      custom_order_items_rows_duplicate_order_items: customOrderItems,
      order_item_ids: orderItemIds,
      cargo_unit_instance_ids: cargoUnitInstanceIds,
      invoice_number: invoiceNumber,
      order_id: order?.id,
      invoice_method: selectedInvoiceMethod,
    })
    if (res?.data.success) {
      toast.success(
        `Invoice generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
      )
    } else {
      toast.error('Error generating invoice')
    }
  }

  return (
    <>
      <div id="selected-items-actions">
        {canDelete && (
          <Button
            basic
            icon={<Trash size={20} weight="bold" />}
            disabled={!selectedCargos || selectedCargos?.length === 0}
            labelPosition={'left'}
            color={'primary'}
            style={{
              '--primaryColor':
                !selectedCargos || selectedCargos?.length === 0
                  ? '#A1A1A1'
                  : env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              zIndex: 1000,
            }}
            size={'tiny'}
            content={'Delete'}
            onClick={() => {
              setArchiveCargoConfirmationModel(true)
            }}
          />
        )}
        {!selectedDifferentSupplier() && canUpdate && (
          <Button
            icon={<CaretRight size={20} weight="bold" />}
            basic
            color={'primary'}
            disabled={!selectedCargos || selectedCargos?.length === 0}
            labelPosition={'left'}
            style={{
              '--primaryColor':
                !selectedCargos || selectedCargos?.length === 0
                  ? '#A1A1A1'
                  : env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              zIndex: 1000,
            }}
            size={'tiny'}
            content={'Payout Supplier'}
            onClick={() => {
              setOpenPayoutSupplierModal(true)
            }}
          />
        )}
        {selectedDifferentSupplier() && canUpdate && (
          <Popup
            trigger={
              <Button
                icon={<CaretRight size={20} weight="bold" />}
                basic
                color={'primary'}
                disabled={!selectedCargos || selectedCargos?.length === 0}
                labelPosition={'left'}
                style={{
                  '--primaryColor':
                    !selectedCargos || selectedCargos?.length === 0
                      ? '#A1A1A1'
                      : env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  opacity: 0.5,
                }}
                size={'tiny'}
                content={'Payout Supplier'}
              />
            }
            content={
              'Select only items from the same supplier in order to payout supplier.'
            }
            position={'bottom'}
          />
        )}
        {selectedDifferentSupplier() && canUpdate && (
          <Popup
            trigger={
              <Button
                basic
                icon={<Plus size={20} weight="bold" />}
                labelPosition={'left'}
                disabled={!selectedCargos || selectedCargos?.length === 0}
                color={'primary'}
                style={{
                  '--primaryColor':
                    !selectedCargos || selectedCargos?.length === 0
                      ? '#A1A1A1'
                      : env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  opacity: 0.5,
                }}
                size={'tiny'}
                content={'Create Purchase Order'}
                onClick={() => {}}
              />
            }
            on="click"
            content={
              'Select only items from the same supplier in order to add new truck.'
            }
            position={'bottom left'}
          />
        )}
        {hasPermission(user, 'Create Document') && (
          <Popup
            id={'send-document-popup'}
            mountNode={
              document.getElementsByClassName(`send-document-button`)[0]
            }
            on={'click'}
            position={'bottom right'}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              width: '190px',
            }}
            open={sendDocumentPopup}
            onOpen={() => setSendDocumentPopup(true)}
            onClose={() => setSendDocumentPopup(false)}
            offset={[0, 0]}
            trigger={
              <Button
                basic
                className="send-document-button"
                labelPosition={'left'}
                color={'primary'}
                disabled={!selectedCargos || selectedCargos?.length === 0}
                style={{
                  '--primaryColor':
                    !selectedCargos || selectedCargos?.length === 0
                      ? '#A1A1A1'
                      : env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
                size={'tiny'}
                icon={
                  sendDocumentPopup ? (
                    <CaretDown size={20} weight="bold" />
                  ) : (
                    <CaretRight size={20} weight="bold" />
                  )
                }
                content={'Send Document'}
                onClick={() => {
                  setSendDocumentPopup(true)
                }}
              />
            }
          >
            <PopupContent>
              <List>
                {!selectedDifferentSupplier() && (
                  <List.Item
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    disabled={!selectedCargos || selectedCargos?.length === 0}
                    onClick={() => {
                      setSendDocumentPopup(false)
                      setOpenPurchaseOrderModal(true)
                    }}
                  >
                    <PaperPlaneTilt size={20} weight="bold" />
                    <b>Send Purchase Order</b>
                  </List.Item>
                )}
                <List.Item
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  disabled={!selectedCargos || selectedCargos?.length === 0}
                  onClick={() => {
                    setSendDocumentPopup(false)
                    setOpenInvoiceBuyerModal(true)
                  }}
                >
                  <PaperPlaneTilt size={20} weight="bold" />
                  <b>Send Invoice</b>
                </List.Item>
                <List.Item
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  disabled={!selectedCargos || selectedCargos?.length === 0}
                  onClick={() => {
                    setSendDocumentPopup(false)
                    setOpenSalesOrderModal(true)
                  }}
                >
                  <PaperPlaneTilt size={20} weight="bold" />
                  <b>Send Sales Order</b>
                </List.Item>
                <List.Item
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  disabled={!selectedCargos || selectedCargos?.length === 0}
                  onClick={() => {
                    setSendDocumentPopup(false)
                    setPriceConfirmationModal(true)
                  }}
                >
                  <PaperPlaneTilt size={20} weight="bold" />
                  <b>Send Price Confirmation</b>
                </List.Item>
              </List>
            </PopupContent>
          </Popup>
        )}

        <ConfirmationModal
          message={'Are you sure you want to archive this fulfillment?'}
          confirmationModal={archiveCargoConfirmationModel}
          setConfirmationModal={setArchiveCargoConfirmationModel}
          yesFunction={() => archiveCargoUnitInstances()}
          noFunction={() => setArchiveCargoConfirmationModel(false)}
        />
      </div>
      {!order?.id && <MickeyPlaceholderLoading />}
      {order?.id && (
        <MickeyTable
          headerRow={itemsHeaderRow}
          containerClassName={'fulfillment-table'}
          loadedStyle={{ width: '100%' }}
          basicSegment
          setParentRow={(cargos) => {
            setCargos(() =>
              cargos.map((cargo) => ({
                ...cargo,
                container_items: cargo.container_items.map(
                  (container_item) => ({
                    ...container_item,
                    selected_invoice_order_quantity: container_item.quantity,
                    selected_invoice_order_unit_of_measure:
                      container_item?.unit?.id,
                    selected_sales_order_quantity: container_item.quantity,
                    selected_sales_order_unit_of_measure:
                      container_item?.unit?.id,
                    selected_purchase_order_quantity: container_item.quantity,
                    selected_purchase_order_unit_of_measure:
                      container_item?.unit?.id,
                  })
                ),
              }))
            )
          }}
          url={'/main/admin-cargo-unit-instances/'}
          urlParams={{
            order_id: order?.id,
            light_serializer: true,
          }}
          reload={reloadAdminOrder}
          setReload={setReloadAdminOrder}
          renderBodyRow={(data, index) => {
            return (
              <AdminOrderFulfillmentRow
                order={order}
                canEdit={!order?.locked && !(order?.admin_order_status === 6)}
                selectedCargos={selectedCargos}
                selectedCUIHasInvoice={selectedCUIHasInvoice}
                setSelectedCUIHasInvoice={setSelectedCUIHasInvoice}
                setSelectedCargos={setSelectedCargos}
                updateTotalTruckingPrice={updateTotalTruckingPrice}
                selectedCargoUnitInstance={selectedCargoUnitInstance}
                setSelectedCargoUnitInstance={setSelectedCargoUnitInstance}
                orderCancelled={order?.admin_order_status === 6}
                orderId={order?.id}
                refreshPizzaTracker={refreshPizzaTracker}
                setRefreshPizzaTracker={setRefreshPizzaTracker}
                setReload={setReloadAdminOrder}
                cargoUnitInstance={cargos[index]}
                setCargoUnitInstance={(d) => {
                  let cargoCopy = [...cargos]
                  let cargoIds = _.map(cargoCopy, (cargo) => cargo?.id)
                  let idx = cargoIds?.indexOf(d?.id)
                  cargoCopy[idx] = {
                    ...d,
                    container_items: d.container_items.map(
                      (container_item) => ({
                        ...container_item,
                        selected_invoice_order_quantity:
                          container_item.quantity,
                        selected_invoice_order_unit_of_measure:
                          container_item?.unit?.id,
                        selected_sales_order_quantity: container_item.quantity,
                        selected_sales_order_unit_of_measure:
                          container_item?.unit?.id,
                        selected_purchase_order_quantity:
                          container_item.quantity,
                        selected_purchase_order_unit_of_measure:
                          container_item?.unit?.id,
                      })
                    ),
                  }
                  setCargos(cargoCopy)
                }}
                index={index}
              />
            )
          }}
          emptyIconHtml={<Truck size={40} />}
          footerRow={
            <Table.Row>
              <Table.HeaderCell colSpan={'7'}>
                <b>Totals</b>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={'left'} colSpan="1">
                <b>{formatTotalPrice(totalTruckingPrice)}</b>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3" />
            </Table.Row>
          }
          emptyMessage={'Cargo instances will populate here'}
        />
      )}
      {/* Create Purchase Order Modal */}
      <Modal
        size={'large'}
        onClose={() => {
          setOpenPurchaseOrderModal(false)
          setOrderItemsMissingSupplier(false)
          setPurchaseOrderNumber('')
          setFrtTerm('')
          setReferenceNumber('')
          setShipVia('')
          setVerbalPoNumber('')
          setMillPickupNumber('')
          setTransportNumber('')
          setCargos((prevState) => {
            return prevState.map((cargo) => {
              cargo.container_items = cargo.container_items.map((ci) => ({
                ...ci,
                selected_purchase_order_quantity: ci.quantity,
                selected_purchase_order_unit_of_measure: ci?.unit?.id,
              }))
              return cargo
            })
          })
        }}
        onOpen={() => setOpenPurchaseOrderModal(true)}
        open={openPurchaseOrderModal}
      >
        <Modal.Header>Create Purchase Order</Modal.Header>
        <Modal.Content>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field width={4}>
                      <label>Purchase Order Number</label>
                      <Form.Input
                        placeholder={'Purchase Order Number'}
                        value={purchaseOrderNumber}
                        onChange={(e, d) => {
                          setPurchaseOrderNumber(d.value)
                        }}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <label>Ship Via</label>
                      <Form.Input
                        placeholder={'Ship Via'}
                        value={shipVia}
                        onChange={(e, d) => {
                          setShipVia(d.value)
                        }}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <label>FRT Term</label>
                      <Form.Input
                        placeholder={'FRT Term'}
                        value={frtTerm}
                        onChange={(e, d) => {
                          setFrtTerm(d.value)
                        }}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <label>Reference Number</label>
                      <Form.Input
                        placeholder={'Reference Number'}
                        value={referenceNumber}
                        onChange={(e, d) => {
                          setReferenceNumber(d.value)
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field width={4}>
                      <label>Verbal PO Number</label>
                      <Form.Input
                        placeholder={'Verbal PO Number'}
                        value={verbalPoNumber}
                        onChange={(e, d) => {
                          setVerbalPoNumber(d.value)
                        }}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <label>Mill Pickup Number</label>
                      <Form.Input
                        placeholder={'Mill Pickup Number'}
                        value={millPickupNumber}
                        onChange={(e, d) => {
                          setMillPickupNumber(d.value)
                        }}
                      />
                    </Form.Field>
                    <Form.Field width={4}>
                      <label>Transport Number</label>
                      <Form.Input
                        placeholder={'Transport Number'}
                        value={transportNumber}
                        onChange={(e, d) => {
                          setTransportNumber(d.value)
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Cargo Number</Table.HeaderCell>
              <Table.HeaderCell>Products</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {cargos?.map((cui, cargo_index) => {
                if (!selectedCargos.includes(cui?.id)) {
                  return null
                }
                return cui?.container_items?.map((cci, cci_index) => {
                  let missingSupplier = false
                  if (!cci?.order_item.supplier) {
                    missingSupplier = true
                    if (!orderItemsMissingSupplier) {
                      setOrderItemsMissingSupplier(true)
                    }
                  }
                  return (
                    <Table.Row>
                      <Table.Cell textAlign={'center'}>
                        {missingSupplier && (
                          <span style={{ color: 'red', marginRight: '5px' }}>
                            <ExclamationTriangle />
                          </span>
                        )}
                        {cui?.id}
                      </Table.Cell>
                      <Table.Cell>
                        <OrderItemDetailTwo orderItem={cci?.order_item} />
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          placeholder={'Quantity'}
                          error={
                            parseFloat(cci?.selected_purchase_order_quantity) >
                              parseFloat(cci?.quantity) ||
                            parseFloat(cci?.selected_purchase_order_quantity) <
                              0
                          }
                          value={cci?.selected_purchase_order_quantity}
                          onChange={(e, d) => {
                            let cuis = [...cargos]
                            let container_items = [...cui.container_items]
                            container_items[cci_index] = {
                              ...cci,
                              selected_purchase_order_quantity: d.value,
                            }
                            cuis[cargo_index] = {
                              ...cargos[cargo_index],
                              container_items: container_items,
                            }
                            setCargos(cuis)
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <MickeyDropdown
                          url={'/main/unit-of-measures/'}
                          name={'unit_of_measure'}
                          value={cci?.selected_purchase_order_unit_of_measure}
                          defaultValue={cci?.unit?.id}
                          params={{
                            page_size: 25,
                            product_id:
                              cci?.order_item?.product_variant?.product_id,
                          }}
                          autoComplete="new-password"
                          placeholder={'Unit of Measure'}
                          titleFields={['plural_name']}
                          descriptionFields={[]}
                          onChange={(e, d) => {
                            let cuis = [...cargos]
                            let container_items = [...cui.container_items]
                            container_items[cci_index] = {
                              ...cci,
                              selected_purchase_order_unit_of_measure: d.value,
                            }
                            cuis[cargo_index] = {
                              ...cargos[cargo_index],
                              container_items: container_items,
                            }
                            setCargos(cuis)
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {formatTotalPrice(cci?.order_item?.price)}
                      </Table.Cell>
                      <Table.Cell>
                        {formatTotalPrice(
                          cci?.order_item?.price *
                            parseFloat(cci?.selected_purchase_order_quantity)
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Sales Tax</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      cargos
                        ?.map((cui, index) => {
                          if (!selectedCargos.includes(cui?.id)) {
                            return 0
                          }
                          let item_cost = cui?.container_items
                            ?.map((cci, index) => {
                              let salesTax = cci.order_item.tax_amount
                                ? cci.order_item.tax_amount
                                : 0
                              if (salesTax > 0) {
                                salesTax =
                                  salesTax *
                                  (parseFloat(
                                    cci?.selected_purchase_order_quantity
                                  ) /
                                    cci.order_item.quantity)
                              }
                              return salesTax
                            })
                            .reduce(
                              (accumulator, currentValue) =>
                                parseFloat(accumulator) +
                                parseFloat(currentValue),
                              0
                            )
                          return parseFloat(item_cost)
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      cargos
                        ?.map((cui, index) => {
                          if (!selectedCargos.includes(cui?.id)) {
                            return 0
                          }
                          let item_cost = cui?.container_items
                            ?.map((cci, index) => {
                              let salesTax = cci.order_item.tax_amount
                                ? cci.order_item.tax_amount
                                : 0
                              if (salesTax > 0) {
                                salesTax =
                                  salesTax *
                                  (parseFloat(
                                    cci?.selected_purchase_order_quantity
                                  ) /
                                    cci.order_item.quantity)
                              }
                              return (
                                cci?.order_item?.price *
                                  parseFloat(
                                    cci.selected_purchase_order_quantity
                                  ) +
                                parseFloat(salesTax)
                              )
                            })
                            .reduce(
                              (accumulator, currentValue) =>
                                parseFloat(accumulator) +
                                parseFloat(currentValue),
                              0
                            )
                          return parseFloat(item_cost)
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          {orderItemsMissingSupplier && (
            <Grid.Column width={16}>
              <span style={{ color: 'red', fontSize: '20px' }}>
                <ExclamationTriangle />
              </span>
              <span
                style={{
                  fontSize: '15px',
                  marginLeft: '5px',
                  position: 'relative',
                  top: '-6px',
                }}
              >
                Cannot send purchase order if material list items are missing a
                supplier
              </span>
            </Grid.Column>
          )}
          <div style={{ clear: 'right' }}></div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setOpenPurchaseOrderModal(false)
              setOrderItemsMissingSupplier(false)
              setPurchaseOrderNumber('')
              setFrtTerm('')
              setReferenceNumber('')
              setShipVia('')
              setVerbalPoNumber('')
              setMillPickupNumber('')
              setTransportNumber('')
              setCargos((prevState) => {
                return prevState.map((cargo) => {
                  cargo.container_items = cargo.container_items.map((ci) => ({
                    ...ci,
                    selected_purchase_order_quantity: ci.quantity,
                    selected_purchase_order_unit_of_measure: ci?.unit?.id,
                  }))
                  return cargo
                })
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          {!orderItemsMissingSupplier && (
            <>
              <Button
                type="submit"
                onClick={() =>
                  addSalesOrPurchaseOrder({ purchaseOrder: true, email: false })
                }
                color="primary"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Create
              </Button>
              <Button
                type="submit"
                onClick={() => addSalesOrPurchaseOrder({ purchaseOrder: true })}
                icon="paper plane"
                color="primary"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Create & Send
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
      {/* Payout Supplier Modal */}
      <Modal
        onClose={() => setOpenPayoutSupplierModal(false)}
        onOpen={() => setOpenPayoutSupplierModal(true)}
        open={openPayoutSupplierModal}
      >
        <Modal.Header>Payout Supplier</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.HeaderCell>Cargo Number</Table.HeaderCell>
              <Table.HeaderCell>Products</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {cargos?.map((cui, index) => {
                if (!selectedCargos.includes(cui?.id)) {
                  return null
                }
                return cui?.container_items?.map((cci, index) => {
                  return (
                    <Table.Row>
                      <Table.Cell textAlign={'center'}>{cui?.id}</Table.Cell>
                      <Table.Cell>
                        <OrderItemDetailTwo orderItem={cci?.order_item} />
                      </Table.Cell>
                      <Table.Cell>{cci?.quantity}</Table.Cell>
                      <Table.Cell>{cci?.unit?.short_name}</Table.Cell>
                      <Table.Cell>
                        {formatTotalPrice(cci?.order_item?.price)}
                      </Table.Cell>
                      <Table.Cell>
                        {formatTotalPrice(
                          cci?.order_item?.price * cci?.quantity
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                })
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      cargos
                        ?.map((cui, index) => {
                          if (!selectedCargos.includes(cui?.id)) {
                            return null
                          }
                          return cui?.container_items
                            ?.map((cci, index) => {
                              return cci?.order_item?.price * cci.quantity
                            })
                            .reduce(
                              (accumulator, currentValue) =>
                                parseFloat(accumulator) +
                                parseFloat(currentValue),
                              0
                            )
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <MickeyButton
            color={'green'}
            content={'Send Payment'}
            loading={sendingSupplierPayout}
            onClick={() => {
              sendSupplierPayout()
            }}
            style={{
              marginTop: '20px',
              float: 'right',
              clear: 'right',
              cursor: 'pointer',
            }}
          />
          <div style={{ clear: 'right' }}></div>
        </Modal.Content>
      </Modal>
      {/* Invoice Buyer Modal */}
      <Modal
        onClose={() => {
          setOpenInvoiceBuyerModal(false)
          setSelectedInvoiceMethod()
          setInvoiceNumber()
          setCargos((prevState) => {
            return prevState.map((cargo) => {
              const ccis = cargo.container_items.map((ci) => ({
                ...ci,
                selected_invoice_order_quantity: ci.quantity,
                selected_invoice_order_unit_of_measure: ci.unit?.id,
              }))
              cargo.container_items = ccis
              return cargo
            })
          })
        }}
        onOpen={() => setOpenInvoiceBuyerModal(true)}
        open={openInvoiceBuyerModal}
        size={'large'}
      >
        <Modal.Header>Create Invoice</Modal.Header>
        <Modal.Content>
          <div style={{ clear: 'right' }}>
            <Form>
              <Form.Group>
                <Popup
                  position={'bottom right'}
                  offset={[0, 0]}
                  content={
                    <div style={{ padding: '10px' }}>
                      <Header as={'h4'} content={'Payment Via Mickey'} />
                      <p>
                        Select this option if you'd like Mickey to take the
                        buyer through a payment checkout process, including
                        offering terms.
                      </p>
                      <Header as={'h4'} content={'ACH Only'} />
                      <p>
                        Select this option if you'd like Mickey to include ACH
                        wire instructions on the Invoice for the buyer to push
                        funds to.
                      </p>
                      <Header as={'h4'} content={'Invoice Only'} />
                      <p>
                        Select this option if you'd like to only send the
                        Invoice to the buyer. This is ideal if you're expecting
                        a check or already have payment methods determined.
                      </p>
                      <Header as={'h4'} content={'Mark Paid Externally'} />
                      <p>
                        Select this option if you collected payment outside the
                        Mickey app.
                      </p>
                    </div>
                  }
                  style={{ marginLeft: '5px' }}
                  on="hover"
                  pinned
                  trigger={
                    <Icon name={'info circle'} style={{ marginLeft: '7px' }} />
                  }
                />
                <Form.Field width={6}>
                  <Form.Dropdown
                    className={'invoice-dropdown'}
                    clearable
                    selection
                    placeholder={'Select Invoice Type'}
                    label={'Select Invoice Type'}
                    onChange={(e, d) => {
                      setSelectedInvoiceMethod(d.value)
                    }}
                    value={selectedInvoiceMethod}
                    style={{
                      minWidth: '70%',
                    }}
                    options={[
                      {
                        key: 'send-buyer-payment',
                        value: 'payment_via_mickey',
                        text: 'Payment Via Mickey',
                      },
                      {
                        key: 'send-buyer-payment-ach-credit',
                        value: 'ach_only',
                        text: 'ACH Only',
                      },
                      {
                        key: 'send-buyer-invoice',
                        value: 'invoice_only',
                        text: 'Invoice Only',
                      },
                      {
                        key: 'mark-buyer-paid',
                        value: 'mark_paid_externally',
                        text: 'Mark Paid Externally',
                      },
                    ]}
                  />
                </Form.Field>
                {selectedInvoiceMethod &&
                  selectedInvoiceMethod != 'mark_paid_externally' && (
                    <>
                      <Form.Field width={4}>
                        <label>Invoice Number</label>
                        <Form.Input
                          placeholder={'Invoice Number'}
                          value={invoiceNumber}
                          onChange={(e, d) => {
                            setInvoiceNumber(d.value)
                          }}
                        />
                      </Form.Field>
                    </>
                  )}
              </Form.Group>
            </Form>
            <Table>
              <Table.Header>
                <Table.HeaderCell>Cargo Number</Table.HeaderCell>
                <Table.HeaderCell>Products</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {cargos?.map((cui, cui_index) => {
                  if (!selectedCargos.includes(cui?.id)) {
                    return null
                  }
                  let x = cui?.container_items?.map((cci, cci_index) => {
                    let mickeyTake = cci.order_item.mickey_take
                      ? cci.order_item.mickey_take
                      : 0
                    if (mickeyTake > 0) {
                      mickeyTake =
                        mickeyTake *
                        (parseFloat(cci?.selected_invoice_order_quantity) /
                          cci.order_item.buyer_quantity)
                    }
                    return (
                      <Table.Row>
                        <Table.Cell textAlign={'center'}>
                          {cui?.id}
                          {cui?.invoices?.length > 0 && (
                            <Popup
                              position={'bottom middle'}
                              trigger={
                                <Icon
                                  style={{ marginLeft: '5px' }}
                                  circular
                                  name={'warning'}
                                  color={'red'}
                                />
                              }
                              content={
                                'Warning: this cargo already has an invoice associated with it.'
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <OrderItemDetailTwo orderItem={cci?.order_item} />
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            placeholder={'Quantity'}
                            error={
                              parseFloat(cci?.selected_invoice_order_quantity) >
                                parseFloat(cci?.quantity) ||
                              parseFloat(cci?.selected_invoice_order_quantity) <
                                0 ||
                              !cci?.selected_invoice_order_quantity
                            }
                            value={cci?.selected_invoice_order_quantity}
                            defaultValue={cci?.quantity}
                            onChange={(e, d) => {
                              let cuis = [...cargos]
                              let container_items = [...cui.container_items]
                              container_items[cci_index] = {
                                ...cci,
                                selected_invoice_order_quantity: d.value,
                              }
                              cuis[cui_index] = {
                                ...cargos[cui_index],
                                container_items: container_items,
                              }
                              setCargos(cuis)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <MickeyDropdown
                            url={'/main/unit-of-measures/'}
                            name={'unit_of_measure'}
                            value={cci?.selected_invoice_order_unit_of_measure}
                            defaultValue={cci?.unit?.id}
                            params={{
                              page_size: 25,
                              product_id:
                                cci?.order_item?.product_variant?.product_id,
                            }}
                            autoComplete="new-password"
                            placeholder={'Unit of Measure'}
                            titleFields={['plural_name']}
                            descriptionFields={[]}
                            onChange={(e, d) => {
                              let cuis = [...cargos]
                              let container_items = [...cui.container_items]
                              container_items[cci_index] = {
                                ...cci,
                                selected_invoice_order_unit_of_measure: d.value,
                              }
                              cuis[cui_index] = {
                                ...cargos[cui_index],
                                container_items: container_items,
                              }
                              setCargos(cuis)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(cci?.order_item?.delivered_price)}
                        </Table.Cell>
                        <Table.Cell>{formatTotalPrice(mickeyTake)}</Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(
                            cci?.order_item?.delivered_price *
                              cci?.selected_invoice_order_quantity +
                              parseFloat(mickeyTake)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                  return x
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Sales Tax</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_invoice_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return salesTax
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Total</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_invoice_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                let mickeyTake = cci.order_item.mickey_take
                                  ? cci.order_item.mickey_take
                                  : 0
                                if (mickeyTake > 0) {
                                  mickeyTake =
                                    mickeyTake *
                                    (parseFloat(
                                      cci?.selected_invoice_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return (
                                  cci?.order_item?.delivered_price *
                                    cci.selected_invoice_order_quantity +
                                  parseFloat(salesTax) +
                                  parseFloat(mickeyTake)
                                )
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <div style={{ clear: 'right' }}></div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setOpenInvoiceBuyerModal(false)
              setSelectedInvoiceMethod()
              setInvoiceNumber()
              setCargos((prevState) => {
                return prevState.map((cargo) => {
                  const ccis = cargo.container_items.map((ci) => ({
                    ...ci,
                    selected_invoice_order_quantity: ci.quantity,
                    selected_invoice_order_unit_of_measure:
                      ci.order_item.unit.id,
                  }))
                  cargo.container_items = ccis
                  return cargo
                })
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          {selectedInvoiceMethod &&
            selectedInvoiceMethod === 'invoice_only' && (
              <>
                <Button
                  type="submit"
                  onClick={() => {
                    createInvoice(false)
                  }}
                  color="primary"
                  style={{
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                  }}
                >
                  Create
                </Button>
              </>
            )}
          {selectedInvoiceMethod &&
            ['invoice_only', 'payment_via_mickey', 'ach_only'].includes(
              selectedInvoiceMethod
            ) && (
              <Button
                type="submit"
                onClick={() => {
                  createInvoice(true)
                }}
                color="primary"
                icon="paper plane"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Create & Send
              </Button>
            )}
          {selectedInvoiceMethod === 'mark_paid_externally' && (
            <Button
              type="submit"
              onClick={markBuyerPaid}
              color="primary"
              icon="paper plane"
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
            >
              Mark Paid
            </Button>
          )}
        </Modal.Actions>
      </Modal>
      {/* Sales Order Modal */}
      <Modal
        onClose={() => {
          setOpenSalesOrderModal(false)
          setSalesOrderNumber()
          setCargos((prevState) => {
            return prevState.map((cargo) => {
              cargo.container_items = cargo.container_items.map((ci) => ({
                ...ci,
                selected_sales_order_quantity: ci.quantity,
                selected_sales_order_unit_of_measure: ci?.unit?.id,
              }))
              return cargo
            })
          })
        }}
        onOpen={() => setOpenSalesOrderModal(true)}
        open={openSalesOrderModal}
        size={'large'}
      >
        <Modal.Header>Create Sales Order</Modal.Header>
        <Modal.Content>
          <div style={{ clear: 'right' }}>
            <Form>
              <Form.Group>
                <Form.Field width={4}>
                  <label>Sales Order Number</label>
                  <Form.Input
                    placeholder={'Sales Order'}
                    value={salesOrderNumber}
                    onChange={(e, d) => {
                      setSalesOrderNumber(d.value)
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <Table>
              <Table.Header>
                <Table.HeaderCell>Cargo Number</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit Of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {cargos?.map((cui, cargo_index) => {
                  if (!selectedCargos.includes(cui?.id)) {
                    return null
                  }
                  let x = cui?.container_items?.map((cci, cci_index) => {
                    let mickeyTake = cci.order_item.mickey_take
                      ? cci.order_item.mickey_take
                      : 0
                    if (mickeyTake > 0) {
                      mickeyTake =
                        mickeyTake *
                        (parseFloat(cci?.selected_sales_order_quantity) /
                          cci.order_item.buyer_quantity)
                    }
                    return (
                      <Table.Row>
                        <Table.Cell textAlign={'center'}>
                          {cui?.id}
                          {cui?.invoices?.length > 0 && (
                            <Popup
                              position={'bottom middle'}
                              trigger={
                                <Icon
                                  style={{ marginLeft: '5px' }}
                                  circular
                                  name={'warning'}
                                  color={'red'}
                                />
                              }
                              content={
                                'Warning: this cargo already has an invoice associated with it.'
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <OrderItemDetailTwo orderItem={cci?.order_item} />
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            placeholder={'Quantity'}
                            error={
                              parseFloat(cci?.selected_sales_order_quantity) >
                                parseFloat(cci?.quantity) ||
                              parseFloat(cci?.selected_sales_order_quantity) < 0
                            }
                            value={cci?.selected_sales_order_quantity}
                            onChange={(e, d) => {
                              let cuis = [...cargos]
                              let container_items = [...cui.container_items]
                              container_items[cci_index] = {
                                ...cci,
                                selected_sales_order_quantity: d.value,
                              }
                              cuis[cargo_index] = {
                                ...cargos[cargo_index],
                                container_items: container_items,
                              }
                              setCargos(cuis)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <MickeyDropdown
                            url={'/main/unit-of-measures/'}
                            name={'unit_of_measure'}
                            value={cci?.selected_sales_order_unit_of_measure}
                            defaultValue={cci?.order_item?.buyer_unit?.id}
                            params={{
                              page_size: 25,
                              product_id:
                                cci?.order_item?.product_variant?.product_id,
                            }}
                            autoComplete="new-password"
                            placeholder={'Unit of Measure'}
                            titleFields={['plural_name']}
                            descriptionFields={[]}
                            onChange={(e, d) => {
                              let cuis = [...cargos]
                              let container_items = [...cui.container_items]
                              container_items[cci_index] = {
                                ...cci,
                                selected_sales_order_unit_of_measure: d.value,
                              }
                              cuis[cargo_index] = {
                                ...cargos[cargo_index],
                                container_items: container_items,
                              }
                              setCargos(cuis)
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(cci?.order_item?.delivered_price)}
                        </Table.Cell>
                        <Table.Cell>{formatTotalPrice(mickeyTake)}</Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(
                            cci?.order_item?.delivered_price *
                              parseFloat(cci?.selected_sales_order_quantity) +
                              parseFloat(mickeyTake)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                  return x
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Sales Tax</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return salesTax
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Total</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                let mickeyTake = cci.order_item.mickey_take
                                  ? cci.order_item.mickey_take
                                  : 0
                                if (mickeyTake > 0) {
                                  mickeyTake =
                                    mickeyTake *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return (
                                  cci?.order_item?.delivered_price *
                                    parseFloat(
                                      cci.selected_sales_order_quantity
                                    ) +
                                  parseFloat(mickeyTake) +
                                  parseFloat(salesTax)
                                )
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <div style={{ clear: 'right' }}></div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setOpenSalesOrderModal(false)
              setSalesOrderNumber()
              setCargos((prevState) => {
                return prevState.map((cargo) => {
                  cargo.container_items = cargo.container_items.map((ci) => ({
                    ...ci,
                    selected_sales_order_quantity: ci.quantity,
                    selected_sales_order_unit_of_measure: ci?.unit?.id,
                  }))
                  return cargo
                })
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() =>
              addSalesOrPurchaseOrder({ salesOrder: true, email: false })
            }
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create
          </Button>
          <Button
            type="submit"
            onClick={() => addSalesOrPurchaseOrder({ salesOrder: true })}
            icon="paper plane"
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create & Send
          </Button>
        </Modal.Actions>
      </Modal>
      {/*price Confirmation modal */}
      <Modal
        onClose={() => {
          setPriceConfirmationModal(false)
          setCargos((prevState) => {
            return prevState.map((cargo) => {
              cargo.container_items = cargo.container_items.map((ci) => ({
                ...ci,
                selected_sales_order_quantity: ci.quantity,
                selected_sales_order_unit_of_measure: ci?.unit?.id,
              }))
              return cargo
            })
          })
        }}
        onOpen={() => setPriceConfirmationModal(true)}
        open={priceConfirmationModal}
        size={'large'}
      >
        <Modal.Header>Create Price Confirmation</Modal.Header>
        <Modal.Content>
          <div style={{ clear: 'right' }}>
            <Table>
              <Table.Header>
                <Table.HeaderCell>Cargo Number</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit Of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {cargos?.map((cui, cargo_index) => {
                  if (!selectedCargos.includes(cui?.id)) {
                    return
                  }
                  let x = cui?.container_items?.map((cci, cci_index) => {
                    let mickeyTake = cci.order_item.mickey_take
                      ? cci.order_item.mickey_take
                      : 0
                    if (mickeyTake > 0) {
                      mickeyTake =
                        mickeyTake *
                        (parseFloat(cci?.selected_sales_order_quantity) /
                          cci.order_item.buyer_quantity)
                    }
                    return (
                      <Table.Row>
                        <Table.Cell textAlign={'center'}>
                          {cui?.id}
                          {cui?.invoices?.length > 0 && (
                            <Popup
                              position={'bottom middle'}
                              trigger={
                                <Icon
                                  style={{ marginLeft: '5px' }}
                                  circular
                                  name={'warning'}
                                  color={'red'}
                                />
                              }
                              content={
                                'Warning: this cargo already has an invoice associated with it.'
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <OrderItemDetailTwo orderItem={cci?.order_item} />
                        </Table.Cell>
                        <Table.Cell>
                          {cci?.selected_sales_order_quantity}
                        </Table.Cell>
                        <Table.Cell>{cci?.unit?.plural_name}</Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(cci?.order_item?.delivered_price)}
                        </Table.Cell>
                        <Table.Cell>{formatTotalPrice(mickeyTake)}</Table.Cell>
                        <Table.Cell>
                          {formatTotalPrice(
                            cci?.order_item?.delivered_price *
                              parseFloat(cci?.selected_sales_order_quantity) +
                              parseFloat(mickeyTake)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    )
                  })
                  return x
                })}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Sales Tax</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return salesTax
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>
                    <b>Total</b>
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    <b>
                      {formatTotalPrice(
                        cargos
                          ?.map((cui, index) => {
                            if (!selectedCargos.includes(cui?.id)) {
                              return 0
                            }
                            let item_cost = cui?.container_items
                              ?.map((cci, index) => {
                                let salesTax = cci.order_item.tax_amount
                                  ? cci.order_item.tax_amount
                                  : 0
                                if (salesTax > 0) {
                                  salesTax =
                                    salesTax *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                let mickeyTake = cci.order_item.mickey_take
                                  ? cci.order_item.mickey_take
                                  : 0
                                if (mickeyTake > 0) {
                                  mickeyTake =
                                    mickeyTake *
                                    (parseFloat(
                                      cci?.selected_sales_order_quantity
                                    ) /
                                      cci.order_item.buyer_quantity)
                                }
                                return (
                                  cci?.order_item?.delivered_price *
                                    parseFloat(
                                      cci.selected_sales_order_quantity
                                    ) +
                                  parseFloat(mickeyTake) +
                                  parseFloat(salesTax)
                                )
                              })
                              .reduce(
                                (accumulator, currentValue) =>
                                  parseFloat(accumulator) +
                                  parseFloat(currentValue),
                                0
                              )
                            return parseFloat(item_cost)
                          })
                          .reduce(
                            (accumulator, currentValue) =>
                              parseFloat(accumulator) +
                              parseFloat(currentValue),
                            0
                          )
                      )}
                    </b>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
            <div style={{ clear: 'right' }}></div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setPriceConfirmationModal(false)
              setCargos((prevState) => {
                return prevState.map((cargo) => {
                  cargo.container_items = cargo.container_items.map((ci) => ({
                    ...ci,
                    selected_sales_order_quantity: ci.quantity,
                    selected_sales_order_unit_of_measure: ci?.unit?.id,
                  }))
                  return cargo
                })
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            loading={documentGenerationLoading}
            onClick={() => {
              sendPaymentConfirmation(false)
            }}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create
          </Button>
          <Button
            type="submit"
            loading={documentGenerationLoading}
            onClick={() => {
              sendPaymentConfirmation(true)
            }}
            icon="paper plane"
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create & Send
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminOrderFulfillment
