import GroupFormPermissionsTable from '../components/Permissions/GroupFormPermissionsTable'

export const GroupForm = {
  model: 'Group',
  format: [
    {
      widths: 'equal',
      fields: {
        users: {
          required: true,
          url: '/main/custom-users/?light_serializer=true',
          type: 'multiple dropdown model',
          label: 'Users',
          option_text_fields: ['full_name', 'email'],
          fieldStyle: { minHeight: '150px' },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        records: {
          type: 'dropdown',
          label: 'Viewable Records',
          required: true,
        },
        pricing: {
          type: 'dropdown',
          label: 'Pricing',
          required: true,
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        admin_portal: {
          required: false,
          type: 'checkbox',
          label: 'Admin Portal Access',
        },
        supplier_portal: {
          required: false,
          type: 'checkbox',
          label: 'Supplier Portal Access',
        },
        buyer_portal: {
          required: false,
          type: 'checkbox',
          label: 'Buyer Portal Access',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        permissions: {
          type: 'custom field',
          hide_label: true,
          custom_field_components: (
            key,
            value,
            handleInputChange,
            labelSuccessField,
            instanceLabel
          ) => {
            return (
              <GroupFormPermissionsTable
                fieldName={key}
                value={value}
                handleInputChange={handleInputChange}
                labelSuccessField={labelSuccessField}
                instanceLabel={instanceLabel}
              />
            )
          },
          handleFormDataAppend: (key, value, formData) => {
            try {
              value?.forEach((item) => {
                formData.append(key + '[]', item)
              })
              return formData
            } catch {
              return formData
            }
          },
        },
      },
    },
  ],
}
