import React, { useEffect } from 'react'
import MXExchange from '../MXExchange/MXExchange'

const MXYourBids = (props) => {
  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab('my-bids')
      if (!props.is_admin) {
        props.setSelectedCommodity(null)
        props.setSelectedCommodityForm(null)
      }
    }
    props.reAuth()
  }, [])

  return (
    <MXExchange
      currentSelectedLocation={props.currentSelectedLocation}
      setCurrentSelectedLocation={props.setCurrentSelectedLocation}
      loadData={props?.loadData}
      yourBids={true}
      user={props.user}
      setUser={props.setUser}
      setInputSecondaryPrice={props.setInputSecondaryPrice}
      inputSecondaryPrice={props.inputSecondaryPrice}
      setAdminUser={props.setAdminUser}
      business={props.business}
      is_admin={props.is_admin}
      reload={props.reload}
      setReload={props.setReload}
      openExchangeFilter={props.openExchangeFilter}
      setOpenExchangeFilter={props.setOpenExchangeFilter}
      currentPortal={props.currentPortal}
      setCurrentPortal={props.setCurrentPortal}
      selectedCommodity={props.selectedCommodity}
      setReloadHeaderStats={props.setReloadHeaderStats}
      inputExpiration={props.inputExpiration}
      setInputExpiration={props.setInputExpiration}
      setSelectedCommodity={props.setSelectedCommodity}
      openStartListingModal={props.openStartListingModal}
      setStartListingModal={props.setStartListingModal}
      openSetAttributesModal={props.openSetAttributesModal}
      setOpenAttributesModal={props.setOpenAttributesModal}
      openPricingModal={props.openPricingModal}
      setOpenPricingModal={props.setOpenPricingModal}
      openReviewListingModal={props.openReviewListingModal}
      setReviewListingModal={props.setReviewListingModal}
      hideFilter={props.hideFilter}
      setHideFilter={props.setHideFilter}
      productVariant={props.productVariant}
      setProductVariant={props.setProductVariant}
      setInputPrice={props.setInputPrice}
      inputPrice={props.inputPrice}
      setInputQuantity={props.setInputQuantity}
      inputQuantity={props.inputQuantity}
      setCurrentTab={props.setCurrentTab}
      currentTab={props.currentTab}
      selectedCommodityForm={props.selectedCommodityForm}
      setSelectedCommodityForm={props.setSelectedCommodityForm}
      currentlySelectedTimeframe={props.currentlySelectedTimeframe}
      setCurrentlySelectedTimeframe={props.setCurrentlySelectedTimeframe}
      currentlySelectedTruckingType={props.currentlySelectedTruckingType}
      setCurrentlySelectedTruckingType={props.setCurrentlySelectedTruckingType}
    />
  )
}

export default MXYourBids
