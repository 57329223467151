import {
  requiredPositiveNumberValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const ManualTruckingForm = {
  model: 'OrderItem',
  format: [
    {
      widths: 'equal',
      fields: {
        trucking_price: {
          label: 'Trucking Price',
          required: true,
          type: 'number',
          step: '0.01',
          validator: (input) => {
            return requiredPositiveNumberValidator(input, 'Trucking Price')
          },
        },
        trucking_additional_charges: {
          label: 'Additional Charges',
          required: false,
          type: 'number',
          step: '0.01',
          validator: (input) => {
            return requiredPositiveNumberValidator(input, 'Additional Charges')
          },
        },
        quick_pay_fee: {
          type: 'number',
          step: '0.01',
          label: 'Quick Pay Fee',
          validator: (input) => {
            return requiredPositiveNumberValidator(input, 'Quick Pay Fee')
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        trucking_company: {
          type: 'dropdown search',
          label: 'Trucking Company',
          url: '/main/list-trucking-companies/',
          title_fields: ['name'],
          description_fields: ['type'],
          filter_field: 'name_icontains',
          allow_add: true,
          model: 'TruckingCompany',
          model_form: 'TruckingCompanyForm',
          model_header_name: 'Trucking Company',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        load_id: {
          type: 'text',
          label: 'Load ID',
        },
        shipper_load_id: {
          type: 'text',
          label: 'Shipper Load ID',
        },
        bill_of_lading_number: {
          type: 'text',
          label: 'Bill of Lading',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        dead_weight_threshold: {
          type: 'text',
          label: 'Dead Weight Threshold',
        },
        dead_weight_unit_of_measure: {
          label: 'Dead Weight UOM',
          type: 'dropdown search',
          url: '/main/unit-of-measures/',
          title_fields: ['plural_name'],
          description_fields: ['short_name'],
        },
      },
    },
  ],
}
