import React, { useState } from 'react'
import { Form, Grid, Header, Button, Input } from 'semantic-ui-react'
import mainLogo from '../../../img/main-logo.webp'
import { useNavigate, useParams } from 'react-router-dom'
import useAxios from '../../../lib/auth/useAxios'
import env from '../../../env'

const UploadInfo = (props) => {
  const order = props.order
  const supplier = props.supplier
  const buyer = props.buyer
  const setOpenUploadInformationModal = props.setOpenUploadInformationModal
  const { stringToken } = useParams()
  const navigate = useNavigate()
  const axios = useAxios()
  const [number, setNumber] = useState(null)

  const handleUpdateNumber = (value) => {
    if (value !== null || value !== '' || value !== undefined) {
      setNumber(value)
    } else {
      setNumber(null)
    }
  }

  const handleSubmit = async () => {
    if (window.location.pathname.includes('supplier-info') || supplier?.id) {
      if (stringToken) {
        await axios.patch(`/main/orders/${stringToken}/?tenant_aware=false`, {
          so_number: number,
        })
        setOpenUploadInformationModal(false)
        if (
          !window.location.pathname.includes(
            `/portal/supplier/orders/${stringToken}/active`
          )
        ) {
          navigate(`/portal/supplier/orders/${stringToken}/active`)
        }
      }
      if (order) {
        await axios.patch(
          `/main/orders/${order?.string_token}/?tenant_aware=false`,
          {
            so_number: number,
          }
        )
        setOpenUploadInformationModal(false)
        if (
          !window.location.pathname.includes(
            `/portal/supplier/orders/${order?.string_token}/active`
          )
        ) {
          navigate(`/portal/supplier/orders/${order?.string_token}/active`)
        }
      }
    }

    if (window.location.pathname.includes('buyer-info') || buyer?.id) {
      if (stringToken) {
        await axios.patch(`/main/orders/${stringToken}/?tenant_aware=false`, {
          po_number: number,
        })
        setOpenUploadInformationModal(false)
        if (
          !window.location.pathname.includes(
            `/portal/buyer/orders/${stringToken}/active`
          )
        ) {
          navigate(`/portal/buyer/orders/${stringToken}/active`)
        }
      }
      if (order) {
        await axios.patch(
          `/main/orders/${order?.string_token}/?tenant_aware=false`,
          {
            po_number: number,
          }
        )
        setOpenUploadInformationModal(false)
        if (
          !window.location.pathname.includes(
            `/portal/buyer/orders/${order?.string_token}/active`
          )
        ) {
          navigate(`/portal/buyer/orders/${order?.string_token}/active`)
        }
      }
    }
  }

  return (
    <Grid stackable id="upload-information" style={{ height: '70vh' }}>
      <Grid.Column
        width={16}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'inherit',
          padding: '3rem',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '300px', marginBottom: '3rem' }}>
            <img src={mainLogo} style={{ width: 'inherit' }} alt="" />
          </div>
          <Header as={'h2'} style={{ marginBottom: '3rem' }}>
            Submit Information
          </Header>
          <Form
            style={{
              display: 'flex',
              width: '30%',
              marginBottom: '5rem',
            }}
          >
            <Form.Field>
              <label>
                {(window.location.pathname.includes('buyer-info') || buyer) &&
                  'PO Number'}
                {(window.location.pathname.includes('supplier-info') ||
                  supplier) &&
                  'SO Number'}
              </label>
              <Input
                placeholder={
                  ((window.location.pathname.includes('buyer-info') || buyer) &&
                    'PO Number') ||
                  ((window.location.pathname.includes('supplier-info') ||
                    supplier) &&
                    'SO Number')
                }
                type="text"
                onChange={(e, d) => handleUpdateNumber(d.value)}
              />
            </Form.Field>
          </Form>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={!number}
            color="primary"
            style={{
              width: '30%',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </div>
      </Grid.Column>
    </Grid>
  )
}

export default UploadInfo
