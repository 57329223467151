import React, { useEffect, useState } from 'react'
import {
  Button,
  Table,
  Modal,
  Segment,
  Grid,
  Header,
  Divider,
  Loader,
} from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import CargoUnitInstance from '../../supplier/Orders/CargoUnitInstance'
import MickeyForm from '../../../components/Forms/MickeyForm'
import BuyerOrderMap from '../../buyer/Orders/BuyerOrderMap'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { green } from '@mui/material/colors'
import { Checks, TrashSimple } from 'phosphor-react'
import env from '../../../env'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import { hasPermission } from '../../../lib/utils/utils'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import './AdminOrder.css'
import { useSelector } from 'react-redux'
import {
  formatPercentage,
  formatTotalPrice,
  hasPricing,
} from '../../../lib/utils/utils'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '-5px' }}>
      <Box sx={{ paddingRight: '10px' }}>
        <Typography
          variant="body2"
          style={{
            color: props?.value >= 100 ? env.REACT_APP_PRIMARY_COLOR : 'black',
            fontWeight: props?.value >= 100 ? 'bold' : '',
          }}
        >
          {props.fraction}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          style={{
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          }}
          variant="determinate"
          {...props}
        />
      </Box>
    </Box>
  )
}
const AdminOrderFulfillmentSelectedRow = (props) => {
  const user = useSelector((state) => state.user.user)
  const axios = useAxios()
  const [reloadTasks, setReloadTasks] = useState()
  const [fulfillmentTasks, setFulfillmentTasks] = useState(false)
  const [fulfillmentTasksCompleted, setFulfillmentTasksCompleted] =
    useState(false)
  const [selectedTab, setSelectedTab] = useState('Edit')
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false)
  const [cargoUnitInstancePhotos, setCargoUnitInstancePhotos] = useState([])
  const [loadingPhotos, setLoadingPhotos] = useState(false)
  const [openPhotoModal, setOpenPhotoModal] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(null)
  const primaryPricing = hasPricing(user, 'primary')
  const [selectedPriceType, setSelectedPriceType] = useState(
    primaryPricing ? 'Primary Delivered Price' : 'Delivered Price'
  )
  let dropdownOptions = []
  if (primaryPricing) {
    dropdownOptions = [
      {
        key: 'Primary Delivered Price',
        value: 'Primary Delivered Price',
        text: 'Primary Delivered Price',
      },
      {
        key: 'Secondary Delivered Price',
        value: 'Secondary Delivered Price',
        text: 'Secondary Delivered Price',
      },
    ]
  } else {
    dropdownOptions = [
      {
        key: 'Delivered Price',
        value: 'Delivered Price',
        text: 'Delivered Price',
      },
    ]
  }

  useEffect(() => {
    if (props.updateTotalTruckingPrice) {
      props.updateTotalTruckingPrice()
    }
  }, [props.cargoUnitInstance?.trucking_price])

  async function removeTask(taskId) {
    let res = await axios.delete(`/main/admin-tasks/${taskId}/`)
    setReloadTasks(true)
  }

  async function getFulfillmentTasks() {
    let res = await axios.get(`/main/admin-tasks/`, {
      params: {
        cargo_unit_instance_id: props.selectedCargoUnitInstance?.id,
      },
    })
    setFulfillmentTasks(res?.data?.results)
    setFulfillmentTasksCompleted(
      res?.data?.results?.filter((res) => {
        return res.complete
      }).length
    )
    setReloadTasks(false)
  }

  useEffect(() => {
    if (props.selectedCargoUnitInstance?.id) {
      getFulfillmentTasks()
    }
  }, [props.selectedCargoUnitInstance])

  useEffect(() => {
    if (reloadTasks) {
      getFulfillmentTasks()
    }
  }, [reloadTasks])

  useEffect(() => {
    setLoadingPhotos(true)
    const getPhotos = async () => {
      let res = await axios.get('/main/cargo-unit-instance-photos/', {
        params: {
          cui_id: props.selectedCargoUnitInstance.id,
        },
      })
      if (res?.data?.results) {
        setCargoUnitInstancePhotos(res.data.results)
        setLoadingPhotos(false)
      }
    }
    getPhotos()
  }, [props.selectedCargoUnitInstance.id])

  async function updateTaskCompletion(task) {
    let newVal = !task.complete
    let tasksCopy = [...fulfillmentTasks]
    tasksCopy.forEach((t) => {
      if (task.id === t.id) {
        t.complete = newVal
      }
    })
    setFulfillmentTasks(tasksCopy)
    setFulfillmentTasksCompleted(
      tasksCopy?.filter((res) => {
        return res.complete
      }).length
    )
    axios.patch(`/main/admin-tasks/${task?.id}/`, {
      complete: newVal,
    })
  }

  return (
    <>
      <Table.Row className={'fulfillment-row selected'}>
        <Table.Cell colSpan={16}>
          <div>
            <div>
              <div
                className="exchange-table-heading"
                style={{ marginBottom: '15px' }}
              >
                <div className="commodities-wrapper">
                  <Header
                    as={'h2'}
                    className={
                      selectedTab === 'Edit'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={selectedTab === 'Edit' ? 'primary' : 'black'}
                    onClick={() => setSelectedTab('Edit')}
                    style={{
                      margin: '0 1rem 0rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Edit Fulfillment
                  </Header>
                  <Header
                    as={'h2'}
                    className={
                      selectedTab === 'Materials'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={selectedTab === 'Materials' ? 'primary' : 'black'}
                    onClick={() => setSelectedTab('Materials')}
                    style={{
                      margin: '0 1rem 0rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Materials
                  </Header>
                  <Header
                    as={'h2'}
                    className={
                      selectedTab === 'Tasks'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={selectedTab === 'Tasks' ? 'primary' : 'black'}
                    onClick={() => setSelectedTab('Tasks')}
                    style={{
                      margin: '0 1rem 0rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Tasks
                  </Header>
                  <Header
                    as={'h2'}
                    className={
                      selectedTab === 'Margins'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={selectedTab === 'Margins' ? 'primary' : 'black'}
                    onClick={() => setSelectedTab('Margins')}
                    style={{
                      margin: '0 1rem 0rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Margins
                  </Header>
                  <Header
                    as={'h2'}
                    className={
                      selectedTab === 'Map'
                        ? 'commodity-header selected'
                        : 'commodity-header'
                    }
                    color={selectedTab === 'Map' ? 'primary' : 'black'}
                    onClick={() => setSelectedTab('Map')}
                    style={{
                      margin: '0 1rem 0rem 0',
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    }}
                  >
                    Map
                  </Header>
                  {hasPermission(user, 'Read Document') && (
                    <Header
                      as={'h2'}
                      className={
                        selectedTab === 'Documents'
                          ? 'commodity-header selected'
                          : 'commodity-header'
                      }
                      color={selectedTab === 'Documents' ? 'primary' : 'black'}
                      onClick={() => setSelectedTab('Documents')}
                      style={{
                        margin: '0 1rem 0rem 0',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      }}
                    >
                      Documents
                    </Header>
                  )}

                  {hasPermission(user, 'Read Document') && (
                    <Header
                      as={'h2'}
                      className={
                        selectedTab === 'Photos'
                          ? 'commodity-header selected'
                          : 'commodity-header'
                      }
                      color={selectedTab === 'Photos' ? 'primary' : 'black'}
                      onClick={() => setSelectedTab('Photos')}
                      style={{
                        margin: '0 1rem 0rem 0',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      }}
                    >
                      Proof of Delivery
                    </Header>
                  )}
                </div>
              </div>
              {selectedTab === 'Margins' && primaryPricing && (
                <MickeyDropdown
                  placeholder="Select price type"
                  defaultValue={selectedPriceType}
                  results={dropdownOptions}
                  style={{ width: '240px' }}
                  onChange={(e, d) => setSelectedPriceType(d.value)}
                />
              )}
            </div>
          </div>

          {selectedTab === 'Edit' && (
            <div>
              <CargoUnitInstance
                cargoUnitInstance={props.cargoUnitInstance}
                setCargoUnitInstance={props.setCargoUnitInstance}
                setRefreshPizzaTracker={props.setRefreshPizzaTracker}
                setReload={props.setReload}
                reload={props.reload}
                order={props.order}
                orderId={props.orderId}
              />
            </div>
          )}
          {selectedTab === 'Documents' && (
            <div>
              <ESignRequestsTable
                cargoUnitInstanceId={props.cargoUnitInstance.id}
                documentName="Rate Confirmation"
                setReload={props.setReload}
                reload={props.reload}
                popupPosition="bottom left"
                hideHeader
                actions
                discludeOrder
                generationDescription
              />
            </div>
          )}
          {selectedTab === 'Tasks' && (
            <Grid stackable>
              <Grid.Column width={16}>
                <Segment>
                  <Header as={'h3'} style={{ margin: '20px' }}>
                    Cargo Tasks
                    <Box
                      sx={{
                        width: '200px',
                        display: 'inline-block',
                        marginLeft: '25px',
                        marginTop: '-5px',
                      }}
                    >
                      <LinearProgressWithLabel
                        fraction={`${fulfillmentTasksCompleted}/${fulfillmentTasks?.length}`}
                        color={'success'}
                        variant="determinate"
                        value={
                          (fulfillmentTasksCompleted /
                            fulfillmentTasks?.length) *
                          100
                        }
                      />
                    </Box>
                    <Button
                      onClick={() => {
                        setOpenAddTaskModal(true)
                      }}
                      color={'primary'}
                      style={{
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        float: 'right',
                      }}
                      content={'Add Task'}
                    />
                  </Header>
                  <Divider />
                  <MickeyTable
                    headerRow={[
                      '',
                      'Description',
                      'Assigned To',
                      'Due Date',
                      '',
                    ]}
                    style={{
                      marginTop: '15px',
                      margin: '20px',
                      clear: 'right',
                    }}
                    basicSegment
                    data={fulfillmentTasks}
                    renderBodyRow={(data, index) => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            <Checkbox
                              color={'primary'}
                              checked={data?.complete}
                              sx={{
                                color: green[800],
                                '&.Mui-checked': {
                                  color: green[600],
                                },
                                '& .MuiSvgIcon-root': { fontSize: 28 },
                              }}
                              onClick={(e, d) => {
                                updateTaskCompletion(data)
                              }}
                            />
                          </Table.Cell>
                          <Table.Cell>{data?.name}</Table.Cell>
                          <Table.Cell>
                            {data?.assignee?.first_name}{' '}
                            {data?.assignee?.last_name}
                          </Table.Cell>
                          <Table.Cell>{data?.due_date}</Table.Cell>
                          <Table.Cell>
                            {data?.removable && (
                              <TrashSimple
                                className={'grey-hover'}
                                onClick={() => {
                                  removeTask(data?.id)
                                }}
                                size={20}
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )
                    }}
                    emptyIconHtml={<Checks size={40} />}
                    emptyMessage={'Fulfillment tasks will populate here'}
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          )}
          {selectedTab === 'Map' && (
            <Segment vertical>
              <BuyerOrderMap
                cargoUnitInstance={props.selectedCargoUnitInstance}
                supplier={props.supplier}
              />
            </Segment>
          )}
          {selectedTab === 'Photos' && (
            <Segment
              vertical
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                padding: '3rem',
              }}
            >
              {loadingPhotos && (
                <div style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
                  <Loader active />
                </div>
              )}
              {!loadingPhotos && cargoUnitInstancePhotos?.length <= 0 && (
                <MickeyPlaceholder
                  style={{ width: '100%' }}
                  icon={'photo'}
                  message={'No proof of delivery photos.'}
                />
              )}
              {!loadingPhotos &&
                cargoUnitInstancePhotos?.map((photo, index) => {
                  return (
                    <img
                      onClick={() => {
                        setPhotoIndex(index)
                        setOpenPhotoModal(true)
                      }}
                      src={photo.image}
                      style={{
                        height: '250px',
                        width: 'auto',
                        marginRight: '4rem',
                      }}
                    />
                  )
                })}
            </Segment>
          )}
          {selectedTab === 'Materials' && (
            <MickeyTable
              headerRow={[
                'Product',
                'Estimated Quantity',
                'Shipped Quantity',
                'Delivered Quantity',
              ]}
              url={'/main/cargo-container-items/'}
              urlParams={{
                cargo_unit_instance_id: props.selectedCargoUnitInstance?.id,
              }}
              renderBodyRow={(data, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo
                        orderItem={{ product_variant: data?.product_variant }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {data?.quantity} {data?.unit?.short_name}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.shipped_quantity} {data?.shipped_uom?.short_name}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.delivered_quantity}{' '}
                      {data?.delivered_uom?.short_name}
                    </Table.Cell>
                  </Table.Row>
                )
              }}
              emptyIconHtml={<Checks size={40} />}
              emptyMessage={'Items will populate here'}
            />
          )}
          {selectedTab === 'Margins' && (
            <MickeyTable
              headerRow={[
                'Supplier Price',
                selectedPriceType,
                'Trucking Price',
                'Supplier Quantity',
                'Buyer Quantity',
                'Margin',
                'Margin %',
              ]}
              url={'/main/cargo-container-items/get_margin/'}
              urlParams={{
                cargo_unit_instance_id: props.selectedCargoUnitInstance?.id,
                primary_pricing: primaryPricing,
                selected_price_type: selectedPriceType,
              }}
              renderBodyRow={(data, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>{formatTotalPrice(data?.price)}</Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(data?.buyer_price)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(data?.trucking_price)}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.quantity} {data?.unit_short_name}
                    </Table.Cell>
                    <Table.Cell>
                      {data?.buyer_quantity} {data?.buyer_unit_short_name}
                    </Table.Cell>
                    <Table.Cell>{data?.margin}</Table.Cell>
                    <Table.Cell>
                      {formatPercentage(data?.margin_per)}
                    </Table.Cell>
                  </Table.Row>
                )
              }}
              emptyIconHtml={<Checks size={40} />}
              emptyMessage={'Items will populate here'}
            />
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => setOpenAddTaskModal(false)}
        onOpen={() => setOpenAddTaskModal(true)}
        open={openAddTaskModal}
      >
        <Modal.Header>Add New Fulfillment Task</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AddTaskForm'}
            model={'Task'}
            url={'/main/admin-tasks/'}
            tenantAware={false}
            values={{
              cargo_unit_instance_id: props.selectedCargoUnitInstance?.id,
              removable: true,
            }}
            handleForm={() => {
              setOpenAddTaskModal(false)
              setReloadTasks(true)
            }}
            buttonLabel={'Add Task'}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenPhotoModal(false)}
        onOpen={() => setOpenPhotoModal(true)}
        open={openPhotoModal}
        size="large"
      >
        <Modal.Header>Image #{photoIndex + 1}</Modal.Header>
        <Modal.Content>
          {photoIndex !== null && (
            <img
              src={cargoUnitInstancePhotos[photoIndex]?.image}
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default AdminOrderFulfillmentSelectedRow
