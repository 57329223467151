import React from 'react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import './MXPrivacyPolicy.css'
const MXPrivacyPolicy = () => {
  const wd = useWindowDimensions()
  return (
    <div
      id="privacy-policy"
      style={{
        padding: wd.width <= 991 ? '2rem' : '6rem 30% 6rem 30%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        Protecting your private information is our priority. This Statement of
        Privacy applies to marketplace.mickey.io, and Mickey Group Inc. and
        governs data collection and usage. For the purposes of this Privacy
        Policy, unless otherwise noted, all references to Mickey Group Inc.
        include marketplace.mickey.io and Mickey. The Mickey website is a
        marketplace.mickey.io site. By using the Mickey website, you consent to
        the data practices described in this statement.
      </p>
      <h3>Collection of your Personal Information</h3>
      <p>
        In order to better provide you with products and services offered,
        Mickey may collect personally identifiable information, such as your:
      </p>
      <ul>
        <li>First and Last Name</li>
        <li>Mailing Address</li>
        <li>Phone Number</li>
        <li>Job Title</li>
        <li>Physical Locations</li>
      </ul>
      <p>
        If you purchase Mickey's products and services, we collect billing and
        credit card information. This information is used to complete the
        purchase transaction.
      </p>
      <p>
        We do not collect any personal information about you unless you
        voluntarily provide it to us. However, you may be required to provide
        certain personal information to us when you elect to use certain
        products or services. These may include: (a) registering for an account;
        (b) entering a sweepstakes or contest sponsored by us or one of our
        partners; (c) signing up for special offers from selected third parties;
        (d) sending us an email message; (e) submitting your credit card or
        other payment information when ordering and purchasing products and
        services. To wit, we will use your information for, but not limited to,
        communicating with you in relation to services and/or products you have
        requested from us. We also may gather additional personal or
        non-personal information in the future.
      </p>
      <h3>Use of your Personal Information</h3>
      <p>
        Mickey collects and uses your personal information to operate and
        deliver the services you have requested.
      </p>
      <p>
        Mickey may also use your personally identifiable information to inform
        you of other products or services available from Mickey and its
        affiliates.
      </p>
      <h3>Sharing Information with Third Parties</h3>
      <p>
        Mickey does not sell, rent or lease its customer lists to third parties.
      </p>
      <p>
        Mickey may, from time to time, contact you on behalf of external
        business partners about a particular offering that may be of interest to
        you. In those cases, your unique personally identifiable information
        (e-mail, name, address, telephone number) is not transferred to the
        third party. Mickey may share data with trusted partners to help perform
        statistical analysis, send you email or postal mail, provide customer
        support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide these
        services to Mickey, and they are required to maintain the
        confidentiality of your information.
      </p>
      <p>
        Mickey may disclose your personal information, without notice, if
        required to do so by law or in the good faith belief that such action is
        necessary to: (a) conform to the edicts of the law or comply with legal
        process served on Mickey or the site; (b) protect and defend the rights
        or property of Mickey; and/or (c) act under exigent circumstances to
        protect the personal safety of users of Mickey, or the public.
      </p>
      <h3>Tracking User Behavior</h3>
      <p>
        Mickey may keep track of the websites and pages our users visit within
        Mickey, in order to determine what Mickey services are the most popular.
        This data is used to deliver customized content and advertising within
        Mickey to customers whose behavior indicates that they are interested in
        a particular subject area.
      </p>
      <h3>Automatically Collected Information</h3>
      <p>
        Information about your computer hardware and software may be
        automatically collected by Mickey. This information can include: your IP
        address, browser type, domain names, access times and referring website
        addresses. This information is used for the operation of the service, to
        maintain quality of the service, and to provide general statistics
        regarding use of the Mickey website.
      </p>
      <h3>Use of Cookies</h3>
      <p>
        The Mickey website may use "cookies" to help you personalize your online
        experience. A cookie is a text file that is placed on your hard disk by
        a web page server. Cookies cannot be used to run programs or deliver
        viruses to your computer. Cookies are uniquely assigned to you, and can
        only be read by a web server in the domain that issued the cookie to
        you.
      </p>
      <p>
        One of the primary purposes of cookies is to provide a convenience
        feature to save you time. The purpose of a cookie is to tell the Web
        server that you have returned to a specific page. For example, if you
        personalize Mickey pages, or register with Mickey site or services, a
        cookie helps Mickey to recall your specific information on subsequent
        visits. This simplifies the process of recording your personal
        information, such as billing addresses, shipping addresses, and so on.
        When you return to the same Mickey website, the information you
        previously provided can be retrieved, so you can easily use the Mickey
        features that you customized.
      </p>
      <p>
        You have the ability to accept or decline cookies. Most Web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. If you choose to decline
        cookies, you may not be able to fully experience the interactive
        features of the Mickey services or websites you visit.
      </p>
      <h3>Links</h3>
      <p>
        This website contains links to other sites. Please be aware that we are
        not responsible for the content or privacy practices of such other
        sites. We encourage our users to be aware when they leave our site and
        to read the privacy statements of any other site that collects
        personally identifiable information.
      </p>
      <h3>Right to Deletion</h3>
      <p>
        Subject to certain exceptions set out below, on receipt of a verifiable
        request from you, we will:
      </p>
      <ul>
        <li>Delete your personal information from our records; and</li>
        <li>
          Direct any service providers to delete your personal information from
          their records
        </li>
      </ul>
      <p>
        Please note that we may not be able to comply with requests to delete
        your personal information if it is necessary to:
      </p>
      <ul>
        <li>
          Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, provide a good or service
          requested by you, or reasonably anticipated within the context of our
          ongoing business relationship with you, or otherwise perform a
          contract between you and us;
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity; or prosecute those responsible for
          that activity;
        </li>
        <li>
          Debug to identify and repair errors that impair existing intended
          functionality;
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          his or her right of free speech, or exercise another right provided
          for by law;
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act;
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when our deletion of the
          information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed
          consent;
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with your
          expectations based on your relationship with us;
        </li>
        <li>Comply with an existing legal obligation; or</li>
        <li>
          Otherwise use your personal information, internally, in a lawful
          manner that is compatible with the context in which you provided the
          information
        </li>
      </ul>
      <h3>Children Under Thirteen</h3>
      <p>
        Mickey does not knowingly collect personally identifiable information
        from children under the age of thirteen. If you are under the age of
        thirteen, you must ask your parent or guardian for permission to use
        this website.
      </p>
      <h3>E-mail Communications</h3>
      <p>
        From time to time, Mickey may contact you via email for the purpose of
        providing announcements, promotional offers, alerts, confirmations,
        surveys, and/or other general communication. In order to improve our
        Services, we may receive a notification when you open an email from
        Mickey or click on a link therein.
      </p>
      <p>
        If you would like to stop receiving marketing or promotional
        communications via email from Mickey, you may opt out of such
        communications by send email with the words "UNSUBSCRIBE" to
        info@mickeytrading.com.
      </p>
      <h3>External Data Storage Sites</h3>
      <p>
        We may store your data on servers provided by third party hosting
        vendors with whom we have contracted.
      </p>
      <h3>Changes to this Statement</h3>
      <p>
        Mickey reserves the right to change this Privacy Policy from time to
        time. We will notify you about significant changes in the way we treat
        personal information by sending a notice to the primary email address
        specified in your account, by placing a prominent notice on our website,
        and/or by updating any privacy information. Your continued use of the
        website and/or Services available after such modifications will
        constitute your: (a) acknowledgment of the modified Privacy Policy; and
        (b) agreement to abide and be bound by that Policy.
      </p>
      <h3>Contact Information</h3>
      <p>
        Mickey welcomes your questions or comments regarding this Statement of
        Privacy. If you believe that Mickey has not adhered to this Statement,
        please contact Mickey at:
      </p>
      Mickey Group Inc. <br />
      110 Bank St. 1K <br />
      New York, New York 10014 <br />
      <br />
      Email Address:
      <br />
      info@mickeytrading.com
      <br />
      <br />
      Telephone number:
      <br />
      (510) 290-4972
      <br />
      <br />
      Effective as of May 16, 2022
      <br />
    </div>
  )
}

export default MXPrivacyPolicy
