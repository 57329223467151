import { Menu } from 'semantic-ui-react'
import './AdminSales.css'
import {
  Package,
  AddressBook,
  Truck,
  Calendar,
  SquaresFour,
  Chats,
  ListBullets,
} from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AdminSalesDashboard from './AdminSalesDashboard'
import AdminSalesCurrentOrders from './AdminSalesCurrentOrders'
import AdminSalesMyBusinesses from './AdminSalesMyBusinesses'
import AdminSalesMyFulfillment from './AdminSalesMyFulfillment'
import AdminSalesNotes from './AdminSalesNotes'
import AdminSalesCalendar from './AdminSalesCalendar'
import MickeySideBar from '../../../../components/MickeySideBar/MickeySideBar'
import AdminSalesTasks from './AdminSalesTasks'
import useAxios from '../../../../lib/auth/useAxios'

const AdminSales = (props) => {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const [activeButton, setActiveButton] = useState('Dashboard')
  const userFullName = `${user?.first_name} ${user?.last_name}`
  const [activeTaskCount, setActiveTaskCount] = useState(0)

  useEffect(() => {
    async function getActiveTaskCount() {
      let res = await axios.get(`/main/admin-tasks/get_active_task_count/`)
      if (res?.data) {
        setActiveTaskCount(res.data?.count)
      }
    }

    getActiveTaskCount()
  }, [])

  return (
    <div id="admin-sales">
      <MickeySideBar
        as={Menu}
        animation="Uncover"
        icon="labeled"
        vertical
        visible={true}
        width="thin"
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        sidebarContent={{
          header: {
            icon: (
              <div
                className="profile-icon"
                style={{
                  cursor: 'pointer',
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#3d3d3d',
                  borderRadius: '17.5px',
                  width: '35px',
                  height: '35px',
                }}
              >
                {user?.first_name ? user?.first_name[0] : ''}
                {user?.last_name ? user?.last_name[0] : ''}
              </div>
            ),
            header:
              userFullName?.length > 10
                ? `${userFullName.slice(0, 10)}...`
                : userFullName,
            sub_header: 'Sales',
          },
          sections: {
            SALES: {
              items: [
                {
                  icon: (
                    <SquaresFour size={25} style={{ marginRight: '5px' }} />
                  ),
                  text: 'Dashboard',
                  permission: true,
                },
                {
                  icon: <Package size={25} style={{ marginRight: '5px' }} />,
                  text: 'Current Deals',
                  permission: true,
                },
                {
                  icon: (
                    <AddressBook size={25} style={{ marginRight: '5px' }} />
                  ),
                  text: 'My Businesses',
                  permission: true,
                },
                {
                  icon: <Truck size={25} style={{ marginRight: '5px' }} />,
                  text: 'My Fulfillment',
                },
                {
                  icon: <Chats size={25} style={{ marginRight: '5px' }} />,
                  text: 'My Sales Log',
                  permission: true,
                },
                {
                  icon: <Calendar size={25} style={{ marginRight: '5px' }} />,
                  text: 'Calender',
                  permission: true,
                },
                {
                  icon: (
                    <ListBullets size={25} style={{ marginRight: '5px' }} />
                  ),
                  text: 'Tasks',
                  permission: true,
                  html: <span>{activeTaskCount}</span>,
                },
              ],
            },
          },
        }}
      />
      <div className="admin-sales-tab">
        {activeButton === 'Dashboard' && (
          <AdminSalesDashboard userId={user?.id} />
        )}
        {activeButton === 'Current Deals' && (
          <AdminSalesCurrentOrders salespersonId={user?.id} />
        )}
        {activeButton === 'My Businesses' && (
          <AdminSalesMyBusinesses salespersonId={user?.id} />
        )}
        {activeButton === 'My Fulfillment' && (
          <AdminSalesMyFulfillment salespersonId={user?.id} />
        )}
        {activeButton === 'My Sales Log' && (
          <AdminSalesNotes salespersonId={user?.id} />
        )}
        {activeButton === 'Calender' && (
          <AdminSalesCalendar salespersonId={user?.id} />
        )}
        {activeButton === 'Tasks' && (
          <AdminSalesTasks
            salespersonId={user?.id}
            setActiveTaskCount={setActiveTaskCount}
          />
        )}
      </div>
    </div>
  )
}

export default AdminSales
