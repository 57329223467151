import {
  requiredEmailValidator,
  requiredPhoneNumberValidator,
} from '../components/Forms/validators'

export const AddTaskForm = {
  model: 'Task',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'text',
          label: 'Task Description',
          max_length: 500,
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        assignee: {
          type: 'dropdown search',
          url: '/main/custom-users/',
          required: false,
          title_fields: ['first_name', 'last_name'],
          params: {
            tenant_aware: false,
            is_admin: true,
            extra_light_serializer: 'true',
          },
          description_fields: [],
        },
        due_date: {
          label: 'Due Date',
          type: 'date',
        },
        completed_field: {
          label: 'Completed On Field',
          type: 'dropdown',
        },
      },
    },
  ],
}
