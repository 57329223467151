import React from 'react'
import { Segment, Header, Icon } from 'semantic-ui-react'

const MickeyPlaceholder = (props) => {
  return (
    <Segment placeholder style={{ ...props.style }}>
      <Header icon>
        {props.iconHtml}
        <Icon name={props.icon} />
        {props.message}
        {props.button}
      </Header>
    </Segment>
  )
}

export default MickeyPlaceholder
