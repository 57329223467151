import './AdminOrderBook.css'
import { getBezierPath } from 'reactflow'
import { useEffect, useState } from 'react'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useWebsocket } from '../../../../hooks/useWebsocket'
import { Button, Modal } from 'semantic-ui-react'

export default function AdminOrderBookEdge({
  id,
  sourceX,
  selected,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const { ws, createTradeWsMessage } = useWebsocket()
  const [trucking, setTrucking] = useState()
  const tenant = useSelector((state) => state.globals.tenant)
  const [truckingWithDivisor, setTruckingWithDivisor] = useState()
  const [mickeyTakePercent, setMickeyTakePercent] = useState()
  const [mickeyTakeColor, setMickeyTakeColor] = useState()
  const [truckingCompany, setTruckingCompany] = useState()
  const [openMatchModal, setOpenMatchModal] = useState()
  const [tbp, setTbp] = useState()
  const [tsp, setTsp] = useState()
  const [divisor, setDivisor] = useState()

  async function onEdgeClick() {
    const messageId = uuid()
    let message = {
      tenant_id: tenant.id,
      message_id: messageId,
      create_trade: [
        {
          ask_order_id: data?.ask?.id,
          bid_order_id: data?.bid?.id,
          order_book_id: data?.eo?.id,
        },
      ],
    }
    ws.send(createTradeWsMessage(message))
    toast.success('Order submitted successfully.')
  }

  useEffect(() => {
    data?.edges?.forEach((edge) => {
      if (edge?.ask === data.ask?.id && edge?.bid === data.bid?.id) {
        setTrucking(edge?.rate)
        setDivisor(edge.divisor)
        setTbp(edge.tbp)
        setTsp(edge.tsp)
        setTruckingCompany(edge.trucking_company)
        setTruckingWithDivisor(edge?.rate_with_divisor)
        setMickeyTakePercent(edge?.mickey_take_percent)
        if (edge?.mickey_take_percent > 4) {
          setMickeyTakeColor('red')
        } else if (edge?.mickey_take_percent > 3) {
          setMickeyTakeColor('#FEAB12')
        } else if (edge?.mickey_take_percent > 2) {
          setMickeyTakeColor('#BEAB12')
        } else if (edge?.mickey_take_percent > 1) {
          setMickeyTakeColor('lightgreen')
        } else if (edge?.mickey_take_percent > 0) {
          setMickeyTakeColor('skyblue')
        } else {
          setMickeyTakeColor('blue')
        }
      }
    })
  }, [data.edges])

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          stroke: selected ? 'lightgrey' : mickeyTakeColor,
          strokeWidth: 3,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {!selected && (
        <>
          <foreignObject
            width={20}
            height={20}
            x={labelX - 10 / 2}
            y={labelY - 10 / 2}
            className="edgebutton-foreignobject-small"
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <div></div>
          </foreignObject>
        </>
      )}
      {selected && (
        <>
          <foreignObject
            width={500}
            height={40}
            x={labelX - 400 / 2}
            y={labelY - 40 / 2}
            className="edgebutton-foreignobject"
            requiredExtensions="http://www.w3.org/1999/xhtml"
          >
            <div style={{ fontSize: '10px' }}>
              <span style={{ paddingLeft: '9px', paddingRight: '2px' }}>
                <b>Trucking</b>: {formatTotalPrice(trucking)} ({truckingCompany}
                )
              </span>
              <span style={{ paddingRight: '2px' }}>
                <b>TWD</b>: {formatTotalPrice(truckingWithDivisor)}
              </span>
              <span style={{ paddingRight: '2px' }}>
                <b>Divisor</b>: {divisor}
              </span>
              <span style={{ paddingRight: '2px' }}>
                <b>Take</b>: {mickeyTakePercent}%
              </span>
              <span style={{ paddingRight: '2px' }}>
                <b>TBP</b>: {formatTotalPrice(tbp)}
              </span>
              <span style={{ paddingRight: '2px' }}>
                <b>TSP</b>: {formatTotalPrice(tsp)}
              </span>
              <button
                className="edgebutton"
                onClick={(event) => setOpenMatchModal(true)}
              >
                Match
              </button>
            </div>
          </foreignObject>
        </>
      )}
      <Modal
        onClose={() => setOpenMatchModal(false)}
        open={openMatchModal}
        size={'mini'}
      >
        <Modal.Header>
          Are you sure you want to match these listings?
        </Modal.Header>
        <Modal.Content>
          <div style={{ padding: '5px' }}>
            <b>Buyer Bid</b>:{' '}
            {`${data?.bid?.business?.name} at ${formatTotalPrice(
              data?.bid?.price ? data?.bid?.price : 0
            )}`}
          </div>
          <div style={{ padding: '5px' }}>
            <b>Supplier Ask</b>:{' '}
            {`${data?.ask?.business?.name} at ${formatTotalPrice(
              data?.ask?.price ? data?.ask?.price : 0
            )}`}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => onEdgeClick()}>
            Confirm
          </Button>
          <Button color="red" onClick={() => setOpenMatchModal(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}
