import { Table } from 'semantic-ui-react'
import { formatTotalPrice } from '../../../lib/utils/utils'

const AdminOrderLedgerRow = (props) => {
  const { ledgerItem } = props
  return (
    <>
      <Table.Row selectable onClick={props.onClick}>
        <Table.Cell>{ledgerItem?.balance_id}</Table.Cell>
        <Table.Cell>{ledgerItem?.balance_payment_method}</Table.Cell>
        <Table.Cell>
          {ledgerItem?.orderItem?.balance_payment_datetime}
        </Table.Cell>
        <Table.Cell>{ledgerItem?.balance_status}</Table.Cell>
        <Table.Cell>
          {ledgerItem?.supplier && ledgerItem?.supplier?.business?.name}
          {ledgerItem?.buyer && ledgerItem?.buyer?.business?.name}
        </Table.Cell>
        <Table.Cell
          positive={ledgerItem?.type === 'Receipt'}
          negative={ledgerItem?.type === 'Disbursement'}
        >
          {ledgerItem?.type}
        </Table.Cell>
        <Table.Cell>
          {formatTotalPrice(ledgerItem?.amount_transferred)}
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default AdminOrderLedgerRow
