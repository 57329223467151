import CustomLength from './CustomLength'

const CustomAttribute = (props) => {
  const { attributeName, product, handleUpdateCustomValues } = props

  return (
    <>
      {attributeName === 'Length' && (
        <CustomLength
          product={product}
          attributeName={attributeName}
          handleUpdateCustomValues={handleUpdateCustomValues}
        />
      )}
    </>
  )
}

export default CustomAttribute
