export const DashboardItemForm = {
  model: 'DashboardItem',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'text',
        },
        type: {
          type: 'dropdown',
        },
        days: {
          type: 'number',
          label: 'Default Timeframe (# days)',
        },
      },
    },
  ],
}
