import React, { useState } from 'react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { Grid, Modal, Image, Segment } from 'semantic-ui-react'
import env from '../../../env'

function ShopSettings(props) {
  const [uploadLogoModal, setUploadLogoModal] = useState(false)
  const [uploadBannerModal, setUploadBannerModal] = useState(false)
  return (
    <Grid style={{ marginTop: '-30px', marginLeft: '10px' }}>
      <Grid.Column style={{ paddingBottom: '4rem' }}>
        <MickeyForm
          formName={'ShopSettingsForm'}
          model={'Supplier'}
          basicSegment
          instanceId={props.supplier?.id}
          url={'/main/suppliers/'}
        />
        {props.supplier?.business?.logo && (
          <Image
            size={'small'}
            style={{
              marginBottom: '10px',
            }}
            src={
              env.REACT_APP_ENVIRONMENT === 'local'
                ? `http://marketplace.localhost:8002${props.supplier.business.logo}`
                : props.supplier.business.logo
            }
          />
        )}
        {/* <Button
          color={"orange"}
          style={{
            marginBottom: "10px",
            marginTop: "0px",
          }}
          onClick={() => {
            setUploadLogoModal(true);
          }}
        >
          Update Logo
        </Button> */}
        <p>Logo</p>
        <Segment
          style={{
            borderStyle: 'dashed',
            height: '125px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <p>
            Drop files to attach or{' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setUploadLogoModal(true)}
            >
              browse
            </span>
          </p>
        </Segment>
        <p>Homepage Banner</p>
        <Segment
          style={{
            borderStyle: 'dashed',
            height: '125px',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <p>
            Drop files to attach or{' '}
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setUploadBannerModal(true)}
            >
              browse
            </span>
          </p>
        </Segment>
      </Grid.Column>
      <Modal
        onClose={() => setUploadLogoModal(false)}
        onOpen={() => setUploadLogoModal(true)}
        open={uploadLogoModal}
      >
        <Modal.Header>Update Business Logo</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'ShopSettingsLogoForm'}
            model={'Supplier'}
            basicSegment
            url={'/main/update-business-logo/'}
            values={{
              supplier_id: props.supplier?.id,
            }}
            handleForm={() => {
              setUploadLogoModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setUploadBannerModal(false)}
        onOpen={() => setUploadBannerModal(true)}
        open={uploadBannerModal}
      >
        <Modal.Header>Update Homepage Banner</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'ShopSettingsHomepageBannerForm'}
            model={'Supplier'}
            basicSegment
            url={'/main/update-business-banner/'}
            values={{
              supplier_id: props.supplier?.id,
            }}
            handleForm={() => {
              setUploadBannerModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </Grid>
  )
}

export default ShopSettings
