import React, { useState, useEffect } from 'react'
import { Button, Divider } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import 'rc-time-picker/assets/index.css'
import { RangeSlider } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import './Profile.css'
import { Plus, X } from 'phosphor-react'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

function IndividualHour(props) {
  const [loaded, setLoaded] = useState(false)
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update Location')
  const axios = useAxios()
  const location = props.location
  const [value, setValue] = useState([])

  useEffect(() => {
    setValue([
      props.labels.indexOf(props.dayHour.hours.from_hour.toLowerCase()),
      props.labels.indexOf(props.dayHour.hours.to_hour.toLowerCase()),
    ])
    setLoaded(true)
  }, [props.dayHour])

  async function updateLocationBusinessHours(
    locationBusinessHoursId,
    updateData
  ) {
    await axios.patch(
      `/main/location-business-hours/${locationBusinessHoursId.toString()}/`,
      updateData
    )
  }

  useEffect(() => {
    if (loaded && location) {
      const delayDebounceFn = setTimeout(() => {
        updateLocationBusinessHours(props.dayHour.hours.id, {
          from_hour: props.labels[value[0]],
          to_hour: props.labels[value[1]],
        })
      }, 200)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [value])

  return (
    <div style={{ minHeight: '50px' }}>
      <div
        style={{
          position: 'relative',
        }}
      >
        <span
          style={{
            fontSize: '1.2em',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {props.dayHour.day}
          {props.dayHour.hours.active && canUpdate && (
            <Button
              icon={<X size={'20'} />}
              className="toggle-day-button"
              // style={{
              //   float: "right",
              //   background: "transparent",
              //   border: "none",
              //   margin: "0"
              // }}
              onClick={() => {
                let newDayHours = [...props.dayHours.hours]
                newDayHours.forEach((hour) => {
                  if (hour.id === props.dayHour.id) {
                    hour.hours.active = false
                  }
                })

                if (location) {
                  updateLocationBusinessHours(props.dayHour.hours.id, {
                    active: false,
                  })
                  props.setDayHours({
                    hours: newDayHours,
                  })
                }
              }}
            />
          )}
          {!props.dayHour.hours.active && canUpdate && (
            <Button
              className="toggle-day-button"
              // content={"Turn " + props.dayHour.day + " On"}
              content={<Plus size={'20'} />}
              // style={{
              //   marginLeft: "1rem",
              //   padding: ".5rem",
              //   position: "relative",
              //   background: "transparent",
              //   border: "none"
              // }}
              onClick={() => {
                let newDayHours = [...props.dayHours.hours]
                newDayHours.forEach((hour) => {
                  if (hour.id === props.dayHour.id) {
                    hour.hours.active = true
                  }
                })

                if (location) {
                  updateLocationBusinessHours(props.dayHour.hours.id, {
                    active: true,
                  })
                  props.setDayHours({
                    hours: newDayHours,
                  })
                }
              }}
            />
          )}
        </span>
      </div>
      <div style={{ margin: '15px 15px 30px 15px' }}>
        {props.dayHour.hours.active && (
          <RangeSlider
            min={0}
            max={props.labels.length - 1}
            value={value}
            defaultValue={value}
            className="mickey-slider"
            progress
            graduated
            barClassName={'mickey-bar'}
            renderMark={(mark, index) => {
              return (
                <span>
                  {mark === value[0] || mark === value[1]
                    ? props.labels[mark]
                    : ''}
                </span>
              )
            }}
            handleStyle={{
              color: '#fff',
              fontSize: 12,
              width: 32,
              height: 22,
            }}
            tooltip={false}
            onChange={(v) => {
              if (canUpdate) {
                let newDayHours = [...props.dayHours.hours]
                newDayHours.forEach((hour) => {
                  if (hour.id === props.dayHour.id) {
                    hour.hours.from_hour = props.labels[v[0]]
                    hour.hours.to_hour = props.labels[v[1]]
                    hour.value = [v[0], v[1]]
                  }
                })
                setValue([v[0], v[1]])
                props.setDayHours({
                  hours: newDayHours,
                })
              }
            }}
          />
        )}
      </div>
      <Divider hidden />
    </div>
  )
}

export default IndividualHour
