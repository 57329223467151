import { Form, Input, Modal, Segment, Table } from 'semantic-ui-react'
import MickeyDropdown from '../../../../components/MickeyDropdown/MickeyDropdown'
import { useEffect, useState } from 'react'
import MickeyTable from '../../../../components/MickeyTable/MickeyTable'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import OrderItemDetailTwo from '../../../../components/OrderItemDetail/OrderItemDetailTwo'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { X } from 'phosphor-react'
import { formatAddressOneLine } from '../../../../helpers/helpers'
import useAxios from '../../../../lib/auth/useAxios'

function SalesScenarioRow(props) {
  const { data, diviser, margin, unit, priceConversionMultiplier } = props
  const [freight, setFreight] = useState(data.trucking_rate)

  const quoted_price = () => {
    let price =
      data?.price && data?.price > 0 && diviser > 0
        ? (data?.price * diviser - freight) / diviser
        : 0
    if (margin > 0 && price > 0) {
      price = price / (1 + margin / 100)
    }
    let conversion_multiplier =
      priceConversionMultiplier[
        `${data?.unit?.plural_name} -> ${unit?.plural_name}`
      ]
    if (conversion_multiplier && conversion_multiplier > 0 && price > 0) {
      price = price * conversion_multiplier
    }
    return price.toFixed(2)
  }

  return (
    <Table.Row>
      <Table.Cell>
        <OrderItemDetailTwo orderItem={data} />
      </Table.Cell>
      <Table.Cell>{data?.business?.name}</Table.Cell>
      <Table.Cell>{formatAddressOneLine(data?.location)}</Table.Cell>
      <Table.Cell>
        {`${formatTotalPrice(data?.price)}/${data?.unit?.short_name}`}
      </Table.Cell>
      <Table.Cell>
        {data?.distance_in_miles}
        {' miles'}
      </Table.Cell>
      <Table.Cell>
        <Input
          name={'freight'}
          icon={'dollar'}
          iconPosition="left"
          placeholder="Enter Freight"
          min={'0'}
          type={'number'}
          step="0.01"
          size={'small'}
          value={freight}
          onChange={(e, d) => {
            if (d.value < 0) {
              toast.error('Freight value must be possitive')
            } else {
              setFreight(d.value)
            }
          }}
        />
      </Table.Cell>
      <Table.Cell>
        {`${formatTotalPrice(quoted_price())}/${unit?.short_name}`}
      </Table.Cell>
    </Table.Row>
  )
}

function AdminSalesScenarioModal(props) {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const { open, setOpen } = props
  const [supplierId, setSupplierId] = useState()
  const [productId, setProductId] = useState()
  const [locationId, setLocationId] = useState()
  const [unit, setUnit] = useState({})
  const [urlParams, setUrlParams] = useState({})
  const [diviser, setDiviser] = useState(
    user?.dead_weight_threshold ? user?.dead_weight_threshold : 0
  )
  const [margin, setMargin] = useState(0)
  const [priceConversionMultiplier, setPriceConversionMultiplier] = useState({})

  useEffect(() => {
    async function getUOMPriceConvertMultiplier() {
      let res = await axios.get(
        'main/unit-of-measures/price_conversion_multiplier/',
        {
          params: {
            product_id: productId,
          },
        }
      )
      if (res?.data) {
        setPriceConversionMultiplier(res?.data)
      }
    }

    if (productId) {
      getUOMPriceConvertMultiplier()
    }
  }, [productId])

  async function getUnit(unitId) {
    let res = await axios.get(`main/unit-of-measures/${unitId}/`)

    if (res?.data) {
      setUnit(res?.data)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setProductId()
    setSupplierId()
    setUrlParams({})
    setLocationId()
    setUnit({})
    setDiviser(user?.dead_weight_threshold ? user?.dead_weight_threshold : 0)
    setMargin(0)
  }

  return (
    <>
      <Modal
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => handleClose()}
        size={'large'}
        className="sales-scenario-modal"
      >
        <Modal.Header>
          Sales Scenario
          <X size={20} weight="bold" onClick={() => handleClose()} />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Field width={7}>
                <label>
                  Material<span style={{ color: 'indianred' }}> *</span>
                </label>
                <MickeyDropdown
                  autoFocus
                  url="/main/products/"
                  placeholder={'Select Product'}
                  params={{
                    page_size: 25,
                  }}
                  titleFields={['material.name']}
                  descriptionFields={['commodity_form.name', 'commodity.name']}
                  hideDescriptionFieldParenthesis
                  onChange={(e, d) => {
                    setProductId(d.value)
                    setUrlParams({
                      ...urlParams,
                      product_id: d.value,
                    })
                  }}
                />
              </Form.Field>
              <Form.Field width={7}>
                <label>
                  Supplier<span style={{ color: 'indianred' }}> *</span>
                </label>
                <MickeyDropdown
                  placeholder={'Supplier'}
                  url={'/main/suppliers/'}
                  name={'supplier'}
                  value={supplierId}
                  tenantAware={false}
                  titleFields={['business.name']}
                  descriptionFields={[]}
                  onChange={(e, d) => {
                    setSupplierId(d.value)
                    setUrlParams({
                      ...urlParams,
                      supplier_id: d.value,
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>Diviser</label>
                <Input
                  name={'diviser'}
                  min={'0'}
                  type={'number'}
                  step="0.01"
                  value={diviser}
                  onChange={(e, d) => setDiviser(d.value)}
                />
              </Form.Field>
              <Form.Field>
                <label>Margin</label>
                <Input
                  icon={'percent'}
                  name={'margin'}
                  min={'0'}
                  type={'number'}
                  step="0.01"
                  value={margin}
                  onChange={(e, d) => setMargin(d.value)}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  Location<span style={{ color: 'indianred' }}> *</span>
                </label>
                <MickeyDropdown
                  url={'/main/locations/'}
                  name={'locations'}
                  autoComplete="new-password"
                  params={{
                    supplier_id: supplierId,
                    page_size: 25,
                  }}
                  disabled={!supplierId}
                  tenantAware={false}
                  defaultValue={locationId}
                  placeholder={'Select Address'}
                  titleFields={['name', 'address_1']}
                  descriptionFields={['city', 'state', 'zip_code']}
                  onChange={(e, d) => {
                    setLocationId(d.value)
                    setUrlParams({
                      ...urlParams,
                      location_id: d.value,
                    })
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  Unit<span style={{ color: 'indianred' }}> *</span>
                </label>
                <MickeyDropdown
                  url={'/main/unit-of-measures/'}
                  name={'unit_of_measure'}
                  defaultValue={unit?.id ? unit?.id : ''}
                  params={{
                    product_id: productId,
                  }}
                  disabled={!productId}
                  autoComplete="new-password"
                  placeholder={'Select UOM'}
                  titleFields={['plural_name']}
                  descriptionFields={['short_name']}
                  onChange={(e, d) => getUnit(d.value)}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          {supplierId && productId && locationId && unit?.id && (
            <MickeyTable
              headerRow={[
                'Material',
                'Buyer',
                'Buyer Location',
                'Buyer Price',
                'Miles to Buyer',
                'Freight',
                'Quoted Price',
              ]}
              urlParams={urlParams}
              basicSegment
              showHeaderNoData={true}
              url={'/main/exchange-order-price-history/get_sales_scenarios/'}
              hideFooter={false}
              renderBodyRow={(data, index) => {
                return (
                  <SalesScenarioRow
                    data={data}
                    diviser={diviser}
                    margin={margin}
                    unit={unit}
                    priceConversionMultiplier={priceConversionMultiplier}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'Material will populate here'}
            />
          )}
        </Modal.Content>
      </Modal>
    </>
  )
}

export default AdminSalesScenarioModal
