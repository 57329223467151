import { ResponsiveLine } from '@nivo/line'
import _ from 'underscore'
import { ArrowDownRight, ArrowUpRight } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Header, Popup } from 'semantic-ui-react'
import useAxios from '../../../../lib/auth/useAxios'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import InventoryTable from '../../../supplier/Inventory/InventoryTable'
import { timeFormat } from 'd3-time-format'
import './MXProductVariantGraph.css'

const MXProductVariantGraph = (props) => {
  const axios = useAxios()
  const productVariant = props.productVariant
  const [chartData, setChartData] = useState([])
  const [lastBoughtPrice, setLastBoughtPrice] = useState()
  const [lastBoughtPriceUnitName, setLastBoughtPriceUnitName] = useState()
  const [timeWindow, setTimeWindow] = useState('2-weeks')
  const [itemWidthValue, setItemWidthValue] = useState(0)
  const [productVarientList, setProductVarientList] = useState([])
  const [newProductVarientModel, setNewProductVarientModel] = useState(false)
  const [changesSinceLastPurchase, setChangesSinceLastPurchase] = useState()
  const [supplierCount, setSupplierCount] = useState()
  const [truckLoads, setTruckLoads] = useState()

  const options = [
    {
      key: 1,
      value: '2-weeks',
      text: 'Last 2 weeks',
    },
    {
      key: 2,
      value: 'quarter',
      text: 'Last quarter',
    },
    {
      key: 3,
      value: '6-months',
      text: 'Last 6 months',
    },
    {
      key: 4,
      value: 'year',
      text: 'Last year',
    },
  ]

  function getTextWidth(text) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    const width = context.measureText(text).width
    return width
  }

  const getOrderItemData = async () => {
    let res = await axios.get(
      `/main/order-items/get_pv_order_item_chart_data/`,
      {
        params: {
          product_variant_id: productVariant?.id,
          product_variant_list: productVarientList
            ? [...productVarientList, productVariant?.id]
            : [productVariant?.id],
          time_window: timeWindow,
          tenant_aware: false,
        },
      }
    )
    if (res?.data) {
      setLastBoughtPrice(res?.data?.last_bought_price)
      setLastBoughtPriceUnitName(res?.data?.last_bought_price_unit_name)
      setChangesSinceLastPurchase(res?.data?.changes_since_last_purchase)
      setSupplierCount(res?.data?.supplier_count)
      setTruckLoads(res?.data?.truck_loads)
      getChartData(res?.data)
    }
  }

  const getChartData = (data) => {
    let copyChartData = []
    let itemLength = 0

    data?.results?.forEach((result) => {
      let formatedData = _.map(result?.order_item_data, (d, index) => {
        let date = new Date(d['created_at']).toLocaleDateString()
        return {
          x: date,
          y: d[
            'total_delivered_price_of_one_quantity_with_sales_tax_no_trucking_price'
          ],
        }
      })

      let dataSet = {
        id: result['order_item_name'],
        data: formatedData,
      }
      itemLength = Math.max(getTextWidth(result['order_item_name']), itemLength)
      copyChartData = copyChartData ? [...copyChartData, dataSet] : [dataSet]
    })
    setItemWidthValue(itemLength + 65)
    setChartData(copyChartData)
  }

  useEffect(() => {
    if (productVariant?.id) {
      getOrderItemData()
    }
  }, [productVariant?.id, productVarientList?.length, timeWindow])

  const getProductVariant = async (result) => {
    const attributeSelectionIds = result.attribute_selections.map(
      (attributeSelection) => {
        return attributeSelection.attribute_selection_id
      }
    )
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        selection_ids: attributeSelectionIds,
        product_id: result.product.id,
      },
    })
    if (res?.data?.id) {
      setNewProductVarientModel(false)
      let pv_list = productVarientList
        ? [res?.data?.id, ...productVarientList]
        : [res?.data?.id]
      pv_list = [...new Set(pv_list)]
      pv_list = pv_list.filter((id) => id !== productVariant?.id)
      setProductVarientList(pv_list)
    }
  }

  return (
    <>
      <Grid id="prioducts-graph">
        <Grid.Row style={{ marginBottom: '2rem', padding: '5px 30px' }}>
          <Grid.Column
            style={{
              paddingBottom: '10px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div width={2}>
              <Header as={'h1'} className="header-css">
                {lastBoughtPrice && lastBoughtPrice > 0
                  ? `${formatTotalPrice(lastBoughtPrice)}  ${
                      lastBoughtPriceUnitName
                        ? '/ ' + lastBoughtPriceUnitName
                        : ''
                    }`
                  : '-.--'}
              </Header>
              <p> Last Bought Price </p>
            </div>
            <div>
              <Header
                as={'h1'}
                className="header-css"
                style={{
                  color: changesSinceLastPurchase
                    ? changesSinceLastPurchase < 0
                      ? 'red'
                      : 'green'
                    : '',
                }}
              >
                {changesSinceLastPurchase ? (
                  changesSinceLastPurchase < 0 ? (
                    <>
                      {`${changesSinceLastPurchase} % `}
                      <ArrowDownRight
                        size={30}
                        style={{ marginLeft: '10px' }}
                      />
                    </>
                  ) : (
                    <>
                      {`+ ${changesSinceLastPurchase} % `}
                      <ArrowUpRight size={30} style={{ marginLeft: '10px' }} />
                    </>
                  )
                ) : (
                  '-.--'
                )}
              </Header>
              <p> Change since Last Purchase </p>
            </div>
            <div>
              <Header as={'h1'} className="header-css">
                {supplierCount ? supplierCount : 0}
              </Header>
              <p> Suppliers Selling this Product </p>
            </div>
            <div>
              <Header as={'h1'} className="header-css">
                {truckLoads ? truckLoads : 0}
              </Header>
              <p> Truckloads on MX </p>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{
              paddingBottom: '10px',
              borderBottom: '1px solid',
              width: '100%',
            }}
          />
          <Grid.Column
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
              paddingTop: '10px',
            }}
          >
            <Popup
              on="click"
              offset={[21, 0]}
              open={newProductVarientModel}
              onOpen={() => setNewProductVarientModel(true)}
              onClose={() => setNewProductVarientModel(false)}
              trigger={
                <Button
                  icon="plus"
                  style={{
                    width: '250px',
                    height: '38px',
                    backgroundColor: 'white',
                    border: '1px solid #A1A1A1',
                  }}
                  disabled={
                    chartData?.length &&
                    chartData[chartData?.length - 1].data?.length < 2
                  }
                  content={'Add Comparison Product'}
                />
              }
              style={{ padding: '0px' }}
              position="bottom right"
              content={
                <InventoryTable
                  supplierOrder={true}
                  showAddToOrderButton
                  productVariantsRoute={'/main/product-variants/'}
                  tableStartMessage={
                    "Start by searching for the product you'd like to add to this Comparison."
                  }
                  onAddButton={(result) => {
                    getProductVariant(result)
                  }}
                  style={{ minWidth: '90vw' }}
                />
              }
            />
            <Dropdown
              circular
              floating
              basic
              selection
              style={{ border: '1px solid #A1A1A1' }}
              placeholder={'Time Window'}
              value={timeWindow}
              onChange={(e, d) => {
                setTimeWindow(d.value)
              }}
              options={options}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0' }}>
          <Grid.Column>
            <div style={{ width: '100%', height: '400px' }}>
              <ResponsiveLine
                data={chartData}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{
                  type: 'time',
                  format: '%d/%m/%Y',
                }}
                yScale={{
                  type: 'linear',
                  min: 0,
                  max: 'auto',
                  stacked: false,
                  reverse: false,
                }}
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  format: '%b %d, %Y',
                  tickValues: `every ${
                    timeWindow === '2-weeks'
                      ? '1 day'
                      : timeWindow === 'quarter'
                      ? '5 day'
                      : timeWindow === '6-months'
                      ? '15 day'
                      : timeWindow === 'year'
                      ? '25 day'
                      : '1 month'
                  }`,
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -40,
                  legendPosition: 'middle',
                  format: (value) => `$${value}`,
                }}
                enableGridX={false}
                colors={['#a50026', '#a1a1a1', '#87ceeb', '#0000ff', '#90ee90']
                  .slice(0, productVarientList?.length + 1)
                  .reverse()}
                lineWidth={3}
                enablePoints={false}
                pointSize={8}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                enableArea={true}
                areaOpacity={0.1}
                useMesh={true}
                tooltip={({ point }) => (
                  <div className="tooltip-div">
                    <i style={{ background: point.borderColor }} />
                    <span>Date: {timeFormat('%d-%m-%Y')(point.data.x)},</span>
                    <span>Price: {formatTotalPrice(point.data.y)}</span>
                  </div>
                )}
                legends={[
                  {
                    anchor: 'top',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemWidth: itemWidthValue,
                    itemHeight: 10,
                    itemsSpacing: 0,
                    symbolSize: 10,
                    symbolShape: 'square',
                    itemDirection: 'left-to-right',
                    itemTextColor: '#777',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemBackground: 'rgba(0, 0, 0, .03)',
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                motionConfig="default"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: '2rem', padding: '5px 30px' }}>
          <Grid.Column
            style={{
              paddingBottom: '10px',
              borderBottom: '1px solid',
              width: '100%',
            }}
          >
            Price Over Time
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default MXProductVariantGraph
