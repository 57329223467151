import React, { useState, useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import './AdminDashboard.css'
import PageHeader from '../../../components/PageHeader/PageHeader'

function AdminDashboard(props) {
  const [iFrameUrl, setIFrameUrl] = useState(null)
  const axios = useAxios()

  useEffect(() => {
    async function getReports() {
      let reports = await axios.get('/main/admin-reports/', {})
      setIFrameUrl(reports.data.iframe_url)
    }
    getReports()
  }, [])

  return (
    <div className={'main-container'} id="admin-dashboard">
      <PageHeader
        header={'Dashboard'}
        subheader={'View and sort your sales data here.'}
      />
      <Grid stackable style={{ paddingTop: '10px' }}>
        <iframe
          src={iFrameUrl}
          frameBorder="0"
          width="100%"
          height="2000"
          allowtransparency="true"
          title="Admin Report"
        ></iframe>
      </Grid>
    </div>
  )
}

export default AdminDashboard

// function AdminDashboard(props) {
//   const [laneModalOpen, setLaneModalOpen] = useState(false);
//   const [selectedTab, setSelectedTab] = useState("order-book-admin");
//   const user = useSelector((state) => state.user.user);
//   const [filter, setFilter] = useState([])
//   const [materialFilter, setMaterialFilter] = useState([])
//   const [attributes, setAttributes] = useState([])
//   const [sideFilter, setSideFilter] = useState([])
//   const [statusFilter, setStatusFilter] = useState([])
//   const [businessFilter, setBusinessFilter] = useState([])
//   const [usersFilter, setUsersFilter] = useState([])
//   const [locationsFilter, setLocationsFilter] = useState([])
//   const [timeframeFilter, setTimeframeFilter] = useState([])
//   const [activeAttributeIndex, setActiveAttributeIndex] = useState(0)
//   const [filterSelections, setFilterSelections] = useState([])
//   const [price, setPrice] = useState(null)
//   const [quantity, setQuantity] = useState(null)
//   const [liveBidAskData, setLiveBidAskData] = useState([])
//   const [expDate, setExpDate] = useState()
//   const axios = useAxios();
//   const [tableLoaded, setTableLoaded] = useState(true)
//   const [liveBidAskTotalPages, setLiveBidAskTotalPages] = useState()
//   const [liveBidAskActivePage, setLiveBidAskActivePages] = useState(1)
//   const [orderBookData, setOrderBookData] = useState([])
//   const [orderBookTotalPages, setOrderBookTotalPages] = useState()
//   const [orderBookActivePage, setOrderBookActivePages] = useState(1)
//   useEffect(() => {
//     if (!props.product && props.selectedCommodity && props.selectedCommodity?.commodity_forms?.length > 0) {
//       props.setSelectedCommodityForm(props.selectedCommodity?.commodity_forms[0])
//     }
//   }, [props.selectedCommodity])

//   useEffect(()=>{
//     if ('order-book-admin' === selectedTab) {
//       setActiveAttributeIndex(2)
//     } else if ('exchange-orders-admin' === selectedTab) {
//       setActiveAttributeIndex(0)
//     }
//   },[selectedTab])

//   useEffect(async () => {
//     let didCancel = false;
//     if (selectedTab === 'exchange-orders-admin') {
//       setTableLoaded(false)
//       let res = await axios.get('main/admin-exchange-orders/', {
//         params: {
//           page: liveBidAskActivePage,
//           filter: filter,
//           status: ["open"],
//           materialFilter: materialFilter,
//           sideFilter: sideFilter,
//           businessFilter: businessFilter,
//           usersFilter: usersFilter,
//           locationsFilter: locationsFilter,
//           timeframeFilter: timeframeFilter,
//           price: price > 0 ? price : null,
//           expirationDate: expDate,
//           quantity: quantity > 0 ? quantity : null
//         }
//       })
//       if (!didCancel) {
//         if (res && res.data) {
//           setLiveBidAskTotalPages(res.data.total_pages)
//           setLiveBidAskData(res.data.results)
//           setTableLoaded(true)
//         }
//       }
//       return () => {
//         didCancel = true;
//       }
//     }

//     if (selectedTab === 'order-book-admin') {
//       setTableLoaded(false)
//       let res = await axios.get('/main/admin-order-books/', {
//         params: {
//           page: orderBookActivePage,
//           filter: filter,
//           materialFilter: materialFilter,
//           businessFilter: businessFilter,
//           disclude_edges: true,
//           disclude_orders: true
//         }
//       })
//       if (res && res?.data) {
//         setOrderBookTotalPages(res.data.total_pages);
//         setOrderBookData(res.data.results);
//         setTableLoaded(true);
//       }
//     }

//   }, [selectedTab,
//     filter,
//     materialFilter,
//     sideFilter,
//     statusFilter,
//     businessFilter,
//     usersFilter,
//     locationsFilter,
//     timeframeFilter,
//     filterSelections,
//     price,
//     expDate,
//     quantity,
//     liveBidAskActivePage,
//     orderBookActivePage
//   ])

//   function handleTabchange(tab) {
//     setFilter([]);
//     setMaterialFilter([]);
//     setAttributes([]);
//     setSideFilter([]);
//     setStatusFilter([]);
//     setBusinessFilter([]);
//     setUsersFilter([]);
//     setLocationsFilter([]);
//     setTimeframeFilter([]);
//     setFilterSelections([]);
//     setPrice(null);
//     setQuantity(null);
//     setExpDate();
//     setSelectedTab(tab);
//   }

//   return (
//     <div className={"main-container"} id="admin-dashboard">
//       <Grid stackable style={{ paddingTop: "10px" }}>
//         <Grid.Row>
//           <Grid.Column width={16}>
//             <div className="exchange-table-heading">
//               <div className="commodities-wrapper">
//                 <Header
//                   as={"h2"}
//                   className={
//                     selectedTab === "order-book-admin"
//                       ? "commodity-header selected"
//                       : "commodity-header"
//                   }
//                   color={
//                     selectedTab === "order-book-admin" ? "primary" : "black"
//                   }
//                   onClick={() => handleTabchange("order-book-admin")}
//                   style={{
//                     margin: "0 1rem 3rem 0",
//                     "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                   }}
//                 >
//                   Order Books
//                 </Header>
//                 <Header
//                   as={"h2"}
//                   className={
//                     selectedTab === "exchange-orders-admin"
//                       ? "commodity-header selected"
//                       : "commodity-header"
//                   }
//                   color={
//                     selectedTab === "exchange-orders-admin" ? "primary" : "black"
//                   }
//                   onClick={() => handleTabchange("exchange-orders-admin")}
//                   style={{
//                     margin: "0 1rem 3rem 0",
//                     "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                   }}
//                 >
//                   Live Asks/Bids
//                 </Header>
//                 <Header
//                   as={"h2"}
//                   className={
//                     selectedTab === "trucking-lane-admin"
//                       ? "commodity-header selected"
//                       : "commodity-header"
//                   }
//                   color={
//                     selectedTab === "trucking-lane-admin" ? "primary" : "black"
//                   }
//                   onClick={() => setSelectedTab("trucking-lane-admin")}
//                   style={{
//                     margin: "0 1rem 3rem 0",
//                     "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                   }}
//                 >
//                   Trucking
//                 </Header>
//                 <Header
//                   as={"h2"}
//                   className={
//                     selectedTab === "map"
//                       ? "commodity-header selected"
//                       : "commodity-header"
//                   }
//                   color={
//                     selectedTab === "map" ? "primary" : "black"
//                   }
//                   onClick={() => setSelectedTab("map")}
//                   style={{
//                     margin: "0 1rem 3rem 0",
//                     "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                   }}
//                 >
//                   Map
//                 </Header>
//                 <Header
//                   as={"h2"}
//                   className={
//                     selectedTab === "take-rate"
//                       ? "commodity-header selected"
//                       : "commodity-header"
//                   }
//                   color={
//                     selectedTab === "take-rate" ? "primary" : "black"
//                   }
//                   onClick={() => setSelectedTab("take-rate")}
//                   style={{
//                     margin: "0 1rem 3rem 0",
//                     "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                   }}
//                 >
//                   Take Rate
//                 </Header>
//                 {user?.is_dev && (
//                   <Header
//                     className={
//                       selectedTab === "potash"
//                         ? "commodity-header selected"
//                         : "commodity-header"
//                     }
//                     as={"h2"}
//                     color={selectedTab === "potash" ? "primary" : "black"}
//                     onClick={() => setSelectedTab("potash")}
//                     style={{
//                       margin: "0 1rem 3rem 0",
//                       "--primaryColor": env.REACT_APP_PRIMARY_COLOR,
//                     }}
//                   >
//                     Potash
//                   </Header>
//                 )}
//               </div>
//             </div>
//           </Grid.Column>
//         </Grid.Row>
//         {selectedTab === "order-book-admin" && (
//           <>
//             <Grid.Row>
//               <Grid.Column width={16}>
//                 <Sidebar.Pushable id="exchange-pushable">
//                   <Sidebar
//                     id='pushable-sidebar'
//                     animation='overlay'
//                     icon='labeled'
//                     inverted
//                     vertical
//                     visible={true}
//                     width='wide'
//                   >
//                     <ProductFilter
//                       watchlist={true}
//                       showAdminBusiness={true}
//                       businessFilter={businessFilter}
//                       setBusinessFilter={setBusinessFilter}
//                       attributes={attributes}
//                       setAttributes={setAttributes}
//                       filter={filter}
//                       setFilter={setFilter}
//                       materialFilter={materialFilter}
//                       setMaterialFilter={setMaterialFilter}
//                       activeAttributeIndex={activeAttributeIndex}
//                       setActiveAttributeIndex={setActiveAttributeIndex}
//                       filterSelections={filterSelections}
//                       setFilterSelections={setFilterSelections}
//                     />
//                   </Sidebar>
//                   <MickeyTable
//                     headerRow={[
//                       "Order ID",
//                       "Product",
//                       "Variant",
//                       "Bids",
//                       "Asks",
//                       "",
//                     ]}
//                     basicSegment
//                     style={{ marginLeft: '285px' }}
//                     data={orderBookData}
//                     totalPages={orderBookTotalPages}
//                     activePage={orderBookActivePage}
//                     setActivePage={setOrderBookActivePages}
//                     loaded={tableLoaded}
//                     renderBodyRow={(data, index) => {
//                       return (
//                         <AdminOrderBookRow
//                           orderBook={data}
//                           index={index}
//                           onClick={() => { }}
//                           key={data ? data.id : null}
//                         />
//                       );
//                     }}
//                     emptyIcon={"file"}
//                     emptyMessage={"Order books will populate here"}
//                   />
//                 </Sidebar.Pushable>
//               </Grid.Column>
//             </Grid.Row>
//           </>
//         )}
//         {selectedTab === "exchange-orders-admin" && (
//           <>
//             <Grid.Row>
//               <Grid.Column width={16}>
//                 <Sidebar.Pushable id="exchange-pushable">
//                   <Sidebar
//                    id='pushable-sidebar'
//                     animation='overlay'
//                     icon='labeled'
//                     inverted
//                     vertical
//                     visible={true}
//                     width='wide'
//                   >
//                     <ProductFilter
//                       height = {window?.innerHeight}
//                       watchlist={true}
//                       showAdminBusiness={true}
//                       businessFilter={businessFilter}
//                       setBusinessFilter={setBusinessFilter}
//                       attributes={attributes}
//                       setAttributes={setAttributes}
//                       filter={filter}
//                       setFilter={setFilter}
//                       materialFilter={materialFilter}
//                       setMaterialFilter={setMaterialFilter}
//                       activeAttributeIndex={activeAttributeIndex}
//                       setActiveAttributeIndex={setActiveAttributeIndex}
//                       filterSelections={filterSelections}
//                       setFilterSelections={setFilterSelections}
//                       extra_filters={true}
//                       yourBids={true}
//                       selectedCommodity={props.selectedCommodity}
//                       openFilter={props.openExchangeFilter}
//                       selectedCommodityForm={props.selectedCommodityForm}
//                       setSelectedCommodityForm={props.setSelectedCommodityForm}
//                       sideFilter={sideFilter}
//                       setSideFilter={setSideFilter}
//                       usersFilter={usersFilter}
//                       setUsersFilter={setUsersFilter}
//                       locationsFilter={locationsFilter}
//                       setLocationsFilter={setLocationsFilter}
//                       timeframeFilter={timeframeFilter}
//                       setTimeframeFilter={setTimeframeFilter}
//                       statusFilter={statusFilter}
//                       setStatusFilter={setStatusFilter}
//                       price={price}
//                       setPrice={setPrice}
//                       expDate={expDate}
//                       setExpDate={setExpDate}
//                       quantity={quantity}
//                       setQuantity={setQuantity}
//                     />

//                   </Sidebar>
//                   <MickeyTable
//                     headerRow={[
//                       "Product",
//                       "Business",
//                       "User",
//                       "Side",
//                       "Location",
//                       "Timeframe",
//                       "Timestamp",
//                       "Expiration",
//                       "Status",
//                       "Quantity",
//                       "Price",
//                     ]}
//                     basicSegment
//                     data={liveBidAskData}
//                     totalPages={liveBidAskTotalPages}
//                     activePage={liveBidAskActivePage}
//                     setActivePage={setLiveBidAskActivePages}
//                     loaded={tableLoaded}
//                     renderBodyRow={(data, index) => {
//                       return (
//                         <AdminExchangeOrderRow
//                           exchangeOrder={data}
//                           index={index}
//                           onClick={() => { }}
//                           key={data ? data.id : null}
//                         />
//                       );
//                     }}
//                     style={{ marginLeft: '285px' }}
//                     emptyIcon={"file"}
//                     emptyMessage={"Order books will populate here"}
//                   />
//                 </Sidebar.Pushable>
//               </Grid.Column>
//             </Grid.Row>
//           </>
//         )}
//         {selectedTab === "trucking-lane-admin" && (
//           <>
//             <Grid.Row>
//               <Grid.Column width={8}>
//                 <AdminDashboardTruckingStatus />
//               </Grid.Column>
//               <Grid.Column width={8}>
//                 <AdminDashboardTruckingLane />
//               </Grid.Column>
//             </Grid.Row>
//             {/* TODO API times out
//              <Grid.Row>
//               <Grid.Column width={16}>
//                 <AdminDashboardTruckingQuotes />
//               </Grid.Column>
//             </Grid.Row> */}
//             <Grid.Row>
//               <Grid.Column width={16}>
//                 <AdminDashboardTruckingLanePriorities />
//               </Grid.Column>
//             </Grid.Row>
//           </>
//         )}
//         {selectedTab === "map" && <>
//           <AdminDashboardMap />
//         </>}
//         {selectedTab === "take-rate" && <>
//           <TakeRate />
//         </>}
//       </Grid>
//       <Modal
//         onClose={() => setLaneModalOpen(false)}
//         onOpen={() => setLaneModalOpen(true)}
//         open={laneModalOpen}
//       >
//         <Modal.Header>Add Lane To Daily Tracking</Modal.Header>
//         <Modal.Content>
//           <MickeyForm
//             formName={"TruckingLaneForm"}
//             model={"TruckingLane"}
//             loadingHeight={"150px"}
//             tenantAware={false}
//             url={"/main/admin-trucking-lanes/"}
//             handleForm={(status, data) => {
//               if (status === "success") {
//                 setLaneModalOpen(false);
//               } else {
//                 toast.error("Error adding lane");
//               }
//             }}
//           />
//         </Modal.Content>
//       </Modal>
//     </div>
//   );
// }

// export default AdminDashboard;
