import { Table } from 'semantic-ui-react'

const ActivityRow = (props) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>{props.activity?.created_at}</Table.Cell>
        <Table.Cell>
          {props.activity?.user?.first_name} {props.activity?.user?.last_name}
        </Table.Cell>
        <Table.Cell>
          {props.activity?.created_for && (
            <>
              {props.activity?.created_for?.first_name}{' '}
              {props.activity?.created_for?.last_name} (
              {props.activity?.created_for?.business?.name})
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          {props.activity?.model_changed}
          {props.activity?.model_description
            ? ': ' + props.activity?.model_description
            : ''}
        </Table.Cell>
        <Table.Cell>{props.activity?.model_id}</Table.Cell>
        <Table.Cell>{props.activity?.field_changed}</Table.Cell>
        <Table.Cell>{props.activity?.old_value}</Table.Cell>
        <Table.Cell>{props.activity?.new_value}</Table.Cell>
      </Table.Row>
    </>
  )
}

export default ActivityRow
