import React, { useState, useEffect } from 'react'
import { Table, Button, Icon, Loader } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'

const AdminChangeOrderRequestRow = (props) => {
  const { removeLineItem, index } = props
  const axios = useAxios()
  const [pieces, setPieces] = useState(null)
  const [orderItem, setOrderItem] = useState(null)
  const [truckingVals, setTruckingVals] = useState(null)

  useEffect(() => {
    const getOrderItem = async () => {
      let res = await axios.get(`/main/order-items/`, {
        params: {
          order_item_id: props.changeOrder.order_item_id,
          tenant_aware: false,
        },
      })
      if (res?.data) {
        setOrderItem(res?.data?.results[0])
      }
    }
    getOrderItem()
  }, [])

  useEffect(() => {
    async function loadOrderItemStatus() {
      let res = await axios.get(
        `/main/cargo-transportation/order-item-location/`,
        {
          params: {
            order_item_ids: [orderItem?.id],
          },
        }
      )
      if (res?.status < 300 && res?.data?.results) {
        setTruckingVals(res?.data?.results[0]['buyer_quantity_values'])
      }
    }
    if (props.changeOrder.is_cancel_line_item) {
      loadOrderItemStatus()
    }
  }, [orderItem])

  useEffect(() => {
    const getPieces = async () => {
      let res
      if (props.changeOrder.is_cancel_line_item) {
        res = await axios.post(
          '/main/change-order-line-items/get_piece_count_from_quantity/',
          {
            pv_id: props.changeOrder.product_variant.id,
            uom_id: props.changeOrder.unit_of_measure.id
              ? props.changeOrder.unit_of_measure.id
              : props.changeOrder.unit_of_measure,
            quantity:
              truckingVals &&
              truckingVals['booked_quantity'] +
                truckingVals['destination_location_quantity'] +
                truckingVals['in_transit_quantity'],
          }
        )
      }
      if (props.changeOrder.is_edit_line_item) {
        res = await axios.post(
          '/main/change-order-line-items/get_piece_count_from_quantity/',
          {
            pv_id: props.changeOrder.product_variant.id,
            uom_id: props.changeOrder.unit_of_measure.id
              ? props.changeOrder.unit_of_measure.id
              : props.changeOrder.unit_of_measure,
            quantity:
              orderItem &&
              parseFloat(orderItem?.buyer_quantity) +
                props.changeOrder.new_quantity,
          }
        )
      }
      if (props.changeOrder.is_new_line_item) {
        res = await axios.post(
          '/main/change-order-line-items/get_piece_count_from_quantity/',
          {
            pv_id: props.changeOrder.product_variant.id,
            uom_id: props.changeOrder.unit_of_measure.id
              ? props.changeOrder.unit_of_measure.id
              : props.changeOrder.unit_of_measure,
            quantity: parseFloat(props.changeOrder.new_quantity),
          }
        )
      }
      if (res?.data) {
        setPieces(res?.data)
      }
    }
    if (orderItem !== null) {
      getPieces()
    }
  }, [orderItem, truckingVals, props.changeOrder])

  return (
    <Table.Row>
      <Table.Cell width={3}>
        <strong>
          {props.changeOrder?.product_variant?.product?.material?.name}
        </strong>
      </Table.Cell>
      <Table.Cell width={4}>
        {props.changeOrder.attributes.map((attribute) => {
          return (
            <p>
              <strong>{attribute.attribute_name}:</strong>{' '}
              {attribute.attribute_selection_value}
            </p>
          )
        })}
      </Table.Cell>
      <Table.Cell width={3}>
        {props.changeOrder.is_new_line_item &&
          `0 /${
            props.addLineItemSelectedUom
              ? props.addLineItemSelectedUom.unit_name
              : props.changeOrder.product_variant.unit_name
          }`}
        {(props.changeOrder.is_cancel_line_item ||
          props.changeOrder.is_edit_line_item) &&
          orderItem?.buyer_quantity +
            ' ' +
            '/' +
            orderItem?.buyer_unit?.short_name}
      </Table.Cell>
      <Table.Cell width={3}>
        {props.changeOrder.is_new_line_item &&
          `${props.changeOrder.new_quantity} /${
            props.addLineItemSelectedUom
              ? props.addLineItemSelectedUom.unit_name
              : props.changeOrder.product_variant.unit_name
          }`}
        {props.changeOrder.is_cancel_line_item &&
          truckingVals &&
          truckingVals['booked_quantity'] +
            truckingVals['destination_location_quantity'] +
            truckingVals['in_transit_quantity'] +
            ' ' +
            '/' +
            orderItem?.buyer_unit?.short_name}
        {props.changeOrder.is_edit_line_item &&
          parseFloat(orderItem?.buyer_quantity) +
            parseFloat(props.changeOrder.new_quantity) +
            ' ' +
            '/' +
            props.changeOrder.unit_of_measure.short_name}
      </Table.Cell>
      <Table.Cell width={3}>
        <Button onClick={() => removeLineItem(index)} color="red">
          <Icon name="x" style={{ margin: '0' }} />
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

export default AdminChangeOrderRequestRow
