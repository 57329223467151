import React, { useState } from 'react'
import { Button, Label, Table } from 'semantic-ui-react'
import env from '../../../../env'
import { useNavigate } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import { green } from '@mui/material/colors'
import useAxios from '../../../../lib/auth/useAxios'

const AdminSalesTasksRow = (props) => {
  const navigate = useNavigate()
  const axios = useAxios()
  const [data, setData] = useState(props.data)
  const order = data?.task_group?.order
  const overdue = new Date(data?.due_date) < new Date()

  function updateTaskCompletion(task, index) {
    let newVal = !task.complete
    setData((prevState) => ({
      ...prevState,
      complete: newVal,
    }))
    props.setActiveTaskCount((prevState) =>
      newVal === true ? prevState - 1 : prevState + 1
    )
    axios.patch(`/main/admin-tasks/${task?.id}/`, {
      complete: newVal,
    })
  }

  return (
    <>
      <Table.Row selectable className="admin-sales-tasks-row">
        <Table.Cell style={{ minWidth: '360px' }}>
          <Checkbox
            checked={data?.complete}
            sx={{
              color: green[800],
              '&.Mui-checked': {
                color: green[600],
              },
              '& .MuiSvgIcon-root': { fontSize: 28 },
            }}
            onClick={(e, d) => {
              updateTaskCompletion(data)
            }}
          />
          <span>{data?.name}</span>
        </Table.Cell>
        <Table.Cell>{order ? `Order#${order?.order_number}` : ''}</Table.Cell>
        <Table.Cell>{data?.task_group?.buyer_name}</Table.Cell>
        <Table.Cell>{data?.task_group?.supplier_name}</Table.Cell>
        <Table.Cell>{data?.task_group?.trucking_company?.name}</Table.Cell>
        <Table.Cell>
          <Label
            style={{
              '--primary': data?.complete
                ? 'rgb(70 175 70)'
                : overdue
                ? 'rgb(98 135 247)'
                : 'rgb(245 180 72)',
              '--background': data?.complete
                ? 'rgba(70, 175, 70, 0.2)'
                : overdue
                ? 'rgba(98, 135, 247, 0.2)'
                : 'rgba(245, 180, 72, 0.2)',
            }}
            content={
              data?.complete ? 'Completed' : overdue ? 'Overdue' : 'Future'
            }
          />
        </Table.Cell>
        <Table.Cell>{data?.due_date}</Table.Cell>
        <Table.Cell>
          {order && (
            <Button
              basic
              color={'primary'}
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              content={'View Order'}
              onClick={() =>
                navigate(`/portal/admin/deals/${order?.string_token}/`)
              }
            />
          )}
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default AdminSalesTasksRow
