import { Table } from 'semantic-ui-react'
import OrderItemDetailTwo from '../OrderItemDetail/OrderItemDetailTwo'

function PurchaseOrderRow(props) {
  const {
    purchaseOrder,
    setPurchaseOrder,
    hideBusiness,
    setUpdate,
    setOpenAddPurchaseOrderModal,
  } = props

  return (
    <Table.Row
      selectable
      onClick={(e, d) => {
        setPurchaseOrder(purchaseOrder)
        setUpdate(true)
        setOpenAddPurchaseOrderModal(true)
      }}
    >
      <Table.Cell>{purchaseOrder?.purchase_order_number}</Table.Cell>
      <Table.Cell>
        {purchaseOrder?.purchase_order_items?.map((lineItem) => {
          return <OrderItemDetailTwo orderItem={lineItem} />
        })}
      </Table.Cell>
      <Table.Cell>
        {purchaseOrder?.purchase_order_items?.map((lineItem) => {
          return <p>{lineItem?.quantity}</p>
        })}
      </Table.Cell>
      <Table.Cell>
        {purchaseOrder?.purchase_order_items?.map((lineItem) => {
          return <p>{lineItem?.uom?.plural_name}</p>
        })}
      </Table.Cell>
      <Table.Cell>
        {purchaseOrder?.purchase_order_items?.map((lineItem) => {
          return <p>{lineItem?.price}</p>
        })}
      </Table.Cell>
      {!hideBusiness && (
        <Table.Cell>{purchaseOrder?.supplier?.business?.name}</Table.Cell>
      )}
      <Table.Cell>{purchaseOrder?.total_amount}</Table.Cell>
    </Table.Row>
  )
}

export default PurchaseOrderRow
