import {
  requiredEmailValidator,
  requiredPhoneNumberValidator,
} from '../components/Forms/validators'

export const TruckingCompanyForm = {
  model: 'TruckingCompany',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
        },
        type: {
          required: true,
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        phone: {
          required: true,
          type: 'phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
        fax: {
          required: false,
          type: 'phone',
          validator: (input) => {
            return requiredPhoneNumberValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        email: {
          required: true,
          type: 'phone',
          validator: (input) => {
            return requiredEmailValidator(input)
          },
        },
      },
    },
  ],
}
