import { useState } from 'react'
import { Table, Button, Modal, Label } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { formatOrderNumber } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import UploadInfo from '../../shared/UploadInfo/UploadInfo'
import ProductIcon from '../../../components/ProductIcon/ProductIcon'

function SupplierOrdersRow(props) {
  const [openUploadInformationModal, setOpenUploadInformationModal] =
    useState(false)
  const business = useSelector((state) => state.user.business)
  const supplier = business.supplier
  const window = useWindowDimensions()
  const { order, orderStatus } = props

  const navigate = useNavigate()
  const path = '/portal/supplier/orders'
  let estimatedDeliveryDate = order?.estimated_delivery_date
  if (!estimatedDeliveryDate && order?.order_items) {
    const orderItem = order?.order_items[0]
    if (orderItem) {
      estimatedDeliveryDate = orderItem.estimated_delivery_date
    }
  }

  const supplierChoices = {
    1: 'Logistics Booked',
    2: 'Shipped',
    3: 'Delivered',
  }
  const supplierOrderStatus = supplierChoices?.[order.supplier_order_status]

  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell
          onClick={() => {
            navigate(`${path}/${order?.string_token}/${orderStatus}/`)
          }}
        >
          {order && formatOrderNumber(order?.order_number)}
        </Table.Cell>
        <Table.Cell>
          {order.so_number === null || order.so_number === ''
            ? '--'
            : order.so_number}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`${path}/${order?.string_token}/${orderStatus}/`)
          }}
        >
          {order?.created_at}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(`${path}/${order?.string_token}/${orderStatus}/`)
          }}
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: order?.order_item_count > 3 ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              width: '15px',
              height: '15px',
              backgroundColor: env.REACT_APP_PRIMARY_COLOR,
              position: 'absolute',
              borderRadius: '500px',
              top: '50%',
              right: '20px',
              color: 'white',
              padding: '11px',
              fontSize: '11px',
              transform: 'translate(0, -50%)',
            }}
          >
            {order?.order_item_count > 13
              ? '10'
              : order?.order_item_count - order?.order_items.length}
            +
          </div>
          {order?.order_items ? (
            <>
              {order?.order_items?.map((orderItem, index) => {
                return (
                  <div key={index}>
                    {orderItem?.product_variant?.product?.material && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <ProductIcon
                          margin={'5px'}
                          productName={
                            orderItem?.product_variant?.product?.material?.name
                          }
                        />
                        {orderItem?.product_variant?.product?.material?.name} (
                        {
                          orderItem?.product_variant?.product?.commodity_form
                            ?.name
                        }
                        )
                        {!orderItem?.is_extra_line_item &&
                          orderItem?.product_variant &&
                          orderItem?.product_variant?.attribute_selections?.map(
                            (attribute, idx) => {
                              return (
                                <Label
                                  style={{
                                    padding: '0 2px',
                                    margin: '2px',
                                    backgroundColor: 'transparent',
                                  }}
                                  key={idx}
                                >
                                  {attribute.attribute_selection_value}
                                </Label>
                              )
                            }
                          )}
                      </div>
                    )}
                  </div>
                )
              })}
            </>
          ) : (
            ''
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        onOpen={() => setOpenUploadInformationModal(true)}
        onClose={() => setOpenUploadInformationModal(false)}
        open={openUploadInformationModal}
        style={{
          height: '70vh',
          width:
            window.width >= 1200
              ? '25vw'
              : window.width >= 768
              ? '45vw'
              : '90vw',
          overflow: 'scroll',
        }}
      >
        <UploadInfo
          order={order}
          supplier={supplier}
          setOpenUploadInformationModal={setOpenUploadInformationModal}
        />
      </Modal>
    </>
  )
}

export default SupplierOrdersRow
