import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import useAxios from '../../../lib/auth/useAxios'
import './OrderTruck.css'
import $ from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import { objFull, trackConversion } from '../../../lib/utils/utils'
import {
  addUser,
  addBusiness,
  addBuyer,
  addSupplier,
  addLocations,
} from '../../../features/user/userSlice'
import { useAuth } from '../../../lib/auth/useAuth'

function BalanceCheckout(props) {
  const { invoice } = props
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const axios = useAxios()
  const auth = useAuth()
  async function submitConfirmedOrder() {
    const b_response = await axios.post(
      '/main/balance-transaction-confirmed/',
      {
        invoice_id: invoice.id,
      }
    )

    const formatOrderItems = (orderItems) => {
      let orderDataForGoogle = []
      orderItems?.map((item) => {
        let orderItemForGoogle = {
          item_id: item.id,
          item_name: item.product_variant.product.material.name,
          quantity: item.quantity,
        }
        orderDataForGoogle.push(orderItemForGoogle)
        return orderDataForGoogle
      })
    }

    props.setOrderItems && props.setOrderItems([])
    props.setRate && props.setRate(null)
    props.setTrucks && props.setTrucks([])
    if (b_response.data?.onboarded_buyer) {
      const a_response = await auth.signin(
        b_response.data?.buyer_email,
        b_response?.data.buyer_password
      )
      if (a_response?.data?.user) {
        dispatch(addUser(a_response?.data?.user))
        if (a_response?.data.business) {
          dispatch(addBusiness(a_response?.data.business))
        }
        if (a_response?.data.business?.locations) {
          dispatch(addLocations(a_response?.data.business?.locations))
        }
        if (a_response?.data.user?.business?.buyer) {
          dispatch(addBuyer(a_response?.data.user?.business?.buyer))
        }
        if (a_response?.data.user?.business?.supplier) {
          dispatch(addSupplier(a_response?.data.user?.business?.supplier))
        }
      }
      props.setSuccessUrl(
        '/portal/buyer/orders/' + props.order.string_token + '/'
      )
    } else if (objFull(user)) {
      props.setSuccessUrl(
        '/portal/buyer/orders/' + props.order.string_token + '/'
      )
    } else {
      toast.success(
        `Thank you! Your payment is being processed. Login with ${b_response?.data?.buyer_email} to track the order's status`
      )
      props.setSuccessUrl('/login')
    }
    trackConversion('purchase', {
      user_id: objFull(user) && user?.id,
      items: formatOrderItems(props.orderItems),
      value: props.order.rate,
    })
  }

  const checkoutOptions = {
    hideDueDate: false,
    onSuccess: () => {
      submitConfirmedOrder()
    },
    onError: (error) => {
      toast.error(
        'There was an error processing your transaction. Please contact customer support.'
      )
    },
    onClose: () => {
      $('#balance-checkout-wrapper').html('<div id="balance-checkout"></div>')
      if (props.resetCart) {
        props.resetCart()
      }
      props.setOrderId && props.setOrderId(null)
      props.setOrder && props.setOrder(null)
      props.setSidebarVisible && props.setSidebarVisible(false)
    },
    onCancel: () => {},
  }

  useEffect(() => {
    if (props.order) {
      window.balanceCheckout.init(checkoutOptions)
    }
  }, [props.order])

  return (
    <>
      <div id="balance-checkout-wrapper">
        <div
          id="balance-checkout"
          style={{ zIndex: '200', position: 'absolute' }}
        ></div>
      </div>
    </>
  )
}

export default BalanceCheckout
