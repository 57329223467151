import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Modal, Menu, Sidebar, Segment, Header } from 'semantic-ui-react'
import env from '../../../env'
import './AdminFulfillment.css'
import {
  AddressBook,
  Calculator,
  Chats,
  FileText,
  Info,
  Package,
  TextAlignLeft,
  Truck,
  User,
  Users,
} from 'phosphor-react'
import AdminFulfillmentTrucking from './AdminFulfillmentTrucking'
import AdminFulfillmentTruckingCompanies from './AdminFulfillmentTruckingCompanies'
import AdminOrderFulfillment from '../AdminOrder/AdminOrderFulfillment'
import AdminFulfillmentUsers from './AdminFulfillmentUsers'

function AdminFulfillment(props) {
  const axios = useAxios()
  const [loading, setLoading] = useState(false)
  const [activeButton, setActiveButton] = useState('Trucking')
  const [sidebarVisible, setSidebarVisible] = useState(true)

  return (
    <div style={{ padding: 0, marginTop: '0px' }}>
      <Sidebar
        as={Menu}
        animation="Uncover"
        icon="labeled"
        vertical
        visible={sidebarVisible}
        width="thin"
        id="admin-order-menu"
      >
        <div class="item"></div>
        <h4 class="ui horizontal divider header" style={{ marginBottom: 0 }}>
          Fulfillment
        </h4>
        <div class="item" style={{ paddingTop: 0, marginTop: 0 }}>
          <div class="menu">
            <div
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              className={
                activeButton === 'Trucking'
                  ? 'admin-order-menu-option item selected'
                  : 'admin-order-menu-option item'
              }
              onClick={(e, d) => {
                setActiveButton('Trucking')
              }}
            >
              <Truck size={20} />
              Fulfillment
            </div>
            <div
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              className={
                activeButton === 'Trucking Companies'
                  ? 'admin-order-menu-option item selected'
                  : 'admin-order-menu-option item'
              }
              onClick={(e, d) => {
                setActiveButton('Trucking Companies')
              }}
            >
              <AddressBook size={20} />
              Companies
            </div>
            <div
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              className={
                activeButton === 'Trucking Users'
                  ? 'admin-order-menu-option item selected'
                  : 'admin-order-menu-option item'
              }
              onClick={(e, d) => {
                setActiveButton('Trucking Users')
              }}
            >
              <Users size={20} />
              Users
            </div>
          </div>
        </div>
      </Sidebar>
      <>
        <Segment
          basic
          loading={loading}
          style={{ minHeight: '200px' }}
          className={'main-segment-sidebar-container'}
        >
          {activeButton === 'Trucking Companies' && (
            <AdminFulfillmentTruckingCompanies />
          )}
          {activeButton === 'Trucking' && <AdminFulfillmentTrucking />}
          {activeButton === 'Trucking Users' && <AdminFulfillmentUsers />}
        </Segment>
      </>
    </div>
  )
}

export default AdminFulfillment
