import { useEffect, useState } from 'react'
import {
  Table,
  Popup,
  Label,
  Button,
  Menu,
  Icon,
  Modal,
} from 'semantic-ui-react'
import { formatTotalPrice } from '../../../lib/utils/utils'
import AutoField from '../../../components/AutoField/AutoField'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { toast } from 'react-toastify'
import useAxios from '../../../lib/auth/useAxios'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import './SupplierOrderItemsRow.css'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const SupplierOrderItemsRow = (props) => {
  const axios = useAxios()
  const wd = useWindowDimensions()
  const [orderItem, setOrderItem] = useState(props.orderItem)
  const [markOrderShippedModal, setMarkOrderShippedModal] = useState(false)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [openDiscountModal, setOpenDiscountModal] = useState(false)
  const user = useSelector((state) => state.user.user)

  const [totalPrice, setTotalPrice] = useState(
    props.orderItem?.supplier_quotes?.length > 0
      ? props.orderItem?.supplier_quotes[0]?.supplier_price *
          props.orderItem.price
      : props.orderItem.quantity * props.orderItem.price
  )

  async function handleMarkOrderShipped(status, data) {
    setAcceptLoading(false)
    if (status === 'success') {
      setMarkOrderShippedModal(false)
      props.setReload(true)
      props.setOrderItem(data)
      setOrderItem(data)
    }
  }

  function isSelectedRow() {
    return (
      props.selectedOrderItem &&
      orderItem &&
      props.selectedOrderItem?.id === orderItem?.id
    )
  }

  async function archiveOrderItem() {
    let resInstance = await axios.patch(
      `/main/order-items/${orderItem.string_token}/?tenant_aware=false`,
      {
        archived: true,
      }
    )
    if (resInstance.data.error) {
      toast.error(resInstance.data.error)
    } else if (resInstance?.status < 300) {
      if (props.setReload) {
        props.setReload(true)
      }
    } else {
      toast.error('Error archiving order item.')
    }
  }

  useEffect(() => {
    props.setOrderItem(orderItem)
  }, [totalPrice])

  useEffect(() => {
    let tp = 0
    if (orderItem.price && orderItem.quantity) {
      tp = parseFloat(orderItem?.price) * parseFloat(orderItem?.quantity)
    }
    setTotalPrice(tp)
  }, [orderItem])

  return (
    <>
      {orderItem?.order?.mx_order && (
        <Table.Row selectable onClick={props.onClick}>
          <Table.Cell
            width={2}
            style={{ fontSize: wd.width >= 768 ? '' : '1.5rem' }}
          >
            {orderItem?.string_token}
          </Table.Cell>
          <Table.Cell>{orderItem?.created_at}</Table.Cell>
          <Table.Cell>
            {orderItem?.is_extra_line_item &&
              orderItem?.extra_line_item_description}
            {!orderItem?.is_extra_line_item &&
              orderItem?.product_variant?.product?.material && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <OrderItemDetailTwo orderItem={orderItem} />
                </div>
              )}
          </Table.Cell>
          <Table.Cell width={1}>
            {props.canEdit ? (
              <AutoField
                icon="box"
                iconPosition={'left'}
                model={'OrderItem'}
                disabled={orderItem?.locked}
                type="number"
                field={'quantity'}
                step="1"
                url={'/main/order-items/'}
                placeholder={'Enter Quantity'}
                instanceId={orderItem?.id}
                value={orderItem?.quantity}
                style={{
                  width: '150px',
                }}
                onChange={(e, d) => {
                  setOrderItem({
                    ...orderItem,
                    quantity: parseInt(d?.value),
                  })
                }}
                size={'small'}
                textarea={false}
              />
            ) : orderItem.quantity && orderItem.quantity > 0 ? (
              `${orderItem.quantity} ${orderItem.unit.short_name}`
            ) : (
              'Awaiting Shipment'
            )}
          </Table.Cell>
          <Table.Cell width={1}>
            {formatTotalPrice(orderItem?.price)}
          </Table.Cell>
          <Table.Cell width={1}>
            {orderItem?.unit_count + ' / ' + orderItem?.piece_count}
          </Table.Cell>
          <Table.Cell positive width={1}>
            <span
              style={{
                textDecoration:
                  !orderItem?.order?.mx_order &&
                  orderItem?.discount &&
                  orderItem?.discount > 0
                    ? 'line-through'
                    : '',
              }}
            >
              {formatTotalPrice(totalPrice)}
            </span>
          </Table.Cell>
        </Table.Row>
      )}
      {!orderItem?.order?.mx_order && (
        <Table.Row
          selectable
          positive={isSelectedRow()}
          onClick={props.onClick}
        >
          <Table.Cell>{orderItem?.string_token}</Table.Cell>
          <Table.Cell>{orderItem?.created_at}</Table.Cell>
          <Table.Cell>
            {orderItem?.is_extra_line_item &&
              orderItem?.extra_line_item_description}
            {!orderItem?.is_extra_line_item &&
              orderItem?.product_variant?.product?.material && (
                <Label
                  style={{
                    backgroundColor: 'white',
                    border: isSelectedRow()
                      ? '1px solid grey'
                      : '1px solid lightgrey',
                  }}
                  size={'mini'}
                >
                  {orderItem?.product_variant?.product?.material?.name} (
                  {orderItem?.product_variant?.product?.commodity_form?.name})
                </Label>
              )}
            {!orderItem?.is_extra_line_item &&
              orderItem?.product_variant &&
              orderItem?.product_variant?.attribute_selections?.map(
                (attribute) => {
                  return (
                    <Label
                      size={'mini'}
                      style={{
                        fontSize: '0.75em',
                        padding: '0 10px',
                        margin: '2px 0px',
                        backgroundColor: isSelectedRow()
                          ? 'transparent'
                          : 'transparent',
                      }}
                    >
                      {attribute.attribute_selection_value}
                    </Label>
                  )
                }
              )}
          </Table.Cell>
          <Table.Cell width={1}>
            {props.canEdit ? (
              <AutoField
                // icon="box"
                icon={'dollar'}
                iconPosition={'left'}
                model={'OrderItem'}
                disabled={orderItem?.locked}
                type="number"
                field={'quantity'}
                step="1"
                url={'/main/order-items/'}
                placeholder={'Enter Quantity'}
                instanceId={orderItem?.id}
                value={orderItem?.quantity}
                style={{
                  width: '150px',
                }}
                onChange={(e, d) => {
                  setOrderItem({
                    ...orderItem,
                    quantity: parseFloat(d?.value),
                  })
                }}
                size={'small'}
                textarea={false}
              />
            ) : (
              orderItem.quantity
            )}
          </Table.Cell>
          <Table.Cell width={1}>{orderItem?.price}</Table.Cell>
          <Table.Cell width={1}>
            {orderItem?.delivered_price &&
              orderItem?.buyer_quantity &&
              parseFloat(orderItem?.delivered_price) *
                parseFloat(orderItem?.buyer_quantity) +
                parseFloat(orderItem?.tax_amount)}
          </Table.Cell>
          <Table.Cell width={1}>{orderItem?.trucking_price}</Table.Cell>
          <Table.Cell width={1}>{orderItem?.delivered_price}</Table.Cell>
          <Table.Cell width={2}>
            {!props.orderItem?.supplier_shipped &&
              hasPermission(user, 'Update Order') && (
                <Popup
                  content={'Mark Order Shipped'}
                  trigger={
                    <Button
                      color={'orange'}
                      size={'mini'}
                      disabled={!props.orderItem?.supplier_accepted}
                      icon={'shipping fast'}
                      loading={acceptLoading}
                      onClick={() => {
                        setMarkOrderShippedModal(true)
                      }}
                    />
                  }
                />
              )}
            {props.orderItem?.supplier_shipped && (
              <Button color="green">Truck Shipped</Button>
            )}
            {!props.locked &&
            !props.orderItem?.locked &&
            hasPermission(user, 'Update Order') ? (
              <Popup
                on={'click'}
                trigger={
                  <Button
                    color={'grey'}
                    circular
                    icon={'ellipsis vertical'}
                    size={'mini'}
                  />
                }
              >
                <Menu secondary vertical>
                  <Menu.Item
                    name="discount"
                    onClick={() => {
                      setOpenDiscountModal(true)
                    }}
                  >
                    <Icon name="percent" />
                    Edit Discount
                  </Menu.Item>
                  <Menu.Item
                    name="archive"
                    onClick={() => {
                      archiveOrderItem()
                    }}
                  >
                    <Icon name="x" />
                    Archive
                  </Menu.Item>
                </Menu>
              </Popup>
            ) : null}
          </Table.Cell>
        </Table.Row>
      )}

      <Modal
        onClose={() => setMarkOrderShippedModal(false)}
        onOpen={() => setMarkOrderShippedModal(true)}
        open={markOrderShippedModal}
      >
        <Modal.Header>Please Submit Information</Modal.Header>
        <Modal.Content>
          <MickeyForm
            model={'Order'}
            formName={'MarkOrderShippedForm'}
            successMessage={'Order marked as shipped!'}
            failureMessage={'Error marking order as shipped, please try again.'}
            url={'/main/mark-order-shipped/'}
            handleForm={handleMarkOrderShipped}
            buttonLabel={'Mark Order Shipped'}
            defaults={{
              amount_shipped: orderItem?.quantity,
            }}
            values={{
              supplier_id: props.supplier?.id,
              order_item_id: props.orderItem?.id,
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenDiscountModal(false)}
        onOpen={() => setOpenDiscountModal(true)}
        open={openDiscountModal}
      >
        <Modal.Header>
          Edit Discount to Order Item {orderItem?.string_token}
        </Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'OrderItemDiscountForm'}
            model={'OrderItem'}
            instanceId={orderItem?.string_token}
            instanceIdOverwrite={orderItem?.id}
            url={'/main/order-items/'}
            onUpdate={(fields) => {
              setOrderItem({
                ...orderItem,
                discount: fields['discount'],
              })
              props.setOrderItem({
                ...orderItem,
                discount: fields['discount'],
              })
            }}
          />
        </Modal.Content>
      </Modal>
      {/* <Modal
        className="trucking-modal"
        onClose={() => setOpenTruckingModal(false) }
        onOpen={() => setOpenTruckingModal(true)}
        open={openTruckingModal}
      >
        <Modal.Header>
          <div>
            <span>Trucking Details</span>
            <X size={25} link onClick={() => {setOpenTruckingModal(false)} } />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group className="form-header">
              <Form.Field>
                <span>Date & Time</span>
              </Form.Field>
              <Form.Field>
                <span>Logistics/Cost</span>
              </Form.Field>
              <Form.Field>
                <span>Truck Size</span>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label style={{marginBottom: "1rem"}} >Location for Pickup</label>
                <Input
                  type={"text"}
                  value={orderItem?.pickup_location?.address_1 
                    + ", " + orderItem?.pickup_location?.city 
                    + ", " + orderItem?.pickup_location?.state
                    + " " + orderItem?.pickup_location?.zip_code
                  }
                  placeholder={"Location for Pickup"}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Truck Size*</label>
                <Input
                  type={"text"}
                  value={orderItem?.trucking_quote?.type}
                  placeholder={"Truck Size"}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Logistics Company</label>
                <Input
                  type={"text"}
                  disabled
                  value={orderItem?.trucking_quote?.trucking_company}
                  placeholder={"TLogistics Company"}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Pick-Up Date*</label>
                <Input
                  type={"text"}
                  disabled
                  value={orderItem?.trucking_quote?.pickup_date}
                  placeholder={"Pick-Up Date"}
                />
              </Form.Field>
              <Form.Field>
                <label>Estimated Load Weight</label>
                <Input
                  type={"text"}
                  disabled
                  value={orderItem?.trucking_quote?.api_response["data"]["items"]["weight"]}
                  placeholder={"Estimated Load Weight"}
                />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Input
                  type={"text"}
                  disabled
                  value={orderItem?.trucking_quote?.rate}
                  placeholder={"Cost"}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Drop-off Date*</label>
                <Input
                  type={"text"}
                  disabled
                  value={orderItem?.trucking_quote?.delivery_date}
                  placeholder={"Drop-off Date"}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal> */}
    </>
  )
}

export default SupplierOrderItemsRow
