import React, { useState } from 'react'
import { Button, Form, Header, Segment, Menu } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
} from 'recharts'
import { toast } from 'react-toastify'

function AdminDashboardTruckingLane({ navigation }) {
  const axios = useAxios()
  const [dateRange, setDateRange] = useState('day')
  const [pickupZipCode, setPickupZipCode] = useState(null)
  const [deliveryZipCode, setDeliveryZipCode] = useState(null)
  const [laneAnalysisData, setLaneAnalysisData] = useState(null)
  const [allLines, setAllLines] = useState(null)
  const [loadingAnalysis, setLoadingAnalysis] = useState(false)

  async function updateLaneAnalysis() {
    setLoadingAnalysis(true)
    let res = await axios.get(`/main/admin-lane-analysis/`, {
      params: {
        pickup_zip_code: pickupZipCode,
        delivery_zip_code: deliveryZipCode,
        timeframe: dateRange,
      },
    })
    if (res.status < 400) {
      setAllLines(res.data.all_lines)
      setLaneAnalysisData(res.data.quotes)
    } else {
      toast.error('Error analyzing lane')
    }
    setLoadingAnalysis(false)
  }

  return (
    <Segment style={{ minHeight: '40vh', height: '100%' }}>
      <Header as="h2">
        Lane Analysis
        <Button color={'green'} content={'Add Lane'} floated={'right'} />
      </Header>
      <Form>
        <Form.Group>
          <Form.Field>
            <Form.Input
              icon={'pin'}
              iconPosition={'left'}
              placeholder={'Pickup Zip Code'}
              defaultValue={pickupZipCode}
              type={'number'}
              onChange={(e, d) => {
                setPickupZipCode(d.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              icon={'pin'}
              iconPosition={'left'}
              placeholder={'Delivery Zip Code'}
              defaultValue={deliveryZipCode}
              type={'number'}
              onChange={(e, d) => {
                setDeliveryZipCode(d.value)
              }}
            />
          </Form.Field>
          <Form.Field>
            <Menu
              icon
              style={{
                height: '100%',
                borderRadius: '20px',
              }}
            >
              <Menu.Item
                name="day"
                active={dateRange === 'day'}
                onClick={(e, d) => {
                  setDateRange('day')
                }}
                style={{
                  height: '100%',
                  borderTopLeftRadius: '20px',
                  borderBottomLeftRadius: '20px',
                }}
              >
                1D
              </Menu.Item>
              <Menu.Item
                name="month"
                active={dateRange === 'month'}
                onClick={(e, d) => {
                  setDateRange('month')
                }}
              >
                1M
              </Menu.Item>
              <Menu.Item
                name="year"
                active={dateRange === 'year'}
                onClick={(e, d) => {
                  setDateRange('year')
                }}
                style={{
                  height: '100%',
                  borderTopRightRadius: '20px',
                  borderBottomRightRadius: '20px',
                }}
              >
                1Y
              </Menu.Item>
            </Menu>
          </Form.Field>
          <Form.Field>
            <Button
              content="Analyze"
              style={{
                height: '100%',
              }}
              onClick={() => {
                updateLaneAnalysis()
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Segment basic loading={loadingAnalysis}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={730}
            height={300}
            data={laneAnalysisData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="datetime" />
            <YAxis />
            <Tooltip />
            <Legend />
            {allLines &&
              allLines.map((line, index) => {
                return (
                  <Line
                    type="monotone"
                    dataKey={line['name']}
                    stroke={line['color']}
                  />
                )
              })}
          </LineChart>
        </ResponsiveContainer>
      </Segment>
    </Segment>
  )
}

export default AdminDashboardTruckingLane
