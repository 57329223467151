import { Grid } from 'semantic-ui-react'
import './AdminDashboard.css'
import AdminDashboardTruckingLanePriorities from './AdminDashboardTruckingLanePriorities'
import AdminDashboardTruckingStatus from './AdminDashboardTruckingStatus'
import AdminDashboardTruckingLane from './AdminDashboardTruckingLane'
import PageHeader from '../../../components/PageHeader/PageHeader'

function AdminTrucking(props) {
  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
          <Grid.Column
            width={16}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              icon={'bid'}
              header={'Trucking'}
              subheader={
                'Track changes in lane pricing and trucking company status.'
              }
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={'no-top-margin'}>
          <Grid.Column width={8}>
            <AdminDashboardTruckingStatus />
          </Grid.Column>
          <Grid.Column width={8}>
            <AdminDashboardTruckingLane />
          </Grid.Column>
        </Grid.Row>
        {/* TODO API times out
           <Grid.Row>
            <Grid.Column width={16}>
              <AdminDashboardTruckingQuotes />
            </Grid.Column>
          </Grid.Row> */}
        <Grid.Row>
          <Grid.Column width={16}>
            <AdminDashboardTruckingLanePriorities />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AdminTrucking
