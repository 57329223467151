import { Modal, Button } from 'semantic-ui-react'
import PriceHistoryTable from './PriceHistoryTable'

const PriceHistoryModal = (props) => {
  const { showModal, toggleModal, exchangeOrderId, hideAdd } = props
  if (!showModal) return <></>
  return (
    <>
      <div id="priceHistoryModal">
        <Modal
          onClose={() => toggleModal(false)}
          open={showModal}
          size="fullscreen"
        >
          <Modal.Header>Price History</Modal.Header>
          <Modal.Content>
            <PriceHistoryTable
              exchangeOrderId={exchangeOrderId}
              hideHeader={true}
              hideBusiness={true}
              hideAdd={hideAdd}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => toggleModal(false)}
              color={'black'}
              style={{
                padding: '.75rem',
                borderRadius: '500px',
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </>
  )
}

export default PriceHistoryModal
