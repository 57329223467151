import React, { useEffect, useState, useRef } from 'react'
import './Cart.css'
import { Grid, Header, Button, Modal, Accordion } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { formatTotalPrice } from '../../../lib/utils/utils'
import BalanceCheckout from '../../../views/buyer/PlaceOrder/BalanceCheckout'
import { CaretRight } from 'phosphor-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { useAuth } from '../../../lib/auth/useAuth'
import {
  addUser,
  addBusiness,
  addBuyer,
  addSupplier,
  addLocations,
} from '../../../features/user/userSlice'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'

export default function Cart(props) {
  // Props
  const {
    balanceLoading,
    setSetCartItems,
    setSidebarVisible,
    renderBalanceCheckout,
  } = props
  // State
  const [orderId, setOrderId] = useState(null)
  const [order, setOrder] = useState(props.order)
  const [activeIndexes, setActiveIndexes] = useState([])
  const [currentTerms, setCurrentTerms] = useState('ACH')
  const [openApplicationModal, setOpenApplicationModal] = useState(false)
  const [openCreateLocationsModal, setOpenCreateLocationsModal] = useState(null)
  // Hooks
  const auth = useAuth()
  const dispatch = useDispatch()
  const wd = useWindowDimensions()
  // Vars
  const currentHeaderHighlight = 1

  const resetCart = () => {
    setOrderId(null)
    setOrder(null)
    setSetCartItems(false)
    setSetCartItems(true)
    setSidebarVisible(false)
  }

  const messagesEndRef = useRef(null)
  const createdAtDate = new Date(order?.created_at).toDateString()

  useEffect(() => {
    if (props.currentTerms) {
      setCurrentTerms(props.currentTerms)
    }
  }, [props?.currentTerms])

  const handleIndexToggleCargo = (ind) => {
    let idx = activeIndexes.indexOf(ind)
    let copyActiveIndexes = [...activeIndexes]
    if (idx >= 0) {
      copyActiveIndexes.splice(idx, 1)
      setActiveIndexes(copyActiveIndexes)
    } else {
      copyActiveIndexes.push(ind)
      setActiveIndexes(copyActiveIndexes)
    }
  }

  let dryVanCount = 0
  let flatBedCount = 0

  useEffect(() => {
    let lines = props.lineItems?.map((a, i) => {
      return i
    })
    setActiveIndexes(lines)
  }, [])

  return (
    <div id="cart-wrapper" style={props.style}>
      <Grid stackable ref={messagesEndRef} className="cart-inner-wrapper">
        <Grid.Column width={16}>
          {order && orderId && props.invoice && (
            <BalanceCheckout
              orderId={orderId}
              order={order}
              invoice={props.invoice}
              setReset
              resetCart={resetCart}
              setOrderId={setOrderId}
              setOrder={setOrder}
              lineItems={props.lineItems}
            />
          )}
          <>
            <Header
              color={currentHeaderHighlight === 3 ? 'orange' : 'black'}
              style={{
                display: 'inline-block',
                marginRight: '20px',
                width: '100%',
              }}
            >
              <Header as={'h2'} className="cart-order-number">
                Order Sent From {env.REACT_APP_BUSINESS_NAME}
                <Header.Subheader
                  as={'p'}
                  style={{ marginTop: '10px', fontSize: '14px' }}
                >
                  {createdAtDate}
                </Header.Subheader>
              </Header>
            </Header>
            <Grid.Row>
              <Accordion style={{ width: '100%' }}>
                {props.lineItems?.map((lineItem, index) => {
                  if (
                    lineItem?.cargo_unit_instance?.cargo_unit?.singular_name ===
                    "Dry Van 53'"
                  ) {
                    dryVanCount = dryVanCount + 1
                  }
                  if (
                    lineItem?.cargo_unit_instance?.cargo_unit?.singular_name ===
                    "Flat Bed 53'"
                  ) {
                    flatBedCount = flatBedCount + 1
                  }
                  return (
                    <>
                      <Accordion.Title
                        id="cart-item-title"
                        active={activeIndexes.includes(index)}
                        onClick={() => handleIndexToggleCargo(index)}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ display: 'inline-flex' }}>
                            <CaretRight
                              size={20}
                              style={{
                                transition: '.2s ease-in-out',
                                transform: activeIndexes.includes(index)
                                  ? 'rotate(90deg)'
                                  : '',
                              }}
                            />
                            {lineItem?.cargo_unit_instance && (
                              <>
                                <span style={{ marginLeft: '10px' }}>
                                  <b>
                                    {
                                      lineItem?.cargo_unit_instance?.cargo_unit
                                        ?.singular_name
                                    }{' '}
                                    {lineItem?.cargo_unit_instance?.cargo_unit
                                      ?.singular_name === "Dry Van 53'"
                                      ? dryVanCount
                                      : flatBedCount}
                                  </b>
                                </span>
                              </>
                            )}
                          </div>
                          <div className="price-wrapper"></div>
                        </div>
                      </Accordion.Title>
                      <Accordion.Content
                        id="cart-item-content"
                        active={activeIndexes.includes(index)}
                      >
                        {lineItem?.lineItems?.map((li, idx) => {
                          return li?.order_item ? (
                            <>
                              <Accordion.Title
                                id="cart-item-title"
                                active={true}
                              >
                                <div className="icon-title-wrapper">
                                  <OrderItemDetailTwo
                                    orderItem={li?.order_item}
                                  />
                                </div>
                              </Accordion.Title>
                              <Accordion.Content active={true}>
                                <div className="details-wrapper">
                                  <div className="space-between-wrapper">
                                    <p>Price:</p>
                                    <div className="units-wrapper">
                                      <p>
                                        {' '}
                                        {formatTotalPrice(
                                          li.order_item?.delivered_price
                                        )}{' '}
                                        / {li?.order_item?.unit?.short_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="details-wrapper">
                                  <div className="space-between-wrapper">
                                    <p>Quantity:</p>
                                    <div className="units-wrapper">
                                      <p>
                                        {' '}
                                        {li?.quantity}{' '}
                                        {li?.order_item?.unit?.short_name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="details-wrapper">
                                  <div className="space-between-wrapper">
                                    <p>
                                      <b>Total:</b>
                                    </p>
                                    <div className="units-wrapper">
                                      <b>
                                        {formatTotalPrice(
                                          li?.quantity *
                                            li?.order_item?.delivered_price
                                        )}
                                      </b>
                                    </div>
                                  </div>
                                </div>
                              </Accordion.Content>
                            </>
                          ) : (
                            <div className="details-wrapper">
                              <div className="space-between-wrapper">
                                <p>{li?.title}:</p>
                                <div className="price-wrapper">
                                  {formatTotalPrice(li?.price)}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        {lineItem?.cargo_unit_instance && (
                          <div className="total-wrapper">
                            <div className="space-between-wrapper">
                              <p>
                                <b>Truck Total</b>:
                              </p>
                              <div className="price-wrapper">
                                <b>
                                  {formatTotalPrice(
                                    lineItem?.total_items_price
                                  )}
                                </b>
                              </div>
                            </div>
                          </div>
                        )}
                        {lineItem?.cargo_unit_instance && (
                          <div className="address-wrapper">
                            <div className="space-between-wrapper">
                              <p>
                                <b>Shipping Address: </b>
                              </p>
                              <div>
                                {
                                  lineItem?.cargo_unit_instance
                                    ?.delivery_location?.address_1
                                }
                                ,{' '}
                                {lineItem?.cargo_unit_instance
                                  ?.delivery_location?.address_2 && (
                                  <span>
                                    {
                                      lineItem?.cargo_unit_instance
                                        ?.delivery_location?.address_2
                                    }
                                    ,{' '}
                                  </span>
                                )}
                                {
                                  lineItem?.cargo_unit_instance
                                    ?.delivery_location?.city
                                }
                                ,{' '}
                                {
                                  lineItem?.cargo_unit_instance
                                    ?.delivery_location?.state_short_code
                                }{' '}
                                {
                                  lineItem?.cargo_unit_instance
                                    ?.delivery_location?.zip_code
                                }
                                ,{' '}
                                {
                                  lineItem?.cargo_unit_instance
                                    ?.delivery_location?.country
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </Accordion.Content>
                    </>
                  )
                })}
              </Accordion>
              <div style={{ marginTop: '60px', marginBottom: '40px' }}>
                {props.creditAmount && props.creditAmount > 0 ? (
                  <div
                    className="total-wrapper"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <p>Terms / Credit Card Fee:</p>
                    <b>{formatTotalPrice(props.creditAmount)}</b>
                  </div>
                ) : (
                  ''
                )}
                {order?.sales_tax_total > 0 && (
                  <div
                    className="total-wrapper"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <p>Sales Tax:</p>
                    <b>{formatTotalPrice(props?.sales_tax_total)}</b>
                  </div>
                )}
                {order?.mx_order && order?.buyer_marketplace_fee > 0 && (
                  <div
                    className="total-wrapper"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <p>Marketplace Fee:</p>
                    <b>{formatTotalPrice(order?.buyer_marketplace_fee)}</b>
                  </div>
                )}
                <div
                  className="total-wrapper"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap',
                  }}
                >
                  <b>Total:</b>
                  <b>
                    {props.totalPrice &&
                      formatTotalPrice(
                        props.creditAmount && parseFloat(props.creditAmount) > 0
                          ? props.totalPrice + parseFloat(props.creditAmount)
                          : props.totalPrice
                      )}
                  </b>
                </div>
              </div>
            </Grid.Row>
          </>
        </Grid.Column>
        {!order?.paid && wd?.width > 768 && (
          <div className="mobile-checkout-button-wrapper">
            <div className="mobile-checkout-button-inner-wrapper">
              <Button
                style={{
                  width: '70%',
                  padding: '15px',
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
                content={'Proceed to Checkout'}
                color={'primary'}
                circular
                disabled={balanceLoading}
                loading={balanceLoading}
                onClick={() => {
                  if (balanceLoading) {
                    return
                  }
                  if (currentTerms) {
                    renderBalanceCheckout()
                  } else {
                    toast.error('Please select a payment term.')
                  }
                }}
              />
            </div>
            <p style={{ fontFamily: 'Brown Light', marginTop: '1rem' }}>
              Powered By <span style={{ fontFamily: 'Brown' }}>Ba/ance</span>
            </p>
            <p style={{ marginTop: '1rem' }}>
              Need Help?{' '}
              <a
                className="contact-us"
                style={{ fontWeight: 'bold' }}
                href="/contact-us"
              >
                Contact Us
              </a>
            </p>
          </div>
        )}
      </Grid>
      <Modal
        onClose={() => setOpenApplicationModal(false)}
        onOpen={() => setOpenApplicationModal(true)}
        open={openApplicationModal}
      >
        <Modal.Header>Add Business Information</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'BusinessApplicationForm'}
            onSubmit={(values) => {}}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenCreateLocationsModal(false)}
        onOpen={() => setOpenCreateLocationsModal(true)}
        open={openCreateLocationsModal}
      >
        <Modal.Header>Create Account</Modal.Header>
        <Modal.Content>
          <MickeyForm
            model={'Business'}
            formName={'PublicOrderBusinessForm'}
            buttonLabel={'Create Account'}
            url={'/main/p-businesses/'}
            values={{
              anonymous_cart: localStorage.getItem('anonymous_cart'),
              is_buyer: true,
            }}
            handleForm={async (status, data) => {
              if (status === 'success') {
                localStorage.setItem('anonymous_cart', '')
                setOpenCreateLocationsModal(false)
                setSidebarVisible(true)
                const response = await auth.signin(data?.email, data?.password)
                if (response?.data?.user) {
                  dispatch(addUser(response?.data.user))
                  if (response?.data.business) {
                    dispatch(addBusiness(response?.data.business))
                  }
                  if (response?.data.locations) {
                    dispatch(addLocations(response?.data.locations))
                  }
                  if (response.data.user?.business?.buyer) {
                    dispatch(addBuyer(response.data.user?.business.buyer))
                  }
                  if (response.data.user?.business?.supplier) {
                    dispatch(
                      addSupplier(response.data.user?.business?.supplier)
                    )
                  }
                }
              }
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}
