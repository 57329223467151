import MickeySearch from '../../../../components/Search/MickeySearch'
import {
  Header,
  Divider,
  PopupContent,
  Table,
  Dimmer,
  Loader,
} from 'semantic-ui-react'
import { X, Circle, CheckCircle, MapPin, Plus } from 'phosphor-react'
import MickeyForm from '../../../../components/Forms/MickeyForm'
import env from '../../../../env'
import './MXHeaderPopups.css'
import { useEffect, useState } from 'react'
import useAxios from '../../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { hasPermission } from '../../../../lib/utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { addCurrentLocation } from '../../../../features/user/userSlice'

const MXNewLocation = (props) => {
  const axios = useAxios()
  const {
    loading,
    business,
    locations,
    setLoading,
    editLocation,
    showLocationForm,
    updatePublicLocation,
    currentSelectedLocation,
    setCurrentSelectedLocation,
    setCurrentlySelectedLocation,
  } = props
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.user.user)
  const [searchSelectedValue, setSearchSelectedValue] = useState(null)
  const [addNewAddressModalForm, setAddNewAddressModalForm] = useState(false)
  const [searchAddressPlaceId, setSearchAddressPlaceId] = useState()
  const [newLocationData, setNewLocationData] = useState({})
  const wd = useWindowDimensions()
  const mobileView = wd.width <= 768

  useEffect(() => {
    if (searchSelectedValue?.id) {
      setCurrentSelectedLocation(searchSelectedValue)
      setCurrentlySelectedLocation(searchSelectedValue?.id)
      dispatch(addCurrentLocation(searchSelectedValue?.id))
    }
  }, [searchSelectedValue?.id])

  const getNewSelectedLocationData = async () => {
    setLoading(true)
    let res = await axios.get(`/main/locations/get_location_of_place/`, {
      params: {
        place_id: searchAddressPlaceId,
      },
    })
    if (res?.data && !res?.data?.error) {
      setNewLocationData(res?.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (searchAddressPlaceId && reduxUser) {
      getNewSelectedLocationData()
    } else if (!reduxUser && searchAddressPlaceId) {
      props.setOpenLocationPopup(false)
      updatePublicLocation(searchAddressPlaceId)
    }
  }, [searchAddressPlaceId])

  const handleForm = async (message, data) => {
    if (message === 'success') {
      toast.success('Location added.')
      props.getLocations()
      setNewLocationData()
      setAddNewAddressModalForm(false)
      setSearchAddressPlaceId()
      setSearchSelectedValue()
      props.setOpenLocationPopup(false)
      setCurrentSelectedLocation(data)
      setCurrentlySelectedLocation(data?.id)
    }
  }

  return (
    <>
      {!showLocationForm &&
        !editLocation &&
        !addNewAddressModalForm &&
        reduxUser && (
          <PopupContent
            style={{
              minWidth: mobileView ? '100%' : '400px',
              position: 'relative',
            }}
          >
            <div style={{ padding: '0px 10px' }}>
              <Header
                as="h4"
                style={{
                  margin: '10px 0 20px',
                  marginLeft: '10px',
                  padding: '0',
                  textAlign: 'start',
                }}
              >
                {props.header ? props.header : 'Select Location'}
              </Header>
              <MapPin
                size={18}
                style={{
                  position: 'absolute',
                  zIndex: 9999,
                  margin: '10px',
                }}
              />
              <MickeySearch
                placeholder={'Search My Addresses'}
                url="/main/locations/"
                name={'locations'}
                titleFields={['address_1']}
                iconPosition="left"
                descriptionFields={['city', 'state', 'zip_code']}
                setSelectionDataObject={setSearchSelectedValue}
                urlParams={{
                  business_id: business?.id,
                }}
                emptyFieldAfterRedirect={true}
                tenantAware={false}
                hideIcons={true}
                autoFocus
                style={{
                  minWidth: '20vw',
                  margin: '15px 0',
                }}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            {hasPermission(reduxUser, 'Create Location') && (
              <>
                <Divider style={{ margin: '0px' }} />
                <div
                  className={'add-location'}
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  onClick={() => setAddNewAddressModalForm(true)}
                >
                  <Plus
                    size={15}
                    weight="bold"
                    style={{ cursor: 'pointer', margin: '0 10px' }}
                  />
                  <div style={{ marginLeft: '10px' }}>Add New Location</div>
                </div>
              </>
            )}
            <Divider style={{ margin: '0px' }} />
            {props.myAsk && (
              <>
                {currentSelectedLocation?.id === 0 ? (
                  <div
                    className="add-location"
                    style={{ color: env.REACT_APP_PRIMARY_COLOR }}
                  >
                    <CheckCircle
                      size={15}
                      weight="bold"
                      style={{ cursor: 'pointer', margin: '0 10px' }}
                    />
                    <div style={{ marginLeft: '10px' }}>All Locations</div>
                  </div>
                ) : (
                  <div
                    className="unselected-location"
                    onClick={() => {
                      setCurrentSelectedLocation({ id: 0 })
                    }}
                  >
                    <Circle
                      size={15}
                      weight="bold"
                      style={{ margin: '0 10px' }}
                    />
                    <div style={{ marginLeft: '10px' }}>All Locations</div>
                  </div>
                )}
                <Divider style={{ margin: '0px' }} />
              </>
            )}
            <div
              style={{ height: '200px', maxHeight: '250px', overflow: 'auto' }}
            >
              {currentSelectedLocation && currentSelectedLocation?.id !== 0 && (
                <>
                  <div
                    className="add-location"
                    style={{ color: env.REACT_APP_PRIMARY_COLOR }}
                  >
                    <CheckCircle
                      size={15}
                      weight="bold"
                      style={{ cursor: 'pointer', margin: '0 10px' }}
                    />
                    <div style={{ marginLeft: '10px' }}>
                      <p style={{ margin: '0' }}>
                        {currentSelectedLocation?.address_1}
                        {currentSelectedLocation?.address_2
                          ? ' ' + currentSelectedLocation?.address_2
                          : ''}
                      </p>
                      <p style={{ margin: '0' }}>
                        {currentSelectedLocation?.city},{' '}
                        {currentSelectedLocation?.state}{' '}
                        {currentSelectedLocation?.zip_code}
                      </p>
                    </div>
                  </div>
                  <Divider style={{ margin: '0px' }} />
                </>
              )}
              {locations &&
                locations?.map((location) => {
                  return (
                    location.id !== currentSelectedLocation?.id &&
                    location.id !== 0 && (
                      <div key={location?.id}>
                        <div
                          className="unselected-location"
                          onClick={() => {
                            setCurrentSelectedLocation(location)
                            setCurrentlySelectedLocation(location?.id)
                          }}
                        >
                          <Circle
                            size={15}
                            weight="bold"
                            style={{ margin: '0 10px' }}
                          />
                          <div style={{ marginLeft: '10px' }}>
                            <p style={{ margin: '0' }}>
                              {location.address_1}
                              {location.address_2
                                ? ' ' + location.address_2
                                : ''}
                            </p>
                            <p style={{ margin: '0' }}>
                              {location.city}, {location.state}{' '}
                              {location.zip_code}
                            </p>
                          </div>
                        </div>
                        <Divider style={{ margin: '0px' }} />
                      </div>
                    )
                  )
                })}
            </div>
          </PopupContent>
        )}
      {(addNewAddressModalForm || !reduxUser) && (
        <PopupContent
          style={{
            minWidth: mobileView ? '100%' : '400px',
            minHeight: '350px',
            position: 'relative',
          }}
        >
          <Header style={{ padding: '10px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'space-between',
                paddingLeft: '10px',
              }}
            >
              <div>{!reduxUser ? 'Select Location' : 'Create New Address'}</div>
              <X
                size={20}
                style={{ cursor: 'pointer', marginTop: '-5px' }}
                onClick={() => {
                  setAddNewAddressModalForm(false)
                  setSearchAddressPlaceId()
                  setNewLocationData()
                }}
              />
            </div>
          </Header>
          {!searchAddressPlaceId && (
            <div style={{ padding: '0 10px' }}>
              <MapPin
                size={18}
                style={{ position: 'absolute', zIndex: 9999, margin: '10px' }}
              />
              <MickeySearch
                placeholder={'Search Address'}
                url={`/main/${!reduxUser ? 'p-' : ''}locations/`}
                name={'locations'}
                titleFields={['description']}
                iconPosition="left"
                descriptionFields={['desc']}
                urlParams={{
                  business_id: business?.id,
                  tenant_aware: false,
                  auto_complete: true,
                }}
                hideIcons={true}
                autoFocus
                setSelection={setSearchAddressPlaceId}
                style={{
                  minWidth: '20vw',
                  margin: '15px 0',
                }}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          )}
          {searchAddressPlaceId && loading && (
            <Dimmer inverted active>
              <Loader>Loading</Loader>
            </Dimmer>
          )}
          {searchAddressPlaceId && !loading && reduxUser && (
            <div
              style={{
                paddingLeft: '10px',
                maxHeight: '500px',
                overflow: 'auto',
              }}
            >
              <div>
                {newLocationData ? (
                  <div style={{ padding: '10px', fontSize: '16px' }}>
                    <p style={{ margin: '0' }}>{newLocationData?.address_1}</p>
                    <p
                      style={{ margin: '0' }}
                    >{`${newLocationData?.state}, ${newLocationData?.city} ${newLocationData?.zip_code}`}</p>
                  </div>
                ) : null}
              </div>
              <MickeyForm
                formName={'LocationUpdateForm'}
                model={'Location'}
                url={'/main/locations/'}
                values={{
                  place_id: searchAddressPlaceId,
                  auto_complete: true,
                  business_id: business?.id,
                }}
                tenantAware={false}
                style={{ padding: '10px' }}
                handleForm={handleForm}
                buttons={[
                  {
                    content: 'Back',
                    style: {
                      width: '80px',
                      height: '40px',
                      '--secondaryColor': 'white',
                      '--primaryColor': 'darkgray',
                    },
                    onClick: () => {
                      setAddNewAddressModalForm(false)
                      setSearchAddressPlaceId()
                      setNewLocationData()
                    },
                  },
                  {
                    content: 'Create New Address',
                    type: 'submit',
                    disabled: true,
                    style: {
                      width: '100%',
                      height: '40px',
                      position: 'relative',
                      '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    },
                  },
                ]}
              />
            </div>
          )}
        </PopupContent>
      )}
    </>
  )
}

const MXTimeFrame = (props) => {
  const user = props.user
  const updateCurrentTimeframe = props.updateCurrentTimeframe

  return (
    <PopupContent style={{ minWidth: '20rem' }}>
      <Header
        as="h4"
        style={{
          margin: '22px 0',
          marginLeft: '10px',
          padding: '0',
          textAlign: 'start',
        }}
      >
        Select Timeframe
      </Header>
      <Divider />
      <>
        <Table.Row
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            opacity:
              user && user?.current_delivery_timeframe !== 'Prompt' && '0.4',
            color:
              user && user?.current_delivery_timeframe === 'Prompt'
                ? env.REACT_APP_PRIMARY_COLOR
                : '',
          }}
          onClick={() => updateCurrentTimeframe('Prompt')}
        >
          <Table.Cell style={{ padding: '0 10px' }}>
            {user?.current_delivery_timeframe === 'Prompt' ? (
              <CheckCircle size={15} weight="bold" />
            ) : (
              <Circle
                size={15}
                weight="bold"
                style={{ margin: 'auto', cursor: 'pointer' }}
              />
            )}
          </Table.Cell>
          <Table.Cell style={{ padding: '0 10px' }}>
            <p style={{ margin: '0' }}>Prompt</p>
          </Table.Cell>
          <Table.Cell style={{ padding: '0 10px' }}></Table.Cell>
        </Table.Row>
        <Divider />
        <Table.Row
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            opacity: user?.current_delivery_timeframe !== '2-4 Weeks' && '0.4',
            color:
              user?.current_delivery_timeframe === '2-4 Weeks'
                ? env.REACT_APP_PRIMARY_COLOR
                : '',
          }}
          onClick={() => updateCurrentTimeframe('2-4 Weeks')}
        >
          <Table.Cell style={{ padding: '0 10px' }}>
            {user?.current_delivery_timeframe === '2-4 Weeks' ? (
              <CheckCircle size={15} weight="bold" />
            ) : (
              <Circle
                size={15}
                weight="bold"
                style={{ margin: 'auto', cursor: 'pointer' }}
              />
            )}
          </Table.Cell>
          <Table.Cell style={{ padding: '0 10px' }}>
            <p style={{ margin: '0' }}>2-4 Weeks</p>
          </Table.Cell>
          <Table.Cell style={{ padding: '0 10px' }}></Table.Cell>
        </Table.Row>
        <Divider />
      </>
    </PopupContent>
  )
}

const MXCargoUnit = (props) => {
  const user = props.user
  const equipmentTypes = props.equipmentTypes
  const setEquipmentType = props.setEquipmentType
  const reduxUser = useSelector((state) => state.user.user)

  return (
    <PopupContent style={{ minWidth: '20rem' }}>
      <Header
        as="h4"
        style={{
          margin: '22px 0',
          marginLeft: '10px',
          padding: '0',
          textAlign: 'Start',
        }}
      >
        Select {props.currentPortal === 'supplier' && 'Pickup'}
        {props.currentPortal === 'buyer' && 'Delivery'}
        {!reduxUser && 'Transport'} Type
      </Header>
      <Divider />
      {equipmentTypes?.map((new_equipmentType, idx) => {
        return (
          <div key={idx}>
            <Table.Row
              style={{
                cursor: 'pointer',
                fontWeight: 'bold',
                opacity:
                  user &&
                  new_equipmentType?.id !== user?.current_equipment_type?.id &&
                  '0.4',
                color:
                  user &&
                  new_equipmentType?.id === user?.current_equipment_type?.id
                    ? env.REACT_APP_PRIMARY_COLOR
                    : '',
              }}
              onClick={() => {
                if (reduxUser) {
                  setEquipmentType(new_equipmentType.id)
                } else {
                  toast.error('Please login to change Equipment Type')
                }
              }}
            >
              <Table.Cell style={{ padding: '0 10px' }}>
                {user &&
                new_equipmentType?.id === user?.current_equipment_type?.id ? (
                  <CheckCircle size={15} weight="bold" />
                ) : (
                  <Circle
                    size={15}
                    weight="bold"
                    style={{ margin: 'auto', cursor: 'pointer' }}
                  />
                )}
              </Table.Cell>
              <Table.Cell style={{ padding: '0 10px' }}>
                <p style={{ margin: '0' }}>
                  {new_equipmentType?.singular_name}
                </p>
              </Table.Cell>
              <Table.Cell style={{ padding: '0 10px' }}></Table.Cell>
            </Table.Row>
            <Divider />
          </div>
        )
      })}
    </PopupContent>
  )
}

export { MXNewLocation, MXTimeFrame, MXCargoUnit }
