import React from 'react'
import { Grid } from 'semantic-ui-react'
import _ from 'underscore'
import MickeyTable from '../MickeyTable/MickeyTable'
import PageHeader from '../PageHeader/PageHeader'
import DocumentTableRow from './DocumentTableRow'
import './DocumentTable.css'

const DocumentTable = (props) => {
  const {
    orderId,
    businessId,
    hideHeader,
    reload,
    setReload,
    canDelete,
    includeESignLogs,
  } = props
  const headerRow = ['Order Number', 'Document Type', 'Date Created', 'Action']
  const headerSort = [
    {
      header: 'Order Number',
      sort_by: 'order__order_number',
    },
    {
      header: 'Document Type',
      sort_by: 'document_type',
    },
    {
      header: 'Date Created',
      sort_by: 'created_at',
    },
  ]
  const headerSearch = [
    {
      header: 'Order Number',
      type: 'search',
      input_type: 'text',
      search_param: 'order__order_number__icontains',
    },
    {
      header: 'Document Type',
      type: 'dropdown',
      options: [
        {
          text: 'Proforma Invoice',
          value: 'Proforma Invoice',
        },
        {
          text: 'Commercial Invoice',
          value: 'Commercial Invoice',
        },
        {
          text: 'Supplier Invoice',
          value: 'Supplier Invoice',
        },
        {
          text: 'Bill of Lading',
          value: 'Bill of Lading',
        },
        {
          text: 'Packing List',
          value: 'Packing List',
        },
        {
          text: 'Sell Side Order Confirmation',
          value: 'Sell Side Order Confirmation',
        },
        {
          text: 'Buy Side Order Confirmation',
          value: 'Buy Side Order Confirmation',
        },
        {
          text: 'Trucking Release',
          value: 'Trucking Release',
        },
        {
          text: 'Proof of Delivery',
          value: 'Proof of Delivery',
        },
        {
          text: 'Other',
          value: 'Other',
        },
      ],
      search_param: 'document_type',
    },
    {
      header: 'Date Created',
      type: 'date',
      type: 'date_range',
      search_param_start: 'created_at_start',
      search_param_end: 'created_at_end',
    },
  ]

  const urlParams = {}
  if (orderId) {
    urlParams.order_id = orderId
  }
  if (businessId) {
    urlParams.business_id = businessId
  }
  if (includeESignLogs) {
    urlParams.include_box_signing_logs = true
  }

  return (
    <>
      <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
        {!hideHeader && (
          <Grid.Column
            width={8}
            className={'no-bottom-margin no-bottom-padding'}
          >
            <PageHeader
              header={'Documents'}
              showDivider
              className={
                'no-bottom-margin no-bottom-padding no-top-margin no-top-padding'
              }
              dividerClassName={'no-bottom-margin no-bottom-padding'}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={8}>
          <MickeyTable
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            reload={reload}
            setReload={setReload}
            url={'/main/documents/'}
            renderBodyRow={(data) => {
              return (
                <DocumentTableRow
                  document={data}
                  setReload={setReload}
                  canDelete={canDelete}
                />
              )
            }}
            emptyIcon={'file'}
            emptyMessage={'No Documents'}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default DocumentTable
