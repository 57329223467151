export const AddNewExchangeListingForm = {
  model: 'ExchangeOrderBook',
  format: [
    {
      widths: 'equal',
      fields: {
        commodity: {
          required: true,
          type: 'dropdown search',
          label: 'Select A Commodity',
          url: '/main/commodities/',
          title_fields: ['name'],
          description_fields: [''],
        },
        product: {
          required: true,
          type: 'dropdown search',
          label: 'Select A Product',
          url: '/main/products/',
          title_fields: ['material.name'],
          description_fields: ['commodity_form.name'],
        },
      },
    },
  ],
}
