import React from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import env from '../../../env'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

const NotificationTriggerRow = (props) => {
  const { row, setReload } = props
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)

  const handleRemoveTrigger = async () => {
    await axios.post(`/main/notification-triggers/batch_delete/`, {
      instance_ids: props.is_admin ? row?.instance_ids : [row.id],
    })
    setReload(true)
  }
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  return (
    <>
      <Table.Row key={row.htmlId} id={row.htmlId} selectable="true">
        <Table.Cell textAlign={'left'} width={1}>
          <strong>{toTitleCase(row?.notification_sender.name)}</strong>
        </Table.Cell>
        <Table.Cell textAlign={'left'} width={props.is_admin ? 3 : 4}>
          <b>Product:</b> {row?.product?.material?.name}
          <br></br>
          <b>Attributes</b> <br />
          {Object.keys(row?.attribute_selections_json).map((key, index) => {
            return (
              <>
                {key}: {row?.attribute_selections_json[key].join(', ')}
                <br />
              </>
            )
          })}
        </Table.Cell>
        <Table.Cell textAlign={'left'} width={props.is_admin ? 3 : 4}>
          {formatTotalPrice(row.price_range_start)} -{' '}
          {formatTotalPrice(row.price_range_end)}
        </Table.Cell>
        <Table.Cell textAlign={'left'} width={4}>
          {`${row?.location?.address_1}${
            row?.location?.address_2 ? ' ' + row?.location?.address_2 : ''
          }, ${row?.location?.city}, ${row?.location?.state} ${
            row?.location?.zip_code
          }`}
        </Table.Cell>
        <Table.Cell textAlign={'left'} width={props.is_admin ? 3 : 4}>
          {props.is_admin
            ? row?.users?.map((user) => {
                return <p>{`${user?.first_name} ${user?.last_name}`}</p>
              })
            : `${row?.user?.first_name} ${row?.user?.last_name}`}
        </Table.Cell>
        {props.is_admin && (
          <Table.Cell textAlign={'left'} width={2}>
            {row?.side === 'ask' ? 'Supplier' : 'Buyer'}
          </Table.Cell>
        )}
        {hasPermission(user, 'Delete Notification') && (
          <Table.Cell textAlign={'right'} width={1}>
            <Button
              color="primary"
              className="inverted"
              icon
              onClick={async () => handleRemoveTrigger()}
              style={{
                fontSize: '1rem',
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
            >
              <Icon name="trash" />
              Delete
            </Button>
          </Table.Cell>
        )}
      </Table.Row>
    </>
  )
}

export default NotificationTriggerRow
