export const AdminSupplierTakeAdjustmentForm = {
  model: 'Supplier',
  format: [
    {
      widths: 'equal',
      fields: {
        supplier_take_adjustment: {
          type: 'number',
          step: '0.1',
          min: -100,
          max: 100,
          round: 2,
          validatorInputs: ['global-supplier-take-rate'],
          validator: (input, globalSupplierTakeRate) => {
            let error
            let float
            if (input) {
              if (isNaN(input)) {
                error =
                  'Number greater than -100 and less than or equal to 100 required'
              } else {
                float = parseFloat(input)
                if (float + globalSupplierTakeRate < 0) {
                  error = 'Effective take rate cannot be less than 0'
                }
              }
            } else {
              error =
                'Number greater than -100 and less than or equal to 100 required'
            }
            return error
          },
          label: 'Supplier Take Rate',
          icon: { name: 'percent', circular: false, link: false },
        },
      },
    },
  ],
}
