import PageHeader from '../../../../components/PageHeader/PageHeader'
import AdminCalendar from '../../AdminCalendar/AdminCalendar'

const AdminSalesCalendar = (props) => {
  const { salespersonId } = props
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/orders' },
    { name: 'Sales', href: '/portal/admin/sales' },
  ]
  return (
    <>
      <PageHeader
        header="Calender"
        breadcrumbHeader="Calender"
        breadcrumb={breadcrumbData}
      />
      <AdminCalendar
        salespersonId={salespersonId}
        headerToolbar={{
          right: 'today prev,next',
          center: 'title',
          left: null,
        }}
      />
    </>
  )
}

export default AdminSalesCalendar
