import React, { useEffect, useState, useMemo } from 'react'
import { Modal, Header, Button, Input, Grid, Dropdown } from 'semantic-ui-react'
import SelectProductListingTriggers from './SelectProductListingTriggers'
import SelectNotifiedUsers from './SelectNotifiedUsers'
import './AddNotificationTrigger.css'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import { RangeSlider } from 'rsuite'
import { toast } from 'react-toastify'

const AddNotificationTriggers = (props) => {
  const { showModal, setShowModal, userId, setReloadTriggersTable } = props
  const [modalSize, setModalSize] = useState('small')
  // const [ view, setView ] = useState('notification trigger')
  const [view, setView] = useState('New product listing')
  const [notificationSenders, setNotificationSenders] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [triggerType, setTriggerType] = useState(null)
  const [product, setProduct] = useState(null)
  const [currentTab, setCurrentTab] = useState(1)
  const [productAttributes, setProductAttributes] = useState({})
  const [AttributesPos, setProductAttributesPos] = useState({})
  const [priceRange, setPriceRange] = useState([0, 5000])
  const [attributeValues, setAttributeValues] = useState({})
  const [selectedPortal, setSelectedPortal] = useState()
  const [portalOptions, setPortalOptions] = useState()
  const axios = useAxios()
  const headers = useMemo(() => {
    return [
      'Select Product And Attributes',
      'Select Price Range',
      'Add Notification Trigger',
      'Add Who Will Receive These Notifications',
      'Select Portal',
    ]
  }, [])
  const discription = useMemo(() => {
    return [
      'Select all product and attributes you would like to be notified about.',
      'Select the price range to be alert and press next',
      'Select the notification trigger and press continue',
      'Select all users you would like to notified on',
      'Select the notification trigger for buyer or supplier',
    ]
  }, [])

  const [modalHeader, setModalHeader] = useState(headers[0])
  const [modalDiscription, setModalDiscription] = useState(discription[0])

  useEffect(() => {
    if (props.admin) {
      let portalOptions = []
      props.business?.supplier?.id &&
        portalOptions.push({ key: 'Supplier', text: 'Supplier', value: 'ask' })
      props.business?.buyer?.id &&
        portalOptions.push({ key: 'Buyer', text: 'Buyer', value: 'bid' })
      setPortalOptions(portalOptions)
    }
  }, [props.admin])

  const handleNotificationTriggerSelect = (trigger) => {
    setView(trigger.label)
    if (trigger.label === 'New product listing') {
      setModalSize('fullscreen')
    }
    setNotificationSenders(trigger.notificationSenders)
  }

  useEffect(() => {
    setView(
      currentTab === 1
        ? 'New product listing'
        : currentTab === 2
        ? 'price range'
        : currentTab === 3
        ? 'notification trigger'
        : currentTab === 4
        ? 'notified users'
        : null
    )
    setModalHeader(
      currentTab === 1
        ? headers[0]
        : currentTab === 2
        ? headers[1]
        : currentTab === 3
        ? headers[2]
        : currentTab === 4
        ? headers[3]
        : null
    )
    setModalDiscription(
      currentTab === 1
        ? discription[0]
        : currentTab === 2
        ? discription[1]
        : currentTab === 3
        ? discription[2]
        : currentTab === 4
        ? discription[3]
        : null
    )
    if (currentTab === 0) {
      setShowModal(false)
    }
    if (props.admin && currentTab === 5) {
      setView('portal')
      setModalHeader(headers[4])
      setModalDiscription(discription[4])
    }
  }, [currentTab, setShowModal])

  const closeModal = () => {
    setShowModal(false)
    setView('New product listing')
    setSelectedUsers([])
    setTriggerType(null)
    setProduct(null)
    setModalHeader(headers[0])
    setModalDiscription(discription[0])
    setNotificationSenders(null)
    setProductAttributes({})
    setCurrentTab(1)
    setProductAttributesPos([])
    setPriceRange([0, 0])
    setSelectedPortal()
  }

  const createNotificationSenders = async () => {
    let notificaiton_trigger = {
      user_ids: selectedUsers,
      product_id: product,
      location_id: selectedLocation,
      side: props.admin
        ? selectedPortal
        : props.currentPortal === 'supplier'
        ? 'ask'
        : 'bid',
      attribute_selections_json: attributeValues,
      price_range_start: priceRange[0],
      price_range_end: priceRange[1],
    }
    await axios
      .post('/main/notification-triggers/batch/', {
        notification_trigger: notificaiton_trigger,
        trigger_types: triggerType,
      })
      .then((response) => {
        setAttributeValues({})
        setReloadTriggersTable(true)
      })
      .catch((error) => {
        // Silently fail if the user tried to create a duplicate trigger
      })
    closeModal()
  }

  if (showModal) {
    return (
      <>
        <Modal
          onClose={() => closeModal()}
          onOpen={() => setShowModal(true)}
          open={showModal}
          closeOnDimmerClick={false}
          closeOnEscape={false}
          closeIcon={true}
          size={modalSize}
          style={{ minWidth: '1000' }}
          id="notification-modal"
        >
          <Header id="notification-header">{modalHeader}</Header>
          <Modal.Description style={{ margin: '1rem 0' }}>
            {modalDiscription}
          </Modal.Description>
          <Modal.Content id="Notification-content">
            {view === 'notified users' ? (
              <>
                <SelectNotifiedUsers
                  handleSelect={handleNotificationTriggerSelect}
                  business={props.business}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
              </>
            ) : null}
            {view === 'notification trigger' ? (
              <>
                <label>Trigger Type</label>
                <br />
                <Dropdown
                  id="trigger-type"
                  fluid
                  search
                  selection
                  className={'auto-dropdown'}
                  icon={props.icon}
                  autoComplete="new-password"
                  style={props.style}
                  label="Trigger Type"
                  placeholder="Select Trigger Type"
                  value={props.triggerType}
                  basic={props.basic}
                  disabled={props.disabled}
                  onChange={(event, data) => {
                    let value = data?.options?.find(
                      (option) => option.value === data.value
                    )?.data
                    setTriggerType(value)
                  }}
                  options={[
                    {
                      key: 'Email',
                      value: 'Email',
                      text: 'Email',
                      data: ['Email'],
                    },
                    { key: 'SMS', value: 'SMS', text: 'SMS', data: ['SMS'] },
                    {
                      key: 'Email & SMS',
                      value: 'Email & SMS',
                      text: 'Email & SMS',
                      data: ['Email', 'SMS'],
                    },
                  ]}
                />
              </>
            ) : null}
            {view === 'New product listing' ? (
              <>
                <SelectProductListingTriggers
                  AttributesPos={AttributesPos}
                  setProductAttributesPos={setProductAttributesPos}
                  productAttributes={productAttributes}
                  setProductAttributes={setProductAttributes}
                  product={product}
                  userId={userId}
                  notificationSenders={notificationSenders}
                  setReloadTriggersTable={setReloadTriggersTable}
                  setProduct={setProduct}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  business={props.business}
                  attributeValues={attributeValues}
                  setAttributeValues={setAttributeValues}
                />
              </>
            ) : null}
            {view === 'price range' ? (
              <>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <RangeSlider
                        progress
                        min={0}
                        max={5000}
                        step={5}
                        style={{ marginTop: 16 }}
                        value={priceRange}
                        onChange={(value) => {
                          setPriceRange(value)
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column style={{ marginRight: '3em' }}>
                      <Input
                        type="number"
                        icon={'dollar'}
                        iconPosition="left"
                        min={0}
                        max={5000}
                        step={5}
                        value={priceRange[0]}
                        onChange={(e, d) => {
                          const end = priceRange[1]
                          if (d.value > end) {
                            return
                          }
                          if (d.value < 0) {
                            toast.error(
                              'Please enter price range between 0-5000'
                            )
                          }
                          setPriceRange([d.value, end])
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column style={{ marginLeft: '3em' }}>
                      <Input
                        type="number"
                        icon={'dollar'}
                        iconPosition="left"
                        min={0}
                        max={5000}
                        step={5}
                        value={priceRange[1]}
                        onChange={(e, d) => {
                          const start = priceRange[0]
                          if (start > d.value) {
                            return
                          }
                          if (d.value > 5000) {
                            toast.error(
                              'Please enter price range between 0-5000'
                            )
                          }
                          setPriceRange([start, d.value])
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </>
            ) : null}
            {view === 'portal' && (
              <>
                <label>Portal</label>
                <br />
                <Dropdown
                  id="trigger-portal"
                  fluid
                  search
                  selection
                  className={'auto-dropdown'}
                  icon={props.icon}
                  autoComplete="new-password"
                  style={props.style}
                  label="Portal"
                  placeholder="Select Portal"
                  value={props.triggerType}
                  onChange={(event, data) => {
                    setSelectedPortal(data?.value)
                  }}
                  options={portalOptions}
                />
              </>
            )}
          </Modal.Content>
          <div id="modal-buttons">
            {currentTab > 1 && (
              <Button
                basic
                style={{
                  padding: '.8rem 3rem',
                  border: 'none !important',
                  fontWeight: 'Bold',
                }}
                onClick={() => setCurrentTab(currentTab - 1)}
              >
                Back
              </Button>
            )}
            <Button
              color="primary"
              disabled={
                currentTab === 1
                  ? !product
                  : currentTab === 2 && priceRange[1] - priceRange[0] <= 0
                  ? true
                  : currentTab === 3 && !triggerType
                  ? true
                  : currentTab === 4 && !selectedUsers?.length
                  ? true
                  : currentTab === 5 && !selectedPortal
                  ? true
                  : false
              }
              onClick={() => {
                if (props.admin) {
                  if (currentTab === 5) {
                    createNotificationSenders()
                  } else {
                    setCurrentTab(currentTab + 1)
                  }
                } else {
                  if (currentTab === 4) {
                    createNotificationSenders()
                  } else {
                    setCurrentTab(currentTab + 1)
                  }
                }
              }}
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
                padding: '.8rem 3rem',
              }}
            >
              {props.admin ? (
                currentTab === 5 ? (
                  <>Create Notification</>
                ) : (
                  <>Next</>
                )
              ) : currentTab === 4 ? (
                <>Create Notification</>
              ) : (
                <>Next</>
              )}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
  return <></>
}

export default AddNotificationTriggers
