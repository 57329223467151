export const AdminOrderBuyerForm = {
  model: 'Order',
  format: [
    {
      widths: 'equal',
      fields: {
        supplier: {
          required: true,
          type: 'search',
          url: '/main/suppliers/',
          label: 'Supplier',
          title_fields: ['business.name'],
          filter: 'business__name__icontains',
          params: {
            tenant_aware: false,
            business__archived: false,
          },
          description_fields: [
            'business.contact_first_name',
            'business.contact_last_name',
          ],
        },
        pickup_address: {
          required: true,
          type: 'dropdown search',
          url: '/main/locations/',
          require_params: true,
          related_params: {
            supplier: 'supplier_id',
          },
          params: {
            tenant_aware: false,
          },
          title_fields: ['name'],
          filter_field: 'name__icontains',
          description_fields: ['address_1', 'city', 'state', 'zip_code'],
          allow_add: true,
          allow_add_related_params: {
            supplier: 'supplier_id',
          },
          model: 'Location',
          model_form: 'LocationForm',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        order_number: {
          label: 'Deal Number',
          required: false,
          type: 'text',
        },
        order_date: {
          label: 'Deal Date',
          type: 'date',
          required: true,
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        po_number: {
          required: false,
          type: 'text',
        },
        so_number: {
          required: false,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        scrapgo_status: {
          required: true,
          type: 'dropdown',
        },
        availability: {
          required: true,
          type: 'dropdown',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        salespersons: {
          label: 'Salespeople',
          type: 'multiple dropdown model',
          url: '/main/admin-users/?light_serializer=true',
          option_text_fields: ['full_name', 'email'],
        },
        mx_order: {
          required: false,
          type: 'checkbox',
          label: 'Exchange Order',
          params: {
            tenant_aware: false,
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        buyer_marketplace_fee: {
          required: false,
          label: 'Buyer Marketplace Fee',
          type: 'number',
          step: 0.01,
        },
        supplier_marketplace_fee: {
          required: false,
          label: 'Supplier Marketplace Fee',
          type: 'number',
          step: 0.01,
        },
      },
    },
  ],
}
