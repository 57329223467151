import { Label } from 'semantic-ui-react'
import { useTimer } from 'react-timer-hook'

const SupplierOrderItemTimer = (props) => {
  const expiryTimestamp = props.expiryTimestamp
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => props.setReload(true),
  })
  return (
    <>
      <Label className={'pulse-green'} color={'green'}>
        <span>{days}d</span> <span>{hours}h</span> <span>{minutes}m</span>{' '}
        <span>{seconds}s</span>
      </Label>
    </>
  )
}

export default SupplierOrderItemTimer
