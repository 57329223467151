import React, { useEffect, useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import {
  Grid,
  Modal,
  Menu,
  Sidebar,
  Segment,
  Header,
  Table,
} from 'semantic-ui-react'
import env from '../../../env'
import './AdminFulfillment.css'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'

function AdminFulfillmentTruckingCompanies(props) {
  const [reload, setReload] = useState(false)

  return (
    <div style={{ padding: 0, marginTop: '0px' }}>
      <PageHeader icon={'truck'} header={'Fulfillment Companies'} />
      <MickeyTable
        headerRow={['Company Name', 'Contact Name', 'Email', 'Phone']}
        url={'/main/admin-trucking-companies/'}
        reload={reload}
        setReload={setReload}
        basicSegment
        renderBodyRow={(data, index) => {
          return (
            <Table.Row>
              <Table.Cell>{data?.name}</Table.Cell>
              <Table.Cell>
                {data?.contact_first_name} {data?.contact_last_name}
              </Table.Cell>
              <Table.Cell>{data?.email}</Table.Cell>
              <Table.Cell>{data?.phone}</Table.Cell>
            </Table.Row>
          )
        }}
        emptyIcon={'truck'}
        emptyMessage={'Trucking companies will populate here'}
      />
    </div>
  )
}

export default AdminFulfillmentTruckingCompanies
