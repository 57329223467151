import { useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import MickeyDropdown from '../MickeyDropdown/MickeyDropdown'
import env from '../../env'
import '../SalesOrders/SalesOrders.css'

function GetProductVariant(props) {
  const {
    productVariant,
    setProductVariant,
    openProductVariantModal,
    setOpenProductVariantModal,
  } = props

  const axios = useAxios()
  const [selectedProductId, setSelectedProductId] = useState()
  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)
  const [attributeSelectionsFromDropdown, setAttributeSelectionsFromDropdown] =
    useState({})

  useEffect(() => {
    async function getAttributes() {
      setLoading(true)
      let res = await axios.get(`/main/product-filter/`, {
        params: {
          product_id: selectedProductId,
        },
      })
      if (res?.data) {
        setAttributes(res.data?.attribute_selections)
      }
      setLoading(false)
    }

    if (selectedProductId) {
      getAttributes()
    }
  }, [selectedProductId])

  const getProductVariant = async () => {
    setLoading(true)
    let selection_ids = Object.values(attributeSelectionsFromDropdown).map(
      (attribute) => {
        return attribute
      }
    )
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        selection_ids: selection_ids,
        product_id: selectedProductId,
      },
    })
    if (res?.data) {
      setProductVariant(res.data)
    }
    setLoading(false)
  }

  const handleSubmit = async () => {
    await getProductVariant()
    handleCancel()
  }

  const handleCancel = () => {
    setAttributes()
    setSelectedProductId()
    setOpenProductVariantModal(false)
    setAttributeSelectionsFromDropdown({})
  }

  return (
    <Modal
      className="get-pv-modal"
      open={openProductVariantModal}
      onOpen={() => setOpenProductVariantModal(true)}
      onClose={() => setOpenProductVariantModal(false)}
    >
      <Modal.Header> Select Product Variant </Modal.Header>
      <Modal.Content>
        <label> Select Product </label>
        <MickeyDropdown
          autoFocus
          url="/main/products/"
          placeholder={'Select Product'}
          style={{
            zIndex: '1003',
            margin: '15px 0 20px 0',
          }}
          params={{
            page_size: 'infinite',
          }}
          titleFields={['material.name']}
          descriptionFields={['commodity_form.name', 'commodity.name']}
          hideDescriptionFieldParenthesis
          onChange={(e, d) => {
            setSelectedProductId(d.value)
          }}
        />
        {attributes &&
          attributes?.map((attribute) => {
            return (
              <>
                <label style={{ margin: '5px' }}>{attribute.name}</label>
                <Dropdown
                  label={attribute.name}
                  fluid
                  search
                  selection
                  value={attributeSelectionsFromDropdown[attribute.name]}
                  style={{
                    marginBottom: '1rem',
                  }}
                  placeholder={`Select ${attribute.name}`}
                  onChange={(e, d) => {
                    setAttributeSelectionsFromDropdown({
                      ...attributeSelectionsFromDropdown,
                      [attribute.name]: d.value,
                    })
                  }}
                  options={attribute?.attribute_selections?.map((selection) => {
                    return {
                      key: selection.id,
                      value: selection.id,
                      text: selection.value,
                    }
                  })}
                />
              </>
            )
          })}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={'Cancel'}
          onClick={handleCancel}
          color={'gray'}
          style={{
            height: '35px',
            width: '8.5em',
            marginRight: '1em',
          }}
        />
        <Button
          type="submit"
          onClick={handleSubmit}
          loading={loading}
          disabled={
            !selectedProductId ||
            attributes?.length !==
              Object.keys(attributeSelectionsFromDropdown)?.length
          }
          color="primary"
          style={{
            height: '35px',
            width: '8.5em',
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
          }}
          content={'Add'}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default GetProductVariant
