import React, { useState } from 'react'
import { Table, Modal, Input, Grid, Header } from 'semantic-ui-react'
import { formatTotalPrice, formatQuantities } from '../../lib/utils/utils'
import { hasPermission } from '../../lib/utils/utils'
import { useSelector } from 'react-redux'
import MickeyTable from '../MickeyTable/MickeyTable'
import ESignRequestsTable from '../Documents/ESignRequestsTable'
import env from '../../env'
import './SettlementStatementModal.css'

const SettlementStatementModal = (props) => {
  const { settlementStatement, setReload, reload, setOpenModal, openModal } =
    props
  const user = useSelector((state) => state.user.user)
  const [selectedTab, setSelectedTab] = useState('Details')
  return (
    <>
      <Modal
        className="settlement-statement-detail"
        size={selectedTab === 'Details' ? 'large' : 'fullscreen'}
        open={openModal}
        onOpen={() => setOpenModal(true)}
        onClose={() => setOpenModal(false)}
        closeIcon
      >
        <Modal.Header>
          Settlement Statement for {settlementStatement.supplier.business.name}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div
                  className="exchange-table-heading"
                  style={{ marginLeft: '10px', marginBottom: '15px' }}
                >
                  <div className="commodities-wrapper">
                    <Header
                      as={'h2'}
                      className={
                        selectedTab === 'Details'
                          ? 'commodity-header selected'
                          : 'commodity-header'
                      }
                      color={selectedTab === 'Details' ? 'primary' : 'black'}
                      onClick={() => setSelectedTab('Details')}
                      style={{
                        margin: '0 1rem 0rem 0',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      }}
                    >
                      Details
                    </Header>
                    <Header
                      as={'h2'}
                      className={
                        selectedTab === 'Loads'
                          ? 'commodity-header selected'
                          : 'commodity-header'
                      }
                      color={selectedTab === 'Loads' ? 'primary' : 'black'}
                      onClick={() => setSelectedTab('Loads')}
                      style={{
                        margin: '0 1rem 0rem 0',
                        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      }}
                    >
                      Loads
                    </Header>
                    {hasPermission(user, 'Read Document') && (
                      <Header
                        as={'h2'}
                        className={
                          selectedTab === 'Documents'
                            ? 'commodity-header selected'
                            : 'commodity-header'
                        }
                        color={
                          selectedTab === 'Documents' ? 'primary' : 'black'
                        }
                        onClick={() => setSelectedTab('Documents')}
                        style={{
                          margin: '0 1rem 0rem 0',
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                        }}
                      >
                        Document Generation
                      </Header>
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {selectedTab === 'Details' && (
              <Grid.Row className="detail">
                <Grid.Column width={4}>
                  <div>
                    <p>Start Date</p>
                  </div>
                  <Input
                    type={'text'}
                    disabled={true}
                    value={settlementStatement.start_date}
                    icon="calendar"
                    iconPosition="right"
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <div>
                    <p>End Date</p>
                  </div>
                  <Input
                    type={'text'}
                    disabled={true}
                    value={settlementStatement.end_date}
                    icon="calendar"
                    iconPosition="right"
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <div>
                    <p>Total Quantity</p>
                  </div>
                  <Input
                    type={'text'}
                    disabled={true}
                    value={
                      settlementStatement?.net_weight_total &&
                      formatQuantities(settlementStatement?.net_weight_total)
                    }
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <div>
                    <p>Total Price</p>
                  </div>
                  <Input
                    type={'text'}
                    disabled={true}
                    value={
                      settlementStatement?.total_amount &&
                      formatTotalPrice(settlementStatement?.total_amount)
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {selectedTab === 'Loads' && (
              <Grid.Row>
                <Grid.Column>
                  <MickeyTable
                    headerRow={[
                      'Load ID',
                      'Trucking Company',
                      'Order',
                      'Buyer',
                      'Delivery Date',
                    ]}
                    basicSegment
                    data={settlementStatement.cargo_unit_instances}
                    renderBodyRow={(data, index) => {
                      return (
                        <>
                          <Table.Row selectable>
                            <Table.Cell width={1}>
                              {data?.shipper_load_id}
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {data?.trucking_company?.name}
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {data?.order?.order_number}
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {data?.buyer_name}
                            </Table.Cell>
                            <Table.Cell width={1}>
                              {data?.estimated_delivery_date}
                            </Table.Cell>
                          </Table.Row>
                        </>
                      )
                    }}
                    emptyIcon={'truck'}
                    emptyMessage={'Fulfillment will populate here'}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {selectedTab === 'Documents' && (
              <ESignRequestsTable
                settlementStatementId={settlementStatement.id}
                documentName="Settlement Statement"
                setReload={setReload}
                reload={reload}
                actions
                discludeOrder
                generationDescription
                hideHeader
                regenerateArgs={{
                  file_type: 'settlement_statement',
                  settlement_statement_id: settlementStatement.id,
                  supplier_id: settlementStatement.supplier.id,
                  delay: false,
                }}
              />
            )}
          </Grid>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default SettlementStatementModal
