import { Handle, Position } from 'reactflow'
import { Header } from 'semantic-ui-react'
import { formatQuantities, formatTotalPrice } from '../../../../lib/utils/utils'
import './AdminOrderBook.css'

function AdminOrderBookBidNode({ data, activeNodeId }) {
  return (
    <>
      <Handle type="source" position={Position.Right} />
      <div
        className={`admin-order-book-node ${
          activeNodeId === data?.order?.id ? 'active' : ''
        }`}
      >
        <Header>
          {data.order.business.name} (Order #{data.order.id})
          <Header.Subheader>
            {data.order.user.first_name} {data.order.user.last_name}
          </Header.Subheader>
        </Header>
        <div>
          <b>FOB Price:</b> {formatTotalPrice(data.order.price)}
        </div>
        <div>
          <b>Quantity/Cargo:</b> {formatQuantities(data.order.quantity)}{' '}
          {data.order.cargo_unit.singular_name}
        </div>
        <div>
          <b>Location:</b> {data.order.location.address_1}
          {data.order.location.address_2
            ? ' ' + data.order.location.address_2
            : ''}
          , {data.order.location.city}, {data.order.location.state}{' '}
          {data.order.location.zip_code}
        </div>
        <div>
          <b>Expires:</b>{' '}
          {data.order.expires_at
            ? data.order.expires_at + ' UTC'
            : 'Good Till Cancelled'}
        </div>
      </div>
    </>
  )
}

export default AdminOrderBookBidNode
