import React, { useEffect, useState } from 'react'
import {
  Table,
  Button,
  Modal,
  Input,
  Grid,
  Form,
  Header,
  Icon,
  Popup,
  Dropdown,
  PopupContent,
  List,
} from 'semantic-ui-react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { formatTotalPrice } from '../../../lib/utils/utils'
import { toast } from 'react-toastify'
import './AdminOrder.css'
import ConfirmationModal from '../../../components/ConfirmationModal'
import env from '../../../env'
import _ from 'underscore'
import AdminOrderItemsRow from './AdminOrderItemsRow'
import useAxios from '../../../lib/auth/useAxios'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import { useNavigate, useParams } from 'react-router-dom'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { TextAlignLeft } from 'phosphor-react'
import {
  CaretDown,
  CaretRight,
  PaperPlaneTilt,
  Plus,
  Trash,
} from 'phosphor-react'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import AddPurchaseOrder from '../../../components/PurchaseOrders/AddPurchaseOrder'

function AdminOrderMaterialsList(props) {
  const axios = useAxios()
  const user = useSelector((state) => state.user.user)
  const { orderId } = useParams()
  const navigate = useNavigate()
  const {
    order,
    setOrder,
    orderItems,
    setOrderItems,
    reloadAdminOrder,
    setReloadAdminOrder,
    addBidOrderModal,
    setAddBidOrderModal,
    bidOrderButtonActive,
    setAddBidOrderButtonActive,
  } = props
  const [globalSettings, setGlobalSettings] = useState()
  const [purchaseOrder, setPurchaseOrder] = useState()
  const [selectedMaterials, setSelectedMaterials] = useState([])
  const [selectedOrderItems, setSelectedOrderItems] = useState([])
  const [addTruckModal, setAddTruckModal] = useState(false)
  const [addPurchaseOrderModal, setAddPurchaseOrderModal] = useState(false)
  const [numberOfTrucks, setNumberOfTrucks] = useState(1)
  const [addTruckButtonLoading, setAddTruckButtonLoading] = useState(false)
  const [archiveOrderConfirmationModel, setArchiveOrderConfirmationModel] =
    useState(false)
  const [loadingArchiveOrderItems, setLoadingArchiveOrderItems] =
    useState(false)
  const [selectedDeliveryLocationId, setSelectedDeliveryLocationId] =
    useState(null)
  const [selectedPickupLocationId, setSelectedPickupLocationId] = useState(null)
  const [selectedCargoUnitId, setSelectedCargoUnitId] = useState(null)
  const [addRFQModal, setAddRFQModal] = useState(null)
  const [selectedSuppliers, setSelectedSuppliers] = useState([])
  const [addSalesOrderModal, setAddSalesOrderModal] = useState(null)
  const [addInvoiceOrderModal, setAddInvoiceOrderModal] = useState(null)
  const [selectedBidSign, setSelectedBidSign] = useState(null)
  const [selectedInvoiceMethod, setSelectedInvoiceMethod] = useState(null)
  const [invoiceNumber, setInvoiceNumber] = useState(null)
  const [salesOrderNumber, setSalesOrderNumber] = useState(null)
  const [sendDocumentPopup, setSendDocumentPopup] = useState(null)
  const [reload, setReload] = useState(reloadAdminOrder)
  const [totalTake, setTotalTake] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [deliveredPrice, setDeliveredPrice] = useState(0)
  const [supplier, setSupplier] = useState(false)
  const [orderItem, setOrderItem] = useState(false)
  const [loading, setLoading] = useState(false)

  const itemsHeaderRow = [
    '',
    'Product Details',
    'Supplier',
    'Supplier Price',
    'Supplier Quantity',
    'Buyer Price',
    'Buyer Quantity',
    'Supplier Total',
    'Margin',
    'Sales Tax',
    'Buyer Total',
    'Status',
  ]

  useEffect(() => {
    setSupplier(order?.supplier)
  }, [order])

  useEffect(() => {
    if (!reloadAdminOrder) {
      setLoading(true)
    }
  }, [reloadAdminOrder])

  useEffect(() => {
    let tp = 0
    let ttrucking = 0
    let ttake = 0
    let ttax = 0
    let dp = 0
    orderItems?.forEach((orderItem) => {
      if (displayOrderItem(orderItem)) {
        let supPrice = 0
        if (
          (!orderItem.is_credit_line_item &&
            !orderItem?.is_buyer_marketplace_fee) ||
          (order?.paid && orderItem.is_credit_line_item)
        ) {
          supPrice = orderItem.price * orderItem.quantity
          tp += supPrice
        }
        let trucking = orderItem?.trucking_price
          ? parseFloat(orderItem?.trucking_price)
          : 0
        let take = orderItem?.is_buyer_marketplace_fee
          ? parseFloat(orderItem?.buyer_marketplace_fee)
          : orderItem?.mickey_take && !orderItem?.is_credit_line_item
          ? parseFloat(orderItem?.mickey_take)
          : 0
        let tax = orderItem?.is_buyer_marketplace_fee
          ? parseFloat(orderItem?.buyer_marketplace_fee_tax_amount)
          : orderItem?.tax_amount
          ? parseFloat(orderItem?.tax_amount)
          : 0
        let discount = orderItem?.discount ? parseFloat(orderItem?.discount) : 0

        ttrucking += trucking
        ttake += take
        ttax += tax
        dp += supPrice - discount + trucking + take + tax
      }
    })
    setTotalPrice(tp)
    setTotalTake(ttake)
    setTotalTax(ttax)
    setDeliveredPrice(dp)
  }, [orderItems])

  const displayOrderItem = (orderItem) => {
    let use = true
    if (orderItem?.is_credit_line_item && orderItem.price === '0.000') {
      use = false
    }
    if (orderItem?.order?.mx_order && orderItem?.is_buyer_marketplace_fee) {
      use = false
    }
    return use
  }

  const getGlobalSettings = async () => {
    const resp = await axios.get('main/global-setting/')
    if (resp?.data?.results) {
      setGlobalSettings(resp?.data?.results[0])
    }
  }

  useEffect(() => {
    getGlobalSettings()
  }, [])

  useEffect(() => {
    setAddBidOrderButtonActive(
      !selectedMaterials || selectedMaterials?.length === 0
    )
  }, [selectedMaterials])

  async function sendRFQ(sendEmail) {
    let res = await axios.post(`/main/rfq/`, {
      suppliers: selectedSuppliers,
      oi_ids: selectedMaterials,
      send_email: sendEmail,
    })

    if (res.data.success) {
      if (sendEmail) {
        toast.success('RFQs Sent!')
      } else {
        toast.success('RFQs Activated!')
      }
      navigate(`/portal/admin/deals/${orderId}/fulfillment`)
    } else {
      if (res?.data?.error) {
        toast.error(res.data.error)
      } else {
        if (sendEmail) {
          toast.error('Error sending RFQs.')
        } else {
          toast.error('Error activating RFQs.')
        }
      }
    }
  }

  async function addTruck() {
    setAddTruckButtonLoading(true)
    if (numberOfTrucks < 1) {
      toast.error('Please Enter Valid Number of Trucks')
      setAddTruckButtonLoading(false)
      return
    }
    let addTruckOrderItems = []
    for (let orderItem of orderItems) {
      if (
        !orderItem?.is_buyer_marketplace_fee &&
        selectedMaterials.includes(orderItem?.id)
      ) {
        if (
          (globalSettings?.id && globalSettings?.disabled_quantity) ||
          (orderItem?.selected_delivery_quantity &&
            orderItem?.selected_delivery_quantity > 0)
        ) {
          addTruckOrderItems.push({
            oi_id: orderItem?.id,
            oi_qty: orderItem?.selected_delivery_quantity
              ? orderItem?.selected_delivery_quantity
              : 0,
          })
        } else {
          toast.error('Please Enter Valid Quantity')
          setAddTruckButtonLoading(false)
          return
        }
      }
    }

    let res = await axios.post(`/main/cargo-transportation/`, {
      cargo_unit_id: selectedCargoUnitId,
      pickup_location_id: selectedPickupLocationId,
      delivery_location_id: selectedDeliveryLocationId,
      number_of_trucks: numberOfTrucks,
      oi_data: addTruckOrderItems,
    })
    setAddTruckButtonLoading(false)
    if (res.data.success) {
      toast.success('Truck Added!')
      navigate(`/portal/admin/deals/${orderId}/fulfillment`)
    } else {
      if (res?.data?.error) {
        toast.error(res.data.error)
      } else {
        toast.error('Error adding truck.')
      }
    }
  }

  async function invoiceOnly(sendEmail) {
    if (!invoiceNumber) {
      toast.error('Invoice number required')
      return
    }
    if (sendEmail && !order.buyer.business.contact) {
      toast.error(
        `${order.buyer.business.name} requires a contact user to send files for signature`
      )
      return
    }
    let customOrderItemRows = {}
    const orderItemIds = []
    for (let orderItem of orderItems) {
      if (
        !orderItem?.is_buyer_marketplace_fee &&
        selectedMaterials.includes(orderItem?.id)
      ) {
        if (
          orderItem?.selected_invoice_quantity &&
          orderItem?.selected_invoice_quantity > 0
        ) {
          customOrderItemRows[orderItem?.id] = {
            buyer_quantity: orderItem?.selected_invoice_quantity,
            buyer_unit_id: orderItem?.selected_invoice_unit_of_measure,
          }
          orderItemIds.push(orderItem?.id)
        } else {
          toast.error('Quantity must be greater than 0')
          return
        }
      }
    }
    let res = await axios.post(`/main/box-sign-requests/`, {
      file_type: 'invoice',
      send_to_customer: sendEmail,
      send_invoice_to_customer: sendEmail,
      custom_order_items_rows: customOrderItemRows,
      order_item_ids: orderItemIds,
      invoice_number: invoiceNumber,
      order_id: order?.id,
      invoice_method: 'invoice_only',
    })
    if (res?.data.success) {
      toast.success(
        `Invoice generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
      )
    } else if (res?.data && res?.data?.error) {
      toast.error(res?.data?.error)
    } else {
      toast.error('Error generating invoice')
    }
  }

  const addSalesOrder = async (sendEmail) => {
    if (sendEmail && !order.buyer.business.contact) {
      toast.error(
        `${order.buyer.business.name} requires a contact user to send files for signature`
      )
    } else if (!salesOrderNumber) {
      toast.error('Sales order number required')
    } else {
      let addSalesOrderOrderItems = []
      for (let orderItem of orderItems) {
        if (
          !orderItem?.is_buyer_marketplace_fee &&
          orderItem?.selected_sales_order_quantity &&
          selectedMaterials.includes(orderItem?.id) &&
          orderItem?.selected_sales_order_quantity > 0
        ) {
          addSalesOrderOrderItems.push({
            oi_id: orderItem?.id,
            quantity: orderItem?.selected_sales_order_quantity,
            buyer_uom_id: orderItem?.selected_sales_order_unit_of_measure,
            product_variant_id: orderItem?.product_variant_id,
            price: orderItem?.delivered_price,
          })
        } else if (
          !orderItem?.selected_sales_order_quantity ||
          parseFloat(orderItem?.selected_sales_order_quantity) <= 0
        ) {
          toast.error('Quantity must be greater than 0')
          return
        }
      }
      let res = await axios.post(`/main/admin-order-accounting-view/`, {
        sales_order: true,
        order_id: order?.id,
        order_items: addSalesOrderOrderItems,
        sales_order_number: salesOrderNumber,
        send_email: sendEmail,
      })
      if (res.data.success) {
        toast.success(
          `Sales order generation started! Please check ${globalSettings.box_sign_review_email} inbox for a Box Sign Request to complete the document.`
        )
      } else {
        toast.error('Error creating sales order')
      }
    }
  }

  async function addBidOrder(sendEmail) {
    if (sendEmail && !order?.buyer?.business?.contact) {
      toast.error(
        `${order.buyer.business.name} requires a contact user to send files for signature`
      )
      return
    }
    const orderItemIds = []
    orderItems.forEach((orderItem) => {
      if (
        !orderItem?.is_buyer_marketplace_fee &&
        selectedMaterials.includes(orderItem?.id)
      ) {
        if (!orderItem.quantity || parseFloat(orderItem.quantity) <= 0) {
          toast.error('Quantity must be greater than 0')
          return
        }
        orderItemIds.push(orderItem.id)
      }
    })
    let res = await axios.post(`/main/box-sign-requests/`, {
      file_type: selectedBidSign,
      send_to_customer: sendEmail,
      order_item_ids: orderItemIds,
      order_id: order?.id,
    })
    if (res?.status === 201) {
      toast.success(
        `${
          selectedBidSign === 'bid' ? 'Bid' : 'Pre bid'
        } generation started! Please check ${
          globalSettings.box_sign_review_email
        } inbox for a Box Sign Request to complete the document.`
      )
    } else {
      toast.error('Error Creating Bid Sign Request')
    }
  }

  async function archiveOrderItems() {
    setLoadingArchiveOrderItems(true)
    let resInstance = await axios.post(
      `/main/admin-order-items/bulk_archive/`,
      {
        order_item_ids: selectedMaterials,
      }
    )
    setLoadingArchiveOrderItems(false)
    setArchiveOrderConfirmationModel(false)
    if (resInstance.data.error) {
      toast.error(resInstance.data.error)
    } else if (resInstance?.data.warning) {
      setSelectedMaterials([])
      setSelectedOrderItems([])
      setReload(true)
      toast.warning(resInstance?.data.warning)
    } else if (resInstance?.status < 300) {
      setSelectedMaterials([])
      setSelectedOrderItems([])
      props.setReload(true)
    } else {
      toast.error('Error archiving order items.')
    }
  }

  function selectedDifferentSupplier() {
    let suppliers = {}

    orderItems
      ?.filter((oi) => !oi.is_buyer_marketplace_fee)
      .forEach((oi) => {
        selectedMaterials?.forEach((selectedMaterial) => {
          if (oi?.id === selectedMaterial && oi?.supplier?.id) {
            suppliers[oi?.supplier?.id] = true
          }
        })
      })
    return Object.keys(suppliers).length > 1
  }

  const handleAddTruckClose = () => {
    setAddTruckModal(false)
    setSelectedCargoUnitId()
    setSelectedPickupLocationId()
    setSelectedDeliveryLocationId()
    setNumberOfTrucks(1)
    setOrderItems((prevState) => {
      return prevState.map((oi) => ({
        ...oi,
        selected_delivery_quantity: null,
      }))
    })
  }

  return (
    <div id="materials-list">
      {addPurchaseOrderModal && (
        <AddPurchaseOrder
          supplier={orderItems[0]?.supplier}
          orderItems={!purchaseOrder && selectedOrderItems}
          order={order}
          setReload={(_) => {
            setReloadAdminOrder(true)
            setPurchaseOrder()
          }}
          setOuterPurchaseOrder={setPurchaseOrder}
          update={purchaseOrder}
          purchaseOrderId={purchaseOrder?.id}
          openAddPurchaseOrderModal={addPurchaseOrderModal}
          setOpenAddPurchaseOrderModal={setAddPurchaseOrderModal}
        />
      )}

      <div id="selected-items-actions">
        {hasPermission(user, 'Delete Material List') && (
          <Button
            basic
            icon={<Trash size={20} weight="bold" />}
            disabled={!selectedMaterials || selectedMaterials?.length === 0}
            labelPosition={'left'}
            color={'primary'}
            style={{
              '--primaryColor':
                !selectedMaterials || selectedMaterials?.length === 0
                  ? '#A1A1A1'
                  : env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            size={'tiny'}
            content={'Delete'}
            onClick={() => {
              setArchiveOrderConfirmationModel(true)
            }}
          />
        )}

        <ConfirmationModal
          message={'Are you sure you want to archive these items?'}
          confirmationModal={archiveOrderConfirmationModel}
          setConfirmationModal={setArchiveOrderConfirmationModel}
          loading={loadingArchiveOrderItems}
          yesFunction={() => archiveOrderItems()}
          noFunction={() => setArchiveOrderConfirmationModel(false)}
        />
        {selectedDifferentSupplier() &&
          hasPermission(user, 'Create Fulfillment') && (
            <Popup
              trigger={
                <Button
                  basic
                  icon={<Plus size={20} weight="bold" />}
                  disabled={
                    !selectedMaterials || selectedMaterials?.length === 0
                  }
                  labelPosition={'left'}
                  color={'primary'}
                  style={{
                    '--primaryColor':
                      !selectedMaterials || selectedMaterials?.length === 0
                        ? '#A1A1A1'
                        : env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    opacity: 0.5,
                  }}
                  size={'tiny'}
                  content={'Add Truck'}
                />
              }
              content={
                'Select only items from the same supplier in order to add new truck.'
              }
              position={'bottom'}
            />
          )}
        {!selectedDifferentSupplier() &&
          hasPermission(user, 'Create Fulfillment') && (
            <Button
              basic
              icon={<Plus size={20} weight="bold" />}
              disabled={!selectedMaterials || selectedMaterials?.length === 0}
              labelPosition={'left'}
              color={'primary'}
              style={{
                '--primaryColor':
                  !selectedMaterials || selectedMaterials?.length === 0
                    ? '#A1A1A1'
                    : env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              size={'tiny'}
              content={'Add Truck'}
              onClick={() => {
                setAddTruckModal(true)
              }}
            />
          )}
        {!selectedDifferentSupplier() &&
          hasPermission(user, 'Create Order') && (
            <Button
              basic
              icon={<Plus size={20} weight="bold" />}
              disabled={!selectedMaterials || selectedMaterials?.length === 0}
              labelPosition={'left'}
              color={'primary'}
              style={{
                '--primaryColor':
                  !selectedMaterials || selectedMaterials?.length === 0
                    ? '#A1A1A1'
                    : env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              size={'tiny'}
              content={'Add Purchase Order'}
              onClick={() => {
                setAddPurchaseOrderModal(true)
              }}
            />
          )}
        {hasPermission(user, 'Create Document') && (
          <Popup
            id={'send-document-popup'}
            mountNode={
              document.getElementsByClassName(`send-document-button`)[0]
            }
            on={'click'}
            position={'bottom right'}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
            open={sendDocumentPopup}
            onOpen={() => setSendDocumentPopup(true)}
            onClose={() => setSendDocumentPopup(false)}
            offset={[0, 0]}
            trigger={
              <Button
                basic
                className="send-document-button"
                labelPosition={'left'}
                color={'primary'}
                disabled={!selectedMaterials || selectedMaterials?.length === 0}
                style={{
                  '--primaryColor':
                    !selectedMaterials || selectedMaterials?.length === 0
                      ? '#A1A1A1'
                      : env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
                size={'tiny'}
                icon={
                  sendDocumentPopup ? (
                    <CaretDown size={20} weight="bold" />
                  ) : (
                    <CaretRight size={20} weight="bold" />
                  )
                }
                content={'Send Document'}
                onClick={() => {
                  setSendDocumentPopup(true)
                }}
              />
            }
          >
            <PopupContent>
              <List>
                {globalSettings?.id && !globalSettings?.hide_rfq && (
                  <List.Item
                    style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                    disabled={
                      !selectedMaterials || selectedMaterials?.length === 0
                    }
                    onClick={() => {
                      setSendDocumentPopup(false)
                      setAddRFQModal(true)
                    }}
                  >
                    <PaperPlaneTilt size={20} weight="bold" />
                    <b>Send RFQ</b>
                  </List.Item>
                )}
                <List.Item
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  disabled={
                    !selectedMaterials || selectedMaterials?.length === 0
                  }
                  onClick={() => {
                    setSendDocumentPopup(false)
                    setAddInvoiceOrderModal(true)
                  }}
                >
                  <PaperPlaneTilt size={20} weight="bold" />
                  <b>Send Invoice</b>
                </List.Item>
                <List.Item
                  style={{ '--primaryColor': env.REACT_APP_PRIMARY_COLOR }}
                  disabled={
                    !selectedMaterials || selectedMaterials?.length === 0
                  }
                  onClick={() => {
                    setSendDocumentPopup(false)
                    setAddSalesOrderModal(true)
                  }}
                >
                  <PaperPlaneTilt size={20} weight="bold" />
                  <b>Send Sales Order</b>
                </List.Item>
              </List>
            </PopupContent>
          </Popup>
        )}
      </div>

      {(orderItems?.length <= 0 ||
        (orderItems?.length === 1 &&
          orderItems[0].is_buyer_marketplace_fee)) && (
        <MickeyPlaceholder
          style={{ margin: '20px', clear: 'right' }}
          iconHtml={<TextAlignLeft size={40} />}
          message={'No material added.'}
        />
      )}
      {orderItems?.length > 0 &&
        !(
          orderItems?.length === 1 && orderItems[0].is_buyer_marketplace_fee
        ) && (
          <MickeyTable
            headerRow={
              orderItem?.is_credit_line_item
                ? [...itemsHeaderRow.slice(0, 4), ...itemsHeaderRow.slice(7)]
                : itemsHeaderRow
            }
            style={{ marginTop: '5px', margin: '10px', clear: 'right' }}
            compact={'very'}
            basicSegment
            collapsing
            sticky
            loaded={loading}
            data={orderItems}
            reload={reload}
            setReload={setReload}
            renderBodyRow={(data, index) => {
              return displayOrderItem(data) ? (
                <AdminOrderItemsRow
                  index={index}
                  setPurchaseOrder={setPurchaseOrder}
                  setAddPurchaseOrderModal={setAddPurchaseOrderModal}
                  canEdit={!order?.locked && !(order?.admin_order_status == 6)}
                  order={order}
                  selectedMaterials={selectedMaterials}
                  onClick={(e, d) => {
                    if (data?.is_buyer_marketplace_fee) {
                      return
                    }
                    if (orderItem?.id === data?.id) {
                      setOrderItem(null)
                    } else {
                      setOrderItem(data)
                    }
                  }}
                  setSelectedMaterials={setSelectedMaterials}
                  setSelectedOrderItems={setSelectedOrderItems}
                  selectedOrderItems={selectedOrderItems}
                  orderCancelled={order?.admin_order_status == 6}
                  orderItems={orderItems}
                  orderItem={data}
                  supplier={supplier}
                  selectedOrderItem={orderItem}
                  setReload={setReload}
                  setOrderItem={(d) => {
                    let ois = [...orderItems]
                    ois[index] = {
                      ...d,
                      selected_sales_order_unit_of_measure: d.buyer_unit?.id,
                      selected_sales_order_quantity: d.buyer_quantity,
                      selected_invoice_quantity: d.buyer_quantity,
                      selected_invoice_unit_of_measure: d.buyer_unit?.id,
                    }
                    setOrderItems(ois)
                  }}
                  key={data ? data.id : null}
                  locked={order.locked}
                />
              ) : null
            }}
            urlParams={{
              order_id: order?.id,
            }}
            emptyIconHtml={<TextAlignLeft size={40} />}
            footerRow={
              <Table.Row>
                <Table.HeaderCell colSpan={'7'}>
                  <b>Totals</b>
                </Table.HeaderCell>
                <Table.Cell colSpan="1" warning>
                  <b>{formatTotalPrice(totalPrice)}</b>
                </Table.Cell>
                <Table.HeaderCell colSpan="1">
                  <b>{formatTotalPrice(totalTake)}</b>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="1">
                  <b>{formatTotalPrice(totalTax)}</b>
                </Table.HeaderCell>
                <Table.Cell colSpan="1" positive>
                  <b>{formatTotalPrice(deliveredPrice)}</b>
                </Table.Cell>
                <Table.HeaderCell colSpan="2"></Table.HeaderCell>
              </Table.Row>
            }
            emptyMessage={'Fulfillment will populate here'}
          />
        )}
      <Modal
        size={'large'}
        onClose={() => {
          setAddInvoiceOrderModal(false)
          setSelectedInvoiceMethod()
          setInvoiceNumber()
          setOrderItems((prevState) => {
            return prevState.map((oi) => ({
              ...oi,
              selected_invoice_quantity: oi.buyer_quantity,
              selected_invoice_unit_of_measure: oi.buyer_unit?.id,
            }))
          })
        }}
        onOpen={() => setAddInvoiceOrderModal(true)}
        open={addInvoiceOrderModal}
      >
        <Modal.Header>Create Invoice</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Popup
                position={'bottom right'}
                offset={[0, 0]}
                content={
                  <div style={{ padding: '10px' }}>
                    <Header as={'h4'} content={'Invoice Only'} />
                    <p>
                      Select this option if you'd like to only send the Invoice
                      to the buyer. This is ideal if you're expecting a check or
                      already have payment methods determined.
                    </p>
                    <Header as={'h4'} content={'Mark Paid Externally'} />
                    <p>
                      Select this option if you collected payment outside the
                      Mickey app.
                    </p>
                  </div>
                }
                style={{ marginLeft: '5px' }}
                on="hover"
                pinned
                trigger={
                  <Icon name={'info circle'} style={{ marginLeft: '7px' }} />
                }
              />
              <Form.Field width={6}>
                <Form.Dropdown
                  className={'invoice-dropdown'}
                  clearable
                  selection
                  placeholder={'Select Invoice Type'}
                  label={'Select Invoice Type'}
                  onChange={(e, d) => {
                    setSelectedInvoiceMethod(d.value)
                  }}
                  value={selectedInvoiceMethod}
                  style={{
                    minWidth: '70%',
                  }}
                  options={[
                    {
                      key: 'send-buyer-invoice',
                      value: 'Invoice Only',
                      text: 'Invoice Only',
                    },
                  ]}
                />
              </Form.Field>
              {selectedInvoiceMethod === 'Invoice Only' && (
                <Form.Field width={4}>
                  <label>Invoice Number</label>
                  <Form.Input
                    placeholder={'Invoice Number'}
                    value={invoiceNumber}
                    onChange={(e, d) => {
                      setInvoiceNumber(d.value)
                    }}
                  />
                </Form.Field>
              )}
            </Form.Group>
          </Form>

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orderItems?.map((orderItem, index) => {
                if (
                  orderItem?.is_buyer_marketplace_fee ||
                  (orderItem?.id && !selectedMaterials.includes(orderItem?.id))
                )
                  return
                let totalCCIQuantity
                if (orderItem?.cargo_container_items?.length > 0) {
                  totalCCIQuantity = _.map(
                    orderItem?.cargo_container_items,
                    (cci) => {
                      return cci.quantity
                    }
                  )
                  totalCCIQuantity = _.reduce(totalCCIQuantity, (ci1, ci2) => {
                    return parseFloat(ci1) + parseFloat(ci2)
                  })
                  totalCCIQuantity = orderItem?.quantity - totalCCIQuantity
                } else {
                  totalCCIQuantity = orderItem?.quantity
                }
                let mickeyTake = orderItem.mickey_take
                  ? orderItem.mickey_take
                  : 0
                mickeyTake =
                  mickeyTake *
                  (parseFloat(orderItem?.selected_invoice_quantity) /
                    orderItem?.buyer_quantity)
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={orderItem} />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder={'Quantity'}
                        error={
                          parseFloat(orderItem?.selected_invoice_quantity) >
                            parseFloat(orderItem?.buyer_quantity) ||
                          parseFloat(orderItem?.selected_invoice_quantity) < 0
                        }
                        value={orderItem?.selected_invoice_quantity}
                        defaultValue={orderItem?.quantity}
                        onChange={(e, d) => {
                          let sms = [...orderItems]
                          sms[index] = {
                            ...orderItem,
                            selected_invoice_quantity: d.value,
                          }
                          setOrderItems(sms)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={orderItem?.selected_invoice_unit_of_measure}
                        defaultValue={orderItem?.unit?.id}
                        params={{
                          page_size: 25,
                          product_id: orderItem?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sms = [...orderItems]
                          sms[index] = {
                            ...orderItem,
                            selected_invoice_unit_of_measure: d.value,
                          }
                          setOrderItems(sms)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(orderItem?.delivered_price)}
                    </Table.Cell>
                    <Table.Cell>{formatTotalPrice(mickeyTake)}</Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(
                        parseFloat(orderItem?.delivered_price) *
                          parseFloat(orderItem?.selected_invoice_quantity) +
                          parseFloat(mickeyTake)
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Sales Tax</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          let salesTax = orderItem.tax_amount
                            ? orderItem.tax_amount
                            : 0
                          salesTax =
                            salesTax *
                            (parseFloat(orderItem?.selected_invoice_quantity) /
                              orderItem?.buyer_quantity)
                          return salesTax
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          let salesTax = orderItem.tax_amount
                            ? orderItem.tax_amount
                            : 0
                          salesTax =
                            salesTax *
                            (parseFloat(orderItem?.selected_invoice_quantity) /
                              orderItem?.buyer_quantity)
                          let mickeyTake = orderItem.mickey_take
                            ? orderItem.mickey_take
                            : 0
                          mickeyTake =
                            mickeyTake *
                            (parseFloat(orderItem?.selected_invoice_quantity) /
                              orderItem?.buyer_quantity)
                          return (
                            parseFloat(orderItem?.delivered_price) *
                              parseFloat(orderItem?.selected_invoice_quantity) +
                            parseFloat(salesTax) +
                            parseFloat(mickeyTake)
                          )
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setAddInvoiceOrderModal(false)
              setSelectedInvoiceMethod()
              setInvoiceNumber()
              setOrderItems((prevState) => {
                return prevState.map((oi) => ({
                  ...oi,
                  selected_invoice_quantity: oi.buyer_quantity,
                  selected_invoice_unit_of_measure: oi.buyer_unit?.id,
                }))
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          {selectedInvoiceMethod === 'Invoice Only' && (
            <>
              <Button
                type="submit"
                onClick={() => invoiceOnly(false)}
                color="primary"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Create
              </Button>
              <Button
                type="submit"
                onClick={() => invoiceOnly(true)}
                color="primary"
                icon="paper plane"
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  '--secondaryButtonColor':
                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                }}
              >
                Create & Send
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
      <Modal
        size={'large'}
        onClose={() => {
          setAddSalesOrderModal(false)
          setSalesOrderNumber()
          setOrderItems((prevState) => {
            return prevState.map((oi) => ({
              ...oi,
              selected_sales_order_quantity: oi.buyer_quantity,
              selected_sales_order_unit_of_measure: oi.buyer_unit?.id,
            }))
          })
        }}
        onOpen={() => setAddSalesOrderModal(true)}
        open={addSalesOrderModal}
      >
        <Modal.Header>Create Sales Order</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form>
                  <Form.Group>
                    <Form.Field width={4}>
                      <label>Sales Order Number</label>
                      <Form.Input
                        placeholder={'Sales Order Number'}
                        value={salesOrderNumber}
                        onChange={(e, d) => {
                          setSalesOrderNumber(d.value)
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orderItems?.map((orderItem, index) => {
                if (
                  orderItem?.is_buyer_marketplace_fee ||
                  (orderItem?.id && !selectedMaterials.includes(orderItem?.id))
                )
                  return
                const mickeyTake = orderItem.mickey_take
                  ? orderItem.mickey_take
                  : 0
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={orderItem} />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder={'Quantity'}
                        error={
                          parseFloat(orderItem?.selected_sales_order_quantity) >
                            parseFloat(orderItem?.buyer_quantity) ||
                          parseFloat(orderItem?.selected_sales_order_quantity) <
                            0
                        }
                        value={orderItem?.selected_sales_order_quantity}
                        defaultValue={orderItem?.quantity}
                        onChange={(e, d) => {
                          let sms = [...orderItems]
                          sms[index] = {
                            ...orderItem,
                            selected_sales_order_quantity: d.value,
                          }
                          setOrderItems(sms)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={orderItem?.selected_sales_order_unit_of_measure}
                        defaultValue={orderItem?.unit?.id}
                        params={{
                          page_size: 25,
                          product_id: orderItem?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sms = [...orderItems]
                          sms[index] = {
                            ...orderItem,
                            selected_sales_order_unit_of_measure: d.value,
                          }
                          setOrderItems(sms)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(orderItem?.delivered_price)}
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(
                        mickeyTake *
                          (parseFloat(
                            orderItem?.selected_sales_order_quantity
                          ) /
                            orderItem?.buyer_quantity)
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(
                        orderItem?.delivered_price *
                          orderItem?.selected_sales_order_quantity +
                          parseFloat(
                            mickeyTake *
                              (parseFloat(
                                orderItem?.selected_sales_order_quantity
                              ) /
                                orderItem?.buyer_quantity)
                          )
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Sales Tax</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          let salesTax = orderItem.tax_amount
                            ? orderItem.tax_amount
                            : 0
                          salesTax =
                            salesTax *
                            (parseFloat(
                              orderItem?.selected_sales_order_quantity
                            ) /
                              orderItem?.buyer_quantity)
                          return salesTax
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          let salesTax = orderItem.tax_amount
                            ? orderItem.tax_amount
                            : 0
                          salesTax =
                            salesTax *
                            (parseFloat(
                              orderItem?.selected_sales_order_quantity
                            ) /
                              orderItem?.buyer_quantity)
                          let mickeyTake = orderItem.mickey_take
                            ? orderItem.mickey_take
                            : 0
                          mickeyTake =
                            mickeyTake *
                            (parseFloat(
                              orderItem?.selected_sales_order_quantity
                            ) /
                              orderItem?.buyer_quantity)
                          return (
                            parseFloat(orderItem?.delivered_price) *
                              parseFloat(
                                orderItem?.selected_sales_order_quantity
                              ) +
                            parseFloat(salesTax) +
                            parseFloat(mickeyTake)
                          )
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setAddSalesOrderModal(false)
              setSalesOrderNumber()
              setOrderItems((prevState) => {
                return prevState.map((oi) => ({
                  ...oi,
                  selected_sales_order_quantity: oi.quantity,
                  selected_sales_order_unit_of_measure: oi.buyer_unit?.id,
                }))
              })
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => addSalesOrder(false)}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create
          </Button>
          <Button
            type="submit"
            onClick={() => addSalesOrder(true)}
            icon="paper plane"
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create & Send
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={'large'}
        onClose={() => {
          setAddBidOrderModal(false)
          setSelectedBidSign(null)
        }}
        onOpen={() => setAddBidOrderModal(true)}
        open={addBidOrderModal}
      >
        <Modal.Header>Create Bid</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}>
                <label style={{ color: 'black' }}>Select Document</label>
                <Dropdown
                  placeholder={'Select Document'}
                  fluid
                  search
                  autoComplete="new-password"
                  selection
                  value={selectedBidSign}
                  options={[
                    { text: 'Bid', value: 'bid', key: 'bid' },
                    { text: 'Pre Bid', value: 'pre_bid', key: 'pre_bid' },
                  ]}
                  onChange={(e, d) => {
                    setSelectedBidSign(d.value)
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Mickey Take</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {orderItems?.map((orderItem, index) => {
                if (
                  orderItem?.is_buyer_marketplace_fee ||
                  (orderItem?.id && !selectedMaterials.includes(orderItem?.id))
                )
                  return
                const mickeyTake = orderItem.mickey_take
                  ? orderItem.mickey_take
                  : 0
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={orderItem} />
                    </Table.Cell>
                    <Table.Cell>{orderItem?.buyer_quantity}</Table.Cell>
                    <Table.Cell>
                      {orderItem?.buyer_unit?.plural_name}
                    </Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(orderItem?.delivered_price)}
                    </Table.Cell>
                    <Table.Cell>{formatTotalPrice(mickeyTake)}</Table.Cell>
                    <Table.Cell>
                      {formatTotalPrice(
                        orderItem?.delivered_price * orderItem?.buyer_quantity +
                          parseFloat(mickeyTake)
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Sales Tax</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          return orderItem.tax_amount ? orderItem.tax_amount : 0
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <b>
                    {formatTotalPrice(
                      orderItems
                        ?.map((orderItem, index) => {
                          if (
                            orderItem?.is_buyer_marketplace_fee ||
                            (orderItem?.id &&
                              !selectedMaterials.includes(orderItem?.id))
                          )
                            return 0
                          const salesTax = orderItem.tax_amount
                            ? orderItem.tax_amount
                            : 0
                          const mickeyTake = orderItem.mickey_take
                            ? orderItem.mickey_take
                            : 0
                          return (
                            parseFloat(orderItem?.delivered_price) *
                              parseFloat(orderItem?.buyer_quantity) +
                            parseFloat(salesTax) +
                            parseFloat(mickeyTake)
                          )
                        })
                        .reduce(
                          (accumulator, currentValue) =>
                            parseFloat(accumulator) + parseFloat(currentValue),
                          0
                        )
                    )}
                  </b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setAddBidOrderModal(false)
              setSelectedBidSign()
            }}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!selectedBidSign}
            onClick={() => {
              if (selectedBidSign) {
                addBidOrder(false)
              }
            }}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create
          </Button>
          <Button
            type="submit"
            disabled={!selectedBidSign}
            onClick={() => {
              if (selectedBidSign) {
                addBidOrder(true)
              }
            }}
            icon="paper plane"
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Create & Send
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={'large'}
        onClose={handleAddTruckClose}
        onOpen={() => setAddTruckModal(true)}
        open={addTruckModal}
      >
        <Modal.Header>Add Truck</Modal.Header>
        <Modal.Content>
          <Grid columns={4}>
            <Grid.Row>
              <Grid.Column>
                <label>Cargo Type</label>
                <MickeyDropdown
                  url={'/main/cargo-units/'}
                  name={'cargo_unit'}
                  defaultValue={selectedCargoUnitId}
                  autoComplete="new-password"
                  label={'Cargo Type'}
                  tenantAware={false}
                  placeholder={'Select Cargo Type'}
                  titleFields={['singular_name']}
                  descriptionFields={[]}
                  onChange={(e, d) => {
                    setSelectedCargoUnitId(d.value)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Pickup Location</label>
                <MickeyDropdown
                  url={'/main/admin-locations/'}
                  params={{
                    admin_order_item_id: orderItem?.id,
                    business_id: orderItems?.filter((orderItem) =>
                      selectedMaterials.includes(orderItem?.id)
                    )[0]?.supplier?.business?.id,
                    is_pickup: true,
                  }}
                  name={'pickup_location'}
                  defaultValue={selectedPickupLocationId}
                  autoComplete="new-password"
                  tenantAware={false}
                  label={'Pickup Address'}
                  placeholder={'Select Pickup Address'}
                  titleFields={['name', 'address_1']}
                  descriptionFields={['city', 'state', 'zip_code']}
                  onChange={(e, d) => {
                    setSelectedPickupLocationId(d.value)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Delivery Location</label>
                <MickeyDropdown
                  url={'/main/admin-locations/'}
                  params={{
                    admin_order_item_id: orderItem?.id,
                    business_id: props.order?.buyer?.business?.id,
                    is_delivery: true,
                  }}
                  name={'pickup_location'}
                  defaultValue={selectedDeliveryLocationId}
                  autoComplete="new-password"
                  tenantAware={false}
                  label={'Pickup Address'}
                  placeholder={'Select Pickup Address'}
                  titleFields={['name', 'address_1']}
                  descriptionFields={['city', 'state', 'zip_code']}
                  onChange={(e, d) => {
                    setSelectedDeliveryLocationId(d.value)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Number of Trucks</label>
                <Input
                  type={'number'}
                  step={1}
                  min={1}
                  value={numberOfTrucks}
                  onChange={(e, d) => setNumberOfTrucks(d.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions className="add-truck-action">
          <Button onClick={handleAddTruckClose}>Cancel</Button>
          <Button
            type="submit"
            onClick={() => addTruck()}
            loading={addTruckButtonLoading}
            disabled={addTruckButtonLoading}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Add Truck
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={'large'}
        onClose={() => setAddRFQModal(false)}
        onOpen={() => setAddRFQModal(true)}
        open={addRFQModal}
      >
        <Modal.Header>Send RFQ</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <label>Select Suppliers</label>
                <MickeyDropdown
                  multiple
                  selection
                  url={'/main/suppliers/'}
                  name={'suppliers'}
                  defaultValue={selectedSuppliers}
                  autoComplete="new-password"
                  label={'Select Suppliers'}
                  tenantAware={false}
                  placeholder={'Select Suppliers'}
                  titleFields={['business.name']}
                  descriptionFields={['']}
                  onChange={(e, d) => {
                    setSelectedSuppliers(d.value)
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Products</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {orderItems?.map((orderItem, index) => {
                    if (
                      orderItem?.is_buyer_marketplace_fee ||
                      (orderItem?.id &&
                        !selectedMaterials.includes(orderItem?.id))
                    )
                      return null
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <OrderItemDetailTwo orderItem={orderItem} />
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => setAddRFQModal(false)}
            color={'black'}
            style={{
              padding: '.75rem',
              borderRadius: '500px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => sendRFQ(false)}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Activate RFQ (No Email)
          </Button>
          <Button
            type="submit"
            onClick={() => sendRFQ(true)}
            color="primary"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Send RFQ Email
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default AdminOrderMaterialsList
