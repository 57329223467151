import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/userSlice'
import globalSlice from './features/globals/globalSlice'
import darkModeSlice from './features/darkMode/darkModeSlice'

export default configureStore({
  reducer: {
    user: userReducer,
    dark_mode: darkModeSlice,
    globals: globalSlice,
  },
})
