import { Button, Table } from 'semantic-ui-react'
import './Permissions.css'
import { PencilSimple } from 'phosphor-react'

const GroupTableRow = (props) => {
  const { group, toggleGroupModal } = props

  return (
    <>
      <Table.Row selectable>
        <Table.Cell width={1}>{group?.name}</Table.Cell>
        <Table.Cell width={9}>
          {group?.users?.map((user, idx) => {
            return `${user?.first_name} ${user?.last_name}${
              idx + 1 !== group?.users?.length ? ', ' : ''
            }`
          })}
        </Table.Cell>
        <Table.Cell width={2}>
          <div style={{ display: 'flex' }}>
            <Button
              className="group-action-btn"
              color="grey"
              icon={<PencilSimple size={20} weight="bold" />}
              content={'Edit'}
              onClick={() => toggleGroupModal(group)}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default GroupTableRow
