import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import './Menu.css'
import './Buyer.css'
import { Grid, Header, Modal, Button } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../lib/auth/useAxios'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { addUser } from '../../features/user/userSlice'
import env from './../../env'
import { phone } from 'phone'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'

export default function Buyer(props) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [phone_numb, setPhone] = useState(null)
  const supplier = useSelector((state) => state.user.supplier)
  const user = useSelector((state) => state.user.user)
  const wd = useWindowDimensions()
  const axios = useAxios()
  const dispatch = useDispatch()

  useEffect(() => {
    props.setCurrentPortal('buyer')
    document.body.style.zoom = '97%'
  }, [])

  useEffect(() => {
    if (user?.id && !user?.has_logged_in) {
      setPhone(user.phone)
      setOpen(true)
    }
  }, [user])

  let supplierPortalOnClick
  if (supplier?.id && !supplier?.archived) {
    user?.tenants?.forEach((tenant) => {
      if (!tenant.mikey_tenant) {
        supplierPortalOnClick = () => {
          const accessToken = localStorage.getItem('access_token')
          const refreshToken = localStorage.getItem('refresh_token')
          if (env.REACT_APP_ENVIRONMENT === 'local') {
            window.location.href = `http://${tenant.domain}:3003/portal/supplier?auth=${accessToken}+${refreshToken}`
          } else {
            window.location.href = `https://${tenant.domain}/portal/supplier?auth=${accessToken}+${refreshToken}`
          }
        }
      }
    })
    if (!supplierPortalOnClick) {
      supplierPortalOnClick = () => {
        navigate('/portal/supplier')
      }
    }
  }

  const updateSMS = async (receive_sms_messages, phone_number) => {
    let valid_phone = phone_number
    if (phone_number) {
      if (valid_phone?.[0] === '1') {
        valid_phone = valid_phone.slice(1)
      }
    }
    if (phone_number == null && !receive_sms_messages) {
      await axios.patch(`/main/custom-users/${user.id}/`, {
        receive_sms_messages,
        has_logged_in: true,
      })
      setOpen(false)
    } else if (!phone(valid_phone).isValid) {
      toast.error('A valid phone number is required')
    } else {
      let res = await axios.patch(`/main/custom-users/${user.id}/`, {
        receive_sms_messages,
        phone_number,
        has_logged_in: true,
      })
      dispatch(addUser(res?.data))
      setOpen(false)
    }
  }

  return (
    <div>
      <Outlet />
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        size={'fullscreen'}
        id="text-updates"
      >
        <Modal.Content>
          <Grid stackable centered width={16} className="modal-inner-wrapper">
            <Grid.Column
              className="updates-left"
              width={wd.width <= 935 ? 16 : 8}
            >
              <div className="header-wrapper">
                <Header as={'h1'} className="text-updates-header">
                  Would you like to receive
                  <br />
                  notifications via text?
                  <Header.Subheader as={'p'} className="text-updates-subheader">
                    These settings can be found and changed in the
                    <br />
                    settings tab.
                  </Header.Subheader>
                </Header>
              </div>
            </Grid.Column>
            <Grid.Column
              className="updates-right"
              width={wd.width <= 935 ? 16 : 8}
            >
              <div className="actions-wrapper">
                <div className="phone-submit-wrapper">
                  <PhoneInput
                    country={'us'}
                    value={phone_numb}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    onChange={(value) => setPhone(value)}
                    style={{ height: '50px', marginTop: '25px' }}
                  />

                  <Button
                    className="submit-button"
                    color="primary"
                    onClick={() => updateSMS(true, phone_numb)}
                    style={{
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div
                  className="no-button"
                  onClick={() => updateSMS(false, null)}
                >
                  Maybe Later
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  )
}
