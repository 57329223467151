import React, { useState } from 'react'
import { Table, Popup, Label, Button, Input, Modal } from 'semantic-ui-react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import './SupplierOrderItemsRow.css'
import { formatAddress } from '../../../helpers/helpers'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import env from '../../../env'
import MickeyForm from '../../../components/Forms/MickeyForm'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const SupplierOrderFulfillmentRow = (props) => {
  const user = useSelector((state) => state.user.user)
  const [markOrderShippedModal, setMarkOrderShippedModal] = useState(false)
  const [acceptLoading, setAcceptLoading] = useState(false)
  console.log(props)
  const [shippedQuantities, setShippedQuantities] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return 0
    })
  )
  const [shippedUOMs, setShippedUOMs] = useState(
    props.cargoUnitInstance?.container_items?.map((cci, index) => {
      return cci.order_item?.unit?.id
    })
  )

  const ccis = props.cargoUnitInstance?.container_items?.map((cci, index) => {
    return cci?.id
  })
  const wd = useWindowDimensions()

  async function handleMarkOrderShipped(status, data) {
    setAcceptLoading(false)
    if (status === 'success') {
      setMarkOrderShippedModal(false)
      props.setReload(true)
      props.setCargoUnitInstance(data)
    }
  }

  return (
    <>
      <Table.Row
        style={{
          backgroundColor:
            props.cargoUnitInstance?.id === props.selectedCargoUnitInstance?.id
              ? 'rgb(250, 255, 250)'
              : '',
        }}
        selectable
        onClick={() => {
          props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
        }}
      >
                <Table.Cell width={1}>
          <Label
            color={
              props.cargoUnitInstance?.delivered
                ? 'green'
                : !props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped
                ? 'red'
                : !props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked
                ? 'orange'
                : ''
            }
            content={
              <>
                {props.cargoUnitInstance?.delivered && 'Delivered'}
                {!props.cargoUnitInstance?.delivered &&
                  props.cargoUnitInstance?.supplier_shipped &&
                  'In Transit'}
                {!props.cargoUnitInstance?.supplier_shipped &&
                  props.cargoUnitInstance?.trucking_booked &&
                  'Booked'}
                {!props.cargoUnitInstance?.supplier_shipped &&
                  !props.cargoUnitInstance?.trucking_booked &&
                  'New'}
              </>
            }
          />
        </Table.Cell>
        <Table.Cell
          style={{ fontSize: wd.width >= 768 ? '' : '1.5rem' }}
          width={2}
        >
          {props.cargoUnitInstance?.id ===
            props.selectedCargoUnitInstance?.id && (
            <div
              style={{
                marginRight: '10px',
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                backgroundColor: '#4daf7c',
                display: 'inline-block',
              }}
            ></div>
          )}
          1000
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.pickup_location
            ? formatAddress(props.cargoUnitInstance?.pickup_location)
            : '--'}
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.estimated_ship_date
            ? props.cargoUnitInstance?.estimated_ship_date
            : '--'}
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.estimated_delivery_date
            ? props.cargoUnitInstance?.estimated_delivery_date
            : '--'}
        </Table.Cell>
        <Table.Cell width={2}>
          {props.cargoUnitInstance?.container_items?.length > 0 ? (
            <Popup
              trigger={
                <span>
                  {props.cargoUnitInstance?.container_items?.length.toString()}{' '}
                  Item
                  {props.cargoUnitInstance?.container_items?.length > 1
                    ? 's'
                    : ''}
                </span>
              }
              position={'bottom left'}
              flowing
              content={
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.cargoUnitInstance?.container_items?.map(
                      (containerItem) => {
                        return (
                          <Table.Row>
                            <Table.Cell>
                              <OrderItemDetailTwo
                                orderItem={{
                                  product_variant:
                                    containerItem?.product_variant,
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              {containerItem?.quantity}{' '}
                              {containerItem?.unit?.short_name}
                            </Table.Cell>
                          </Table.Row>
                        )
                      }
                    )}
                  </Table.Body>
                </Table>
              }
            />
          ) : (
            'No Items'
          )}
        </Table.Cell>
        <Table.Cell width={2}>
        {props.cargoUnitInstance.supplier_payout && new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(props.cargoUnitInstance.supplier_payout)}
        </Table.Cell>
        <Table.Cell width={2}>
          {hasPermission(user, 'Update Fulfillment')}
          <Button
            content={'Mark Shipped'}
            size={'mini'}
            color="primary"
            style={{
              cursor: 'pointer',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              color: 'white',
              display: props.cargoUnitInstance?.supplier_shipped ? 'none' : '',
              padding: '0.6em 1.1em 0.6em',
              '--secondaryButtonColor': 'transparent',
            }}
            loading={acceptLoading}
            onClick={() => {
              setMarkOrderShippedModal(true)
            }}
          />
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => setMarkOrderShippedModal(false)}
        onOpen={() => setMarkOrderShippedModal(true)}
        open={markOrderShippedModal}
      >
        <Modal.Header>Mark Cargo Shipped</Modal.Header>
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Unit of Measure</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {props.cargoUnitInstance?.container_items?.map((cci, index) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <OrderItemDetailTwo orderItem={cci?.order_item} />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        placeholder={'Quantity'}
                        min={0}
                        type={'number'}
                        step={0.01}
                        value={shippedQuantities[index]}
                        onChange={(e, d) => {
                          if (d.value < 0) {
                            toast.error('Please Enter Valid Quantity')
                            return
                          }
                          let sq = [...shippedQuantities]
                          sq[index] = d.value
                          setShippedQuantities(sq)
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <MickeyDropdown
                        url={'/main/unit-of-measures/'}
                        name={'unit_of_measure'}
                        value={shippedUOMs[index]}
                        params={{
                          page_size: 25,
                          product_id:
                            cci?.order_item?.product_variant?.product_id,
                        }}
                        autoComplete="new-password"
                        placeholder={'Unit of Measure'}
                        titleFields={['plural_name']}
                        descriptionFields={[]}
                        onChange={(e, d) => {
                          let sq = [...shippedUOMs]
                          sq[index] = d.value
                          setShippedUOMs(sq)
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
          <MickeyForm
            model={'CargoUnitInstance'}
            formName={'MarkOrderShippedForm'}
            successMessage={'Cargo marked as shipped!'}
            failureMessage={'Error marking cargo as shipped, please try again.'}
            url={'/main/mark-cargo-unit-instance-shipped/'}
            handleForm={handleMarkOrderShipped}
            buttonLabel={'Mark Shipped'}
            values={{
              supplier_id: props.supplier?.id,
              shipped_quantities: shippedQuantities,
              shipped_uoms: shippedUOMs,
              ccis: ccis,
              cargo_unit_instance_id: props.cargoUnitInstance?.id,
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

export default SupplierOrderFulfillmentRow
