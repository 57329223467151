import { emailValidator } from '../components/Forms/validators'

export const ContactUsForm = {
  model: '',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          fieldClassName: 'first_name',
          required: true,
          type: 'text',
        },
        last_name: {
          fieldClassName: 'last_name',
          required: true,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        company_name: {
          fieldClassName: 'company_name',
          required: true,
          type: 'text',
        },
        email: {
          fieldClassName: 'email',
          type: 'text',
          validator: (input) => {
            return emailValidator(input)
          },
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        message: {
          rows: 6,
          fieldClassName: 'message',
          required: true,
          type: 'text area',
        },
      },
    },
  ],
}
