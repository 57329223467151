export const MarkOrderShippedForm = {
  model: 'CargoContainerItem',
  format: [
    {
      widths: 'equal',
      fields: {
        packing_list: {
          type: 'file',
        },
      },
    },
  ],
}
