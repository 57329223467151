import { useEffect, useState } from 'react'
import MickeyDropdown from '../MickeyDropdown/MickeyDropdown'
import { Button, Divider, Dropdown, Grid } from 'semantic-ui-react'
import useAxios from '../../lib/auth/useAxios'
import env from '../../env'
import './PriceHistoryTable.css'

function SelectProductAttribute(props) {
  const axios = useAxios()
  const [product, setProduct] = useState()
  const [attributes, setAttributes] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      setAttributes([])
      let res = await axios.get(`/main/product-filter/`, {
        params: {
          product_id: product,
        },
      })
      if (res?.data?.attribute_selections?.length) {
        setAttributes(res?.data?.attribute_selections)
      }
      setLoading(false)
    }
    if (product) {
      fetchData()
    }
  }, [product])

  let selection_ids = {}
  let selection_ids_list = []

  const getCombnations = (array, keys, index) => {
    let key = keys[index]
    array[key].forEach((val) => {
      selection_ids[key] = val
      let selection_dict = {}
      Object.keys(selection_ids)?.forEach((selection_key) => {
        selection_dict[selection_key] =
          selection_key === key ? val : selection_ids[selection_key]
      })
      if (keys[index + 1]) {
        getCombnations(array, keys, index + 1)
      } else {
        selection_ids_list.push(selection_dict)
      }
    })
    if (index === 0) {
      return selection_ids_list
    }
  }

  const getProductVariant = async (selection) => {
    let attribute_selections = Object.values(selection).map((attribute) => {
      return attribute
    })
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        selection_ids: attribute_selections,
        product_id: product,
      },
    })
    if (res?.data) {
      return res?.data
    }
    return null
  }

  const handleClick = async () => {
    if (Object.keys(selectedAttributes)?.length) {
      let pv_ids = []
      let keys = Object.keys(selectedAttributes)
      let combinations = await getCombnations(selectedAttributes, keys, 0)
      combinations?.map(async (comb, index) => {
        let res = await getProductVariant(Object.values(comb))
        if (res?.id) {
          pv_ids.push(res?.id)
        }
        if (combinations?.length === index + 1) {
          props.setProductVariant([...props.productVariant, ...pv_ids])
        }
      })
    } else {
      props.setSelectedProducts([...props.selectedProducts, product])
    }
    props.setNewProductVarientModel(false)
  }

  return (
    <div>
      <Grid id="select-product">
        <Grid.Row className="no-bottom-padding">
          <Grid.Column className={'no-bottom-margin no-bottom-padding'}>
            <label>Product</label>
            <MickeyDropdown
              url="/main/products/"
              placeholder={'Select Product'}
              titleFields={['material.name']}
              descriptionFields={['commodity_form.name', 'commodity.name']}
              onChange={(e, d) => {
                setProduct(d.value)
              }}
            />
            {attributes?.map((attribute, idx) => {
              return (
                <div key={idx}>
                  <label>{attribute.name}</label>
                  <Dropdown
                    label={attribute.name}
                    fluid
                    multiple
                    search
                    selection
                    value={
                      selectedAttributes[attribute.name]
                        ? selectedAttributes[attribute.name]
                        : []
                    }
                    placeholder={`Select ${attribute.name}`}
                    onChange={(e, d) => {
                      let avs = { ...selectedAttributes }
                      avs[attribute.name] = d.value
                      setSelectedAttributes(avs)
                    }}
                    options={attribute?.attribute_selections?.map(
                      (selection) => {
                        return {
                          key: selection.id,
                          value: selection.id,
                          text: selection.value,
                        }
                      }
                    )}
                  />
                </div>
              )
            })}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-top-padding">
          <Grid.Column>
            <Divider />
            <Button
              color="gray"
              content={'Cancel'}
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              onClick={() => props.setNewProductVarientModel(false)}
            />
            <Button
              disabled={!product}
              color="primary"
              content={'Add'}
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
              onClick={() => handleClick()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default SelectProductAttribute
