import React, { useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { addGlobalSettings } from '../../../features/globals/globalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'

function GlobalTakeRate() {
  const [globalSettingId, setGlobalSettingId] = useState()
  const [reloadGlobalSetting, setReloadGlobalSetting] = useState(true)
  const user = useSelector((state) => state.user.user)
  const axios = useAxios()
  const dispatch = useDispatch()

  useEffect(() => {
    const getGlobalSetting = async () => {
      const res = await axios.get('/main/global-setting/')
      if (res.data?.results) {
        setGlobalSettingId(res.data.results[0].id)
        dispatch(addGlobalSettings(res?.data?.results[0]))
      }
      setReloadGlobalSetting(false)
    }

    if (reloadGlobalSetting) {
      getGlobalSetting()
    }
  }, [reloadGlobalSetting])

  if (!globalSettingId) return <></>
  return (
    <>
      <Grid>
        <Grid.Row style={{ padding: '10px' }}>
          <Grid.Column width={16}>
            <p style={{ color: '#A6A6A6' }}>
              This will affect all future orders, and is the same accross all
              admin accounts. To adjust individual take rates, go the the
              individual business account via the Admin Portal.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: '10px' }}>
          <Grid.Column width={3}>
            <MickeyForm
              formName={'AdminGlobalTakeAdjustmentForm'}
              model={'GlobalSetting'}
              tenantAware={false}
              disabled={!hasPermission(user, 'Update Take Rate')}
              instanceId={globalSettingId}
              url={'/main/global-setting/'}
              style={{ border: 'none', boxShadow: 'none' }}
              postSubmit={() => {
                setReloadGlobalSetting(true)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default GlobalTakeRate
