import React from 'react'
import { Label } from 'semantic-ui-react'
import ProductIcon from '../ProductIcon/ProductIcon'

const OrderItemDetailTwo = (props) => {
  const orderItem = props.orderItem
  const productVariant = props.productVariant
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <ProductIcon
        margin={'5px'}
        productName={
          orderItem
            ? orderItem?.product_variant?.product?.material?.name
            : productVariant?.product?.material?.name
        }
      />
      <span style={{ fontWeight: 'bold' }}>
        {orderItem
          ? orderItem?.product_variant?.product?.material?.name
          : productVariant?.product?.material?.name}
        (
        {orderItem
          ? orderItem?.product_variant?.product?.commodity_form?.name
          : productVariant?.product?.commodity_form?.name}
        )
      </span>
      <span style={{ width: '10px' }}></span>
      {orderItem &&
        !orderItem?.is_extra_line_item &&
        orderItem?.product_variant &&
        orderItem?.product_variant?.attribute_selections?.map((attribute) => {
          return (
            <Label
              style={{
                padding: '0 2px',
                margin: '2px',
                backgroundColor: 'transparent',
                color: props.order?.locked ? 'rgba(40, 40, 40, 0.3)' : '',
              }}
            >
              {attribute.attribute_selection_value}
            </Label>
          )
        })}
      {productVariant &&
        productVariant?.attribute_selections?.map((attribute) => {
          return (
            <Label
              style={{
                padding: '0 2px',
                margin: '2px',
                backgroundColor: 'transparent',
              }}
            >
              {attribute.attribute_selection_value}
            </Label>
          )
        })}
    </div>
  )
}

export default OrderItemDetailTwo
