import React, { useEffect, useState, useRef } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import ReactDOM from 'react-dom'
import { Segment, Button } from 'semantic-ui-react'
import env from '../../../env'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken = env.REACT_APP_MAPBOX_TOKEN

const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature.properties.title + ': ' + feature.properties.description)
  }

  let color
  if (feature?.properties?.is_buyer && feature?.properties?.is_supplier) {
    color = 'blue'
  } else if (feature?.properties?.is_buyer) {
    color = 'green'
  } else if (feature?.poperties?.is_supplier) {
    color = 'orange'
  }

  return (
    <Button
      size={'mini'}
      color={color}
      circular
      icon={'map pin'}
      onClick={_onClick}
      className="marker"
    >
      {children}
    </Button>
  )
}

function AdminDashboardMap() {
  const axios = useAxios()
  const mapContainer = useRef(null)
  const map = useRef(null)
  const [locations, setLocations] = useState(null)
  const [loading, setLoading] = useState(false)

  const markerClicked = (title) => {
    window.alert(title)
  }

  useEffect(() => {
    if (!locations) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/westonmickey/cl2gbpoe2000m16qr147gca43',
      center: [-93.65, 38.84],
      zoom: 3,
    })
    // Render custom marker components
    locations?.forEach((feature) => {
      // Create a React ref
      const ref = React.createRef()
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement('div')
      // Render a Marker Component on our new DOM node
      ReactDOM.render(
        <Marker onClick={markerClicked} feature={feature} />,
        ref.current
      )

      // Create a Mapbox Marker at our new DOM node
      new mapboxgl.Marker(ref.current)
        .setLngLat(feature.geometry.coordinates)
        .addTo(map.current)
    })
    // Add navigation control (the +/- zoom buttons)
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right')

    // Clean up on unmount
    return () => map.current.remove()
  }, [locations])

  useEffect(() => {
    async function loadData() {
      setLoading(true)
      let res = await axios.get(`/main/admin-locations/`, {
        params: {},
      })
      setLoading(false)
      if (res?.data) {
        let newRes = res?.data?.results
          ?.filter((location) => {
            return location.latitude && location.longitude
          })
          .map((location) => {
            return {
              properties: {
                type: 'Feature',
                title: location?.business?.name,
                description: location?.address_1,
                is_buyer: location?.is_buyer,
                is_supplier: location?.is_supplier,
              },
              geometry: {
                coordinates: [location?.longitude, location?.latitude],
                type: 'Point',
              },
            }
          })
        setLocations(newRes)
      }
    }
    loadData()
  }, [])

  return (
    <>
      <div>
        <Button
          size={'mini'}
          color={'blue'}
          circular
          icon={'map pin'}
          className="marker"
        >
          Supplier & Buyer
        </Button>
        <Button
          size={'mini'}
          color={'orange'}
          circular
          icon={'map pin'}
          className="marker"
        >
          Supplier
        </Button>
        <Button
          size={'mini'}
          color={'green'}
          circular
          icon={'map pin'}
          className="marker"
        >
          Buyer
        </Button>
      </div>
      <Segment loading={loading} style={{ minHeight: '500px', width: '100%' }}>
        <div
          ref={mapContainer}
          className="map-container"
          style={{ height: '500px', width: '100%' }}
        />
      </Segment>
    </>
  )
}

export default AdminDashboardMap
