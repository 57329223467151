import { useState, useEffect } from 'react'
import ProductFilter from './ProductFilter/ProductFilter'
import ExchangeTable from './ExchangeTable/ExchangeTable'
import { Sidebar } from 'semantic-ui-react'
import './MXExchange.css'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import env from '../../../../env'

const MXExchange = (props) => {
  const [filter, setFilter] = useState([])
  const [materialFilter, setMaterialFilter] = useState([])
  const [attributes, setAttributes] = useState([])
  const [activeAttributeIndex, setActiveAttributeIndex] = useState(0)
  const navigate = useNavigate()
  const [filterSelections, setFilterSelections] = useState([])
  const { commoditySlug, commodityFormSlug } = useParams()
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const [timeframeFilter, setTimeframeFilter] = useState([])
  const [truckingTypeFilter, setTruckingTypeFilter] = useState([])
  const [customFilterLoading, setCustomFilterLoading] = useState(true)

  useEffect(() => {
    if (globalSettings?.id && globalSettings?.hide_exchange) {
      navigate(window.location.pathname + '/orders')
    }
  }, [props.currentPortal])

  useEffect(() => {
    if (
      !props.product &&
      !commodityFormSlug &&
      props.selectedCommodity &&
      props.selectedCommodity?.commodity_forms?.length > 0
    ) {
      props.setSelectedCommodityForm(
        props.yourBids || props.watchlist
          ? props.selectedCommodity?.commodity_forms[0]
          : { id: 0 }
      )
    }
  }, [props.selectedCommodity])

  useEffect(() => {
    if (commodityFormSlug && props.commodityForms) {
      props.commodityForms?.forEach((result) => {
        if (result.slug === commodityFormSlug) {
          props.setSelectedCommodityForm(result)
        }
      })
    }
  }, [props.commodityForms, commodityFormSlug])

  useEffect(() => {
    if (commoditySlug && props.commodities) {
      props.commodities?.forEach((result) => {
        if (result.slug === commoditySlug) {
          props.setSelectedCommodity(result)
        }
      })
    }
  }, [props.commodities, commoditySlug])

  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab('exchange')
    }
  }, [])

  const makeProductSchema = () => {
    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Home',
          url:
            (env.REACT_APP_ENVIRONMENT === 'local'
              ? 'marketplace.localhost:3003'
              : env.REACT_APP_URL) +
            ((props.currentPortal === 'buyer' && '/portal/buyer') ||
              (props.currentPortal === 'supplier' && '/portal/supplier') ||
              (!props.currentPortal && '/exchange')),
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: props.product?.commodity?.name?.toLowerCase(),
          url:
            (env.REACT_APP_ENVIRONMENT === 'local'
              ? 'marketplace.localhost:3003'
              : env.REACT_APP_URL) +
            ((props.currentPortal === 'buyer' && '/portal/buyer') ||
              (props.currentPortal === 'supplier' && '/portal/supplier') ||
              (!props.currentPortal && '/exchange')) +
            props?.product?.commodity?.name?.toLowerCase(),
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: props.product?.commodity_form?.name?.toLowerCase(),
          url:
            (env.REACT_APP_ENVIRONMENT === 'local'
              ? 'marketplace.localhost:3003'
              : env.REACT_APP_URL) +
            ((props.currentPortal === 'buyer' && '/portal/buyer') ||
              (props.currentPortal === 'supplier' && '/portal/supplier') ||
              (!props.currentPortal && '/exchange')) +
            props.product?.commodity?.name?.toLowerCase() +
            '/' +
            props.product?.commodity_form?.name?.toLowerCase(),
        },
        {
          '@type': 'ListItem',
          position: 4,
          name: props.product?.material?.name?.toLowerCase(),
          url:
            (env.REACT_APP_ENVIRONMENT === 'local'
              ? 'marketplace.localhost:3003'
              : env.REACT_APP_URL) +
            ((props.currentPortal === 'buyer' && '/portal/buyer') ||
              (props.currentPortal === 'supplier' && '/portal/supplier') ||
              (!props.currentPortal && '/exchange')) +
            props.product?.commodity?.name?.toLowerCase() +
            '/' +
            props.product?.commodity_form?.name?.toLowerCase() +
            '/' +
            props.product?.slug?.toLowerCase(),
        },
      ],
    })
  }
  return (
    <>
      <Helmet>
        {props.product && (
          <script type="application/ld+json" key={props.product?.slug}>
            {makeProductSchema()}
          </script>
        )}
      </Helmet>
      <Sidebar.Pushable id="exchange-pushable">
        {!props.hideFilter && (
          <Sidebar
            id="pushable-sidebar"
            animation="overlay"
            icon="labeled"
            inverted={'true'}
            vertical={'true'}
            visible={props.openExchangeFilter}
            width="wide"
          >
            <ProductFilter
              yourBids={props.yourBids}
              watchlist={props.watchlist}
              attributes={attributes}
              setAttributes={setAttributes}
              selectedCommodity={props.selectedCommodity}
              openFilter={props.openExchangeFilter}
              filter={filter}
              setFilter={setFilter}
              materialFilter={materialFilter}
              setMaterialFilter={setMaterialFilter}
              timeframeFilter={timeframeFilter}
              setTimeframeFilter={setTimeframeFilter}
              truckingTypeFilter={truckingTypeFilter}
              setTruckingTypeFilter={setTruckingTypeFilter}
              activeAttributeIndex={activeAttributeIndex}
              setActiveAttributeIndex={setActiveAttributeIndex}
              filterSelections={filterSelections}
              setFilterSelections={setFilterSelections}
              selectedCommodityForm={props.selectedCommodityForm}
              setSelectedCommodityForm={props.setSelectedCommodityForm}
              setCustomFilterLoading={setCustomFilterLoading}
            />
          </Sidebar>
        )}
        {((!props.product &&
          !props.yourBids &&
          !props.watchlist &&
          props.commodityForms) ||
          props.product ||
          props.yourBids ||
          props.watchlist ||
          props.commodityForms) && (
          <ExchangeTable
            loadData={props?.loadData}
            user={props.user}
            setUser={props.setUser}
            setAdminUser={props.setAdminUser}
            business={props.business}
            is_admin={props.is_admin}
            yourBids={props.yourBids}
            product={props.product}
            watchlist={props.watchlist}
            commoditySlug={commoditySlug}
            openFilter={props.openExchangeFilter}
            setOpenFilter={props.setOpenExchangeFilter}
            reload={props.reload}
            setReload={props.setReload}
            filter={filter}
            setFilter={setFilter}
            materialFilter={materialFilter}
            setMaterialFilter={setMaterialFilter}
            currentPortal={props.currentPortal}
            selectedCommodity={props.selectedCommodity}
            setSelectedCommodity={props.setSelectedCommodity}
            setActiveAttributeIndex={setActiveAttributeIndex}
            currentSelectedLocation={props.currentSelectedLocation}
            setCurrentSelectedLocation={props.setCurrentSelectedLocation}
            timeframeFilter={timeframeFilter}
            truckingTypeFilter={truckingTypeFilter}
            filterSelections={filterSelections}
            setFilterSelections={setFilterSelections}
            openStartListingModal={props.openStartListingModal}
            setStartListingModal={props.setStartListingModal}
            openSetAttributesModal={props.openSetAttributesModal}
            setReloadHeaderStats={props.setReloadHeaderStats}
            setOpenAttributesModal={props.setOpenAttributesModal}
            openPricingModal={props.openPricingModal}
            setOpenPricingModal={props.setOpenPricingModal}
            openReviewListingModal={props.openReviewListingModal}
            setReviewListingModal={props.setReviewListingModal}
            selectedCommodityForm={props.selectedCommodityForm}
            setSelectedCommodityForm={props.setSelectedCommodityForm}
            inputExpiration={props.inputExpiration}
            setInputExpiration={props.setInputExpiration}
            inputSecondaryPrice={props.inputSecondaryPrice}
            setInputSecondaryPrice={props.setInputSecondaryPrice}
            productVariant={props.productVariant}
            setProductVariant={props.setProductVariant}
            setInputPrice={props.setInputPrice}
            inputPrice={props.inputPrice}
            setInputQuantity={props.setInputQuantity}
            inputQuantity={props.inputQuantity}
            commodities={props.commodities}
            setCommodities={props.setCommodities}
            commodityForms={props.commodityForms}
            setCommodityForms={props.setCommodityForms}
            publicLocation={props.publicLocation}
            setPublicLocation={props.setPublicLocation}
            publicExchange={props.publicExchange}
            setPublicExchange={props.setPublicExchange}
            hideFilter={props.hideFilter}
            setHideFilter={props.setHideFilter}
            currentlySelectedTimeframe={props.currentlySelectedTimeframe}
            setCurrentlySelectedTimeframe={props.setCurrentlySelectedTimeframe}
            currentlySelectedTruckingType={props.currentlySelectedTruckingType}
            setCurrentlySelectedTruckingType={
              props.setCurrentlySelectedTruckingType
            }
            customFilterLoading={customFilterLoading}
          />
        )}
      </Sidebar.Pushable>
    </>
  )
}

export default MXExchange
