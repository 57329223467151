import React, { useState } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Table, Label, Button, Form, Input, Modal } from 'semantic-ui-react'
import {
  formatTotalPrice,
  formatQuantities,
  formatOrderNumber,
} from '../../../lib/utils/utils'
import { toast } from 'react-toastify'
import ProductIcon from '../../../components/ProductIcon/ProductIcon'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { X } from 'phosphor-react'
import './BuyerOrderItemsRow.css'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const BuyerOrderItemsRow = (props) => {
  const { orderItem, setReloadOrder } = props
  const user = useSelector((state) => state.user.user)
  const [openTruckingModal, setOpenTruckingModal] = useState(false)
  const wd = useWindowDimensions()
  const axios = useAxios()

  const markOrderDelivered = async () => {
    let res = await axios.patch(
      `/main/order-items/${orderItem.string_token}/?tenant_aware=false`,
      {
        delivered: true,
      }
    )
    if (res.status === 200) {
      toast.success('Marked order as delivered!')
    }
    setReloadOrder(true)
  }

  return (
    <>
      <Table.Row selectable>
        <Table.Cell
          style={{ fontSize: wd.width >= 768 ? '' : '1.5rem' }}
          width={2}
        >
          {formatOrderNumber(orderItem?.string_token)}
        </Table.Cell>
        <Table.Cell>{orderItem?.created_at}</Table.Cell>

        <Table.Cell>
          {orderItem?.is_extra_line_item &&
            orderItem?.extra_line_item_description}
          {orderItem?.is_sales_tax_fee && <>Sales Tax Fee</>}
          {orderItem?.is_marketplace_fee && <>Marketplace Fee</>}
          {orderItem?.is_credit_line_item && <>Terms // Credit Card Fee</>}
          {orderItem?.product_variant?.product && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <ProductIcon
                margin={'5px'}
                productName={orderItem?.product_variant?.product.material.name}
              />
              <Label color={'green'}>
                {orderItem?.product_variant?.product.material.name} (
                {orderItem?.product_variant?.product.commodity_form.name})
              </Label>
              {orderItem?.product_variant?.attribute_selections?.map(
                (attribute) => {
                  return (
                    <Label
                      style={{
                        margin: '5px',
                        backgroundColor: 'transparent',
                      }}
                    >
                      {attribute.attribute_selection_value}
                    </Label>
                  )
                }
              )}
            </div>
          )}
        </Table.Cell>
        <Table.Cell width={1}>
          {!orderItem?.is_marketplace_fee &&
            !orderItem?.is_credit_line_item && (
              <>
                <>
                  {formatQuantities(orderItem?.buyer_quantity)}{' '}
                  {orderItem?.unit?.short_name}
                </>
              </>
            )}
        </Table.Cell>
        <Table.Cell width={1}>
          {!orderItem?.is_marketplace_fee && (
            <>
              {formatTotalPrice(orderItem?.delivered_price)}
              {'/'}
              {orderItem?.unit?.short_name}
            </>
          )}
        </Table.Cell>
        <Table.Cell positive>
          {orderItem?.is_sales_tax_fee && (
            <>{formatTotalPrice(orderItem?.sales_tax_fee)}</>
          )}
          {orderItem?.is_marketplace_fee && (
            <>{formatTotalPrice(orderItem?.buyer_marketplace_fee)}</>
          )}
          {!orderItem?.is_marketplace_fee && !orderItem?.is_sales_tax_fee && (
            <>
              {formatTotalPrice(
                orderItem?.buyer_quantity && orderItem?.delivered_price
                  ? orderItem?.buyer_quantity * orderItem?.delivered_price
                  : 0
              )}
            </>
          )}
        </Table.Cell>
        {orderItem?.supplier_shipped &&
          !orderItem?.delivered &&
          hasPermission(user, 'Update Material List') && (
            <Table.Cell width={2}>
              <Button
                content={'Mark Item Delivered'}
                size={'mini'}
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'rgb(242 88 47)',
                  color: 'white',
                }}
                onClick={async () => {
                  await markOrderDelivered()
                }}
              />
            </Table.Cell>
          )}
      </Table.Row>

      <Modal
        className="trucking-modal"
        onClose={() => setOpenTruckingModal(false)}
        onOpen={() => setOpenTruckingModal(true)}
        open={openTruckingModal}
      >
        <Modal.Header>
          <div>
            <span>Trucking Details</span>
            <X
              size={25}
              link
              onClick={() => {
                setOpenTruckingModal(false)
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group className="form-header">
              <Form.Field>
                <span>Date & Time</span>
              </Form.Field>
              <Form.Field>
                <span>Logistics/Cost</span>
              </Form.Field>
              <Form.Field>
                <span>Truck Size</span>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label style={{ marginBottom: '1rem' }}>
                  Location for Pickup
                </label>
                <Input
                  type={'text'}
                  value={
                    orderItem?.pickup_location?.address_1 +
                    ', ' +
                    orderItem?.pickup_location?.city +
                    ', ' +
                    orderItem?.pickup_location?.state +
                    ' ' +
                    orderItem?.pickup_location?.zip_code
                  }
                  placeholder={'Location for Pickup'}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Truck Size*</label>
                <Input
                  type={'text'}
                  value={orderItem?.trucking_quote?.type}
                  placeholder={'Truck Size'}
                  disabled
                />
              </Form.Field>
              <Form.Field>
                <label>Logistics Company</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.trucking_company}
                  placeholder={'TLogistics Company'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Pick-Up Date*</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.pickup_date}
                  placeholder={'Pick-Up Date'}
                />
              </Form.Field>
              <Form.Field>
                <label>Estimated Load Weight</label>
                <Input
                  type={'text'}
                  disabled
                  value={
                    orderItem?.trucking_quote?.api_response?.data?.items?.weight
                  }
                  placeholder={'Estimated Load Weight'}
                />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.rate}
                  placeholder={'Cost'}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field>
                <label>Drop-off Date*</label>
                <Input
                  type={'text'}
                  disabled
                  value={orderItem?.trucking_quote?.delivery_date}
                  placeholder={'Drop-off Date'}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    </>
  )
}

export default BuyerOrderItemsRow
