import React, { useEffect, useState } from 'react'
import './Menu.css'
import { Button, Grid, Modal, Header } from 'semantic-ui-react'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useAxios from '../../lib/auth/useAxios'
import env from './../../env'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { phone } from 'phone'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import { addUser } from '../../features/user/userSlice'

export default function Supplier(props) {
  const [open, setOpen] = useState(false)
  const [phone_numb, setPhone] = useState(null)
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const axios = useAxios()
  const wd = useWindowDimensions()

  useEffect(() => {
    props.setCurrentPortal('supplier')
    document.body.style.zoom = '100%'
  }, [])

  useEffect(() => {
    if (user?.id && !user?.has_logged_in) {
      setPhone(user.phone)
      setOpen(true)
    }
  }, [user])

  const updateSMS = async (receive_sms_messages, phone_number) => {
    let valid_phone = phone_number
    if (phone_number) {
      if (valid_phone?.[0] === '1') {
        valid_phone = valid_phone.slice(1)
      }
    }
    if (phone_number == null && !receive_sms_messages) {
      await axios.patch(`/main/custom-users/${user.id}/`, {
        receive_sms_messages,
        has_logged_in: true,
      })
      setOpen(false)
    } else if (!phone(valid_phone).isValid) {
      toast.error('A valid phone number is required')
    } else {
      let res = await axios.patch(`/main/custom-users/${user.id}/`, {
        receive_sms_messages,
        phone_number,
        has_logged_in: true,
      })
      dispatch(addUser(res?.data))
      setOpen(false)
    }
  }

  return (
    <div>
      <Outlet />
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        size={'fullscreen'}
        id="text-updates"
      >
        <Modal.Content>
          <Grid stackable centered width={16} className="modal-inner-wrapper">
            <Grid.Column
              className="updates-left"
              width={wd.width <= 935 ? 16 : 8}
            >
              <div className="header-wrapper">
                <Header as={'h1'} className="text-updates-header">
                  Would you like to receive
                  <br />
                  notifications via text?
                  <Header.Subheader as={'p'} className="text-updates-subheader">
                    These settings can be found and changed in the
                    <br />
                    settings tab.
                  </Header.Subheader>
                </Header>
              </div>
            </Grid.Column>
            <Grid.Column
              className="updates-right"
              width={wd.width <= 935 ? 16 : 8}
            >
              <div className="actions-wrapper">
                <div className="phone-submit-wrapper">
                  <PhoneInput
                    country={'us'}
                    value={phone_numb}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    onChange={(e, d) => setPhone(e)}
                    style={{ height: '50px', marginTop: '25px' }}
                  />

                  <Button
                    className="submit-button"
                    color="primary"
                    onClick={() => updateSMS(true, phone_numb)}
                    style={{
                      '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                      '--secondaryButtonColor':
                        env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    }}
                  >
                    Submit
                  </Button>
                </div>
                <div
                  className="no-button"
                  onClick={() => updateSMS(false, null)}
                >
                  Maybe Later
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  )
}
