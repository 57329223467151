import React, { useState, useEffect } from 'react'
import _ from 'underscore'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import useAxios from '../../../lib/auth/useAxios'
import { Segment } from 'semantic-ui-react'
import moment from 'moment'

function AdminCalendar(props) {
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const axios = useAxios()

  async function getData(startDate, endDate) {
    setLoading(true)
    let res = await axios.get(`main/cargo-unit-instances/`, {
      params: {
        salesperson_id: props.salespersonId,
        estimated_shipping_date_range: [startDate, endDate],
        calendar_serializer: 'true',
        page_size: 'infinite',
      },
    })

    let evs = res?.data?.results?.map((cui) => {
      return {
        title: `Deal ${cui?.order?.order_number}, ${cui?.pickup_business_name} -> ${cui?.delivery_business_name}`,
        url: `/portal/admin/deals/${cui?.order?.string_token}/`,
        start: Date.parse(cui.estimated_ship_date),
        end: Date.parse(cui.estimated_delivery_date),
      }
    })

    setEvents(evs)
    setLoading(false)
  }

  const handleDatesSet = (info) => {
    let startDate = moment(info.view.activeStart).format('YYYY-MM-DD')
    let endDate = moment(info.view.activeEnd).format('YYYY-MM-DD')

    getData(startDate, endDate)
  }

  return (
    <div style={{ margin: '30px' }}>
      <Segment basic loading={loading}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          headerToolbar={props.headerToolbar}
          datesSet={handleDatesSet}
        />
      </Segment>
    </div>
  )
}
export default AdminCalendar
