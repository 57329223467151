export const AdminBuyerTakeAdjustmentForm = {
  model: 'Buyer',
  format: [
    {
      widths: 'equal',
      fields: {
        buyer_take_adjustment: {
          type: 'number',
          step: '0.1',
          min: -100,
          max: 100,
          round: 2,
          label: 'Buyer Take Rate',
          validatorInputs: ['global-buyer-take-rate'],
          validator: (input, globalBuyerTakeRate) => {
            let error
            let float
            if (input) {
              if (isNaN(input)) {
                error =
                  'Number greater than -100 and less than or equal to 100 required'
              } else {
                float = parseFloat(input)
                if (float + globalBuyerTakeRate < 0) {
                  error = 'Effective take rate cannot be less than 0'
                }
              }
            } else {
              error =
                'Number greater than -100 and less than or equal to 100 required'
            }
            return error
          },
          icon: { name: 'percent', circular: false, link: false },
        },
      },
    },
  ],
}
