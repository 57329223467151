import colorable from 'colorable'

function ProductIcon(props) {
  var stringToColour = function (str) {
    var hash = 0
    for (var i = 0; i < str?.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    var colour = '#'
    for (var j = 0; j < 3; j++) {
      var value = (hash >> (j * 8)) & 0xff
      colour += ('00' + value.toString(16)).substr(-2)
    }
    return colour
  }

  function getContrastedColors(colors) {
    let c = colorable(colors, { compact: true, threshold: 0 })
    let finalColors = []
    c.forEach((color, index) => {
      if (color.combinations.length > 0) {
        color.combinations.forEach((combination) => {
          if (combination.contrast > 4) {
            finalColors = finalColors.concat([colors[index], combination.hex])
          }
        })
      }
    })
    return finalColors
  }

  var initialColors = [
    stringToColour(props.productName),
    stringToColour(props.productName?.split('').reverse().join('')),
    stringToColour(props.productName?.split('').sort().join('')),
  ]

  let finalContrastedColors = getContrastedColors(initialColors)
  for (var i = 0; i < 5; i++) {
    if (finalContrastedColors?.length <= 1) {
      let newProductName =
        i.toString() +
        props.productName +
        i.toString() +
        i.toString() +
        i.toString()
      let newColors = [
        stringToColour(newProductName),
        stringToColour(newProductName?.split('').reverse().join('')),
        stringToColour(newProductName?.split('').sort().join('')),
      ]

      finalContrastedColors = getContrastedColors(newColors)
    }
    if (finalContrastedColors?.length <= 1 && i === 4) {
      finalContrastedColors = initialColors
    }
  }
  return (
    <span
      style={{
        alignItems: 'center',
        display: 'inline-block',
        position: 'relative',
        justifyContent: 'center',
        padding: props.padding ? props.padding : '4px',
        margin: props.margin ? props.margin : '',
        width: props.width ? props.width : '27px',
        height: props.height ? props.height : '27px',
        minWidth: props.width ? props.width : '27px',
        minHeight: props.height ? props.height : '27px',
        borderRadius: props.width
          ? `${parseFloat(props.width.toString().replace('px', '')) / 2}px`
          : '13.5px',
        backgroundColor: finalContrastedColors[0], //c[0]["combinations"][0]["hex"],
        color: finalContrastedColors[1],
      }}
    >
      <span
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: props.fontSize && props.fontSize,
        }}
      >
        {props.productName?.charAt(0).toUpperCase()}
        {props.productName?.charAt(1).toLowerCase().match(/[a-z]/i)
          ? props.productName?.charAt(1).toLowerCase()
          : props.productName?.charAt(2).toLowerCase()}
      </span>
    </span>
  )
}

export default ProductIcon
