import PageHeader from '../../../../components/PageHeader/PageHeader'
import AdminFulfillmentTrucking from '../../AdminFulfillment/AdminFulfillmentTrucking'

const AdminSalesMyFulfillment = (props) => {
  const { salespersonId } = props
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/orders' },
    { name: 'Sales', href: '/portal/admin/sales' },
  ]
  return (
    <>
      <PageHeader
        header="My Fulfillment"
        breadcrumbHeader="My Fulfillment"
        breadcrumb={breadcrumbData}
      />
      <AdminFulfillmentTrucking
        salespersonId={salespersonId}
        hideHeader={true}
      />
    </>
  )
}

export default AdminSalesMyFulfillment
