import React, { useState } from 'react'
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react'
import './ResetPassword.css'
import logo from '../../../../img/main-logo.webp'
import useAxios from '../../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import env from '../../../../env'
import { emailValidator } from '../../../../components/Forms/validators'

function ResetPassword(props) {
  const [email, setEmail] = useState('')
  const axios = useAxios()

  const submitEmail = () => {
    async function postEmail() {
      let errorMessage = emailValidator(email)
      if (errorMessage) {
        toast.error(errorMessage)
        return
      }
      const res = await axios.post('/main/reset-password/', {
        email: email,
      })
      if (res.status === 200 && !res?.data?.error) {
        toast.success(
          'A password reset link was E-mailed. Check your inbox for further instruction!'
        )
      } else {
        toast.error('No account exists with this email')
      }
    }
    postEmail()
  }

  return (
    <Grid
      id="reset-password-page"
      style={{
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
      }}
    >
      <Grid.Column
        style={{
          maxWidth: '500px',
        }}
      >
        <Segment
          className="main-background-texture"
          style={{
            padding: '10px 44px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          <Image
            centered
            src={env.REACT_APP_LOGO}
            alt="Logo"
            style={{ marginTop: '20px', maxWidth: '200px', height: 'auto' }}
          />
          <Header
            textAlign="center"
            style={{
              color: '#000',
              fontSize: '24px',
              margin: '35px auto 20px auto',
              letterSpacing: '1px',
            }}
          >
            Reset your password
          </Header>
          <p className="submit-text" style={{ color: 'grey' }}>
            Submit your email below and await further instruction
          </p>
          <Form size="large" style={{ minWidth: '100%' }}>
            <Form.Input
              inverted
              fluid
              color={'white'}
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ margin: '40px auto', borderRadius: '5px' }}
            />
            <Button
              className={'reset-btn'}
              color={'primary'}
              fluid
              size="large"
              type="submit"
              onClick={submitEmail}
              style={{
                border: 'none',
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              }}
              disabled={email === '' || !email.includes('@')}
            >
              Submit
            </Button>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default ResetPassword
