import { Table, Label, Popup } from 'semantic-ui-react'
import './AdminFulfillment.css'
import OrderItemDetailTwo from '../../../components/OrderItemDetail/OrderItemDetailTwo'
import { CaretDown, CaretRight } from 'phosphor-react'

function AdminFulfillmentTruckingRow(props) {
  return (
    <Table.Row
      positive={
        props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
      }
    >
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        {props.cargoUnitInstance?.id !==
          props.selectedCargoUnitInstance?.id && (
          <CaretRight size={20} style={{ verticalAlign: 'middle' }} />
        )}
        {props.cargoUnitInstance?.id ===
          props.selectedCargoUnitInstance?.id && (
          <CaretDown size={20} style={{ verticalAlign: 'middle' }} />
        )}
        <span style={{ marginLeft: '10px' }}>
          {props.cargoUnitInstance?.shipper_load_id}
        </span>
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        {props.cargoUnitInstance?.trucking_company?.name}
      </Table.Cell>
      <Table.Cell>
        <a
          href={`/portal/admin/deals/${props.cargoUnitInstance?.order?.string_token}/`}
        >
          {props.cargoUnitInstance?.order?.order_number}
        </a>
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        {props.cargoUnitInstance?.supplier_name}
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        {props.cargoUnitInstance?.buyer_name}
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        {props.cargoUnitInstance?.estimated_ship_date}
      </Table.Cell>
      <Table.Cell
        onClick={() => {
          if (
            props.selectedCargoUnitInstance?.id === props.cargoUnitInstance?.id
          ) {
            props.setSelectedCargoUnitInstance(null)
          } else {
            props.setSelectedCargoUnitInstance(props.cargoUnitInstance)
          }
        }}
      >
        <Label
          color={
            props.cargoUnitInstance?.delivered
              ? 'green'
              : !props.cargoUnitInstance?.delivered &&
                props.cargoUnitInstance?.supplier_shipped
              ? 'orange'
              : !props.cargoUnitInstance?.supplier_shipped &&
                props.cargoUnitInstance?.trucking_booked
              ? 'grey'
              : ''
          }
          content={
            <>
              {props.cargoUnitInstance?.delivered && 'Delivered'}
              {!props.cargoUnitInstance?.delivered &&
                !props.cargoUnitInstance?.delivered &&
                props.cargoUnitInstance?.supplier_shipped &&
                'In Transit'}
              {!props.cargoUnitInstance?.delivered &&
                !props.cargoUnitInstance?.supplier_shipped &&
                props.cargoUnitInstance?.trucking_booked &&
                'Booked'}
              {!props.cargoUnitInstance?.delivered &&
                !props.cargoUnitInstance?.supplier_shipped &&
                !props.cargoUnitInstance?.trucking_booked &&
                'Unbooked'}
            </>
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default AdminFulfillmentTruckingRow
