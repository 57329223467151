import React, { useState, useMemo } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Grid, Segment, Modal, Button, Header } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import AdminBusinessDocumentGenerationModal from './AdminBusinessDocumentGenerationModal'
import env from '../../../env'
import DocumentTable from '../../../components/Documents/DocumentTable'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import { Plus } from 'phosphor-react'

function AdminBusinessDocuments(props) {
  const { business } = props
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update Document')
  const canDelete = hasPermission(user, 'Delete Document')
  const [openDocumentForm, setOpenDocumentForm] = useState(false)
  const [reload, setReload] = useState(false)
  const [showDocGenerationModal, setShowDocGenerationModal] = useState(false)
  const [activeItem, setActiveItem] = useState('Documents')
  const items = useMemo(() => {
    return ['Documents', 'Document Generation']
  })

  return (
    <div>
      <div className="exchange-table-heading">
        <div className="commodities-wrapper">
          {items?.map((item) => {
            return (
              <Header
                as={'h2'}
                className={
                  activeItem === item
                    ? 'commodity-header selected'
                    : 'commodity-header'
                }
                color={activeItem === item ? 'primary' : 'black'}
                onClick={() => setActiveItem(item)}
                style={{
                  margin: '0 1rem 1rem 0',
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
              >
                {item}
              </Header>
            )
          })}
        </div>
      </div>
      <Segment basic>
        {activeItem === 'Documents' && (
          <>
            {canUpdate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  basic
                  icon={<Plus size={20} weight="bold" />}
                  labelPosition={'left'}
                  color={'primary'}
                  style={{
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    height: '40px',
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  }}
                  size={'tiny'}
                  className={'extra-padding'}
                  content={'Add Document'}
                  onClick={() => setOpenDocumentForm(true)}
                />
              </div>
            )}
            <Grid.Column width={16}>
              <DocumentTable
                hideHeader
                reload={reload}
                setReload={setReload}
                businessId={business?.id}
                canDelete={canDelete}
              />
            </Grid.Column>
          </>
        )}
        {activeItem === 'Document Generation' && (
          <>
            <ESignRequestsTable
              businessIdUsers={business?.id}
              businessId={business?.id}
              style={{ marginTop: '50px' }}
              hideHeader
            />
          </>
        )}
      </Segment>
      <Modal
        onClose={() => setOpenDocumentForm(false)}
        onOpen={() => setOpenDocumentForm(true)}
        open={openDocumentForm}
      >
        <Modal.Header>Add New Document</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'AdminBusinessDocumentUploadForm'}
            currentPortal={'admin'}
            model={'Document'}
            basicSegment
            url={'/main/documents/'}
            choicesFetchArgs={{
              order: [business?.id],
            }}
            tenantAware={false}
            handleForm={() => {
              setReload(true)
              setOpenDocumentForm(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <AdminBusinessDocumentGenerationModal
        showModal={showDocGenerationModal}
        toggleModal={() => setShowDocGenerationModal(false)}
        business={business}
      />
    </div>
  )
}

export default AdminBusinessDocuments
