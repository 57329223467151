import { phone } from 'phone'
import { validate } from 'react-email-validator'
import countries from 'i18n-iso-countries'

export const requiredValidator = (input) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  }
  return errorMessage
}

export const requiredEmailValidator = (input) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  } else if (!validate(input)) {
    errorMessage = 'Invalid email'
  }
  return errorMessage
}

export const emailValidator = (input) => {
  let errorMessage
  if (input && !validate(input)) {
    errorMessage = 'Invalid email'
  }
  return errorMessage
}

export const requiredPhoneNumberValidator = (input) => {
  let valid_phone = input
  if (valid_phone[0] === '1') {
    valid_phone = valid_phone.slice(1)
  }
  let errorMessage
  if (!input || input === '+1') {
    errorMessage = 'This field is required'
  } else if (!phone(valid_phone).isValid) {
    errorMessage = 'A valid phone number is required'
  }
  return errorMessage
}

export const phoneNumberValidator = (input) => {
  let valid_phone = input
  if (valid_phone && valid_phone[0] === '1') {
    valid_phone = valid_phone.slice(1)
  }
  let errorMessage
  if (input && input !== '+1' && !phone(valid_phone).isValid) {
    errorMessage = 'Invalid phone number'
  }
  return errorMessage
}

export const requiredCountryValidator = (input) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  } else if (!countries.isValid(input)) {
    errorMessage = 'A valid country is required'
  }
  return errorMessage
}

export const zipCodeValidator = (input) => {
  let errorMessage
  let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input)
  if (!input) {
    errorMessage = 'This field is required'
  } else if (!isValidZip) {
    errorMessage = 'A valid zip code is required'
  }
  return errorMessage
}

export const requiredOnlyDigtsNotAllowedValidator = (input, fieldName) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  } else if (/^\d+$/.test(input) || input === parseFloat(input)) {
    errorMessage = `A valid ${fieldName} is required`
  }
  return errorMessage
}

export const onlyDigtsNotAllowedValidator = (input, fieldName) => {
  let errorMessage
  if (input && (/^\d+$/.test(input) || input === parseFloat(input))) {
    errorMessage = `A valid ${fieldName} is required`
  }
  return errorMessage
}

export const requiredPositiveNumberValidator = (input, fieldName) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  } else if (input < 0) {
    errorMessage = `A valid ${fieldName} is required`
  }
  return errorMessage
}

export const requiredImageFileValidator = (input) => {
  let errorMessage
  if (!input) {
    errorMessage = 'This field is required'
  } else if (!input?.type?.includes('image')) {
    errorMessage = `Invalid image.`
  }
  return errorMessage
}
