import React, { useState, useContext, useEffect, createContext } from 'react'
import env from './../env'
import { useInterval } from './useInterval'
import { v4 as uuid } from 'uuid'
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from '../lib/auth/useAuth'

let WebsocketContext = createContext()

const WebsocketProvider = ({ children }) => {
  const { isLoggedIn: reAuth } = useAuth()
  const [ws, setWs] = useState()
  const [wsOpen, setWsOpen] = useState(false)
  const [resetWs_, setResetWs] = useState(false)
  const [wsIdle, setWsIdle] = useState(false)
  const [openingWs, setOpeningWs] = useState(false)
  const [lastWsMessage, setLastWsMessage] = useState()
  const [lastWsError, setWsLastError] = useState()
  const [lastPingDateTime, setLastPingDateTime] = useState()
  const [lastResetDateTime, setLastResetDateTime] = useState()
  const [currentMessageId, setCurrentMessageId] = useState()

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: (event) => {
      setWsIdle(true)
      disconnectWs()
    },
    onActive: (event) => {
      setWsIdle(false)
      resetWs()
    },
    debounce: 500,
  })

  useInterval(() => {
    const accessToken = localStorage.getItem('access_token')
    if (!wsIdle && accessToken && (!ws || !ws.readyState === 1)) {
      resetWs()
    } else if (ws && ws.readyState === 1 && !wsOpen) {
      setWsOpen(true)
    }
  }, 1000)

  useInterval(
    () => {
      if (
        ws &&
        ws.readyState === 1 &&
        (!lastPingDateTime || new Date() - lastPingDateTime > 1000 * 60 * 4)
      ) {
        ws.send(pingPongWsMessage())
        setLastPingDateTime(new Date())
      }
    },
    1000 * 60 * 5
  )

  useEffect(() => {
    const resetWebSocket = async () => {
      console.log('ws auth')
      await reAuth()
      const url = wsUrl()
      if (url && !wsIdle && (!wsOpen || resetWs_) && !openingWs) {
        setOpeningWs(true)
        console.log('opening websocket')
        const websocket = new WebSocket(url)
        websocket.onopen = (e) => {
          console.log('websocket open')
          setWsOpen(true)
          setResetWs(false)
        }
        websocket.onmessage = (e) => {
          const message = JSON.parse(e.data)
          console.log('websocket message')
          console.log(message)
          setLastWsMessage(message)
        }
        websocket.onerror = (e) => {
          setWsLastError(e)
          console.log('websocket error')
          console.log(e)
        }
        websocket.onclose = (e) => {
          console.log('websocket closed')
          console.log(e)
          setWsOpen(false)
          if (
            !env.REACT_APP_ENVIRONMENT === 'local' ||
            !lastResetDateTime ||
            new Date() - lastPingDateTime > 4000
          ) {
            setLastResetDateTime(new Date())
            resetWs()
          } else {
            disconnectWs()
          }
        }
        setWs(websocket)
        setOpeningWs(false)
      }
    }
    resetWebSocket()
  }, [resetWs_])

  const disconnectWs = () => {
    console.log('disconnect ws')
    setWs((prevState) => {
      if (prevState) {
        try {
          console.log('disconnecting')
          prevState.close()
        } catch {
          //
        }
      }
      return null
    })
    setWsOpen(false)
  }

  const resetWs = () => {
    console.log('reset ws')
    disconnectWs()
    setResetWs(true)
  }

  const wsUrl = () => {
    const accessToken = localStorage.getItem('access_token')
    if (accessToken) {
      return `${env.REACT_APP_YODA_WEBSOCKET}?jwt=${accessToken}`
    } else {
      return env.REACT_APP_YODA_WEBSOCKET
    }
  }

  const orderWsMessage = (message) => {
    const instant = new Date()
    message['submitted_at'] = instant.toISOString()
    message['action'] = 'order'
    return wsMessage(message)
  }

  const createTradeWsMessage = (message) => {
    message['handler'] = 'create-trade'
    return exchangeWsMessage(message)
  }
  const editOrderWsMessage = (message) => {
    message['handler'] = 'update-order'
    return exchangeWsMessage(message)
  }

  const closeOrderWsMessage = (message) => {
    message['handler'] = 'close-order'
    return exchangeWsMessage(message)
  }

  const orderBookWsMessage = (message) => {
    message['handler'] = 'filter-order-book'
    return exchangeWsMessage(message)
  }

  const exchangeWsMessage = (message) => {
    message['action'] = 'exchange'
    return wsMessage(message)
  }

  const pingPongWsMessage = () => {
    return exchangeWsMessage({
      message_id: uuid(),
      handler: 'ping',
    })
  }

  const wsMessage = (message) => {
    if (message?.handler !== 'ping') {
      const messageId = uuid()
      setCurrentMessageId(messageId)
      console.log(currentMessageId)
      message['message_id'] = messageId
    }
    console.log('sending websocket message')
    console.log(JSON.stringify(message))

    return JSON.stringify(message)
  }

  return (
    <WebsocketContext.Provider
      value={{
        ws,
        wsOpen,
        resetWs,
        disconnectWs,
        lastWsError,
        lastWsMessage,
        wsMessage,
        exchangeWsMessage,
        orderBookWsMessage,
        editOrderWsMessage,
        closeOrderWsMessage,
        createTradeWsMessage,
        orderWsMessage,
        currentMessageId,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  )
}

export default WebsocketProvider

export const useWebsocket = () => {
  return useContext(WebsocketContext)
}
