import { Grid, Table, Button, Form } from 'semantic-ui-react'
import { useState } from 'react'
import MickeyTable from '../MickeyTable/MickeyTable'
import InventoryTable from '../../views/supplier/Inventory/InventoryTable'
import { Trash } from 'phosphor-react'
import env from '../../env'
import useAxios from '../../lib/auth/useAxios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import AutoDropdown from '../AutoDropdown/AutoDropdown'
import { useFormik } from 'formik'
import * as Yup from 'yup'

function DealForm(props) {
  const { supplier } = props
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const axios = useAxios()

  const onAddInventory = (inventoryItem) => {
    formik.setFieldValue('inventory', [
      ...formik.values.inventory,
      {
        product: inventoryItem.product,
        uuid: inventoryItem.uuid,
        attributeSelections: inventoryItem.attribute_selections,
      },
    ])
  }

  const formik = useFormik({
    initialValues: {
      availableOnMobile: true,
      inventory: [],
      pickupAddressId: null,
      availability: 'One Time',
    },
    onSubmit: async (values) => {
      setLoading(true)
      const resp = await axios.post('/main/orders/create_supplier_order/', {
        supplier_id: supplier.id,
        pickup_location_id: values.pickupAddressId,
        available_on_mobile: values.availableOnMobile,
        products: values.inventory.map((i) => {
          return {
            product_id: i.product.id,
            price: i.price,
            unit_id: i.unitId,
            quantity: i.quantity,
            attribute_selection_ids: i.attributeSelections.map(
              (attr) => attr.attribute_selection_id
            ),
          }
        }),
      })
      toast.success('Deal created')
      navigate(`/portal/admin/deals/${resp.data.string_token}/`)
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      inventory: Yup.array().of(
        Yup.object().shape({
          product: Yup.object(),
          uuid: Yup.string(),
          quantity: Yup.number().required('required'),
          attributeSelections: Yup.array().of(Yup.object()),
          unitId: Yup.number().required('required'),
          price: Yup.number().required('required'),
        })
      ),
      availableOnMobile: Yup.boolean(),
      pickupAddressId: Yup.number().required('required'),
      availability: Yup.string().required('required'),
    }),
  })
  console.log(formik)
  return (
    <div style={{ marginTop: '20px' }}>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <AutoDropdown
              dontUpdate
              optionsParams={{
                supplier_id: supplier.id,
              }}
              optionsUrl={'/main/locations/'}
              optionTitleField={'name'}
              serializer={'LocationSerializer'}
              header={'Select Location'}
              titleFields={['name']}
              style={{
                width: '95px',
              }}
              onChange={(e, d) => {
                console.log(d.value)
                formik.setFieldValue(`pickupAddressId`, d.value)
              }}
            />
            {!formik.values.pickupAddressId &&
              formik.errors.pickupAddressId && (
                <p style={{ color: 'red' }}>required</p>
              )}
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ marginTop: '40px' }}></div>
            <Form.Field
              control="input"
              type="checkbox"
              label="Available on Mobile"
              name="availableOnMobile"
              value={formik.values.availableOnMobile}
              checked={formik.values.availableOnMobile}
              onChange={(e) =>
                formik.setFieldValue(
                  'availableOnMobile',
                  !formik.values.availableOnMobile
                )
              }
            />
          </Grid.Column>
        </Grid.Row>
        <div style={{ marginTop: '20px' }} />
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <p>Select Products</p>
            <InventoryTable
              supplierOrder
              dealForm
              formik={formik}
              showAddToOrderButton
              onAddButton={onAddInventory}
              disableSubmitLoading
            />
          </Grid.Column>
        </Grid.Row>
        {formik.values.inventory.length > 0 && (
          <>
            <Grid.Row className={'no-top-margin no-top-padding'}>
              <Grid.Column width={16}>
                <h3>Selected Products</h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className={'no-top-margin no-top-padding'}>
              <Grid.Column width={16}>
                <MickeyTable
                  headerRow={[
                    'Product',
                    'Attributes',
                    'Unit',
                    'Quantity',
                    'Price',
                    'Remove',
                  ]}
                  data={formik.values.inventory}
                  renderBodyRow={(data, i) => {
                    return (
                      <Table.Row>
                        <Table.Cell width={3}>
                          <p>
                            {data.product.material.name} (
                            {data.product.commodity_form.name})
                          </p>
                        </Table.Cell>
                        <Table.Cell width={3}>
                          {data.attributeSelections.map(
                            (attributeSelection) => {
                              return (
                                <p>{`${attributeSelection.attribute_name} - ${attributeSelection.attribute_selection_value}`}</p>
                              )
                            }
                          )}
                        </Table.Cell>
                        <Table.Cell width={3}>
                          <AutoDropdown
                            dontUpdate
                            optionsUrl={'/main/unit-of-measures/'}
                            optionTitleField={'plural_name'}
                            serializer={'OrderItemSerializer'}
                            placeholder={'Select Unit'}
                            titleFields={['plural_name']}
                            style={{
                              width: '95px',
                            }}
                            onChange={(e, d) => {
                              formik.setFieldValue(
                                `inventory[${i}].unitId`,
                                d.value
                              )
                            }}
                          />
                          {!formik.values.inventory?.[i]?.unitId &&
                            formik.errors.inventory?.[i]?.unitId && (
                              <p style={{ color: 'red' }}>required</p>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            type="number"
                            value={formik.values.inventory[i].quantity}
                            className={'auto-field'}
                            onChange={(event, data) => {
                              formik.setFieldValue(
                                `inventory[${i}].quantity`,
                                event.target.value
                              )
                            }}
                          />
                          {!formik.values.inventory?.[i]?.quantity &&
                            formik.errors.inventory?.[i]?.quantity && (
                              <p style={{ color: 'red' }}>required</p>
                            )}
                        </Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            type="number"
                            value={formik.values.inventory[i].price}
                            className={'auto-field'}
                            onChange={(event, data) => {
                              formik.setFieldValue(
                                `inventory[${i}].price`,
                                event.target.value
                              )
                            }}
                          />
                          {!formik.values.inventory?.[i]?.price &&
                            formik.errors.inventory?.[i]?.price && (
                              <p style={{ color: 'red' }}>required</p>
                            )}
                        </Table.Cell>
                        <Table.Cell width={3}>
                          <Button
                            basic
                            color={'red'}
                            onClick={() => {
                              const newInventory = [...formik.values.inventory]
                              formik.setFieldValue(
                                'inventory',
                                newInventory.filter(
                                  (item) => item.uuid !== data.uuid
                                )
                              )
                            }}
                            style={{
                              margin: '5px',
                            }}
                          >
                            <Trash size={20} weight={'bold'} />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    )
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  color="primary"
                  style={{
                    width: '20em',
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryColor': env.REACT_APP_SECONDARY_COLOR,
                  }}
                  content={'Create Deal'}
                  onClick={() => {
                    formik.handleSubmit()
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </div>
  )
}

export default DealForm
