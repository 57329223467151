import React, { useState, useEffect } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import SupplierOrdersPendingRow from './SupplierOrdersPendingRow'
import { Steps } from 'intro.js-react'
import useAxios from '../../../lib/auth/useAxios'
import { Menu, Label, Modal, Segment } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import SupplierOrdersRow from './SupplierOrdersRow'
import './SupplierOrders.css'
import { addBusiness } from '../../../features/user/userSlice'
import { useDispatch } from 'react-redux'
import env from '../../../env'

function SupplierOrders(props) {
  const { business, admin, setCurrentTab } = props
  const axios = useAxios()
  const [runTour, setRunTour] = useState(false)
  const [completedOrders, setCompletedOrders] = useState(null)
  const [reloadActiveOrders, setReloadActiveOrders] = useState(true)
  const [draftOrders, setDraftOrders] = useState([])
  const [reloadDraftOrders, setReloadDraftOrders] = useState(true)
  const supplier = props.supplier
  const [openAddOrderModal, setOpenAddOrderModal] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  let orderHeaderRow = ['Order Number', 'PO Number', 'Order Date', 'Products']
  let pendingHeaderRow = [
    'Order Number',
    'Order Date',
    'Estimated Pickup Date',
    'Items',
    'Quantity',
    'Price',
    'Total Price',
    'Actions',
  ]
  let completedHeaderRow = ['Order Number', 'Order Date', 'Actions']
  const [activeItem, setActiveItem] = useState('accepted')
  const dispatch = useDispatch()

  if (location.pathname === '/') {
    navigate(`portal/supplier/orders`)
  }

  const tourSteps = [
    {
      element: '.step-1',
      title: 'Welcome to the Orders Page',
      intro:
        'The Orders Page is where you view, manage, and track orders. You can sort through orders based on their status and where the orders came from via the different order tabs.',
    },
    {
      element: '.step-4',
      title: 'Active Deals',
      intro:
        'The Active Deals tab displays orders that have been matched but have not yet been completed.',
    },
    {
      element: '.step-5',
      title: 'Completed Deals',
      intro:
        'The Completed Deals tab displays orders that have been completed.',
    },
    {
      element: '.step-7',
      title: 'Orders Table',
      intro:
        'The Orders Table displays orders based on which tab you have selected. Clicking on an order will take you to the order page where you can manage all aspects of a specific order.',
    },
  ]

  const updateFinishedPageTour = async () => {
    if (!props.admin) {
      let businessCopy = { ...business }
      businessCopy.completed_supplier_order_tour = true
      dispatch(addBusiness(businessCopy))
    }
    await axios.patch(`/main/businesses/${business?.id}/`, {
      completed_supplier_order_tour: true,
      business_id: business?.id,
    })
    setRunTour(false)
  }
  function handleAddOrderForm(status, data) {
    if (status === 'success') {
      setOpenAddOrderModal(false)
      props.setReloadOrders(true)
      navigate(`/portal/supplier/orders/${data?.string_token}/draft/`)
    }
  }

  useEffect(() => {
    if (business?.id) {
      setRunTour(!admin && !business?.completed_supplier_order_tour)
    }
  }, [business?.id, business?.completed_supplier_order_tour, admin])

  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab('orders')
    }
  }, [reloadActiveOrders, setCurrentTab])

  return (
    <div
      style={{ padding: props.admin ? '' : '1rem', margin: '0' }}
      id="supplier-orders-container"
    >
      {/* {runTour && (
        <Steps
          enabled={runTour}
          steps={tourSteps}
          initialStep={0}
          onExit={() => {}}
          onComplete={() => updateFinishedPageTour()}
          options={{
            exitOnEsc: false,
            exitOnOverlayClick: false,
            showStepNumbers: true,
            showBullets: false,
            showProgress: true,
          }}
        />
      )} */}
      <Segment className={props.admin ? '' : 'main-segment-container step-7'}>
        <PageHeader icon={'file'} header={'Orders'} />
        <Menu secondary className={'header'}>
          <Menu.Item
            name="new"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
            onClick={() => {
              setActiveItem('new')
            }}
            active={activeItem === 'new'}
          >
            New Deals
          </Menu.Item>
          <Menu.Item
            name="accepted"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
            onClick={() => {
              setActiveItem('accepted')
            }}
            active={activeItem === 'accepted'}
          >
            Active Deals
          </Menu.Item>
          <Menu.Item
            name="completed"
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
            onClick={() => {
              setActiveItem('completed')
            }}
            active={activeItem === 'completed'}
          >
            Completed Deals
            {completedOrders?.length > 0 && (
              <Label color="green" floating>
                {completedOrders?.length}
              </Label>
            )}
          </Menu.Item>
        </Menu>
        {activeItem === 'supplier-orders' && (
          <div>
            <MickeyTable
              headerRow={orderHeaderRow}
              basicSegment={true}
              setParentRow={setDraftOrders}
              loaded={!reloadDraftOrders}
              data={draftOrders}
              reload={reloadDraftOrders}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrdersRow
                    order={data}
                    index={index}
                    orderStatus="draft"
                    supplier={props.supplier}
                    setReload={(reload) => {
                      setReloadDraftOrders(reload)
                      setReloadActiveOrders(reload)
                    }}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={
                'No draft orders yet! Draft orders are orders you create and have not been paid for or shipped.'
              }
            />
          </div>
        )}
        {activeItem === 'pending' && (
          <div>
            <MickeyTable
              headerRow={pendingHeaderRow}
              basicSegment={true}
              data={props.sads}
              reload={props.reloadSads}
              loaded={props.sadsLoaded}
              setReload={props.setReloadSads}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrdersPendingRow
                    sad={data}
                    orderItem={data?.quote?.order_item}
                    index={index}
                    supplier={props.supplier}
                    setReload={props.setReloadSads}
                    setAcceptedReload={props.setReload}
                    buyer={props.buyer}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={
                'No marketplace orders yet! Marketplace orders are orders originated by Mickey on our marketplace and sent to you.'
              }
            />
          </div>
        )}
        {activeItem === 'new' && (
          <div>
            <MickeyTable
              style={{ marginTop: '20px' }}
              headerRow={orderHeaderRow}
              url={'/main/orders/'}
              urlParams={{
                supplier_id: supplier?.id,
                active: true,
                delivered: false,
                scrapgo_status: 'New Deal',
                tenant_aware: false,
                supplier_accepted: true,
                page_size: 10,
              }}
              basicSegment={true}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrdersRow
                    order={data}
                    index={index}
                    orderStatus="active"
                    supplier={props.supplier}
                    setReload={(reload) => {
                      setReloadDraftOrders(reload)
                      setReloadActiveOrders(reload)
                    }}
                    buyer={props.buyer}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'No Active Deals'}
            />
          </div>
        )}
        {activeItem === 'accepted' && (
          <div>
            <MickeyTable
              style={{ marginTop: '20px' }}
              headerRow={orderHeaderRow}
              // data={activeOrders}
              url={'/main/orders/'}
              urlParams={{
                supplier_id: supplier?.id,
                active: true,
                delivered: false,
                scrapgo_status: 'Active Deal',
                tenant_aware: false,
                supplier_accepted: true,
                page_size: 10,
              }}
              basicSegment={true}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrdersRow
                    order={data}
                    index={index}
                    orderStatus="active"
                    supplier={props.supplier}
                    setReload={(reload) => {
                      setReloadDraftOrders(reload)
                      setReloadActiveOrders(reload)
                    }}
                    buyer={props.buyer}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'No Active Deals'}
            />
          </div>
        )}
        {activeItem === 'completed' && (
          <div>
            <MickeyTable
              style={{ marginTop: '20px', marginBottom: '10px' }}
              headerRow={orderHeaderRow}
              url={'/main/orders/'}
              basicSegment
              setParentRow={setCompletedOrders}
              urlParams={{
                supplier_id: supplier?.id,
                active: true,
                scrapgo_status: 'Completed Deal',
                tenant_aware: false,
                page_size: 10,
              }}
              renderBodyRow={(data, index) => {
                return (
                  <SupplierOrdersRow
                    order={data}
                    index={index}
                    orderStatus="active"
                    supplier={props.supplier}
                    buyer={props.buyer}
                    key={data ? data.id : null}
                  />
                )
              }}
              emptyIcon={'file'}
              emptyMessage={'No Completed Deals'}
            />
          </div>
        )}
      </Segment>
      <Modal
        onClose={() => setOpenAddOrderModal(false)}
        onOpen={() => setOpenAddOrderModal(true)}
        open={openAddOrderModal}
      >
        <Modal.Header>Add Order</Modal.Header>
        <Modal.Content>
          <p>
            If you don't see the buyer or delivery adress you're looking for,
            add them{' '}
            <Link to="/portal/supplier/contacts">in the contacts section</Link>.
          </p>
          <MickeyForm
            formName={'SupplierOrderForm'}
            model={'Order'}
            successMessage={'Added order!'}
            failureMessage={'Error adding order, please try again.'}
            values={{
              supplier_id: supplier?.id,
              active: false,
              tenant_aware: false,
              draft_order: true,
            }}
            buttonLabel={'Add Order'}
            buttonFloatedRight={true}
            url={'/main/orders/'}
            handleForm={handleAddOrderForm}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default SupplierOrders
