import React, { useState, useEffect } from 'react'
import { Grid, Modal } from 'semantic-ui-react'
import useAxios from '../../../lib/auth/useAxios'
import './AdminDashboard.css'
import MickeyForm from '../../../components/Forms/MickeyForm'
import env from '../../../env'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../components/PageHeader/PageHeader'
import AdminManageLocationsRow from './AdminManageLocationsRow'
import { hasPermission, inGroup } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import { Plus } from 'phosphor-react'

function AdminManageLocations(props) {
  const { openAddLocationModal, setOpenAddLocationModal } = props
  const axios = useAxios()
  const reduxUser = useSelector((state) => state.user.user)
  const [tableLoaded, setTableLoaded] = useState(true)
  const [locations, setLocations] = useState([])
  const [reload, setReload] = useState(true)

  useEffect(() => {
    let didCancel = false
    const getAdminLocations = async () => {
      if (reload) {
        setTableLoaded(false)
        let res = await axios.get('main/admin-locations/', {
          params: {
            is_god: true,
          },
        })
        if (!didCancel) {
          if (res && res.data) {
            setLocations(res.data.results)
            setTableLoaded(true)
          }
        }
        setReload(false)
      }
    }
    getAdminLocations()
    return () => {
      didCancel = true
    }
  }, [reload])

  return (
    <div className={'main-container'} id="admin-dashboard">
      <Grid stackable>
        <Grid.Row className={'no-top-margin no-top-padding'}>
          <Grid.Column width={16}>
            <MickeyTable
              headerRow={[
                'Name',
                'Address',
                'Type',
                'Contact Name',
                'Contact Email',
                'Contact Phone',
                'Actions',
              ]}
              basicSegment
              data={locations}
              loaded={tableLoaded}
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <AdminManageLocationsRow
                    location={data}
                    index={index}
                    setReload={setReload}
                    onClick={() => {}}
                    key={data ? data.id : null}
                  />
                )
              }}
              style={{ padding: '15px' }}
              emptyIcon={'building'}
              emptyMessage={'Admin locations will populate here'}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Modal
        onClose={() => {
          setOpenAddLocationModal(false)
          setReload(true)
        }}
        onOpen={() => setOpenAddLocationModal(true)}
        open={openAddLocationModal}
      >
        <Modal.Header>Add Loaction</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'LocationForm'}
            model={'Location'}
            url={'/main/admin-locations/'}
            tenantAware={false}
            urlParams={{
              business_id: props.business?.id,
              is_god: true,
            }}
            successMessage={'Admin location added.'}
            handleForm={() => {
              setReload(true)
              setOpenAddLocationModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default AdminManageLocations
