export const OrderItemDiscountForm = {
  model: 'OrderItem',
  format: [
    {
      widths: 'equal',
      fields: {
        discount: {
          required: true,
          type: 'number',
          min: '0',
          step: '0.01',
        },
      },
    },
  ],
}
