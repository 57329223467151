export const OrderItemForm = {
  model: 'OrderItem',
  format: [
    {
      widths: 'equal',
      fields: {
        product_variant: {
          required: true,
          type: 'dropdown search',
          url: '/main/product-variants/',
          title_fields: ['product__name'],
          description_fields: ['attribute_selections_array'],
        },
      },
    },
  ],
}
