import React, { useState, useEffect } from 'react'
import {
  Header,
  Button,
  Accordion,
  Divider,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react'
import useAxios from '../../../../../lib/auth/useAxios'
import './ProductFilter.css'
import env from '../../../../../env'
import { X } from 'phosphor-react'
import CustomFilter from './CustomFilter/CustomFilter'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { MagnifyingGlass, CaretRight } from 'phosphor-react'

const ProductFilter = (props) => {
  const [loading, setLoading] = useState(true)

  const axios = useAxios()
  const attributes = props.attributes
  const setAttributes = props.setAttributes
  const selectedCommodity = props.selectedCommodity
  const filter = props.filter
  const setFilter = props.setFilter
  const activeAttributeIndex = props.activeAttributeIndex
  const setActiveAttributeIndex = props.setActiveAttributeIndex
  const setFilterSelections = props.setFilterSelections
  const filterSelections = props.filterSelections
  const materialFilter = props.materialFilter
  const setMaterialFilter = props.setMaterialFilter
  const businessFilter = props.businessFilter
  const setBusinessFilter = props.setBusinessFilter
  const sideFilter = props.sideFilter
  const setSideFilter = props.setSideFilter
  const usersFilter = props.usersFilter
  const setUsersFilter = props.setUsersFilter
  const locationsFilter = props.locationsFilter
  const setLocationsFilter = props.setLocationsFilter
  const timeframeFilter = props.timeframeFilter
  const setTimeframeFilter = props.setTimeframeFilter
  const quantity = props.quantity
  const setQuantity = props.setQuantity
  const price = props.price
  const setPrice = props.setPrice
  const setExpDate = props.setExpDate
  const truckingTypeFilter = props.truckingTypeFilter
  const setTruckingTypeFilter = props.setTruckingTypeFilter
  const [users, setUsers] = useState([])
  const [sides, setSides] = useState([])
  // const [statusChoices, setStatusChoices] = useState([]);
  const [timeframeChoices, setTimeframeChoices] = useState([])
  const [truckingTypesChoices, setTruckingTypesChoices] = useState([])
  const [locations, setLocations] = useState([])
  const [materials, setMaterials] = useState(null)
  const [businesses, setBusinesses] = useState(null)
  const [customFilterSelections, setCustomFilterSelections] = useState([])
  const [customFilterValues, setCustomFilterValues] = useState([])
  const [loadingCustomAttributes, setLoadingCustomAttributes] = useState(false)
  const [openSearchBar, setOpenSearchBar] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredBusinesses, setFilteredBusinesses] = useState([])
  const [filteredMaterials, setFilteredMaterials] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filteredSide, setFilteredSide] = useState([])
  const [filteredLocations, setFilteredLocations] = useState([])
  const [filteredTimeframe, setFilteredTimeframe] = useState([])
  const [filteredTruckingTypes, setFilteredTruckingTypes] = useState([])

  function updateFilteredStates() {
    const filtered_businesses = searchText
      ? businesses?.filter((business) =>
          business?.name?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : businesses
    const filtered_materials = searchText
      ? materials?.filter((material) =>
          material?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : materials
    const filtered_users = searchText
      ? users?.filter(
          (user) =>
            user?.first_name +
            user?.last_name?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : users
    const filtered_side = searchText
      ? sides?.filter((side) =>
          side?.value?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : sides
    const filtered_locations = searchText
      ? locations?.filter((location) =>
          location?.address_1
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase())
        )
      : locations
    const filtered_timeframe = searchText
      ? timeframeChoices?.filter((f_timeframe) =>
          f_timeframe?.text?.toLowerCase()?.includes(searchText?.toLowerCase())
        )
      : timeframeChoices
    const filtered_truckingType = searchText
      ? truckingTypesChoices?.filter((f_truckingType) =>
          f_truckingType?.singular_name
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase())
        )
      : truckingTypesChoices

    setFilteredBusinesses(filtered_businesses)
    setFilteredMaterials(filtered_materials)
    setFilteredUsers(filtered_users)
    setFilteredSide(filtered_side)
    setFilteredLocations(filtered_locations)
    setFilteredTimeframe(filtered_timeframe)
    setFilteredTruckingTypes(filtered_truckingType)
  }

  useEffect(() => {
    updateFilteredStates()
  }, [searchText])

  useEffect(() => {
    if (!openSearchBar) {
      setSearchText('')
    }
  }, [openSearchBar])

  useEffect(() => {
    if (props.setCustomFilterLoading) {
      props.setCustomFilterLoading(!loadingCustomAttributes)
    }
  }, [loadingCustomAttributes])

  useEffect(() => {
    const getData = async () => {
      if (props.extra_filters && !loading) {
        let params =
          props.watchlist ||
          props.yourBids ||
          props.selectedCommodityForm?.id === 0
            ? {
                show_all_filters: true,
              }
            : {
                commodity_id: props.selectedCommodity?.id,
                commodity_form_id: props.selectedCommodityForm?.id,
              }
        let res = await axios.get(`/main/product-filter/`, {
          params: {
            ...params,
            live_ask_bid_admin_business: true,
            business_ids: businessFilter,
          },
        })
        if (res?.data?.users) {
          setUsers(res?.data?.users)
        }
        if (res?.data?.locations) {
          setLocations(res?.data?.locations)
        }
      }
    }
    getData()
  }, [businessFilter, loading])

  useEffect(() => {
    const getAttributes = async () => {
      setLoading(true)
      let params =
        props.watchlist || props.yourBids
          ? {
              show_all_filters: true,
              commodity_id: props.selectedCommodity?.id,
            }
          : {
              commodity_id: props.selectedCommodity?.id,
              commodity_form_id: props.selectedCommodityForm?.id
                ? props.selectedCommodityForm?.id
                : null,
            }
      if (props.showAdminBusiness && !props.extra_filters) {
        params = { ...params, order_book_admin_business: true }
      }
      if (props.extra_filters) {
        params = { ...params, live_ask_bid_admin_business: true }
      }
      let res = await axios.get(`/main/product-filter/`, {
        params: { ...params },
      })
      if (res?.data?.attribute_selections?.length > 0) {
        setAttributes(res.data.attribute_selections)
      }
      if (res?.data?.material?.length > 0) {
        setMaterials(res.data.material)
      }
      if (
        (props.extra_filters || props.showAdminBusiness) &&
        res?.data?.admin_business?.length > 0
      ) {
        setBusinesses(res.data.admin_business)
      }
      setLoading(false)
    }
    if (
      (props.selectedCommodity && props.selectedCommodityForm) ||
      props.watchlist ||
      props.yourBids
    ) {
      getAttributes()
    }

    const getChoices = async (model, state, field) => {
      let res = await axios.get(
        `/main/choices/?model=${model}&field=${field}`,
        {
          params: {
            tenant_aware: false,
          },
        }
      )
      state(res?.data?.choices)
    }

    const getTruckingTypes = async () => {
      let res = await axios.get(`/main/cargo-units/`, {
        params: {
          tenant_aware: false,
        },
      })
      if (res.data.results) {
        setTruckingTypesChoices(res.data.results)
      }
    }

    getAttributes()

    if (setTimeframeFilter) {
      getChoices('ExchangeOrder', setTimeframeChoices, 'timeframe')
    }

    if (setTruckingTypeFilter) {
      getTruckingTypes()
    }

    if (props.extra_filters) {
      // getChoices('ExchangeOrder',setStatusChoices,'status')
      getChoices('ExchangeOrder', setSides, 'side')
    }
  }, [
    selectedCommodity,
    props.selectedCommodityForm,
    props.watchlist,
    props.yourBids,
  ])

  const handleFilterSelection = (attributeName, attributeSelection) => {
    if (attributeName === 'custom') {
      const customFilter = attributeSelection
      if (filter?.length < 1 && attributeSelection?.length > 0) {
        let customFilterSelection = customFilterSelections?.find(
          (selection) =>
            selection?.commodityForm === attributeSelection[0].commodityForm
        )
        if (customFilterSelection?.values?.length) {
          let attribute = {
            name: attributeSelection[0]?.values[0]?.attributeName,
            values: attributeSelection[0]?.values[0]?.customFilterValues.map(
              (customFilterValue) => {
                return customFilterValue?.value
              }
            ),
          }
          setFilter([...filter, attribute])
        }
      }

      if (filter.length > 0) {
        let filterCopy = [...filter]
        let newFilterValues = []

        customFilter?.forEach((filterItem) => {
          filterItem?.values?.forEach((value) => {
            let existingAttributeName = filterCopy?.filter(
              (item) => value.attributeName === item.name
            )
            let existingIndex = filterCopy?.indexOf(existingAttributeName[0])

            filterCopy[existingIndex]?.values.forEach((val) => {
              if (filterSelections?.includes(val)) {
                newFilterValues?.push(val)
              }
            })
            if (
              filterCopy &&
              existingIndex > -1 &&
              filterCopy?.length > existingIndex
            ) {
              filterCopy[existingIndex].values = [
                ...newFilterValues,
                ...value.customFilterValues?.map((customValue) => {
                  return customValue?.value
                }),
              ]
            }
          })
        })
        setFilter(filterCopy)
      }
      let newFilterSelections = []
      customFilter?.forEach((filterItem) => {
        filterItem?.values?.forEach((value) => {
          value?.filterSelectionDisplay?.forEach((display) => {
            if (!filterSelections?.includes(display?.value)) {
              newFilterSelections?.push(display?.value)
            }
          })
          if (newFilterSelections?.length) {
            setFilterSelections([...filterSelections, ...newFilterSelections])
          }
        })
      })
    } else {
      if (
        !filterSelections.includes(
          attributeSelection.value ? attributeSelection.value : 'Unspecified'
        )
      ) {
        setFilterSelections((filterSelections) => [
          ...filterSelections,
          attributeSelection.value ? attributeSelection.value : 'Unspecified',
        ])
      } else {
        const newFilterSelections = filterSelections?.filter(
          (attribute) =>
            attribute !==
            (attributeSelection.value
              ? attributeSelection.value
              : 'Unspecified')
        )
        setFilterSelections(newFilterSelections)
      }
      let existingAttributeName = filter.filter(
        (filterItem) => filterItem.name === attributeName
      )
      if (existingAttributeName.length) {
        let filterCopy = [...filter]
        let existingIndex = filterCopy.indexOf(existingAttributeName[0])
        if (
          !filterCopy[existingIndex]?.values.includes(attributeSelection.value)
        ) {
          filterCopy[existingIndex].values = filterCopy[
            existingIndex
          ]?.values.concat(attributeSelection.value)
        } else {
          const values = filterCopy[existingIndex]?.values.filter(
            (attribute) => attribute !== attributeSelection.value
          )
          if (values.length === 0) {
            filterCopy.splice(existingIndex, 1)
          } else {
            filterCopy[existingIndex].values = values
          }
        }

        if (!filterCopy[existingIndex]?.values?.length) {
          filterCopy = filterCopy.filter(
            (filterItem) => filterItem.name !== attributeName
          )
        }
        setFilter(filterCopy)
      } else {
        const attribute = {
          name: attributeName,
          values: [attributeSelection.value],
        }
        setFilter((filter) => [...filter, attribute])
      }
    }
  }

  const removeCustomSelectionsFromFilter = (
    currentFilter,
    commodityForm,
    attributeName
  ) => {
    let customFilterValuesCopy = [...customFilterValues]
    let existingCommodityFormValues = customFilterValuesCopy?.filter(
      (value) => value.commodityForm === commodityForm
    )
    let existingCommodityFormIndex = customFilterValuesCopy?.indexOf(
      existingCommodityFormValues[0]
    )
    let existingAttributeFilterValues = customFilterValuesCopy[
      existingCommodityFormIndex
    ]?.values.filter((value) => value.attributeName === attributeName)
    let existingAttributeFilterValuesIndex = customFilterValuesCopy[
      existingCommodityFormIndex
    ]?.values.indexOf(existingAttributeFilterValues[0])
    let customFilter = customFilterValuesCopy[
      existingCommodityFormIndex
    ]?.values[existingAttributeFilterValuesIndex]?.customFilterValues.map(
      (item) => item.value
    )
    customFilter?.forEach((customFilterItem) => {
      let customFilterValueInCurrentFilter = currentFilter?.find(
        (currentFilterItem) => currentFilterItem === customFilterItem
      )
      if (customFilterValueInCurrentFilter) {
        let index = currentFilter.indexOf(customFilterValueInCurrentFilter)
        currentFilter.splice(index, 1)
      } else {
        return
      }
    })
    return currentFilter
  }

  const handleRemoveFilterSelection = (customFilter, filterValue) => {
    let filterCopy = [...filter]
    if (customFilter) {
      let filterAttribute = filterCopy.find(
        (filterItem) => filterItem?.name === filterValue?.attributeName
      )
      let filterAttributeIndex = filterCopy.indexOf(filterAttribute)
      let newCurrentFilterValues = removeCustomSelectionsFromFilter(
        filterCopy[filterAttributeIndex]?.values,
        filterValue.commodityForm,
        filterValue.attributeName
      )
      if (filterCopy[filterAttributeIndex]?.values) {
        filterCopy[filterAttributeIndex].values = newCurrentFilterValues
      }
      if (newCurrentFilterValues?.length < 1) {
        filterCopy.splice(filterAttributeIndex, 1)
      }
      setFilter(filterCopy)
      let newSelections = filterSelections?.filter(
        (selection) => selection !== filterValue?.filterValue?.value
      )
      setFilterSelections(newSelections)
    } else {
      filterCopy?.forEach((filterItem, index) => {
        if (filterItem?.values.includes(filterValue)) {
          filterItem.values = filterItem?.values.filter(
            (value) => value !== filterValue
          )
          let newSelections = filterSelections?.filter(
            (selection) => selection !== filterValue
          )
          setFilterSelections(newSelections)
          if (filterItem?.values?.length < 1) {
            filterCopy = filterCopy.filter(
              (filterItem) => filterItem?.values?.length >= 1
            )
          }
          setFilter(filterCopy)
        } else {
        }
      })
      let removeCustomFilter = [
        "Some 8'",
        "Some 10'",
        "Some 12'",
        "Some 14'",
        "Some 16'",
        "Some 18'",
        "Some 20'",
      ].includes(filterValue)
      if (removeCustomFilter) {
        let customFilterSelectionsCopy = [...customFilterSelections]
        let filterAttributeName = 'Length'
        let existingCommodityForm
        if (props.selectedCommodity?.commodity_forms) {
          let commodityFormNameFromSelectedCommodity
          customFilterSelectionsCopy?.find((selection) => {
            commodityFormNameFromSelectedCommodity =
              props.selectedCommodity?.commodity_forms?.find(
                (commodityForm) =>
                  commodityForm?.name === selection?.commodityForm
              )
            return (
              selection?.commodityForm ===
              commodityFormNameFromSelectedCommodity?.name
            )
          })
          existingCommodityForm = customFilterSelectionsCopy?.find(
            (selection) =>
              selection?.commodityForm ===
              commodityFormNameFromSelectedCommodity?.name
          )
        } else {
          let filterCommodityFormName = props.selectedCommodityForm?.name
            ? props.selectedCommodityForm?.name
            : 'Lumber'
          existingCommodityForm = customFilterSelectionsCopy?.find(
            (selection) => selection?.commodityForm === filterCommodityFormName
          )
        }
        let commodityFormIndex = customFilterSelectionsCopy?.indexOf(
          existingCommodityForm
        )
        let existingAttribute = customFilterSelectionsCopy[
          commodityFormIndex
        ]?.values?.find(
          (attribute) => attribute?.attributeName === filterAttributeName
        )
        let existingAttributeIndex =
          customFilterSelectionsCopy[commodityFormIndex]?.values?.indexOf(
            existingAttribute
          )
        let displayValue = customFilterSelectionsCopy[
          commodityFormIndex
        ]?.values[existingAttributeIndex]?.filterSelectionDisplay?.find(
          (display) => display?.value === filterValue
        )
        let displayIndex =
          customFilterSelectionsCopy[commodityFormIndex]?.values[
            existingAttributeIndex
          ]?.filterSelectionDisplay?.indexOf(displayValue)
        if (
          customFilterSelectionsCopy[commodityFormIndex]?.values[
            existingAttributeIndex
          ]?.filterSelectionDisplay[displayIndex]?.value === filterValue
        ) {
          let existingCustomValueIndex = customFilterSelectionsCopy[
            commodityFormIndex
          ]?.values[existingAttributeIndex]?.customFilterValues?.indexOf(
            customFilterSelectionsCopy[commodityFormIndex]?.values[
              existingAttributeIndex
            ]?.filterSelectionDisplay[displayIndex]?.customValueInput
          )
          let filterAttribute = filterCopy.find(
            (filterItem) => filterItem?.name === filterAttributeName
          )
          if (filterAttribute) {
            let filterAttributeIndex = filterCopy.indexOf(filterAttribute)
            let newCurrentFilterValues = removeCustomSelectionsFromFilter(
              filterCopy[filterAttributeIndex]?.values,
              existingCommodityForm?.commodityForm,
              existingAttribute?.attributeName
            )
            if (filterCopy[filterAttributeIndex]?.values) {
              filterCopy[filterAttributeIndex].values = newCurrentFilterValues
            }
            if (newCurrentFilterValues?.length < 1) {
              filterCopy.splice(filterAttributeIndex, 1)
            }
            setFilter(filterCopy)
          }
          customFilterSelectionsCopy[commodityFormIndex]?.values[
            existingAttributeIndex
          ]?.customFilterValues?.splice(existingCustomValueIndex, 1)
          customFilterSelectionsCopy[commodityFormIndex]?.values[
            existingAttributeIndex
          ]?.filterSelectionDisplay?.splice(displayIndex, 1)
          if (
            customFilterSelectionsCopy[commodityFormIndex]?.values[
              existingAttributeIndex
            ]?.customFilterValues?.length < 1
          ) {
            customFilterSelectionsCopy[commodityFormIndex]?.values?.splice(
              existingAttributeIndex
            )
            if (
              customFilterSelectionsCopy[commodityFormIndex]?.values?.length < 1
            ) {
              customFilterSelectionsCopy.splice(commodityFormIndex)
            }
          }
          setCustomFilterSelections(customFilterSelectionsCopy)
        }
      }
      let newSelections = filterSelections?.filter(
        (selection) => selection !== filterValue
      )
      setFilterSelections(newSelections)
      let newmaterialFilter = materialFilter?.filter(
        (filterItem) => filterItem !== filterValue
      )
      setMaterialFilter(newmaterialFilter)
      if (setTimeframeFilter) {
        setTimeframeFilter(
          timeframeFilter?.filter((timeframe) => timeframe !== filterValue)
        )
      }
      if (setTruckingTypeFilter) {
        let existingTruckingTypes = truckingTypesChoices?.filter(
          (truckingType) => truckingType?.singular_name === filterValue
        )
        let newTruckingTypes = truckingTypeFilter
        existingTruckingTypes?.forEach((existingTruckingType) => {
          newTruckingTypes = newTruckingTypes?.filter(
            (truckingType_id) => truckingType_id !== existingTruckingType?.id
          )
        })
        setTruckingTypeFilter(newTruckingTypes)
      }
      if (props.showAdminBusiness) {
        let existingBusiness = businesses?.filter(
          (business) => business?.name === filterValue
        )
        let newbusinessFilter = businessFilter
        existingBusiness?.forEach((existingBusiness) => {
          newbusinessFilter = newbusinessFilter?.filter(
            (business_id) => business_id !== existingBusiness.id
          )
        })
        setBusinessFilter(newbusinessFilter)
      }
    }
    if (props?.extra_filters && !customFilter) {
      let existingUsers = users?.filter(
        (user) => `${user?.first_name} ${user?.last_name}` === filterValue
      )
      let newUserFilter = usersFilter
      existingUsers.forEach((existingUser) => {
        newUserFilter = newUserFilter?.filter(
          (user_id) => user_id !== existingUser.id
        )
      })
      setUsersFilter(newUserFilter)
      setSideFilter(sideFilter?.filter((side) => side !== filterValue))
      setLocationsFilter(
        locationsFilter?.filter((loc_id) => loc_id !== filterValue)
      )
      if (new Date(filterValue)?.getTime()) {
        setExpDate(null)
      }
      if (price?.toLowerCase()?.includes(filterValue?.toLowerCase())) {
        setPrice(null)
      }
      if (
        !filterValue?.includes('$') &&
        quantity?.toLowerCase()?.includes(filterValue?.toLowerCase())
      ) {
        setQuantity(null)
      }
    }
  }

  const handleOpenFilter = (itemIndex) => {
    if (itemIndex === activeAttributeIndex) {
      setActiveAttributeIndex(null)
    } else {
      setActiveAttributeIndex(itemIndex)
    }
  }

  const handleMaterialFilter = (material) => {
    setMaterialFilter(
      materialFilter.includes(material)
        ? materialFilter?.filter(
            (existing_material) => existing_material !== material
          )
        : [...materialFilter, material]
    )
    setFilterSelections(
      filterSelections.includes(material)
        ? filterSelections?.filter(
            (existing_material) => existing_material !== material
          )
        : [...filterSelections, material]
    )
  }

  const handleExtraFilters = (
    filter_key,
    state,
    variable,
    filter_value = null
  ) => {
    state(
      variable.includes(filter_key)
        ? variable?.filter((existing_key) => existing_key !== filter_key)
        : [...variable, filter_key]
    )

    if (filter_value) {
      setFilterSelections(
        filterSelections.includes(filter_value)
          ? filterSelections?.filter(
              (existing_filter_name) => existing_filter_name !== filter_value
            )
          : [...filterSelections, filter_value]
      )
    } else {
      setFilterSelections(
        filterSelections?.includes(filter_key)
          ? filterSelections?.filter(
              (existing_filter_name) => existing_filter_name !== filter_key
            )
          : [...filterSelections, filter_key]
      )
    }
  }

  const handleBusinessFilter = (business) => {
    setBusinessFilter(
      businessFilter.includes(business.id)
        ? businessFilter?.filter(
            (existing_business_id) => existing_business_id !== business.id
          )
        : [...businessFilter, business.id]
    )
    setFilterSelections(
      filterSelections.includes(business.name)
        ? filterSelections?.filter(
            (existing_business_name) => existing_business_name !== business.name
          )
        : [...filterSelections, business.name]
    )
  }

  useEffect(() => {
    if (openSearchBar) {
      setOpenSearchBar(false)
    }
  }, [filterSelections?.length])

  return (
    <div id="product-filter">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        <Header
          as="h4"
          style={{ margin: '0', display: 'flex', alignItems: 'center' }}
        >
          <span>Select Filters</span>
          <Button
            id="search-filter-button"
            color="grey"
            size={'mini'}
            basic={!openSearchBar}
            onClick={() => setOpenSearchBar(!openSearchBar)}
          >
            <MagnifyingGlass size={20} color="#3D3D3D" />
          </Button>
        </Header>
        {filterSelections && filterSelections?.length > 0 && (
          <span
            className={'clear-filter'}
            onClick={() => {
              setCustomFilterSelections([])
              setCustomFilterValues([])
              props.setFilterSelections([])
              props.setFilter([])
              props.setMaterialFilter([])
              if (props?.extra_filters) {
                setUsersFilter([])
                setSideFilter([])
                setLocationsFilter([])
                setExpDate(null)
                setPrice(null)
                setQuantity(null)
              }
              if (setTimeframeFilter) {
                setTimeframeFilter([])
              }
              if (setTruckingTypeFilter) {
                setTruckingTypeFilter([])
              }
              if (props.showAdminBusiness || props.extra_filters) {
                props.setBusinessFilter([])
              }
            }}
          >
            Clear filters
          </span>
        )}
      </div>

      <Divider className="product-filter-divider" />
      {openSearchBar && (
        <>
          <div className="ui fluid search" style={{ padding: '.5rem 20px' }}>
            <Input
              id="mickey-search"
              type="text"
              placeholder="Search for Filters"
              autoFocus
              value={searchText}
              onChange={(e, d) => setSearchText(d.value)}
            />
          </div>
          <Divider className="product-filter-divider" />
        </>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '10px',
          padding: '0 20px',
        }}
      >
        {filterSelections?.map((selection, i) => {
          return (
            <Button
              key={i}
              size={'mini'}
              color="grey"
              className={'selected-btn'}
              style={{
                margin: '.2rem',
                padding: '0.25rem 0.55rem 0.25rem 0.1rem',
                display: 'flex',
                alignItems: 'center',
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
              }}
            >
              <span
                style={{ backgroundColor: 'transparent', padding: '.2rem' }}
                onClick={() => handleRemoveFilterSelection(false, selection)}
              >
                <X size={14} style={{ margin: '0', color: 'white' }} />
              </span>
              {selection}
            </Button>
          )
        })}
      </div>
      {filterSelections.length > 0 && <Divider />}
      {loading && (
        <Dimmer inverted active style={{ height: '40em', marginTop: '4em' }}>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      )}
      {((!openSearchBar && !searchText) || (openSearchBar && searchText)) &&
        !loading && (
          <Accordion
            id="filter-attribute-selections"
            style={{
              marginTop: '0px',
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            }}
          >
            {!loading &&
              timeframeChoices.length > 0 &&
              (filteredTimeframe?.length > 0 || !searchText) && (
                <>
                  <div style={{ padding: '0 20px' }}>
                    <Accordion.Title
                      active={
                        activeAttributeIndex === 0 || searchText?.length > 0
                      }
                      index={0}
                      onClick={() => handleOpenFilter(0)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Header as="h5" style={{ margin: '0' }}>
                        {'Timeframe'}
                      </Header>
                      {!openSearchBar && (
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              activeAttributeIndex === 0 ? 'rotate(90deg)' : '',
                          }}
                        />
                      )}
                    </Accordion.Title>
                    <Accordion.Content
                      active={
                        activeAttributeIndex === 0 || searchText?.length > 0
                      }
                      className={
                        activeAttributeIndex !== 0 ||
                        activeAttributeIndex === null
                          ? 'inactive'
                          : ''
                      }
                    >
                      <>
                        {timeframeChoices?.map((choice, index) => {
                          return !openSearchBar ||
                            (filteredTimeframe?.length > 0 &&
                              filteredTimeframe?.find(
                                (f_timeframe) =>
                                  f_timeframe?.text === choice?.text
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!timeframeFilter.includes(choice.text)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() =>
                                handleExtraFilters(
                                  choice.text,
                                  setTimeframeFilter,
                                  timeframeFilter
                                )
                              }
                            >
                              {choice.text}
                            </Button>
                          ) : null
                        })}
                      </>
                    </Accordion.Content>
                  </div>
                  <Divider className="product-filter-divider" />
                </>
              )}
            {!loading &&
              truckingTypesChoices.length > 0 &&
              (filteredTruckingTypes?.length > 0 || !searchText) && (
                <>
                  <div style={{ padding: '0 20px' }}>
                    <Accordion.Title
                      active={
                        activeAttributeIndex === 1 || searchText?.length > 0
                      }
                      index={1}
                      onClick={() => handleOpenFilter(1)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Header as="h5" style={{ margin: '0' }}>
                        {'Trucking Type'}
                      </Header>
                      {!openSearchBar && (
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              activeAttributeIndex === 1 ? 'rotate(90deg)' : '',
                          }}
                        />
                      )}
                    </Accordion.Title>
                    <Accordion.Content
                      active={
                        activeAttributeIndex === 1 || searchText?.length > 0
                      }
                      className={
                        activeAttributeIndex !== 1 ||
                        activeAttributeIndex === null
                          ? 'inactive'
                          : ''
                      }
                    >
                      <>
                        {truckingTypesChoices?.map((choice, index) => {
                          return !openSearchBar ||
                            (filteredTruckingTypes?.length > 0 &&
                              filteredTruckingTypes?.find(
                                (f_truckingType) =>
                                  f_truckingType?.singular_name ===
                                  choice?.singular_name
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!truckingTypeFilter.includes(choice.id)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() =>
                                handleExtraFilters(
                                  choice.id,
                                  setTruckingTypeFilter,
                                  truckingTypeFilter,
                                  choice.singular_name
                                )
                              }
                            >
                              {choice.singular_name}
                            </Button>
                          ) : null
                        })}
                      </>
                    </Accordion.Content>
                  </div>
                  <Divider className="product-filter-divider" />
                </>
              )}
            {!loading &&
              materials &&
              (filteredMaterials?.length > 0 || !searchText) && (
                <>
                  <div style={{ padding: '0 20px' }}>
                    <Accordion.Title
                      active={
                        activeAttributeIndex === 2 || searchText?.length > 0
                      }
                      index={2}
                      onClick={() => {
                        handleOpenFilter(2)
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Header as="h4" style={{ margin: '0' }}>
                        {'Species'}
                      </Header>
                      {!openSearchBar && (
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              activeAttributeIndex === 2 ? 'rotate(90deg)' : '',
                          }}
                        />
                      )}
                    </Accordion.Title>
                    <Accordion.Content
                      active={
                        activeAttributeIndex === 2 || searchText?.length > 0
                      }
                      className={
                        activeAttributeIndex !== 2 ||
                        activeAttributeIndex === null
                          ? 'inactive'
                          : ''
                      }
                    >
                      {materials &&
                        materials?.map((material, index) => {
                          return !openSearchBar ||
                            (filteredMaterials?.length > 0 &&
                              filteredMaterials?.find(
                                (f_material) => f_material === material
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!filterSelections.includes(material)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() => handleMaterialFilter(material)}
                            >
                              {material}
                            </Button>
                          ) : null
                        })}
                    </Accordion.Content>
                  </div>
                  <Divider className="product-filter-divider" />
                </>
              )}
            {!loading &&
              attributes.map((attribute, index) => {
                let filtered_recordes = attribute.attribute_selections?.filter(
                  (item) =>
                    item?.value
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase())
                )
                return filtered_recordes?.length > 0 || !searchText ? (
                  <div key={index}>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === index + 3 ||
                          searchText?.length > 0
                        }
                        index={index + 3}
                        onClick={() => handleOpenFilter(index + 3)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {attribute.name}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === index + 3
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === index + 3 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== index + 3 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        {attribute.attribute_selections.map(
                          (selection, index) => {
                            return !openSearchBar ||
                              (filtered_recordes?.length > 0 &&
                                filtered_recordes?.find(
                                  (item) => item?.value === selection?.value
                                )) ? (
                              <Button
                                key={index}
                                color="grey"
                                size={'mini'}
                                basic={
                                  !filterSelections.includes(
                                    selection.value
                                      ? selection.value
                                      : 'Unspecified'
                                  )
                                }
                                style={{
                                  borderRadius: '3px',
                                  margin: '.25rem',
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                  '--secondaryButtonColor':
                                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                }}
                                onClick={() => {
                                  handleFilterSelection(
                                    attribute.name,
                                    selection
                                  )
                                }}
                              >
                                {selection.value
                                  ? selection.value
                                  : 'Unspecified'}
                              </Button>
                            ) : null
                          }
                        )}
                        {attribute?.custom && (
                          <>
                            <CustomFilter
                              commodityForm={props.selectedCommodityForm}
                              commodity={props.selectedCommodity}
                              attribute={attribute}
                              handleFilterSelection={handleFilterSelection}
                              handleRemoveFilterSelection={
                                handleRemoveFilterSelection
                              }
                              customFilterSelections={customFilterSelections}
                              setCustomFilterSelections={
                                setCustomFilterSelections
                              }
                              customFilterValues={customFilterValues}
                              setCustomFilterValues={setCustomFilterValues}
                              loadingCustomAttributes={loadingCustomAttributes}
                              setLoadingCustomAttributes={
                                setLoadingCustomAttributes
                              }
                            />
                          </>
                        )}
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </div>
                ) : null
              })}
            {!loading &&
              props.showAdminBusiness &&
              businesses &&
              (filteredBusinesses?.length > 0 || !searchText) && (
                <>
                  <div style={{ padding: '0 20px' }}>
                    <Accordion.Title
                      active={
                        activeAttributeIndex === attributes.length + 3 ||
                        searchText?.length > 0
                      }
                      index={attributes.length + 3}
                      onClick={() => {
                        handleOpenFilter(attributes.length + 3)
                      }}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Header as="h4" style={{ margin: '0' }}>
                        {'Business'}
                      </Header>
                      {!openSearchBar && (
                        <CaretRight
                          size={20}
                          style={{
                            transition: '.2s ease-in-out',
                            transform:
                              activeAttributeIndex === attributes.length + 3
                                ? 'rotate(90deg)'
                                : '',
                          }}
                        />
                      )}
                    </Accordion.Title>
                    <Accordion.Content
                      active={
                        activeAttributeIndex === attributes.length + 3 ||
                        searchText?.length > 0
                      }
                      className={
                        activeAttributeIndex !== attributes.length + 3 ||
                        activeAttributeIndex === null
                          ? 'inactive'
                          : ''
                      }
                    >
                      {props.showAdminBusiness &&
                        businesses &&
                        businesses?.map((business, index) => {
                          return !openSearchBar ||
                            (filteredBusinesses?.length > 0 &&
                              filteredBusinesses?.find(
                                (f_business) => f_business?.id === business?.id
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!filterSelections.includes(business.name)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() => handleBusinessFilter(business)}
                            >
                              {business.name}
                            </Button>
                          ) : null
                        })}
                    </Accordion.Content>
                  </div>
                  <Divider className="product-filter-divider" />
                </>
              )}
            {props?.extra_filters && (
              <>
                {users && (filteredUsers.length > 0 || !searchText) && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 4 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 4}
                        onClick={() => handleOpenFilter(attributes.length + 4)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Users'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 4
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 4 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 4 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        {users?.map((selection, index) => {
                          return !openSearchBar ||
                            (filteredUsers?.length > 0 &&
                              filteredUsers?.find(
                                (f_user) => f_user?.id === selection?.id
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!usersFilter.includes(selection.id)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() =>
                                handleExtraFilters(
                                  selection.id,
                                  setUsersFilter,
                                  usersFilter,
                                  selection?.first_name +
                                    ' ' +
                                    selection?.last_name
                                )
                              }
                            >
                              {`${selection.first_name} ${selection.last_name}`}
                            </Button>
                          ) : null
                        })}
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}
                {sides && (filteredSide.length > 0 || !searchText) && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 5 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 5}
                        onClick={() => {
                          handleOpenFilter(attributes.length + 5)
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Side'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 5
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 5 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 5 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        {sides?.map((selection, index) => {
                          return !openSearchBar ||
                            (filteredSide?.length > 0 &&
                              filteredSide?.find(
                                (f_side) => f_side?.value === selection?.value
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!sideFilter.includes(selection.value)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() =>
                                handleExtraFilters(
                                  selection.value,
                                  setSideFilter,
                                  sideFilter
                                )
                              }
                            >
                              {selection?.text}
                            </Button>
                          ) : null
                        })}
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}

                {businessFilter?.length > 0 && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 6 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 6}
                        onClick={() => handleOpenFilter(attributes.length + 6)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Locations'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 6
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 6 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 6 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        {locations?.map((selection, index) => {
                          return !openSearchBar ||
                            (filteredLocations.length > 0 &&
                              filteredLocations?.find(
                                (f_location) => f_location?.id === selection?.id
                              )) ? (
                            <Button
                              key={index}
                              color="grey"
                              size={'mini'}
                              basic={!locationsFilter.includes(selection.id)}
                              style={{
                                borderRadius: '3px',
                                margin: '.25rem',
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                '--secondaryButtonColor':
                                  env.REACT_APP_SECONDARY_BUTTON_COLOR,
                              }}
                              onClick={() =>
                                handleExtraFilters(
                                  selection.id,
                                  setLocationsFilter,
                                  locationsFilter,
                                  selection?.address_1
                                )
                              }
                            >
                              {selection?.address_1}
                            </Button>
                          ) : null
                        })}
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}
                {!searchText && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 7 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 7}
                        onClick={() => handleOpenFilter(attributes.length + 7)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Price'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 7
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 7 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 7 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        <p>
                          <span style={{ float: 'Right' }}>${price}</span>
                        </p>
                        <Input
                          type="range"
                          min={0}
                          max={1000000}
                          step={100}
                          value={price ? price : 0}
                          onChange={(e, d) => {
                            let val = d?.value
                            setPrice(val)
                            let exist = filterSelections?.filter(
                              (f_val) =>
                                !f_val
                                  ?.toLowerCase()
                                  .includes('$'.toLowerCase())
                            )
                            if (val > 0) {
                              setFilterSelections([...exist, `$ ${val}`])
                            } else {
                              setFilterSelections(exist)
                            }
                          }}
                          style={{
                            width: '90%',
                            height: '20px',
                            marginLeft: '5%',
                          }}
                        />
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}
                {!searchText && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 8 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 8}
                        onClick={() => handleOpenFilter(attributes.length + 8)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Quantity'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 8
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 8 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 8 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        <p>
                          <span style={{ float: 'Right' }}>{quantity}</span>
                        </p>
                        <Input
                          type="range"
                          min={0}
                          max={10000}
                          step={100}
                          value={quantity ? quantity : 0}
                          onChange={(e, d) => {
                            let val = d?.value
                            setQuantity(val)
                            let exist = filterSelections?.filter(
                              (f_val) => !/^\d+$/.test(f_val)
                            )
                            if (val > 0) {
                              setFilterSelections([...exist, val])
                            } else {
                              setFilterSelections(exist)
                            }
                          }}
                          style={{
                            width: '90%',
                            height: '20px',
                            marginLeft: '5%',
                          }}
                        />
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}
                {!searchText && (
                  <>
                    <div style={{ padding: '0 20px' }}>
                      <Accordion.Title
                        active={
                          activeAttributeIndex === attributes.length + 9 ||
                          searchText?.length > 0
                        }
                        index={attributes.length + 9}
                        onClick={() => handleOpenFilter(attributes.length + 9)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Header as="h4" style={{ margin: '0' }}>
                          {'Expiration Date'}
                        </Header>
                        {!openSearchBar && (
                          <CaretRight
                            size={20}
                            style={{
                              transition: '.2s ease-in-out',
                              transform:
                                activeAttributeIndex === attributes.length + 9
                                  ? 'rotate(90deg)'
                                  : '',
                            }}
                          />
                        )}
                      </Accordion.Title>
                      <Accordion.Content
                        active={
                          activeAttributeIndex === attributes.length + 9 ||
                          searchText?.length > 0
                        }
                        className={
                          activeAttributeIndex !== attributes.length + 9 ||
                          activeAttributeIndex === null
                            ? 'inactive'
                            : ''
                        }
                      >
                        <SemanticDatepicker
                          onChange={(e, d) => {
                            let val = d?.value?.toLocaleDateString('en-US')
                            setExpDate(val)
                            let exist = filterSelections?.filter(
                              (f_val) => !new Date(f_val)?.getTime()
                            )
                            if (val) {
                              setFilterSelections([...exist, val])
                            } else {
                              setFilterSelections(exist)
                            }
                          }}
                          clearable={true}
                          autoComplete="nope"
                          disabled={
                            activeAttributeIndex !== attributes.length + 9
                          }
                          name={'Expiration Date'}
                          format={'MMM D, YYYY'}
                          disableAutoCorrect
                        />
                      </Accordion.Content>
                    </div>
                    <Divider className="product-filter-divider" />
                  </>
                )}
                <div style={{ padding: '0 20px' }}></div>
              </>
            )}
          </Accordion>
        )}
    </div>
  )
}

export default ProductFilter
