import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: true,
  userId: null,
}
export const darkModeSlice = createSlice({
  name: 'dark_mode',
  initialState,
  reducers: {
    reset: () => initialState,
    setDarkMode: (state, data) => {
      state.value = data.payload.dark_mode
    },
    setDarkModeUserId: (state, data) => {
      state.userId = data.payload.user_id
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDarkMode, setDarkModeUserId } = darkModeSlice.actions

export default darkModeSlice.reducer
