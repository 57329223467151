import React, { useState } from 'react'
import { Button, Label, Table } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import { formatOrderNumber } from '../../../lib/utils/utils'
import useAxios from '../../../lib/auth/useAxios'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { formatTotalPrice, formatQuantities } from '../../../lib/utils/utils'
import SupplierOrderItemTimer from './SupplierOrderItemTimer'

function SupplierOrdersPendingRow(props) {
  const { orderItem } = props
  const axios = useAxios()
  const nowTime = new Date()
  nowTime.setSeconds(nowTime.getSeconds() + props.sad?.seconds_until_end)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const navigate = useNavigate()
  const supplier = useSelector((state) => state.user.supplier)

  async function acceptOrder() {
    setAcceptLoading(true)
    let res = await axios.post('/main/supplier-accept-order/', {
      order_item_id: orderItem?.id,
      supplier_id: supplier?.id,
    })
    setAcceptLoading(false)
    if (res?.data?.success) {
      props.setReload(true)
      props.setAcceptedReload(true)
      toast.success('Order accepted!')
    } else {
      toast.error(res.data.error ? res.data.error : 'Error accepting order.')
    }
  }

  async function declineOrder() {
    setAcceptLoading(true)
    let res = await axios.post('/main/supplier-decline-order/', {
      order_item_id: orderItem?.id,
      supplier_id: supplier?.id,
    })
    setAcceptLoading(false)
    if (res.data.success) {
      props.setReload(true)
      toast.success('Order declined!')
    } else {
      toast.error(res.data.error ? res.data.error : 'Error declining order.')
    }
  }
  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {orderItem?.order &&
            formatOrderNumber(orderItem?.order?.string_token)}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {orderItem?.order?.created_at}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {props.sad
            ? props.sad?.quote?.estimated_ship_date
            : orderItem?.estimated_ship_date}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {props.sad?.quote?.supplier_location && (
            <>
              {props.sad?.quote?.supplier_location?.name}
              <br />
              {props.sad?.quote?.supplier_location?.address_1}{' '}
              {props.sad?.quote?.supplier_location?.address_2}
              <br />
              {props.sad?.quote?.supplier_location?.city},{' '}
              {props.sad?.quote?.supplier_location?.state}{' '}
              {props.sad?.quote?.supplier_location?.zip_code}
            </>
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          <Label color={'green'}>
            {props.sad?.product_variant?.product?.material?.name} (
            {props.sad?.product_variant?.product?.commodity_form?.name})
          </Label>
          {props.sad?.product_variant?.attribute_selections?.map(
            (attribute) => {
              return (
                <Label style={{ margin: '3px' }}>
                  {attribute.attribute_selection_value}
                </Label>
              )
            }
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {formatQuantities(props.orderItem?.quantity)}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {props.sad?.quote?.supplier_price}
        </Table.Cell>
        <Table.Cell
          positive
          onClick={() => {
            navigate(
              `/portal/supplier/orders/${orderItem?.order?.string_token}/pending/`
            )
          }}
        >
          {formatTotalPrice(
            props.sad?.quote?.supplier_price * props.orderItem?.quantity
          )}
        </Table.Cell>
        <Table.Cell textAlign="center" width={2}>
          <SupplierOrderItemTimer
            expiryTimestamp={nowTime}
            setReload={props.setReload}
          />
          <Button.Group style={{ marginTop: '10px' }}>
            <Button
              size={'small'}
              content={'Decline'}
              loading={acceptLoading}
              onClick={() => {
                declineOrder()
              }}
            />
            <Button
              color={'green'}
              size={'small'}
              content={'Accept'}
              loading={acceptLoading}
              onClick={() => {
                navigate(
                  `/portal/supplier/orders/${orderItem?.order?.string_token}/active/`
                )
                acceptOrder()
              }}
            />
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    </>
  )
}

export default SupplierOrdersPendingRow
