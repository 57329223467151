export const ProductListingForm = {
  model: 'ProductListing',
  format: [
    {
      widths: 'equal',
      fields: {
        product: {
          type: 'dropdown search',
          label: 'Products',
          url: '/main/products/',
          title_fields: ['material.name', 'commodity_form.name'],
          description_fields: ['commodity.name'],
          filter_field: 'product__commodity__name__icontains',
        },
      },
    },
  ],
}
