import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { formatTotalPrice } from '../../../lib/utils/utils'
import AdminOrderLedgerRow from './AdminOrderLedgerRow'
import './AdminOrder.css'

function AdminOrderPayments(props) {
  const [reload, setReload] = useState(true)
  const [orderLedgerItems, setOrderLedgerItems] = useState(
    props.order?.ledger_items
  )
  const [ledgerItemsTotal, setLedgerItemsTotal] = useState()
  const ledgerHeaderRow = [
    'Balance ID',
    'Payment Method',
    'Payment Date/Time',
    'Balance Status',
    'Business',
    'Type',
    'Amount',
  ]

  useEffect(() => {
    let lit = 0
    orderLedgerItems?.forEach((ledgerItem) => {
      if (ledgerItem.type === 'Disbursement') {
        lit -= parseFloat(ledgerItem.amount_transferred)
      }
      if (ledgerItem.type === 'Receipt') {
        lit += parseFloat(ledgerItem.amount_transferred)
      }
    })
    setLedgerItemsTotal(lit)
  }, [orderLedgerItems])

  useEffect(() => {
    setOrderLedgerItems(props.order?.ledger_items)
  }, [props.order])

  return (
    <MickeyTable
      headerRow={ledgerHeaderRow}
      style={{ marginTop: '5px', margin: '10px', textAlign: 'center' }}
      basicSegment
      data={orderLedgerItems}
      reload={reload}
      setReload={setReload}
      renderBodyRow={(data, index) => {
        return (
          <AdminOrderLedgerRow
            orderId={props.order?.id}
            ledgerItem={data}
            setReload={setReload}
            index={index}
            key={data ? data.id : null}
          />
        )
      }}
      emptyIcon={'money'}
      footerRow={
        <Table.Row>
          <Table.HeaderCell colSpan={'6'}>
            <b>Totals</b>
          </Table.HeaderCell>
          <Table.HeaderCell
            colSpan="1"
            positive={ledgerItemsTotal >= 0}
            negative={ledgerItemsTotal < 0}
          >
            <b>{formatTotalPrice(ledgerItemsTotal)}</b>
          </Table.HeaderCell>
        </Table.Row>
      }
      emptyMessage={'Ledger items will populate here'}
    />
  )
}

export default AdminOrderPayments
