import React, { useEffect, useState } from 'react'
import { Grid, Divider, Button, Header, Pagination } from 'semantic-ui-react'
import './MxNotification.css'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import env from '../../../env'
import useAxios from '../../../lib/auth/useAxios'
import MickeyPlaceholder from '../../../components/Placeholder/Placeholder'
import { useNavigate } from 'react-router-dom'
import MickeyPlaceholderLoading from '../../../components/PlaceholderLoading/PlaceholderLoading'
import PageHeader from '../../../components/PageHeader/PageHeader'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

const MxNotification = (props) => {
  const { user, supplier, buyer, currentPortal } = props
  const window = useWindowDimensions()
  const axios = useAxios()
  const reduxUser = useSelector((state) => state.user.user)
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([])
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loaded, setLoaded] = useState(false)

  async function markAsRead(notificationId) {
    let res = await axios.patch('/main/notifications/' + notificationId + '/', {
      archived: true,
    })
    if (res?.data?.id) {
      setNotifications(
        notifications.filter(
          (notification) => notification.id !== notificationId
        )
      )
    }
  }
  useEffect(() => {
    props.setCurrentTab('notifications')
  }, [])

  useEffect(() => {
    const getNotifications = async () => {
      setLoaded(false)
      let res = await axios.get(`/main/notifications/`, {
        params: {
          user__id: user?.id,
          buyer__id: currentPortal === 'buyer' ? buyer?.id : '',
          supplier__id: currentPortal === 'supplier' ? supplier?.id : '',
          order__archived: false,
          page: activePage,
        },
      })
      if (res?.data?.results) {
        setTotalPages(res?.data?.total_pages ? res?.data?.total_pages : 1)
        setNotifications(res?.data?.results)
      }
      setLoaded(true)
    }
    if (currentPortal && activePage) {
      getNotifications()
    }
  }, [activePage])

  return (
    <>
      <Grid id="notification-page" style={{}}>
        <Grid.Column
          width={window.width < 768 ? 15 : 16}
          style={{ padding: window.width < 768 ? '' : '20px' }}
        >
          <PageHeader icon={'file'} header={'Notifications'} />
          <Divider />
          {!loaded ? (
            <>
              <MickeyPlaceholderLoading />
            </>
          ) : notifications && notifications.length > 0 ? (
            notifications?.map((notification) => {
              let notification_url = notification?.url
                ? notification.url
                : `orders/${notification?.order?.string_token}/active`
              return (
                <>
                  <Grid stackable className="notification-row" width={16}>
                    <Grid.Column className="info-wrapper" width={13}>
                      <h5 className="heading">{notification.created_at}</h5>
                      <h3 className="sec-heading">
                        {notification.notification_sender.email_subject}
                      </h3>
                      <p className="para-heading">{notification.description}</p>
                    </Grid.Column>
                    <Grid.Column className="button-wrapper" width={3}>
                      {hasPermission(user, 'Update Notification') && (
                        <Button
                          style={{
                            padding: '1rem',
                            cursor: 'pointer',
                            borderRadius: '3px',
                          }}
                          color="grey"
                          onClick={() => {
                            markAsRead(notification.id)
                          }}
                        >
                          Mark Read
                        </Button>
                      )}

                      <Button
                        style={{
                          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                          '--secondaryButtonColor':
                            env.REACT_APP_SECONDARY_BUTTON_COLOR,
                          padding: '1rem 2.3rem 1rem 2.3rem',
                          cursor: 'pointer',
                          borderRadius: '3px',
                        }}
                        disabled={
                          !notification?.url &&
                          !notification?.order?.string_token
                        }
                        color="primary"
                        onClick={() => {
                          navigate(
                            props?.supplier
                              ? '/portal/supplier/' + notification_url
                              : `/portal/buyer/` + notification_url
                          )
                        }}
                      >
                        View
                      </Button>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                </>
              )
            })
          ) : (
            <MickeyPlaceholder
              icon={'bell'}
              message={'No Notifications'}
              style={{
                backgroundColor: 'white',
                border: 'none',
                boxShadow: 'none',
              }}
            />
          )}
          <Pagination
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            floated={'right'}
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={(event, data) => {
              setActivePage(data.activePage)
            }}
          />
        </Grid.Column>
      </Grid>
    </>
  )
}

export default MxNotification
