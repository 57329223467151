import React, { useState } from 'react'
import { Table, Modal, Button } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import { format_phone_number } from '../../../lib/utils/utils'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import { hasPermission, inGroup } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'

function AdminManageUsersRow(props) {
  const { developmentUsers } = props
  const axios = useAxios()
  const reduxUser = useSelector((state) => state.user.user)

  const [openEditUserModal, setOpenEditUserModal] = useState(false)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)

  let updateForm
  if (developmentUsers) {
    updateForm = 'AdminDevelopmentCustomUserForm'
  } else {
    updateForm = 'AdminCustomUserForm'
  }

  const deleteUser = async () => {
    setOpenDeleteUserModal(false)
    await axios
      .patch(`/main/admin-users/${props.user?.id}/?tenant_aware=false`, {
        archive_user_id: props.user.id,
      })
      .then(() => {
        toast.success('Successfully deleted user')
      })
    props.setReload(true)
  }

  return (
    <>
      <Table.Row
        selectable={'true'}
        className={'admin-manage-users-row'}
        onClick={() => {
          if (
            hasPermission(reduxUser, 'Update User') &&
            (inGroup(reduxUser, 'Admin') || inGroup(reduxUser, 'Development'))
          ) {
            setOpenEditUserModal(true)
          }
        }}
      >
        <Table.Cell width={2}>{props.user.first_name}</Table.Cell>
        <Table.Cell width={2}>{props.user.last_name}</Table.Cell>
        <Table.Cell width={2}>{props.user.groups[0]?.name}</Table.Cell>
        <Table.Cell width={2}>{props.user.email}</Table.Cell>
        <Table.Cell width={2}>
          {format_phone_number(props.user?.phone)}
        </Table.Cell>
        <Table.Cell width={1}>
          {props.user.receive_sms_messages ? 'Yes' : 'No'}
        </Table.Cell>
        <Table.Cell width={1}>
          {props.user.is_active ? (
            <span style={{ color: 'green' }}>Yes</span>
          ) : (
            <span style={{ color: 'red' }}>No</span>
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        onClose={() => {
          setOpenEditUserModal(false)
          props.setReload(true)
        }}
        onOpen={() => setOpenEditUserModal(true)}
        open={openEditUserModal}
      >
        <Modal.Header>
          Edit {props.user?.first_name} {props.user?.last_name}
        </Modal.Header>
        <p
          style={{
            padding: '1.25rem 1.5rem',
            margin: '0',
          }}
        >
          Changes will update automatically. When you're finished click the
          Close button below.
        </p>
        <Modal.Content>
          <MickeyForm
            formName={updateForm}
            model={'CustomUser'}
            url={'/main/admin-users/'}
            instanceId={props?.user?.id}
            tenantAware={false}
            defaults={{
              type: props.user.groups[0]?.name,
            }}
            urlParams={{
              business_id: props.business?.id,
            }}
          />
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button
            onClick={() => {
              props.setReload(true)
              setOpenEditUserModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setOpenDeleteUserModal(false)
        }}
        onOpen={() => setOpenDeleteUserModal(true)}
        open={openDeleteUserModal}
      >
        <Modal.Header>Delete User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this user?</p>
        </Modal.Content>
        <Modal.Actions floated={'left'}>
          <Button onClick={() => deleteUser()}>Yes</Button>
          <Button onClick={() => setOpenDeleteUserModal(false)}>No</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default AdminManageUsersRow
