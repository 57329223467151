import React, { useState, useEffect, useCallback } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import useAxios from '../../../lib/auth/useAxios'
import { useParams } from 'react-router-dom'
import Profile from '../../shared/Profile/Profile'
import { toast } from 'react-toastify'
import _ from 'underscore'
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'
import { useLocation } from 'react-router-dom'
import env from '../../../env'
import './AdminBusiness.css'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../lib/utils/utils'
import { Plus, X } from 'phosphor-react'

function AdminBusiness(props) {
  const reduxUser = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(reduxUser, 'Update Business')
  const { businessId } = useParams()
  const [business, setBusiness] = useState(null)
  const [loading, setLoading] = useState(true)
  const [reloadBusiness, setReloadBusiness] = useState(false)
  const axios = useAxios()
  const setStartListingModal = props.setStartListingModal
  const [currentlySelectedLocation, setCurrentlySelectedLocation] =
    useState(null)
  console.log(currentlySelectedLocation)
  const [currentExchangeTable, setCurrentExchangeTable] = useState(null)
  const [currentExchangeTab, setCurrentExchangeTab] = useState('my-bids')
  const equipmentType = props.equipmentType
  const [user, setUser] = useState({})
  const [users, setUsers] = useState([])
  const [openBusinessArchivedPopUp, setOpenBusinessArchivedPopUp] =
    useState(false)
  const [initialState, setinitialState] = useState(true)
  const state = useLocation()?.state
  const [activeButton, setActiveButton] = useState('Dashboard')
  const [openCreateLocationsModal, setOpenCreateLocationsModal] =
    useState(false)
  const [showAddNotificationsModal, setShowAddNotificationsModal] =
    useState(false)
  const [openInviteNewUserModal, setOpenInviteNewUserModal] = useState(false)
  const [openSalesLogModal, setOpenSalesLogModal] = useState(false)
  const [hideHeader, setHideHeader] = useState(false)
  const [openAddPurchaseOrderModal, setOpenAddPurchaseOrderModal] =
    useState(false)
  const [openAddSalesOrderModel, setOpenAddSalesOrderModel] = useState(false)

  useEffect(() => {
    if (user) {
      setCurrentlySelectedLocation(user.current_location?.id)
    }
  }, [user])

  async function getUser(id) {
    let res = await axios.get(`/main/custom-users/${id}/`)
    if (res?.data) {
      let userCopy = res?.data

      if (initialState && state) {
        setinitialState(false)
        userCopy.current_delivery_timeframe = state?.timeframe
        userCopy.current_equipment_type = state?.cargoUnit
        userCopy.current_location = state?.location

        axios.patch(`/main/custom-users/${id}/`, {
          current_delivery_timeframe: state?.timeframe,
          current_equipment_type_id: state?.cargoUnit?.id,
          current_location_id: state?.location?.id,
          business_id: business?.id,
        })
      }
      setUser(userCopy)
    }
  }

  async function loadData() {
    if (reloadBusiness) {
      setLoading(true)
    }
    let res = await axios.get(`/main/admin-businesses/${businessId}/`, {
      params: {
        tenant_aware: false,
      },
    })
    setLoading(false)
    setBusiness(res?.data)
    if (res?.data?.user?.length > 0) {
      let id
      if (initialState && state) {
        id = state?.user?.id
      } else if (user?.id) {
        id = res.data?.user.filter((userData) => userData.id === user.id)[0]?.id
      } else {
        id = res.data?.user[0]?.id
      }
      await getUser(id)
      setUsers(res.data?.user)
    }
  }

  useEffect(() => {
    loadData()
  }, [reloadBusiness, businessId])

  const archiveBusiness = async () => {
    let res = await axios.delete(`/main/admin-businesses/${businessId}/`)
    if (res?.status < 300) {
      window.location = '/portal/admin/businesses/'
    }
  }

  // handle what happens on key press
  const handleKeyPress = useCallback((event) => {
    if (event.shiftKey === true && event.ctrlKey === true) {
      if (event.key === '+') {
        setStartListingModal(true)
      }
    }
  }, [])

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    const getCommodities = async () => {
      let res = await axios.get(`/main/commodities/`)
      if (res?.data?.results?.length > 0) {
        let results = res.data.results.reverse()
        if (results.length > 0) {
          props.setCommodities(results)
          props.setCommodityForms(results[0]?.commodity_forms)
          props.setSelectedCommodity(results[0])
        }
      }
    }
    getCommodities()
  }, [])

  useEffect(() => {
    async function updateCurrentEquipmentType() {
      let equipmentTypeId
      if (typeof equipmentType === 'object') {
        equipmentTypeId = equipmentType?.id
      }
      let res = await axios.patch(`/main/custom-users/${user?.id}/`, {
        current_equipment_type_id: equipmentTypeId,
        business_id: business?.id,
      })
      if (res?.data?.id) {
        // toast.success("Equipment Updated");
        setUser(res?.data)
      }
    }
    if (
      equipmentType &&
      user?.id &&
      equipmentType !== user?.current_equipment_type?.id
    ) {
      updateCurrentEquipmentType()
    }
  }, [equipmentType, user?.id])

  useEffect(() => {
    async function updateSelectedLocation() {
      let res = await axios.patch(`/main/custom-users/${user?.id}/`, {
        current_location_id: currentlySelectedLocation,
        business_id: business?.id,
      })
      if (res?.data?.id) {
        setUser(res.data)
      }
    }
    if (
      currentlySelectedLocation &&
      user?.id &&
      currentlySelectedLocation !== user?.current_location?.id
    ) {
      updateSelectedLocation()
    }
  }, [currentlySelectedLocation, user?.id])

  async function makeSupplierAccount() {
    setLoading(true)
    let res = await axios.post(
      `/main/admin-businesses/${businessId}/make_supplier/`
    )
    setLoading(false)
    if (res.data.success) {
      setReloadBusiness(true)
      toast.success('Supplier account created successfully')
    } else {
      toast.error('Error creating supplier account.')
    }
  }
  let buttons = [
    {
      color: currentExchangeTable === 'supplier' ? 'primary' : 'grey',
      hidden: activeButton !== 'Exchange' || !business?.supplier?.id,
      disabled: !users,
      className: 'supplier-step-2 buyer-step-2',
      content: 'Supplier',
      onClick: () => {
        setCurrentExchangeTable('supplier')
        setCurrentExchangeTab('my-bids')
        setCurrentExchangeTab('exchange')
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
    {
      color: currentExchangeTable === 'buyer' ? 'green' : 'grey',
      hidden: activeButton !== 'Exchange' || !business?.buyer?.id,
      disabled: !users,
      className: 'supplier-step-2 buyer-step-2',
      content: 'Buyer',
      onClick: () => {
        setCurrentExchangeTable('buyer')
        setCurrentExchangeTab('my-bids')
        setCurrentExchangeTab('exchange')
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
  ]
  if (hasPermission(reduxUser, 'Create Location')) {
    buttons.push({
      color: 'primary',
      className: 'supplier-step-2 buyer-step-2',
      icon: <Plus weight="bold" />,
      content: 'Add Location',
      hidden: activeButton !== 'Manage Addresses',
      onClick: () => {
        setOpenCreateLocationsModal(true)
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    })
  }
  if (canUpdate) {
    buttons = [
      ...buttons,
      ...[
        {
          icon: <Plus weight="bold" />,
          hidden: activeButton !== 'Account Details' || business?.supplier?.id,
          color: 'orange',
          loading: loading,
          content: 'Make Supplier',
          onClick: () => {
            makeSupplierAccount()
          },
        },
        {
          color: 'primary',
          className: 'supplier-step-2 buyer-step-2',
          icon: <Plus weight="bold" />,
          content: 'Add Notification Trigger',
          hidden: activeButton !== 'Notifications',
          onClick: () => {
            setShowAddNotificationsModal(true)
          },
          style: {
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
          },
        },
        {
          color: 'primary',
          className: 'supplier-step-2 buyer-step-2',
          icon: <Plus weight="bold" />,
          content: 'Add Sales Log',
          hidden: activeButton !== 'Sales Logs',
          onClick: () => {
            setOpenSalesLogModal(true)
          },
          style: {
            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
            '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
          },
        },
      ],
    ]
  }
  if (hasPermission(reduxUser, 'Create User')) {
    buttons.push({
      color: 'primary',
      className: 'supplier-step-2 buyer-step-2',
      icon: <Plus weight="bold" />,
      content: 'Add User',
      hidden: activeButton !== 'Manage Users',
      onClick: () => {
        setOpenInviteNewUserModal(true)
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    })
  }
  if (hasPermission(reduxUser, 'Delete Business')) {
    buttons.push({
      icon: <X weight="bold" />,
      color: 'red',
      content: 'Archive',
      hidden: activeButton !== 'Account Details',
      loading: loading,
      popupOffset: [-25, 0],
      popupOpen: openBusinessArchivedPopUp,
      popupOnOpen: () => setOpenBusinessArchivedPopUp(true),
      popupOnClose: () => setOpenBusinessArchivedPopUp(false),
      popup: true,
      popupPosition: 'bottom right',
      popupContent: (
        <Grid style={{ minWidth: '300px', paddingLeft: '15px' }}>
          <Grid.Row>
            <div style={{ margin: '10px auto' }}>Are you sure!</div>
            <MickeyButton
              color={'red'}
              content={'Archive Business'}
              loading={!businessId}
              innerStyle={{ width: '100%' }}
              style={{
                marginTop: '20px',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => {
                archiveBusiness()
              }}
            />
          </Grid.Row>
        </Grid>
      ),
    })
  }
  if (hasPermission(user, 'Create Sales Order')) {
    buttons.push({
      color: 'primary',
      content: 'Add Sales Order',
      className: 'supplier-step-2 buyer-step-2',
      hidden: activeButton !== 'Sales Orders',
      icon: <Plus weight="bold" />,
      onClick: () => {
        setOpenAddSalesOrderModel(true)
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    })
  }
  if (hasPermission(user, 'Create Purchase Order')) {
    buttons.push({
      color: 'primary',
      content: 'Add Purchase Order',
      icon: <Plus weight="bold" />,
      className: 'supplier-step-2 buyer-step-2',
      hidden: activeButton !== 'Purchase Orders',
      onClick: () => {
        setOpenAddPurchaseOrderModal(true)
      },
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    })
  }

  return (
    <>
      <div className={'main-segment-sidebar-container'}>
        {loading && !business?.id && (
          <Segment basic style={{ minHeight: '600px' }}>
            <Dimmer inverted active>
              <Loader size="large">Loading</Loader>
            </Dimmer>
          </Segment>
        )}
        {!loading && business?.id && (
          <>
            {hideHeader && (
              <PageHeader
                icon={'address book'}
                header={business?.name}
                label={business?.is_god ? 'Admin' : ''}
                labelColor={business?.is_god ? 'green' : ''}
                blueCheck={business?.verified_customer}
                breadcrumb={[
                  { name: 'Businesses', href: '/portal/admin/businesses' },
                ]}
                buttons={buttons}
              />
            )}
            <Profile
              business={business}
              setBusiness={setBusiness}
              supplier={business?.supplier}
              buyer={business?.buyer}
              user={user}
              setUser={setUser}
              users={users}
              loadData={loadData}
              hideHeader={hideHeader}
              setHideHeader={setHideHeader}
              currentExchangeTable={currentExchangeTable}
              setCurrentExchangeTable={setCurrentExchangeTable}
              currentExchangeTab={currentExchangeTab}
              setCurrentExchangeTab={setCurrentExchangeTab}
              admin
              profileActiveTab={activeButton}
              setProfileActiveTab={setActiveButton}
              setCurrentlySelectedLocation={setCurrentlySelectedLocation}
              currentlySelectedLocation={currentlySelectedLocation}
              currentTab={props.currentTab}
              setCurrentTab={props.setCurrentTab}
              currentPortal={props.currentPortal}
              setCurrentPortal={props.setCurrentPortal}
              openExchangeFilter={props.openExchangeFilter}
              openStartListingModal={props.openStartListingModal}
              setReloadHeaderStats={props.setReloadHeaderStats}
              setStartListingModal={props.setStartListingModal}
              selectedCommodity={props.selectedCommodity}
              openSetAttributesModal={props.openSetAttributesModal}
              setOpenAttributesModal={props.setOpenAttributesModal}
              openPricingModal={props.openPricingModal}
              setOpenPricingModal={props.setOpenPricingModal}
              openReviewListingModal={props.openReviewListingModal}
              setReviewListingModal={props.setReviewListingModal}
              openCreateLocationsModal={openCreateLocationsModal}
              setOpenCreateLocationsModal={setOpenCreateLocationsModal}
              showAddNotificationsModal={showAddNotificationsModal}
              setShowAddNotificationsModal={setShowAddNotificationsModal}
              openInviteNewUserModal={openInviteNewUserModal}
              setOpenInviteNewUserModal={setOpenInviteNewUserModal}
              openSalesLogModal={openSalesLogModal}
              setOpenSalesLogModal={setOpenSalesLogModal}
              productVariant={props.productVariant}
              setProductVariant={props.setProductVariant}
              inputExpiration={props.inputExpiration}
              setInputExpiration={props.setInputExpiration}
              setInputPrice={props.setInputPrice}
              setInputSecondaryPrice={props.setInputSecondaryPrice}
              inputSecondaryPrice={props.inputSecondaryPrice}
              inputPrice={props.inputPrice}
              setInputQuantity={props.setInputQuantity}
              inputQuantity={props.inputQuantity}
              notifications={props.notifications}
              setNotifications={props.setNotifications}
              timeframe={props.timeframe}
              getUser={getUser}
              setTimeframe={props.setTimeframe}
              equipmentType={props.equipmentType}
              setEquipmentType={props.setEquipmentType}
              commodities={props.commodities}
              setCommodities={props.setCommodities}
              commodityForms={props.commodityForms}
              setCommodityForms={props.setCommodityForms}
              selectedCommodityForm={props.selectedCommodityForm}
              setSelectedCommodityForm={props.setSelectedCommodityForm}
              adminCurrentlySelectedTimeframe={
                props.adminCurrentlySelectedTimeframe
              }
              setAdminCurrentlySelectedTimeframe={
                props.setAdminCurrentlySelectedTimeframe
              }
              adminCurrentlySelectedTruckingType={
                props.adminCurrentlySelectedTruckingType
              }
              setAdminCurrentlySelectedTruckingType={
                props.setAdminCurrentlySelectedTruckingType
              }
              openAddPurchaseOrderModal={openAddPurchaseOrderModal}
              setOpenAddPurchaseOrderModal={setOpenAddPurchaseOrderModal}
              openAddSalesOrderModel={openAddSalesOrderModel}
              setOpenAddSalesOrderModel={setOpenAddSalesOrderModel}
            />
          </>
        )}
      </div>
    </>
  )
}

export default AdminBusiness
