export const PermissionForm = {
  model: 'Permission',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
          label: 'Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        groups: {
          url: '/main/group/',
          type: 'multiple dropdown model',
          label: 'Groups',
          option_text_field: 'name',
        },
      },
    },
  ],
}
