export const SendSupplierPaymentForm = {
  model: 'Order',
  format: [
    {
      widths: 'equal',
      fields: {
        payment_amount: {
          type: 'number',
          label: 'Total Payout Amount',
          step: '0.01',
        },
      },
    },
  ],
}
