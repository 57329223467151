import React from 'react'
import CustomLumberLength from './Lumber/CustomLumberLength'

const CustomLength = (props) => {
  const { attributeName, product, handleUpdateCustomValues } = props

  return (
    <>
      {product?.commodity_form?.name === 'Lumber' && (
        <CustomLumberLength
          attributeName={attributeName}
          handleUpdateCustomValues={handleUpdateCustomValues}
        />
      )}
    </>
  )
}

export default CustomLength
