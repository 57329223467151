import {
  requiredCountryValidator,
  phoneNumberValidator,
} from '../components/Forms/validators'

export const LocationFormNotRequired = {
  model: 'Location',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          type: 'text',
        },
        location_truck_capabilities: {
          type: 'multiple dropdown',
          label: 'Trucking Capabilities',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        address_1: {
          type: 'text',
        },
        address_2: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        country: {
          required: true,
          type: 'country',
          validator: (input) => {
            return requiredCountryValidator(input)
          },
        },
        state: {
          required: true,
          type: 'dropdown search',
          url: '/main/states/',
          require_params: true,
          related_params: {
            country: 'country',
          },
          title_fields: ['name'],
          description_fields: [''],
        },
        city: {
          required: true,
          type: 'text',
        },
        zip_code: {
          required: true,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        type: {
          type: 'dropdown',
        },
        on_site_contact_name: {
          type: 'text',
          label: 'On-Site Contact Name',
        },
        email: {
          type: 'text',
        },
        phone: {
          type: 'phone',
          label: 'Phone',
          validator: (input) => {
            return phoneNumberValidator(input)
          },
        },
      },
    },
  ],
}
