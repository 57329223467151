import React, { useState, useMemo, useEffect } from 'react'
import {
  Segment,
  Header,
  Grid,
  Dropdown,
  Form,
  Divider,
  List,
  Input,
} from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import './MXSignUp.css'
import { Circle, CaretDown } from 'phosphor-react'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useLocation } from 'react-router-dom'
import useAxios from '../../../lib/auth/useAxios'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function MXSignUp() {
  const window = useWindowDimensions()
  const axios = useAxios()
  const [applicationType, setApplicationType] = useState('Buyer')
  const [hearAboutUs, setHearAboutUs] = useState()
  const [hearAboutUsUpdated, setHearAboutUsUpdated] = useState(false)
  const [signupBusinessId, setSignupBusinessId] = useState(null)
  const [otherInput, setOtherInput] = useState()
  let query = useQuery()

  async function updateHearAboutUs() {
    await axios.post(`/main/auto-update/`, {
      model: 'Business',
      field: 'hear_about_us',
      value: hearAboutUs === 'Other' ? otherInput : hearAboutUs,
      instance_id: signupBusinessId,
    })

    setHearAboutUsUpdated(true)
    setTimeout(() => {
      setHearAboutUsUpdated(false)
    }, 2000)
  }

  useEffect(() => {
    if (
      (hearAboutUs !== 'Other' || (hearAboutUs === 'Other' && otherInput)) &&
      signupBusinessId
    ) {
      updateHearAboutUs()
    }
  }, [otherInput, hearAboutUs])

  const hearAboutUsOptions = [
    {
      text: 'Google',
      value: 'Google',
    },
    {
      text: 'Facebook',
      value: 'Facebook',
    },
    {
      text: 'LinkedIn',
      value: 'LinkedIn',
    },
    {
      text: 'Current MaterialsXchange User',
      value: 'Current MaterialsXchange User',
    },
    {
      text: 'MaterialsXchange Live Show',
      value: 'MaterialsXchange Live Show',
    },
    {
      text: 'Trade Show',
      value: 'Trade Show',
    },
    {
      text: 'Word of Mouth',
      value: 'Word of Mouth',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ]

  return (
    <div id="signup-page">
      <Grid className="signup-grid" stackable width={16}>
        <Grid.Row className="signup-row">
          <Grid.Column
            className="signup-left"
            width={window.width < 996 ? 15 : 6}
          >
            <Header className="signup-header">
              Let's Create <br /> an Account
            </Header>
            <List>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '4rem',
                }}
              >
                <Circle
                  size={12}
                  color={env.REACT_APP_PRIMARY_COLOR}
                  style={{ marginRight: '1rem', marginTop: '9px' }}
                />
                <List.Item>
                  <p style={{ margin: 0, lineHeight: '1.75em' }}>
                    View wood products and pricing in real-time
                  </p>
                </List.Item>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Circle
                  size={12}
                  color={env.REACT_APP_PRIMARY_COLOR}
                  style={{ marginRight: '1rem ', marginTop: '9px' }}
                />
                <List.Item>
                  <p style={{ margin: 0, lineHeight: '1.75em' }}>
                    Bid on lumber you need at prices you want to pay
                  </p>
                </List.Item>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Circle
                  size={12}
                  color={env.REACT_APP_PRIMARY_COLOR}
                  style={{ marginRight: '1rem ', marginTop: '9px' }}
                />
                <List.Item>
                  <p style={{ margin: 0, lineHeight: '1.75em' }}>
                    Match buyer and seller prices for instant transactions
                  </p>
                </List.Item>
              </div>
            </List>
          </Grid.Column>
          {window.width < 996 && (
            <Divider style={{ width: '85%', margin: '2rem 0 2rem 0' }} />
          )}
          <Grid.Column
            className="signup-right"
            width={window.width < 996 ? 15 : 10}
          >
            <Segment className="form-wrapper">
              <Header>Account Type</Header>
              <Form.Group columns={'equal'} style={{ display: 'flex' }}>
                <Form.Field
                  style={{
                    marginBottom: '1rem',
                    width: '50%',
                    padding: '0 7px',
                  }}
                >
                  <label className="dropdown-label">
                    Select your account type
                  </label>
                  <Dropdown
                    id="account-type-dropdown"
                    icon={<CaretDown />}
                    placeholder="Account Type"
                    fluid
                    selection
                    defaultValue={applicationType}
                    style={{
                      marginBottom: '1rem',
                      // maxWidth: "50%",
                      paddingRight: '1rem',
                      height: '49px',
                    }}
                    options={[
                      {
                        text: 'Buyer',
                        value: 'Buyer',
                      },
                      {
                        text: 'Supplier',
                        value: 'Supplier',
                      },
                    ]}
                    onChange={(e, d) => setApplicationType(d.value)}
                  />
                </Form.Field>
                {signupBusinessId && (
                  <Form.Field
                    style={{
                      marginBottom: '1rem',
                      width: '50%',
                      padding: '0 7px',
                    }}
                  >
                    <div>
                      <label>
                        Where Did You Hear About Us?
                        <label
                          className={
                            hearAboutUsUpdated && hearAboutUs !== 'Other'
                              ? 'saved-label saving'
                              : 'saved-label'
                          }
                        >
                          {' '}
                          Saved!
                        </label>
                      </label>
                    </div>
                    <Dropdown
                      id="account-type-dropdown"
                      icon={<CaretDown />}
                      placeholder={
                        window.width > 500
                          ? 'Where Did You Hear About Us?'
                          : 'Heard from?'
                      }
                      fluid
                      selection
                      style={{
                        marginBottom: '1rem',
                        // maxWidth: "50%",
                        paddingRight: '1rem',
                        height: '49px',
                      }}
                      options={hearAboutUsOptions}
                      onChange={(e, d) => {
                        setHearAboutUs(d.value)
                      }}
                    />
                  </Form.Field>
                )}
              </Form.Group>
              {signupBusinessId && hearAboutUs === 'Other' && (
                <Form.Group>
                  <Form.Field
                    style={{
                      marginBottom: '1rem',
                      width: '50%',
                      padding: '0 7px',
                    }}
                  >
                    <div>
                      <label>
                        <label
                          className={
                            hearAboutUsUpdated && hearAboutUs === 'Other'
                              ? 'saved-label saving'
                              : 'saved-label'
                          }
                        >
                          {' '}
                          Saved!
                        </label>
                      </label>
                    </div>
                    <Input
                      id="other-input"
                      style={{ width: '100%' }}
                      placeholder="Other"
                      onChange={(e, d) => {
                        setOtherInput(d.value)
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              )}
              <MickeyForm
                formName={'BusinessApplicationForm'}
                model={'Business'}
                innerSuccessMessage={
                  "Thanks for applying! We'll get back to you shortly!"
                }
                innerButtonMessage={'Click here to read more about Mickey'}
                innerButtonHref={'/about'}
                successMessage={
                  "Thanks for applying! We'll get back to you shortly!"
                }
                failureMessage={'Error with your application.'}
                url={'/main/p-businesses/'}
                buttonLabel={'Apply'}
                buttonSize={'full'}
                values={{
                  is_supplier: applicationType === 'Supplier' ? true : null,
                  is_buyer: applicationType === 'Buyer' ? true : null,
                }}
                defaults={{
                  contact_email: query.get('email', null),
                }}
                reCaptcha={true}
                disableButton={true}
                handleForm={(status, data) => {
                  if (status === 'success') {
                    setSignupBusinessId(data?.business_id)
                  }
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default MXSignUp
