import React, { useEffect, useState } from 'react'
import {
  Table,
  Dropdown,
  Popup,
  Icon,
  Button,
  Checkbox,
} from 'semantic-ui-react'
import _ from 'underscore'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import AutoField from '../../../components/AutoField/AutoField'
import { startLoader, stopLoader } from '../../../lib/utils/utils'
import { useDispatch } from 'react-redux'

function InventoryPricedCombo(props) {
  const axios = useAxios()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [isActive, setIsActive] = useState(props.productListingPrice?.active)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsActive(props.productListing?.active)
  }, [props.productListing?.active])

  async function updateProductListingPrice(data) {
    let res = await axios.post('/main/update-attribute-selection/', {
      id: props.productListingPrice?.id,
      attribute_selection_priority:
        props.attributeSelection?.attribute?.prioriy,
      attribute_selection_value: data.value,
    })
    if (res?.success) {
      toast.success('Updated!')
    }
  }

  async function toggleProductListingActive(e, d) {
    let res = await axios.patch(
      `/main/product-listing-prices/${props.productListingPrice?.id}/`,
      {
        active: d.checked,
      }
    )
    if (res?.status < 300) {
      toast.success('Updated listing!')
    }
  }
  async function addProductListingPrice() {
    startLoader(dispatch)
    await axios.post('/main/product-listing-prices/', {
      attribute_selection_values: _.map(
        props.productListingPrice?.attribute_selections,
        (att_sel) => {
          return {
            value: att_sel.value,
            attribute_id: att_sel.id,
          }
        }
      ),
      product_listing: props.productListing.id,
    })
    stopLoader(dispatch)
  }
  async function removeProductListingPrice() {
    setButtonLoading(true)
    startLoader(dispatch)
    let res = await axios.delete(
      `/main/product-listing-prices/${props.productListingPrice?.id}/`
    )
    stopLoader(dispatch)
    setButtonLoading(false)
    if (res?.status < 300) {
      props.setReload(true)
      toast.success('Removed listing!')
    }
  }
  function getEmptyCells() {
    let totalFilledCells = props.attributeSelections?.length
    let attributes = props.priceAttributes?.filter((attribute, idx) => {
      return idx > totalFilledCells - 1
    })
    let blankCells = attributes?.map((attribute, idx) => {
      return (
        <Table.Cell style={{ position: 'relative' }}>
          <Dropdown
            placeholder={'Select ' + attribute.name}
            search
            onChange={(e, d) => {
              updateProductListingPrice(d)
            }}
            selection
            options={_.map(attribute.values, (value) => {
              return {
                key: value.value,
                text: value.value,
                value: value.value,
              }
            })}
          />
          {idx === 0 && (
            <Popup
              trigger={
                <Icon
                  popup={'Nice!'}
                  onClick={() => {
                    addProductListingPrice()
                  }}
                  className={'product-inventory-plus'}
                  color={'green'}
                  style={{ cursor: 'pointer' }}
                  name="circle plus"
                ></Icon>
              }
            >
              <Popup.Content>
                Add
                <b>{' ' + attribute.name + ' '}</b>
                Line Item
              </Popup.Content>
            </Popup>
          )}
        </Table.Cell>
      )
    })
    return blankCells
  }

  return (
    <Table.Row
      style={{ cursor: 'pointer' }}
      selectable={true}
      className={'priced-combo'}
    >
      <Table.Cell disabled>
        {props.productListing?.product?.material?.name} (
        {props.productListing?.product?.commodity_form?.name})
      </Table.Cell>
      {props.attributeSelections?.map((attributeSelection, index) => {
        return (
          <Table.Cell key={index} style={{ position: 'relative' }}>
            {attributeSelection?.attribute_selection_value}
          </Table.Cell>
        )
      })}
      {getEmptyCells()}
      <Popup
        trigger={
          <Table.Cell>
            <AutoField
              icon="dollar"
              model={'ProductListingPrice'}
              field={'price'}
              url={'/main/product-listing-prices/'}
              instanceId={props.productListingPrice?.id}
              iconPosition="left"
              value={props.productListingPrice.price}
              placeholder="Enter Price..."
              size={'small'}
              textarea={false}
              style={{
                width: '120px',
              }}
            />
          </Table.Cell>
        }
        header="Notice:"
        content="Inventory will not be available in the storefront until its price and quanitity have been set."
        on="focus"
      />
      <Popup
        trigger={
          <Table.Cell>
            <AutoField
              icon="box"
              model={'ProductListingPrice'}
              field={'inventory'}
              url={'/main/product-listing-prices/'}
              instanceId={props.productListingPrice?.id}
              iconPosition="left"
              value={props.productListingPrice.inventory}
              placeholder="Enter Quantity..."
              size={'small'}
              style={{
                width: '120px',
              }}
            />
          </Table.Cell>
        }
        header="Notice:"
        content="Please note units are in board feet and not thousand board feet. Inventory will not be available in the storefront until its price and quanitity have been set."
        on="focus"
      />

      <Table.Cell textAlign="center" width="1">
        <Checkbox
          slider
          value={isActive}
          checked={isActive}
          onChange={(e, d) => {
            setIsActive(d.checked)
            toggleProductListingActive(e, d)
          }}
          size="mini"
          loading={buttonLoading}
          style={{ cursor: 'pointer' }}
        />
      </Table.Cell>
      <Table.Cell textAlign="center" width="1">
        <Button
          onClick={() => {
            removeProductListingPrice()
          }}
          size="mini"
          loading={buttonLoading}
          icon="x"
          style={{ cursor: 'pointer' }}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default InventoryPricedCombo
