import { useEffect, useState } from 'react'
import { Plus } from 'phosphor-react'
import env from '../../../env'
import { Header } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { hasPermission, inGroup } from '../../../lib/utils/utils'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './AdminSettings.css'
import PageHeader from '../../../components/PageHeader/PageHeader'

function AdminSettings(props) {
  const {
    openAddModal,
    setOpenAddModal,
    adminHomepageSelectedTab,
    setAdminHomepageSelectedTab,
  } = props
  const user = useSelector((state) => state.user.user)
  const negative = useNavigate()
  const location = useLocation()
  const [activeItem, setActiveItem] = useState()

  useEffect(() => {
    setActiveItem(
      location.pathname.includes('/portal/admin/settings/manage-users')
        ? 'Manage Users'
        : location.pathname.includes('/portal/admin/settings/permissions')
        ? 'Permission'
        : location.pathname.includes('/portal/admin/settings/manage-locations')
        ? 'Locations'
        : location.pathname.includes('/portal/admin/settings/documents')
        ? 'Document'
        : location.pathname.includes('/portal/admin/settings/trucking')
        ? 'Trucking'
        : location.pathname.includes('/portal/admin/settings/homepage-settings')
        ? 'Homepage'
        : location.pathname.includes('/portal/admin/settings/manage-dev-users')
        ? 'Manage Development Users'
        : 'Manage Users'
    )
    if (
      location.pathname.includes('/portal/admin/settings/homepage-settings')
    ) {
      setAdminHomepageSelectedTab('press')
    }
  }, [location?.pathname])

  const headerItems = [
    { label: 'Manage Users', redirectUrl: 'manage-users' },
    { label: 'Permission', redirectUrl: 'permissions' },
    { label: 'Locations', redirectUrl: 'manage-locations' },
    { label: 'Document', redirectUrl: 'documents' },
    { label: 'Trucking', redirectUrl: 'trucking' },
    { label: 'Homepage', redirectUrl: 'homepage-settings' },
    {
      label: 'Manage Development Users',
      redirectUrl: 'manage-dev-users',
      hidden: !inGroup(user, 'Development'),
    },
  ]

  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/' },
    { name: 'Settings', href: '/portal/admin/settings/' },
  ]

  let buttons = [
    {
      color: 'primary',
      content: 'Add Press Release',
      icon: <Plus weight="bold" />,
      hidden: adminHomepageSelectedTab !== 'press' || activeItem !== 'Homepage',
      onClick: () => setOpenAddModal(true),
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
    {
      color: 'primary',
      content: 'Add News',
      icon: <Plus weight="bold" />,
      hidden: adminHomepageSelectedTab !== 'news' || activeItem !== 'Homepage',
      onClick: () => setOpenAddModal(true),
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
    {
      color: 'primary',
      content: 'Add Event',
      icon: <Plus weight="bold" />,
      hidden:
        adminHomepageSelectedTab !== 'events' || activeItem !== 'Homepage',
      onClick: () => setOpenAddModal(true),
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
    {
      color: 'primary',
      content: 'Add Team Member',
      icon: <Plus weight="bold" />,
      hidden:
        adminHomepageSelectedTab !== 'team-members' ||
        activeItem !== 'Homepage',
      onClick: () => setOpenAddModal(true),
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
    {
      color: 'primary',
      content: 'Add Ticker',
      icon: <Plus weight="bold" />,
      hidden:
        adminHomepageSelectedTab !== 'tickers' || activeItem !== 'Homepage',
      onClick: () => setOpenAddModal(true),
      style: {
        '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
        '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
      },
    },
  ]
  if (inGroup(user, 'Admin') || inGroup(user, 'Development')) {
    if (hasPermission(user, 'Create User')) {
      buttons.push({
        color: 'primary',
        content:
          activeItem === 'Manage Development Users'
            ? 'Add Development User'
            : 'Add User',
        icon: <Plus weight="bold" />,
        onClick: () => {
          setOpenAddModal(true)
        },
        hidden:
          activeItem !== 'Manage Development Users' &&
          activeItem !== 'Manage Users',
        style: {
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        },
      })
    }
    if (hasPermission(user, 'Create Location')) {
      buttons.push({
        color: 'primary',
        content: 'Add Admin Locations',
        icon: <Plus weight="bold" />,
        onClick: () => {
          setOpenAddModal(true)
        },
        hidden: activeItem !== 'Locations',
        style: {
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        },
      })
    }
  }

  return (
    <div className={'main-container'} id="admin-settings">
      <PageHeader
        icon={'file'}
        header={activeItem}
        breadcrumb={breadcrumbData}
        buttons={buttons}
      />
      <div className="commodities-wrapper">
        {headerItems?.map((item, idx) => {
          if (item?.hidden) return <></>
          return (
            <Header
              as={'h2'}
              className={
                activeItem === item?.label
                  ? 'commodity-header selected'
                  : 'commodity-header'
              }
              color={activeItem === item?.label ? 'primary' : 'black'}
              onClick={() => {
                setActiveItem(item?.label)
                negative(item?.redirectUrl)
                setOpenAddModal(false)
                if (item?.label === 'Homepage') {
                  setAdminHomepageSelectedTab('press')
                }
              }}
              style={{
                margin: '0 1rem 0rem 0',
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              }}
              content={item?.label}
            />
          )
        })}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  )
}

export default AdminSettings
