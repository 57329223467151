import React, { useState, useEffect } from 'react'
import {
  TextArea,
  Header,
  Button,
  Grid,
  Icon,
  Form,
  Segment,
  Loader,
} from 'semantic-ui-react'
import { validate } from 'react-email-validator'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'
import { formatTotalPrice } from '../../../lib/utils/utils'
import MickeyForm from '../../../components/Forms/MickeyForm'
import AutoDatepicker from '../../../components/AutoDatepicker/AutoDatepicker'
import MickeyDropdown from '../../../components/MickeyDropdown/MickeyDropdown'
import AutoDropdown from '../../../components/AutoDropdown/AutoDropdown'
import useAxios from '../../../lib/auth/useAxios'
import { toast } from 'react-toastify'
import './TruckingLoader.css'
import { formatAddressOneLine } from '../../../helpers/helpers'
import moment from 'moment-timezone'

const CargoUnitInstance = (props) => {
  //state
  const [showManualTrucking, setShowManualTrucking] = useState(
    props.cargoUnitInstance?.manual_cargo_unit_on
  )
  const [truckindDispatchSignerName, setTruckingDispatchSignerName] = useState()
  const [truckingDispatchSignerEmail, setTruckingDispatchSignerEmail] =
    useState()
  const [truckingQuotes, setTruckingQuotes] = useState(null)
  const [globalSettings, setGlobalSettings] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingDocSend, setLoadingDocSend] = useState(false)
  const [bookTruckLoading, setBookTruckLoading] = useState(false)
  const [productVariantDivisor, setProductVariantDivisor] = useState(null)
  const [purchaseOrderId, setPurchaseOrderId] = useState()
  const [salesOrderId, setSalesOrderId] = useState()
  const [documentNotes, setDocumentNotes] = useState('')
  const [loadingDeliveryDate, setLoadingDeliveryDate] = useState(false)
  const [selectedPickupLocation, setSelectedPickupLocation] = useState(null)
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState(null)
  const [sendEmail, setSendEmail] = useState(true)
  const [selectedTruckingQuotesId, setSelectedTruckingQuotesId] = useState(null)
  const param = useState({
    supplier_id: props.cargoUnitInstance?.supplier
      ? props.cargoUnitInstance?.supplier?.id
      : props.cargoUnitInstance?.order?.supplier?.id,
    page_size: 25,
  })
  //hooks
  const axios = useAxios()

  function onUpdateFunction(data) {
    let cui = { ...data }
    if (cui?.trucking_price < 0) {
      return
    }
    if (
      props.cargoUnitInstance &&
      props.cargoUnitInstance?.estimated_ship_date
    ) {
      cui.estimated_ship_date = props.cargoUnitInstance?.estimated_ship_date
    }
    if (
      props.cargoUnitInstance &&
      props.cargoUnitInstance?.estimated_delivery_date
    ) {
      cui.estimated_delivery_date =
        props.cargoUnitInstance?.estimated_delivery_date
    }
    props.setCargoUnitInstance(cui)
  }

  useEffect(() => {
    const getGlobalSettings = async () => {
      const resp = await axios.get('main/global-setting/')
      if (resp?.data?.results) {
        setGlobalSettings(resp?.data?.results[0])
      }
    }
    getGlobalSettings()
  }, [])

  useEffect(() => {
    async function setDivisor() {
      if (!props.cargoUnitInstance?.product_variant) {
        setProductVariantDivisor(`Unable to find Product Variant for cargo`)
      } else if (!props.cargoUnitInstance.cargo_unit) {
        setProductVariantDivisor(`Please select a Trucking Type/Cargo Unit`)
      } else if (
        !('divisors_by_unit' in props.cargoUnitInstance?.product_variant)
      ) {
        setProductVariantDivisor(
          `Unable to find divisors for Product Variant ID: ${props.cargoUnitInstance?.id}`
        )
      } else if (
        !(
          props.cargoUnitInstance.cargo_unit.id in
          props.cargoUnitInstance?.product_variant['divisors_by_unit']
        )
      ) {
        setProductVariantDivisor(
          `No divisors found for cargo unit ${props.cargoUnitInstance?.cargo_unit?.singular_name}`
        )
      } else if (
        !(
          props.cargoUnitInstance.unit.id in
          props.cargoUnitInstance?.product_variant['divisors_by_unit'][
            props.cargoUnitInstance.cargo_unit.id
          ]
        )
      ) {
        setProductVariantDivisor(
          `Cannot find divisor for unit of measure ${props.cargoUnitInstance?.unit?.short_name}`
        )
      } else {
        setProductVariantDivisor(
          props.cargoUnitInstance?.product_variant['divisors_by_unit'][
            props.cargoUnitInstance.cargo_unit.id
          ][props.cargoUnitInstance.unit.id]['cud_value']
        )
      }
    }
    setDivisor()
  }, [props.cargoUnitInstance?.cargo_unit, props.cargoUnitInstance?.unit])

  useEffect(() => {
    async function updateCargoUnitInstance() {
      let res = await axios.patch(
        `/main/admin-cargo-unit-instances/${props.cargoUnitInstance?.id}/`,
        {
          pickup_location_id: selectedPickupLocation,
        }
      )
      if (res?.data?.pickup_location?.id === selectedPickupLocation) {
        toast.success('Updated cargo!')
        props.setCargoUnitInstance({
          ...props.cargoUnitInstance,
          pickup_location: res.data?.pickup_location,
        })
        props.setReload(true)
      } else {
        toast.error('Error updating cargo.')
      }
    }
    if (selectedPickupLocation) {
      updateCargoUnitInstance()
    }
  }, [selectedPickupLocation])

  useEffect(() => {
    async function updateCargoUnitInstance() {
      let res = await axios.patch(
        `/main/admin-cargo-unit-instances/${props.cargoUnitInstance?.id}/`,
        {
          delivery_location_id: selectedDeliveryLocation,
        }
      )
      if (res?.data?.delivery_location?.id === selectedDeliveryLocation) {
        toast.success('Updated cargo!')
        props.setCargoUnitInstance({
          ...props.cargoUnitInstance,
          delivery_location: res.data?.delivery_location,
        })
        props.setReload(true)
      } else {
        toast.error('Error updating cargo.')
      }
    }
    if (selectedDeliveryLocation) {
      updateCargoUnitInstance()
    }
  }, [selectedDeliveryLocation])

  async function toggleManualTrucking(isOn) {
    setShowManualTrucking(isOn)
    await axios.post(`/main/auto-update/`, {
      model: 'CargoUnitInstance',
      field: 'manual_cargo_unit_on',
      value: isOn,
      instance_id: props.cargoUnitInstance?.id,
    })
  }

  async function getTruckingRate() {
    if (!props.cargoUnitInstance?.cargo_unit) {
      toast.error('Please enter fulfillment type.')
    } else if (!props.cargoUnitInstance?.estimated_delivery_date) {
      toast.error('Please enter estimated delivery date.')
    } else if (!props.cargoUnitInstance?.estimated_ship_date) {
      toast.error('Please enter estimated ship date.')
    } else {
      setLoading(true)
      let res = await axios.post(`/main/calculate-trucking/`, {
        cargo_unit_instance_id: props.cargoUnitInstance?.id,
      })
      setLoading(false)
      if (res?.data?.success) {
        setTruckingQuotes(res.data.trucking_quotes)
      } else {
        toast.error(res?.data.error)
      }
    }
  }

  async function reset() {
    setTruckingQuotes(null)
    getTruckingRate()
  }

  async function bookTruck(truckingQuoteId) {
    setBookTruckLoading(true)
    setSelectedTruckingQuotesId(truckingQuoteId)
    let res = await axios.post(`/main/book-trucking/`, {
      cargo_unit_instance_id: props.cargoUnitInstance?.id,
      trucking_quote_id: truckingQuoteId,
      send_email: sendEmail ? true : false,
    })
    setBookTruckLoading(false)
    if (res?.data?.success) {
      toast.success('Booked truck!')
      props.setRefreshPizzaTracker(!props.refreshPizzaTracker)
      props.setCargoUnitInstance({
        ...props.cargoUnitInstance,
        locked: true,
        shipper_load_id: res?.data?.cui?.shipper_load_id,
        trucking_price: res?.data?.cui?.trucking_price,
        trucking_company: res?.data?.cui?.trucking_company,
        trucking_booked: res?.data?.cui?.trucking_booked,
      })
    } else {
      toast.error(res?.data?.error)
      if (res?.data?.requote) {
        getTruckingRate()
      }
    }
  }

  // a function that updates the delivery date based on the pickup date and distance travelled
  async function updateDeliveryDate() {
    setLoadingDeliveryDate(true)
    let res = await axios.post(`/main/update-delivery-date/`, {
      cargo_unit_instance_id: props.cargoUnitInstance?.id,
    })
    setLoadingDeliveryDate(false)
    if (res?.data?.success) {
      props.setCargoUnitInstance({
        ...props.cargoUnitInstance,
        estimated_delivery_date:
          res?.data?.cargo_unit_instance?.estimated_delivery_date,
      })
    } else {
      toast.error(res?.data?.error)
    }
  }

  async function markTruckingBooked() {
    if (!props.cargoUnitInstance?.cargo_unit) {
      toast.error('Please enter fulfillment type.')
      return
    } else if (!props.cargoUnitInstance?.estimated_ship_date) {
      toast.error('Please enter estimated ship date.')
      return
    } else if (!props.cargoUnitInstance?.estimated_delivery_date) {
      toast.error('Please enter estimated delivery date.')
      return
    }
    setLoading(true)
    let res = await axios.post(`/main/mark-trucking-booked/`, {
      cargo_unit_instance_id: props.cargoUnitInstance?.id,
      send_email: sendEmail ? true : false,
    })
    setLoading(false)
    if (res?.data?.success) {
      props.setCargoUnitInstance({
        ...props.cargoUnitInstance,
        locked: true,
        trucking_price: res?.data?.cargo_unit_instance?.trucking_price,
        manual_cargo_unit_company:
          res?.data?.cargo_unit_instance?.manual_cargo_unit_company_name,
        trucking_booked: res?.data?.cargo_unit_instance?.trucking_booked,
      })
      if (props.setReload) {
        props.setReload(true)
      }
      toast.success('Marked Fulfillment as Booked!')
    } else {
      toast.error(res?.data?.error)
    }
  }

  async function createRateConfirmation(sendEmail) {
    toast.success(
      'Please wait while the rate & load confimration document generation is queued'
    )
    setLoadingDocSend(true)
    if (sendEmail) {
      if (!props.cargoUnitInstance?.trucking_company?.email) {
        toast.error(
          `${props.cargoUnitInstance?.trucking_company?.name} requires a contact email to send files for signature`
        )
        setLoadingDocSend(false)
        return
      }
    }
    if (!purchaseOrderId) {
      toast.error(`Purchase Order required`)
      setLoadingDocSend(false)
      return
    }
    if (!salesOrderId) {
      toast.error(`Sales Order required`)
      setLoadingDocSend(false)
      return
    }
    let res = await axios.post(`/main/box-sign-requests/`, {
      file_type: 'rate_confirmation',
      send_to_customer: sendEmail,
      cargo_unit_instance_id: props.cargoUnitInstance.id,
      purchase_order_id: purchaseOrderId,
      sales_order_id: salesOrderId,
      order_id: props.orderId
        ? props.orderId
        : props.cargoUnitInstance?.order?.id,
      notes: documentNotes,
      delay: false,
    })
    if (res?.data.success) {
      toast.success(
        `Please check the document section to continue document generation`
      )
    } else {
      toast.error('Error generating Rate & Load Confirmation')
    }
    setLoadingDocSend(false)
  }

  if (!props?.order?.buyer) {
    return (
      <Grid stackable doubling columns={3} style={{ marginBottom: '100px' }}>
        <Header>Add a buyer to the deal to edit fulfillment</Header>
      </Grid>
    )
  }
  return (
    <Grid stackable doubling columns={3}>
      {loadingDocSend && (
        <>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 10000,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10000,
            }}
          >
            <Loader active inline="centered" />
            <div
              style={{
                marginTop: '10px',
                color: 'white',
                fontSize: '16px',
              }}
            >
              Queueing Document Generation...
            </div>
          </div>
        </>
      )}
      <Grid.Column>
        <Header>Pickup & Delivery Details</Header>
        <Form className="fulfillment-edit-form">
          <AutoDropdown
            optionsUrl={'/main/cargo-units/'}
            button
            model={'CargoUnitInstance'}
            field={'cargo_unit'}
            key={'cargo_unit'}
            label={'Fulfillment Type'}
            titleFields={['singular_name']}
            optionTitleField={'singular_name'}
            disabled={props.cargoUnitInstance?.locked}
            instanceId={props.cargoUnitInstance?.id}
            value={props.cargoUnitInstance?.cargo_unit?.id}
            placeholder={'Fulfillment Type'}
            onChange={(e, d) => {
              props.setCargoUnitInstance({
                ...props.cargoUnitInstance,
                cargo_unit: {
                  id: d.value,
                },
              })
            }}
          />
          <MickeyDropdown
            url={'/main/admin-locations/'}
            params={{
              ...param,
              admin_cui_id: props.cargoUnitInstance?.id,
              is_pickup: true,
            }}
            name={'pickup_location'}
            defaultValue={props.cargoUnitInstance?.pickup_location?.id}
            isForm
            label={'Pickup Location'}
            autoComplete="new-password"
            tenantAware={false}
            placeholder={'Select Pickup Address'}
            disabled={
              props.cargoUnitInstance?.locked ||
              props.cargoUnitInstance?.trucking_booked
            }
            titleFields={['address_1', 'address_2']}
            descriptionFields={['city', 'state', 'zip_code']}
            descriptionFieldSeparator={', '}
            hideDescriptionFieldParenthesis
            onChange={(e, d) => {
              setSelectedPickupLocation(d.value)
            }}
          />
          <MickeyDropdown
            url={'/main/admin-locations/'}
            params={{
              ...param,
              admin_cui_id: props.cargoUnitInstance?.id,
              is_delivery: true,
            }}
            name={'delivery_location'}
            isForm
            defaultValue={props.cargoUnitInstance?.delivery_location?.id}
            autoComplete="new-password"
            label={'Delivery Location'}
            tenantAware={false}
            placeholder={'Select Delivery Address'}
            titleFields={['address_1', 'address_2']}
            disabled={
              props.cargoUnitInstance?.locked ||
              props.cargoUnitInstance?.trucking_booked
            }
            descriptionFields={['city', 'state', 'zip_code']}
            descriptionFieldSeparator={', '}
            hideDescriptionFieldParenthesis
            onChange={(e, d) => {
              setSelectedDeliveryLocation(d.value)
            }}
          />
          <AutoDatepicker
            model={'CargoUnitInstance'}
            field={'estimated_ship_date'}
            label={'Pickup Date'}
            instanceId={props.cargoUnitInstance?.id}
            clearable={!props.cargoUnitInstance?.locked}
            filterDate={(date) => {
              var result = new Date()
              result.setDate(result.getDate() + 2)
              return date >= result
            }}
            value={props.cargoUnitInstance?.estimated_ship_date}
            disabled={props.cargoUnitInstance?.locked}
            onChange={(newValue) => {
              props.setCargoUnitInstance({
                ...props.cargoUnitInstance,
                estimated_ship_date: newValue
                  ? moment(newValue).format('MMM DD, YYYY')
                  : '',
              })
            }}
            onSuccess={(newValue) => {}}
          />
          <AutoDatepicker
            model={'CargoUnitInstance'}
            field={'estimated_delivery_date'}
            instanceId={props.cargoUnitInstance?.id}
            disabled={props.cargoUnitInstance?.locked}
            label={'Delivery Date'}
            clearable={!props.cargoUnitInstance?.locked}
            loading={loadingDeliveryDate}
            value={props.cargoUnitInstance?.estimated_delivery_date}
            filterDate={(date) => {
              return (
                date >= new Date(props.cargoUnitInstance?.estimated_ship_date)
              )
            }}
            onChange={(newValue) => {
              props.setCargoUnitInstance({
                ...props.cargoUnitInstance,
                estimated_delivery_date: newValue
                  ? moment(newValue).format('MMM DD, YYYY')
                  : '',
              })
            }}
          />
        </Form>
        <Button
          onClick={() => updateDeliveryDate()}
          color={'green'}
          disabled={props.cargoUnitInstance?.trucking_booked}
          content={'Calculate'}
          size={'mini'}
          style={{
            width: '100%',
            marginTop: '5px',
            padding: '3px',
            cursor: 'pointer',
          }}
        />
      </Grid.Column>
      <Grid.Column>
        <Header>Book Fulfillment</Header>
        {!props.cargoUnitInstance?.locked && (
          <Form className="fulfillment-edit-form">
            <MickeyDropdown
              name={'Booking Type'}
              isForm
              label={'Booking Type'}
              defaultValue={
                showManualTrucking ? 'Enter Manually' : 'Get Fulfillment Quote'
              }
              results={[
                {
                  text: 'Enter Manually',
                  value: 'Enter Manually',
                  key: 'Enter Manually',
                },
                {
                  text: 'Get Fulfillment Quote',
                  value: 'Get Fulfillment Quote',
                  key: 'Get Fulfillment Quote',
                },
              ]}
              onChange={(e, d) => {
                if (d.value === 'Enter Manually') {
                  toggleManualTrucking(true)
                } else {
                  getTruckingRate()
                  toggleManualTrucking(false)
                }
              }}
            />
          </Form>
        )}
        {showManualTrucking && (
          <div className="fulfillment-edit-form">
            <MickeyForm
              formName={'ManualTruckingForm'}
              model={'CargoUnitInstance'}
              instanceId={props.cargoUnitInstance?.id}
              tenantAware={false}
              disabled={props.cargoUnitInstance?.trucking_booked}
              successMessage={'Added!'}
              failureMessage={'Error adding, please try again.'}
              url={'/main/admin-cargo-unit-instances/'}
              onUpdate={onUpdateFunction}
            />
            <MickeyButton
              color={'green'}
              content={'Mark Fulfillment Booked'}
              loading={loading}
              disabled={props.cargoUnitInstance?.trucking_booked}
              innerStyle={{
                width: '100%',
              }}
              style={{
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => {
                markTruckingBooked()
              }}
            />
          </div>
        )}
        {!loading && !showManualTrucking && truckingQuotes?.length > 0 && (
          <Segment>
            <Grid>
              {truckingQuotes?.map((truckingQuote) => {
                return (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header>
                        {truckingQuote?.trucking_company?.name}
                        <Header.Subheader>
                          {formatTotalPrice(truckingQuote?.rate)}
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Button
                        disabled={
                          props.cargoUnitInstance?.locked ||
                          truckingQuote.trucking_company.name === 'Mickey'
                        }
                        icon={'truck'}
                        content={'Book Truck'}
                        color={'green'}
                        loading={
                          bookTruckLoading &&
                          selectedTruckingQuotesId === truckingQuote?.id
                        }
                        onClick={() => {
                          bookTruck(truckingQuote?.id)
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )
              })}
            </Grid>
            <Button
              disabled={props.cargoUnitInstance?.locked}
              color={'red'}
              content={'Reset'}
              style={{
                marginTop: '30px',
              }}
              onClick={() => {
                reset()
              }}
            />
          </Segment>
        )}
        {loading && !showManualTrucking && (
          <div className="loader-container">
            <div className="loader-trucking">
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--text"></div>
            </div>
          </div>
        )}
        {!loading &&
          !bookTruckLoading &&
          !showManualTrucking &&
          props.cargoUnitInstance?.trucking_booked &&
          props.cargoUnitInstance?.trucking_company?.id && (
            <Segment placeholder>
              <Header icon>
                <Icon name="check circle" color={'green'} />
                {props.cargoUnitInstance?.trucking_company?.name}
                <br></br>
                {formatTotalPrice(props.cargoUnitInstance?.trucking_price)}
                <Header.Subheader>
                  <b>Quote Expires:</b>{' '}
                  {props.cargoUnitInstance?.trucking_quote?.expiration_datetime}{' '}
                  UTC
                </Header.Subheader>
              </Header>
              <div style={{ color: '#4daf7c', margin: '0 auto' }}>Booked</div>
            </Segment>
          )}
      </Grid.Column>
      <Grid.Column>
        <Header>Generate Rate & Load Confirmation</Header>
        {props.cargoUnitInstance?.trucking_booked && (
          <>
            <Form className="fulfillment-edit-form">
              <MickeyDropdown
                url={'/main/admin-purchase-order/'}
                isForm={true}
                allowEmpty
                label={'Purchase Order #'}
                tenantAware={false}
                params={{
                  cargo_unit_instance_id: props.cargoUnitInstance.id,
                }}
                placeholder={'Select Purchase Order Associated to Cargo'}
                titleFields={['purchase_order_number']}
                descriptionFields={[]}
                descriptionFieldSeparator={' '}
                hideDescriptionFieldParenthesis
                description
                onChange={(e, d) => {
                  if (d.value) {
                    setPurchaseOrderId(d.value)
                  } else {
                    setPurchaseOrderId()
                  }
                }}
              />
              <MickeyDropdown
                url={'/main/admin-sales-order/'}
                isForm={true}
                label={'Sales Order #'}
                allowEmpty
                tenantAware={false}
                params={{
                  cargo_unit_instance_id: props.cargoUnitInstance.id,
                }}
                placeholder={'Select Sales Order Associated to Cargo'}
                titleFields={['sales_order_number']}
                descriptionFields={[]}
                descriptionFieldSeparator={' '}
                hideDescriptionFieldParenthesis
                description
                onChange={(e, d) => {
                  if (d.value) {
                    setSalesOrderId(d.value)
                  } else {
                    setSalesOrderId()
                  }
                }}
              />
              <label>Notes</label>
              <TextArea
                type={'text'}
                value={documentNotes}
                style={{ width: '100%' }}
                onChange={(e, d) => {
                  setDocumentNotes(d.value)
                }}
              />
            </Form>
            <div style={{ marginTop: '20px' }}>
              <Button.Group style={{ width: '100%' }}>
                <MickeyButton
                  color={'green'}
                  content={'Create'}
                  loading={loadingDocSend}
                  innerStyle={{
                    width: '100%',
                  }}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    createRateConfirmation(false)
                  }}
                />
                <MickeyButton
                  color={'green'}
                  content={'Create & Send'}
                  loading={loadingDocSend}
                  innerStyle={{
                    width: '100%',
                  }}
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    createRateConfirmation(true)
                  }}
                />
              </Button.Group>
            </div>
          </>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default CargoUnitInstance
