import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Form } from 'semantic-ui-react'
import { useFormik } from 'formik'
import useAxios from '../../../lib/auth/useAxios'
import AddNotificationTriggers from './AddNotificationTriggers'

const SMSNotificationSettings = (props) => {
  const { userId, userReceiveSMSMessages, setReloadTriggersTable } = props
  const axios = useAxios()
  const [showAddNotificationsModal, setShowAddNotificationsModal] =
    useState(false)
  const formik = useFormik({
    initialValues: {
      receiveSMSMessages: '',
    },
    validationSchema: Yup.object().shape({
      receiveSMSMessages: Yup.bool().required(
        'SMS notifications setting is required'
      ),
    }),
    validateOnChange: true,
  })

  useEffect(() => {
    if (userId && userReceiveSMSMessages !== undefined) {
      formik.setValues({
        receiveSMSMessages: userReceiveSMSMessages,
      })
    }
  }, [userId, userReceiveSMSMessages])

  useEffect(() => {
    const updateCustomUser = async () => {
      await axios.post(`/main/auto-update/`, {
        model: 'CustomUser',
        field: 'receive_sms_messages',
        value: formik.values.receiveSMSMessages,
        instance_id: userId,
      })
    }
    updateCustomUser()
  }, [formik.values.receiveSMSMessages])

  if (userId && userReceiveSMSMessages !== undefined) {
    return (
      <>
        <div className="userNotificationSettings">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <p style={{ marginTop: '10px', marginLeft: '10px' }}>
                Receive Text Messages
              </p>
              <Form.Field
                control="input"
                type="checkbox"
                name="receiveSMSMessages"
                value={formik.values.receiveSMSMessages}
                checked={formik.values.receiveSMSMessages}
                onChange={formik.handleChange}
              />
            </Form.Group>
          </Form>
          <div
            className={`supplier-step-2 buyer-step-2`}
            style={{ marginTop: '10px', marginBottom: '20px' }}
          >
            {/* <Button
                    size={'mini'}
                    float={'right'}
                    color={'green'}
                    className={'supplier-step-1 buyer-step-1'}
                    onClick={() => {
                      setShowAddNotificationsModal(true)
                  }}
                >
                    <Icon name='plus' />
                    Add Notification Trigger
                </Button> */}
          </div>
        </div>
        <AddNotificationTriggers
          userId={userId}
          setReloadTriggersTable={setReloadTriggersTable}
          showModal={showAddNotificationsModal}
          setShowModal={setShowAddNotificationsModal}
        />
      </>
    )
  } else {
    return <></>
  }
}

export default SMSNotificationSettings
