import React, { useState, useMemo } from 'react'
import { Grid, Segment, Modal, Button, Header } from 'semantic-ui-react'
import MickeyForm from '../../../components/Forms/MickeyForm'
import ESignRequestsTable from '../../../components/Documents/ESignRequestsTable'
import env from '../../../env'
import AdminOrderDocumentGenerationModal from './AdminOrderDocumentGenerationModal'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import DocumentTable from '../../../components/Documents/DocumentTable'
import { Plus } from 'phosphor-react'

function AdminOrderDocuments(props) {
  const user = useSelector((state) => state.user.user)
  const canUpdate = hasPermission(user, 'Update Document')
  const canDelete = hasPermission(user, 'Delete Document')
  const [openDocumentForm, setOpenDocumentForm] = useState(false)
  const [reload, setReload] = useState(false)
  const [showDocGenerationModal, setShowDocGenerationModal] = useState(false)
  const order = props.order
  const [activeItem, setActiveItem] = useState('Documents')
  const items = useMemo(() => {
    return {
      Documents: {},
      'Document Generation': {
        permission: order?.id,
      },
    }
  })

  return (
    <div style={{ padding: '20px' }}>
      <div className="exchange-table-heading">
        <div className="commodities-wrapper">
          {Object.keys(items)?.map((item) => {
            return !('permission' in items[item]) || items[item]?.permission ? (
              <Header
                as={'h2'}
                className={
                  activeItem === item
                    ? 'commodity-header selected'
                    : 'commodity-header'
                }
                color={activeItem === item ? 'primary' : 'black'}
                onClick={() => setActiveItem(item)}
                style={{
                  margin: '0 1rem 1rem 0',
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                }}
              >
                {item}
              </Header>
            ) : null
          })}
        </div>
      </div>

      <Segment basic>
        {activeItem === 'Documents' && (
          <>
            {canUpdate && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  // icon={<Plus size={10} weight="bold" />}
                  // labelPosition={'left'}
                  color={'primary'}
                  style={{
                    '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                    '--secondaryButtonColor':
                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    // height: '40px',
                    // fontSize: '14px',
                    // fontWeight: 'bolder',
                  }}
                  size={'small'}
                  content={'Add Document'}
                  onClick={() => setOpenDocumentForm(true)}
                />
              </div>
            )}
            <Grid.Column width={16}>
              <DocumentTable
                hideHeader
                reload={reload}
                setReload={setReload}
                orderId={props.order?.id}
                canDelete={canDelete}
                includeESignLogs
              />
            </Grid.Column>
          </>
        )}
        {activeItem === 'Document Generation' && (
          <>
            <ESignRequestsTable
              orderId={order?.id}
              style={{ marginTop: '50px' }}
              hideHeader
              discludeOrder
              generationDescription
            />
          </>
        )}
      </Segment>
      <Modal
        onClose={() => setOpenDocumentForm(false)}
        onOpen={() => setOpenDocumentForm(true)}
        open={openDocumentForm}
      >
        <Modal.Header>Add New Document</Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'DocumentUploadForm'}
            currentPortal={'admin'}
            model={'Document'}
            basicSegment
            url={'/main/documents/'}
            tenantAware={false}
            values={{
              order_id: order?.id,
              supplier_id: order?.supplier?.id,
              buyer_id: order?.buyer?.id,
            }}
            handleForm={() => {
              setReload(true)
              setOpenDocumentForm(false)
            }}
          />
        </Modal.Content>
      </Modal>
      <AdminOrderDocumentGenerationModal
        showModal={showDocGenerationModal}
        toggleModal={() => setShowDocGenerationModal(false)}
        order={order}
      />
    </div>
  )
}

export default AdminOrderDocuments
