export const ShopSettingsForm = {
  model: 'Supplier',
  format: [
    {
      widths: 'equal',
      fields: {
        description: {
          type: 'text',
          label: 'Shop Description',
        },
      },
    },
  ],
}
