import React, { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'
import _ from 'underscore'
import MickeyTable from '../../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import AdminSalesTasksRow from './AdminSalesTasksRow'

const AdminSalesTasks = (props) => {
  const { salespersonId } = props
  let breadcrumbData = useMemo(() => {
    return [
      { name: 'Admin', href: '/portal/admin/orders' },
      { name: 'Sales', href: '/portal/admin/sales' },
    ]
  })

  const headerRow = [
    'Name',
    'Order Number',
    'Buyer Name',
    'Supplier Name',
    'Trucking Name',
    'Task status',
    'Due date',
    'Action',
  ]

  const headerSort = [
    {
      header: 'Name',
      sort_by: 'name',
    },
    {
      header: 'Order Number',
      sort_by: 'task_group__order__order_number',
    },
    {
      header: 'Buyer Name',
      sort_by: 'task_group__order__buyer__business__name',
    },
    {
      header: 'Supplier Name',
      hide_sort_by: true,
    },
    {
      header: 'Trucking Name',
      hide_sort_by: true,
    },
    {
      header: 'Task status',
      hide_sort_by: true,
    },
    {
      header: 'Due date',
      sort_by: 'due_date',
    },
  ]
  const headerSearch = [
    {
      header: 'Name',
      type: 'search',
      input_type: 'text',
      search_param: 'name_icontains',
    },
    {
      header: 'Order Number',
      type: 'search',
      input_type: 'number',
      search_param: 'order_number_icontains',
    },
    {
      header: 'Buyer Name',
      type: 'dropdown',
      options_url: 'buyers/',
      options_params: { tenant_aware: false },
      options_titleFields: ['business.name'],
      search_param: 'buyer_id',
    },
    {
      header: 'Supplier Name',
      type: 'dropdown',
      options_url: 'suppliers/',
      options_params: { tenant_aware: false },
      options_titleFields: ['business.name'],
      search_param: 'supplier_id',
    },
    {
      header: 'Trucking Name',
      type: 'dropdown',
      options_url: 'list-trucking-companies/',
      options_params: { tenant_aware: false },
      options_titleFields: ['name'],
      search_param: 'trucking_id',
    },
    {
      header: 'Task status',
      type: 'dropdown',
      options: [
        { text: 'Future', value: 'Future', key: 'Future' },
        { text: 'Overdue', value: 'Overdue', key: 'Overdue' },
        { text: 'Completed', value: 'Completed', key: 'Completed' },
      ],
      search_param: 'task_status',
    },
    {
      header: 'Due date',
      type: 'date',
      search_param: 'due_date',
    },
  ]

  const urlParams = {}
  if (salespersonId) {
    urlParams.assignee_id = salespersonId
  }

  return (
    <>
      <Grid.Row className={'no-bottom-margin no-bottom-padding'}>
        <Grid.Column width={8} className={'no-bottom-margin no-bottom-padding'}>
          <PageHeader header="Tasks" breadcrumb={breadcrumbData} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={8}>
          <MickeyTable
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            url={'/main/admin-tasks/'}
            renderBodyRow={(data) => {
              return (
                <AdminSalesTasksRow
                  data={data}
                  setActiveTaskCount={props.setActiveTaskCount}
                />
              )
            }}
            emptyIcon={'list ul'}
            emptyMessage={'No Tasks'}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default AdminSalesTasks
