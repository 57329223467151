import React, { useEffect, useState } from 'react'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import NotificationTriggerRow from './NotificationTriggerRow'
import useAxios from '../../../lib/auth/useAxios'

const NotificationTriggers = (props) => {
  const axios = useAxios()
  const { userId, reload, setReload } = props
  const notificationTypeTableHeaders = [
    'Trigger',
    'Detail',
    'Price Range',
    'Location',
    'User',
    props.admin && 'Portal',
    '',
  ]
  const [notifications, setNotifications] = useState([])
  const [tableLoaded, setTableLoaded] = useState(true)
  const [totalPages, setTotalPages] = useState()
  const [activePage, setActivePages] = useState(1)

  function groupData(data) {
    const groupedDataLine = {}
    data.reduce((acc, item) => {
      const key = `${item.product_id}-${item.location_id}-${item.notification_sender_id}-${item.price_range_start}-${item.price_range_end}-${item.side}`
      if (!acc[key]) {
        acc[key] = { ...item, users: [item.user], instance_ids: [item.id] }
      } else {
        acc[key].users.push(item.user)
        acc[key].instance_ids.push(item.id)
      }
      return acc
    }, groupedDataLine)

    const groupedDataArray = Object.values(groupedDataLine)
    return groupedDataArray
  }

  useEffect(() => {
    async function fetchData() {
      setTableLoaded(false)
      let urlParams = {}
      if (!props.admin) {
        urlParams['user_id'] = userId
        urlParams['side'] = props.currentPortal === 'supplier' ? 'ask' : 'bid'
      } else if (props.admin && props.business?.id) {
        urlParams['business_id'] = props.business?.id
      }
      let res = await axios.get('/main/notification-triggers/', {
        params: {
          ...urlParams,
          page: activePage,
        },
      })
      if (res && res?.data) {
        if (props.admin) {
          let groupedDataArray = groupData(res.data.results)
          setNotifications(groupedDataArray)
        } else {
          setNotifications(res.data.results)
        }
        setTotalPages(res.data.total_pages)
        setTableLoaded(true)
      }
      setReload(false)
    }
    if (reload || !notifications?.length) {
      fetchData()
    }
  }, [reload])

  return (
    <>
      <MickeyTable
        id="mickey-table"
        headerRow={notificationTypeTableHeaders}
        unselectable={true}
        data={notifications}
        totalPages={totalPages}
        activePage={activePage}
        setActivePage={setActivePages}
        loaded={tableLoaded}
        renderBodyRow={(row, index) => {
          return (
            <NotificationTriggerRow
              is_admin={props.admin}
              row={row}
              index={index}
              setReload={setReload}
              key={row ? row.id : null}
            />
          )
        }}
        emptyIcon={'bell'}
        emptyMessage={
          'No notification triggers added. Click the button above to add some.'
        }
      />
    </>
  )
}

export default NotificationTriggers
