import React, { useState, useEffect } from 'react'
import PageHeader from '../../../components/PageHeader/PageHeader'
import BuyerOrdersRow from './BuyerOrdersRow'
import MickeyTable from '../../../components/MickeyTable/MickeyTable'
import { Steps } from 'intro.js-react'
import useAxios from '../../../lib/auth/useAxios'
import { Menu, Segment, Button } from 'semantic-ui-react'
import './BuyerOrders.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addBusiness } from '../../../features/user/userSlice'
import env from '../../../env'

function BuyerOrders(props) {
  let { tenantAware } = props
  if (tenantAware !== false) {
    tenantAware = true
  }
  const axios = useAxios()
  const business = props.business
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [runTour, setRunTour] = useState(
    !props.admin && !business?.completed_buyer_order_tour
  )
  const buyer = props.buyer
  let headerRow = [
    'Order Number',
    'PO Number',
    'Order Date',
    'Products',
    'Delivery Location',
    'Estimated Delivery Date',
    'Next Actions',
  ]
  const [activeItem, setActiveItem] = useState('pending')
  const dispatch = useDispatch()

  const tourSteps = [
    {
      element: '#step-1',
      title: 'Orders',
      intro: 'Your current orders will populate here.',
    },
  ]

  const updateFinishedPageTour = async () => {
    if (!props.admin) {
      let businessCopy = { ...business }
      businessCopy.completed_buyer_order_tour = true
      dispatch(addBusiness(businessCopy))
    }
    await axios.patch(`/main/businesses/${business?.id}/`, {
      completed_buyer_order_tour: true,
      business_id: business?.id,
    })
    setRunTour(false)
  }

  useEffect(() => {
    if (props.setCurrentTab) {
      props.setCurrentTab('orders')
    }
  }, [])

  return (
    <div id={props.admin ? '' : 'buyer-orders-container'}>
      {runTour && (
        <Steps
          enabled={runTour}
          steps={tourSteps}
          initialStep={0}
          onExit={() => {}}
          onComplete={() => updateFinishedPageTour()}
          options={{
            exitOnEsc: false,
            exitOnOverlayClick: false,
            showStepNumbers: true,
            showBullets: false,
            showProgress: true,
          }}
        />
      )}
      <div id="step-1">
        <Segment>
          <PageHeader icon={'file'} header={'Orders'} />
          <Menu secondary className={'header'}>
            <Menu.Item
              name="pending"
              as={'div'}
              className="step-1"
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              }}
              onClick={() => {
                setActiveItem('pending')
              }}
              active={activeItem === 'pending'}
            >
              Active Deals
            </Menu.Item>
            <Menu.Item
              name="completed"
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              }}
              onClick={() => {
                setActiveItem('completed')
              }}
              className="step-2"
              active={activeItem === 'completed'}
            >
              Completed Deals
            </Menu.Item>
          </Menu>
          {activeItem === 'pending' && props.buyer?.id && (
            <MickeyTable
              headerRow={headerRow}
              url={'/main/orders/'}
              reload={reload}
              setReload={setReload}
              basicSegment
              renderBodyRow={(data, index) => {
                return (
                  <BuyerOrdersRow
                    order={data}
                    index={index}
                    buyer={props.buyer}
                    setReload={setReload}
                    onClick={() => {
                      navigate(
                        props.admin
                          ? `/portal/admin/deals/${data.string_token}`
                          : `/portal/buyer/orders/${data.string_token}`
                      )
                    }}
                    key={index}
                  />
                )
              }}
              urlParams={{
                delivered: false,
                buyer_id: props.buyer?.id,
                active: true,
                tenant_aware: tenantAware,
                page_size: 10,
              }}
              emptyIcon={'file'}
              emptyMessage={'Orders will populate here'}
            />
          )}
          {activeItem === 'completed' && (
            <MickeyTable
              headerRow={headerRow}
              url={'/main/orders/'}
              basicSegment
              reload={reload}
              setReload={setReload}
              renderBodyRow={(data, index) => {
                return (
                  <BuyerOrdersRow
                    order={data}
                    index={index}
                    supplier={props.supplier}
                    buyer={props.buyer}
                    onClick={() => {
                      //
                    }}
                    key={data ? data.id : null}
                  />
                )
              }}
              urlParams={{
                buyer_id: buyer?.id,
                delivered: true,
                active: true,
                tenant_aware: tenantAware,
                page_size: 10,
              }}
              emptyIcon={'file'}
              emptyMessage={'Orders will populate here'}
            />
          )}
        </Segment>
      </div>
    </div>
  )
}

export default BuyerOrders
