import React from 'react'
import { Table } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'
import ProductIcon from '../../../components/ProductIcon/ProductIcon'

function AdminOrderBookRow(props) {
  const orderBook = props.orderBook
  const navigate = useNavigate()
  return (
    <>
      <Table.Row
        selectable={'true'}
        className={'admin-order-book-row'}
        onClick={() => {
          navigate(`/portal/admin/order-book/${orderBook.id}`)
        }}
      >
        <Table.Cell warning width={1}>
          {orderBook?.id}
        </Table.Cell>

        <Table.Cell
          width={2}
          style={{
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <ProductIcon
              productName={orderBook?.product_variant?.product?.material?.name}
              width={'25px'}
              height={'25px'}
            />
            <div
              className="product-material-name"
              style={{
                marginLeft: '10px',
                marginRight: '30px',
                display: 'inline-block',
                justifyContent: 'left',
              }}
            >
              {orderBook.product_variant?.product?.material?.name}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell
          width={3}
          style={{
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            {orderBook?.product_variant?.attribute_selections?.map(
              (attribute) => {
                return (
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '10px',
                      paddingTop: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    <p
                      style={{
                        margin: '0',
                        fontSize: '1em',
                        marginTop: '0px',
                        textAlign: 'center',
                      }}
                    >
                      {attribute?.attribute_selection_value}
                    </p>
                    <p
                      style={{
                        fontSize: '0.7em',
                        textAlign: 'center',
                      }}
                    >
                      {attribute?.attribute_name}
                    </p>
                  </div>
                )
              }
            )}
          </div>
        </Table.Cell>
        <Table.Cell width={2}>{orderBook?.bid_order_count} bids</Table.Cell>
        <Table.Cell width={2}>{orderBook?.ask_order_count} asks</Table.Cell>
        <Table.Cell width={5}></Table.Cell>
      </Table.Row>
    </>
  )
}

export default AdminOrderBookRow
