import { requiredImageFileValidator } from '../components/Forms/validators'

export const TeamMemberForm = {
  model: 'TeamMember',
  format: [
    {
      widths: 'equal',
      fields: {
        first_name: {
          type: 'text',
          required: true,
          label: 'First Name',
        },
        last_name: {
          type: 'text',
          required: true,
          label: 'Last Name',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        position: {
          type: 'text',
          required: true,
          label: 'Position',
        },
        profile: {
          type: 'file',
          required: true,
          label: 'Headshot',
          validator: (input) => {
            return requiredImageFileValidator(input)
          },
        },
      },
    },
  ],
}
