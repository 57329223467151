import {
  Table,
  Popup,
  Button,
  Grid,
  Header,
  Label,
  Divider,
} from 'semantic-ui-react'

const InventoryTableRow = (props) => {
  const {
    result,
    tenant,
    fillCurrentTruck,
    fullTruckload,
    submitButtonLoading,
    setSubmitButtonLoading,
    activeLocation,
    supplier,
    showProductDetail,
    showAddButton,
    onAddButton,
    showAddToOrderButton,
    includeSetSubmitButtonLoadingOnAddButton,
    disableSubmitLoading,
  } = props
  return (
    <Table.Row>
      <Table.Cell>{result?.product?.material.name}</Table.Cell>
      {result?.attribute_selections?.map((attribute_variation) => {
        return (
          <Table.Cell>
            {attribute_variation?.attribute_selection_value
              ? attribute_variation?.attribute_selection_value
              : 'Unspecified'}
          </Table.Cell>
        )
      })}

      {!tenant?.mickey_tenant &&
        supplier &&
        supplier?.show_product_prices_in_shop && (
          <Table.Cell textAlign={'center'}>${result?.price}</Table.Cell>
        )}
      <Table.Cell textAlign={'center'}>
        {showProductDetail && (
          <Popup
            trigger={<Button size={'tiny'}>Add To Truck</Button>}
            flowing
            hoverable
          >
            <Grid centered divided>
              <Grid.Column textAlign="center">
                <Header>{result?.product?.material.name}</Header>
                <p>
                  {result?.attribute_selections?.map((attribute_variation) => {
                    return (
                      <Label basic>
                        {attribute_variation.attribute_selection_value}
                      </Label>
                    )
                  })}
                </p>
                <Divider />
                <Button
                  onClick={() => {
                    fullTruckload(result)
                  }}
                  size={'tiny'}
                  primary
                  style={{ marginTop: '10px' }}
                >
                  Full Truckload
                </Button>
                <Button
                  onClick={() => {
                    fillCurrentTruck(result)
                  }}
                  size={'tiny'}
                  color={'green'}
                  style={{ marginTop: '10px' }}
                >
                  Fill Current Truck
                </Button>
                <Button size={'tiny'} style={{ marginTop: '10px' }}>
                  Custom Quantity
                </Button>
              </Grid.Column>
            </Grid>
          </Popup>
        )}
        {showAddButton && (
          <Button
            color={result.supplier_sells ? 'grey' : 'green'}
            disabled={result.supplier_sells}
            icon={'plus'}
            circular
            style={{
              cursor: 'pointer',
            }}
            loading={submitButtonLoading === result?.uuid}
            onClick={() => {
              if (activeLocation) {
                result.location_id = activeLocation.id
              }
              onAddButton(result)
              if (!disableSubmitLoading) {
                if (result?.uuid) {
                  setSubmitButtonLoading(result?.uuid)
                }
                if (result?.id) {
                  setSubmitButtonLoading(result?.id)
                }
              }
              result.supplier_sells = true
            }}
          ></Button>
        )}
        {showAddToOrderButton && (
          <Button
            // className={'add-inventory-button'}
            color={'green'}
            icon={'plus'}
            content={'Add Product'}
            circular
            loading={submitButtonLoading === result?.uuid}
            onClick={() => {
              if (activeLocation) {
                result.location_id = activeLocation.id
              }
              if (includeSetSubmitButtonLoadingOnAddButton) {
                onAddButton({
                  result,
                  setSubmitButtonLoading,
                })
              } else {
                onAddButton(result)
              }
              if (!disableSubmitLoading) {
                setSubmitButtonLoading(result?.uuid)
              }
            }}
          />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export default InventoryTableRow
