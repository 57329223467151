import React, { useState } from 'react'
import { Table, Modal, Button, Label } from 'semantic-ui-react'
import { formatOrderNumber } from '../../../lib/utils/utils'
import env from '../../../env'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import './BuyerOrdersRow.css'
import UploadInfo from '../../shared/UploadInfo/UploadInfo'
import ProductIcon from '../../../components/ProductIcon/ProductIcon'
import { hasPermission } from '../../../lib/utils/utils'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

function BuyerOrdersRow(props) {
  const order = props.order
  const buyer = props.buyer
  const window = useWindowDimensions()
  const user = useSelector((state) => state.user.user)
  const [openUploadInformationModal, setOpenUploadInformationModal] =
    useState(false)

  const buyerChoices = {
    1: 'Logistics Booked',
    2: 'Shipped',
    3: 'Delivered',
  }
  const buyerOrderStatus = buyerChoices?.[order.buyer_order_status]

  return (
    <>
      <Table.Row selectable={'true'}>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
        >
          {order && formatOrderNumber(order?.order_number)}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
        >
          {order.po_number === null || order.po_number === ''
            ? '--'
            : order.po_number}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
        >
          {order?.created_at}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              display: order?.order_item_count > 3 ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              width: '15px',
              height: '15px',
              backgroundColor: env.REACT_APP_PRIMARY_COLOR,
              position: 'absolute',
              borderRadius: '500px',
              top: '50%',
              right: '20px',
              color: 'white',
              padding: '11px',
              fontSize: '11px',
              transform: 'translate(0, -50%)',
            }}
          >
            {order?.order_item_count > 13
              ? '10'
              : order?.order_item_count - order?.order_items.length}
            +
          </div>
          {order?.order_items ? (
            <>
              {order?.order_items?.map((orderItem, index) => {
                return (
                  <>
                    {orderItem?.product_variant?.product?.material && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <ProductIcon
                          margin={'5px'}
                          productName={
                            orderItem?.product_variant?.product?.material?.name
                          }
                        />
                        {orderItem?.product_variant?.product?.material?.name} (
                        {
                          orderItem?.product_variant?.product?.commodity_form
                            ?.name
                        }
                        )
                        {!orderItem?.is_extra_line_item &&
                          orderItem?.product_variant &&
                          orderItem?.product_variant?.attribute_selections?.map(
                            (attribute) => {
                              return (
                                <Label
                                  style={{
                                    padding: '0 2px',
                                    margin: '2px',
                                    backgroundColor: 'transparent',
                                  }}
                                >
                                  {attribute.attribute_selection_value}
                                </Label>
                              )
                            }
                          )}
                      </div>
                    )}
                  </>
                )
              })}
            </>
          ) : (
            ''
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
        >
          {order?.delivery_address && (
            <>
              {order?.delivery_address?.name
                ? `${order?.delivery_address?.name}, `
                : ''}
              {order?.delivery_address?.address_1}{' '}
              {order?.delivery_address?.address_2}
              <br />
              {order?.delivery_address?.city}, {order?.delivery_address?.state}{' '}
              {order?.delivery_address?.zip_code}
            </>
          )}
        </Table.Cell>
        <Table.Cell
          onClick={() => {
            props.onClick()
          }}
        >
          {order?.estimated_delivery_dates?.length > 0
            ? order?.estimated_delivery_dates?.map((date) => {
                return <p>{moment(date).format('MMM DD, Y')}</p>
              })
            : order?.estimated_delivery_date}
        </Table.Cell>
        <Table.Cell textAlign="left">
          {!order?.po_number && !order?.buyer_order_status === 6 ? (
            <Button
              color="primary"
              style={{
                backgroundColor: 'transparent',
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                fontWeight: '600',
                width: '100%',
                borderRadius: '3px',
              }}
              onClick={() => {
                if (hasPermission(user, 'Update Order')) {
                  setOpenUploadInformationModal(true)
                }
              }}
            >
              {'Upload Information'}
            </Button>
          ) : (
            <Label
              style={{
                // textAlign: "center",
                width: '100%',
                lineHeight: 'inherit',
                backgroundColor: 'transparent',
                border: 'none',
                color:
                  buyerOrderStatus === 'Delivered'
                    ? '#4daf70'
                    : buyerOrderStatus === 'Cancelled'
                    ? '#FF0000'
                    : env.REACT_APP_PRIMARY_COLOR,
                fontSize: '1rem',
              }}
            >
              {buyerOrderStatus}
            </Label>
          )}
        </Table.Cell>
      </Table.Row>
      <Modal
        onOpen={() => setOpenUploadInformationModal(true)}
        onClose={() => setOpenUploadInformationModal(false)}
        open={openUploadInformationModal}
        style={{
          height: '70vh',
          width:
            window.width >= 1200
              ? '25vw'
              : window.width >= 768
              ? '45vw'
              : '90vw',
          overflow: 'scroll',
        }}
      >
        <UploadInfo
          order={order}
          buyer={buyer}
          setOpenUploadInformationModal={setOpenUploadInformationModal}
        />
      </Modal>
    </>
  )
}

export default BuyerOrdersRow
