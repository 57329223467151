export const ExtraLineOrderItemForm = {
  model: 'OrderItem',
  format: [
    {
      widths: 'equal',
      fields: {
        extra_line_item_description: {
          required: true,
          type: 'text',
          label: 'Item Description',
        },
        quantity: {
          required: true,
          type: 'number',
          step: '1',
        },
        price: {
          required: true,
          type: 'number',
          step: '0.001',
        },
        supplier_id: {
          label: 'Supplier',
          type: 'dropdown search',
          url: '/main/suppliers/',
          required: true,
          title_fields: ['business.name'],
          params: {
            tenant_aware: false,
          },
          filterField: 'q',
          description_fields: [],
        },
      },
    },
  ],
}
