import React from 'react'
import { Pagination, Table } from 'semantic-ui-react'

function FooterRow(props) {
  return (
    <Table.Row>
      <Table.HeaderCell colSpan="16">
        {props.totalPages > 1 && (
          <Pagination
            firstItem={null}
            lastItem={null}
            pointing
            secondary
            floated={'right'}
            activePage={props.activePage}
            totalPages={props.totalPages}
            onPageChange={(event, data) => {
              props.setActivePage(data.activePage)
            }}
          />
        )}
      </Table.HeaderCell>
    </Table.Row>
  )
}

export default FooterRow
