import React, { useState, useEffect, useCallback } from 'react'
import * as XLSX from 'xlsx'
import {
  Button,
  Dropdown,
  Icon,
  Header,
  Form,
  Modal,
  Loader,
  Grid,
  Input,
  Divider,
} from 'semantic-ui-react'
import './MXHeader.css'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useNavigate } from 'react-router-dom'
import { track } from '@amplitude/analytics-browser'
import { useSelector } from 'react-redux'
import useAxios from '../../../../lib/auth/useAxios'
import { useDispatch } from 'react-redux'
import { CaretDown } from 'phosphor-react'
import MickeyDropdown from '../../../../components/MickeyDropdown/MickeyDropdown'
import env from '../../../../env'
import { useWebsocket } from '../../../../hooks/useWebsocket'
import _ from 'underscore'
//Components
import { toast } from 'react-toastify'
import { addUser } from '../../../../features/user/userSlice'
import { formatTotalPrice } from '../../../../lib/utils/utils'
import { v4 as uuid } from 'uuid'
import moment from 'moment-timezone'
import './MXNewListing.css'
import CustomAttribute from './CustomAttributes/CustomAttribute'
import { hasPricing, inGroup } from '../../../../lib/utils/utils'

const MXNewListing = (props) => {
  const { ws, wsOpen, orderWsMessage } = useWebsocket()
  const currentLocation_ = useSelector((state) => state.user.current_location)
  const openStartListingModal = props.openStartListingModal
  const setStartListingModal = props.setStartListingModal
  const openSetAttributesModal = props.openSetAttributesModal
  const setOpenAttributesModal = props.setOpenAttributesModal
  const openPricingModal = props.openPricingModal
  const setOpenPricingModal = props.setOpenPricingModal
  const openReviewListingModal = props.openReviewListingModal
  const setReviewListingModal = props.setReviewListingModal
  const openTimeframeModal = props.openTimeframeModal
  const setTimeframeModal = props.setTimeframeModal
  const currentlySelectedTimeframe = props.currentlySelectedTimeframe
  const setCurrentlySelectedTimeframe = props.setCurrentlySelectedTimeframe
  const currentlySelectedTruckingType = props.currentlySelectedTruckingType
  const setCurrentlySelectedTruckingType =
    props.setCurrentlySelectedTruckingType
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user)
  const business = useSelector((state) => state.user.business)
  const globalSettings = useSelector((state) => state.globals.globalSettings)
  const tenant = useSelector((state) => state.globals.tenant)
  const axios = useAxios()
  const navigate = useNavigate()
  const [selectedProductLoading, setSelectedProductLoading] = useState(false)

  const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState(
    props.is_admin
      ? props.currentlySelectedLocation
      : user?.current_location?.id
  )
  const [attributesForSelectedProduct, setAttributesForSelectedProduct] =
    useState([])
  const [attributeSelectionsFromDropdown, setAttributeSelectionsFromDropdown] =
    useState({})
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [openCSVUploadModal, setOpenCSVUploadModal] = useState(false)
  const [loadingNextModal, setLoadingNextModal] = useState(false)
  const [uploadedFile, setUploadedFile] = useState()
  const [product, setProduct] = useState(null)
  const wd = useWindowDimensions()
  const [truckingTypeOptions, setTruckingTypesOptions] = useState([])
  const [submittingCsvOrders, setSubmittingCsvOrders] = useState(false)
  const [useCustomTally, setUseCustomTally] = useState(false)
  const [customAttributesSelected, setCustomAttributesSelected] = useState([])
  console.log(currentlySelectedLocation)
  const createOrder = () => {
    if (ws && wsOpen) {
      let orderMessage = {
        tenant_id: tenant.id,
        message_id: uuid(),
        location_id: currentlySelectedLocation,
        product_variant_ids: [props.productVariant.id],
        quantity: parseFloat(props.inputQuantity),
        cargo_unit_id: currentlySelectedTruckingType?.id,
        side: props.currentPortal === 'supplier' ? 'ask' : 'bid',
        price: props.inputPrice,
        secondary_price: props.inputSecondaryPrice,
        timeframe: currentlySelectedTimeframe,
      }
      if (props.is_admin) {
        orderMessage.user_id = props.user?.id
        orderMessage.business_id = props.business?.id
      }
      if (props.inputExpiration === 'End of Day') {
        const currentTimezone = props.is_admin
          ? props.user?.current_location?.timezone
          : user?.current_location?.timezone
        if (currentTimezone) {
          orderMessage.expires_at = moment()
            .tz(currentTimezone)
            .endOf('day')
            .toISOString()
        } else {
          orderMessage.expires_at = moment()
            .tz('US/Central')
            .endOf('day')
            .toISOString()
        }
      }
      ws.send(orderWsMessage(orderMessage))
      if (props.currentPortal === 'supplier') {
        toast.success('Sell will be created momentarily')
      } else {
        toast.success('Buy will be created momentarily')
      }
      props.setReloadHeaderStats(true)
      track('Exchange Order Created', {
        Message: orderMessage,
        'User ID': props.is_admin ? props.user?.id : user?.id,
        'Business Name': props.is_admin ? props.business?.name : business?.name,
      })
      if (props.is_admin) {
        setTimeout(() => {
          if (props.loadData) {
            props.loadData()
          }
        }, 1500)
      }
      if (!props.is_admin) {
        if (props.currentPortal === 'supplier') {
          navigate('/portal/supplier/my-asks')
        } else {
          navigate('/portal/buyer/my-bids')
        }
      }
    }
  }

  const handleClose = () => {
    // CLOSE MODALS
    setStartListingModal(false)
    setOpenAttributesModal(false)
    setOpenPricingModal(false)
    setReviewListingModal(false)
    setLoadingNextModal(false)
    setOpenCSVUploadModal(false)
    setTimeframeModal(false)

    //STATE
    setAttributeSelectionsFromDropdown({})
    setSelectedProductId(null)
    setProduct(null)
    setCurrentlySelectedLocation(null)
    setCurrentlySelectedTimeframe(null)
    setCurrentlySelectedTruckingType(null)
    props.setInputPrice(null)
    props.setInputQuantity(null)
    setUploadedFile(null)
    setUseCustomTally(false)
    setCustomAttributesSelected([])
  }

  const handleUpdateDropdownSelections = (
    attribute,
    selection,
    attributeName
  ) => {
    if (selection.id === 'custom' && customAttributesSelected.length < 1) {
      setUseCustomTally(true)
      setCustomAttributesSelected([attribute.name])
    }
    if (
      selection.id === 'custom' &&
      !customAttributesSelected.includes(attribute.name) &&
      customAttributesSelected.length >= 1
    ) {
      setCustomAttributesSelected((tally) => [...tally, attribute.name])
    }
    if (
      attribute.id !== 'custom' &&
      customAttributesSelected.includes(attribute.name) &&
      customAttributesSelected.length >= 1
    ) {
      let customAttributesSelectedCopy = [...customAttributesSelected]
      let customIndex = customAttributesSelectedCopy.indexOf(attribute.name)
      customAttributesSelectedCopy.splice(customIndex)
      setCustomAttributesSelected(customAttributesSelectedCopy)
    }
    setAttributeSelectionsFromDropdown({
      ...attributeSelectionsFromDropdown,
      [attribute.name]: {
        attributeName: attribute.name,
        selectionId: selection.id,
        selectionValue: selection.value,
        selectionValueDisplay: selection.value_display,
      },
    })
  }

  const handleUpdateCustomValues = (attributeName, customValues) => {
    let attributeSelectionsFromDropdownCopy = attributeSelectionsFromDropdown
    if (attributeSelectionsFromDropdownCopy[attributeName]) {
      attributeSelectionsFromDropdownCopy[attributeName].selectionValue =
        customValues
      setAttributeSelectionsFromDropdown(attributeSelectionsFromDropdownCopy)
    }
  }

  const getAttributes = async () => {
    updateSelectedLocationAndTimeframe()
    setLoadingNextModal(true)
    let res = await axios.get(`/main/product-filter/`, {
      params: {
        product_id: selectedProductId,
      },
    })

    if (res?.data) {
      setAttributesForSelectedProduct(res.data.attribute_selections)
      setProduct(res.data.product)
      setStartListingModal(false)
      setOpenAttributesModal(true)
    }
    setLoadingNextModal(false)
  }

  const getProductVariant = async () => {
    setLoadingNextModal(true)
    let attribute_selections = Object.values(
      attributeSelectionsFromDropdown
    ).map((attribute) => {
      return {
        selection_id: attribute.selectionId,
        selection_value: attribute.selectionValue,
        attribute_name: attribute.attributeName,
      }
    })
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        attribute_selections: attribute_selections,
        product_id: selectedProductId,
      },
    })
    if (res?.data) {
      props.setProductVariant(res.data)
      setOpenAttributesModal(false)
      setOpenPricingModal(true)
    }
    setLoadingNextModal(false)
  }

  const handlePriceInput = (price, pricing) => {
    if (price < 0) {
      toast.error('Price must be greater than 0')
      if (pricing === 'primary') {
        props.setInputPrice(null)
      } else {
        props.setInputSecondaryPrice(null)
      }
    } else if (globalSettings?.round_exchange_order_prices && price % 1 !== 0) {
      toast.error('Price must be a whole number')
      if (pricing === 'primary') {
        props.setInputPrice(null)
      } else {
        props.setInputSecondaryPrice(null)
      }
    } else if (price > 999999) {
      toast.error('Price must be less than 1000000')
      if (pricing === 'primary') {
        props.setInputPrice(null)
      } else {
        props.setInputSecondaryPrice(null)
      }
    } else if (price !== '') {
      if (pricing === 'primary') {
        props.setInputPrice(parseFloat(price))
      } else {
        props.setInputSecondaryPrice(parseFloat(price))
      }
    } else {
      if (pricing === 'primary') {
        props.setInputPrice(null)
      } else {
        props.setInputSecondaryPrice(null)
      }
    }
  }

  const handleQuantityInput = (quantity) => {
    if (quantity < 0) {
      toast.error('Quantity must be greater than 0')
      props.setInputQuantity(null)
    } else if (quantity % 1 !== 0) {
      toast.error('Quantity must be a whole number')
      props.setInputQuantity(null)
    } else if (quantity > 999999) {
      toast.error('Quantity must be less than 1000000')
      props.setInputQuantity(null)
    } else {
      props.setInputQuantity(quantity)
    }
  }

  const handleOpenReviewModal = () => {
    setLoadingNextModal(true)
    setReviewListingModal(true)
    setTimeframeModal(false)
    setLoadingNextModal(false)
  }

  const handleOpenTimeframeModal = () => {
    setLoadingNextModal(true)
    setTimeframeModal(true)
    setOpenPricingModal(false)
    setLoadingNextModal(false)
  }

  function getUserProductVariants(
    activeProductData,
    productVariantId,
    locationId,
    timeframe,
    cargoUnitId
  ) {
    for (var ask in activeProductData) {
      for (var orderBook in activeProductData[ask]['order_books']) {
        if (
          activeProductData[ask]['order_books'][orderBook][
            'product_variant_id'
          ] === productVariantId &&
          activeProductData[ask].location.id === locationId &&
          activeProductData[ask].timeframe === timeframe &&
          activeProductData[ask].cargo_unit.id === cargoUnitId
        ) {
          return true
        }
      }
    }
    return false
  }

  const handleSubmitOrder = () => {
    // make sure the users active orders is current
    props.refreshUser()
    var alreadyPresentVariants = false
    if (props.currentPortal === 'supplier') {
      alreadyPresentVariants = getUserProductVariants(
        props.is_admin ? props?.user?.active_asks : user?.active_asks,
        props.productVariant?.id,
        props.is_admin
          ? props.user?.current_location?.id
          : user?.current_location?.id,
        currentlySelectedTimeframe,
        currentlySelectedTruckingType?.id
      )
    } else if (props.currentPortal === 'buyer') {
      alreadyPresentVariants = getUserProductVariants(
        props.is_admin ? props?.user?.active_bids : user?.active_bids,
        props.productVariant?.id,
        props.is_admin
          ? props.user?.current_location?.id
          : user?.current_location?.id,
        currentlySelectedTimeframe,
        currentlySelectedTruckingType?.id
      )
    }
    if (alreadyPresentVariants) {
      toast.error(
        'You already have an order for this item! Please edit your existing bid or ask.'
      )
      return
    }
    setLoadingNextModal(true)
    createOrder()
    setLoadingNextModal(false)
    setUseCustomTally(false)
    setReviewListingModal(false)
    setAttributeSelectionsFromDropdown({})
    track('Submitted Exchange Order', {
      'Product Variant': props.productVariant,
      Price: props.inputPrice,
      Quantity: props.inputQuantity,
      'User ID': props.is_admin ? props.user?.id : user?.id,
      'Business Name': props.is_admin ? props.business?.name : business?.name,
    })
    setTimeout(() => {
      props.setReloadHeaderStats()
      props.reAuth()
      props.setInputPrice(null)
      props.setInputQuantity(null)
      if (props.loadData) {
        props.loadData()
      }
    }, 1000)
  }

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      if (event.shiftKey === true && event.ctrlKey === true) {
        if (event.key === '+') {
          setStartListingModal(true)
        }
      }
    },
    [setStartListingModal]
  )

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    const getCommodities = async () => {
      let res = await axios.get(`/main/commodities/`)
      if (res?.data?.results?.length > 0) {
        let results = res.data.results.reverse()
        if (results.length > 0) {
          props.setCommodities(results)
          props.setCommodityForms(results[0]?.commodity_forms)
          props.setSelectedCommodity(results[0])
        }
      }
    }
    getCommodities()
  }, [])

  const uploadCsvComplete = () => {
    props.setReloadHeaderStats(true)
    props.reAuth()
    handleClose()
    if (props.loadData) {
      props.loadData()
    }
    setSubmittingCsvOrders(false)
  }

  function handleUploadCSV() {
    // Creating listing from csv
    setSubmittingCsvOrders(true)
    const file = uploadedFile
    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      let product_variants = {}
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      excelData.shift()
      let orderMessage = {
        tenant_id: tenant.id,
        message_id: uuid(),
        location_id: props.is_admin
          ? props.user?.current_location?.id
          : user?.current_location?.id,
        cargo_unit_id: currentlySelectedTruckingType?.id,
        side: props.currentPortal === 'supplier' ? 'ask' : 'bid',
        timeframe: currentlySelectedTimeframe,
      }

      for (let row of excelData) {
        if (
          row.length &&
          ws &&
          wsOpen &&
          parseFloat(row[3]) > 0 &&
          parseInt(row[2]) > 0
        ) {
          product_variants[row[0]] = {
            quantity: parseFloat(row[3]),
            price: row[2],
          }
        }
      }

      if (props.is_admin) {
        orderMessage.user_id = props.user?.id
        orderMessage.business_id = props.business?.id
      }
      if (props.inputExpiration === 'End of Day') {
        const currentTimezone = props.is_admin
          ? props.user?.current_location?.timezone
          : user?.current_location?.timezone
        if (currentTimezone) {
          orderMessage.expires_at = moment()
            .tz(currentTimezone)
            .endOf('day')
            .toISOString()
        } else {
          orderMessage.expires_at = moment()
            .tz('US/Central')
            .endOf('day')
            .toISOString()
        }
      }

      if (Object.keys(product_variants)?.length > 0) {
        orderMessage.productVariants = product_variants
      }
      ws?.send(orderWsMessage(orderMessage))
      track('Exchange Order Created', {
        Message: orderMessage,
        'User ID': user?.id,
        'Business Name': business?.name,
      })
      toast.success(
        `Please wait up to ${
          excelData.length * 2
        } seconds for orders to be created`
      )
      setTimeout(() => {
        uploadCsvComplete()
      }, 2000 * excelData.length)
    }
    fileReader.readAsArrayBuffer(file)
  }

  async function downloadWatchlist() {
    const params = props.is_admin
      ? { user_id: props.user?.id, tenant_aware: false }
      : { tenant_aware: false }
    await axios
      .get('/main/export-watchlist/', {
        responseType: 'blob',
        params,
      })
      .then((res) => {
        // Extract the filename from the Content-Disposition header using a regular expression
        const filename = `MX-Watchlist-${
          props.is_admin ? props.user?.email : user?.email
        }.xlsx`
        // Create a new Blob object with the binary data and specify the MIME type
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        // Create a temporary URL for the Blob object
        const url = window.URL.createObjectURL(blob)

        // Create a temporary link element to trigger the download
        const link = document.createElement('a')
        link.href = url
        link.download = filename

        // Click the link to trigger the download
        document.body.appendChild(link)
        link.click()

        // Clean up the temporary URL and link element
        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          document.body.removeChild(link)
        }, 0)
      })
      .catch((error) => {
        toast.error('Error downloading watchlist, please contact support.')
      })
  }

  async function updateSelectedLocationAndTimeframe() {
    let res = await axios.patch(
      `/main/custom-users/${props.is_admin ? props.user?.id : user.id}/`,
      {
        current_location_id: currentlySelectedLocation,
        current_delivery_timeframe: currentlySelectedTimeframe,
        business_id: props.is_admin ? props.business?.id : business?.id,
      }
    )
    if (res?.data?.id) {
      if (props.is_admin) {
        props.setUser(res.data)
      } else {
        dispatch(addUser(res.data))
      }
      if (props.setSelectLocationModal) props.setSelectLocationModal(false)
    }
  }

  useEffect(() => {
    async function getEquipmentTypes() {
      let res = await axios.get(`/main/cargo-units/`, {
        params: {
          tenant_aware: false,
        },
      })
      if (res.data.results) {
        let results = _.map(res?.data?.results, (d) => {
          return {
            text: d['singular_name'],
            value: d['singular_name'],
            key: d['id'],
            data: d,
          }
        })
        setTruckingTypesOptions(results)
      }
    }
    if (openTimeframeModal || openCSVUploadModal) {
      getEquipmentTypes()
    }
  }, [openTimeframeModal])

  const checkNull = (attribute) => {
    if (attributeSelectionsFromDropdown.length === undefined) {
      return false
    }
    if (attributeSelectionsFromDropdown.length > 1) {
      let value = Object.keys(attributeSelectionsFromDropdown)[attribute?.name][
        'selectionId'
      ]
      if (value === undefined || value === null || value.length < 1) {
        return false
      }
    }
  }

  return (
    <div
      style={{
        width: '100%',
        position:
          window &&
          !window.location.pathname.startsWith('/portal') &&
          !window.location.pathname.includes('reset-password') &&
          !window.location.pathname.startsWith('/products') &&
          !window.location.pathname.includes('login') &&
          !window.location.pathname.startsWith('/payment') &&
          wd.width <= 950 &&
          'fixed',
        zIndex:
          window &&
          !window.location.pathname.startsWith('/portal') &&
          !window.location.pathname.includes('reset-password') &&
          !window.location.pathname.startsWith('/products') &&
          !window.location.pathname.includes('login') &&
          !window.location.pathname.startsWith('/payment') &&
          wd.width <= 950 &&
          '200',
        top:
          window &&
          !window.location.pathname.startsWith('/portal') &&
          !window.location.pathname.includes('reset-password') &&
          !window.location.pathname.startsWith('/products') &&
          !window.location.pathname.includes('login') &&
          !window.location.pathname.startsWith('/payment') &&
          wd.width <= 950 &&
          '0',
      }}
    >
      <Modal
        id="start-listing-modal"
        onOpen={() => {
          setStartListingModal(true)
          track('Add Exchange Order Page 1', {
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={openStartListingModal}
        size="mini"
        style={{
          minHeight: '500px',
          padding: '2rem',
          display: 'flex !important',
        }}
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Start Your {props.currentPortal === 'supplier' ? 'Sell' : 'Buy'}{' '}
              Listing
            </Header>
            <Header.Subheader style={{ textAlign: 'center' }}>
              Search for a product below to get started
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              justifyContent: 'center',
              overflow: 'visible',
              width: '100%',
            }}
          >
            <Button
              basic
              primary
              onClick={() => {
                setStartListingModal(false)
                setOpenCSVUploadModal(true)
              }}
              content={'Upload From CSV'}
            />
            <Divider horizontal>Or</Divider>
            <label>Select Location</label>
            <MickeyDropdown
              url={'/main/locations/'}
              name={'locations'}
              autoComplete="new-password"
              label={'Select Location'}
              params={{
                business_id: props.is_admin ? props.business?.id : business?.id,
                page_size: 25,
              }}
              tenantAware={false}
              placeholder={'Select Address'}
              titleFields={['address_1']}
              descriptionFields={['city', 'state', 'zip_code']}
              hideDescriptionFieldParenthesis
              style={{
                margin: '15px 0 20px 0',
                zIndex: '1003',
              }}
              onChange={(e, d) => {
                setCurrentlySelectedLocation(d.value)
              }}
            />
            <label>Select Product</label>
            <MickeyDropdown
              url="/main/products/"
              placeholder={'Select Product'}
              style={{
                zIndex: '1003',
                margin: '15px 0 20px 0',
              }}
              params={{
                page_size: 25,
              }}
              titleFields={['material.name']}
              descriptionFields={['commodity_form.name', 'commodity.name']}
              hideDescriptionFieldParenthesis
              onChange={(e, d) => {
                setSelectedProductId(d.value)
              }}
              autoFocus
              loading={selectedProductLoading}
              setLoading={setSelectedProductLoading}
            />
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              color="primary"
              onClick={() => getAttributes()}
              disabled={!(selectedProductId && currentlySelectedLocation)}
            >
              {loadingNextModal && <Loader size="tiny" inline="centered" />}
              {!loadingNextModal && 'Next'}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => {
          setOpenAttributesModal(true)
          track('Add Exchange Order Page 2', {
            Product: product,
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={openSetAttributesModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Select Attributes
            </Header>
            <Header.Subheader style={{ textAlign: 'center' }}>
              For {product && product?.material.name}{' '}
              {product && product.commodity_form.name}
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              overflow: 'visible',
            }}
          >
            {attributesForSelectedProduct?.map((attribute, index) => {
              return (
                <div key={index}>
                  <label style={{ margin: '5px' }}>{attribute.name}</label>
                  <Dropdown
                    label={attribute.name}
                    style={{
                      marginBottom: '1rem',
                    }}
                    placeholder={`Select ${attribute.name}`}
                    selection
                    fluid
                    text={
                      attributeSelectionsFromDropdown[attribute.name]
                        ? attributeSelectionsFromDropdown[attribute.name]
                            .selectionValueDisplay
                          ? attributeSelectionsFromDropdown[attribute.name]
                              .selectionValueDisplay
                          : 'Unspecified'
                        : ''
                    }
                    options={_.sortBy(
                      attribute?.attribute_selections,
                      'order'
                    ).map((selection, idx) => {
                      return (
                        <Dropdown.Item
                          key={idx}
                          value={selection.value}
                          onClick={() =>
                            handleUpdateDropdownSelections(
                              attribute,
                              selection,
                              attribute.name
                            )
                          }
                        >
                          {selection.value_display
                            ? selection.value_display
                            : 'Unspecified'}
                        </Dropdown.Item>
                      )
                    })}
                  />
                  {useCustomTally &&
                    customAttributesSelected.includes(attribute.name) && (
                      <CustomAttribute
                        attributeName={attribute.name}
                        product={product}
                        handleUpdateCustomValues={handleUpdateCustomValues}
                      />
                    )}
                </div>
              )
            })}
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              color="primary"
              onClick={async () => await getProductVariant()}
              disabled={
                Object.keys(attributeSelectionsFromDropdown).length !==
                  attributesForSelectedProduct?.length &&
                attributesForSelectedProduct.map((attribute) =>
                  checkNull(attribute)
                )?.length > 0
              }
            >
              {loadingNextModal && <Loader size="tiny" inline="centered" />}
              {!loadingNextModal && 'Next'}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => {
          setOpenPricingModal(true)
          track('Add Exchange Order Page 3', {
            'Product Variant': props.productVariant,
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={openPricingModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Price & Quantity
            </Header>
            <Header.Subheader
              as={'h4'}
              style={{
                textAlign: 'center',
                marginTop: '0',
                marginBottom: '.5rem',
              }}
            >
              {props.productVariant &&
                props.productVariant?.product?.material?.name}
              {', '}
              {props.productVariant &&
                props.productVariant.attribute_selections.map(
                  (attribute, index) => {
                    return attribute.attribute_selection_value + index !==
                      props.productVariant.attribute_selections.length - 1
                      ? ', '
                      : ''
                  }
                )}
            </Header.Subheader>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              overflow: 'visible',
              justifyContent: 'center',
            }}
          >
            <Grid style={{ padding: '2rem 0 0 0' }}>
              <Grid.Column>
                {props.currentPortal === 'buyer' &&
                  hasPricing(user, 'primary') &&
                  !inGroup(user, 'Buyer') && (
                    <>
                      <Header as={'h4'} textAlign="center">
                        Primary Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          defaultValue={props.inputPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            handlePriceInput(d.value, 'primary')
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {props.productVariant?.unit_name
                            ? props.productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                      <Divider />
                      <Header as={'h4'} textAlign="center">
                        Secondary Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          defaultValue={props.inputSecondaryPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            handlePriceInput(d.value, 'secondary')
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {props.productVariant?.unit_name
                            ? props.productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                    </>
                  )}
                {(props.currentPortal === 'supplier' ||
                  (props.currentPortal === 'buyer' &&
                    hasPricing(user, 'primary') &&
                    inGroup(user, 'Buyer'))) && (
                  <>
                    <Header as={'h4'} textAlign="center">
                      Your Price
                    </Header>
                    <div style={{ position: 'relative' }}>
                      <Input
                        icon={
                          <Icon
                            name="dollar"
                            size="big"
                            style={{ width: '20px' }}
                          />
                        }
                        defaultValue={props.inputPrice}
                        iconPosition={'left'}
                        className="price-modal-input"
                        type={'number'}
                        onChange={(e, d) => {
                          handlePriceInput(d.value, 'primary')
                        }}
                        step={'1'}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          color: 'grey',
                          right: '0',
                          top: '0',
                          bottom: '0',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '1rem',
                        }}
                      >
                        /
                        {props.productVariant?.unit_name
                          ? props.productVariant?.unit_name
                          : 'MBF'}
                      </div>
                    </div>
                  </>
                )}
                {props.currentPortal === 'buyer' &&
                  !hasPricing(user, 'primary') && (
                    <>
                      <Header as={'h4'} textAlign="center">
                        Your Price
                      </Header>
                      <div style={{ position: 'relative' }}>
                        <Input
                          icon={
                            <Icon
                              name="dollar"
                              size="big"
                              style={{ width: '20px' }}
                            />
                          }
                          defaultValue={props.inputSecondaryPrice}
                          iconPosition={'left'}
                          className="price-modal-input"
                          type={'number'}
                          onChange={(e, d) => {
                            handlePriceInput(d.value, 'secondary')
                          }}
                          step={'1'}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            color: 'grey',
                            right: '0',
                            top: '0',
                            bottom: '0',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '1rem',
                          }}
                        >
                          /
                          {props.productVariant?.unit_name
                            ? props.productVariant?.unit_name
                            : 'MBF'}
                        </div>
                      </div>
                    </>
                  )}
                <Divider />
                <Header as={'h4'} textAlign="center">
                  Quantity
                </Header>
                <div style={{ position: 'relative' }}>
                  <Input
                    icon={
                      <Icon name="truck" size="big" style={{ width: '20px' }} />
                    }
                    defaultValue={props.inputQuantity}
                    iconPosition={'left'}
                    className="price-modal-input"
                    type={'number'}
                    onChange={(e, d) => {
                      handleQuantityInput(d.value)
                    }}
                    step={1}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      color: 'grey',
                      right: '0',
                      top: '0',
                      bottom: '0',
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: '1rem',
                    }}
                  >
                    Trucks
                  </div>
                </div>
                <Divider />
                <Header as={'h4'} textAlign="center">
                  Expiration
                </Header>
                <div style={{ position: 'relative', overflow: 'visible' }}>
                  <Dropdown
                    id="expiration-dropdown"
                    icon={<CaretDown style={{ float: 'right' }} />}
                    placeholder="Select Expiration"
                    fluid
                    selection
                    defaultValue={props.inputExpiration}
                    style={{
                      marginBottom: '1rem',
                      maxWidth: '100%',
                      zIndex: '1001',
                    }}
                    options={[
                      {
                        text: 'End of Day',
                        value: 'End of Day',
                      },
                      {
                        text: 'Good Till Cancelled',
                        value: 'Good Till Cancelled',
                      },
                    ]}
                    onChange={(e, d) => props.setInputExpiration(d.value)}
                  />
                </div>
                <Divider />
              </Grid.Column>
            </Grid>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              style={{
                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                display: 'flex',
                justifyContent: 'center',
              }}
              color="primary"
              onClick={() => {
                if (
                  currentlySelectedTimeframe &&
                  currentlySelectedTruckingType?.id
                ) {
                  setOpenPricingModal(false)
                  handleOpenReviewModal()
                } else {
                  handleOpenTimeframeModal()
                }
              }}
              disabled={
                (!props.inputPrice && !props.inputSecondaryPrice) ||
                !props.inputQuantity ||
                !props.inputExpiration
              }
            >
              {loadingNextModal && <Loader size="tiny" inline="centered" />}
              {!loadingNextModal && 'Next'}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => {
          setTimeframeModal(true)
          track('Add Exchange Order Page 4', {
            'Product Variant': props.productVariant,
            Price: props.inputPrice,
            Quantity: props.inputQuantity,
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={openTimeframeModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Select Timeframe & Trucking Type
            </Header>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              justifyContent: 'center',
              overflow: 'visible',
              width: '100%',
            }}
          >
            <label>Select Timeframe</label>
            <Dropdown
              id="mickey-dropdown"
              fluid
              search
              selection
              placeholder={'Select Timeframe'}
              defaultValue={currentlySelectedTimeframe}
              style={{
                margin: '15px 0 20px 0',
              }}
              options={[
                {
                  text: 'Prompt',
                  value: 'Prompt',
                },
                {
                  text: '2-4 Weeks',
                  value: '2-4 Weeks',
                },
              ]}
              onChange={(e, d) => {
                setCurrentlySelectedTimeframe(d.value)
              }}
            />
            <label>Select Trucking Type</label>
            <Dropdown
              id="mickey-dropdown"
              fluid
              search
              selection
              loading={!truckingTypeOptions}
              placeholder={'Select Trucking Type'}
              defaultValue={currentlySelectedTruckingType?.singular_name}
              style={{
                margin: '15px 0 20px 0',
              }}
              options={truckingTypeOptions}
              onChange={(e, d) => {
                let selectedOptionData = truckingTypeOptions?.find(
                  (option) => option.value === d.value
                )?.data
                setCurrentlySelectedTruckingType(selectedOptionData)
              }}
            />
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  display: 'flex',
                  flex: '0 1 100%',
                  justifyContent: 'center',
                }}
                color="primary"
                disabled={
                  !(currentlySelectedTimeframe && currentlySelectedTruckingType)
                }
                onClick={() => handleOpenReviewModal()}
              >
                {submittingCsvOrders && (
                  <Loader size="tiny" inline="centered" />
                )}
                {!submittingCsvOrders && <>Next</>}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => {
          setReviewListingModal(true)
          track('Add Exchange Order Page 4', {
            'Product Variant': props.productVariant,
            Price: props.inputPrice,
            Quantity: props.inputQuantity,
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => handleClose()}
        open={openReviewListingModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Review Your {props.currentPortal === 'supplier' ? 'Sell' : 'Buy'}{' '}
              Listing
            </Header>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              overflow: 'scroll',
              justifyContent: 'flex-start',
            }}
          >
            <Grid>
              <Grid.Column>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <p style={{ fontSize: '15px', margin: '0', width: '35%' }}>
                    <strong>Item:</strong>
                  </p>
                  <p style={{ fontSize: '15px', margin: '0', width: '65%' }}>
                    {props.productVariant &&
                      props.productVariant?.product?.material?.name}
                    {', '}
                    {props.productVariant &&
                      props.productVariant.attribute_selections.map(
                        (attribute, index) => {
                          return attribute.attribute_selection_value + index !==
                            props.productVariant.attribute_selections.length - 1
                            ? ', '
                            : ''
                        }
                      )}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <p style={{ fontSize: '15px', margin: '0', width: '35%' }}>
                    <strong>Your Offer:</strong>
                  </p>
                  <p style={{ fontSize: '15px', margin: '0', width: '65%' }}>
                    {props.inputPrice && props.inputSecondaryPrice && (
                      <>
                        Primary {formatTotalPrice(props.inputPrice)} /{' '}
                        {props.productVariant?.unit_name
                          ? props.productVariant?.unit_name
                          : 'MBF'}
                        Secondary {formatTotalPrice(props.inputSecondaryPrice)}{' '}
                        /{' '}
                        {props.productVariant?.unit_name
                          ? props.productVariant?.unit_name
                          : 'MBF'}
                      </>
                    )}
                    {props.inputPrice && !props.inputSecondaryPrice && (
                      <>
                        {formatTotalPrice(props.inputPrice)} /{' '}
                        {props.productVariant?.unit_name
                          ? props.productVariant?.unit_name
                          : 'MBF'}
                      </>
                    )}
                    {!props.inputPrice && props.inputSecondaryPrice && (
                      <>
                        {formatTotalPrice(props.inputSecondaryPrice)} /{' '}
                        {props.productVariant?.unit_name
                          ? props.productVariant?.unit_name
                          : 'MBF'}
                      </>
                    )}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <p style={{ fontSize: '15px', margin: '0', width: '35%' }}>
                    <strong>Quantity:</strong>
                  </p>
                  <p style={{ fontSize: '15px', margin: '0', width: '65%' }}>
                    {props.inputQuantity}{' '}
                    {props.inputQuantity > 1
                      ? currentlySelectedTruckingType?.plural_name
                      : currentlySelectedTruckingType?.singular_name}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <p style={{ fontSize: '15px', margin: '0', width: '35%' }}>
                    <strong>Expiration:</strong>
                  </p>
                  <p style={{ fontSize: '15px', margin: '0', width: '65%' }}>
                    {props.inputExpiration}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    marginBottom: '1rem',
                  }}
                >
                  <p style={{ fontSize: '15px', margin: '0', width: '35%' }}>
                    <strong>Timeframe:</strong>
                  </p>
                  <p style={{ fontSize: '15px', margin: '0', width: '65%' }}>
                    {currentlySelectedTimeframe}
                  </p>
                </div>
                <Divider />
              </Grid.Column>
            </Grid>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  display: 'flex',
                  flex: '0 1 100%',
                  justifyContent: 'center',
                }}
                color="primary"
                onClick={() => handleSubmitOrder()}
              >
                {loadingNextModal && <Loader size="tiny" inline="centered" />}
                {!loadingNextModal &&
                  'Submit ' +
                    (props.currentPortal === 'supplier' ? 'Sell' : 'Buy')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => {
          setReviewListingModal(true)
          track('Add Exchange Order Page 4', {
            'Product Variant': props.productVariant,
            Price: props.inputPrice,
            Quantity: props.inputQuantity,
            'User ID': user?.id,
            'Business Name': props.is_admin
              ? props.business?.name
              : business?.name,
          })
        }}
        onClose={() => setOpenCSVUploadModal(false)}
        open={openCSVUploadModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
        closeIcon={true}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Upload from CSV
              <Header.Subheader>
                First, add any products that you would like to upload or edit to
                your watchlist. Then, download your watchlist CSV below and edit
                your current prices and quantities. Lastly, upload the new CSV
                file below.
              </Header.Subheader>
            </Header>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              overflow: 'scroll',
              justifyContent: 'flex-start',
            }}
          >
            <Grid>
              <Grid.Column>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '0 1 65%',
                    justifyContent: 'center',
                    overflow: 'visible',
                    width: '100%',
                  }}
                >
                  <Button
                    basic
                    primary
                    onClick={() => {
                      downloadWatchlist()
                    }}
                    content={'Download Watchlist'}
                    style={{ margin: '1rem 0' }}
                  />
                  <Form>
                    <Form.Field>
                      <label>Upload File</label>
                      <Input
                        placeholder={'Watchlist File'}
                        name={'watchlist_file'}
                        type={'file'}
                        onChange={(event) => {
                          setUploadedFile(event.target.files[0])
                        }}
                      />
                    </Form.Field>
                  </Form>
                </div>
                <Divider />
              </Grid.Column>
            </Grid>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              justifyContent: 'center',
              overflow: 'visible',
              width: '100%',
            }}
          >
            <label>Select Location</label>
            <MickeyDropdown
              url={'/main/locations/'}
              name={'locations'}
              autoComplete="new-password"
              label={'Select Location'}
              params={{
                business_id: props.is_admin ? props.business?.id : business?.id,
              }}
              tenantAware={false}
              placeholder={'Select Address'}
              titleFields={['address_1']}
              descriptionFields={['city', 'state', 'zip_code']}
              style={{
                margin: '15px 0 20px 0',
                zIndex: '1003',
              }}
              onChange={(e, d) => {
                setCurrentlySelectedLocation(d.value)
              }}
            />
            <label>Select Timeframe</label>
            <Dropdown
              id="mickey-dropdown"
              fluid
              search
              selection
              placeholder={'Select Timeframe'}
              style={{
                margin: '15px 0 20px 0',
              }}
              options={[
                {
                  text: 'Prompt',
                  value: 'Prompt',
                },
                {
                  text: '2-4 Weeks',
                  value: '2-4 Weeks',
                },
              ]}
              onChange={(e, d) => {
                setCurrentlySelectedTimeframe(d.value)
              }}
            />
            <label>Select Trucking Type</label>
            <Dropdown
              id="mickey-dropdown"
              fluid
              search
              selection
              loading={!truckingTypeOptions}
              placeholder={'Select Trucking Type'}
              style={{
                margin: '15px 0 20px 0',
              }}
              options={truckingTypeOptions}
              onChange={(e, d) => {
                let selectedOptionData = truckingTypeOptions?.find(
                  (option) => option.value === d.value
                )?.data
                setCurrentlySelectedTruckingType(selectedOptionData)
              }}
            />
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  display: 'flex',
                  flex: '0 1 100%',
                  justifyContent: 'center',
                }}
                color="primary"
                onClick={() => handleUploadCSV()}
              >
                {submittingCsvOrders && (
                  <Loader size="tiny" inline="centered" />
                )}
                {!submittingCsvOrders && <>Upload</>}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default MXNewListing
