import { Modal, Button } from 'semantic-ui-react'
import env from '../env'

const ConfirmationModal = (props) => {
  //props.archiveItem should be passed as a string.

  return (
    <>
      <Modal
        onClose={() => props.setConfirmationModal(false)}
        onOpen={() => props.setConfirmationModal(true)}
        open={props.confirmationModal}
        size={'mini'}
      >
        <Modal.Header>
          {' '}
          {props.message
            ? props.message
            : `Are you sure want to archive ${props.archiveItem}?`}
        </Modal.Header>
        <Modal.Actions>
          <Button onClick={props.noFunction}>No</Button>
          <Button
            color={'primary'}
            onClick={props.yesFunction}
            loading={props.loading}
            style={{
              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
              '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
            }}
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ConfirmationModal
