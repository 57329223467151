import React, { useState, useEffect, useCallback } from 'react'
import {
  X,
  WarningCircle,
  ArrowRight,
  Circle,
  CheckCircle,
  MagnifyingGlass,
} from 'phosphor-react'
import {
  Button,
  Header,
  Menu,
  Modal,
  Sidebar,
  Popup,
  Dimmer,
  Grid,
  Divider,
  Label,
  PopupContent,
} from 'semantic-ui-react'
import './MXHeader.css'
import env from '../../../../env'
import article from '../../../../img/article.svg'
import newspaperClipping from '../../../../img/newspaper-clipping.svg'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useNavigate, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useAxios from '../../../../lib/auth/useAxios'
import { useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import {
  User,
  Bell,
  Receipt,
  MapPin,
  Storefront,
  CaretDown,
  Funnel,
  SlidersHorizontal,
  Gear,
  UserCircle,
  Package,
} from 'phosphor-react'
import MickeyDropdown from '../../../../components/MickeyDropdown/MickeyDropdown'
import { Squash as Hamburger } from 'hamburger-react'
import MickeyForm from '../../../../components/Forms/MickeyForm'
import MickeySearch from '../../../../components/Search/MickeySearch'
import { useWebsocket } from '../../../../hooks/useWebsocket'
import { Fade } from 'hamburger-react'
//Components
import MXTicker from './MXTicker'
import { toast } from 'react-toastify'
import {
  addUser,
  loggedOut,
  removeBusiness,
  removeUser,
} from '../../../../features/user/userSlice'
import { useAuth } from '../../../../lib/auth/useAuth'
import MXNewListing from './MXNewListing'
import { MXNewLocation } from './MXHeaderPopups'
import { hasPortalAccess, hasPermission } from '../../../../lib/utils/utils'

const MXHeader = (props) => {
  const { disconnectWs } = useWebsocket()
  const hideFilter = props.hideFilter
  const setStartListingModal = props.setStartListingModal
  const notifications = props.notifications
  const publicLocation = props.publicLocation
  const setPublicLocation = props.setPublicLocation
  const setSelectedTimeframe = props.setSelectedTimeframe
  const user = props.user
  const setUser = props.setUser
  const publicExchange = props.publicExchange
  const setPublicExchange = props.setPublicExchange
  const dispatch = useDispatch()
  const [globalSettings, setGlobalSettings] = useState()
  const reduxUser = useSelector((state) => state.user.user)
  const business = reduxUser?.business
  const axios = useAxios()
  const navigate = useNavigate()
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [profileModalOpen, setProfileModalOpen] = useState(false)
  const [addNewLocationModal, setAddNewLocationModal] = useState(false)
  const [resourceDropdownVisible, setResourceDropdownVisible] = useState(false)
  const auth = useAuth()
  const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState(
    user?.current_location?.id
  )
  const [dim, setDim] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectLocationModal, setSelectLocationModal] = useState(null)
  const [loading, setLoading] = useState(null)
  const [openMobileLocationMenu, setOpenMobileLocationMenu] = useState(false)
  const [locations, setLocations] = useState([])
  const [searchProductData, setSearchProductData] = useState([])

  const wd = useWindowDimensions()

  const [searchFocus, setSearchFocus] = useState(false)
  const [value, setValue] = useState(null)
  const [searchValue, setSearchValue] = useState(false)
  const [showLocationForm, setShowLocationForm] = useState(false)
  const [editLocation, setEditLocation] = useState(false)
  const [openLocationPopup, setOpenLocationPopup] = useState(false)
  const currentPath = window?.location?.pathname
  // wd.width not getting correct width if page is not responsive so added this.
  const pagewidth = document.body.clientWidth

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      if (event.shiftKey === true && event.ctrlKey === true) {
        if (event.key === '+') {
          setStartListingModal(true)
        }
      }
    },
    [setStartListingModal]
  )

  useEffect(() => {
    if (publicExchange && !window?.location?.pathname.includes('exchange')) {
      setPublicExchange(false)
    }
  }, [window?.location?.pathname, publicExchange, setPublicExchange])

  useEffect(() => {
    const getGlobalSettings = async () => {
      const resp = await axios.get('main/global-setting/')
      if (resp?.data?.results) {
        setGlobalSettings(resp?.data?.results[0])
      }
    }
    getGlobalSettings()
  }, [])

  const handleProductResponse = async (data, q) => {
    let newdata = [...data]
    if (data?.length === 0) {
      let res = await axios.get('/main/inventory/', {
        params: {
          q: q,
        },
      })
      if (res?.data) {
        res?.data?.results?.forEach((result) => {
          let obj = {}
          obj.title = result?.product?.material?.name
          result?.attribute_selections?.forEach((attr_selection) => {
            obj.title = `${obj.title} ${attr_selection?.attribute_selection_value}`
          })
          obj.extraFields = {
            commodity: result?.product?.commodity,
            commodity_form: result?.product?.commodity_form,
            slug: result?.product?.slug,
            selection_ids: result?.attribute_selections?.map(
              (attr) => attr?.attribute_selection_id
            ),
            product_id: result?.product?.id,
          }
          newdata.push(obj)
        })
      }
    }
    setSearchProductData(newdata)
  }
  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  useEffect(() => {
    const getCommodities = async () => {
      let res = await axios.get(`/main/commodities/`)
      if (res?.data?.results?.length > 0) {
        let results = res.data.results.reverse()
        if (results.length > 0) {
          props.setCommodities(results)
          props.setCommodityForms(results[0]?.commodity_forms)
          props.setSelectedCommodity(results[0])
        }
      }
    }
    if (props.currentPortal !== 'admin') {
      getCommodities()
    }
  }, [props.currentPortal])

  const refreshUser = async () => {
    let res = await axios.get(`/main/custom-users/${user.id}/`)
    if (res.data.id) {
      dispatch(addUser(res?.data))
    }
  }

  async function updateSelectedLocation() {
    if (user) {
      console.log('hihih')
      let res = await axios.patch(`/main/custom-users/${user.id}/`, {
        current_location_id: currentlySelectedLocation,
        business_id: business?.id,
      })
      if (res?.data?.id) {
        toast.success('Location Updated')
        dispatch(addUser(res.data))
        setSelectLocationModal(false)
      } else {
        toast.error('Error updating location, please contact support.')
      }
    }
  }

  const variants = {
    open: {
      opacity: 1,
      height: 'fit-content',
      zIndex: '101',
      overflow: 'hidden',
    },
    closed: { opacity: 1, height: '0px', overflow: 'hidden', zIndex: '101' },
  }

  async function getLocations() {
    let res = await axios.get(`/main/locations/`, {
      params: {
        business_id: business?.id,
        tenant_aware: false,
        page_size: 'infinite',
      },
    })
    if (res?.data?.results) {
      setLocations(res.data.results)
    }
  }

  useEffect(() => {
    if (business && user) {
      getLocations()
    }
  }, [business?.id])

  const updatePublicLocation = async (locationPlaceId) => {
    let res = await axios.post(`/main/p-locations/`, {
      tenant_aware: false,
      place_id: locationPlaceId,
    })
    if (res.data) {
      localStorage.setItem('publicLocation', res.data.id)
      setPublicLocation(res?.data)
      props.setCurrentSelectedLocation(res?.data)
      let userCopy = { ...user }
      userCopy.current_location = res?.data
      setUser(userCopy)
    }
  }

  useEffect(() => {
    if (props.currentTab === 'my-bids') {
      props.setCurrentSelectedLocation({ id: 0 })
    } else {
      props.setCurrentSelectedLocation(user?.current_location)
    }
  }, [props.currentTab])

  useEffect(() => {
    if (value !== null && value !== '') {
      setShowLocationForm(true)
    }
  }, [value])

  useEffect(() => {
    if (
      currentlySelectedLocation &&
      currentlySelectedLocation !== user?.current_location?.id
    ) {
      updateSelectedLocation()
    }
  }, [currentlySelectedLocation])

  const getProductVariant = async (product_id, selection_ids) => {
    let res = await axios.get(`/main/product-variant-from-attributes/`, {
      params: {
        selection_ids: selection_ids,
        product_id: product_id,
      },
    })
    if (res?.data) {
      return res?.data
    }
  }

  const handleNavigateFromSearch = async (data) => {
    if (user?.id) {
      if (data?.selection_ids) {
        let pv = await getProductVariant(data?.product_id, data?.selection_ids)
        navigate(
          `/portal/${props.currentPortal}/${data.commodity.slug}/${data.commodity_form.slug}/${data.slug}/${pv?.slug}/`
        )
      } else {
        navigate(
          `/portal/${props.currentPortal}/${data.commodity.slug}/${data.commodity_form.slug}/${data.slug}`
        )
      }
    } else {
      if (data?.selection_ids) {
        let pv = await getProductVariant(data?.product_id, data?.selection_ids)
        navigate(
          `/exchange/${data.commodity.slug}/${data.commodity_form.slug}/${data.slug}/${pv?.slug}/`
        )
      } else {
        navigate(
          `/exchange/${data.commodity.slug}/${data.commodity_form.slug}/${data.slug}`
        )
      }
    }
  }

  useEffect(() => {
    if (wd.width >= 1237) {
      setOpenMobileMenu(false)
    }
  }, [wd.width])


  return (
    <div
      className={'header-container'}
      style={{
        width: '100%',
        position:
          window &&
          !window?.location?.pathname?.startsWith('/portal') &&
          !window?.location?.pathname?.includes('reset-password') &&
          !window?.location?.pathname?.startsWith('/products') &&
          !window?.location?.pathname?.includes('login') &&
          !window?.location?.pathname?.startsWith('/payment') &&
          wd.width <= 950 &&
          'fixed',
        zIndex:
          window &&
          !window?.location?.pathname?.startsWith('/portal') &&
          !window?.location?.pathname?.includes('reset-password') &&
          !window?.location?.pathname?.startsWith('/products') &&
          !window?.location?.pathname?.includes('login') &&
          !window?.location?.pathname?.startsWith('/payment') &&
          wd.width <= 950 &&
          '200',
        top:
          window &&
          !window?.location?.pathname?.startsWith('/portal') &&
          !window?.location?.pathname?.includes('reset-password') &&
          !window?.location?.pathname?.startsWith('/products') &&
          !window?.location?.pathname?.includes('login') &&
          !window?.location?.pathname?.startsWith('/payment') &&
          wd.width <= 950 &&
          '0',
      }}
    >
      {window &&
        window?.location &&
        window?.location?.pathname &&
        !window.location.pathname.startsWith('/portal') &&
        !window.location.pathname.startsWith('/exchange') &&
        !window.location.pathname.includes('reset-password') &&
        !window.location.pathname.startsWith('/products') &&
        !window.location.pathname.includes('login') &&
        !window.location.pathname.startsWith('/payment') && <MXTicker />}
      <div>
        {window &&
          window?.location &&
          window?.location?.pathname &&
          (window.location.pathname.startsWith('/portal') ||
            window.location.pathname.startsWith('/commodities') ||
            window.location.pathname.startsWith('/exchange')) &&
          !window.location.pathname.startsWith('/portal/admin') &&
          !window.location.pathname.startsWith('/payment') && (
            <>
              <header>
                {props.currentPortal && (
                  <p
                    style={{
                      textAlign: 'right',
                      color:
                        props.currentPortal === 'supplier'
                          ? env.REACT_APP_PRIMARY_COLOR
                          : props.currentPortal === 'buyer'
                          ? '#4FAA80'
                          : '',
                      fontSize: '12px',
                      padding: '6px 12px',
                      margin: 0,
                    }}
                  >
                    {props.currentPortal === 'supplier'
                      ? 'Supplier'
                      : props.currentPortal === 'buyer'
                      ? 'Buyer'
                      : ''}{' '}
                    |{' '}
                    <span style={{ fontWeight: 'Bold' }}>
                      {props.is_admin
                        ? props.user?.business?.name
                        : user?.business?.name}
                    </span>
                  </p>
                )}
                <div id="exchange-header">
                  <div className="mobile-exchange-header-inner-wrapper">
                    <MickeySearch
                      icon={
                        <Button
                          onClick={() =>
                            props.setOpenExchangeFilter(
                              !props.openExchangeFilter
                            )
                          }
                          style={{
                            height: '30px',
                            width: '30px',
                            backgroundColor: 'white',
                            borderRadius: '500000px',
                            border: ' 1px #A1A1A1 solid',
                            boxShadow:
                              ' 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%)',
                            padding: '.3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '.5rem',
                          }}
                        >
                          <SlidersHorizontal size={50} color="#2b2727" />
                        </Button>
                      }
                      placeholder={'Search Orders'}
                      url={'/main/p-orders/search/'}
                      urlParams={{
                        portal: props.currentPortal,
                        buyer_id: props.buyer?.id,
                        supplier_id: props.supplier?.id,
                      }}
                      titleFields={['title']}
                      descriptionFields={['description']}
                      emptyFieldAfterRedirect={true}
                      onChange={(e, d) => {
                        if (d.result.url) {
                          navigate(d.result.url)
                        }
                      }}
                      style={{
                        border: ' 1px #A1A1A1 solid',
                        boxShadow:
                          ' 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%)',
                      }}
                      loading={loading}
                    />

                    <Button
                      onClick={() => {
                        if (business?.id && user?.id) {
                          getLocations()
                        }
                        setOpenMobileLocationMenu(!openMobileLocationMenu)
                      }}
                      style={{
                        height: '50px',
                        width: '50px',
                        backgroundColor: 'white',
                        borderRadius: '500000px',
                        border: ' 1px #A1A1A1 solid',
                        boxShadow:
                          ' 0 1px 1px hsl(0deg 0% 0% / 8%), 0 2px 2px hsl(0deg 0% 0% / 8%), 0 4px 4px hsl(0deg 0% 0% / 8%), 0 8px 8px hsl(0deg 0% 0% / 8%), 0 16px 16px hsl(0deg 0% 0% / 8%)',
                        padding: '.75rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <MapPin size={50} color="#2b2727" />
                    </Button>
                  </div>
                  <div className="exchange-header-inner-wrapper">
                    <div className="main-logo">
                      <img
                        style={{ cursor: 'pointer' }}
                        src={env.REACT_APP_LOGO}
                        onClick={() => {
                          if (window.location.pathname.includes('/buyer')) {
                            navigate('/portal/buyer')
                          } else {
                            navigate('/portal/supplier')
                          }
                        }}
                        alt=""
                      />
                    </div>
                    <Menu
                      secondary
                      className="menu-list"
                      style={{ margin: 0, backgroundColor: 'white' }}
                    >
                      <Menu.Item
                        style={{ paddingRight: '30px', marginLeft: '30px' }}
                      >
                        <MickeySearch
                          name={'global-search'}
                          placeholder={'Search Orders'}
                          url={'/main/p-orders/search/'}
                          tenantAware={false}
                          urlParams={{
                            portal: props.currentPortal,
                            buyer_id: props.buyer?.id,
                            supplier_id: props.supplier?.id,
                          }}
                          titleFields={['title']}
                          descriptionFields={['description']}
                          emptyFieldAfterRedirect={true}
                          onChange={(e, d) => {
                            if (d.result.url) {
                              navigate(d.result.url)
                            }
                          }}
                          style={{
                            '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                            '--secondaryButtonColor':
                              env.REACT_APP_SECONDARY_BUTTON_COLOR,
                          }}
                          loading={loading}
                        />
                      </Menu.Item>
                      {!publicExchange && (
                        <>
                          {globalSettings?.id &&
                            !globalSettings?.hide_exchange &&
                            hasPermission(user, 'Create Order') && (
                              <Menu.Item style={{ paddingRight: '30px' }}>
                                <Button
                                  color={
                                    props.currentPortal === 'supplier'
                                      ? 'primary'
                                      : props.currentPortal === 'buyer'
                                      ? 'green'
                                      : ''
                                  }
                                  style={{
                                    '--primaryColor':
                                      env.REACT_APP_PRIMARY_COLOR,
                                    '--secondaryButtonColor':
                                      env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                  }}
                                  className="add-listing"
                                  onClick={() => {
                                    setStartListingModal(true)
                                    setSelectedTimeframe(null)
                                    setCurrentlySelectedLocation(null)
                                  }}
                                >
                                  {props.currentPortal === 'supplier'
                                    ? 'Add Listing'
                                    : props.currentPortal === 'buyer'
                                    ? 'Add Buy'
                                    : ''}
                                </Button>
                              </Menu.Item>
                            )}
                          {wd.width >= 1237 &&
                            globalSettings?.id &&
                            !globalSettings?.hide_exchange &&
                            hasPermission(user, 'Read Order') && (
                              <Menu.Item
                                as={NavLink}
                                icon={
                                  <Storefront
                                    weight="fill"
                                    size={15}
                                    style={{ marginRight: '5px' }}
                                  />
                                }
                                name="exchange"
                                className={'main-item'}
                                style={{
                                  color:
                                    props.currentTab === 'exchange' ||
                                    props.currentTab === 'my-bids' ||
                                    props.currentTab === 'watchlist'
                                      ? env.REACT_APP_PRIMARY_COLOR
                                      : '',
                                  backgroundColor: 'transparent',
                                }}
                                to={
                                  window.location.pathname.includes('/buyer')
                                    ? '/portal/buyer'
                                    : '/portal/supplier'
                                }
                              />
                            )}
                          {wd.width >= 1237 &&
                            hasPermission(user, 'Read Order') && (
                              <Menu.Item
                                as={NavLink}
                                icon={
                                  <Package
                                    weight="fill"
                                    size={15}
                                    style={{ marginRight: '5px' }}
                                  />
                                }
                                name="orders"
                                content={
                                  <>
                                    Orders
                                    <div
                                      style={{
                                        display:
                                          props.numberOfOrders < 1
                                            ? 'none'
                                            : 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor:
                                          env.REACT_APP_PRIMARY_COLOR,
                                        position: 'absolute',
                                        borderRadius: '500px',
                                        top: '-5px',
                                        right: '-5px',
                                        color: 'white',
                                        padding: '11px',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {props.numberOfOrders < 1 && ''}
                                      {props.numberOfOrders < 10 &&
                                        props.numberOfOrders}
                                      {props.numberOfOrders > 9 && '10+'}
                                    </div>
                                  </>
                                }
                                className={'main-item'}
                                style={{
                                  color:
                                    props.currentTab === 'orders'
                                      ? env.REACT_APP_PRIMARY_COLOR
                                      : '',
                                  backgroundColor: 'transparent',
                                }}
                                to={
                                  window.location.pathname.includes('/buyer')
                                    ? '/portal/buyer/orders'
                                    : '/portal/supplier/orders'
                                }
                              />
                            )}
                          {wd.width >= 1237 &&
                            hasPermission(user, 'Read Notification') && (
                              <Menu.Item
                                as={NavLink}
                                icon={
                                  <Bell
                                    weight="fill"
                                    size={15}
                                    style={{ marginRight: '5px' }}
                                  />
                                }
                                name="alerts"
                                content={
                                  <>
                                    Alerts
                                    <div
                                      style={{
                                        display:
                                          notifications.length < 1
                                            ? 'none'
                                            : 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor:
                                          env.REACT_APP_PRIMARY_COLOR,
                                        position: 'absolute',
                                        borderRadius: '500px',
                                        top: '-5px',
                                        right: '-5px',
                                        color: 'white',
                                        padding: '11px',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {notifications.legnth < 1 && ''}
                                      {notifications.length < 10 &&
                                        notifications.length}
                                      {notifications.length > 9 && '10+'}
                                    </div>
                                  </>
                                }
                                className={'main-item'}
                                style={{
                                  color:
                                    props.currentTab === 'notifications'
                                      ? env.REACT_APP_PRIMARY_COLOR
                                      : '',
                                  backgroundColor: 'transparent',
                                }}
                                to={
                                  window.location.pathname.includes('/buyer')
                                    ? '/portal/buyer/notifications'
                                    : '/portal/supplier/notifications'
                                }
                              />
                            )}
                          {wd.width >= 1237 && (
                            <Menu.Item
                              as={NavLink}
                              icon={
                                <Gear
                                  weight="fill"
                                  size={15}
                                  style={{ marginRight: '5px' }}
                                />
                              }
                              name="settings"
                              className={'main-item'}
                              style={{
                                color:
                                  props.currentTab === 'settings'
                                    ? env.REACT_APP_PRIMARY_COLOR
                                    : '',
                                backgroundColor: 'transparent',
                              }}
                              to={
                                window.location.pathname.includes('/buyer')
                                  ? '/portal/buyer/profile'
                                  : '/portal/supplier/profile'
                              }
                            />
                          )}
                          {wd.width >= 1237 && (
                            <>
                              <Dimmer
                                page
                                active={dim}
                                style={{ opacity: '0.35' }}
                              />
                              <Popup
                                as={Menu.Item}
                                on="click"
                                onOpen={() => {
                                  setOpen(true)
                                  setDim(true)
                                }}
                                onClose={() => {
                                  setOpen(false)
                                  setDim(false)
                                }}
                                offset={[21, 0]}
                                trigger={
                                  <Menu.Item
                                    style={{
                                      backgroundColor: 'transparent',
                                    }}
                                  >
                                    <div
                                      className="profile-icon"
                                      style={{
                                        cursor: 'pointer',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#3d3d3d',
                                        borderRadius: '17.5px',
                                        width: '35px',
                                        height: '35px',
                                      }}
                                    >
                                      {user?.first_name
                                        ? user?.first_name[0]
                                        : ''}
                                      {user?.last_name
                                        ? user?.last_name[0]
                                        : ''}
                                    </div>
                                  </Menu.Item>
                                }
                                open={open}
                                position="bottom right"
                                wide
                                className="user-popup"
                              >
                                <Popup.Content>
                                  <Grid
                                    centered
                                    style={{
                                      padding: '1rem 0rem',
                                      margin: '0',
                                    }}
                                  >
                                    <Grid.Row
                                      style={{
                                        margin: '0 1rem',
                                        padding: '0',
                                        justifyContent: 'right',
                                      }}
                                    >
                                      <X
                                        size={24}
                                        weight="bold"
                                        style={{
                                          margin: '0',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          setOpen(false)
                                          setDim(false)
                                        }}
                                      />
                                    </Grid.Row>
                                    <Grid.Row
                                      style={{
                                        margin: '0',
                                        padding: '2rem 0rem',
                                      }}
                                    >
                                      <Header as="h1">{business?.name}</Header>
                                    </Grid.Row>
                                    <Grid.Row
                                      style={{ margin: '0', padding: '0' }}
                                    >
                                      <p>
                                        {user?.first_name +
                                          ' ' +
                                          user?.last_name}
                                      </p>
                                    </Grid.Row>
                                    <Grid.Row
                                      style={{ margin: '0', padding: '0' }}
                                    >
                                      <p style={{ color: '#6D6E70' }}>
                                        {user?.email}
                                      </p>
                                    </Grid.Row>
                                    <Grid.Row style={{ padding: '3rem' }}>
                                      <div
                                        className="manage-profile-btn"
                                        onClick={() => {
                                          setOpen(false)
                                          setDim(false)
                                          if (
                                            window?.location?.pathname?.includes(
                                              '/portal/buyer/profile'
                                            ) ||
                                            window?.location?.pathname?.includes(
                                              '/portal/supplier/profile'
                                            )
                                          ) {
                                          } else {
                                            if (
                                              window?.location?.pathname?.includes(
                                                '/supplier'
                                              )
                                            ) {
                                              navigate(
                                                '/portal/supplier/profile'
                                              )
                                            } else {
                                              navigate('/portal/buyer/profile')
                                            }
                                          }
                                        }}
                                        style={{
                                          '--primaryColor':
                                            env.REACT_APP_PRIMARY_COLOR,
                                          '--secondaryButtonColor':
                                            env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                        }}
                                      >
                                        Manage Your Profile
                                      </div>
                                    </Grid.Row>
                                    {((props.currentPortal === 'supplier' &&
                                      props.buyer?.id) ||
                                      (props.currentPortal === 'buyer' &&
                                        props?.supplier?.id)) && (
                                      <>
                                        <Divider
                                          style={{ margin: '1rem 0rem' }}
                                        />
                                        <Grid.Row
                                          style={{
                                            cursor: 'pointer',
                                            padding: '0',
                                          }}
                                          onClick={() => {
                                            setOpen(false)
                                            setDim(false)
                                            setProfileModalOpen(false)
                                            if (
                                              props.currentPortal === 'buyer'
                                            ) {
                                              navigate('/portal/supplier')
                                            } else {
                                              navigate('/portal/buyer')
                                            }
                                          }}
                                        >
                                          <div
                                            className={'switch-portal-row'}
                                            style={{ display: 'contents' }}
                                          >
                                            <p
                                              style={{
                                                width: '100%',
                                                display: 'flex',
                                                flex: '0 1 75%',
                                                margin: '0px',
                                              }}
                                            >
                                              {props.currentPortal ===
                                                'buyer' &&
                                                hasPortalAccess(
                                                  reduxUser,
                                                  'supplier'
                                                ) && (
                                                  <>
                                                    {business?.name} Supplier
                                                    Portal
                                                  </>
                                                )}
                                              {props.currentPortal ===
                                                'supplier' &&
                                                hasPortalAccess(
                                                  reduxUser,
                                                  'buyer'
                                                ) && (
                                                  <>
                                                    {business?.name} Buyer
                                                    Portal
                                                  </>
                                                )}
                                            </p>
                                            <ArrowRight
                                              size={'25px'}
                                              style={{
                                                display: 'flex',
                                                flex: '0 1 16%',
                                              }}
                                            />
                                          </div>
                                        </Grid.Row>
                                        <Divider
                                          style={{ margin: '1rem 0rem' }}
                                        />
                                      </>
                                    )}
                                    {user?.is_god &&
                                      props.currentPortal !== 'admin' &&
                                      hasPortalAccess(reduxUser, 'admin') && (
                                        <>
                                          <Divider
                                            style={{ margin: '1rem 0rem' }}
                                          />
                                          <Grid.Row
                                            style={{
                                              cursor: 'pointer',
                                              padding: '0',
                                            }}
                                            onClick={() => {
                                              setOpen(false)
                                              setDim(false)
                                              navigate('/portal/admin')
                                            }}
                                          >
                                            <div
                                              className={'switch-portal-row'}
                                              style={{ display: 'contents' }}
                                            >
                                              <p
                                                style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  flex: '0 1 75%',
                                                  margin: '0px',
                                                }}
                                              >
                                                Switch to Admin Portal
                                              </p>
                                              <ArrowRight
                                                size={'25px'}
                                                style={{
                                                  display: 'flex',
                                                  flex: '0 1 16%',
                                                }}
                                              />
                                            </div>
                                          </Grid.Row>
                                        </>
                                      )}
                                    <Divider style={{ margin: '1rem 0rem' }} />
                                    <Grid.Row
                                      style={{
                                        cursor: 'pointer',
                                        padding: '0',
                                      }}
                                    >
                                      <Grid.Column>
                                        <p
                                          className={'logout-row'}
                                          style={{
                                            padding: '0',
                                          }}
                                          onClick={async () => {
                                            disconnectWs()
                                            dispatch(loggedOut())
                                            dispatch(removeUser())
                                            dispatch(removeBusiness())
                                            await auth.signout()
                                            if (
                                              env.REACT_APP_REDIRECT &&
                                              env.REACT_APP_REDIRECT !==
                                                'false' &&
                                              env.REACT_APP_REDIRECT !== 'null'
                                            ) {
                                              window.location = '/login'
                                            } else {
                                              window.location = '/'
                                            }
                                          }}
                                        >
                                          Log Out
                                        </p>
                                      </Grid.Column>
                                    </Grid.Row>
                                    <Divider style={{ margin: '1rem 0rem' }} />
                                  </Grid>
                                </Popup.Content>
                              </Popup>
                            </>
                          )}
                        </>
                      )}
                      {publicExchange && (
                        <>
                          <Menu.Item>
                            <Button
                              onClick={() => navigate('/apply')}
                              id="header-get-started"
                            >
                              Create Account
                            </Button>
                          </Menu.Item>
                          <Menu.Item style={{ padding: '0 0 0 30px' }}>
                            <Button
                              onClick={() => navigate('/login')}
                              id="header-login"
                            >
                              Login
                            </Button>
                          </Menu.Item>
                        </>
                      )}
                    </Menu>
                    {wd.width < 1237 && user && (
                      <div className="exchange-menu-btn">
                        <Hamburger
                          size={25}
                          toggled={openMobileMenu}
                          toggle={setOpenMobileMenu}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <Sidebar
                  id="exchange-sidebar"
                  className={openMobileMenu ? 'visible' : ''}
                  as={Menu}
                  direction="right"
                >
                  <div className="sidebar-menu">
                    <Menu.Item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      className="nav-item"
                      as={NavLink}
                      to={
                        window.location.pathname.includes('/buyer')
                          ? '/portal/buyer'
                          : '/portal/supplier'
                      }
                    >
                      <Storefront
                        style={{ cursor: 'pointer' }}
                        size={25}
                        weight="duotone"
                        color={
                          props.currentTab === 'exchange' ||
                          props.currentTab === 'my-bids' ||
                          props.currentTab === 'watchlist'
                            ? env.REACT_APP_PRIMARY_COLOR
                            : '#000000'
                        }
                      />
                      <span
                        style={{
                          paddingTop: '5px',
                          color:
                            props.currentTab === 'exchange' ||
                            props.currentTab === 'my-bids' ||
                            props.currentTab === 'watchlist'
                              ? env.REACT_APP_PRIMARY_COLOR
                              : '#000000',
                        }}
                      >
                        Exchange
                      </span>
                    </Menu.Item>

                    <Menu.Item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      className="nav-item"
                      as={NavLink}
                      to={
                        window.location.pathname.includes('/buyer')
                          ? '/portal/buyer/orders'
                          : '/portal/supplier/orders'
                      }
                    >
                      <Receipt
                        style={{ cursor: 'pointer' }}
                        size={25}
                        weight="duotone"
                        color={
                          props.currentTab === 'orders'
                            ? env.REACT_APP_PRIMARY_COLOR
                            : '#000000'
                        }
                      />
                      <span
                        style={{
                          paddingTop: '5px',
                          color:
                            props.currentTab === 'orders'
                              ? env.REACT_APP_PRIMARY_COLOR
                              : '#000000',
                        }}
                      >
                        Orders
                      </span>
                    </Menu.Item>

                    <Menu.Item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                      }}
                      className="nav-item"
                      as={NavLink}
                      to={
                        window.location.pathname.includes('/buyer')
                          ? '/portal/buyer/notifications'
                          : '/portal/supplier/notifications'
                      }
                    >
                      <Bell
                        style={{ cursor: 'pointer' }}
                        size={25}
                        weight="duotone"
                        color={
                          props.currentTab === 'notifications'
                            ? env.REACT_APP_PRIMARY_COLOR
                            : '#000000'
                        }
                      />
                      <span
                        style={{
                          paddingTop: '5px',
                          color:
                            props.currentTab === 'notifications'
                              ? env.REACT_APP_PRIMARY_COLOR
                              : '#000000',
                        }}
                      >
                        Alerts
                      </span>

                      <div
                        style={{
                          display: notifications.length < 1 ? 'none' : 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '15px',
                          height: '15px',
                          backgroundColor: env.REACT_APP_PRIMARY_COLOR,
                          position: 'absolute',
                          borderRadius: '500px',
                          top: '-5px',
                          right: '20px',
                          color: 'white',
                          padding: '11px',
                          fontSize: '11px',
                        }}
                      >
                        {notifications.legnth < 1 && ''}
                        {notifications.length < 10 && notifications.length}
                        {notifications.length > 9 && '10+'}
                      </div>
                    </Menu.Item>

                    <Menu.Item
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      className="nav-item"
                      as={NavLink}
                      to={
                        window.location.pathname.includes('/buyer')
                          ? '/portal/buyer/profile'
                          : '/portal/supplier/profile'
                      }
                    >
                      <Gear
                        style={{ cursor: 'pointer' }}
                        size={25}
                        weight="duotone"
                        color={
                          props.currentTab === 'settings'
                            ? env.REACT_APP_PRIMARY_COLOR
                            : '#000000'
                        }
                      />
                      <span
                        style={{
                          paddingTop: '5px',
                          color:
                            props.currentTab === 'settings'
                              ? env.REACT_APP_PRIMARY_COLOR
                              : '#000000',
                        }}
                      >
                        Settings
                      </span>
                    </Menu.Item>

                    <Modal
                      size="mini"
                      as={Menu.Item}
                      onOpen={() => {
                        setProfileModalOpen(true)
                        setDim(true)
                      }}
                      onClose={() => {
                        setProfileModalOpen(false)
                        setDim(false)
                      }}
                      offset={[21, 0]}
                      trigger={
                        <Menu.Item
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <User
                            style={{ cursor: 'pointer' }}
                            weight="duotone"
                            size={25}
                            color="#000000"
                          />
                          <span style={{ paddingTop: '5px' }}>
                            {user?.first_name}
                          </span>
                        </Menu.Item>
                      }
                      open={profileModalOpen}
                      position="bottom right"
                      wide
                      className="user-popup"
                    >
                      <Grid
                        centered
                        style={{ padding: '1rem 0rem', margin: '0' }}
                      >
                        <Grid.Row
                          style={{
                            margin: '0 1rem',
                            padding: '0',
                            justifyContent: 'right',
                          }}
                        >
                          <X
                            size={24}
                            weight="bold"
                            style={{ margin: '0', cursor: 'pointer' }}
                            onClick={() => {
                              setProfileModalOpen(false)
                              setDim(false)
                            }}
                          />
                        </Grid.Row>
                        <Grid.Row style={{ margin: '0', padding: '2rem 0rem' }}>
                          <Header as="h1">{business?.name}</Header>
                        </Grid.Row>
                        <Grid.Row style={{ margin: '0', padding: '0' }}>
                          <p>{user?.first_name + ' ' + user?.last_name}</p>
                        </Grid.Row>
                        <Grid.Row style={{ margin: '0', padding: '0' }}>
                          <p style={{ color: '#6D6E70' }}>{user?.email}</p>
                        </Grid.Row>
                        <Grid.Row style={{ padding: '3rem' }}>
                          <div
                            className="manage-profile-btn"
                            onClick={() => {
                              setOpen(false)
                              setDim(false)
                              setProfileModalOpen(false)
                              if (
                                window?.location?.pathname?.includes(
                                  '/portal/buyer/profile'
                                ) ||
                                window?.location?.pathname?.includes(
                                  '/portal/supplier/profile'
                                )
                              ) {
                              } else {
                                if (
                                  window?.location?.pathname?.includes(
                                    '/supplier'
                                  )
                                ) {
                                  navigate('/portal/supplier/profile')
                                } else {
                                  navigate('/portal/buyer/profile')
                                }
                              }
                            }}
                            style={{
                              '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              '--secondaryButtonColor':
                                env.REACT_APP_SECONDARY_BUTTON_COLOR,
                            }}
                          >
                            Manage Your Profile
                          </div>
                        </Grid.Row>
                        {((props.currentPortal === 'supplier' &&
                          props.buyer?.id) ||
                          (props.currentPortal === 'buyer' &&
                            props?.supplier?.id)) && (
                          <>
                            <Divider style={{ margin: '1rem 0rem' }} />
                            <Grid.Row
                              style={{
                                cursor: 'pointer',
                                padding: '0',
                              }}
                              onClick={() => {
                                setOpen(false)
                                setDim(false)
                                setProfileModalOpen(false)
                                if (props.currentPortal === 'buyer') {
                                  navigate('/portal/supplier')
                                } else {
                                  navigate('/portal/buyer')
                                }
                              }}
                            >
                              <div
                                className={'switch-portal-row'}
                                style={{ display: 'contents' }}
                              >
                                <p
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    flex: '0 1 75%',
                                    margin: '0px',
                                  }}
                                >
                                  {props.currentPortal === 'buyer' ? (
                                    <>{business?.name} Supplier Portal</>
                                  ) : (
                                    <>{business?.name} Buyer Portal</>
                                  )}
                                </p>
                                <ArrowRight
                                  size={'25px'}
                                  style={{ display: 'flex', flex: '0 1 16%' }}
                                />
                              </div>
                            </Grid.Row>
                            <Divider style={{ margin: '1rem 0rem' }} />
                          </>
                        )}
                        {user?.is_god && props.currentPortal !== 'admin' && (
                          <>
                            <Divider style={{ margin: '1rem 0rem' }} />
                            <Grid.Row
                              style={{ cursor: 'pointer', padding: '0' }}
                              onClick={() => {
                                navigate('/portal/admin')
                              }}
                            >
                              <div
                                className={'switch-portal-row'}
                                style={{ display: 'contents' }}
                              >
                                <p
                                  style={{
                                    width: '100%',
                                    display: 'flex',
                                    flex: '0 1 75%',
                                    margin: '0px',
                                  }}
                                >
                                  Switch to Admin Portal
                                </p>
                                <ArrowRight
                                  size={'25px'}
                                  style={{ display: 'flex', flex: '0 1 16%' }}
                                />
                              </div>
                            </Grid.Row>
                          </>
                        )}
                        <Divider style={{ margin: '1rem 0rem' }} />
                        <Grid.Row
                          style={{
                            cursor: 'pointer',
                            padding: '0',
                          }}
                        >
                          <Grid.Column>
                            <p
                              className={'logout-row'}
                              style={{
                                padding: '0',
                              }}
                              onClick={async () => {
                                disconnectWs()
                                dispatch(loggedOut())
                                dispatch(removeUser())
                                dispatch(removeBusiness())
                                await auth.signout()
                                if (
                                  env.REACT_APP_REDIRECT &&
                                  env.REACT_APP_REDIRECT !== 'false' &&
                                  env.REACT_APP_REDIRECT !== 'null'
                                ) {
                                  window.location = '/login'
                                } else {
                                  window.location = '/'
                                }
                              }}
                            >
                              Log Out
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                        <Divider style={{ margin: '1rem 0rem' }} />
                      </Grid>
                    </Modal>
                  </div>
                </Sidebar>
              </header>
              {globalSettings?.id && !globalSettings?.hide_exchange && (
                <Menu
                  id="exchange-subheader"
                  style={{
                    flexDirection: wd.width <= 950 ? 'column' : 'row',
                    zIndex: wd.width <= 770 ? 10 : 0,
                    position: 'relative',
                  }}
                >
                  {wd.width <= 768 &&
                    openMobileLocationMenu &&
                    (props.currentTab === 'exchange' ||
                      props.currentTab === 'my-bids' ||
                      props.currentTab === 'watchlist') && (
                      <motion.div
                        variants={variants}
                        animate={openMobileLocationMenu ? 'open' : 'closed'}
                        // transition={{ duration: .5 }}

                        style={{
                          position: 'absolute',
                          width: '100%',
                          // height: "100vh",
                          backgroundColor: 'white',
                        }}
                      >
                        <div>
                          <MXNewLocation
                            header={'Select Where'}
                            myAsk={props.currentTab === 'my-bids'}
                            currentSelectedLocation={
                              props.currentSelectedLocation
                            }
                            setCurrentSelectedLocation={
                              props.setCurrentSelectedLocation
                            }
                            showLocationForm={showLocationForm}
                            editLocation={editLocation}
                            setSearchValue={setSearchValue}
                            business={business}
                            value={value}
                            setValue={setValue}
                            user={user}
                            setEditLocation={setEditLocation}
                            searchValue={searchValue}
                            locations={locations}
                            setCurrentlySelectedLocation={
                              setCurrentlySelectedLocation
                            }
                            setOpenLocationPopup={setOpenLocationPopup}
                            setShowLocationForm={setShowLocationForm}
                            getLocations={getLocations}
                            supplier={props.supplier}
                            buyer={props.buyer}
                            loading={loading}
                            setLoading={setLoading}
                            updatePublicLocation={updatePublicLocation}
                            publicLocation={publicLocation}
                          />
                        </div>
                      </motion.div>
                    )}
                  <Menu
                    floated="left"
                    style={{
                      overflow: 'visible',
                      justifyContent:
                        wd.width <= 950 ? 'space-between' : 'left',
                      zIndex: 10,
                    }}
                  >
                    {!hideFilter && (
                      <Menu.Item
                        id="product-filter-button"
                        style={{ outline: '0' }}
                        as={Button}
                        onClick={() =>
                          props.setOpenExchangeFilter(!props.openExchangeFilter)
                        }
                      >
                        <Funnel
                          color={'grey'}
                          style={{
                            display:
                              props.currentTab === 'exchange' ||
                              props.currentTab === 'my-bids' ||
                              props.currentTab === 'watchlist'
                                ? 'inherit'
                                : 'none',
                          }}
                          weight={props.openExchangeFilter ? 'fill' : 'regular'}
                          size={23}
                        />
                      </Menu.Item>
                    )}
                    {pagewidth > 768 ? (
                      props.commodities?.map((commodity, index) => {
                        return (
                          <Menu.Item
                            key={index}
                            className="commodity"
                            onClick={() => {
                              props.setSelectedCommodity(commodity)
                              props.setCommodityForms(
                                commodity?.commodity_forms
                              )
                              if (
                                !(
                                  window.location.pathname === '/portal/buyer'
                                ) &&
                                props.currentPortal === 'buyer'
                              ) {
                                navigate(`/portal/buyer`)
                              }
                              if (
                                !(
                                  window.location.pathname ===
                                  '/portal/supplier'
                                ) &&
                                props.currentPortal === 'supplier'
                              ) {
                                navigate(`/portal/supplier`)
                              }
                              if (
                                window.location.pathname.includes(
                                  '/commodities'
                                )
                              ) {
                                navigate(props.selectedCommodity.name)
                              }
                            }}
                          >
                            <Header
                              style={{
                                '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                              }}
                              color={
                                commodity?.id === props.selectedCommodity?.id &&
                                props.currentTab === 'exchange'
                                  ? 'primary'
                                  : ''
                              }
                              as={'h4'}
                            >
                              <div className="mobile-commodity-image-placeholder"></div>
                              {commodity?.name}
                            </Header>
                          </Menu.Item>
                        )
                      })
                    ) : (
                      <Menu.Item>
                        <div>
                          <Popup
                            on={'click'}
                            position={'bottom right'}
                            className={'right'}
                            style={{ width: '250px' }}
                            // offset={[-55, 0]}
                            trigger={
                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                  fontWeight: 'bold',
                                }}
                              >
                                {['/portal/supplier', '/portal/buyer'].includes(
                                  window?.location?.pathname
                                )
                                  ? props.selectedCommodity?.name
                                  : 'Exchange'}
                                <CaretDown
                                  style={{ marginLeft: '10px' }}
                                  weight={'bold'}
                                  size={20}
                                />
                              </div>
                            }
                          >
                            <PopupContent style={{ minWidth: '200px' }}>
                              <Header
                                as="h4"
                                style={{
                                  margin: '10px 0 20px',
                                  padding: '0',
                                  textAlign: 'start',
                                }}
                              >
                                Select Commodity
                              </Header>
                              <Divider style={{ margin: '0px' }} />
                              <div
                                style={{
                                  overflow: 'scroll',
                                  maxHeight: '10em',
                                }}
                              >
                                {props.commodities?.map((commodity) => {
                                  return (
                                    <>
                                      <div
                                        style={{
                                          fontWeight: 'bold',
                                          width: '100%',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'start',
                                          cursor: 'pointer',
                                          padding: '1rem 0',
                                          color:
                                            props?.selectedCommodity?.id ===
                                              commodity?.id &&
                                            [
                                              '/portal/supplier',
                                              '/portal/buyer',
                                            ].includes(
                                              window?.location?.pathname
                                            ) &&
                                            env.REACT_APP_PRIMARY_COLOR,
                                        }}
                                        onClick={() => {
                                          props.setSelectedCommodity(commodity)
                                          props.setCommodityForms(
                                            commodity?.commodity_forms
                                          )
                                          if (
                                            !(
                                              window.location.pathname ===
                                              '/portal/buyer'
                                            ) &&
                                            props.currentPortal === 'buyer'
                                          ) {
                                            navigate(`/portal/buyer`)
                                          }
                                          if (
                                            !(
                                              window.location.pathname ===
                                              '/portal/supplier'
                                            ) &&
                                            props.currentPortal === 'supplier'
                                          ) {
                                            navigate(`/portal/supplier`)
                                          }
                                          if (
                                            window.location.pathname.includes(
                                              '/commodities'
                                            )
                                          ) {
                                            navigate(
                                              props.selectedCommodity.name
                                            )
                                          }
                                        }}
                                      >
                                        <div style={{ marginLeft: '10px' }}>
                                          <p style={{ margin: '0' }}>
                                            {props?.selectedCommodity?.id ===
                                              commodity?.id &&
                                            [
                                              '/portal/supplier',
                                              '/portal/buyer',
                                            ].includes(
                                              window?.location?.pathname
                                            ) ? (
                                              <CheckCircle
                                                size={15}
                                                weight="bold"
                                                style={{
                                                  cursor: 'pointer',
                                                  margin: '0 10px',
                                                }}
                                              />
                                            ) : (
                                              <Circle
                                                size={15}
                                                weight="bold"
                                                style={{ margin: '0 10px' }}
                                              />
                                            )}
                                            {commodity?.name}
                                          </p>
                                        </div>
                                      </div>
                                      <Divider style={{ margin: '0px' }} />
                                    </>
                                  )
                                })}
                              </div>
                            </PopupContent>
                          </Popup>
                        </div>
                      </Menu.Item>
                    )}
                    {reduxUser && (
                      <Menu.Item>
                        <Header
                          as={'h4'}
                          style={{
                            cursor: 'pointer',
                            color:
                              props.currentTab === 'my-bids'
                                ? env.REACT_APP_PRIMARY_COLOR
                                : '',
                          }}
                          onClick={() => {
                            if (
                              props.currentPortal === 'buyer' ||
                              currentPath.includes('buyer')
                            ) {
                              navigate(`/portal/buyer/my-bids`)
                            } else if (
                              props.currentPortal === 'supplier' ||
                              currentPath.includes('supplier')
                            ) {
                              navigate(`/portal/supplier/my-asks`)
                            }
                          }}
                        >
                          My Active{' '}
                          {props.currentPortal === 'supplier' ||
                          currentPath.includes('supplier')
                            ? 'Sells'
                            : props.currentPortal === 'buyer' ||
                              currentPath.includes('buyer')
                            ? 'Buys'
                            : ''}
                          {(props.currentPortal === 'supplier' ||
                            currentPath.includes('supplier')) &&
                            user?.active_asks?.length > 0 && (
                              <Label
                                color={
                                  props.currentTab === 'my-bids'
                                    ? 'primary'
                                    : 'grey'
                                }
                                floating
                                content={user?.active_asks?.length}
                                style={{
                                  display: openMobileLocationMenu ? 'none' : '',
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                  '--secondaryColor':
                                    env.REACT_APP_SECONDARY_COLOR,
                                  '--secondaryButtonColor':
                                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                }}
                              />
                            )}
                          {(props.currentPortal === 'buyer' ||
                            currentPath.includes('buyer')) &&
                            user?.active_bids?.length > 0 && (
                              <Label
                                color={
                                  props.currentTab === 'my-bids'
                                    ? 'primary'
                                    : 'grey'
                                }
                                floating
                                content={user?.active_bids?.length}
                                style={{
                                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                                  '--secondaryColor':
                                    env.REACT_APP_SECONDARY_COLOR,
                                  '--secondaryButtonColor':
                                    env.REACT_APP_SECONDARY_BUTTON_COLOR,
                                }}
                              />
                            )}
                        </Header>
                      </Menu.Item>
                    )}
                    {reduxUser && (
                      <Menu.Item>
                        <Header
                          as={'h4'}
                          style={{
                            cursor: 'pointer',
                            color:
                              props.currentTab === 'watchlist'
                                ? env.REACT_APP_PRIMARY_COLOR
                                : '',
                          }}
                          onClick={() => {
                            navigate(`/portal/${props.currentPortal}/watchlist`)
                          }}
                        >
                          Watchlist
                        </Header>
                      </Menu.Item>
                    )}
                  </Menu>
                  {pagewidth > 768 &&
                    (props.currentTab === 'exchange' ||
                      props.currentTab === 'my-bids' ||
                      props.currentTab === 'watchlist') && (
                      <Menu className="location" floated="right">
                        <Menu.Item style={{ padding: '0' }}>
                          <Dimmer
                            page
                            active={openLocationPopup}
                            style={{ backgroundColor: '#d3d3d380' }}
                          />
                          <Popup
                            on={'click'}
                            position={'bottom right'}
                            offset={[
                              props.currentPortal === 'supplier' ? 24 : -32,
                              0,
                            ]}
                            flowing
                            trigger={
                              <div
                                className={`popup-item popup-trigger ${
                                  openLocationPopup ? 'active' : ''
                                }`}
                              >
                                {user?.current_location ? (
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <p
                                      className=""
                                      style={{
                                        fontSize: '11px',
                                        color: '#A1A1A1',
                                        textAlign: 'start',
                                        margin: '0 6px 0',
                                      }}
                                    >
                                      {props.currentPortal === 'supplier'
                                        ? 'DELIVERING FROM'
                                        : 'DELIVERING TO'}
                                    </p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        margin: '1px 0px 0px',
                                        fontSize: '1em',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: '0',
                                          padding: '0 .4rem 0 .5rem',
                                        }}
                                      >
                                        {props.currentSelectedLocation &&
                                        props.currentSelectedLocation?.id === 0
                                          ? 'All Locations'
                                          : props.currentSelectedLocation
                                          ? `${
                                              props.currentSelectedLocation
                                                ?.address_1
                                            }
                                        ${
                                          props.currentSelectedLocation
                                            ?.address_2
                                            ? ' ' +
                                              props.currentSelectedLocation
                                                ?.address_2
                                            : ''
                                        }
                                        , ${
                                          props.currentSelectedLocation?.city
                                        }, ${
                                          props.currentSelectedLocation
                                            ?.state_short_code
                                        }`
                                          : ' '}
                                      </p>
                                      <p
                                        style={{
                                          margin:
                                            props.currentSelectedLocation
                                              ?.id === 0
                                              ? '0 4em'
                                              : '0',
                                          padding: '0 1rem 0 0',
                                          opacity: '.3',
                                        }}
                                      >
                                        {props.currentSelectedLocation &&
                                        props.currentSelectedLocation?.id === 0
                                          ? ''
                                          : props.currentSelectedLocation
                                              ?.zip_code}
                                      </p>
                                      <CaretDown weight={'bold'} size={15} />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <MapPin size={25} />
                                    <p
                                      style={{
                                        margin: '0 .5rem 0 .5rem',
                                        color: env.REACT_APP_PRIMARY_COLOR,
                                      }}
                                    >
                                      Select a Location
                                    </p>
                                    <WarningCircle
                                      style={{ marginRight: '.5rem' }}
                                      color={env.REACT_APP_PRIMARY_COLOR}
                                      size={25}
                                    />
                                  </>
                                )}
                              </div>
                            }
                            onClose={() => {
                              setShowLocationForm(false)
                              setSearchValue(false)
                              setEditLocation(false)
                              setOpenLocationPopup(false)
                            }}
                            onOpen={() => {
                              if (business?.id && user?.id) {
                                getLocations()
                              }
                              setOpenLocationPopup(true)
                            }}
                            style={{ padding: '10px 0px' }}
                            open={openLocationPopup}
                          >
                            <MXNewLocation
                              myAsk={props.currentTab === 'my-bids'}
                              currentSelectedLocation={
                                props.currentSelectedLocation
                              }
                              setCurrentSelectedLocation={
                                props.setCurrentSelectedLocation
                              }
                              showLocationForm={showLocationForm}
                              editLocation={editLocation}
                              setSearchValue={setSearchValue}
                              business={business}
                              value={value}
                              setValue={setValue}
                              user={user}
                              setEditLocation={setEditLocation}
                              searchValue={searchValue}
                              locations={locations}
                              setCurrentlySelectedLocation={
                                setCurrentlySelectedLocation
                              }
                              setOpenLocationPopup={setOpenLocationPopup}
                              setShowLocationForm={setShowLocationForm}
                              getLocations={getLocations}
                              supplier={props.supplier}
                              buyer={props.buyer}
                              loading={loading}
                              setLoading={setLoading}
                              updatePublicLocation={updatePublicLocation}
                              publicLocation={publicLocation}
                            />
                          </Popup>
                        </Menu.Item>
                      </Menu>
                    )}
                </Menu>
              )}
            </>
          )}

        {window &&
          !window.location.pathname.startsWith('/portal') &&
          !window.location.pathname.startsWith('/exchange') &&
          !window.location.pathname.includes('reset-password') &&
          !window.location.pathname.includes('login') &&
          !window.location.pathname.startsWith('/payment') &&
          !window.location.pathname.startsWith('/commodities') && (
            <>
              <Menu
                fluid
                id="homepage-header"
                onMouseLeave={() => setResourceDropdownVisible(false)}
              >
                <Menu.Item position="left" href="/">
                  <img
                    src={env.REACT_APP_LOGO}
                    className="header-logo"
                    alt=""
                  />
                </Menu.Item>
                <Menu.Menu position="right">
                  <Menu.Item>
                    <a href={'/about'}>About</a>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <a href={"/team"}>
                      Team
                    </a>
                  </Menu.Item> */}
                  {/* <Menu.Item> */}
                  {/*   <span */}
                  {/*     onMouseEnter={() => setResourceDropdownVisible(true)} */}
                  {/*     onClick={() => */}
                  {/*       setResourceDropdownVisible(!resourceDropdownVisible) */}
                  {/*     } */}
                  {/*     style={{ */}
                  {/*       display: 'flex', */}
                  {/*       alignItems: 'center', */}
                  {/*       cursor: 'pointer', */}
                  {/*       color: resourceDropdownVisible && '#f1592a', */}
                  {/*     }} */}
                  {/*   > */}
                  {/*     Resources */}
                  {/*     { */}
                  {/*       <CaretDown */}
                  {/*         size={16} */}
                  {/*         style={{ */}
                  {/*           marginLeft: '.5rem', */}
                  {/*           transition: '.2s ease-in-out', */}
                  {/*           transform: */}
                  {/*             resourceDropdownVisible && 'rotate(180deg)', */}
                  {/*         }} */}
                  {/*       /> */}
                  {/*     } */}
                  {/*   </span> */}
                  {/*   {resourceDropdownVisible && ( */}
                  {/*     <Grid */}
                  {/*       className="resource-dropdown-menu" */}
                  {/*       width={16} */}
                  {/*       style={{ */}
                  {/*         padding: '2rem', */}
                  {/*         flexWrap: wd.width <= 1000 ? 'wrap' : '', */}
                  {/*       }} */}
                  {/*     > */}
                  {/*       <Grid.Column */}
                  {/*         style={{ */}
                  {/*           display: wd.width <= 1000 ? 'flex' : '', */}
                  {/*           flexDirection: wd.width <= 1000 ? 'column' : '', */}
                  {/*           alignItems: wd.width <= 1000 ? 'center' : '', */}
                  {/*         }} */}
                  {/*         width={wd.width <= 1000 ? 8 : 4} */}
                  {/*       > */}
                  {/*         <div className="resource-box"> */}
                  {/*           <div className="resource-img"> */}
                  {/*             <img */}
                  {/*               src={article} */}
                  {/*               style={{ width: '1.5em' }} */}
                  {/*               alt="" */}
                  {/*             /> */}
                  {/*           </div> */}
                  {/*           <div className="resource-category"> */}
                  {/*             <a href="/press" className="category-head"> */}
                  {/*               Press */}
                  {/*             </a> */}
                  {/*             <p className="category-content"> */}
                  {/*               Updates on {env.REACT_APP_BUSINESS_NAME}{' '} */}
                  {/*             </p> */}
                  {/*           </div> */}
                  {/*         </div> */}
                  {/*       </Grid.Column> */}
                  {/*       <Grid.Column */}
                  {/*         style={{ */}
                  {/*           display: wd.width <= 1000 ? 'flex' : '', */}
                  {/*           flexDirection: wd.width <= 1000 ? 'column' : '', */}
                  {/*           alignItems: wd.width <= 1000 ? 'center' : '', */}
                  {/*         }} */}
                  {/*         width={wd.width <= 1000 ? 8 : 4} */}
                  {/*       > */}
                  {/*         <div className="resource-box"> */}
                  {/*           <div className="resource-img"> */}
                  {/*             <img */}
                  {/*               src={newspaperClipping} */}
                  {/*               style={{ width: '1.5em' }} */}
                  {/*               alt="" */}
                  {/*             /> */}
                  {/*           </div> */}
                  {/*           <div className="resource-category"> */}
                  {/*             <a */}
                  {/*               href="/news" */}
                  {/*               className="category-head" */}
                  {/*               style={{ padding: '0' }} */}
                  {/*             > */}
                  {/*               News */}
                  {/*             </a> */}
                  {/*             <p className="category-content"> */}
                  {/*               Featured articles in lumber and tech */}
                  {/*             </p> */}
                  {/*           </div> */}
                  {/*         </div> */}
                  {/*       </Grid.Column> */}
                  {/*     </Grid> */}
                  {/*   )} */}
                  {/* </Menu.Item> */}
                  <Menu.Item>
                    <a href={'/contact-us'}>Contact</a>
                  </Menu.Item>
                  <Menu.Item>
                    {/* <Button */}
                    {/*   id="header-get-started" */}
                    {/*   style={{ backgroundImage: 'none' }} */}
                    {/**/}
                    {/*   as={'a'} */}
                    {/*   href="/apply" */}
                    {/* > */}
                    {/*   Get Started */}
                    {/* </Button> */}
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      id="header-login"
                      icon={<UserCircle size={21} />}
                      as={'a'}
                      href="/login"
                      content={
                        <span style={{ marginLeft: '15px', marginTop: '-3px' }}>
                          {user && user.id ? `${user.first_name}` : `Login`}
                        </span>
                      }
                    />
                  </Menu.Item>
                  <Menu.Item className="mobile-menu-toggle">
                    <Fade toggled={openMobileMenu} toggle={setOpenMobileMenu} />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
              {openMobileMenu && (
                <Menu id="homepage-mobile-menu" vertical fluid>
                  <Menu.Item>
                    <a href={'/about'}>About</a>
                  </Menu.Item>
                  {/* <Menu.Item>
                    <a href={"/about"}>
                      Team
                    </a>
                  </Menu.Item> */}
                  <Menu.Item
                    style={{
                      flexDirection: resourceDropdownVisible ? 'column' : 'row',
                    }}
                  >
                    <span
                      onClick={() =>
                        setResourceDropdownVisible(!resourceDropdownVisible)
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        color: resourceDropdownVisible && '#f1592a',
                      }}
                    >
                      Resources
                      {
                        <CaretDown
                          size={24}
                          style={{
                            marginLeft: '.75rem',
                            transition: '.2s ease-in-out',
                            transform:
                              resourceDropdownVisible && 'rotate(180deg)',
                          }}
                        />
                      }
                    </span>
                    {resourceDropdownVisible && (
                      <Grid
                        className="resource-dropdown-menu"
                        width={16}
                        style={{
                          padding: '0 2rem 2rem 2rem',
                          marginTop: '2rem',
                          flexWrap: wd.width <= 1000 ? 'wrap' : '',
                        }}
                      >
                        <Grid.Column
                          style={{
                            display: wd.width <= 1000 ? 'flex' : '',
                            flexDirection: wd.width <= 1000 ? 'column' : '',
                          }}
                          width={wd.width <= 1000 ? 16 : 4}
                        >
                          <div className="resource-box">
                            <div className="resource-img">
                              <img
                                src={article}
                                style={{ width: '1.5em' }}
                                alt=""
                              />
                            </div>
                            <div className="resource-category">
                              <a href="/press" className="category-head">
                                Press
                              </a>
                              <p className="category-content">
                                Updates on {env.REACT_APP_BUSINESS_NAME}{' '}
                              </p>
                            </div>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          style={{
                            display: wd.width <= 1000 ? 'flex' : '',
                            flexDirection: wd.width <= 1000 ? 'column' : '',
                          }}
                          width={wd.width <= 1000 ? 16 : 4}
                        >
                          <div className="resource-box">
                            <div className="resource-img">
                              <img
                                src={newspaperClipping}
                                style={{ width: '1.5em' }}
                                alt=""
                              />
                            </div>
                            <div className="resource-category">
                              <a
                                href="/news"
                                className="category-head"
                                style={{ padding: '0' }}
                              >
                                News
                              </a>
                              <p className="category-content">
                                Featured articles in lumber and tech
                              </p>
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    <a href={'/contact-us'}>Support</a>
                  </Menu.Item>
                  <Menu.Item>
                    <Button id="header-get-started" as={'a'} href="/apply">
                      Get Started
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button id="header-login" as={'a'} href="/login">
                      {reduxUser && reduxUser.email
                        ? `${reduxUser.first_name}`
                        : `Login`}
                    </Button>
                  </Menu.Item>
                </Menu>
              )}
            </>
          )}
      </div>

      <MXNewListing
        refreshUser={refreshUser}
        currentPortal={props.currentPortal}
        openExchangeFilter={props.openExchangeFilter}
        setOpenExchangeFilter={props.setOpenExchangeFilter}
        openStartListingModal={props.openStartListingModal}
        selectedCommodity={props.selectedCommodity}
        setSelectedCommodity={props.setSelectedCommodity}
        setStartListingModal={props.setStartListingModal}
        openSetAttributesModal={props.openSetAttributesModal}
        setOpenAttributesModal={props.setOpenAttributesModal}
        openPricingModal={props.openPricingModal}
        setOpenPricingModal={props.setOpenPricingModal}
        openReviewListingModal={props.openReviewListingModal}
        setReviewListingModal={props.setReviewListingModal}
        openTimeframeModal={props.openTimeframeModal}
        setTimeframeModal={props.setTimeframeModal}
        productVariant={props.productVariant}
        setProductVariant={props.setProductVariant}
        setInputPrice={props.setInputPrice}
        inputPrice={props.inputPrice}
        inputSecondaryPrice={props.inputSecondaryPrice}
        setInputSecondaryPrice={props.setInputSecondaryPrice}
        setInputQuantity={props.setInputQuantity}
        setReloadHeaderStats={props.setReloadHeaderStats}
        inputQuantity={props.inputQuantity}
        notifications={props.notifications}
        setNotifications={props.setNotifications}
        inputExpiration={props.inputExpiration}
        setInputExpiration={props.setInputExpiration}
        currentTab={props.currentTab}
        setCurrentTab={props.setCurrentTab}
        buyer={props.buyer}
        supplier={props.supplier}
        timeframe={props.timeframe}
        numberOfOrders={props.numberOfOrders}
        setTimeframe={props.setTimeframe}
        reAuth={props.reAuth}
        equipmentType={props.equipmentType}
        setEquipmentType={props.setEquipmentType}
        commodities={props.commodities}
        setCommodities={props.setCommodities}
        commodityForms={props.commodityForms}
        setCommodityForms={props.setCommodityForms}
        selectedCommodityForm={props.selectedCommodityForm}
        setSelectedCommodityForm={props.setSelectedCommodityForm}
        setSelectLocationModal={setSelectLocationModal}
        currentlySelectedTimeframe={props.currentlySelectedTimeframe}
        setCurrentlySelectedTimeframe={props.setCurrentlySelectedTimeframe}
        currentlySelectedTruckingType={props.currentlySelectedTruckingType}
        setCurrentlySelectedTruckingType={
          props.setCurrentlySelectedTruckingType
        }
      />
      <Modal
        onOpen={() => setSelectLocationModal(true)}
        onClose={() => setSelectLocationModal(false)}
        open={selectLocationModal}
        style={{
          minHeight: '500px',
          padding: '2rem',
        }}
        size="mini"
        closeOnDimmerClick={false}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: 'inherit',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 20%',
            }}
          >
            <Header
              as={'h1'}
              style={{ marginBottom: '.5rem' }}
              textAlign="center"
            >
              Please Select Location
            </Header>
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 65%',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <MickeyDropdown
              url={'/main/locations/'}
              name={'locations'}
              autoComplete="new-password"
              params={{
                business_id: business?.id,
                page_size: 25,
              }}
              tenantAware={false}
              placeholder={'Select Address'}
              titleFields={['name', 'address_1']}
              descriptionFields={['city', 'state', 'zip_code']}
              onChange={(e, d) => {
                setCurrentlySelectedLocation(d.value)
              }}
            />
          </div>
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: '0 1 15%',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  display: 'flex',
                  flex: '0 1 100%',
                  justifyContent: 'center',
                }}
                color="primary"
                onClick={() => updateSelectedLocation()}
              >
                Select Location
              </Button>
            </div>
            <div style={{ display: 'flex', width: '100%', marginTop: '10px' }}>
              <Button
                style={{
                  '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
                  display: 'flex',
                  flex: '1 100%',
                  justifyContent: 'center',
                }}
                color="grey"
                onClick={() => {
                  setAddNewLocationModal(true)
                }}
              >
                Or, Add a New Location...
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onOpen={() => setAddNewLocationModal(true)}
        onClose={() => setAddNewLocationModal(false)}
        open={addNewLocationModal}
        style={{}}
      >
        <Modal.Header>
          <Header
            as={'h1'}
            style={{ marginBottom: '.5rem' }}
            textAlign="center"
          >
            Add New Location
          </Header>
        </Modal.Header>
        <Modal.Content>
          <MickeyForm
            formName={'LocationForm'}
            model={'Location'}
            instanceId={props.location?.id}
            tenantAware={!props.admin}
            url={'/main/locations/'}
            urlParams={{
              supplier_id: props.supplier?.id,
              buyer_id: props.buyer?.id,
            }}
            values={{ business_id: business?.id }}
            handleForm={() => {
              setAddNewLocationModal(false)
            }}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default MXHeader
