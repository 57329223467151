export const TickerFieldForm = {
  model: 'TickerFieldForm',
  format: [
    {
      widths: 'equal',
      fields: {
        name: {
          required: true,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        description: {
          required: true,
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        url: {
          type: 'text',
        },
      },
    },
    {
      widths: 'equal',
      fields: {
        link_text: {
          type: 'text',
        },
      },
    },
  ],
}
