import React, { useState, useEffect } from 'react'
import useAxios from '../../../lib/auth/useAxios'
import { Table, Placeholder, Confirm } from 'semantic-ui-react'
import InventoryPricedCombo from './InventoryPricedCombo'
import _ from 'underscore'
import { toast } from 'react-toastify'
import './InventoryRow.css'
import MickeyButton from '../../../components/MickeyButton/MickeyButton'

function InventoryRow(props) {
  const axios = useAxios()
  const [productListingPrices, setProductListingPrices] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [attributes, setAttributes] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    async function getAttributes() {
      setLoading(true)
      let res = await axios.get(`/main/supplier-inventory-detail`, {
        params: {
          product_listing: props.editingProductId,
          supplier_id: props.supplier?.id,
        },
      })
      let response = await axios.get('/main/supplier-inventory-product', {
        params: {
          product_listing_id: props.editingProductId,
          supplier_id: props.supplier?.id,
        },
      })
      setProductListingPrices(response?.data?.results)
      setAttributes(res?.data?.results?.attributes)
      let priceAttributes = _.filter(
        res?.data?.results?.attributes,
        (attribute) => {
          return attribute.has_price
        }
      )
      props.setNumAttributes(priceAttributes?.length)
      setLoading(false)
    }

    if (props.editingProductId === props.productListing?.id) {
      getAttributes()
    }
  }, [props.editingProductId, props.reload])

  function currentlyEditingRow() {
    return props.editingProductId === props.productListing?.id
  }

  async function removeItem() {
    props.setLoading(true)
    const res = await axios.delete(
      `/main/product-listings/${props.productListing?.id}/`
    )
    if (res.status < 300) {
      props.setReload(true)
      toast.success('Removed product listing!')
    }
  }
  function updatingEditingId() {
    if (
      props.editingProductId &&
      props.productListing.id === props.editingProductId
    ) {
      props.setEditingProductId(null)
    } else {
      props.setEditingProductId(props.productListing.id)
    }
  }
  return (
    <>
      <Table.Row
        key={props.productListing?.id}
        className={currentlyEditingRow() ? 'no-bottom' : 'border-top'}
        style={{ cursor: 'pointer', position: 'relative' }}
        positive={currentlyEditingRow()}
      >
        <Table.Cell
          onClick={() => {
            updatingEditingId()
          }}
          className={'table-header'}
        >
          {props.productListing?.product?.material?.name} (
          {props.productListing?.product?.commodity_form?.name})
        </Table.Cell>
        {currentlyEditingRow() &&
          attributes?.map((attribute) => {
            return (
              <Table.Cell
                style={{ position: 'relative' }}
                onClick={() => {
                  updatingEditingId()
                }}
              >
                {attribute.name}
              </Table.Cell>
            )
          })}
        {currentlyEditingRow() && attributes?.length && (
          <>
            <Table.Cell width={2}>
              FOB Price{' '}
              {props.productListing?.product?.default_unit_of_measure
                ?.singular_name
                ? 'Per ' +
                  props.productListing?.product?.default_unit_of_measure
                    ?.singular_name
                : ''}
            </Table.Cell>
            <Table.Cell width={2}>
              Inventory (
              {props.productListing?.product?.default_unit_of_measure?.name})
            </Table.Cell>
          </>
        )}
        {!currentlyEditingRow() && (
          <Table.Cell
            onClick={() => {
              updatingEditingId()
            }}
            colSpan={props.numAttributes ? props.numAttributes + 4 : 0}
          />
        )}
        {currentlyEditingRow() && <Table.Cell>Active?</Table.Cell>}
        <Table.Cell>
          <MickeyButton
            basic
            onClick={() => {
              setOpenConfirm(true)
            }}
            size="mini"
            color="red"
            icon="x"
            style={{
              cursor: 'pointer',
            }}
          />
        </Table.Cell>
        <Confirm
          size={'small'}
          open={openConfirm}
          onCancel={() => {
            setOpenConfirm(false)
          }}
          onConfirm={() => {
            setOpenConfirm(false)
            removeItem()
          }}
          content="Removing this product will permanently remove it and all sub-products from your inventory."
          header="Are you sure?"
        />
      </Table.Row>
      {currentlyEditingRow() && loading && (
        <Table.Row colSpan={16}>
          <Table.Cell
            loading
            width={16}
            colSpan={attributes ? attributes.length + 5 : 6}
          >
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Table.Cell>
        </Table.Row>
      )}
      {currentlyEditingRow() &&
        !props.loading &&
        !loading &&
        productListingPrices?.map((productListingPrice, index, arr) => {
          return (
            <InventoryPricedCombo
              key={productListingPrice?.id}
              attributeSelections={
                productListingPrice?.product_variant.attribute_selections
              }
              setEditingProductId={props.setEditingProductId}
              productListingPrice={productListingPrice}
              setReload={props.setReload}
              priceAttributes={attributes}
              productListing={props.productListing}
              lastIndex={arr.length - 1 === index}
            />
          )
        })}
    </>
  )
}

export default InventoryRow
