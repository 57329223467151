import env from '../../../../../../../../env'
import { Button, Header } from 'semantic-ui-react'

const CustomLumberLengthFilter = (props) => {
  const { attribute, updateCustomSelections, checkFilterSelection, commodity } =
    props

  return (
    <>
      <Header as={'h5'}>Custom Lumber Length Tally</Header>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 0) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 0,
            filterValue: { value: "Some 8'", order: 0, customValueInput: 0 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 8'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 1) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 1,
            filterValue: { value: "Some 10'", order: 1, customValueInput: 1 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 10'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 2) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 2,
            filterValue: { value: "Some 12'", order: 2, customValueInput: 2 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 12'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 3) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 3,
            filterValue: { value: "Some 14'", order: 3, customValueInput: 3 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 14'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 4) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 4,
            filterValue: { value: "Some 16'", order: 4, customValueInput: 4 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 16'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 5) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 5,
            filterValue: { value: "Some 18'", order: 5, customValueInput: 5 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 18'
      </Button>
      <Button
        color="grey"
        size={'mini'}
        basic={checkFilterSelection('Lumber', attribute?.name, 6) === false}
        style={{
          borderRadius: '3px',
          margin: '.25rem',
          '--primaryColor': env.REACT_APP_PRIMARY_COLOR,
          '--secondaryButtonColor': env.REACT_APP_SECONDARY_BUTTON_COLOR,
        }}
        onClick={() =>
          updateCustomSelections({
            value: 6,
            filterValue: { value: "Some 20'", order: 6, customValueInput: 6 },
            attributeName: attribute?.name,
            commodityForm: 'Lumber',
            commodity: commodity?.name,
          })
        }
      >
        Some 20'
      </Button>
    </>
  )
}

export default CustomLumberLengthFilter
