import { Grid } from 'semantic-ui-react'
import _ from 'underscore'
import MickeyTable from '../../../../components/MickeyTable/MickeyTable'
import PageHeader from '../../../../components/PageHeader/PageHeader'
import { ChatDots } from 'phosphor-react'
import AdminSalesNotesRow from './AdminSalesNotesRow'
import { format_phone_number } from '../../../../lib/utils/utils'

const AdminSalesNotes = (props) => {
  const { salespersonId } = props
  const headerRow = ['Date', 'Salesperson', 'User', 'Contact Method', 'Notes']

  const headerSort = [
    {
      header: 'Date',
      sort_by: 'created_at',
    },
    {
      header: 'Salesperson',
    },
    {
      header: 'User',
      sort_by: 'user__first_name',
    },
    {
      header: 'Contact Method',
      sort_by: 'communication_type',
    },
    {
      header: 'Notes',
    },
  ]
  const headerSearch = [
    {
      header: 'Date',
      type: 'date_range',
      search_param_start: 'created_at_start',
      search_param_end: 'created_at_end',
    },
    {
      header: 'User',
      type: 'dropdown',
      options_url: 'custom-users/',
      options_params: {
        extra_light_serializer: 'true',
      },
      options_titleFields: ['first_name', 'last_name'],
      search_param: 'user_id',
    },
    {
      header: 'Contact Method',
      type: 'dropdown',
      generateOptions: (results) => {
        let options = []
        results.forEach((d) => {
          options.push({
            text: d.email,
            value: d.email,
            key: d.email,
          })
          if (d.phone && d.phone !== '1') {
            options.push({
              text: format_phone_number(d.phone),
              value: d.phone,
              key: d.phone,
            })
          }
        })
        return options
      },
      options_url: 'custom-users/',
      options_params: {
        light_serializer: 'true',
      },
      search_param: 'communication_type',
    },
  ]

  const urlParams = {}
  if (salespersonId) {
    urlParams.salesperson_id = salespersonId
  }
  let breadcrumbData = [
    { name: 'Admin', href: '/portal/admin/orders' },
    { name: 'Sales', href: '/portal/admin/sales' },
  ]

  return (
    <>
      <PageHeader
        header="My Sales Log"
        breadcrumbHeader="My Sales Log"
        breadcrumb={breadcrumbData}
      />
      <Grid.Row className={'no-top-margin no-top-padding'}>
        <Grid.Column width={8}>
          <MickeyTable
            headerRow={headerRow}
            headerSearch={headerSearch}
            headerSort={headerSort}
            urlParams={urlParams}
            basicSegment
            showHeaderNoData={true}
            url={'/main/admin-sales-logs/'}
            renderBodyRow={(data, index) => {
              return (
                <AdminSalesNotesRow salesLog={data} index={index} key={index} />
              )
            }}
            emptyIconHtml={<ChatDots size={40} />}
            emptyMessage={'No Sales Log'}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default AdminSalesNotes
