import React, { useState, useEffect, useRef } from 'react'
import { Input, Header, Card, Grid, Button } from 'semantic-ui-react'
import './MXHomepage.css'
import plywood from '../../../../img/osb-plywood.gif'
import marketplace from '../../../../img/materialXchange.png'
import Lenco from '../../../../img/lenco-logo.png'
import access from '../../../../img/get-access.png'
import digital from '../../../../img/digital-expand.webp'
import eye from '../../../../img/eye.png'
import match from '../../../../img/match.png'
import bid from '../../../../img/bid.png'
import { useNavigate } from 'react-router-dom'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import env from '../../../../env'
import BackgroundVideo from '../../../../video/about-page-video.webm'
import BackgroundVideoStill from '../../../../img/background-video-still.png'

const MXHomepage = () => {
  // STATE
  const [getStartedEmail, setGetStartedEmail] = useState('')
  // HOOKS
  const navigate = useNavigate()
  const wd = useWindowDimensions()

  function Section({ children }) {
    const ref = useRef(null)

    return (
      <section ref={ref}>
        <span
          style={{
            transition: '0.8s',
            display: 'flex',
          }}
        >
          {children}
        </span>
      </section>
    )
  }

  useEffect(() => {
    if (
      env.REACT_APP_REDIRECT &&
      env.REACT_APP_REDIRECT !== 'false' &&
      env.REACT_APP_REDIRECT !== 'null'
    ) {
      navigate('/login')
    }
  }, [])

  const handleEmail = (inputEmail) => {
    setGetStartedEmail(inputEmail)
  }

  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

  return (
    <div>

      <div className="container">
        <div className="main-section">
          <div className="welcome_section">
            <div className="welcome-left">
              <div className="welcome-big-text" >
                <h4 style={{color: primaryColor}}>WELCOME</h4>
                <h1>{env.REACT_APP_HOMEPAGE_TITLE}</h1>
              </div>
              <p>
                {env.REACT_APP_HOMEPAGE_SUBTITLE}
              </p>
              <div className="welcome-input-btn">
                <button
                  style={{ padding: '0 2rem 0 2rem' }}
                  onClick={() => (window.location.href = '/exchange')}
                  className="ui button get-btn"
                >
                  Explore the Marketplace
                </button>
              </div>
            </div>
            <div className="welcome-right">
              <img className="osb-plywood" src={env.REACT_APP_HOMEPAGE_TITLE_IMAGE} alt="" />
              {/* <div className="plywood_border" style={{background: primaryColor}}></div> */}
            </div>
          </div>

          <div className="material_bigimg">
            <img src={marketplace} alt="" />
          </div>
          <div className="about-us">
            <h4>The Platform</h4>
            <h2>What is {env.REACT_APP_HOMEPAGE_TITLE}</h2>
            <p>
              {env.REACT_APP_HOMEPAGE_WHAT_IS_DESCRIPTION}             
            </p>
            <div className="learn-more">
              <button
                onClick={() => navigate('/about')}
                className="ui button get-btn"
              >
                Learn More
              </button>
            </div>
          </div>

          <div className="future-commodity">
            <h2>
              <span className="lt_gray">Step into the</span> future of {env.REACT_APP_INDUSTRY} 
            </h2>
            <p>
              The team at {env.REACT_APP_BUSINESS_NAME} is modernizing the inefficient, "call around"
              structure of buying and selling {env.REACT_APP_INDUSTRY} with our digital
              marketplace.
            </p>
            <div
              className="future-icons"
              style={{ flexDirection: wd.width <= 950 ? 'column' : 'row' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={eye} alt="" />
                <Header as={'h2'}>
                  View
                  <Header.Subheader
                    style={{ marginTop: 'calc(2rem - 0.142857em)' }}
                  >
                    {env.REACT_APP_INDUSTRY} Products and Pricing in Real-Time
                  </Header.Subheader>
                </Header>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={bid} alt="" />
                <Header as={'h2'}>
                  Bid
                  <Header.Subheader
                    style={{ marginTop: 'calc(2rem - 0.142857em)' }}
                  >
                    {env.REACT_APP_INDUSTRY} You Need at Prices You Want to Pay
                  </Header.Subheader>
                </Header>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: '0 1 33%',
                  padding: '.75rem',
                }}
              >
                <img src={match} alt="" />
                <Header as={'h2'}>
                  Match
                  <Header.Subheader
                    style={{ marginTop: 'calc(2rem - 0.142857em)' }}
                  >
                    Buyer and Seller Interaction Automations
                  </Header.Subheader>
                </Header>
              </div>
            </div>
          </div>

          {/* <div className="explore-cat">
            <h4>Explore Categories</h4>
            <a href="/verticals">
              <button class="ui button see-all">See All</button>
            </a>
            {verticals?.length > 0 && !loadingVerticals ?

              <div>
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  showDots
                  customDot={<CustomDot />}
                  infinite={true}
                  autoPlay={true}
                  focusOnSelect={true}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="image-item"
                >
                  {verticals.map((vertical) => {
                    if (vertical?.vertical_image === null) {
                      console.log("");
                    } else {
                      return (
                        <div className="species-slide">
                          <img
                            src={
                              env.REACT_APP_ENVIRONMENT === "local"
                                ? `http://marketplace.localhost:8002${vertical?.vertical_image}`
                                : vertical?.vertical_image
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1.5rem",
                            }}
                          >
                            <p>{vertical?.name}</p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Carousel>
              </div>
              :
              <Loader active />
            }

          </div> */}

          {/* <div className="Softwood">
            <h4>Softwood Lumber & Panels</h4>

            {
              products?.length > 0 && !loadingProducts ?
                <ProductSlider
                  tenant={tenant}
                  products={products}
                  tenantProducts={tenantProducts}
                />
                :
                <Loader active />
            }
          </div> */}
          {/* <div className="testimonial-wrapper"> */}
          {/*   <Card > */}
          {/*     <div className="customers-say"> */}
          {/*       <p > */}
          {/*         What our customers say */}
          {/*       </p> */}
          {/*     </div> */}
          {/*     <div className="testimonial-image-wrapper"> */}
          {/*       <img src={Lenco} style={{ width: "250px", height: "auto" }} /> */}
          {/*     </div> */}
          {/*     <div className="testimonial-text-wrapper" > */}
          {/*       <Header as={"p"}> */}
          {/*         "MaterialsXchange continues to be leading the charge for the digital revolution taking place in commodity lumber and panels. There is nothing that compares to the speed, efficiency, and simplicity of MX when buying or selling these products." */}
          {/*         <Header.Subheader as={"p"}> */}
          {/*           <b> */}
          {/*             Steve Cappola, */}
          {/*           </b> */}
          {/*           <span> */}
          {/*             {" "}VP Lenco Supplies */}
          {/*           </span> */}
          {/*         </Header.Subheader> */}
          {/*       </Header> */}
          {/*     </div> */}
          {/*   </Card> */}
          {/* </div> */}
          {/* <div className="digital-expand"> */}
          {/*   <div className="digital-img"> */}
          {/*     <img src={digital} alt="" /> */}
          {/*   </div> */}
          {/*   <div className="digital-expand-content"> */}
          {/*     <h2> */}
          {/*       Digitally<span className="blck"> expand your network</span> to */}
          {/*       reach across the United States and Canada */}
          {/*     </h2> */}
          {/*     <div className="digital-box-row"> */}
          {/*       <div className="digital-box"> */}
          {/*         <p className="digital-text">Years of Experience</p> */}
          {/*         <Section className="digit-num"> */}
          {/*           100 <span className="digital-plus">+</span> */}
          {/*         </Section> */}
          {/*       </div> */}
          {/*       <div className="digital-box"> */}
          {/*         <p className="digital-text">User Locations</p> */}
          {/*         <Section className="digit-num"> */}
          {/*           1800 <span className="digital-plus">+</span> */}
          {/*         </Section> */}
          {/*       </div> */}
          {/*       <div className="digital-box"> */}
          {/*         <p className="digital-text">Platform Users</p> */}
          {/*         <Section className="digit-num"> */}
          {/*           715 <span className="digital-plus">+</span> */}
          {/*         </Section> */}
          {/*       </div> */}
          {/*     </div> */}
          {/*   </div> */}
          {/* </div> */}
          <div className="get-started">
            <div className="get-left">
              <div className="get-big-text">
                <h2>Get started with {env.REACT_APP_BUSINESS_NAME} today.</h2>
              </div>
              <p>
                Create your account to buy or sell on {env.REACT_APP_BUSINESS_NAME} for one-click
                sales, quick liquidation, and fully automated logistics.
              </p>
              <div
                className="welcome-input-btn"
                style={{
                  display: wd.width <= 768 ? 'flex' : '',
                  flexDirection: wd.width <= 768 ? 'column' : '',
                  alignItems: wd.width <= 768 ? 'center' : '',
                }}
              >
                <Input
                  placeholder="enter email..."
                  onChange={(e, d) => handleEmail(d?.value)}
                />
                <button
                  style={{ width: '130px' }}
                  onClick={() => navigate(`/apply/?email=${getStartedEmail}`)}
                  className="ui button get-btn"
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="get-right">
              <img src={env.REACT_APP_HOMEPAGE_BOTTOM_IMAGE} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MXHomepage
